<app-breadcrumb></app-breadcrumb>

<div class="container-fluid pt-3 pl-0 pr-0 espace">

  <ng-container>

    <div class="my-4">
      <div class="container-fluid main">
        <div class="row row-eq-height">
          <div class="col-12">
            <div class="row align-items-center">
              <div class="col-md-2">
                <h1><b>{{'modules.alarms.alarms' | translate}}</b></h1>
              </div>
              <div class="col-md-10 text-end">
                <div class="btn btn-primary m-2" *ngIf="authSrv.isAdmin() == true || authSrv.isManufacturer() || authSrv.isClient() " [routerLink]="['/alarms/device/add']">
                  {{'modules.alarms.add-alarm-to-device' | translate}}
                </div>
                <div class="btn btn-primary m-2"  *ngIf="authSrv.isAdmin() == true || authSrv.isManufacturer()" [routerLink]="['/alarms/model/add']">
                  {{'modules.alarms.add-alarm-to-model' | translate}}
                </div>
                <div class="btn btn-primary m-2"  *ngIf="authSrv.isAdmin() == true" [routerLink]="['/alarms/default/add']">
                  {{'modules.alarms.add-default-value' | translate}}
                </div>
                <div class="btn btn-warning m-2"  *ngIf="authSrv.isAdmin() == true" [routerLink]="['/alarms/trace-simulator']">
                  {{'modules.alarms.simulate-trace' | translate}}
                </div>

                <app-generic-button *ngIf="authSrv.isAdmin() == true" txtBtn="{{ 'modules.shared.sync' | translate }}" title="{{'modules.shared.warning' | translate}}" txt="{{'modules.alarms.are-you-sure-sync' | translate}} ?"
              btnAccept="{{ 'modules.shared.sync' | translate }}" (confirmDelete)="syncAlarms()" ></app-generic-button>

                <!-- <div class="btn btn-danger m-2"  *ngIf="authSrv.isAdmin() == true" (click)="syncAlarms()">
                  {{ 'modules.shared.sync' | translate }}
                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <app-block-group-dashboard>
      <div class="col-xl-3 col-lg-6">
        <div class="card card-inverse card-success mb-3">
          <div class="card-block bg-success">
            <div class="rotate">
              <i class="fa fa-bell fa-5x"></i>
            </div>
            <a [routerLink]="['/alarms/add']">
              <h6 class="text-uppercase txt-size-info">
                {{'modules.alarms.add-alarm-to-device' | translate}}
              </h6></a>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6">
        <div class="card card-inverse card-danger mb-3">
          <div class="card-block bg-info">
            <div class="rotate">
              <i class="fa-solid fa-bells fa-5x"></i>
            </div>
            <a [routerLink]="['/users/add/fabricant']">
              <h6 class="text-uppercase txt-size-info">
                {{'modules.alarms.add-alarm-to-model' | translate}}
              </h6></a>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6">
        <div class="card card-inverse card-warning mb-3">
          <div class="card-block bg-warning">
            <div class="rotate">
              <i class="fas fa-bell-exclamation fa-5x"></i>
            </div>
            <a [routerLink]="['/alarms/default/add']">
              <h6 class="text-uppercase txt-size-info">
                {{'modules.alarms.add-default-value' | translate}}
              </h6></a>
          </div>
        </div>
      </div>

     </app-block-group-dashboard> -->

  </ng-container>


  <app-block-group-dashboard>

    <app-block-dashboard>

      <mat-tab-group mat-align-tabs="start" [selectedIndex]="page" (selectedIndexChange)="page = $event">
        <mat-tab>
            <ng-template mat-tab-label>
              {{'modules.alarms.alarms-in-devices' | translate}}
            </ng-template>
            <div class="mt-3">
              <app-list-device-alarms></app-list-device-alarms>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
              {{'modules.alarms.alarms-in-models' | translate}}
            </ng-template>
            <div class="mt-3">
              <app-list-model-alarms [showDefaultAlarm]="false" ></app-list-model-alarms>
            </div>
        </mat-tab>
        <mat-tab *ngIf="authSrv.isAdmin() == true">
            <ng-template mat-tab-label>
              {{'modules.alarms.default-values' | translate}}
            </ng-template>
            <div class="mt-3">
              <app-list-default-model-alarms></app-list-default-model-alarms>
            </div>
        </mat-tab>
        <!--
        <mat-tab>
            <ng-template mat-tab-label>
                {{'modules.notifications.notifications' | translate}}
            </ng-template>
            <div class="mt-3">
            </div>
        </mat-tab>-->
      </mat-tab-group>

    </app-block-dashboard>

  </app-block-group-dashboard>

<!--   <app-block-group-dashboard>

    <app-block-dashboard>
      <h2>{{'modules.users.users-list' | translate}}</h2>
      <app-list-users></app-list-users>
    </app-block-dashboard>

  </app-block-group-dashboard>
-->




</div>

<app-footer></app-footer>
