import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Location } from '@angular/common';
import { LinkModal, Modal } from '../models/modal';
import { MatDialog } from '@angular/material/dialog';
import { InfoModalComponent } from '../modals/info-modal/info-modal.component';
import { mapNameSensors, mapNameSensorsTranslations, sensors } from '../const/sensor-const';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class UtilsService {

    constructor(
            private http: HttpClient,
            public location : Location,
            public dialog: MatDialog,
            private translate: TranslateService
        ) {

        }


    ///////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////// INTERNS/////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////

    getClientIp(): Observable<any>  {
        return this.http.get<any>('https://api.ipify.org/?format=json');
    }

    getSensorAccomulative(): Array<string> {

      let listS = new Array<string>();

      sensors.forEach( element => {
        if(element.accomulative == true){
          listS.push(element.tag);
        }
      });

      return listS;
    }

    getNameSensors(): Array<string> {

      return this.valuesToArray(mapNameSensors);
      return [
        'RPM',
        'Horas trabajo',
        'Presión',
        'Caudal',
        'Temp. aceite',
        'Nivel aceite',
        'Nº acciones',
        'Peso',
        'RPM 2',
        'RPM 3',
        'Presión 2',
        'Presión 3',
        'Caudal 2',
        'Caudal 3',
        'Peso 2',
        'Nº acciones 2',
        'Nº acciones 3',
        'Aux1',
        'Aux2',
        'Aux3',
        'Aux4',
        'Aux5'
      ];
    }

    getNameSensorsTranslations(): Array<string> {

      return this.valuesToArray(mapNameSensorsTranslations);
    }

    getPermitNameSensors(): Array<string> {
      return this.keysToArray(mapNameSensors);
      return [
        'RPM',
        'WorkingHours',
        'Pressure1',
        'Pressure2',
        'Flow1',
        'Flow2',
        'Action1',
        'Action2',
        'Action3',
        'Action4',
        'Action5',
        'Action6',
        'Weight1',
        'Weight2',
        'Aux1',
        'Aux2',
        'Aux3',
        'Aux4',
        'Aux5',
        'Aux6',
        'Aux7',
        'Aux8'
      ];
    }

    /*
    mapNameSensors(): Map<string, string> {
      const sensors = new Map<string, string>();
      let i = 0;
      for (const name of this.getNameSensors()) {
        let x = 0;
        for (const name2 of this.getPermitNameSensors()) {
          if(i == x){
            sensors.set(name2,name);
            break;
          }

          x++;
        }     
        i++;
      }
      return sensors;
    }*/

    /**
     * Returns a random number between min (inclusive) and max (exclusive)
     */
    public getRandomArbitrary(min : number, max : number)  {
        return Math.random() * (max - min) + min;
    }

    /**
     * Returns a random integer between min (inclusive) and max (inclusive).
     * The value is no lower than min (or the next integer greater than min
     * if min isn't an integer) and no greater than max (or the next integer
     * lower than max if max isn't an integer).
     * Using Math.round() will give you a non-uniform distribution!
     */
    public getRandomInt(min : number, max : number) : number{
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    /*
    public toParams(obj ): HttpParams{
        let params: HttpParams = new HttpParams();
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                params.set(key, obj[key]);
        }
        return params;
     }*/

     /**
      * Devuelve la hora actual en uso UTC
      *
      * @returns {Date}
      * @memberof UtilsService
      */
     public UtcNow() : Date{
            return new Date(Date.now());
     }

     public DateNow():string{
        let date :Date
        date  = new Date();
        return date.toLocaleString().split(' ')[0];
     }

     public DateToString(date:Date):string{
        //console.log(date.toLocaleString().split(' ')[0] );
        return date.toLocaleString().split(' ')[0];
     }

     public FromSAPToDateString(d :Date):string{
        //console.log(d.toLocaleString());
        let s: string
        s = d.toLocaleString().split('T')[0];
        return s.split('-')[2] + "-" + s.split('-')[1] + "-"+s.split('-')[0];
     }


     /**
      * Convierte el formato de fecha de 2019-5-1 a  2019-05-01
      *
      * lo sé, lo sé... "'(¬_¬)
      *
      * @param {string} date
      * @returns
      * @memberof UtilsService
      */
     public TransformDateToHtmlInput(date:string){
        let d:string;
        let m:string;

        d = date.split("-")[2];
        if (d.length==1) d="0"+d;

        m = date.split("-")[1];
        if (m.length==1) m="0"+m;

        return date.split("-")[0]+"-"+m+"-"+d;
     }

     /**
      * Función para buscar un valor en un array en base a un patrón
      *
      * @param {*} arr
      * @param {*} regex
      * @returns
      * @memberof UtilsService
      */
     public searchFromArray(arr: string | any[], regex: any) {
        let matches = [], i;
        for (i = 0; i < arr.length; i++) {
          if (arr[i].match(regex)) {
            matches.push(arr[i]);
          }
        }
        return matches;
      };


    /**
     * Devuelve el número x con el número de decimales especificado
     *
     * @param {*} x
     * @param {*} scale
     * @returns
     * @memberof UtilsService
     */
    public Round(x: number, scale: any){
        return parseFloat(x.toFixed(scale));
    }



    public getAppDomain () : string {
      let domain : string = '';
      const angularRoute = this.location.path;
      const url = window.location.href;
      domain = url.replace(angularRoute.toString(), '');
      if (domain.endsWith('/')){
          domain = domain.slice(0, -1)
      }

      let start = "";

      if(domain.includes("http://")){
        start = "http://";
      }else if(domain.includes("https://")){
        start = "https://";
      }
      domain = domain.replace(start,"");

      let domainArray: string[];
      domainArray = domain.split("/");

      return start + domainArray[0];
  }

    public dec2hex (dec: any) {
        return dec.toString(16).padStart(2, "0")
      }

    public generateId (len: any) : string {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
    }



  public DatatablesFileLang() {
    let lang = localStorage.getItem("lang")
    return `assets/i18n/${lang}-datatables.json`;
  }

  public hexToRGB(hex: string, alpha: number) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }



  public infoModal(msg : string, link?: LinkModal, title?: string){
    let modal = new Modal();
    modal.Title = title??this.translate.instant('modules.shared.warning');
    modal.Body = msg
    modal.BtnAccept = this.translate.instant('modules.modals.continue');
    modal.ClassType = "danger";
    modal.Link = link??undefined

    const dialogRef = this.dialog.open(
      InfoModalComponent,
      {
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: modal,
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'
    });
  }

  valuesToArray<K, V>(map: Map<K, V>): Array<V> {
    const values = [];
    for (const [_, value] of map) {
      values.push(value);
    }
    return values;
  }

  keysToArray<K, V>(map: Map<K, V>): Array<K> {
    const keys = [];
    for (const [key, _] of map) {
      keys.push(key);
    }
    return keys;
  }
  
  public calcularHorasYMinutos(numero: number) {
    // Obtén las horas enteras (parte entera del número)
    const horas = Math.floor(numero);
  
    // Obtén los minutos a partir de la parte decimal del número
    const minutosDecimal = numero - horas;
    const minutos = Math.round(minutosDecimal * 60); // Convierte el decimal a minutos
  
    return { horas: horas, minutos: minutos };
  }

  public  getPropertyValue< T extends keyof typeof Object>(   objectName: T,  propertyName: keyof typeof Object[T]): any {
    console.log(Object[objectName][propertyName]);
    return Object[objectName][propertyName];
  }

}
