<app-breadcrumb></app-breadcrumb>


<div class="main-page container-fluid pt-3 pl-0 pr-0 espace">

  <app-loading-custom [loading]="loading"></app-loading-custom>
  <app-block-group-dashboard *ngIf="loading == false">

    <app-detail-public-user *ngIf="user" [user]="user"></app-detail-public-user>

  </app-block-group-dashboard>




</div>


<app-footer></app-footer>
