<app-breadcrumb></app-breadcrumb>

<div class="container-fluid pt-3 pl-0 pr-0 espace">
  <app-block-group-dashboard>
      <div class="col-12">
        <div class="my-4">
          <div class="row align-items-center">
            <div class="col-xxl-8">
              <h1><b>{{'modules.devices.load-config' | translate}}</b></h1>
            </div>

          </div>
        </div>
      </div>
    </app-block-group-dashboard>


    <app-block-group-dashboard>

    <app-block-dashboard>
      <app-load-configuration *ngIf="!loading" [ids]="ids"></app-load-configuration>

    </app-block-dashboard>

  </app-block-group-dashboard>




</div>


<app-footer></app-footer>
