
<app-loading-custom [loading]="loading"></app-loading-custom>

<ng-container *ngIf="loading == false">
    <form class="text-start p-2" [formGroup]="addForm" (ngSubmit)="onSubmit()" autocomplete="off" >

        <mat-card fxFlex fxLayoutAlign="start center" fxLayout="column" class="solution"  formGroupName="Media" [style.borderRadius.px]="'0'"  [style.paddingTop.px]="'20'" [style.marginBottom.px]="'20'">
            
            <div class="form-group">
                <div *ngIf="(!this.currentFile ||  !this.currentFile!.Id)"  class="alert alert-warning mt-3 text-start">
                    <div *ngIf="parentFolder">
                        Este documento se creara dentro del siguiente directorio: {{ parentFolder.Name }}.
                    </div>                
                    <div *ngIf="!parentFolder">
                        Este documento se creara en el directorio raíz.
                    </div>   
                </div>
            </div>
            <div class="form-group" *ngIf="showInput ">
                                
                <input type="file" class="file-input"
                [accept]="requiredFileType"
                (change)="onFileSelected($event)" #fileUpload>

                <div class="file-upload">

                {{fileName || 'Seleccionar documento' }}

                <button mat-mini-fab color="primary" [hidden] class="upload-btn" (click)="fileUpload.click()">
                <mat-icon>attach_file</mat-icon>

                </button>

                </div>

                <div  *ngIf="uploadProgress" class="progress mt-2">
                    <mat-progress-bar *ngIf="uploadProgress" class="progress-bar" mode="determinate" [value]="uploadProgress" >

                    </mat-progress-bar>
                </div>

            </div>
            <div class="form-group">           

                <label for="Name">{{'modules.tables.name' | translate}} <i #tooltip="matTooltip" class="fas fa-circle-question fa-md m-2 c-pointer" [matTooltipTouchGestures]="'on'" matTooltip="Nombre directorio"
                  matTooltipClass="tooltip-class" (click)="tooltip.show()" ></i></label>
                <input type="text" class="form-control" id="Name"  formControlName="Name" required [(ngModel)]="newDocManagerFile.Name">

                    <!-- <div [hidden]="Name!.valid || Name!.pristine" class="alert alert-danger">
                        {{'modules.shared.name-required' | translate}}.
                    </div> -->
                    <div *ngIf="Name!.invalid && (Name!.dirty || Name!.touched)" class="alert alert-danger mt-3 text-start">
                        <div *ngIf="Name!.errors?.['required']">
                            {{'modules.shared.name-required' | translate}}.
                        </div>
                        <div *ngIf="Name!.errors?.['minlength']">
                            {{'modules.shared.min-characters' | translate}}.
                        </div>
                        <div *ngIf="Name!.errors?.['maxlength']">
                            {{'modules.shared.max-characters' | translate}}.
                        </div>
                        <div *ngIf="Name!.errors?.['pattern']">
                            {{'modules.shared.num-not-allowed' | translate}}.
                        </div>
                    </div>
            </div>


            <div class="form-group">

                <label for="role">Documento accesible para:</label>
                    <!--Using items input-->
                    <ng-select [items]="rolesList"
                    (change)="changeRole($event)"
                    bindLabel="InternName"                    
                    [clearable]="false"
                    [searchable]="false"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="newDocManagerFile.Role">
                    </ng-select>
            </div>
        
            
            <div class="form-group">
                <label for="Active">Documento visible</label>
                <div style="display:inline-block; width: 20px;"></div>
                <mat-slide-toggle  color="primary" formControlName="IsVisible" [(ngModel)]="newDocManagerFile!.IsVisible" ></mat-slide-toggle>
        
            </div>

            <div class="form-group">
                <label for="Active">Documento público</label>
                <div style="display:inline-block; width: 20px;"></div>
                <mat-slide-toggle  color="primary" formControlName="IsPublic" [(ngModel)]="newDocManagerFile!.IsPublic" ></mat-slide-toggle>
        
            </div>
            <div class="row mt-2">
                <ng-multiselect-dropdown
                    id="clients"
                    formControlName="SelectedClientsList"
                    [(ngModel)]="selectedClientsList"
                    [placeholder]="'modules.users.users' | translate"
                    [settings]="dropdownClients"
                    [data]="clientsList">
                    <!-- <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
                    <div style="padding:1px 10px;margin-right:10px;right:2px;top:-2px;cursor: pointer !important;">
                        <label style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
                        {{option}}
                        </label>
                    </div>
                    </ng-template>
                    <ng-template #optionSelectedTemplate let-option="option" let-id="id">
                    <div>
                        {{option}}
                    </div>
                    </ng-template> -->
                </ng-multiselect-dropdown>
            </div>


        </mat-card>



        <section class="mt-2">
            <div class="text-right" style="flex-direction: row-reverse;">
                <button *ngIf="(!this.currentFile ||  !this.currentFile!.Id)"  mat-raised-button class="btn btn-primary" [disabled]="!addForm.get('Media')?.valid || fileName==''"  (click)="onSubmit()">{{'modules.shared.create' | translate}}</button>
                <button *ngIf="(this.currentFile &&  this.currentFile!.Id)"  mat-raised-button class="btn btn-primary" [disabled]="!addForm.get('Media')?.valid"  (click)="onSubmit()">Guardar</button>
                <button *ngIf="showButtonCancel" mat-raised-button class="btn btn-danger" type="button" style="margin-right: 10px;" (click)="cancel()">{{'modules.shared.cancel' | translate}}</button>
            </div>
        </section>
    </form>

</ng-container>