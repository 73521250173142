import { DateTime } from "luxon";
import { AlarmDeviceModelDefaultValues } from "./alarms";
import { Manufacturer, User } from "./user";
import { Trip } from "./trip";
import { cloneDeep } from 'lodash';

export class Device {
  Id?: number;
  IdUser?: number | any;
  IdModel?: number;
  User?: User;
  Fabricant?: User;
  IMEI?: string;
  Name?: string;
  ICCID?: string;
  Active?: boolean;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  NumSerieManufacturer?: string;
  Comments?: string;

  DeviceModel?: vDeviceModel | any;
  Client?: User | any;
  Manufacturer?: Manufacturer | any;
  nameSelector?: string;

  Informations?: Array<Information>;
  InformationFirst?: Date;
  InformationLast?: Date;
  InformationSocketLast?: Date;
  InformationsCount?: number;

  LatitudeLast?: number;
  LongitudeLast?: number;

  Trips?: Array<Trip>;


  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
    this.nameSelector = this.Name??'' + ' - ' + this.IMEI??'' + ' | ' + this.DeviceModel!.Name??'' + ' - ' + this.DeviceModel!.NameManufacturer??'' ;


  }

  public clearInformations(){
    this.Informations = new Array<Information>();
  }

  copyOf(dev: Device) {
    if (dev.Id !== undefined) this.Id = dev.Id;
    if (dev.IdUser !== undefined) this.IdUser = dev.IdUser;
    if (dev.IdModel !== undefined) this.IdModel = dev.IdModel;
    if (dev.User !== undefined) this.User = dev.User;
    if (dev.Fabricant !== undefined) this.Fabricant = dev.Fabricant;
    if (dev.IMEI !== undefined) this.IMEI = dev.IMEI;
    if (dev.Name !== undefined) this.Name = dev.Name;
    if (dev.ICCID !== undefined) this.ICCID = dev.ICCID;
    if (dev.Active !== undefined) this.Active = dev.Active;
    if (dev.CreatedAt !== undefined) this.CreatedAt = dev.CreatedAt;
    if (dev.UpdatedAt !== undefined) this.UpdatedAt = dev.UpdatedAt;
    if (dev.NumSerieManufacturer !== undefined) this.NumSerieManufacturer = dev.NumSerieManufacturer;
    if (dev.Comments !== undefined) this.Comments = dev.Comments;
    if (dev.DeviceModel !== undefined) this.DeviceModel = dev.DeviceModel;


    this.nameSelector = this.Name??'' + ' - ' + this.IMEI??'' + ' | ' + this.DeviceModel!.Name??'' + ' - ' + this.DeviceModel!.NameManufacturer??'' ;
    // Copiar otros atributos aquí

    // Si deseas copiar arreglos, debes hacerlo por separado

    // Copiar la matriz Informations
    //if (dev.Informations !== undefined) this.Informations = [...dev.Informations];

    // Copiar la matriz Trips
    //if (dev.Trips !== undefined) this.Trips = [...dev.Trips];
  }

}

export class Information {
  [x: string]: any;
  Id?: number;
  IdDevice?: number | any;
  IMEI?: string;
  RPM?: number;
  WorkingHours?: number;
  Pressure1?: number;
  Pressure2?: number;
  Flow1?: number;
  Flow2?: number;
  Action1?: number;
  Action2?: number;
  Action3?: number;
  Action4?: number;
  Action5?: number;
  Action6?: number;
  Weight1?: number;
  Weight2?: number;
  Aux1?: number;
  Aux2?: number;
  Aux3?: number;
  Aux4?: number;
  Aux5?: number;
  Aux6?: number;
  Aux7?: number;
  Aux8?: number;
  Ip?: string;
  Trace?: string;
  IdPosition?: number;
  TrackPosition?: Position;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

  toArray(){

  }

  getBytag(tag: string){
    return this[tag as keyof typeof this];
  }

}

export class Position {
  Id?:                 number;
  Valid?:              string;
  Latitude?:           number;
  LatitudeDirection?:  string;
  Longitude?:          number;
  LongitudeDirection?: string;
  Speed?:              number;
  Degree?:             number;
  MagneticVariation?:  number;
  MagneticDirection?:  number;
  Cheksum?:            number | string;
  Date?:               Date;
  CreatedAt?:          Date;
  Processed?:          boolean;
  LatitudeWGS84?:      number;
  LongitudeWGS84?:     number;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}

export class InformationChart{
  RPM?: Array<number> | any;
  WorkingHours?: Array<number> | any;
  Pressure1?: Array<number> | any;
  Pressure2?: Array<number> | any;
  Flow1?: Array<number> | any;
  Flow2?: Array<number> | any;
  Action1?: Array<number> | any;
  Action2?: Array<number> | any;
  Action3?: Array<number> | any;
  Action4?: Array<number> | any;
  Action5?: Array<number> | any;
  Action6?: Array<number> | any;
  Weight1?: Array<number> | any;
  Weight2?: Array<number> | any;
  Aux1?: Array<number> | any;
  Aux2?: Array<number> | any;
  Aux3?: Array<number> | any;
  Aux4?: Array<number> | any;
  Aux5?: Array<number> | any;
  Aux6?: Array<number> | any;
  Aux7?: Array<number> | any;
  Aux8?: Array<number> | any;
  Dates?: Array<DateTime> | any;
}

export class vDeviceModel {
  Id?: number;
  Name?: string;
  IdManufacturer?: number;
  NameManufacturer?: string;
  Manufacturer?: Manufacturer | any;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  Selected?: boolean = false;
  NumDevices?: number;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

}



export class vDevice {
  Id?: number;
  IdUser?: number;
  User?: User;
  Name?: string;
  IMEI?: string;
  ICCID?: string;
  IdModel?: number;
  DeviceModel?: string;
  NameDeviceModel?: string;
  IdManufacturer?: number;
  NameManufacturer?: string;
  NameClient?: string;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  Selected?: boolean = false;
  InformationFirst?: Date;
  InformationLast?: Date;
  FirmwareVersion?: string;
  NumSerieManufacturer?: string;
  Comments?: string;
  InformationSocketLast?: Date;
  OnlineDate?: Date;
  LatitudeLast?: number;
  LongitudeLast?: number;
  //NameSelector?: string;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }


  get NameSelector() : string{
    return this?.Name??'' + ' ' + this?.IMEI??'' + ' ' + this?.NameManufacturer??'' + ' ' + this?.NameDeviceModel ;
  }


  get IsOnline(): boolean{

    if(this.InformationLast == null){
      return false;
    }
    let currentDate:  DateTime= DateTime.now();
    let date: DateTime = DateTime.fromJSDate(new Date(this.InformationLast));
    return (currentDate.diff(date).as('minutes') > 15)?false:true;
  }

  get onlineState(): number{

    if(this.InformationLast == null){
      return 0;
    }
    let currentDate:  DateTime= DateTime.now();
    let date: DateTime = DateTime.fromJSDate(new Date(this.InformationLast));
    if((currentDate.diff(date).as('minutes') < 10)){
      return 1;
    }

    if(this.InformationSocketLast == null){
      return 0;
    }
    let socket: DateTime = DateTime.fromJSDate(new Date(this.InformationSocketLast));
    if((currentDate.diff(socket).as('minutes') < 10)){
      return 2;
    }
    return 0;

  }


}

export class DeviceModel {
  Id?: number;
  IdUser?: number;
  Name?: string;
  Manufacturer?:Manufacturer;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DefaultAlarmConfig?: AlarmDeviceModelDefaultValues;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

}
