import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlarmDeviceModelDefaultValues, AlarmValue } from 'src/app/models/alarms';
import { DeviceModel } from 'src/app/models/device';
import { AlarmsService } from 'src/app/services/alarms.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { DevicesService } from 'src/app/services/devices.service';
import { UsersService } from 'src/app/services/users.services';

@Component({
  selector: 'app-detail-default-values-alarm-page',
  templateUrl: './detail-default-values-alarm-page.component.html',
  styleUrls: ['./detail-default-values-alarm-page.component.scss']
})
export class DetailDefaultValuesAlarmPageComponent implements OnInit {

  id?: number;
  loading:boolean = true;
  idModel?: number;
  information?: AlarmDeviceModelDefaultValues;
  title: string = "";
  showChangeModel= true;
  showDeleteButton: boolean = false;


  constructor(private breadcrumbService: BreadcrumbService,
    public authSrv: AuthenticationService,
    private deviceSrv: DevicesService,
    private alarmrSrv : AlarmsService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService) {
      this.title = this.translate.instant('modules.alarms.management-default-value')
      breadcrumbService.setBreadCrumbUrl('alarms/list', this.title );

      this.route.params.subscribe(params => {
        this.id = params['id'];
        let auxId = params['idAlarm'];

        console.log(this.id)
        if(this.id && (auxId == null || (auxId != null && auxId != "add" ))){

          if(auxId){
            this.idModel = this.id;
            this.id = auxId;
          }

          this.loadValues(this.id! );

        }else{

          if(auxId){
            this.idModel = this.id;
          }

          this.id = 0;
          this.information = new AlarmDeviceModelDefaultValues();
          this.information.defaultValues();



          if(this.idModel){

            this.loadModel(this.idModel);
          }else{
            this.breadcrumbService.setBreadCrumbUrl('alarms/list', this.translate.instant('modules.alarms.management'));
            this.title = this.translate.instant('modules.alarms.new-default-config');
            this.breadcrumbService.addBreadCrumb('alarms/default/add', this.title );

          }


          console.log(this.information);
          this.loading = false;
          console.log(this.loading);
        }

      });
    }

  ngOnInit(): void {

  }

  loadValues(id: number){
    this.loading = true;
    this.information = undefined;
    console.log('load information: ' , id );
    this.alarmrSrv.getDefaultValuesById(id).subscribe({
      next: (res: AlarmDeviceModelDefaultValues) => {
        this.information = new AlarmDeviceModelDefaultValues(<AlarmDeviceModelDefaultValues>res);
        this.breadcrumbService.setBreadCrumbUrl('alarms/list', this.translate.instant('modules.alarms.management'));
        this.title = this.translate.instant('modules.alarms.default-values-for-model') + ' ' + this.information!.DeviceModel!.Name??'';
        this.breadcrumbService.addBreadCrumb('alarms/default/' + this.information!.Id  ,this.title  );


      }, error: ()=> {
        this.loading = false;

      },
      complete: ()=>{
        this.loading = false;

        console.log(this.information);
      }
    });
  }

  loadModel(id: number){
    this.loading = true;
    console.log('load model: ' , id );

    this.deviceSrv.getModelById(id).subscribe({
      next: (res: DeviceModel) => {
        this.information!.DeviceModel = new DeviceModel(<DeviceModel>res);
        this.information!.IdModel = this.information!.DeviceModel.Id;


        this.breadcrumbService.setBreadCrumbUrl('devices/list', this.translate.instant('modules.devices.management'));
        this.breadcrumbService.addBreadCrumb('devices/models/' + this.information!.DeviceModel!.Id + '/detail' , this.translate.instant('modules.alarms.model') + ' ' + this.information!.DeviceModel!.Name );
        this.title = this.translate.instant('modules.alarms.default-values-model') + ' ' + this.information!.DeviceModel!.Name??'';
        this.breadcrumbService.addBreadCrumb('devices/models/' + this.information!.DeviceModel!.Id + '/alarms/default/' + (this.information!.Id==0?'add':this.information!.Id)  ,this.title  );
        this.showChangeModel = false;


      }, error: ()=> {
        this.loading = false;

      },
      complete: ()=>{
        this.loading = false;

      }
    });
  }


  deleteAlarm(){
    console.log("deleteAlarm");
    this.alarmrSrv.deleteByModelDefault(this.information!).subscribe(
      {
        next: (res: boolean) => {
          if(res==true){
            if(this.idModel){
              this.router.navigate(['/devices/models/' + this.idModel + '/detail/alarmas']);
            }else{
              this.router.navigate(['/alarms/list']);
            }
          }
        }
      }
    )
  }

}
