import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalComponent } from 'src/app/modals/confirmation-modal/confirmation-modal.component';
import { Modal } from 'src/app/models/modal';

@Component({
  selector: 'app-generic-button',
  templateUrl: './generic-button.component.html',
  styleUrls: ['./generic-button.component.scss']
})
export class GenericButtonComponent implements OnInit {


  @Input() txtBtn: string = this.translate.instant('modules.shared.delete');
  @Input() title: string = "";
  @Input() disabled: string | null = null;

  @Input() btnAccept: string = this.translate.instant('modules.shared.delete');
  @Input() classType: string = "danger";
  @Input() hasModal: boolean = true;

  @Input() txt: string = "";
  @Output() confirmDelete = new EventEmitter<void>();

  constructor(
    public dialog: MatDialog,
    private router : Router,
    private translate: TranslateService) { }

  ngOnInit(): void {
  }


  click(){

    if(this.hasModal == true){
      let modal = new Modal();
      modal.Title = this.title??this.translate.instant('modules.shared.warning');
      modal.Body = this.txt;
      modal.BtnAccept = this.btnAccept;
      modal.BtnCancel = this.translate.instant('modules.shared.cancel');
      modal.ClassType = "this.ClassType";

      const dialogRef = this.dialog.open(
        ConfirmationModalComponent,
        {
          autoFocus : 'dialog',
          width: "auto",
          minWidth: '600px',
          data: modal,
          closeOnNavigation: true,
          disableClose: false,
          panelClass: 'custom-modalbox'
      });


      dialogRef.afterClosed().subscribe( (result: boolean) => {
        if(result){
          this.confirmDelete.next();
        }
      });
    }else{
      this.confirmDelete.next();
    }
  }


}
