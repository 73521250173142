
<app-breadcrumb></app-breadcrumb>

<div class="container-fluid pt-3 pl-0 pr-0 espace">

  <app-loading-custom [loading]="loading"></app-loading-custom>


  <app-block-group-dashboard *ngIf="loading == false">

    <div class="my-4">
      <div class="row align-items-center">
        <div class="col-8">
          <h1><b>{{ 'modules.trips.split-trip' | translate }}: {{title}}</b></h1>
        </div>
        <div class="col-4 text-end" >
          <app-generic-button [hasModal]="false" txtBtn="{{ 'modules.trips.change-split-mode' | translate }}" classType="warning"
          (confirmDelete)="changeMode()" ></app-generic-button>
        </div>
   

      </div>
    </div>



    <app-block-dashboard>

      <app-split-trip *ngIf="trip && typeSplit == 0" [trip]="trip" [hasDevicePage]="true"   ></app-split-trip>
      <app-split-map-trip *ngIf="trip && typeSplit == 1" [trip]="trip"  [hasDevicePage]="true"  ></app-split-map-trip>

    </app-block-dashboard>

  </app-block-group-dashboard>


</div>


<app-footer></app-footer>
