export const environment = {
  production: false,
  BASE_URL: 'http://test.batsicloud.eu',
  API_ENDPOINT: 'http://test.batsicloud.eu/api/',
  envName: "pre",
  mailFrom: "rorellana@actiumdigital.es",
  urlRememberPassword: "http://test.batsicloud.eu/auth/token?token={0}",
  dirImages: "Images",
  NameDeviceModelMain: "Sin asignar",
  SOCKET_SERVICE: "http://test.batsicloud.eu:10002",
  //SOCKET_SERVICE: "localhost:3000"
  showTrips: true,
  showDocManager: true,
  sensorTag: [
    'RPM',
    'WorkingHours',
    'Pressure1',
    'Pressure2',
    'Flow1',
    'Flow2',
    'Action1',
    'Action2',
    'Action3',
    'Action4',
    'Action5',
    'Action6',
    'Weight1',
    'Weight2',
    'Aux1',
    'Aux2',
    'Aux3',
    'Aux4',
    'Aux5',
    'Aux6',
    'Aux7',
    'Aux8'
  ]
};
