import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeviceEditQuickComponent } from 'src/app/modals/device-edit-quick/device-edit-quick.component';
import { Device, Information, vDevice } from 'src/app/models/device';
import { Modal } from 'src/app/models/modal';

@Component({
  selector: 'app-configuration-detail-device',
  templateUrl: './configuration-detail-device.component.html',
  styleUrls: ['./configuration-detail-device.component.scss']
})
export class ConfigurationDetailDeviceComponent implements OnInit {

  @Input() device?: Device;
  @Input() information: Information | null = null;

  constructor(
    public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  get urlGoogleMaps(): string{
    // 'https://www.google.com/maps/search/?api=1&query=' + information!.TrackPosition!.LatitudeWGS84! + ',' + information!.TrackPosition!.LongitudeWGS84! + '&z=16&t=k&hl=es-ES&gl=ES&mapclient=apiv3)'
    if(this.information){
      return "http://maps.google.com/maps?t=k&q=loc:" + this.latitudeWGS84!+","+ this.longitudeWGS84!+ "&ll=" + this.latitudeWGS84!+","+ this.longitudeWGS84! + "";
    }
    return "";

  }




  /**
   * Cambiamos el signo cuando el GMT pasa de 0  a -1
   */
   get longitudeWGS84(): number | null{
    if(this.information?.TrackPosition?.LongitudeWGS84){
      if( this.information?.TrackPosition?.LongitudeDirection == 'E'){
        return this.information?.TrackPosition?.LongitudeWGS84;
      }
      if(this.information?.TrackPosition?.LongitudeWGS84 < 0){
        return this.information?.TrackPosition?.LongitudeWGS84;
      }
      return 0-this.information?.TrackPosition?.LongitudeWGS84;
    }

    return null;
  }

  /**
   * Cambiamos el signo cuando el la posicio esta por denajo del ecuador.
   */
  get latitudeWGS84(): number | null{
    if(this.information?.TrackPosition?.LatitudeWGS84){
      if( this.information?.TrackPosition?.LatitudeDirection == 'N'){
        return this.information?.TrackPosition?.LatitudeWGS84;
      }
      if(this.information?.TrackPosition?.LatitudeWGS84<0){
        return this.information?.TrackPosition?.LatitudeWGS84;
      }
      return 0-this.information?.TrackPosition?.LatitudeWGS84;
    }

    return null;
  }
}
