import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListTripsPageComponent } from './pages/list-trips-page/list-trips-page.component';
import { DetailTripPageComponent } from './pages/detail-trip-page/detail-trip-page.component';
import { DetailTripComponent } from './components/detail-trip/detail-trip.component';
import { ListTripsComponent } from './components/list-trips/list-trips.component';
import { GOOGLE_MAPS_API_CONFIG, NgMapsGoogleModule } from '@ng-maps/google';
import { MaterialCdkModule } from '../material-cdk/material-cdk.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TripsRoutingModule } from './trips-routing.module';
import { DataTablesModule } from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ChartsModule } from '../charts/charts.module';
import { NgMapsCoreModule } from '@ng-maps/core';
import { AgmCoreModule } from '@agm/core';
import { NewTripPageComponent } from './pages/new-trip-page/new-trip-page.component';
import { NewTripComponent } from './components/new-trip/new-trip.component';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { SplitTripComponent } from './components/split-trip/split-trip.component';
import { SplitTripPageComponent } from './pages/split-trip-page/split-trip-page.component';
import { DetailJoinTripPageComponent } from './pages/detail-join-trip-page/detail-join-trip-page.component';
import { DetailJoinTripComponent } from './components/detail-join-trip/detail-join-trip.component';
import { SplitMapTripComponent } from './components/split-map-trip/split-map-trip.component';
import { OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker-moment-adapter';
//import { NgxMaterialTimelineModule } from 'ngx-material-timeline';

export class DefaultIntl extends OwlDateTimeIntl {
  /** A label for the cancel button */
  override cancelBtnLabel= 'X'

  /** A label for the set button */
  override setBtnLabel= '✓'
};

export const MY_MOMENT_FORMATS = {
  parseInput: 'yyyy-MM-DDTHH:mm',
  fullPickerInput: 'DD/MM/YYYY HH:mm',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'HH:mm',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  declarations: [
    ListTripsPageComponent,
    DetailTripPageComponent,
    DetailTripComponent,
    ListTripsComponent,
    NewTripPageComponent,
    NewTripComponent,
    SplitTripComponent,
    SplitTripPageComponent,
    DetailJoinTripPageComponent,
    DetailJoinTripComponent,
    SplitMapTripComponent
  ],
  imports: [
    CommonModule,
    MaterialCdkModule,
    CoreModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TripsRoutingModule,
    DataTablesModule,
    NgMultiSelectDropDownModule,
    ChartsModule,
    NgMapsCoreModule,
    NgMapsGoogleModule,
    AgmCoreModule,
    //NgxMaterialTimelineModule ,
    MatLuxonDateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule
  ],

  exports:[
    DetailTripComponent,
    ListTripsComponent,
    SplitTripComponent,
    SplitMapTripComponent
  ],
  providers: [

    {
      provide: GOOGLE_MAPS_API_CONFIG,
      useValue: {
        apiKey: 'AIzaSyBoksiGlAZxushSb9vuGN4SNYGrMuTsFSk'
      }
    },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'es'},
  ]
})
export class TripsModule { }
