

<div class="page w-100">

  <div class="group col-12 col-xl-12 col-lg-12">

    <div class="my-4">
      <div class="row align-items-center">
        <div class="col-xl-8" *ngIf="hasAggregateView">
          <h1><b>{{'modules.trips.aggregate-view-trip-2-1' | translate}} {{ trip.Id }} {{'modules.trips.aggregate-view-trip-2-2' | translate}} {{ trip.Id2 }}</b> </h1>
        </div>
        <div class="col-xl-8" *ngIf="!hasAggregateView">
          <h1><b>{{'modules.trips.trip-detail' | translate}} {{ trip.Id }}</b> </h1>
        </div>
        <div class="col-xl-4 text-xl-end" *ngIf="!hasAggregateView">
           
          <!-- <div *ngIf="isAdmin && device.IdUser != authSrv.currentUserValue!.UserId" class="btn btn-primary m-2" [routerLink]="['/devices/' + device!.Id  + '/detail']">
            Editar dispositivo
          </div>-->

          <div  class="btn btn-primary m-2" (click)="goToDevice()" >
            {{ 'modules.trips.back-device' | translate }}
          </div>

          <app-generic-button [hasModal]="false" txtBtn="{{'modules.trips.split' | translate}}" classType="warning"
          (confirmDelete)="splitTrip()" ></app-generic-button>
          <app-generic-button title="{{'modules.shared.warning' | translate}}" txt="{{'modules.modals.delete-current-trip' | translate}}" classType="danger"
          (confirmDelete)="deleteTrip()" ></app-generic-button>

        </div>
        <div class="col-xl-4 text-xl-end" *ngIf="hasAggregateView">
        
            <app-generic-button [hasModal]="false" txtBtn="{{'modules.modals.join' | translate}}" classType="warning"
            (confirmDelete)="joinTrip()" ></app-generic-button> 
        </div>
      </div>
    </div>

    <app-block-dashboard>
      <app-detail-trip  [trip]="trip" [centralPoint]="[positions[0].TrackPosition!.LatitudeWGS84!, positions[0].TrackPosition!.LongitudeWGS84!]" [positions]="positions"></app-detail-trip>

    </app-block-dashboard>
  </div>
</div>