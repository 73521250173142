
<app-loading-custom [loading]="loading"></app-loading-custom>

<ng-container *ngIf="loading == false">


<form class="text-start p-2" [formGroup]="addForm" (ngSubmit)="onSubmit()" autocomplete="off" >

<mat-card fxFlex fxLayoutAlign="start center" fxLayout="column" class="solution" *ngIf="authSrv.isAdmin() == true && rolInput == null"
    [style.borderRadius.px]="'0'"  [style.paddingTop.px]="'20'" [style.marginBottom.px]="'20'">
    <div class="form-group">

        <label for="role">{{'modules.users.user-type' | translate}}</label>
            <!--Using items input-->
            <ng-select [items]="rolesList"
            bindLabel="InternName"
            (change)="changeRole()"
            [clearable]="false"
            [searchable]="false"
            [ngModelOptions]="{standalone: true}"
            [(ngModel)]="selectedRole">
            </ng-select>
    </div>


</mat-card>


<h2 class="text-center">{{'modules.users.general-info' | translate}} - {{ selectedRole!.InternName! }}</h2>



<mat-card fxFlex fxLayoutAlign="start center" fxLayout="column" class="solution"
    [style.borderRadius.px]="'0'"  [style.paddingTop.px]="'20'" [style.marginBottom.px]="'20'" formGroupName="User">

    <div class="form-group text-start" *ngIf="selectedRole!.Tag! == 'user'">
        <label for="icon_solid">{{'modules.users.client-type' | translate}}</label>

        <div class="container">
            <div class="pretty p-icon p-round">
                <input type="radio" formControlName="IsCompany"  [value]="false"  required [(ngModel)]="newUser.IsCompany"
                (change)="changeCompany()" />
                <div class="state p-primary  text-start">
                    <i class="icon mdi mdi-check"></i>
                    <label>{{'modules.users.private-client' | translate}}</label>
                </div>
            </div>

            <div class="pretty p-icon p-round">
                <input type="radio" formControlName="IsCompany" [value]="true"  required [(ngModel)]="newUser.IsCompany"
                (change)="changeCompany()" />
                <div class="state p-success">
                    <i class="icon mdi mdi-check"></i>
                    <label>{{'modules.users.company-client' | translate}}</label>
                </div>
            </div>
        </div>
    </div>

            <div class="form-group">
                <label for="Name">{{'modules.tables.name' | translate}} <i #tooltip="matTooltip" class="fas fa-circle-question fa-md m-2 c-pointer" [matTooltipTouchGestures]="'on'" matTooltip="Nombre del cliente"
                  matTooltipClass="tooltip-class" (click)="tooltip.show()" ></i></label>
                <input type="text" class="form-control" id="Name"  formControlName="Name" required [(ngModel)]="newUser.Name">

                    <!-- <div [hidden]="Name!.valid || Name!.pristine" class="alert alert-danger">
                        {{'modules.shared.name-required' | translate}}.
                    </div> -->
                    <div *ngIf="Name!.invalid && (Name!.dirty || Name!.touched)" class="alert alert-danger mt-3 text-start">
                        <div *ngIf="Name!.errors?.['required']">
                            {{'modules.shared.name-required' | translate}}.
                        </div>
                        <div *ngIf="Name!.errors?.['minlength']">
                            {{'modules.shared.min-characters' | translate}}.
                        </div>
                        <div *ngIf="Name!.errors?.['maxlength']">
                            {{'modules.shared.max-characters' | translate}}.
                        </div>
                        <div *ngIf="Name!.errors?.['pattern']">
                            {{'modules.shared.num-not-allowed' | translate}}.
                        </div>
                    </div>
            </div>

            <div class="form-group"  *ngIf="selectedRole!.Tag! == 'admin' || (selectedRole!.Tag! == 'user' && newUser!.IsCompany! == false)">
                <label for="FirstName">{{'modules.tables.first-name' | translate}}</label>
                <input type="text" class="form-control" id="FirstName"  formControlName="FirstName" required
                    [(ngModel)]="newUser.FirstName">

                    <div *ngIf="FirstName!.invalid && (FirstName!.dirty || FirstName!.touched)" class="alert alert-danger mt-3 text-start">
                       <div [hidden]="FirstName!.errors?.['required']" >
                          {{'modules.shared.first-name-required' | translate}}.
                      </div>
                    </div>

              </div>

              <div class="form-group"  *ngIf="selectedRole!.Tag! == 'admin' || (selectedRole!.Tag! == 'user' && newUser!.IsCompany! == false)">
                <label for="LastName">{{'modules.tables.last-name' | translate}}</label>
                  <input type="text" class="form-control" id="LastName"  formControlName="LastName" required
                      [(ngModel)]="newUser.LastName">

                      <div *ngIf="LastName!.invalid && (LastName!.dirty || LastName!.touched)" class="alert alert-danger mt-3 text-start">

                        <div [hidden]="LastName!.errors?.['required']">
                            {{'modules.shared.last-name-required' | translate}}.
                        </div>
                      </div>
              </div>

              <div class="form-group text-start" *ngIf="selectedRole!.Tag! == 'user' || selectedRole!.Tag! == 'fabricant'">
                <label for="TypeDocument">{{'modules.users.type-document' | translate}}</label>

                <div class="container">
                    <div class="pretty p-icon p-round">
                        <input type="radio" formControlName="TypeDocument"  [value]="false"  required [(ngModel)]="newUser.TypeDocument"
                         />
                        <div class="state p-primary  text-start">
                            <i class="icon mdi mdi-check"></i>
                            <label>DNI</label>
                        </div>
                    </div>

                    <div class="pretty p-icon p-round">
                        <input type="radio" formControlName="TypeDocument" [value]="true"  required [(ngModel)]="newUser.TypeDocument"
                         />
                        <div class="state p-success">
                            <i class="icon mdi mdi-check"></i>
                            <label>NIF</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="selectedRole!.Tag! == 'user' || selectedRole!.Tag! == 'fabricant'">
                <label *ngIf="newUser.TypeDocument == false" for="DNICIF">DNI</label>
                <label  *ngIf="newUser.TypeDocument == true" for="DNICIF">NIF</label>
                <input type="text" class="form-control" id="DNICIF"  formControlName="DNICIF" required [(ngModel)]="newUser.DNICIF">

                    <!-- <div [hidden]="Name!.valid || Name!.pristine" class="alert alert-danger">
                        {{'modules.shared.name-required' | translate}}.
                    </div> -->
                    <div *ngIf="DNICIF!.invalid && (DNICIF!.dirty || DNICIF!.touched)" class="alert alert-danger mt-3 text-start">
                        <div [hidden]="(!DNICIF!.errors?.['required'])">
                            <ng-container *ngIf="newUser.TypeDocument == false">
                                {{'modules.shared.dni-required' | translate}}.
                            </ng-container>
                            <ng-container *ngIf="newUser.TypeDocument == true">
                                {{'modules.shared.nif-required' | translate}}.
                            </ng-container>
                        </div>
                        <!-- <div [hidden]="(!DNICIF!.errors?.['required']) && user.TypeDocument == true">{{'modules.shared.nif-required' | translate}}.</div> -->
                        <div [hidden]="!DNICIF!.errors?.['minlength']">{{'modules.shared.min' | translate}} {{ DNICIF!.errors?.['minlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
                        <div [hidden]="!DNICIF!.errors?.['maxlength']">{{'modules.shared.max' | translate}} {{ DNICIF!.errors?.['maxlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>

                    </div>
            </div>


            <div class="form-group" *ngIf="selectedRole!.Tag! == 'admin'">
                <label for="WorkerCode">{{'modules.users.worker-code' | translate}}</label>
                  <input type="text" class="form-control" id="WorkerCode"  formControlName="WorkerCode" required
                      [(ngModel)]="newUser.WorkerCode">

                      <div *ngIf="WorkerCode!.invalid && (WorkerCode!.dirty || WorkerCode!.touched)" class="alert alert-danger mt-3 text-start">

                        <div [hidden]="WorkerCode!.valid || WorkerCode!.pristine" >
                            {{'modules.users.worker-code-required' | translate}}.
                        </div>
                    </div>
              </div>

              <div class="form-group text-start" *ngIf="authSrv.isManufacturer() && selectedRole!.Tag! == 'user'">
                <label for="IsSeller">{{'modules.users.is-seller' | translate}}?</label>

                <div class="container">
                    <div class="pretty p-icon p-round">
                        <input type="radio" formControlName="IsSeller"  [value]="true"  required [(ngModel)]="newUser.IsSeller"
                        />
                        <div class="state p-success  text-start">
                            <i class="icon mdi mdi-check"></i>
                            <label>{{'modules.shared.yes' | translate}}</label>
                        </div>
                    </div>

                    <div class="pretty p-icon p-round">
                        <input type="radio" formControlName="IsSeller" [value]="false"  required [(ngModel)]="newUser.IsSeller"
                       />
                        <div class="state p-danger">
                            <i class="icon mdi mdi-check"></i>
                            <label>{{'modules.shared.no' | translate}}</label>
                        </div>
                    </div>
                </div>
            </div>


</mat-card>



<section class="mt-2">
    <div class="text-right" style="flex-direction: row-reverse;">
        <button  mat-raised-button class="btn btn-primary" [disabled]="!addForm.get('User')?.valid"  (click)="onSubmit()">{{'modules.shared.create' | translate}}</button>
        <button *ngIf="showButtonCancel" mat-raised-button class="btn btn-danger" type="button" style="margin-right: 10px;" (click)="cancel()">{{'modules.shared.cancel' | translate}}</button>
    </div>
</section>

</form>


</ng-container>
