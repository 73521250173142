<div class="main main-box">
  <h4 class="mb-3">{{ 'modules.devices.coordinates' | translate }}</h4>

  <div *ngIf="lat == null || lng == null">
    {{ 'modules.shared.no-info' | translate }}
  </div>

    <map-view *ngIf="lat && lng" [latitude]="latitude" [longitude]="longitude" mapTypeId="satellite" [zoom]="15">
      <map-marker [latitude]="latitude" [longitude]="longitude"></map-marker>
    </map-view>
<!--
  <agm-map [latitude]="latitude" [longitude]="longitude">
    <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
  </agm-map>-->
</div>
