export class Sensor   {
  name?: string;
  translation?: string;
  value?: number;



  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

  get minutes(): string{
    if(this.name == 'Horas trabajo' && this.value){
      let val = this.value!.toString().split(".")[1];
      let aux: number = +val
      if(val.length > 2){
        aux = +val / Math.pow(10, val.length - 2);
      }else if(val.length == 1){

        return "0" + Math.trunc((60/100)*(aux)).toString();
      }

      return Math.trunc((60/100)*(aux)).toString();
    }
    return "";
  }
}
