import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Modal } from 'src/app/models/modal';

@Component({
  selector: 'app-input-text-modal',
  templateUrl: './input-text-modal.component.html',
  styleUrls: ['./input-text-modal.component.scss']
})
export class InputTextModalComponent implements OnInit {

  inputValue: string = '';

  constructor(
    private dialogRef: MatDialogRef<InputTextModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Modal) { }


    ngOnInit(): void {
    }

    accept(){
      this.dialogRef.close(this.inputValue);
    }

    cancel(){
      this.dialogRef.close('');
    }

}
