<mat-toolbar color="{{ data.ClassType}}"  class="bg-gradient-custom">
    <!-- <mat-toolbar color="{{ data.ClassType}}"  class="bg-{{ data.ClassType}}"></mat-toolbar> -->
      <mat-toolbar-row>
        <span>{{ data.Title }}</span>
        <span class="example-spacer"></span>
        <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="cancel()" >
          <mat-icon>close</mat-icon>
        </button>
      </mat-toolbar-row>
      </mat-toolbar>
  
    <!-- <h2 mat-dialog-title class="bg-{{ data.ClassType}}">{{ data.Title }}</h2> -->
            <form class="text-start p-2" [formGroup]="form"  autocomplete="off" >
    <div>
  
      <div class="container">
  
        <div  class="row">
          <div [innerHTML]="data.Body" class="col-12">
          </div>
          <div class="col-12 element">
            <mat-form-field class="form-field w-100" appearance="fill">
                <mat-label>{{'modules.shared.date' | translate}}</mat-label>

                
                <input matInput [matDatepicker]="picker" formControlName="InformationFirst" [(ngModel)]="InformationFirstValue" >
                <mat-hint>DD/MM/YYYY</mat-hint> 
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                 
            </mat-form-field>
 
          </div>
        </div>
  
  
      <div class="row">
        <div class="col-6">
  
          <button class="btn btn-secondary btn-block my-4 w-100 mb-0" type="submit" (click)="cancel()" >
            {{ data.BtnCancel | translate }}
          </button>
  
        </div>
        <div class="col-6">
          <button class="btn btn-primary btn-block my-4 w-100 mb-0" type="submit" (click)="accept()" >
            {{ data.BtnAccept | translate }}
          </button>
  
  
        </div>
  
      </div>
  
  
    </div>
  
    </div>
</form>
  