<div class="main main-box">
  <h4 class="mb-3">{{'modules.devices.sensor-info' | translate}}</h4>
  <div class="row g-0 row-cols-1 row-cols-md-2 row-cols-lg-3 text-center" *ngIf="!loading && ( sensors == null || mapSensor.length == 0)">
    {{ 'modules.shared.no-info' | translate }}
  </div>
  <div class="row g-0 row-cols-1 row-cols-md-2 row-cols-lg-3 text-center" *ngIf="!loading && sensors">
    <ng-container  *ngFor="let sensor of mapSensor ; let i = index ">

        <ng-container *ngIf="(sensor!.value != undefined && sensor.name != 'Aux5') || (sensor.name == 'Aux5' && sensor!.value != undefined && authSrv.isAdmin())">
          <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
            <div class="small text-black-50">{{ sensor.translation! |translate }}</div>
            <!-- <div class="valor" *ngIf="sensor.name != 'Horas trabajo' && sensor!.value != undefined">{{ sensor!.value | number:'1.0-2'   }}</div> -->
            <div class="valor" *ngIf="sensor.name != 'Aux5' && sensor.name != 'Horas trabajo' && sensor!.value != undefined">{{ sensor!.value | customDecimal  }}</div>
            <div class="valor" *ngIf="sensor.name == 'Horas trabajo' && sensor!.value != undefined">{{ sensor!.value | hoursMinutes }}</div>
            <div class="valor" *ngIf="sensor.name == 'Aux5' && sensor!.value != undefined && authSrv.isAdmin()">{{ sensor!.value | customDecimal }}</div>
            <!-- <div class="sin-valor" *ngIf="sensor!.value == null || sensor!.value == undefined">Sin valor</div> -->
          </div>
        </ng-container>

    </ng-container>
  </div>
</div>
