import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Modal } from 'src/app/models/modal';
import { User, vUser } from 'src/app/models/user';

@Component({
  selector: 'app-search-user-modal',
  templateUrl: './search-user-modal.component.html',
  styleUrls: ['./search-user-modal.component.scss']
})
export class SearchUserModalComponent implements OnInit {

  loading: boolean = true;
  noDataAvailablePlaceholderText: string = "";
  labelText: string = "";
  user: User | null = null;
  typeUser: string | null = null;
  usersSelected: Array<vUser> | null = null;

  constructor(
    private dialogRef: MatDialogRef<SearchUserModalComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: Modal) {

      console.log(this.data!.Data);

      if(this.data!.User){
        this.user = new User(this.data!.User);
      }
      this.typeUser = this.data!.Data??"";
      if(this.typeUser == "user"){
        this.noDataAvailablePlaceholderText = this.translate.instant('modules.shared.no-users');
        this.labelText = this.translate.instant('modules.shared.search-user');
      }else if(this.typeUser == "fabricant"){
        this.labelText = this.translate.instant('modules.shared.search-account');
        this.noDataAvailablePlaceholderText = this.translate.instant('modules.shared.no-accounts');
      }


  }

  ngOnInit(): void {
    this.usersSelected = null;
  }

  updateUser(users: Array<vUser>){
    console.log("updateUser");

    if(users){

      if(users.length > 0){
        this.usersSelected = users;
      }else{
        this.usersSelected = [];
      }
    }   

    console.log(this.usersSelected );


    if(this.loading == true){
      this.loading = false;
    }
  }

  
  emit(userEmit: Array<vUser> | null){        

    console.log("emit" , userEmit);
    if(userEmit){
      this.loading = false;

      if(userEmit.length > 0){

        console.log("SearchUserModalComponent", userEmit);
        this.dialogRef.close(userEmit);
      }

    }
  }

  accept(){
    this.dialogRef.close(this.usersSelected);
  }

  cancel(){
    this.dialogRef.close();
  }

}
