import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-list-trips-page',
  templateUrl: './list-trips-page.component.html',
  styleUrls: ['./list-trips-page.component.scss']
})
export class ListTripsPageComponent implements OnInit {

  public page?: number;
  loading:boolean = true;

  constructor(breadcrumbService: BreadcrumbService,
    public authSrv: AuthenticationService,
    private route: ActivatedRoute,
    private translate: TranslateService) {
      breadcrumbService.setBreadCrumbUrl('trips', this.translate.instant('modules.trips.trip-management-device'));
      this.page = Number(this.route.snapshot.paramMap.get('page'))?? 0;

  }

  ngOnInit(): void {
  }

}
