import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UploadFileConfigModalComponent } from 'src/app/modals/upload-file-config-modal/upload-file-config-modal.component';
import { UploadFileModalComponent } from 'src/app/modals/upload-file-modal/upload-file-modal.component';
import { ApiRequestObject } from 'src/app/models/apiRequestObject';
import { ConsoleTrack, ConsoleTrackCommand } from 'src/app/models/console';
import { vDevice, Device } from 'src/app/models/device';
import { Modal } from 'src/app/models/modal';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DevicesService } from 'src/app/services/devices.service';
import { TerminalService } from 'src/app/services/terminal.service';
import { Md5 } from 'ts-md5';
import { DateTime } from 'luxon';
import { NgTerminal } from 'ng-terminal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-load-configuration',
  templateUrl: './load-configuration.component.html',
  styleUrls: ['./load-configuration.component.scss']
})
export class LoadConfigurationComponent implements OnInit {

  @ViewChild('term', {static: false}) child?: NgTerminal;
  @Input() ids: Array<number> = [];
  devices: Array<vDevice> = [];
  loading: boolean = true;
  error: boolean = false;
  showTerminal: boolean = false;

  comandos: Array<string> | null = null;
  testTerminal?: ConsoleTrack;
  terminalSrv?: TerminalService;

  prompt = "";
  countResponse = 0;

  constructor(
    public dialog: MatDialog,
    private router : Router,
    public authSrv: AuthenticationService,
    private deviceSrv: DevicesService,
    private translate: TranslateService) {

  }

  ngOnInit(): void {

    this.ids.forEach( (x: number) => {
      this.loadDevice(x);
    });
  }


  verify(){
    if(this.devices.length == this.ids.length){
      this.loading = false;
    }
  }

  showError(){
    this.loading = false;
    this.error = true;
  }




  loadDevice(id: number){
    this.loading = true;
    console.log('loadDevice: ' , id );
    this.deviceSrv.getDeviceById(id).subscribe({
      next: (res: Device) => {
        console.log('res: ' , res );
        this.devices.push(<vDevice>new vDevice(res));
      }, error: (err: ApiRequestObject)=> {
        this.showError();
      }, complete: () =>{
        this.verify();
      }
    });
  }

  openUploadFileModal(){

    let modal = new Modal();
    modal.Title = this.translate.instant('modules.modals.upload-config');
    modal.Body = "";
    modal.BtnAccept = this.translate.instant('modules.modals.confirm')
    modal.ClassType = "primary";
    modal.Data = { 'textInput' : this.translate.instant('modules.modals.select-config') };
    const dialog = this.dialog.open( UploadFileConfigModalComponent,{
      autoFocus : 'dialog',
      width: "400px",
      height: "auto",
      data: modal,
      closeOnNavigation: false,
      disableClose: true,
      panelClass: 'custom-modalbox',
      position: { top: '150px'}
    });

    dialog.afterClosed().subscribe(
      (result: Array<string> | null) => {
        console.log('new url: ' + result);
        if(result!){
          this.comandos = [];
          this.comandos = result;

        }
      }
    );
  }

  runScript(imei: string){


    this.testTerminal = new ConsoleTrack();
    this.testTerminal.imei = imei;
    this.testTerminal.timeSequence = 2500;
    this.testTerminal.commands = [];


    this.comandos?.forEach((x: string) =>{
      this.testTerminal!.commands?.push(new ConsoleTrackCommand({ command: x, createdAt: Date.now() }));
    });


    this.runTerminal(this.testTerminal);

    //this.terminalSrv = new TerminalService(this.testTerminal.imei!, String(tokenEncrypt) , this.authSrv.currentUserValue!.UserId!.toString());


  }

  runTerminal(console: ConsoleTrack){
    const md5 = new Md5();

    this.showTerminal = true;

    let date: DateTime = DateTime.now();
    let token: string = this.authSrv.currentUserValue!.Username! + date.toFormat('yyyy');
    let tokenEncrypt = md5.appendStr(  token ).end();

    //this.prompt = "$..." + console!.imei!.slice(-3) + "> ";
    this.prompt = this.getPrompt();

    // countResponse

    try{
      this.terminalSrv = new TerminalService(console!.imei!, String(tokenEncrypt) , this.authSrv.currentUserValue!.UserId!.toString());





      this.terminalSrv!.callback.subscribe( (res: any) => {

        this.child!.write('\x1b[2K')
        //this.child!.write('\r' + this.getPrompt() + res + '\r\n' + this.prompt );
        this.countResponse = this.countResponse + 1;
        this.child!.write('\r\x1b[1;32m' + this.getPrompt() + ' \x1b[31mRespuesta ' + this.countResponse + '/' + this.testTerminal!.commands!.length + " " + this.translate.instant('modules.devices.commands-executed') + "." + '\r\n\x1b  \x1b[36m' + res.result.result + '\r\n\x1b[37m' + this.getPrompt() );

        //this.child!.write('\r\x1b[1;32m' + this.getPrompt() + ' \x1b[36m' + res.result.result + '\r\n\x1b[37m' + this.prompt );
        //this.input = "";

        if( this.countResponse < this.testTerminal!.commands!.length){
          this.child!.write(this.testTerminal!.commands![this.countResponse].command!);
          this.child!.write('\r\n' + this.getPrompt());
          this.sendmessage(this.testTerminal!.commands![this.countResponse].command!);
        }

        if(this.countResponse == this.testTerminal!.commands!.length){
          this.child!.write('\r\x1b[1;32m' + this.getPrompt() + ' \x1b[31m' + this.testTerminal!.commands!.length + " " + this.translate.instant('modules.devices.commands-executed') + "." + '\r\n\x1b[37m' + this.getPrompt() );

        }


      });


    this.terminalSrv!.connected.subscribe( async (res: any) => {
      this.prompt = this.getPrompt();

      this.child!.write('$ TERMINAL \x1b[33mIMEI ' + console!.imei! + '\x1b[37m' + '\r\n' + this.getPrompt() );

      this.child!.write('\r\x1b[1;32m' + this.getPrompt() + ' \x1b[37m' + this.translate.instant('modules.devices.connecting') + '...' + '\r\n\x1b[37m' + this.getPrompt() );

      this.child!.write('\r\x1b[1;32m' + this.getPrompt() + ' \x1b[31m' + res + '\r\n\x1b[37m' + this.getPrompt() );
      if(res == "DESCONECTADO"){
        this.child!.write('\r\x1b[1;32m' + this.getPrompt() + ' \x1b[37m' + this.translate.instant('modules.devices.connecting') + '...' + '\r\n\x1b[37m' + this.getPrompt() );


      }else{

        if(this.testTerminal!.commands!.length > 0){

          this.child!.write(this.translate.instant('modules.devices.starting-sequence') + " ( " + this.testTerminal?.timeSequence + "ms )");
          this.child!.write('\r\n' + this.getPrompt());

          this.child!.write(this.testTerminal!.commands![0].command!);
          this.child!.write('\r\n' + this.getPrompt());
          this.sendmessage(this.testTerminal!.commands![0].command!);

          /*
          for(let i = 0 ; i < this.testTerminal!.commands!.length; i++ ){

            this.child!.write(this.testTerminal!.commands![i].command!);
            this.child!.write('\r\n' + this.getPrompt());
            this.sendmessage(this.testTerminal!.commands![i].command!);

            await this.sleep(this.testTerminal!.timeSequence!);
          }*/

          await this.sleep(5000);

        }else{

        }


      }


      //this.showTerminal = false;
    });

    }catch(e){
    }
  }

  getPrompt(): string{
    return "$" + DateTime.now().toFormat('HH:mm:ss')+ ">";
  }

  sendmessage(i: string) {
    console.log("sendmessage");
    this.terminalSrv!.emitEvent(i);
    //this.socket!.emit('new-message', 'Hi-flask');
  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
