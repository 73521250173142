import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UploadFileModalComponent } from 'src/app/modals/upload-file-modal/upload-file-modal.component';
import { Modal } from 'src/app/models/modal';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ThemeService } from 'src/app/services/theme.services';

@Component({
  selector: 'app-edit-user-theme',
  templateUrl: './edit-user-theme.component.html',
  styleUrls: ['./edit-user-theme.component.scss']
})
export class EditUserThemeComponent implements OnInit {
  @Input() user?: User;
  @Input() page?: number;
  userEdit?: User;
  loading:boolean = true;
  existPreview:boolean = false;

  themeForm: FormGroup = this.formBuilder.group({
  });

  @Output() themeFormShared = new EventEmitter<FormGroup>();

  private emitFormGeneral() {
    this.themeFormShared.emit(this.themeForm);
  }

  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public authSrv : AuthenticationService,
    private themeSrv: ThemeService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.userEdit = this.user;
    this.themeForm.addControl('Theme',this.addThemeForm());
    this.emitFormGeneral();

    let previewItem = this.themeSrv.currentPreviewTheme();
    if(previewItem == null){
      this.existPreview = false;
    }else{
      this.existPreview = true;
    }



    this.loading = false;
  }

  onSubmit(){

  }


  openUploadFileModal(){

    let modal = new Modal();
    modal.Title = this.translate.instant('modules.modals.upload-logo-for') + " " + this.user!.Name??'' + ' ' + this.user!.FirstName??''+ ' ' + this.user!.LastName??'';
    modal.Body = "";
    modal.BtnAccept = this.translate.instant('modules.modals.continue');
    modal.ClassType = "primary";
    modal.User = this.user ;
    modal.Data = { 'textInput' : this.translate.instant('modules.modals.select-image'), 'typeForm' : 'ThemeFile' };
    const dialog = this.dialog.open( UploadFileModalComponent,{
      autoFocus : 'dialog',
      width: "auto",
      height: "auto",
      data: modal,
      closeOnNavigation: false,
      disableClose: true,
      panelClass: 'custom-modalbox',
      position: { top: '150px'}
    });

    dialog.afterClosed().subscribe(
      (result: string | null) => {
        console.log('new url: ' + result);
        if(result!){
          this.user!.Theme!.HeadLogoColor = result;
        }
      }
    );
  }

  get HeadColor(){ return this.themeForm.get("Theme")!.get("HeadColor"); }
  get HeadIconColor(){ return this.themeForm.get("Theme")!.get("HeadIconColor"); }
  get HeadIconHoverColor(){ return this.themeForm.get("Theme")!.get("HeadIconHoverColor"); }

  get MenuColorTop(){ return this.themeForm.get("Theme")!.get("MenuColorTop"); }
  get MenuColor(){ return this.themeForm.get("Theme")!.get("MenuColor"); }
  get MenuColorBottom(){ return this.themeForm.get("Theme")!.get("MenuColorBottom"); }
  get MenuTextColor(){ return this.themeForm.get("Theme")!.get("MenuTextColor"); }
  get MenuTextActiveColor(){ return this.themeForm.get("Theme")!.get("MenuTextActiveColor"); }
  get MenuTextHoverColor(){ return this.themeForm.get("Theme")!.get("MenuTextHoverColor"); }

  get MenuBottomColor(){ return this.themeForm.get("Theme")!.get("MenuBottomColor"); }
  get MenuBottomTextColor(){ return this.themeForm.get("Theme")!.get("MenuBottomTextColor"); }
  get MenuBottomTextHoverColor(){ return this.themeForm.get("Theme")!.get("MenuBottomTextHoverColor"); }
  get MenuBottomTextActiveColor(){ return this.themeForm.get("Theme")!.get("MenuBottomTextActiveColor"); }

  get HeadLogoColor(){ return this.themeForm.get("Theme")!.get("HeadLogoColor"); }
  get DefaultTheme(){ return this.themeForm.get("Theme")!.get("DefaultTheme") };

  addThemeForm() {
    return this.formBuilder.group({
      DefaultTheme: new FormControl(this.user!.DefaultTheme, [
        Validators.required
      ]),
      HeadColor: new FormControl(this.user!.Theme!.HeadColor, [
        Validators.required,
      ]),
      HeadIconColor: new FormControl(this.user!.Theme!.HeadIconColor, [
        Validators.required,
      ]),
      HeadIconHoverColor: new FormControl(this.user!.Theme!.HeadIconHoverColor, [
        Validators.required,
      ]),
      MenuColorTop: new FormControl(this.user!.Theme!.MenuColorTop, [
        Validators.required,
      ]),
      MenuColor: new FormControl(this.user!.Theme!.MenuColor, [
        Validators.required,
      ]),
      MenuColorBottom: new FormControl(this.user!.Theme!.MenuColorBottom, [
        Validators.required,
      ]),
      MenuTextColor: new FormControl(this.user!.Theme!.MenuTextColor, [
        Validators.required,
      ]),
      MenuTextActiveColor: new FormControl(this.user!.Theme!.MenuTextActiveColor, [
        Validators.required,
      ]),
      MenuTextHoverColor: new FormControl(this.user!.Theme!.MenuTextHoverColor, [
        Validators.required,
      ]),
      MenuBottomColor: new FormControl(this.user!.Theme!.MenuBottomColor, [
        Validators.required,
      ]),
      MenuBottomTextColor: new FormControl(this.user!.Theme!.MenuBottomTextColor, [
        Validators.required,
      ]),
      MenuBottomTextActiveColor: new FormControl(this.user!.Theme!.MenuBottomTextActiveColor, [
        Validators.required,
      ]),
      MenuBottomTextHoverColor: new FormControl(this.user!.Theme!.MenuBottomTextHoverColor, [
        Validators.required,
      ]),
      HeadLogoColor: new FormControl(this.user!.Theme!.HeadLogoColor, [
        Validators.required,
      ]),
    });
  }


  previewTheme(){
    let previewItem = this.themeSrv.currentPreviewTheme();
    console.log("previewTheme", this.themeSrv.currentPreviewTheme);
    if(previewItem == null){
      console.log("no exist");
      this.user!.Theme!.State = 3;
      this.themeSrv.setPreviewTheme(this.user!.Theme!);
      this.existPreview = true;

      window.open("/home", "_blank");
    }else{
      console.log("borrar");
      this.existPreview = false;
      this.themeSrv.deletePreviewTheme();

    }
  }



}
