<!-- <div *ngFor="let media of listMedia">
     {{ media.Name }}
</div> --> 

<div class="accordion accordion-flush" [id]="idParentId">
  <div *ngIf="listMedia!.length == 0"  class="pr-5 pt-3 pb-3" style="padding-left: 20px;">
    {{'modules.docManager.documents-available' | translate}}
  </div>
  <div class="accordion-item"  *ngFor="let media of listMedia">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseFolder' + media.Id" aria-expanded="false" [attr.aria-controls]="'collapseFolder' + media.Id">
        <img [src]="'assets/img/folder.png'"  width="20" height="20" style="margin-right: 10px;" />
        <span>{{ media.Name }} </span> 
        <span *ngIf="media.ChildFolders!.length > 0 && media.Files!.length == 0"> - Contiene {{ media.ChildFolders!.length }} directorio{{ media.ChildFolders!.length != 1 ? 's' : '' }}.</span>
        <span *ngIf="media.ChildFolders!.length == 0 && media.Files!.length > 0"> - Contiene {{ media.Files!.length }} documento{{ media.Files!.length != 1 ? 's' : '' }}.</span>
        <span *ngIf="media.ChildFolders!.length == 0 && media.Files!.length == 0"> - {{'modules.docManager.folder-empty-2' | translate}}</span>
        <span *ngIf="media.ChildFolders!.length > 0 && media.Files!.length > 0"> - Contiene {{ media.ChildFolders!.length }} directorio{{ media.ChildFolders!.length != 1 ? 's' : '' }} y {{ media.Files!.length }} documento{{ media.Files!.length != 1 ? 's' : '' }}.</span>
        <span *ngIf="authSrv.isAdmin() && media.Role != null"> ( solo rol {{ media.Role.InternName }})</span>
    </button>
    </h2>
    <div [id]="'collapseFolder' + media.Id" class="accordion-collapse collapse" [attr.data-bs-parent]="'#' + idParentId">
      <div class="accordion-body">

        <div *ngIf="authSrv.isAdmin() && media.Id! >= 0"  class="pr-5 pt-3 pb-3 file-click no-pointer item-list" style="display: inline-flex; width: 100%;">
          <span *ngIf="authSrv.isAdmin()"><i  class="fa-solid fa-gear text-primary fa-lg no-pointer" ></i></span> 
          <span class="spacer d-flex">Acciones de directorio</span>
          <div>

            <span *ngIf="authSrv.isAdmin()" (click)="newOrEditFolder(media, true)"><i  class="fa-solid fa-folder-plus text-warning fa-lg" ></i></span>
            <span *ngIf="authSrv.isAdmin()" (click)="newOrEditFile(media)"><i  class="fa-solid fa-upload text-download fa-lg" ></i></span>
            <span *ngIf="authSrv.isAdmin()" (click)="newOrEditFolder(media, false)"><i  class="fa-solid fa-pencil text-primary fa-lg" ></i></span> 
            <span *ngIf="authSrv.isAdmin() && media.IsPublic"><i  class="fa-solid fa-lock text-lock fa-lg" ></i></span> 
            <span *ngIf="authSrv.isAdmin() && !media.IsPublic"><i  class="fa-solid fa-lock-open text-lock fa-lg" ></i></span>
            <span *ngIf="authSrv.isAdmin() && media.IsVisible"><i  class="fa-solid fa-eye text-warning fa-lg" ></i></span> 
            <span *ngIf="authSrv.isAdmin() && !media.IsVisible"><i  class="fa-solid fa-eye-slash text-warning fa-lg" ></i></span> 
            <span *ngIf="authSrv.isAdmin()" (click)="deleteItem(media)"><i  class="fa-solid fa-trash text-danger fa-lg" ></i></span>
          </div>
        </div>

        <app-list-media *ngIf="media.ChildFolders!.length > 0" [listMedia]="media.ChildFolders" [idParent]="media.Id"  ></app-list-media>
          

        <div *ngFor="let file of media.Files" [ngClass]="(!authSrv.isAdmin())?'file-click':'file-click-admin'" class="pr-5 pt-3 pb-3 item-list" style="display: inline-flex; width: 100%;">
          
                <img [src]="'assets/img/files.png'" width="20" height="20" class="d-flex icon"  style="margin-right: 10px; width: 20px;" />
                <span class="spacer d-flex">{{ file.Name }}<ng-container *ngIf="authSrv.isAdmin()"> - {{ file.Path }}</ng-container><ng-container *ngIf="authSrv.isAdmin() && file.Role != null"> ( solo rol {{ file.Role.InternName }})</ng-container></span>
                
                <img  *ngIf="!authSrv.isAdmin()" (click)="download(file)"  [src]="'assets/img/download.png'" width="20" height="20" class="d-flex opacity download"   style="margin-right: 10px; width: 20px;" />
                <span *ngIf="authSrv.isAdmin()" (click)="download(file)"><i  class="fa-solid fa-download text-download fa-lg" ></i></span>
     
                <span *ngIf="authSrv.isAdmin()" (click)="newOrEditFile(media, file)"><i  class="fa-solid fa-pencil text-primary fa-lg" ></i></span> 
                <span *ngIf="authSrv.isAdmin() && file.IsPublic"><i  class="fa-solid fa-lock text-lock fa-lg" ></i></span> 
                <span *ngIf="authSrv.isAdmin() && !file.IsPublic"><i  class="fa-solid fa-lock-open text-lock fa-lg" ></i></span> 
                <span *ngIf="authSrv.isAdmin() && file.IsVisible"><i  class="fa-solid fa-eye text-warning fa-lg" ></i></span> 
                <span *ngIf="authSrv.isAdmin() && !file.IsVisible"><i  class="fa-solid fa-eye-slash text-warning fa-lg" ></i></span> 
                <span *ngIf="authSrv.isAdmin()" (click)="deleteItemFile(file, media)"><i  class="fa-solid fa-trash text-danger fa-lg" ></i></span>

        </div>
        <div *ngIf="media.ChildFolders!.length == 0 && media.Files!.length == 0" class="pr-5 pt-3 pb-3" style="padding-left: 20px;">
          {{'modules.docManager.folder-empty' | translate}}
        </div>
      </div>
    </div>
  </div>
 
</div>
