import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Servicio para transferir mensajes entre compoenentes.
 *
 * @export
 * @class DataService
 */
@Injectable({ providedIn: 'root' })
export class DataService {

  constructor() {
    this.persistentBag = new Map<string, string>();

    //Inicializar el persistentBag con lo que haya en el localstorage
    let keys = Object.keys(localStorage);
    let i = 0;
    let key :any;

    for (; key = keys[i]; i++) {
        this.persistentBag.set(key,localStorage.getItem(key));
    }

  }

  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  private messageObject = new BehaviorSubject(new Object());
  currentObject = this.messageObject.asObservable();

  private tempMessage?: string;
  private arrayMessage?: Array<string>;

  private persistentBag: Map<string, any>;

  /**
   * Guarda el objeto o en el DataService.
   *
   * @param {object} o
   * @memberof DataService
   */
  public setDataString(o: string) {
    this.tempMessage = o;
  }

  public setDataArray(o: Array<string>) {
    this.arrayMessage = new Array<string>();
    this.arrayMessage = o;
  }

  /**
   * Obtiene el mensaje actualmente almacenado en el DataService
   * Si no se especifica lo contrario, cada vez que se obtiene se borra.
   * @param {string} [erase=true]
   * @returns {object}
   * @memberof DataService
   */
  public getDataString(erase: boolean = true): string {
    let o: string
    o = <string>this.tempMessage;
    if (erase) this.tempMessage = undefined;
    return o;
  }
  /**
   * Obtiene el Array Guardado en el objeto. Opcionalmente lo borra.
   *
   * @param {boolean} [erase=false]
   * @returns
   * @memberof DataService
   */
  public getDataArray(erase: boolean = false) {
    let a: Array<string> | undefined;
    a = this.arrayMessage;
    if (erase) this.arrayMessage = [];
    return a;
  }

  /**
   * Añade una entrada al Map interno del servicio. Si existe actualiza su valor.
   *
   * @param {string} key
   * @param {*} value
   * @memberof DataService
   */
  public addDataToBag(key: string, value: any) {
    if (this.persistentBag.has(key)) {
      this.persistentBag.delete(key);
      this.persistentBag.set(key, value);
    } else {
      this.persistentBag.set(key, value);
    }
    localStorage.setItem(key,value);
  }

  /**
   * Obtiene una entrada, si existe, del Map interno del servicio. Si no devolverá undefined.
   * Puede especificar remove para eliminar el registro tras su lectura.
   *
   * @param {string} key
   * @param {boolean} [remove=false]
   * @returns
   * @memberof DataService
   */
  public getDataFromBag(key: string, remove: boolean=false) {
    let value: any
    if (this.persistentBag.has(key)) {
      //value = this.persistentBag.get(key)
      value = localStorage.getItem(key); //Lo obtendremos el localstorage. Así todo lo que haya en el persistent"Bag" será siempre "persistente"
    }else{
      return undefined;
    }
    if (remove) {
      this.removeFromBag(key);
    }

    return value;
  }
  /**
   * Elimina una entrada del Map interno del servicio, si existe.
   *
   * @param {string} key
   * @memberof DataService
   */
  public removeFromBag(key: string) {
    if (this.persistentBag.has(key)) {
      this.persistentBag.delete(key);
      localStorage.removeItem(key);
    }
  }

  changeObject(o: Object) {
    this.messageObject.next(o);
  }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

}
