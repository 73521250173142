
  <div *ngIf="showTitle">
    <h1>{{'modules.shared.chart' | translate}} {{ device?.Name??'~' }} {{'modules.shared.with' | translate}} IMEI: {{ device?.IMEI }}</h1>
    <h2>{{'modules.tables.account' | translate}}: {{ device?.Manufacturer.Name }} - {{'modules.alarms.model' | translate}}: {{ device?.DeviceModel.Name }} - {{'modules.users.user' | translate}}: {{ device?.IdUser??'~' }}</h2>
  </div>

<div *ngIf="loading == false && (noData == false || ( noData == true && this.device?.InformationFirst != null))" class="grid-container">


  <form class="text-start p-2" [formGroup]="filterChartForm"  autocomplete="off" >
  <!-- flex item: block child -->




  <div class="mr-2 ml-2 pl-3">
    <button mat-fab class="my-fab" aria-label="Filtrar" (click)="reloadInfo()">
      <mat-icon>search</mat-icon>
    </button>
  </div>

  <div class="mr-2">


  <mat-form-field class="form-field" appearance="fill">
      <mat-label>{{'modules.shared.time-frame' | translate}}</mat-label>
      <mat-date-range-input
  [rangePicker]="campaignOnePicker"
  [comparisonStart]="InformationFirstValue"
  [comparisonEnd]="InformationLastValue">
  <input matStartDate placeholder="{{'modules.shared.start-data' | translate}}" formControlName="InformationFirst" [(ngModel)]="InformationFirstValue" >
  <input matEndDate placeholder="{{'modules.shared.end-data' | translate}}" formControlName="InformationLast" [(ngModel)]="InformationLastValue" >
</mat-date-range-input>
<mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
<mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
</mat-form-field>
</div>

</form>
</div>

<div style="display: block;" style="height: 400px" *ngIf="loading == false && noData == false" >

  <canvas baseChart width="400" height="400"
  [data]="lineChartData"
  [options]="lineChartOptions"
  [type]="lineChartType"
  (chartHover)="chartHovered($event)"
  (chartClick)="chartClicked($event)"></canvas>

</div>

<div *ngIf="loading == false && noData == true">


  {{'modules.shared.no-info-chart' | translate}}.
</div>
