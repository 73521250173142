<app-loading-custom [loading]="loadingDelete"></app-loading-custom>

<section *ngIf="showNewUser" class="mt-2">
  <div class="text-right " style="flex-direction: row-reverse;">
      <button  mat-raised-button class="btn btn-primary"  (click)="newUser()">
        {{'modules.users.add-user' | translate}}
      </button>
  </div>
</section>
<!-- <div *ngIf="showNewUser" class="text-right-label" style="flex-direction: row-reverse">  <span class="c-pointer"  (click)="newUser()" >{{'modules.users.exist' | translate}}</span></div>
-->

<div *ngIf="datatableConfigColumns && datatableConfigColumns.headerTable" class="m-3">
  <table #userList [id]="nameDatatable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
    class="display nowrap table row-border hover stripe table-striped" style="width: 100% !important">
    <thead>
      <tr>
        <th *ngFor="let headColumn of datatableConfigColumns.headerTable" [attr.class]="'column-' + headColumn.column + ' column-' + headColumn.tag">
          {{ headColumn.name}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loading == true">
        <td [attr.colspan]="datatableConfigColumns!.headerTable!.length">{{'modules.shared.loading-info' | translate}}...</td>
      </tr>
      <tr *ngIf="loading == false && listaUsuarios.length == 0">
        <td [attr.colspan]="datatableConfigColumns!.headerTable!.length">{{'modules.shared.no-records' | translate}}.</td>
      </tr>

      <tr *ngFor="let usuario of listaUsuarios" [ngClass]="(usuario.Active == false)?'user-disabled':''">
        <td class="text-center">
          <user-icon [user]="usuario!"></user-icon>
        </td>

        <td class="c-pointer">
          <a [routerLink]="['/users/' + usuario.Id + '/detail' ]">{{ usuario.Name }}</a>
        </td>

        <td>{{ usuario.FirstName }} {{ usuario.LastName }}</td>

        <td>{{ usuario.CreatedAt | date:"dd-MM-yyyy" }}</td>

        <td>{{ usuario.Email??'' }}</td>

        <td *ngIf="authSrv.isAdmin()"  class="text-right">{{ (usuario.isManufacturer?'~':usuario.NumDevices??0) }}</td>
        <td *ngIf="authSrv.isAdmin()" class="text-right">{{ (usuario.isClient?'~':usuario.NumClients??0) }}</td>
        <td *ngIf="authSrv.isAdmin()" class="text-right">{{ (usuario.isManufacturer?'~':usuario.NumManufacturers??0) }}</td>

        <td class="c-pointer-i text-center">
          <i *ngIf="usuario.Active == false" class="far fa-square fa-lg pl-3" style="color: #000"></i>
          <i *ngIf="usuario.Active == true" class="far fa-check-square text-success fa-lg pl-3"></i>

        </td>

        <td class="c-pointer-i text-right">
          <i *ngIf="authSrv.isAdmin() || authSrv.isManufacturer()" (click)="deleteItem(usuario)" class="fa-solid fa-trash text-danger fa-lg" ></i>

          <a [routerLink]="['/users/' + usuario.Id + '/detail' ]"><i
              class="fas fa-angle-double-right text-primary fa-lg pl-3"></i></a>
        </td>
      </tr>
    </tbody>
  </table>


</div>
