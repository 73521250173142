import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { ApiRequestObject, ListResult } from '../models/apiRequestObject';
import { User } from '../models/user';
import { ApiService } from './api.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UsersService  extends ApiService{

  constructor(private http: HttpClient, router: Router, authSrv: AuthenticationService) {
    super(router, authSrv);
  }

  /*
  getUserById(id:number){
    return this.http.get<User>(`${environment.API_ENDPOINT}users/${id}`).pipe(
      catchError(this.error)
   );
  }*/

  getRoles(){
    return this.http.get<ListResult>(`${environment.API_ENDPOINT}roles/list/`).pipe(
      catchError(this.error)
   );
  }


  getList(page: number, offset: number, order: string, direction: string,  filter?: string, user?:User ) : Observable<ListResult> {

    let userFilter=""
    if(user){
      userFilter="&UserId="+user.Id
    }
    if(!filter){
      filter="";
    }

    let url = `${environment.API_ENDPOINT}users/list/${page}/${offset}/${order}/${direction}?filter=${filter}${userFilter}`;


    return this.http.get<ListResult>(url).pipe(
       catchError(this.error)
    );
  }

  getManufacturers(page: number, offset: number, order: string, direction: string,  filter?: string ){

    let url;
    if ( filter == undefined){
      url = `${environment.API_ENDPOINT}users/manufacturers/list/${page}/${offset}/${order}/${direction}`;
    }else{
      url = `${environment.API_ENDPOINT}users/manufacturers/list/${page}/${offset}/${order}/${direction}?filter=${filter}`;
    }

    return this.http.get<ListResult>(url).pipe(
      catchError(this.error)
   );
  }



  getClients(page: number, offset: number, order: string, direction: string,  filter?: string ){

    let url;
    if ( filter == undefined){
      url = `${environment.API_ENDPOINT}users/clients/list/${page}/${offset}/${order}/${direction}`;
    }else{
      url = `${environment.API_ENDPOINT}users/clients/list/${page}/${offset}/${order}/${direction}?filter=${filter}`;
    }

    return this.http.get<ListResult>(url).pipe(
      catchError(this.error)
   );
  }

  getUsersByRole(role: string, page: number, offset: number, order: string, direction: string,  filter?: string ){

    let url;
    if ( filter == undefined){
      url = `${environment.API_ENDPOINT}users/${role}/list/${page}/${offset}/${order}/${direction}`;
    }else{
      url = `${environment.API_ENDPOINT}users/${role}/list/${page}/${offset}/${order}/${direction}?filter=${filter}`;
    }

    return this.http.get<ListResult>(url).pipe(
      catchError(this.error)
   );
  }

  syncUsers(){


    return this.http.get<ListResult>(`${environment.API_ENDPOINT}users/usersRelatedSync`).pipe(
      catchError(this.error)
   );
  }


  create(user: User) : Observable<User> {


    return this.http.post<User>(`${environment.API_ENDPOINT}user`, user).pipe(
       catchError(this.error)
    );
  }


  delete(user: User ) : Observable<boolean> {
    return this.http.delete<boolean>(`${environment.API_ENDPOINT}user/${user.Id}`).pipe(
       catchError(this.error)
    );
  }


}
