import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ListResult } from 'src/app/models/apiRequestObject';
import { DocManagerFile, DocManagerFolder } from 'src/app/models/docManager';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { DocManagerService } from 'src/app/services/doc-manager.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FolderMediaAddQuickComponent } from 'src/app/modals/folder-media-add-quick/folder-media-add-quick.component';
import { Modal } from 'src/app/models/modal';
import { UtilsService } from 'src/app/services/utils.service';
import { FileMediaAddQuickComponent } from 'src/app/modals/file-media-add-quick/file-media-add-quick.component';

@Component({
  selector: 'app-list-media-page',
  templateUrl: './list-media-page.component.html',
  styleUrls: ['./list-media-page.component.scss']
})
export class ListMediaPageComponent implements OnInit {

  public page?: number;
  loading:boolean = true;
  public listMedia: Array<DocManagerFolder> = []; 

  constructor(breadcrumbService: BreadcrumbService,
    public authSrv: AuthenticationService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private utilsSrv: UtilsService,
    public dialog: MatDialog,
    private docMediaService: DocManagerService) {
      breadcrumbService.setBreadCrumbUrl('trips', this.translate.instant('modules.docManager.title-page'));
      this.page = Number(this.route.snapshot.paramMap.get('page'))?? 0;


      console.log("list-media");

  }

  ngOnInit(): void {
    this.docMediaService.getMedia().subscribe(
      {
        next: (res: ListResult) => {

          res.Result.forEach( (x) => {
            this.listMedia.push( new DocManagerFolder({...x}) );
          });
          
          this.loading = false;
      },
      error: (err) =>{
        this.utilsSrv.infoModal('Error limpiar dispositivo.',undefined);

      }
      });
  }
  newFile(){
    const dialogRef = this.dialog.open(
      FileMediaAddQuickComponent,
      {
        id: 'FileMediaAddQuickComponent',
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: new Modal({ 'Data' : {  eventOnSubmit: true }  }),
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe( (result?: DocManagerFile) => {
      if(result){
        console.log('result: ' + result.Name);

        this.loading = true;
        let indexPublicFolder = this.listMedia.map(x => x.Id).indexOf(-1);
        console.log('indexPublicFolder: ' + indexPublicFolder);
        if(indexPublicFolder >= 0){
          this.listMedia[indexPublicFolder]!.Files?.push(result); 

        }else{
          let folder = new DocManagerFolder({Id: -1, Name: "Carpeta pública", 
          IsPublic: false, IsVisible: false, Files: [] });
          folder.Files?.push(result);

          this.listMedia.push(folder);
        }
        this.utilsSrv.infoModal('Documento creado correctamente',undefined);
        
        this.loading = false;
      }
    });

  }

  newFolder(){

    const dialogRef = this.dialog.open(
      FolderMediaAddQuickComponent,
      {
        id: 'FolderMediaAddQuickComponent',
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: new Modal({ 'Data' : {  eventOnSubmit: true }  }),
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe( (result?: DocManagerFolder) => {
      if(result){
        console.log('result: ' + result.Name);

        this.loading = true;
        this.listMedia.push(result);
        this.utilsSrv.infoModal('Directorio creado correctamente',undefined);
        
        this.loading = false;
      }
    });

  }

}
