import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, tap } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';
import { DataService } from '../services/datatransfer.service';


@Injectable({
  providedIn: 'root'
})
export class AuthorizatedGuard implements CanActivate, CanLoad {

    constructor(private router: Router, private authSrv: AuthenticationService, private storageService: DataService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      //console.log( 'AuthorizatedGuard: canActivate' , true);
      
      let canActivate : boolean = false;
      canActivate = this.authSrv.isLogedUser;
      if(!canActivate){
        this.router.navigate(['/auth/login']);
      }
      return of(canActivate);

      //return of(true);

      // QQ : Comentat per rendiment 20230203
      // return this.authSrv.verificaAutenticacion(true).pipe(
      //   tap( (estaAuth: boolean) => {
      //     if(!estaAuth){
      //       this.router.navigate(['/auth/login']);
      //     }
      //   }, (error: any) => {
      //     this.router.navigate(['/auth/login']);
      //   }
      //   )
      // );

    }

    canLoad(route: Route, segments: UrlSegment[]) {

      // console.log("AuthorizatedGuard: canLoad" );

      let canActivate : boolean = false;
      canActivate = this.authSrv.isLogedUser;
      if(!canActivate){
        this.router.navigate(['/auth/login']);
      }
      return of(canActivate);

      
      // return of(true);

      // QQ : Comentat per rendiment 20230203
      // return this.authSrv.verificaAutenticacion(true).pipe(
      //   tap( (estaAuth: boolean) => {
      //     if(!estaAuth){
      //       this.router.navigate(['/auth/login']);
      //     }
      //     return true;
      //   }, (error: any) => {
      //     this.router.navigate(['/auth/login']);
      //   }
      //   )
      // );

    }
}
