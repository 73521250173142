import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmailValidator, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserWithPassword } from 'src/app/models/user';
import { UserValidator } from 'src/app/validators/user.validators';

@Component({
  selector: 'app-edit-user-password',
  templateUrl: './edit-user-password.component.html',
  styleUrls: ['./edit-user-password.component.scss']
})
export class EditUserPasswordComponent implements OnInit {

  @Input() newPassword?: String;
  @Input() newPasswordRepeat?: String;
  loading:boolean = true;

  passwordForm: FormGroup = this.formBuilder.group({
  });
  @Output() passwordFormShared = new EventEmitter<FormGroup>();

  public emitFormGeneral() {
    this.passwordFormShared.emit(this.passwordForm);
  }

  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private userValidator: UserValidator,
    public authSrv : AuthenticationService) { }


    ngOnInit(): void {

      this.passwordForm.addControl('Password',this.addPasswordForm());
      this.emitFormGeneral();
      this.loading = false;

    }
    get NewPassword(){ return this.passwordForm.get("Password")!.get("NewPassword"); }
    get NewPasswordRepeat(){ return this.passwordForm.get("Password")!.get("NewPasswordRepeat"); }

    addPasswordForm() {
      return this.formBuilder.group({
        NewPassword: new FormControl(this.newPassword, [
          Validators.minLength(3),
          Validators.maxLength(25)
        ]),
        NewPasswordRepeat: new FormControl(this.newPasswordRepeat, [
          Validators.minLength(3),
          Validators.maxLength(25),
        ])
      },
      {
        validator: this.userValidator.checkMatchValidator('NewPassword', 'NewPasswordRepeat')
      }
    );
    }

}
