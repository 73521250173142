import { Injectable, EventEmitter } from '@angular/core';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Socket, SocketIoConfig } from 'ngx-socket-io';


export class TerminalService extends Socket {

  outEven: EventEmitter<any> = new EventEmitter();
  callback: EventEmitter<any> = new EventEmitter();
  connected: EventEmitter<any> = new EventEmitter();

  constructor(private imei: string, private token:string, private id: string, local: boolean = false) {
    let body: SocketIoConfig ;
    let aux = "http://localhost:4200/devices/195/terminal";
    if(local == true){
      body = { url: environment.SOCKET_SERVICE, options: {
        transports: [
          'websocket'
        ],
       query: {
          imei: imei,
          token: token,
          id: id,

      } }
      };
    }else{
      body = { url: environment.SOCKET_SERVICE, options: {
        extraHeaders: {
        X_IMEI: imei,
        X_TOKEN: token,
        X_ID: id,

      } }
      };
    }

    super( body );

    this.listen();
    this.listenConnected();
    console.log(this);

    //this.listenConnected();
  }

  listenConnected = () => {
    this.ioSocket.on('connected', (res: any) => {
      console.log("connected");
      this.connected.emit(res);
    })
  }

  listen = () => {
    this.ioSocket.on('my_message', (res: any) => {
      console.log("listen: " , res);
      this.callback.emit(res);
    })
  }

  emitEvent = (payload = {} ) => {
    this.ioSocket.emit('my_message', payload);
  }
}
