import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceModelEditQuickComponent } from 'src/app/modals/device-model-edit-quick/device-model-edit-quick.component';
import { ApiRequestObject } from 'src/app/models/apiRequestObject';
import { DeviceModel, vDeviceModel } from 'src/app/models/device';
import { Modal } from 'src/app/models/modal';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { DevicesService } from 'src/app/services/devices.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-detail-device-model',
  templateUrl: './detail-device-model.component.html',
  styleUrls: ['./detail-device-model.component.scss']
})
export class DetailDeviceModelComponent implements OnInit {

  public page?: number;
  @Input() model?: DeviceModel;
  @Input() pageInput?: string = "";
  @Input() showNewModel: boolean = true;
  currentIndex: number = 0;

  loading:boolean = false;

  groupTab: string[] = [
    'dispositivos',
    'alarmas',
    'alarmas-por-defecto'
  ];

  constructor(
    public dialog: MatDialog,
    private breadcrumbService: BreadcrumbService,
    private router : Router,
    public authSrv: AuthenticationService,
    private deviceSrv: DevicesService,
    private route: ActivatedRoute,
    private translate: TranslateService) {
      this.page = Number(this.route.snapshot.paramMap.get('page'))?? 0;
    }


  get isAdmin(): boolean{
    return this.authSrv.isAdmin();
  }
  get nameDeviceModelMain(): string{
    return environment.NameDeviceModelMain;
  }

  ngOnInit(): void {

    if(this.pageInput! != ""){
      console.log(this.groupTab, this.pageInput, this.groupTab.indexOf(this.pageInput!));
      this.currentIndex  =  this.groupTab.indexOf(this.pageInput!);
      /*setTimeout(() => {
        this.currentIndex  =  this.groupTab.indexOf(this.pageInput!);
      }, 500);*/
    }

    this.breadcrumbService.addBreadCrumb('devices/models/' + this.model!.Id??''  + '/detail', this.translate.instant('modules.alarms.model') + ' ' + this.model!.Name??'' );


  }

  editDeviceModel()
  {
    let data;

    console.log(this.model);

    data = {'Data' : { 'fromList' : true  }};

    if(this.model?.Manufacturer){
      data = { 'Data' : { "DeviceModel" : { ...this.model } , 'idUser': this.model?.Manufacturer.Id }, "User" : { ...<User>this.model?.Manufacturer }  } ;
    }


    console.log("data", data);

    const dialogRef = this.dialog.open(
      DeviceModelEditQuickComponent,
      {
        id: 'DeviceModelEditQuickComponent',
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: new Modal( data ),
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe( (result?: vDeviceModel) => {
      if(result){

        console.log("Guardar modificacions maquina");
        console.log(result);
        this.loadDeviceModel(result.Id!);



      }

    });
  }



  loadDeviceModel(id: number){
    this.loading = true;
    console.log('loadDevice: ' , id );
    this.deviceSrv.getModelById(id).subscribe({
      next: (res: DeviceModel) => {
        this.model = <DeviceModel>new DeviceModel(res);
        console.log(this.model );
        this.loading = false;
      }, error: (err: ApiRequestObject)=> {
        this.loading = false;
      }
    });
  }


}
