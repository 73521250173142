import { TranslateService } from "@ngx-translate/core";
import { Device, DeviceModel } from "./device";
import { Role, User } from "./user";

export enum AlertType{
    Device = 0,
    DeviceModel = 1,
    DeviceModelDefault = 2,
    Bolero = 3,
    System = 4,
    Admin = 5
}


export class vAlarmDeviceModelDefaultValue {
  Id?:               number;
  IdDevice?:          number;
  IdModel?:          number;
  Name?:             string;
  IMEI? :            string;
  Color?:            string;
  State?:            number;
  Active?:           boolean;
  CreatedAt?:        Date;
  UpdatedAt?:        null;
  IdManufacturer?:   number;
  NameManufacturer?: string;
  NameModelDevice?:  string;
  NumModelAlarms?:   number;
  TypeAlarm?: number;
  IdUserCreated?:   number;
  NameUserCreated?: string;
  RoleUserCreated?:  string;


  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

  get getIcon(): string{

    if(this.TypeAlarm){
      if(this.TypeAlarm == AlertType.Device ){
        return 'fa-bell';
      }
      if(this.TypeAlarm == AlertType.DeviceModel ){
        return 'fa-layer-group';
      }
      if(this.TypeAlarm == AlertType.DeviceModelDefault ){
        return 'fa-circle-question';
      }

    }


    return '';
  }


  get getText(): string{

    if(this.TypeAlarm){
      if(this.TypeAlarm == AlertType.Device ){
        return 'modules.alarms.new-alarm-device';
      }
      if(this.TypeAlarm == AlertType.DeviceModel ){
        return 'modules.alarms.alarm-model-machine';
      }
      if(this.TypeAlarm == AlertType.DeviceModelDefault ){
        return 'modules.alarms.default-value-model';
      }

    }


    return '';
  }

  get getPreRouterLinkFromDevices(): string{

    if(this.TypeAlarm){
      if(this.TypeAlarm == 0 ){
        return '/devices/' ;
      }
      if(this.TypeAlarm == 1 ){
        return '/devices/models/' ;
      }
      if(this.TypeAlarm == 2 ){
        return '/devices/models/' ;
      }

    }

    return '';
  }
  get getRouterLinkFromDevices(): string{

    if(this.TypeAlarm){
      if(this.TypeAlarm == 0 ){
        return '/alarms/' + this.Id??'' ;
      }
      if(this.TypeAlarm == 1 ){
        return '/alarms/' + this.Id??'' ;
      }
      if(this.TypeAlarm == 2 ){
        return '/alarms/default/' + this.Id??'' ;
      }

    }

    return '';
  }

  get getRouterLinkFromDeviceModels(): string{

    if(this.TypeAlarm){
      if(this.TypeAlarm == 0 ){
        return '/alarms/' + this.Id??'' ;
      }
      if(this.TypeAlarm == 1 ){
        return '/alarms/' + this.Id??'' ;
      }
      if(this.TypeAlarm == 2 ){
        return '/alarms/default/' + this.Id??'' ;
      }

    }
    return '';
  }


  get getRouterLinkFromAlarms(): string{

    if(this.TypeAlarm){
      if(this.TypeAlarm == 0 ){
        return '/alarms/device/' + this.Id??'' ;
      }
      if(this.TypeAlarm == 1 ){
        return '/alarms/model/' + this.Id??'' ;
      }
      if(this.TypeAlarm == 2 ){
        return '/alarms/default/' +  this.Id??'' ;
      }

    }

    return '';
  }
}


export class AlarmDeviceModelDefaultValues {
  AlarmValue?:             AlarmValue;
  DeviceModel?:            DeviceModel | any;
  Id?:                     number;
  IdModel?:                number;
  IdAlarmValues?:          number;
  IdUserCreated?: number;
  User?:                 User | any;


  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

  defaultValues(){

      this.Id= 0;
      this.IdModel = 0;
      this.AlarmValue = new AlarmValue();
      this.AlarmValue.defaultValues();

  }


  correct(){
    let u : User = new User(this.User);
    u.Role = new Role(this.User.Role);
    this.User = u;
  }
}

export class AlarmDeviceModelValues {
  AlarmDeviceModelDefaultValues?: AlarmDeviceModelDefaultValues;
  AlarmValue?:             AlarmValue;
  DeviceModel?:            DeviceModel | any;
  Id?:                     number;
  IdModel?:                number;
  IdAlarmDeviceModelDefaultValue?: number;
  IdAlarmValues?:          number;
  IdUserCreated?: number;
  User?:                 User | any;


  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

  correct(){
    let u : User = new User(this.User);
    u.Role = new Role(this.User.Role);
    this.User = u;
  }
}

export class AlarmDeviceValues {
  AlarmValue?:             AlarmValue;
  AlarmDeviceModelValues?: AlarmDeviceModelValues | any;
  Device?:                 Device | any;
  Id?:                     number;
  IdAlarmDeviceModelValue?: number;
  IdDevice?:                number;
  IdAlarmValues?:          number;
  IdUserCreated?: number;
  User?:                 User | any;


  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

  defaultValues(){
      this.Id= 0;
      this.IdDevice = 0;
      this.AlarmValue = new AlarmValue();
      this.AlarmValue.defaultValues();
  }

  correct(){

    if(this.User){
      let u : User = new User(this.User);
      u.Role = new Role(this.User.Role);
      this.User = u;
    }
  }
}

export class vAlarmDeviceValues {
  Id?:               number;
  IdDevice?:         number;
  Name?:             string;
  Color?:            string;
  State?:            number;
  Active?:           boolean;
  CreatedAt?:        Date;
  UpdatedAt?:        null;
  IdManufacturer?:   number;
  NameManufacturer?: string;
  IdClient?:         number;
  NameClient?: string;
  NameModelDevice?:  string;
  NumAlarms?:   number;


  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}



export class AlarmValue {
  AlarmDeviceModelDefaultValues?: any[];
  AlarmDeviceModelValues?:        any[];
  AlarmDeviceValues?:             any[];
  Id?:                            number;
  Name?:                          string;
  Color?:                         string;
  State?:                         number;
  Active?:                        boolean;
  CreatedAt?:                     Date;
  UpdatedAt?:                     null;
  RPMMin?:                        number;
  RPMMax?:                        number;
  WorkingHoursMin?:               number;
  WorkingHoursMax?:               number;
  Pressure1Min?:                  number;
  Pressure1Max?:                  number;
  Pressure2Min?:                  number;
  Pressure2Max?:                  number;
  Flow1Min?:                      number;
  Flow1Max?:                      number;
  Flow2Min?:                      number;
  Flow2Max?:                      number;
  Action1Min?:                    number;
  Action1Max?:                    number;
  Action2Min?:                    number;
  Action2Max?:                    number;
  Action3Min?:                    number;
  Action3Max?:                    number;
  Action4Min?:                    number;
  Action4Max?:                    number;
  Action5Min?:                    number;
  Action5Max?:                    number;
  Action6Min?:                    number;
  Action6Max?:                    number;
  Weight1Min?:                    number;
  Weight1Max?:                    number;
  Weight2Min?:                    number;
  Weight2Max?:                    number;
  Aux1Min?:                       number;
  Aux1Max?:                       number;
  Aux2Min?:                       number;
  Aux2Max?:                       number;
  Aux3Min?:                       number;
  Aux3Max?:                       number;
  Aux4Min?:                       number;
  Aux4Max?:                       number;
  Aux5Min?:                       number;
  Aux5Max?:                       number;
  Aux6Min?:                       number;
  Aux6Max?:                       number;
  Aux7Min?:                       number;
  Aux7Max?:                       number;
  Aux8Min?:                       number;
  Aux8Max?:                       number;
  ManufacturerActive:            boolean = false;
  ClientActive:                  boolean = false;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

  defaultValues(){
    (<any>Object).assign(this, <AlarmValue>{
      'Name' : '',
      'RPMMin' : undefined,
      'RPMMax' : undefined,
      'WorkingHoursMin' : undefined,
      'WorkingHoursMax' : undefined,
      'Pressure1Min' : undefined,
      'Pressure1Max' : undefined,
      'Pressure2Min' : undefined,
      'Pressure2Max' : undefined,
      'Flow1Min' : undefined,
      'Flow1Max' : undefined,
      'Flow2Min' : undefined,
      'Flow2Max' : undefined,
      'Action1Min' : undefined,
      'Action1Max' : undefined,
      'Action2Min' : undefined,
      'Action2Max' : undefined,
      'Action3Min' : undefined,
      'Action3Max' : undefined,
      'Action4Min' : undefined,
      'Action4Max' : undefined,
      'Action5Min' : undefined,
      'Action5Max' : undefined,
      'Action6Min' : undefined,
      'Action6Max' : undefined,
      'Weight1Min' : undefined,
      'Weight1Max' : undefined,
      'Weight2Min' : undefined,
      'Weight2Max' : undefined,
      'Aux1Min' : undefined,
      'Aux1Max' : undefined,
      'Aux2Min' : undefined,
      'Aux2Max' : undefined,
      'Aux3Min' : undefined,
      'Aux3Max' : undefined,
      'Aux4Min' : undefined,
      'Aux4Max' : undefined,
      'Aux5Min' : undefined,
      'Aux5Max' : undefined,
      'Aux6Min' : undefined,
      'Aux6Max' : undefined,
      'Aux7Min' : undefined,
      'Aux7Max' : undefined,
      'Aux8Min' : undefined,
      'ManufacturerActive' : false,
      'ClientActive' : false,
    });
  }
}

export interface DataSetApex{
  name: string;
  data: DataSetPoint[]
}

export interface DataSetPoint{
  x: number;
  y: number;
}
