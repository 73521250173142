<mat-toolbar color="primary" class="md-medium-tall toolbar">
	<!-- <h1 class="space-left">Batsi</h1> -->

	<button mat-icon-button id="menu" class="mr-4" (mouseover)="hoverCss('menu')" (mouseleave)="leaveCss('menu')" (click)="inputSideNav!.toggle()">
		<mat-icon >menu</mat-icon>
	</button>

	<a routerLink="/home" >
		<img class="d-none d-lg-block img-brand" src="/assets/img/batsi-brand.svg" />
		<img class="d-block d-lg-none img-brand img-brand-sm" src="/assets/img/batsi-brand-sm.svg" />
	</a>

	<!-- <span class="spacer"></span> -->
	<span class="mx-auto" >
		<img class="img-brand-customers" src="{{ customLogo }}" *ngIf="!customLogo.includes('batsi-brand.svg')" />
		<!-- <img class="img-brand-customers" src="/assets/img/Fendt-Logo.png" /> -->
	</span>

	<span style="padding-right: 20px;">
		<button *ngIf="existPreview == true" mat-raised-button class="btn-warning m-1" (click)="previewTheme()">{{'modules.shared.stop-previewing-theme' | translate}}</button>
	</span>

	<!-- <span style="padding-right: 20px;">{{ nameUser }}</span> -->

	<div>
		<!-- Selector de idioma -->
		<app-language-selector></app-language-selector>

	  <a routerLink="/notifications/list" >
    	<mat-icon mat-icon-button id="notifications" (mouseover)="hoverCss('notifications')" (mouseleave)="leaveCss('notifications')" [matBadge]="notificationsCount"  >notifications</mat-icon>
			<span class="cdk-visually-hidden">
				{{'modules.notifications.notifications' | translate}}
			</span>
	    </a>
	</div>
</mat-toolbar>
