import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detail-join-trip',
  templateUrl: './detail-join-trip.component.html',
  styleUrls: ['./detail-join-trip.component.scss']
})
export class DetailJoinTripComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
