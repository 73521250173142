import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ListResult } from 'src/app/models/apiRequestObject';
import { DocManagerFile, DocManagerFolder } from 'src/app/models/docManager';
import { Role, User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DocManagerService } from 'src/app/services/doc-manager.service';
import { UsersService } from 'src/app/services/users.services';
import { UtilsService } from 'src/app/services/utils.service';
import { finalize, Subscription } from 'rxjs';

@Component({
  selector: 'app-add-file-media',
  templateUrl: './add-file-media.component.html',
  styleUrls: ['./add-file-media.component.scss']
})
export class AddFileMediaComponent implements OnInit {

  @Input() parentFolder?: DocManagerFolder | null;
  @Input() currentFile?: DocManagerFile | null;
  @Input() requiredFileType?:string;
  @Input() textInput: string = this.translate.instant('modules.modals.select-image') + '.';

  @Input() eventOnSubmit: boolean = false;
  @Input() showButtonCancel: boolean = false;
  @Output() fileOutput = new EventEmitter<DocManagerFile>();  
  @Output() newFileShared =  new EventEmitter<string>();
  @Output() waitUploadShared =  new EventEmitter<boolean>();

  loading: boolean = false;
  public roleAll: Role = new Role({Id: -1, InternName: 'Todos'});
  public newDocManagerFile: DocManagerFile = new DocManagerFile({'Name' : '', 'InternalName' : '', 'IsVisible' : true, 'IsPublic' : true , 'Priority' : 1});

  public rolesList: Array<Role> | null = null;
  dropdownClients: IDropdownSettings= {};
  public selectedClientsList: Array<User> = [];
  public clientsList: Array<User> = [];
  loadClients: boolean = false;
  loadClientsSelecteds: boolean = false;
  loadRoles: boolean = false;
  file: any;
  
  addForm: FormGroup = this.formBuilder.group({
  });

  waitUpload = false;
  urlNewFile: string|null = null;
  reader = new FileReader();
  fileName = '';
  uploadProgress:number|null = null;
  uploadSub: Subscription|null = null;

  private emitNewFileShared() {
    this.newFileShared.emit(this.urlNewFile!);
  }
  private emitWaitUploadShared() {
    this.waitUploadShared.emit(this.waitUpload);
  }

  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private usersSrv : UsersService,
    private utilsSrv : UtilsService,
    public authSrv : AuthenticationService,
    public mediaSrv: DocManagerService,
    private translate: TranslateService) { 
      
    }

  ngOnInit(): void {
    this.loading = true;
    this.newDocManagerFile.Role = this.roleAll;

    this.emitWaitUploadShared();

    if(this.currentFile &&  this.currentFile!.Id){
      console.log('currentFolder', this.currentFile);
      this.newDocManagerFile = { ...this.currentFile};

      if(this.newDocManagerFile.Role == null){
        this.newDocManagerFile.Role = this.roleAll;
      }
    }

    console.log('this.currentFile' , this.currentFile);

    this.addForm.addControl( 'Media' ,  this.formBuilder.group({
      Name: new FormControl(this.newDocManagerFile!.Name, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(25)
      ]),
      IsVisible: new FormControl(this.newDocManagerFile.IsVisible, [
        Validators.required
      ]),
      IsPublic: new FormControl(this.newDocManagerFile.IsPublic, [
        Validators.required
      ]),
      Role: new FormControl(this.newDocManagerFile.Role),
      IdRole: new FormControl(this.newDocManagerFile.IdRole),
      IdFolder: new FormControl(this.newDocManagerFile.IdFolder),
      SelectedClientsList: new FormControl(this.selectedClientsList)

    }));

    // Get roles
    this.rolesList = null; 
    
    if(this.newDocManagerFile.IdRole && this.newDocManagerFile.Role.Tag == 'user' ){
      this.getClientsUser();
    }else if(this.newDocManagerFile.IdRole && this.newDocManagerFile.Role.Tag == 'fabricant' ){
      this.getManufacturerUser();
    }else if(this.newDocManagerFile.IdRole && this.newDocManagerFile.Role.Tag == 'admin' ){
      this.getAdminUser();
    }else{      
      this.getAllUser();
    }
     
    this.usersSrv.getRoles().subscribe({
      next: (res: ListResult) => {
        this.rolesList =  <Role[]> res.Result; 
        this.rolesList.push(new Role({InternName: 'Todos'}))
  
  
      },error: (err: any) =>{
        console.log("error getRoles");
      }, complete: () =>{
        this.loadRoles = true;
        this.loadComplete();
      }
    });

    this.dropdownClients = {
      singleSelection: false,
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: true,
      idField: 'Id',
      textField: 'Name',
      selectAllText: this.translate.instant('modules.shared.select-all'),
      unSelectAllText: this.translate.instant('modules.shared.unselect-all'),
      allowSearchFilter: true,
      //noFilteredDataAvailablePlaceholderText: "No hay coincidencias",
      noDataAvailablePlaceholderText: this.translate.instant('modules.shared.no-users'),
      searchPlaceholderText: this.translate.instant('modules.shared.search-user'),
      clearSearchFilter: true,
    };
    
    // Folders
    this.newDocManagerFile.IdFolder = this.parentFolder?.Id;
  }

  get Name(){ return this.addForm.get("Media")!.get("Name"); }
  get IsVisible(){ return this.addForm.get("Media")!.get("IsVisible"); }
  get IsPublic(){ return this.addForm.get("Media")!.get("IsPublic"); }
  get IdRole(){ return this.addForm.get("Media")!.get("IdRole"); }
  get IdFolder(){ return this.addForm.get("Media")!.get("IdFolder")}
  get SelectedClientsList(){ return this.addForm.get("Media")!.get("SelectedClientsList"); }

  cancel() {

    this.dialog.getDialogById('FileMediaAddQuickComponent')?.close();
  }

  get showInput(): boolean{
    if(this.currentFile && this.currentFile!.Id){
      return false;
    }
    return true;
  }

  onSubmit(){
    this.loading = true;
    let isNotNew = this.currentFile && this.currentFile!.Id;
    if (!this.addForm!.valid || ( this.file == null && !isNotNew)   ){
      this.loading = false;
      return ;
    }

    this.newDocManagerFile.IdRole = this.newDocManagerFile.Role?.Id;
    
    this.newDocManagerFile.Users = this.selectedClientsList;
    
    if(this.currentFile &&  this.currentFile!.Id){

      console.log('saveFileById'   );
      this.mediaSrv.saveFileById(this.newDocManagerFile).subscribe({
        next: (res: DocManagerFile) =>{
          console.log('eventOnSubmit' , this.eventOnSubmit );
          if(this.eventOnSubmit){
            console.log('res' , res );

            this.emitFile({ ...res });

          }else{

            console.log('DocumentoModal' , this.eventOnSubmit );
            this.utilsSrv.infoModal('Documento modificado correctamente',undefined);


          }

        },
        error: (err: any)=>{
          console.log("error create");
        }, 
        complete: () =>{
          this.loading = false;
        }

      });


    }else{

      
      const formData = new FormData();
      formData.append("File", this.file);
      formData.append("Name", this.newDocManagerFile.Name!);
      if(this.newDocManagerFile!.IdFolder){
        formData.append("IdFolder", this.newDocManagerFile.IdFolder!.toString());
      } 
      if(this.newDocManagerFile!.IdRole){
        formData.append("IdRole", this.newDocManagerFile.IdRole!.toString());
      } 
      formData.append("IsVisible", this.newDocManagerFile.IsVisible?'1':'0');
      formData.append("IsPublic", this.newDocManagerFile.IsPublic?'1':'0'); 

      this.mediaSrv.file(formData).subscribe({
        next: (res: DocManagerFile) =>{
          if(this.eventOnSubmit){

            this.emitFile({ ...res });

          }else{

            this.utilsSrv.infoModal('Documento creado correctamente',undefined);


          }
        }


      }
      );
    }
  }

  private emitFile(resultFile: DocManagerFile) {
    this.fileOutput.emit(resultFile);
  }


  getAllUser(){
    this.loading = true;
    this.loadClients = false;
    this.clientsList = [];
    this.usersSrv.getList(0, 10000, 'Name' , 'ASC' ).subscribe({
      next: (res: ListResult) => {

        this.clientsList = <User[]>res.Result;
        console.log(this.clientsList);

        this.selectedClientsList = [];
        this.newDocManagerFile.Users?.forEach( x=> {
          this.selectedClientsList.push(new User({...x}));
        });

      },error: (err: any) =>{
        console.log("error getClients");
        this.loadClients = true;
        this.loadComplete();
      }, complete: () =>{
        this.loadClients = true;
        this.loadComplete();

      }
    });
  }

  getManufacturerUser(){
    this.loading = true;
    this.loadClients = false;
    this.clientsList = [];
    this.usersSrv.getManufacturers(0, 10000, 'Name' , 'ASC' ).subscribe({
      next: (res: ListResult) => {

        this.clientsList = <User[]>res.Result;
        console.log(this.clientsList);

        this.selectedClientsList = [];
        this.newDocManagerFile.Users?.forEach( x=> {
          this.selectedClientsList.push(new User({...x}));
        });

      },error: (err: any) =>{
        console.log("error getClients");
        this.loadClients = true;
        this.loadComplete();
      }, complete: () =>{
        this.loadClients = true;
        this.loadComplete();

      }
    });
  }

  getClientsUser(){
    this.loading = true;
    this.loadClients = false;
    this.clientsList = [];
    this.usersSrv.getClients(0, 10000, 'Name' , 'ASC' ).subscribe({
      next: (res: ListResult) => {

        this.clientsList = <User[]>res.Result;
        console.log(this.clientsList);

        this.selectedClientsList = [];
        this.newDocManagerFile.Users?.forEach( x=> {
          this.selectedClientsList.push(new User({...x}));
        });

      },error: (err: any) =>{
        console.log("error getClients");
        this.loadClients = true;
        this.loadComplete();
      }, complete: () =>{
        this.loadClients = true;
        this.loadComplete();

      }
    });
  }

  getAdminUser(){
    this.loading = true;
    this.loadClients = false;
    this.clientsList = [];
    this.usersSrv.getUsersByRole('admins', 0, 10000, 'Name' , 'ASC' ).subscribe({
      next: (res: ListResult) => {

        this.clientsList = <User[]>res.Result;
        console.log(this.clientsList);

        this.selectedClientsList = [];
        this.newDocManagerFile.Users?.forEach( x=> {
          this.selectedClientsList.push(new User({...x}));
        });

      },error: (err: any) =>{
        console.log("error getClients");
        this.loadClients = true;
        this.loadComplete();
      }, complete: () =>{
        this.loadClients = true;
        this.loadComplete();

      }
    });
  }

  

  loadComplete(){
    if(this.loadClients == true   && this.loadRoles == true){
      this.loading = false; 
    }
  }

  onFileSelected(event: any) {

    const file:File = event.target.files[0];

    this.file = file;
    
    if (file) {
      this.fileName = file.name;
    }
    return;
    /*
    this.waitUpload = true;
    this.urlNewFile  = null;

    if (file) {
        this.fileName = file.name;
        const formData = new FormData();
        formData.append("File", file);
        formData.append("Name", this.newDocManagerFile.Name!);
        if(this.newDocManagerFile!.IdFolder){
          formData.append("IdFolder", this.newDocManagerFile.IdFolder!.toString());
        } 
        if(this.newDocManagerFile!.IdRole){
          formData.append("IdRole", this.newDocManagerFile.IdRole!.toString());
        } 
        formData.append("IsVisible", this.newDocManagerFile.IsVisible?'1':'0');
        formData.append("IsPublic", this.newDocManagerFile.IsPublic?'1':'0'); 

      

        console.log(this.fileName);

        //const upload$ = this.userSrv.uploadLogoTheme(file , this.user!.Id!);
        //upload$.subscribe();

        const upload$ = this.filesSrv.upload(formData );

        this.uploadSub = upload$.subscribe(event => {
          if (event.type == HttpEventType.UploadProgress) {
            this.uploadProgress = Math.round(100 * (event.loaded / event!.total!));
          }
          if (event.type == HttpEventType.Response){
            console.log("finish");
            console.log(event.body);
            this.urlNewFile = event.body;
            this.emitNewFileShared();
            this.waitUpload = false;

          }
        },error =>{
          console.log("Error")
        }
        );
    }else{

      this.waitUpload = false;
    }*/
  }

  
  changeRole(event: any){
    console.log(event.Tag);
    console.log(this.selectedClientsList );

    this.newDocManagerFile.Users = [];
    this.selectedClientsList = [];
    this.addForm.get("Media")!.get("SelectedClientsList")!.setValue([]); 

    if(event.Tag == 'user' ){
      this.getClientsUser();
    }else if(event.Tag == 'fabricant' ){
      this.getManufacturerUser();
    }else if(event.Tag == 'admin'){  
      this.getAdminUser();
    }else{
      this.getAllUser();

    }
    console.log(this.selectedClientsList );
    
  }

}
