import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { InfoModalComponent } from 'src/app/modals/info-modal/info-modal.component';
import { ApiRequestObject, ListResult } from 'src/app/models/apiRequestObject';
import { DatatableColumn, DatatableConfig } from 'src/app/models/datatableColumn';
import { Device, DeviceModel } from 'src/app/models/device';
import { LinkModal, Modal } from 'src/app/models/modal';
import { Notification } from 'src/app/models/notifications';
import { User } from 'src/app/models/user';
import { AlarmsService } from 'src/app/services/alarms.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { UsersService } from 'src/app/services/users.services';
import { UtilsService } from 'src/app/services/utils.service';

declare const $: any

@Component({
  selector: 'app-list-notifications',
  templateUrl: './list-notifications.component.html',
  styleUrls: ['./list-notifications.component.scss']
})
export class ListNotificationsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() userInput: User | null = null;
  @Input() modelInput: DeviceModel | null = null;
  @Input() nameDatatable: string = "NotificationsList";

  @Input() device?: Device;

  @Input() isManufacturer: boolean | null = null;
  @Input() isClient: boolean | null = null;

  @Input() onlyProcessed: boolean = false;
  @Input() onlyNoProcessed: boolean = false;

  @Output() confirmChange = new EventEmitter<void>();
  @Output() numberTotal = new EventEmitter<number>();

  @ViewChild(DataTableDirective, {static: false}) datatableElement?: DataTableDirective;

  /* Datatable parameters */
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  isDtInitialized: boolean = false;
  datatableConfigColumns: DatatableConfig = new DatatableConfig();
  viewPaging: boolean = true;
  selectedItems: boolean = false;

  get isViewManufacturer(): boolean{

    if(this.userInput){
      return this.userInput.isManufacturer;
    }
    return false;
  }

  get isViewClient(): boolean{

    if(this.userInput){
      return this.userInput.isClient;
    }
    return false;
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next(0);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    console.log("ngOnChanges");
    this.rerender();
  }

  listaNotificaciones: Notification[] = [];
  selectedNotificationsList: Notification[] = [];
  selectedState: number = 1;

  loading: boolean = true;
  loadingDelete: boolean = false;
  loadingDtOptions: boolean = true;

  constructor(
    public dialog: MatDialog,
    public authSrv: AuthenticationService,
    private router : Router,
    private usersSrv : UsersService,
    private notificationSrv : NotificationsService,
    private utilsSrv : UtilsService,
    private translate: TranslateService) {
      // QQ. Afegit per a que recarregi la pagina al anar a una altra notificacio
      this.router.routeReuseStrategy.shouldReuseRoute = () => {
        return false;
      };

    }

  ngOnInit(): void {

    this.dtOptions = this.optionsDatatable();
  }



  public optionsDatatable() {
    const that = this;


    this.datatableConfigColumns.headerTable = [
      new DatatableColumn(0,"#select", "", "asc"),
      new DatatableColumn(1,"IMEI", "IMEI", "asc"),
      new DatatableColumn(2,"Sensor", "Data", "asc"),
      new DatatableColumn(3,this.translate.instant('modules.tables.value'), "Value", "asc"),
      new DatatableColumn(4,this.translate.instant('modules.tables.date'), "RealDate", "desc"),
      new DatatableColumn(5,this.translate.instant('modules.tables.actions'), "", "asc")
    ];

    console.log("isManufacturer" , this.isManufacturer);
    console.log("isClient" , this.isClient);
    console.log("userInput" , this.userInput);

    if(this.userInput){
      if(this.userInput.isClient){
        //this.datatableConfigColumns.hideColumnByName("Usuario");
      }
    }


    this.datatableConfigColumns.changeOrderByTag("RealDate");
    this.datatableConfigColumns.orderDesc();

    console.log(this.datatableConfigColumns.order);
    console.log(this.datatableConfigColumns.columnDefs);

    this.loadingDtOptions = false;

    return {
      name: this.nameDatatable,
      order: this.datatableConfigColumns.order,
      columnDefs: this.datatableConfigColumns.columnDefs,
      lengthChange: true,
      serverSide: true,
      processing: false,
      retrieve: true,
      //stateSave: true,
      deferRender: true,
      searchDelay: 750,
      initComplete: (settings: DataTables.Settings, json: any) => {
        $("#" + this.nameDatatable).wrap("<div class='demo' style='overflow:auto; width:100%;position:relative;'></div>");

        let idAux = "#" + this.nameDatatable + "_wrapper";
        console.log("this.viewPaging" , this.viewPaging, idAux);

        if(!this.viewPaging){
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().parent().find("div").eq(0).removeClass("col-md-5").addClass("col-md-12");
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().css("display", "none" );
        }else{
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().parent().find("div").eq(0).removeClass("col-md-12").addClass("col-md-5");
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().css("display", "block" );
        }
      },
      ajax: (dataTablesParameters: any, callback: any) => {

        that.loading = true;

        let column = dataTablesParameters.order[0].column;
        let colmunOrder = this.datatableConfigColumns.getColumnById(column);
        let colmunOrderTag = colmunOrder?.tag!;
        let order = dataTablesParameters.order[0].dir;
        let draw = dataTablesParameters.draw;

        dataTablesParameters.search.regex = true
        let page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length);
        this.listaNotificaciones = [];


        console.log(colmunOrder);

        console.log(this.onlyProcessed, this.onlyNoProcessed);

        that.notificationSrv.getList(page, dataTablesParameters.length, colmunOrderTag, order, dataTablesParameters.search.value, this.device??undefined, this.modelInput??undefined, this.onlyProcessed, this.onlyNoProcessed).subscribe(
          {
            next: (res: ListResult) => {

            //that.listaUsuarios = res.result.map(x => new User (x));
            //that.listaDispositivos = <vDevice[]>res.Result;
            that.listaNotificaciones = <Notification[]>res.Result.map( x => new Notification(x));

            // Activa los checklist de los modelos seleccionados entre paginas
            for (let index = 0; index < this.listaNotificaciones.length; index++) {
              const notification = this.listaNotificaciones[index];
              if (this.selectedNotificationsList.some(n => n.Id === notification.Id)) {
                notification.Selected = true;
              }
            }
            this.checkSelectedState();

            this.viewPaging = false;
            if(res.Filter > 10){
              this.viewPaging = true;
            }

            console.warn('Registros: ',that.listaNotificaciones.length)

            callback({
              recordsTotal: res.Total,
              recordsFiltered: res.Filter,
              data: []
            });

            //this.rerender();
            //this.dtTrigger.next(0);
            this.selectedItems = false;
            that.loading = false;
            that.numberTotal.next(res.Total);

            //$('.dataTables_empty').css('display', 'none');

            if (res.Result.length > 0) {
              $('.dataTables_empty').css('display', 'none');

              that.columnAdjust();
            }else{
              //this.dtTrigger.next(0);
              //$('.dataTables_empty').css('display', 'none');

            }
            //document.querySelectorAll<HTMLElement>('.dataTables_empty')[0].style.display = 'none';



          },
          error: ( err : any) => {
            that.loading = false;
          }
        });


      },

      headerCallback: (thead: any, data: any, start: any, end: any, display: any) => {

      },
      language: {
        url: this.utilsSrv.DatatablesFileLang()
      },
      infoCallback: function( settings: any, start:number, end:number, max:number, total:number, pre: any ) {
        return that.datatableConfigColumns.DatatablesFooterShowText(settings, start,end, max, total , pre );
      },
    };

  }

  columnAdjust(): void {
    $($.fn.dataTable.tables(true)).DataTable().columns.adjust().responsive.recalc();
  }

  rerender(){

    // Buida la llista d'elemens seleccionats
    this.selectedNotificationsList = [];
    this.selectedState = 1;
    this.selectedItems = false;

    if(this.datatableElement){
      this.datatableElement!.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear().destroy();
        this.dtTrigger.next(0);
      });
    }
  }

  checkuncheckall(notification: Notification)
  {
    notification.Selected = !notification.Selected;

    this.selectedItems = false;
    for(let i = 0; i < this.listaNotificaciones.length; i++){
      if(this.listaNotificaciones[i].Selected == true){
        this.selectedItems = true;
        break;
      }
    }
    if (notification.Selected == true) {
      if (!this.selectedNotificationsList.some(n => n.Id === notification.Id)) {
        this.selectedNotificationsList.push(notification);
      }
    } else {
      this.selectedNotificationsList.splice(this.selectedNotificationsList.findIndex(n => n.Id === notification.Id), 1);
    }
    this.checkSelectedState();
  }

  selectUnselectAll() {
    if (this.listaNotificaciones.some( m => m.Selected === false )) {
      if (this.selectedNotificationsList.length === 0){
        this.selectedNotificationsList = [...this.listaNotificaciones];
        for (let index = 0; index < this.listaNotificaciones.length; index++) {
          this.listaNotificaciones[index].Selected = true;
        }
      } else {
        for (let index = 0; index < this.listaNotificaciones.length; index++) {
          const unique = this.selectedNotificationsList.findIndex(model => model.Id === this.listaNotificaciones[index].Id);
          if (unique === -1) {
            this.selectedNotificationsList.push(this.listaNotificaciones[index]);
            this.selectedNotificationsList[ this.selectedNotificationsList.length-1].Selected = true;
          }
        }
      }
      this.selectedItems = true;
    } else {
      for (let index = 0; index < this.listaNotificaciones.length; index++) {
        this.listaNotificaciones[index].Selected = false;
        this.selectedNotificationsList.splice(this.selectedNotificationsList.findIndex(model => model.Id === this.listaNotificaciones[index].Id), 1);
      }
      this.selectedItems = false;
    }
    this.checkSelectedState();
  }

  checkSelectedState() {
    if (this.selectedNotificationsList.length === 0) {
      this.selectedState = 1;
    } else if (!this.listaNotificaciones.some( m => m.Selected === false )) {
      this.selectedState = 2
    } else {
      this.selectedState = 3;
    }
  }

  readCheck(){
    console.log("check" , this.onlyNoProcessed, this.onlyProcessed  );
    let allowedModels = this.selectedNotificationsList.filter(m => m.Processed! == false);
    let notAllowedModels = this.selectedNotificationsList.filter(m => m.Processed! == true);

    if(this.onlyProcessed == true){
      allowedModels = this.selectedNotificationsList.filter(m => m.Processed! == true);
      notAllowedModels = this.selectedNotificationsList.filter(m => m.Processed! == false);
    }

    let contador = allowedModels.length;
    if(contador == 0 ){
      this.checkDeleteAll(contador, allowedModels);
    }
    this.loadingDelete = true;

    for (let index = 0; index < allowedModels.length; index++) {
      const notification = allowedModels[index];


      console.log("check" , this.onlyNoProcessed, this.onlyProcessed  );

      if(this.onlyNoProcessed == true){

        this.notificationSrv.checkRead(notification).subscribe({
          next: (res: Notification) => {
            contador--;
            if(res.Processed == true){
              allowedModels.splice(allowedModels.indexOf(notification), 1);

              this.authSrv.changeNotification(false);
            }
            this.checkDeleteAll(contador, allowedModels);
          },
          error: (err: ApiRequestObject) =>{
            contador--;
            this.checkDeleteAll(contador, allowedModels);
          }
        });
      }

      if(this.onlyProcessed == true){
        this.notificationSrv.uncheckRead(notification).subscribe({
          next: (res: Notification) => {
            contador--;
            if(res.Processed == false){
              allowedModels.splice(allowedModels.indexOf(notification), 1);
              this.authSrv.changeNotification(true);
            }
            this.checkDeleteAll(contador, allowedModels);
          },
          error: (err: ApiRequestObject) =>{
            contador--;
            this.checkDeleteAll(contador, allowedModels);
          }
        });

      }
    }
  }

  checkDeleteAll(contador: number, items: Array<Notification>): void {
    items = Object.assign([], items)
    // console.log(contador);
    // console.log(items);

    if (contador === 0) {
      if (items.length > 0) {
        // console.log('error');
        this.utilsSrv.infoModal(this.translate.instant('modules.modals.server-problems' + '.'));
        this.loadingDelete = false;
      }
      else if (this.loadingDelete == true) {
        this.loadingDelete = false;
        // console.log("finalizada eliminación masiva");
        this.confirmChange.next();

        this.rerender();
      } else {
        this.loadingDelete = false;
      }
    }
  }





}
