
<app-loading-custom [loading]="loading"></app-loading-custom>

<ng-container *ngIf="loading == false && user">

<form class="text-start p-2" [formGroup]="themeForm" (ngSubmit)="onSubmit()" autocomplete="off" >

<h2 class="text-center mt-3 mb-3">{{'modules.tables.theme' | translate}}</h2>
<mat-card fxFlex fxLayoutAlign="start center" fxLayout="column" class="solution"
    [style.borderRadius.px]="'0'"  [style.paddingTop.px]="'20'" [style.marginBottom.px]="'20'" formGroupName="Theme">

    <div class="form-group text-start">

        <div class="container">
            <div class="pretty p-icon p-round">
                <input type="radio" formControlName="DefaultTheme"  [value]="true"  required [(ngModel)]="user!.DefaultTheme"
                />
                <div class="state p-primary  text-start">
                    <i class="icon mdi mdi-check"></i>
                    <label>{{'modules.users.default' | translate}}</label>
                </div>
            </div>

            <div class="pretty p-icon p-round">
                <input type="radio" formControlName="DefaultTheme" [value]="false"  required [(ngModel)]="user!.DefaultTheme"
                />
                <div class="state p-success">
                    <i class="icon mdi mdi-check"></i>
                    <label>{{'modules.users.custom' | translate}}</label>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="user!.DefaultTheme == false ">

        <mat-divider></mat-divider>

        <h3>{{'modules.users.logo' | translate}} </h3>

        <div class="form-group-parent">
            <img class="img-brand" src={{user!.Theme!.HeadLogoColor!}} (click)="openUploadFileModal()" />
        </div>

        <ng-container >
          <mat-divider [ngClass]="(authSrv.isManufacturer())?'d-none':''"></mat-divider>

          <h3 [ngClass]="(authSrv.isManufacturer())?'d-none':''">{{'modules.users.header' | translate}}</h3>
          <div [ngClass]="(authSrv.isManufacturer())?'d-none':''" class="form-group-parent">
              <div class="form-group">
                  <label for="HeadColor">{{'modules.users.header' | translate}} - {{'modules.users.bg-color' | translate}}</label>
                  <input formControlName="HeadColor"   [(ngModel)]="user!.Theme!.HeadColor"
                      [style.background]="user!.Theme!.HeadColor"
                      [(colorPicker)]="user!.Theme!.HeadColor"
                      [cpEyeDropper]="true"
                      [cpSaveClickOutside]="false"
                      [cpOKButton]="true"
                      [cpCancelButton]="true"
                      [cpCancelButtonText]="'modules.shared.cancel' | translate"
                      [cpOutputFormat]="'hex'"
                      [cpCancelButtonClass]= "'btn btn-primary btn-xs'"
                      [cpOKButtonClass]= "'btn btn-primary btn-xs'"/>

              </div>

              <div class="form-group">
                  <label for="HeadIconColor">{{'modules.users.header' | translate}} - {{'modules.users.text-icons-color' | translate}}</label>
                  <input formControlName="HeadIconColor"   [(ngModel)]="user!.Theme!.HeadIconColor" [(colorPicker)]="user!.Theme!.HeadIconColor" [style.background]="user!.Theme!.HeadIconColor"
                      [cpEyeDropper]="true"
                      [cpSaveClickOutside]="false"
                      [cpOKButton]="true"
                      [cpCancelButton]="true"
                      [cpCancelButtonText]="'modules.shared.cancel' | translate"
                      [cpOutputFormat]="'hex'"
                      [cpCancelButtonClass]= "'btn btn-primary btn-xs'"
                      [cpOKButtonClass]= "'btn btn-primary btn-xs'"/>

              </div>

              <div class="form-group">
                  <label for="HeadIconHoverColor">{{'modules.users.header' | translate}} - {{'modules.users.text-icons-change-color' | translate}}</label>
                  <input formControlName="HeadIconHoverColor"   [(ngModel)]="user!.Theme!.HeadIconHoverColor"
                      [style.background]="user!.Theme!.HeadIconHoverColor"
                      [(colorPicker)]="user!.Theme!.HeadIconHoverColor"
                      [cpEyeDropper]="true"
                      [cpSaveClickOutside]="false"
                      [cpOKButton]="true"
                      [cpCancelButton]="true"
                      [cpCancelButtonText]="'modules.shared.cancel' | translate"
                      [cpOutputFormat]="'hex'"
                      [cpCancelButtonClass]= "'btn btn-primary btn-xs'"
                      [cpOKButtonClass]= "'btn btn-primary btn-xs'"/>
              </div>
          </div>

          <mat-divider [ngClass]="(authSrv.isManufacturer())?'d-none':''" ></mat-divider>

          <h3 [ngClass]="(authSrv.isManufacturer())?'d-none':''">{{'modules.users.side-top-menu' | translate}}</h3>
          <div [ngClass]="(authSrv.isManufacturer())?'d-none':''" class="form-group-parent">
              <div class="form-group">
                  <label for="MenuColorTop">{{'modules.users.side-top-menu' | translate}} - {{'modules.users.gradient-color-top' | translate}}</label>
                  <input formControlName="MenuColorTop"   [(ngModel)]="user!.Theme!.MenuColorTop"
                      [style.background]="user!.Theme!.MenuColorTop"
                      [(colorPicker)]="user!.Theme!.MenuColorTop"
                      [cpEyeDropper]="true"
                      [cpSaveClickOutside]="false"
                      [cpOKButton]="true"
                      [cpCancelButton]="true"
                      [cpCancelButtonText]="'modules.shared.cancel' | translate"
                      [cpOutputFormat]="'hex'"
                      [cpCancelButtonClass]= "'btn btn-primary btn-xs'"
                      [cpOKButtonClass]= "'btn btn-primary btn-xs'"/>

              </div>
              <div class="form-group">
                  <label for="MenuColor">{{'modules.users.side-top-menu' | translate}} - {{'modules.users.gradient-color-middle' | translate}}</label>
                  <input formControlName="MenuColor"   [(ngModel)]="user!.Theme!.MenuColor"
                      [style.background]="user!.Theme!.MenuColor"
                      [(colorPicker)]="user!.Theme!.MenuColor"
                      [cpEyeDropper]="true"
                      [cpSaveClickOutside]="false"
                      [cpOKButton]="true"
                      [cpCancelButton]="true"
                      [cpCancelButtonText]="'modules.shared.cancel' | translate"
                      [cpOutputFormat]="'hex'"
                      [cpCancelButtonClass]= "'btn btn-primary btn-xs'"
                      [cpOKButtonClass]= "'btn btn-primary btn-xs'"/>

              </div>
              <div class="form-group">
                  <label for="MenuColorBottom">{{'modules.users.side-top-menu' | translate}} - {{'modules.users.gradient-color-bottom' | translate}}</label>
                  <input formControlName="MenuColorBottom"   [(ngModel)]="user!.Theme!.MenuColorBottom"
                      [style.background]="user!.Theme!.MenuColorBottom"
                      [(colorPicker)]="user!.Theme!.MenuColorBottom"
                      [cpEyeDropper]="true"
                      [cpSaveClickOutside]="false"
                      [cpOKButton]="true"
                      [cpCancelButton]="true"
                      [cpCancelButtonText]="'modules.shared.cancel' | translate"
                      [cpOutputFormat]="'hex'"
                      [cpCancelButtonClass]= "'btn btn-primary btn-xs'"
                      [cpOKButtonClass]= "'btn btn-primary btn-xs'"/>

              </div>

              <div class="form-group">
                  <label for="MenuTextColor">{{'modules.users.side-top-menu' | translate}} - {{'modules.users.text-icons-color' | translate}}</label>
                  <input formControlName="MenuTextColor"   [(ngModel)]="user!.Theme!.MenuTextColor" [(colorPicker)]="user!.Theme!.MenuTextColor" [style.background]="user!.Theme!.MenuTextColor"
                      [cpEyeDropper]="true"
                      [cpSaveClickOutside]="false"
                      [cpOKButton]="true"
                      [cpCancelButton]="true"
                      [cpCancelButtonText]="'modules.shared.cancel' | translate"
                      [cpOutputFormat]="'hex'"
                      [cpCancelButtonClass]= "'btn btn-primary btn-xs'"
                      [cpOKButtonClass]= "'btn btn-primary btn-xs'"/>

              </div>

              <div class="form-group">
                  <label for="MenuTextHoverColor">{{'modules.users.side-top-menu' | translate}} - {{'modules.users.text-icons-change-color' | translate}}</label>
                  <input formControlName="MenuTextHoverColor"   [(ngModel)]="user!.Theme!.MenuTextHoverColor"
                      [style.background]="user!.Theme!.MenuTextHoverColor"
                      [(colorPicker)]="user!.Theme!.MenuTextHoverColor"
                      [cpEyeDropper]="true"
                      [cpSaveClickOutside]="false"
                      [cpOKButton]="true"
                      [cpCancelButton]="true"
                      [cpCancelButtonText]="'modules.shared.cancel' | translate"
                      [cpOutputFormat]="'hex'"
                      [cpCancelButtonClass]= "'btn btn-primary btn-xs'"
                      [cpOKButtonClass]= "'btn btn-primary btn-xs'"/>
              </div>
          </div>


          <div [ngClass]="(authSrv.isManufacturer())?'d-none':''" class="form-group">
              <label for="MenuTextActiveColor">{{'modules.users.side-top-menu' | translate}} - {{'modules.users.active-menu-color' | translate}}</label>
              <input formControlName="MenuTextActiveColor"   [(ngModel)]="user!.Theme!.MenuTextActiveColor"
                  [style.background]="user!.Theme!.MenuTextActiveColor"
                  [(colorPicker)]="user!.Theme!.MenuTextActiveColor"
                  [cpEyeDropper]="true"
                  [cpSaveClickOutside]="false"
                  [cpOKButton]="true"
                  [cpCancelButton]="true"
                  [cpCancelButtonText]="'modules.shared.cancel' | translate"
                  [cpOutputFormat]="'hex'"
                  [cpCancelButtonClass]= "'btn btn-primary btn-xs'"
                  [cpOKButtonClass]= "'btn btn-primary btn-xs'"/>
          </div>

          <mat-divider [ngClass]="(authSrv.isManufacturer())?'d-none':''"></mat-divider>

          <h3 [ngClass]="(authSrv.isManufacturer())?'d-none':''">{{'modules.users.side-bottom-menu' | translate}}</h3>
          <div [ngClass]="(authSrv.isManufacturer())?'d-none':''" class="form-group-parent">
              <div class="form-group">
                  <label for="MenuBottomColor">{{'modules.users.side-bottom-menu' | translate}} - {{'modules.users.bg-color' | translate}}</label>
                  <input formControlName="MenuBottomColor"   [(ngModel)]="user!.Theme!.MenuBottomColor"
                      [style.background]="user!.Theme!.MenuBottomColor"
                      [(colorPicker)]="user!.Theme!.MenuBottomColor"
                      [cpEyeDropper]="true"
                      [cpSaveClickOutside]="false"
                      [cpOKButton]="true"
                      [cpCancelButton]="true"
                      [cpCancelButtonText]="'modules.shared.cancel' | translate"
                      [cpOutputFormat]="'hex'"
                      [cpCancelButtonClass]= "'btn btn-primary btn-xs'"
                      [cpOKButtonClass]= "'btn btn-primary btn-xs'"/>

              </div>

              <div class="form-group">
                  <label for="MenuBottomTextColor">{{'modules.users.side-bottom-menu' | translate}} - {{'modules.users.text-icons-color' | translate}}</label>
                  <input formControlName="MenuBottomTextColor"   [(ngModel)]="user!.Theme!.MenuBottomTextColor" [(colorPicker)]="user!.Theme!.MenuBottomTextColor" [style.background]="user!.Theme!.MenuBottomTextColor"
                      [cpEyeDropper]="true"
                      [cpSaveClickOutside]="false"
                      [cpOKButton]="true"
                      [cpCancelButton]="true"
                      [cpCancelButtonText]="'modules.shared.cancel' | translate"
                      [cpOutputFormat]="'hex'"
                      [cpCancelButtonClass]= "'btn btn-primary btn-xs'"
                      [cpOKButtonClass]= "'btn btn-primary btn-xs'"/>

              </div>

              <div class="form-group">
                  <label for="MenuBottomTextHoverColor">{{'modules.users.side-bottom-menu' | translate}} - {{'modules.users.text-icons-change-color' | translate}}</label>
                  <input formControlName="MenuBottomTextHoverColor"   [(ngModel)]="user!.Theme!.MenuBottomTextHoverColor"
                      [style.background]="user!.Theme!.MenuBottomTextHoverColor"
                      [(colorPicker)]="user!.Theme!.MenuBottomTextHoverColor"
                      [cpEyeDropper]="true"
                      [cpSaveClickOutside]="false"
                      [cpOKButton]="true"
                      [cpCancelButton]="true"
                      [cpCancelButtonText]="'modules.shared.cancel' | translate"
                      [cpOutputFormat]="'hex'"
                      [cpCancelButtonClass]= "'btn btn-primary btn-xs'"
                      [cpOKButtonClass]= "'btn btn-primary btn-xs'"/>
              </div>


              <div class="form-group">
                  <label for="MenuBottomTextActiveColor">{{'modules.users.side-bottom-menu' | translate}} - {{'modules.users.active-menu-color' | translate}}</label>
                  <input formControlName="MenuBottomTextActiveColor"   [(ngModel)]="user!.Theme!.MenuBottomTextActiveColor"
                      [style.background]="user!.Theme!.MenuBottomTextActiveColor"
                      [(colorPicker)]="user!.Theme!.MenuBottomTextActiveColor"
                      [cpEyeDropper]="true"
                      [cpSaveClickOutside]="false"
                      [cpOKButton]="true"
                      [cpCancelButton]="true"
                      [cpCancelButtonText]="'modules.shared.cancel' | translate"
                      [cpOutputFormat]="'hex'"
                      [cpCancelButtonClass]= "'btn btn-primary btn-xs'"
                      [cpOKButtonClass]= "'btn btn-primary btn-xs'"/>
              </div>
          </div>
        </ng-container>
    </ng-container>

</mat-card>

</form>


    <ng-container *ngIf="user!.DefaultTheme == false && authSrv.isAdmin() ">
        <section class="mt-2">
            <div class="text-right" style="flex-direction: row-reverse;">
                <button mat-raised-button class="btn btn-warning m-1" (click)="previewTheme()">{{'modules.users.preview-theme' | translate}}</button>
            </div>
        </section>

    </ng-container>


</ng-container>
