import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Role, User, vUser } from 'src/app/models/user';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ListResult } from 'src/app/models/apiRequestObject';
import { UsersService } from 'src/app/services/users.services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-users',
  templateUrl: './search-users.component.html',
  styleUrls: ['./search-users.component.scss']
})
export class SearchUsersComponent implements OnInit {

  @Input() userInput: User | null = null;  
  @Input() roleInput: string | null = null;

  @Output() usersOutput = new EventEmitter<Array<vUser>>();

  
  @Input() nameField: string = "";
  @Input() placeholder: string = "";  
  @Input() idField: string = "Id";
  @Input() textField: string = "Name";
  @Input() noDataAvailablePlaceholderText: string = this.translate.instant('modules.shared.no-records');
  @Input() autoClose: boolean = true;
  
  
  dropdownSettingsUser : IDropdownSettings= {};  
  loading: boolean = false;
  public userList: Array<vUser> | null= null;
  public userListSelected: Array<vUser> = [];

  constructor(
    private usersSrv: UsersService,
    private translate: TranslateService) { 
  }

  ngOnInit(): void {

    this.loading = true;
    
    this.dropdownSettingsUser = {
      singleSelection: true,
      idField: this.idField,
      textField: this.textField,
      selectAllText: this.translate.instant('modules.shared.select-all'),
      unSelectAllText: this.translate.instant('modules.shared.unselect-all'),
      allowSearchFilter: true,
      closeDropDownOnSelection: this.autoClose,
      //noFilteredDataAvailablePlaceholderText: "No hay coincidencias",
      noDataAvailablePlaceholderText: this.noDataAvailablePlaceholderText,
      searchPlaceholderText: this.translate.instant('modules.shared.search'),
      clearSearchFilter: true,
    };

    this.getUsers();
  
  }


  getUsers(){

    if(this.roleInput){

      if(this.roleInput == "user"){
        this.getClients();

      }else if(this.roleInput == "fabricant"){
        this.getClients();

      }

    }

  }

  getClients(){
    this.loading = true;
    this.userList = null;

      this.usersSrv.getUsersByRole((this.roleInput! == 'user')?'clients':'manufacturers', 0, 10000, 'Name' , 'ASC'  ).subscribe({
        next: (res: ListResult) => {

          this.userList = <vUser[]>res.Result;
          console.log("getUsersByRole");

          /*if(this.isClient){
            this.newDevice.IdUser = this.userInput!.Id;
            this.newDevice.Client = [ this.userInput! ];          
          }*/
          this.emitUsers(this.userListSelected);

        },error: (err: any) =>{
          console.log("error getClients");
        }, complete: () =>{
          this.loading = false;
        }
      });
    

  }

  get getClient() {
    return this.userList!.reduce((acc:any , curr: vUser) => {
      acc[curr.Id!] = curr;
      return acc;
    }, {});
  }

  onItemSelect(items: any) {
    this.emitUsers(this.userListSelected);
  }

  private emitUsers(resultUser: Array<vUser>) {
    this.usersOutput.emit(resultUser);
  }
}
