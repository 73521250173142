import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-edit-user-address',
  templateUrl: './edit-user-address.component.html',
  styleUrls: ['./edit-user-address.component.scss']
})
export class EditUserAddressComponent implements OnInit {
  @Input() user?: User;
  @Input() page?: number;
  userEdit?: User;
  loading:boolean = true;

  addressForm: FormGroup = this.formBuilder.group({
  });

  @Output() addressFormShared = new EventEmitter<FormGroup>();

  private emitFormGeneral() {
    this.addressFormShared.emit(this.addressForm);
  }



  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public authSrv : AuthenticationService,) { }

  ngOnInit(): void {

    this.userEdit = this.user;
    this.addressForm.addControl('Address',this.addAddressForm());
    this.emitFormGeneral();

    this.loading = false;
  }

  onSubmit(){
    console.log(this.user);
  }


  get Address(){ return this.addressForm.get("Address")!.get("Address"); }
  get CP(){ return this.addressForm.get("Address")!.get("CP"); }
  get Population(){ return this.addressForm.get("Address")!.get("Population"); }
  get Location(){ return this.addressForm.get("Address")!.get("Location");   }
  get Country(){ return this.addressForm.get("Address")!.get("Country"); }

  addAddressForm() {
    return this.formBuilder.group({
      Address: new FormControl(this.user!.Address, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(250)
      ]),
      CP: new FormControl(this.user!.CP, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5)
      ]),
      Population: new FormControl(this.user!.Population, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(250)
      ]),
      Location: new FormControl(this.user!.Location, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(250)
      ]),
      Country: new FormControl(this.user!.Country, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(250)
      ])
    });
  }
}
