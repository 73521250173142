<ol class="breadcrumb" *ngIf="breadcrumb">
  <li class="breadcrumb-item" *ngFor="let ruta of breadcrumb.ruta ; let i = index ">
    <span *ngIf="breadcrumb.ruta.length - 1 > i ">
      <a routerLink="/{{ruta.ruta}}">{{ ruta.nombre }}</a>
      </span>
    <span  *ngIf="breadcrumb.ruta.length - 1 == i ">{{ ruta.nombre }}</span>
  </li>
  <!-- <li class="breadcrumb-item active">
    <span tabindex="0">Dashboard</span>
  </li> -->
</ol>
