

<mat-toolbar color="{{ data.ClassType}}"  class="bg-gradient-custom">
  <mat-toolbar-row>
    <span>{{ data.Title }}</span>
    <span class="example-spacer"></span>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="cancel()" >
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
  </mat-toolbar>

<!-- <h2 mat-dialog-title class="bg-{{ data.ClassType}}">{{ data.Title }}</h2> -->
<div>

  <div class="container">
      <div  class="row" *ngIf="deviceModel">
        <div class="col-12">

          <div class="m-3" *ngIf="userAuth?.isAdmin">
            {{'modules.devices.current-model' | translate}}: <strong>{{ deviceModel!.Name??'' }}</strong> {{'modules.shared.of' | translate}} <strong>{{ deviceModel!.Manufacturer!.Name }}</strong>.
          </div>
          <div class="m-3" *ngIf="userAuth?.isManufacturer">
            {{'modules.devices.current-model' | translate}}: <strong>{{ deviceModel!.Name??'' }}</strong>.
          </div>
      </div>


    </div>

    <div  class="row" *ngIf="deviceModel">
      <div class="col-12">

          <div class="m-3">
              <app-search-device-models
                  [nameField]="''"
                  [placeholder]="labelText"
                  [deviceModelInput]="deviceModel"
                  [idField]="'Id'"
                  [textField]="'Name'"
                  [userInput]="userAuth"
                  [noDataAvailablePlaceholderText]="noDataAvailablePlaceholderText"
                  (deviceModelsOutput)="updateModel($event)">
              </app-search-device-models>
          </div>
      </div>


    </div>


  <div class="row" *ngIf="loading == false">
    <div class="col-6">

      <button class="btn btn-danger btn-block my-4 w-100 mb-0" type="submit" (click)="cancel()" >
        {{ data.BtnCancel | translate }}
      </button>

    </div>
    <div class="col-6">
      <button class="btn btn-primary btn-block my-4 w-100 mb-0" type="submit" (click)="accept()" [disabled]="(deviceModelsSelected && deviceModelsSelected.length > 0)? null : 'disabled'"  >
        {{ data.BtnAccept | translate }}
      </button>


    </div>

  </div>


</div>

</div>

