import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AuthorizatedGuard } from './guards/authorizated-guard';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';

const routes: Routes = [
  {
    path: 'auth',
    component: LoginLayoutComponent,
    loadChildren: () => import('src/app/layouts/login-layout/login-layout.module').then(m => m.LoginLayoutModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: '',
    component: DashboardLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/layouts/dashboard-layout/dashboard-layout.module').then(m => m.DashboardLayoutModule),
        canLoad: [ AuthorizatedGuard ],
        canActivate: [ AuthorizatedGuard ],
      }
    ],
  },
  { path: '**', redirectTo: 'auth' }

];

@NgModule({
  // QQ : Afegit per a que recarregui les grafiques desde el llsitat de notificacions
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
