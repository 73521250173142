<app-breadcrumb></app-breadcrumb>


<div class="container-fluid pt-3 pl-0 pr-0 espace">

  <app-loading-custom [loading]="loading"></app-loading-custom>

  <app-block-group-dashboard *ngIf="loading == false">
    <app-block-dashboard>
    <app-timeline-apex-chart *ngIf="device" [device]="device" [idAlert]="idAlert"  [showTitle]="true" ></app-timeline-apex-chart>
      <!--
      <app-detail-chart  *ngIf="device" [device]="device" [showTitle]="true"></app-detail-chart>

      <app-timeline-chart  *ngIf="device" [device]="device" [showTitle]="false"></app-timeline-chart>
      -->

      <!-- <app-apex-chart></app-apex-chart> -->
    </app-block-dashboard>

  </app-block-group-dashboard>




</div>
<app-footer></app-footer>
