import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { MatSidenav } from '@angular/material/sidenav';
import { ThemeService } from 'src/app/services/theme.services';
import { Theme } from 'src/app/models/theme';
import { environment } from '../../../../../environments/environment';

declare var $: any;


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() inputSideNav?: MatSidenav;
  titulo : String;
  currentTheme : Theme = new Theme();

  public focus: any;
  public listTitles: any[] = [];
  public location: Location;
  hoverNotification: boolean = false;
  hoverMenu: boolean = false;
  existPreview:boolean = false;

  constructor(  location: Location,  private element: ElementRef, private router: Router,
    private themeSrv: ThemeService,
    public authenticationService: AuthenticationService) {
      this.location = location;
    this.titulo = "Dashboard";

    if(this.themeSrv.currentPreviewTheme()){
      this.currentTheme = this.themeSrv.currentPreviewTheme()!;
      this.existPreview = true;
    }else{
      this.currentTheme = this.themeSrv.currentTheme;
      this.existPreview = false;
    }

  }


  get notificationsCount(){
    if(this.authenticationService.currentUserValue?.NotificationsCount == 0){
        return null;
    }
    return this.authenticationService.currentUserValue?.NotificationsCount;
  }

  get nameUser(){
    return this.authenticationService.currentUserValue?.UsernameLong;
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void{
    // QQ : En certs casos no es pinta el sidebar correctament
    setTimeout(() => {
      this.setTheme();
    }, 100);

  }

  get customLogo(){
    return environment.BASE_URL + "/" + this.currentTheme.HeadLogoColor.replace( environment.BASE_URL+"/", "");
  }



  previewTheme(){
    this.themeSrv.deletePreviewTheme();
    this.currentTheme = this.themeSrv.currentTheme;
    this.setTheme();
    this.existPreview = false;

    window.open("/home", "_self");

  }

  setTheme(){
    $("mat-toolbar").css('background-color', this.currentTheme.HeadColor);
    $("mat-toolbar span").css('color', this.currentTheme.HeadIconColor);
    $("mat-icon").css('color', this.currentTheme.HeadIconColor);
    $("mat-badge-content").css('color', this.currentTheme.HeadIconColor);
  }

  getTitle(){
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if(titlee.charAt(0) === '#'){
        titlee = titlee.slice( 1 );
    }

    for(var item = 0; item < this.listTitles.length; item++){
        if(this.listTitles[item].path === titlee){
            return this.listTitles[item].title;
        }
    }
    return 'Dashboard';
  }

  public logout(): void{

    this.authenticationService.logout();

    window.location.reload();

  }

  public hoverCss(id: string){
    $("#" + id).css("color", this.currentTheme.HeadIconHoverColor);
    $("#" + id+ " mat-icon").css("color", this.currentTheme.HeadIconHoverColor);
  }

  public leaveCss(id: string){
    $("#" + id).css("color", this.currentTheme.HeadIconColor);
    $("#" + id+ " mat-icon").css("color", this.currentTheme.HeadIconColor);
  }

}
