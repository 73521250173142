import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { ApiRequestObject, ListResult } from '../models/apiRequestObject';
import { User } from '../models/user';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { AlarmDeviceModelDefaultValues, AlarmDeviceModelValues, AlarmDeviceValues } from '../models/alarms';
import { Device, DeviceModel } from '../models/device';
import { Notification } from '../models/notifications';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService  extends ApiService{

  constructor(private http: HttpClient, router: Router, authSrv: AuthenticationService) {
    super(router, authSrv);
  }

  getById(notification: Notification){
    return this.http.get<Notification>(`${environment.API_ENDPOINT}notifications/${notification.Id}`  ).pipe(
      catchError(this.error)
    );
  }
  checkRead(notification: Notification){
    notification.Processed= true;

    return this.http.patch<Notification>(`${environment.API_ENDPOINT}notifications/checkRead`, notification ).pipe(
      catchError(this.error)
    );
  }
  checkReadAll(): Observable<boolean>{
    return this.http.get<boolean>(`${environment.API_ENDPOINT}notifications/checkReadAll` ).pipe(
      catchError(this.error)
    );
  }

  uncheckRead(notification: Notification){
    notification.Processed= false;

    return this.http.patch<Notification>(`${environment.API_ENDPOINT}notifications/uncheckRead`, notification ).pipe(
      catchError(this.error)
    );
  }

  getList(page: number, offset: number, order: string, direction: string,  filter?: string,
    device?:Device, modelDevice?:DeviceModel,
    onlyProcessed: boolean = false, onlyNoProcessed: boolean= false ) : Observable<ListResult> {

      console.log(onlyProcessed, onlyNoProcessed);

    let onlyFilter=""
    if(onlyProcessed == true || onlyNoProcessed == true){

      if(onlyProcessed == true){
        onlyFilter="&filterProcessed=OnlyProcessed";
      }else{
        onlyFilter="&filterProcessed=OnlyNoProcessed";
      }
    }
    let deviceFilter=""
    if(device){
      deviceFilter = "&DeviceId="+device.Id;
    }

    let url = `${environment.API_ENDPOINT}notifications/liteList/${page}/${offset}/${order}/${direction}?filter=${filter}${onlyFilter}${deviceFilter}`;


    return this.http.get<ListResult>(url).pipe(
      catchError(this.error)
    );
  }
}
