import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { Breadcrumb, BreadcrumbDetail } from 'src/app/models/breadcrumb';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {
  @ViewChild('sideNav')  sideNav!: MatSidenav;
  
  public selectedItem : string = '';
  public close = false;

  @HostListener('window:resize', ['$event'])
    onResize(event?: { target: { innerWidth: number; }; } | undefined) {
      if(event){
          this.controlMenu(event.target.innerWidth)
      }else{
        this.controlMenu(window.innerWidth)
      }
    }

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result: BreakpointState) => result.matches));
    constructor( private cdRef:ChangeDetectorRef, private breakpointObserver: BreakpointObserver, private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    if(!this.breadcrumbService.getBreadCrumb()){
      let b : Breadcrumb;
      b = new Breadcrumb;
      b.ruta = new Array<BreadcrumbDetail>();
      b.nombre = new Array<string>();

      this.breadcrumbService.setBreadCrumb(b);
    }
  }

  controlMenu(w: number){

    if (w <= 991) {
      if(this.sideNav.opened == true){
        //this.sideNav.close();
        this.sideNav.close();
        this.close = true;
      }
    }else{
      if(this.sideNav.opened != true){
        this.sideNav.open();
        this.close = false;
      }
    }
  }

  ngAfterViewInit(){
    console.log(window.innerWidth);
    setTimeout( () => {
      this.onResize();
    }, 500 );


  }

}
