<app-loading-custom [loading]="loading"></app-loading-custom>

<ng-container *ngIf="user!.isManufacturer">

    <h2 class="text-center mt-3 mb-3">{{'modules.devices.models' | translate}}</h2>
    <mat-card fxFlex fxLayoutAlign="start center" fxLayout="column" class="solution" [style.borderRadius.px]="'0'"
      [style.paddingTop.px]="'20'" [style.marginBottom.px]="'20'" style="min-height: 400px;" >
  
      <app-list-device-models [userInput]="user" [showNewModel]="true"></app-list-device-models>
      
  
    </mat-card>
  
</ng-container>
