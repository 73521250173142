<app-loading-custom [loading]="loading"></app-loading-custom>


<ng-container *ngIf="loading == false && user">

  <form class="text-start p-2" [formGroup]="clientsForm" autocomplete="off">

    <ng-container *ngIf="!user!.isClient">

      <h2 class="text-center mt-3 mb-3">{{'modules.users.users' | translate}}</h2>
      <!-- <div class="text-right-label" style="flex-direction: row-reverse">  <span class="c-pointer" (click)="newDevice()">{{'modules.devices.exist' | translate}}</span></div> -->
      <mat-card fxFlex fxLayoutAlign="start center" fxLayout="column" class="solution" [style.borderRadius.px]="'0'"
        [style.paddingTop.px]="'20'" [style.marginBottom.px]="'20'" style="min-height: 400px;" formGroupName="Clients">


<ng-container *ngIf="loading == false ">

  <ng-multiselect-dropdown
  id="clients"
  formControlName="SelectedClientsList"
  [(ngModel)]="selectedClientsList"
  [placeholder]="'modules.users.users' | translate"
  [settings]="dropdownClients"
  [data]="clientsList">
  <!-- <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
    <div style="padding:1px 10px;margin-right:10px;right:2px;top:-2px;cursor: pointer !important;">
      <label style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
        {{option}}
      </label>
    </div>
  </ng-template>
  <ng-template #optionSelectedTemplate let-option="option" let-id="id">
    <div>
      {{option}}
    </div>
  </ng-template> -->
</ng-multiselect-dropdown>

</ng-container>

</mat-card>
</ng-container>

</form>


</ng-container>
