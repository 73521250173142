import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import {tap} from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { DataService } from '../services/datatransfer.service';
import { environment } from '../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private storageService: DataService, private router: Router, private as: AuthenticationService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        //console.log('intercept');
        //console.log(req)

        if(req.url.includes("/login") || req.url.includes("/mail/send")  ){
            return next.handle(req);
        }

        if(req.url == environment.SOCKET_SERVICE){
          return next.handle(req);

        }

        //console.log('No login');


        let request = req;

        if (this.as.getCurrentToken()) {
          const token : string = <string>this.as.getCurrentToken();

          //console.log("Bearer")
          //console.log(token);

            request = req.clone({
                setHeaders: {
                    authorization: `Bearer ${token}`
                }
            });
        }
        //console.log("reque3st")
        //console.log(request)

        return next.handle(request).pipe( tap(() => {},
            (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (((err.status == 401 && err.url?.includes("/api/device/") ) || err.status !== 401) && err.status !== 0) {
                    console.log("No 401 " , err.status  );
                    return;
                }
                this.as.logout();
                //this.router.navigate(['/auth/login']);
            }
            }));
    }

}
