import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RolesGuard } from 'src/app/guards/roles.guard';
import { DetailDefaultValuesAlarmPageComponent } from '../alarms/pages/detail-default-values-alarm-page/detail-default-values-alarm-page.component';
import { DetailDeviceAlarmPageComponent } from '../alarms/pages/detail-device-alarm-page/detail-device-alarm-page.component';
import { DetailModelAlarmPageComponent } from '../alarms/pages/detail-model-alarm-page/detail-model-alarm-page.component';
import { AddDevicePageComponent } from './pages/add-device-page/add-device-page.component';
import { AddModelDevicePageComponent } from './pages/add-model-device-page/add-model-device-page.component';
import { ConsoleDevicePageComponent } from './pages/console-device-page/console-device-page.component';
import { DetailDeviceModelPageComponent } from './pages/detail-device-model-page/detail-device-model-page.component';
import { DetailDevicePageComponent } from './pages/detail-device-page/detail-device-page.component';
import { ListDevicesPageComponent } from './pages/list-devices-page/list-devices-page.component';
import { LoadConfigurationPageComponent } from './pages/load-configuration-page/load-configuration-page.component';
import { LoadFirmwarePageComponent } from './pages/load-firmware-page/load-firmware-page.component';
import { DetailTripDevicePageComponent } from './pages/detail-trip-device-page/detail-trip-device-page.component';
import { TerminalTestComponent } from '../shared/components/terminal-test/terminal-test.component';
import { SplitTripDevicePageComponent } from './pages/split-trip-device-page/split-trip-device-page.component';
import { DetailJoinTripDevicePageComponent } from './pages/detail-join-trip-device-page/detail-join-trip-device-page.component';

const routes: Routes = [
  {
    path: '',
    children:  [
      { path: 'list', component: ListDevicesPageComponent},
      { path: 'load-firmware', component: LoadFirmwarePageComponent, canActivate: [ RolesGuard ], data: { role: ['admin'] }},
      { path: 'load-configuration', component: LoadConfigurationPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin'] }},
      { path: ':id/detail', component: DetailDevicePageComponent},
      { path: ':id/terminal', component: ConsoleDevicePageComponent},
      { path: ':id/detail/:page/alert/:idAlert', component: DetailDevicePageComponent},
      { path: ':id/detail/:page', component: DetailDevicePageComponent},
      //{ path: ':id/alarms/add', component: DetailDeviceAlarmPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant', 'user'] }},
      { path: ':id/alarms/:idAlarm', component: DetailDeviceAlarmPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant', 'user'] }},
      { path: ':id/alarms/model/:idAlarm', component: DetailModelAlarmPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant', 'user'] }},
      { path: ':id/alarms/default/:idAlarm', component: DetailDefaultValuesAlarmPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant', 'user'] }},

      { path: ':id/trips/:idTrip', component: DetailTripDevicePageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant', 'user'] }},
      { path: ':id/trips/:idTrip/split', component: SplitTripDevicePageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant', 'user'] }},
      { path: ':id/trips/:idTrip/join', component: DetailJoinTripDevicePageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant', 'user'] }},

      { path: 'add', component: AddDevicePageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant'] }},
      { path: 'add/:user', component: AddDevicePageComponent, canActivate: [ RolesGuard ], data: { role: ['admin'] }},
      { path: 'models/add', component: AddModelDevicePageComponent, canActivate: [ RolesGuard ], data: { role: ['admin'] }},
      { path: 'models/add/:user', component: AddModelDevicePageComponent, canActivate: [ RolesGuard ], data: { role: ['admin'] }},
      { path: 'models/:id/detail', component: DetailDeviceModelPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant', 'user'] }},
      { path: 'models/:id/detail/:page', component: DetailDeviceModelPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant', 'user'] }},
      { path: 'models/:id/alarms/:idAlarm', component: DetailModelAlarmPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant', 'user'] }},
      //{ path: 'models/:id/alarms/model/:idAlarm', component: DetailModelAlarmPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant', 'user'] }},
      { path: 'models/:id/alarms/default/:idAlarm', component: DetailDefaultValuesAlarmPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin'] }},
      { path: 'terminal-test', component: TerminalTestComponent, canActivate: [ RolesGuard ], data: { role: ['admin'] }},


      

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DevicesRoutingModule { }
