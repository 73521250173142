
<app-loading-custom [loading]="loading"></app-loading-custom>

<ng-container *ngIf="loading == false && user">

<form class="text-start p-2" [formGroup]="bankInfoForm" autocomplete="off" >

<h2 class="text-center mt-3 mb-3">{{'modules.tables.bank-data' | translate}}</h2>
<mat-card fxFlex fxLayoutAlign="start center" fxLayout="column" class="solution"
    [style.borderRadius.px]="'0'"  [style.paddingTop.px]="'20'" [style.marginBottom.px]="'20'" formGroupName="BankInformation">


            <div class="form-group">
                <label for="CreditCard">{{'modules.users.credit-card' | translate}}</label>
                <input type="text" class="form-control" id="CreditCard"  formControlName="CreditCard" required [(ngModel)]="user!.CreditCard" />

                    <div *ngIf="(user!.CreditCard && user!.CreditCard.length > 0) && CreditCard!.invalid && (CreditCard!.dirty || CreditCard!.touched)" class="alert alert-danger mt-3 text-start">

                        <div [hidden]="!CreditCard!.errors?.['minlength']">{{'modules.users.credit-card-length' | translate}}  {{ CreditCard!.errors?.['minlength']?.requiredLength}} {{'modules.users.digits' | translate}}.</div>
                        <div [hidden]="!CreditCard!.errors?.['maxlength']">{{'modules.users.credit-card-length' | translate}}  {{ CreditCard!.errors?.['maxlength']?.requiredLength}} {{'modules.users.digits' | translate}}.</div>
                    </div>
            </div>

            <div class="form-group" >
                <label for="CreditCardName">{{'modules.users.owner-name' | translate}}</label>
                <input type="text" class="form-control" id="CreditCardName" min="3" max="55"  formControlName="CreditCardName" required [(ngModel)]="user!.CreditCardName" />

                    <div *ngIf="(user!.CreditCardName && user!.CreditCardName.length > 0) && CreditCardName!.invalid && (CreditCardName!.dirty || CreditCardName!.touched )" class="alert alert-danger mt-3 text-start">
                        <div [hidden]="!CreditCardName!.errors?.['minlength']">{{'modules.shared.min' | translate}} {{ CreditCardName!.errors?.['minlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
                        <div [hidden]="!CreditCardName!.errors?.['maxlength']">{{'modules.shared.max' | translate}} {{ CreditCardName!.errors?.['maxlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
                    </div>

              </div>

              <div class="form-group mt-2" >
                <mat-form-field appearance="fill">
                  <mat-label>{{'modules.users.due-date' | translate}}</mat-label>
                  <input matInput [matDatepicker]="dp" formControlName="CreditCardDate" [(ngModel)]="endDate"  >
                  <mat-hint>{{'modules.users.month-year' | translate}}</mat-hint>
                  <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                  <mat-datepicker #dp
                                  startView="multi-year"
                                  (monthSelected)="setMonthAndYear($event, dp)"
                                  panelClass="example-month-picker">
                  </mat-datepicker>
                </mat-form-field>
<!--
                <label for="Population">{{'modules.users.due-date' | translate}}</label>
                <mat-form-field>
                  <input matInput class="form-control ml-2"  [matDatepicker]="dp2" placeholder="{{'modules.users.due-date' | translate}}" (click)="openDatePicker(dp2)" formControlName="CreditCardDate" [(ngModel)]="user!.CreditCardDate">
                  <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                  <mat-datepicker #dp2 startView="multi-year" (monthSelected)="closeDatePicker($event, dp2)"></mat-datepicker>
                </mat-form-field>-->

              </div>

</mat-card>

</form>


</ng-container>
