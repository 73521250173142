import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-load-firmware-page',
  templateUrl: './load-firmware-page.component.html',
  styleUrls: ['./load-firmware-page.component.scss']
})
export class LoadFirmwarePageComponent implements OnInit {

  ids: Array<number> = [];
  arrayIds: Array<number> = [206,205,204,203];
  loading = true;

  constructor(breadcrumbService: BreadcrumbService,
    public authSrv: AuthenticationService,
    private route: ActivatedRoute,
    private translate: TranslateService) {
      breadcrumbService.setBreadCrumbUrl('devices/list', this.translate.instant('modules.devices.management'));
      breadcrumbService.addBreadCrumb('devices/load-firmware', this.translate.instant('modules.devices.load-firmware'));

      this.route.queryParams
        .subscribe(params => {
          console.log(params);
          this.ids = params["ids"];
          console.log(this.ids);

          this.loading = false;
        }
      );

    }

  ngOnInit(): void {
  }

}
