
<app-loading-custom [loading]="loading"></app-loading-custom>

<ng-container *ngIf="loading == false && user">

<form class="text-start p-2" [formGroup]="editForm" (ngSubmit)="onSubmit()" autocomplete="off" >

<h2 class="text-center mt-3 mb-3">{{'modules.users.general-info' | translate}}</h2>



<mat-card fxFlex fxLayoutAlign="start center" fxLayout="column" class="solution"
    [style.borderRadius.px]="'0'"  [style.paddingTop.px]="'20'" [style.marginBottom.px]="'20'" formGroupName="User">

    <div class="form-group text-start" *ngIf="user!.Role!.Tag! == 'user'">
        <label for="icon_solid">{{'modules.users.client-type' | translate}}</label>

        <div class="container">
            <div class="pretty p-icon p-round">
                <input type="radio" formControlName="IsCompany"  [value]="false"  required [(ngModel)]="user!.IsCompany"
                (change)="changeCompany()" />
                <div class="state p-primary  text-start">
                    <i class="icon mdi mdi-check"></i>
                    <label>{{'modules.users.private-client' | translate}}</label>
                </div>
            </div>

            <div class="pretty p-icon p-round">
                <input type="radio" formControlName="IsCompany" [value]="true"  required [(ngModel)]="user!.IsCompany"
                (change)="changeCompany()" />
                <div class="state p-success">
                    <i class="icon mdi mdi-check"></i>
                    <label>{{'modules.users.company-client' | translate}}</label>
                </div>
            </div>
        </div>
    </div>

            <div class="form-group">
                <label for="Name">{{'modules.tables.name' | translate}}</label>
                <input type="text" class="form-control" id="Name"  formControlName="Name" required [(ngModel)]="user!.Name">

                <div *ngIf="Name!.invalid && (Name!.dirty || Name!.touched)" class="alert alert-danger mt-3 text-start">
                    <div [hidden]="!Name!.errors?.['required']">{{'modules.shared.name-required' | translate}}.</div>
                    <div [hidden]="!Name!.errors?.['minlength']">{{'modules.shared.min' | translate}} {{ Name!.errors?.['minlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
                    <div [hidden]="!Name!.errors?.['maxlength']">{{'modules.shared.max' | translate}} {{ Name!.errors?.['maxlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
                    <div [hidden]="!Name!.errors?.['pattern']">{{'modules.shared.num-not-allowed' | translate}}.</div>

                                   <!--  <div [hidden]="!Name!.hasError('minlength')">{{'modules.shared.min' | translate}} {{ editForm.get('User').get('Name').errors.minlength.requiredLength }} {{'modules.shared.characters' | translate}}.</div>
                    <div [hidden]="!Name!.hasError('pattern')">{{'modules.shared.num-not-allowed' | translate}}.</div>
                    <div [hidden]="Name!.hasError('maxLength')">{{'modules.shared.max' | translate}} {{ editForm.get('User').get('Name').errors.maxlength.requiredLength }} {{'modules.shared.characters' | translate}}.</div>
               -->
                </div>

            </div>

            <div class="form-group"  *ngIf="user!.Role!.Tag! == 'admin' || (user!.Role!.Tag! == 'user' && user!.IsCompany! == false)">
                <label for="FirstName">{{'modules.tables.first-name' | translate}}</label>
                <input type="text" class="form-control" id="FirstName"  formControlName="FirstName" required [(ngModel)]="user!.FirstName">

                    <div *ngIf="FirstName!.invalid && (FirstName!.dirty || FirstName!.touched)" class="alert alert-danger mt-3 text-start">
                        <div [hidden]="!FirstName!.errors?.['required']">{{'modules.shared.first-name-required' | translate}}.</div>
                        <div [hidden]="!FirstName!.errors?.['minlength']">{{'modules.shared.min' | translate}} {{ FirstName!.errors?.['minlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
                        <div [hidden]="!FirstName!.errors?.['maxlength']">{{'modules.shared.max' | translate}} {{ FirstName!.errors?.['maxlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
                        <div [hidden]="!FirstName!.errors?.['pattern']">{{'modules.shared.num-not-allowed' | translate}}.</div>
                    </div>

              </div>

              <div class="form-group"  *ngIf="user!.Role!.Tag! == 'admin' || (user!.Role!.Tag! == 'user' && user!.IsCompany! == false)">
                <label for="LastName">{{'modules.tables.last-name' | translate}}</label>
                  <input type="text" class="form-control" id="LastName"  formControlName="LastName" required
                      [(ngModel)]="user!.LastName">

                      <div *ngIf="LastName!.invalid && (LastName!.dirty || LastName!.touched)" class="alert alert-danger mt-3 text-start">

                        <div [hidden]="!LastName!.errors?.['required']">{{'modules.shared.last-name-required' | translate}}.</div>
                        <div [hidden]="!LastName!.errors?.['minlength']">{{'modules.shared.min' | translate}} {{ LastName!.errors?.['minlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
                        <div [hidden]="!LastName!.errors?.['maxlength']">{{'modules.shared.max' | translate}} {{ LastName!.errors?.['maxlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
                        <div [hidden]="!LastName!.errors?.['pattern']">{{'modules.shared.num-not-allowed' | translate}}.</div>
                      </div>
              </div>



            <div class="form-group text-start" *ngIf="user!.Role!.Tag! == 'user' || user!.Role!.Tag! == 'fabricant'">
                <label for="TypeDocument">{{'modules.users.type-document' | translate}}</label>

                <div class="container">
                    <div class="pretty p-icon p-round">
                        <input type="radio" formControlName="TypeDocument"  [value]="false"  required [(ngModel)]="user.TypeDocument"
                        />
                        <div class="state p-primary  text-start">
                            <i class="icon mdi mdi-check"></i>
                            <label>DNI</label>
                        </div>
                    </div>

                    <div class="pretty p-icon p-round">
                        <input type="radio" formControlName="TypeDocument" [value]="true"  required [(ngModel)]="user.TypeDocument"
                       />
                        <div class="state p-success">
                            <i class="icon mdi mdi-check"></i>
                            <label>NIF</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="user!.Role!.Tag! == 'user' || user!.Role!.Tag! == 'fabricant'">
                <label *ngIf="user.TypeDocument == false" for="DNICIF">DNI</label>
                <label  *ngIf="user.TypeDocument == true" for="DNICIF">NIF</label>
                <input type="text" class="form-control" id="DNICIF"  formControlName="DNICIF" required [(ngModel)]="user.DNICIF">

                    <!-- <div [hidden]="Name!.valid || Name!.pristine" class="alert alert-danger">
                        {{'modules.shared.name-required' | translate}}.
                    </div> -->
                    <div *ngIf="DNICIF!.invalid && (DNICIF!.dirty || DNICIF!.touched)" class="alert alert-danger mt-3 text-start">

                        <div [hidden]="(!DNICIF!.errors?.['required'])">
                            <ng-container *ngIf="user.TypeDocument == false">
                                {{'modules.shared.dni-required' | translate}}.
                            </ng-container>
                            <ng-container *ngIf="user.TypeDocument == true">
                                {{'modules.shared.nif-required' | translate}}.
                            </ng-container>
                        </div>
                        <!-- <div [hidden]="(!DNICIF!.errors?.['required']) && user.TypeDocument == true">{{'modules.shared.nif-required' | translate}}.</div> -->
                        <div [hidden]="!DNICIF!.errors?.['minlength']">{{'modules.shared.min' | translate}} {{ DNICIF!.errors?.['minlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
                        <div [hidden]="!DNICIF!.errors?.['maxlength']">{{'modules.shared.max' | translate}} {{ DNICIF!.errors?.['maxlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>

                    </div>
            </div>


            <div class="form-group"  *ngIf="user!.Role!.Tag! == 'admin'">
                <label for="WorkerCode">{{'modules.users.worker-code' | translate}}</label>
                  <input type="text" class="form-control" id="WorkerCode"  formControlName="WorkerCode" required [(ngModel)]="user!.WorkerCode">

                      <div *ngIf="WorkerCode!.invalid && (WorkerCode!.dirty || WorkerCode!.touched)" class="alert alert-danger mt-3 text-start">

                        <div [hidden]="!WorkerCode!.errors?.['required']">{{'modules.users.worker-code-required' | translate}}.</div>
                        <div [hidden]="!WorkerCode!.errors?.['minlength']">{{'modules.shared.min' | translate}} {{ WorkerCode!.errors?.['minlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
                        <div [hidden]="!WorkerCode!.errors?.['maxlength']">{{'modules.shared.max' | translate}} {{ WorkerCode!.errors?.['maxlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>

                    </div>
              </div>

              <!--
              <div class="form-group" *ngIf="user!.Role!.Tag! == 'user' && authSrv.isManufacturer() == false">
                <label for="CreditCard">{{'modules.users.credit-card' | translate}}</label>
                  <input type="text" class="form-control" id="CreditCard"  formControlName="CreditCard" required [(ngModel)]="user!.CreditCard">

                      <div *ngIf="CreditCard!.invalid && (CreditCard!.dirty || CreditCard!.touched)" class="alert alert-danger mt-3 text-start">

                        <div [hidden]="!CreditCard!.errors?.['required']">{{'modules.users.credit-card-required' | translate}}.</div>

                    </div>
              </div>-->

              <div class="form-group text-start" *ngIf="authSrv.isManufacturer() && user!.Role!.Tag! == 'user'">
                <label for="IsSeller">{{'modules.users.is-seller' | translate}}?</label>

                <div class="container">
                    <div class="pretty p-icon p-round">
                        <input type="radio" formControlName="IsSeller"  [value]="true"  required [(ngModel)]="user!.IsSeller"
                        />
                        <div class="state p-success  text-start">
                            <i class="icon mdi mdi-check"></i>
                            <label>{{'modules.shared.yes' | translate}}</label>
                        </div>
                    </div>

                    <div class="pretty p-icon p-round">
                        <input type="radio" formControlName="IsSeller" [value]="false"  required [(ngModel)]="user!.IsSeller"
                       />
                        <div class="state p-danger">
                            <i class="icon mdi mdi-check"></i>
                            <label>{{'modules.shared.no' | translate}}</label>
                        </div>
                    </div>
                </div>
            </div>


</mat-card>

</form>

</ng-container>
