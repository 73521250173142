import { Device, DeviceModel, vDevice } from "./device";
import { Theme } from "./theme";


export class User   {
  Id?: number;
  IdRole?: number;
  Name?: string;
  FirstName?: string;
  LastName?: number;
  Phone1?: string;
  Phone2?: string;
  Email?: string;
  DNICIF?: string;
  Active?: boolean;
  TripsModuleEnabled?: boolean;
  DocManagerModuleEnabled?: boolean;
  State?: number;
  CardCode?: string;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  Address?: string;
  CP?: string;
  Population?: string;
  Location?: string;
  Province?: string;
  Country?: string;
  TypeDocument?: boolean;
  ContactName?: string;
  IsSeller?: boolean;
  ServiceStart?: Date;
  ServiceEnd?: Date;
  WorkerCode?: string;
  IsCompany?: boolean;
  CreditCardName?: string;
  CreditCardDate?: Date;

  Role?: Role;
  GroupMasterUser?: User[];
  GroupSlaveUser?: User[];
  Theme?: Theme;
  DefaultTheme?:boolean;
  IconRole?:string;
  CreditCard?: string;
  vDevices?:vDevice[];
  Devices?:Device[];
  NotificationsCount?: number;

  Manufacturers?: User[];
  Clients?: User[];

  Language?: string;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);

    if(this.CreditCardDate){
      this.CreditCardDate = new Date(this.CreditCardDate);
    }
  }

  get isAdmin(){
    return this.Role!.Tag == 'admin'?true:false;
  }
  get isManufacturer(){
    return this.Role!.Tag == 'fabricant'?true:false;
  }
  get isClient(){
    return this.Role!.Tag == 'user'?true:false;
  }

  setIconRole(): void{
    if(this.Role?.Tag == 'user'){
      this.IconRole = "fa fa-user";
    }else if(this.Role?.Tag! == 'admin'){
      this.IconRole = "fas fa-user-shield";
    }else if(this.Role?.Tag! == 'admin-dev'){
      this.IconRole = "fas fa-user-shield";
    }else if(this.Role?.Tag! == 'fabricant'){
      this.IconRole = "fas fa-users";
    }else if(this.Role?.Tag! == 'test'){
      this.IconRole = "fa fa-users-cog";
    }else{
      this.IconRole = "";
    }

  }
}

export class UserWithPassword extends User   {

  CurrentPassword?: string;
  NewPassword?: string;
  NewPassordRepeat?: string;

  constructor(values: Object = {}) {
    super(values);
    (<any>Object).assign(this, values);
  }

}

export class vUser{

  Id?: number;
  IdRole?: number;
  Name?: string;
  FirstName?: string;
  LastName?: number;
  Email?: string;
  Active?: boolean;
  State?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  NumDevices?: number;
  NumClients?: number;
  NumManufacturers?: number;

  NameRole?: string;
  TagRole?: string;

  IconRole?:string;
  Role?: Role;

  NotificationsCount?: number;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

  get isAdmin(){
    return this.TagRole == 'admin'?true:false;
  }
  get isManufacturer(){
    return this.TagRole == 'fabricant'?true:false;
  }
  get isClient(){
    return this.TagRole == 'user'?true:false;
  }

  setIconRole(): void{
    if(this.TagRole == 'user'){
      this.IconRole = "fa fa-user";
    }else if(this.TagRole == 'admin'){
      this.IconRole = "fas fa-user-shield";
    }else if(this.TagRole == 'admin-dev'){
      this.IconRole = "fas fa-user-shield";
    }else if(this.TagRole == 'fabricant'){
      this.IconRole = "fas fa-users";
    }else if(this.TagRole == 'test'){
      this.IconRole = "fa fa-users-cog";
    }else{
      this.IconRole = "";
    }

  }

}


export class Manufacturer extends User {

  deviceModels?:Array<DeviceModel>;


}

export class Role {
  Id?: number;
  Tag?: string;
  Name?: string;
  InternName?: string;
  Icon?: string = 'asas';


  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

  setIcon(){
    if (this.Tag){
      if(this.Tag! == 'user'){
        this.Icon = "fa fa-user";
      }else if(this.Tag! == 'admin'){
        this.Icon =  "fas fa-user-shield";
      }else if(this.Tag! == 'admin-dev'){
        this.Icon =  "fas fa-user-shield";
      }else if(this.Tag! == 'fabricant'){
        this.Icon =  "fas fa-users";
      }else if(this.Tag! == 'test'){
        this.Icon =  "fa fa-users-cog";
      }
    }
    this.Icon =  "1123";
  }
}


export class Register {
  Nombre?: number;
  Apellido1?: string;
  Apellido2?: string;
  Telefono1?: string;
  Telefono2?: string;
  Email?: string;
  Roles_Id?: number;
  Password?: string;
  DNICIF?: string;
  NombreEmpresa?: string;
  NombreComercialExplotacion?: string;
  CuentaBancaria?: string;
  DireccionFiscal?: string;
  DireccionEntrega?: string;
  TarjetaPago?: string;
  CardValid? : Date
  OwnerIdNum? : string
}


