import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocManagerFile, DocManagerFolder } from 'src/app/models/docManager';
import { Modal } from 'src/app/models/modal';

@Component({
  selector: 'app-file-media-add-quick',
  templateUrl: './file-media-add-quick.component.html',
  styleUrls: ['./file-media-add-quick.component.scss']
})
export class FileMediaAddQuickComponent implements OnInit {

  parentFolder?: DocManagerFolder;
  fileOutput: DocManagerFile = new DocManagerFile();
  eventOnSubmit: boolean = false;

  constructor(private dialogRef: MatDialogRef<FileMediaAddQuickComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Modal) { 

      if(this.data!.Data && this.data!.Data!.ParentFolder){
        this.fileOutput.IdFolder = this.data!.Data!.ParentFolder.Id;
        this.parentFolder = this.data!.Data!.ParentFolder;
      }else{
        this.fileOutput.IdFolder = undefined; 
      }

      if(this.data!.Data && this.data!.Data!){
        this.fileOutput = this.data!.Data!;
      }

      if(this.data!.Data && this.data!.Data!.eventOnSubmit){
        this.eventOnSubmit = this.data!.Data!.eventOnSubmit;
      }
  
  }
      

  ngOnInit(): void {
  }

  emitFile(fileOutput: DocManagerFile){

    console.log('emitFile' , fileOutput );
    this.fileOutput = fileOutput;
    this.dialogRef.close(fileOutput);
  }

  accept(){
    this.dialogRef.close();
  }

  cancel(){
    this.dialogRef.close(null);
  }

  onSubmit(){
    console.log(this.fileOutput);
  }


}
