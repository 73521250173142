import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ListResult } from 'src/app/models/apiRequestObject';
import { DatatableColumn, DatatableConfig } from 'src/app/models/datatableColumn';
import { Device } from 'src/app/models/device';
import { Modal } from 'src/app/models/modal';
import { vTrip } from 'src/app/models/trip';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TripsService } from 'src/app/services/trips.service';
import { UsersService } from 'src/app/services/users.services';
import { UtilsService } from 'src/app/services/utils.service';
import { ConfirmationModalComponent } from 'src/app/modals/confirmation-modal/confirmation-modal.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateTime, Settings, } from "luxon";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomLuxonDateAdapter } from 'src/app/modules/charts/components/timeline-apex-chart/customDateAdapter';
import { LuxonDateAdapter, MAT_LUXON_DATE_FORMATS, MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';



declare const $: any

@Component({
  selector: 'app-list-trips',
  templateUrl: './list-trips.component.html',
  styleUrls: ['./list-trips.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: DateAdapter,
      useClass: CustomLuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS},
  ]
})
export class ListTripsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() device?: Device;
  @Input() userInput: User | null = null;
  @Input() showFilter?: Boolean = false;

  filterChartForm: FormGroup = this.formBuilder.group({
  });
  
  loading: boolean = true;
  loadingDelete: boolean = false;
  loadingDtOptions: boolean = true;

  @Input() isManufacturer: boolean | null = null;
  @Input() isClient: boolean | null = null;
  @Input() isDevicePage: boolean   = false;
  
  @ViewChild(DataTableDirective, {static: false}) datatableElement?: DataTableDirective;
  InformationFirstValue: DateTime | null= null;
  InformationLastValue: DateTime  | null = null;
  
  /* Datatable parameters */
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  nameDatatable: string = "TripList";
  isDtInitialized: boolean = false;
  datatableConfigColumns: DatatableConfig = new DatatableConfig();
  viewPaging: boolean = true;
  selectedItems: boolean = false;
  
  tripsList: vTrip[] = [];
  selectedTripsList: vTrip[] = [];
  selectedState: number = 1;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private translate: TranslateService,
    private router : Router,
    public authSrv: AuthenticationService,
    private usersSrv : UsersService,
    private tripSrv : TripsService,
    private utilsSrv : UtilsService,
    private _adapter: DateAdapter<any>
  ) {
    this._adapter.setLocale(localStorage.getItem('lang'));
  }

  ngOnInit(): void {
    this.dtOptions = this.optionsDatatable();

    
    this.filterChartForm = this.formBuilder.group({
      InformationFirst: new FormControl(this.InformationFirstValue, [
        Validators.required
      ]),
      InformationLast: new FormControl(this.InformationLastValue, [
        Validators.required
      ]) 
    });
  }


  ngAfterViewInit(): void {

    if(this.userInput){
      console.log("userInput");
      console.log(this.userInput);
    }

    console.log("ngAfterViewInit");
      const that = this;

      that.dtTrigger.next(0);
}
  public ngOnChanges(changes: SimpleChanges): void {
    console.log("ngOnChanges");
    this.rerender();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  public optionsDatatable() {
    const that = this;


    this.datatableConfigColumns.headerTable = [
      new DatatableColumn(0,"#select", "", "asc"),
      new DatatableColumn(1,"#", "", "asc"),
      new DatatableColumn(2,this.translate.instant('modules.trips.start'), "StartedDate", "desc"),
      new DatatableColumn(3,this.translate.instant('modules.trips.end'), "FinishedDate", "desc"),
      new DatatableColumn(4,this.translate.instant('modules.trips.alerts'), "AlarmCount", "desc"),
      new DatatableColumn(5,"IMEI", "IMEI", "asc"),
      new DatatableColumn(6,this.translate.instant('modules.tables.device-name'), "NameDevice", "asc"),
      new DatatableColumn(7,this.translate.instant('modules.tables.serial-number'), "NumSerieManufacturer", "asc"),
      new DatatableColumn(8,this.translate.instant('modules.tables.comments'), "Comments", "asc"),
      new DatatableColumn(9,this.translate.instant('modules.tables.name-client'), "NameClient", "asc"),
      new DatatableColumn(10,this.translate.instant('modules.tables.account'), "NameManufacturer", "asc"),
      new DatatableColumn(11,this.translate.instant('modules.trips.avg-voltage'), "VoltageValue", "asc"),
      new DatatableColumn(12,this.translate.instant('modules.trips.operator'), "MobileOperator", "asc"),
      new DatatableColumn(13,this.translate.instant('modules.tables.created-at'), "CreatedAt", "desc"),
      new DatatableColumn(14,this.translate.instant('modules.tables.actions'), "", "asc")
    ];

    console.log("isManufacturer" , this.isManufacturer);
    console.log("isClient" , this.isClient);
    console.log("userInput" , this.userInput);

    if(this.userInput){
      if(this.userInput.isClient){
        this.datatableConfigColumns.hideColumnByTag("VoltageValue");
        this.datatableConfigColumns.hideColumnByTag("MobileOperator");
      }
    }

    if(this.authSrv.isAdmin() == false && this.authSrv.isManufacturer() == false ){
      this.datatableConfigColumns.hideColumnByTag("NumSerieManufacturer");
      this.datatableConfigColumns.hideColumnByTag("Comments");
    }

    if(this.authSrv.isAdmin() == false){

      this.datatableConfigColumns.hideColumnByTag("VoltageValue");
      this.datatableConfigColumns.hideColumnByTag("MobileOperator");
    }

    if(this.authSrv.isClient()){
      this.datatableConfigColumns.hideColumnByTag("CreatedAt");
    }


    if( (this.isManufacturer != null && this.isManufacturer == true) || (this.userInput != null && this.userInput.isManufacturer == true) ){
      this.datatableConfigColumns.hideColumnByTag("NameManufacturer");
    }

    if(this.isClient != null && this.isClient == true){
      this.datatableConfigColumns.hideColumnByTag("NameClient");
    }

    this.datatableConfigColumns.changeOrderByTag("CreatedAt");
    this.datatableConfigColumns.orderDesc();

    console.log(this.datatableConfigColumns.order);
    console.log(this.datatableConfigColumns.columnDefs);

    this.loadingDtOptions = false;

    return {
      name: this.nameDatatable,
      order: this.datatableConfigColumns.order,
      columnDefs: this.datatableConfigColumns.columnDefs,
      lengthChange: true,
      serverSide: true,
      processing: false,
      retrieve: true,
      //stateSave: true,
      deferRender: true,
      initComplete: (settings: DataTables.Settings, json: any) => {
        $("#" + this.nameDatatable).wrap("<div class='demo' style='overflow:auto; width:100%;position:relative;'></div>");
        if(!this.viewPaging){
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().parent().find("div").eq(0).removeClass("col-md-5").addClass("col-md-12");
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().css("display", "none" );
        }else{
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().parent().find("div").eq(0).removeClass("col-md-12").addClass("col-md-5");
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().css("display", "block" );
        }
      },
      ajax: (dataTablesParameters: any, callback: any) => {

        that.loading = true;
        console.log('InformationFirstValue ' , this.InformationFirstValue);
        console.log('InformationLastValue ' , this.InformationLastValue);

        let column = dataTablesParameters.order[0].column;
        let colmunOrder = this.datatableConfigColumns.getColumnById(column);
        let colmunOrderTag = colmunOrder?.tag!;
        let order = dataTablesParameters.order[0].dir;
        let draw = dataTablesParameters.draw;

        dataTablesParameters.search.regex = true
        let page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length);
        this.tripsList = [];


        console.log(colmunOrder);
        console.log(this.device);

        that.tripSrv.getList(page, dataTablesParameters.length, colmunOrderTag, order, dataTablesParameters.search.value, undefined, (this.device)?this.device!.Id:undefined, this.InformationFirstValue??undefined, this.InformationLastValue??undefined).subscribe(
          {
            next: (res: ListResult) => {

            //that.listaUsuarios = res.result.map(x => new User (x));
            //that.tripsList = <vTrip[]>res.Result;
            that.tripsList = <vTrip[]>res.Result.map( x => new vTrip(x));

            // Activa los checklist de los modelos seleccionados entre paginas
            for (let index = 0; index < this.tripsList.length; index++) {
              const trip = this.tripsList[index];
              if (this.selectedTripsList.some(n => n.Id === trip.Id)) {
                trip.Selected = true;
              }              
            }
            this.checkSelectedState();

            this.viewPaging = false;
            if(res.Filter > 10){
              this.viewPaging = true;
            }

            callback({
              recordsTotal: res.Total,
              recordsFiltered: res.Filter,
              data: []
            });

            //this.rerender();
            //this.dtTrigger.next(0);
            this.selectedItems = false;
            that.loading = false;

            //$('.dataTables_empty').css('display', 'none');

            if (res.Result.length > 0) {
              $('.dataTables_empty').css('display', 'none');
            }else{
              //this.dtTrigger.next(0);
              //$('.dataTables_empty').css('display', 'none');

            }
            //document.querySelectorAll<HTMLElement>('.dataTables_empty')[0].style.display = 'none';
            that.columnAdjust();


          },
          error: ( err : any) => {
            that.loading = false;
          }
        });


      },

      headerCallback: (thead: any, data: any, start: any, end: any, display: any) => {

      },
      language: {
        url: this.utilsSrv.DatatablesFileLang()
      },
      infoCallback: function( settings: any, start:number, end:number, max:number, total:number, pre: any ) {
        return that.datatableConfigColumns.DatatablesFooterShowText(settings, start,end, max, total , pre );
      },
    };

  }

  columnAdjust(): void {
    $($.fn.dataTable.tables(true)).DataTable().columns.adjust().responsive.recalc();
  }

  rerender(){

    this.selectedTripsList = [];
    this.selectedState = 1;
    this.selectedItems = false;

    if(this.datatableElement){
      this.datatableElement!.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear().destroy();
        this.dtTrigger.next(0);
      });
    }
  }

  
  get isViewManufacturer(): boolean{

    if(this.userInput){
      return this.userInput.isManufacturer;
    }
    return false;
  }

  get isViewClient(): boolean{

    if(this.userInput){
      return this.userInput.isClient;
    }
    return false;
  }



  get urlDetail(): string{

    if(this.device){
      return `/devices/${this.device.Id}/trips/`;
    }
    return '/trips/'
  }

  
  deleteTrip(trip: vTrip){

    let modal = new Modal();
    modal.Title = this.translate.instant('modules.shared.warning');
    modal.Body = this.translate.instant('modules.modals.delete-trip') + " #" + trip.Id + ". " + this.translate.instant('modules.modals.are-you-sure');
    modal.BtnAccept = this.translate.instant('modules.shared.delete')
    modal.BtnCancel = this.translate.instant('modules.shared.cancel');
    modal.ClassType = "danger";

    const dialogRef = this.dialog.open(
      ConfirmationModalComponent,
      {
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: modal,
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe( (result: boolean) => {


      if(result){
        this.loadingDelete = true;

        console.log("deleteTrip");
        this.tripSrv.deleteById(trip).subscribe(
          {
            next: (res: boolean) => {
              this.loadingDelete = false;

              if(res==true){
                this.utilsSrv.infoModal(this.translate.instant('modules.modals.deleted-trip-success') + '.',undefined);
                this.rerender();
              }else{            
                this.utilsSrv.infoModal(this.translate.instant('modules.modals.delete-trip-error') + '.',undefined);
              }
            },
            error: (err) =>{
              this.loadingDelete = false;
              this.utilsSrv.infoModal(this.translate.instant('modules.modals.delete-trip-error') + '.',undefined);

            }
          }
        )

        
      }
    });

    

  }

  checkuncheckall(trip: vTrip)
  {

    if(!trip.Selected == true && this.selectedTripsList.length > 1){
      return;
    }
    trip.Selected = !trip.Selected;

    this.selectedItems = false;
    for(let i = 0; i < this.tripsList.length; i++){
      if(this.tripsList[i].Selected == true){
        this.selectedItems = true;
        break;
      }
    }
    if (trip.Selected == true) {
      if (!this.selectedTripsList.some(n => n.Id === trip.Id)) {
        this.selectedTripsList.push(trip);
      }
    } else {
      this.selectedTripsList.splice(this.selectedTripsList.findIndex(n => n.Id === trip.Id), 1);
    }
    this.checkSelectedState();
  }

  selectUnselectAll() {
    if (this.tripsList.some( m => m.Selected === false )) {
      if (this.selectedTripsList.length === 0){
        this.selectedTripsList = [...this.tripsList];
        for (let index = 0; index < this.tripsList.length; index++) {
          this.tripsList[index].Selected = true;
        }
      } else {
        for (let index = 0; index < this.tripsList.length; index++) {
          const unique = this.selectedTripsList.findIndex(model => model.Id === this.tripsList[index].Id);
          if (unique === -1) {
            this.selectedTripsList.push(this.tripsList[index]);
            this.selectedTripsList[ this.selectedTripsList.length-1].Selected = true;
          }
        }
      }
      this.selectedItems = true;
    } else {
      for (let index = 0; index < this.tripsList.length; index++) {
        this.tripsList[index].Selected = false;
        this.selectedTripsList.splice(this.selectedTripsList.findIndex(model => model.Id === this.tripsList[index].Id), 1);
      }
      this.selectedItems = false;
    }
    this.checkSelectedState();
  }

  checkSelectedState() {
    if (this.tripsList.length === 0) {
      this.selectedState = 1;
    } else if (!this.tripsList.some( m => m.Selected === false )) {
      this.selectedState = 2
    } else {
      this.selectedState = 3;
    }
  }

  goToAggregateView(){
    console.log('this.selectedTripsList', this.selectedTripsList)

    if(this.selectedTripsList.length == 2){
      let id1 = this.selectedTripsList[0].Id!;
      let id2 = this.selectedTripsList[1].Id!;
      if(this.selectedTripsList[0].Id! > this.selectedTripsList[1].Id!){
        id1 = this.selectedTripsList[1].Id!;
        id2 = this.selectedTripsList[0].Id!
      }


      let url = '/trips/' + id1 + '/join' ;
      if(this.isDevicePage == true){
        
        url = '/devices/' + this.selectedTripsList[0].IdDevice +  '/trips/'   + id1 + '/join' ;
      }
      console.log('url' , url);
      this.router.navigate([url ] , { queryParams: { addTrips: id2 } });
      
    }



  }

  reloadInfo(){
    console.log(":: RELOAD");
    console.log('InformationFirstValue ' , this.InformationFirstValue);
    console.log('InformationLastValue ' , this.InformationLastValue);
    
    this.rerender();
  }

  clearDate(){
    this.InformationFirstValue = null;
    this.InformationLastValue = null;
    this.filterChartForm.reset();

    this.rerender();
  }
}
