import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { UsersService } from 'src/app/services/users.services';

@Component({
  selector: 'app-list-users-page',
  templateUrl: './list-users-page.component.html',
  styleUrls: ['./list-users-page.component.scss']
})
export class ListUsersPageComponent implements OnInit {

  public page?: number;

  constructor(
    private breadcrumbService: BreadcrumbService,
    public authSrv: AuthenticationService,
    private usersSrv : UsersService,
    private route: ActivatedRoute,
    private translate: TranslateService) {

    breadcrumbService.setBreadCrumbUrl('users/list', this.translate.instant('modules.users.management'));
    this.page = Number(this.route.snapshot.paramMap.get('page'))?? 0;

  }

  ngOnInit(): void {

  }

  syncUsers(){

    console.log("syncUsers");
    this.usersSrv.syncUsers().subscribe({
      next: (res: any) =>{
        console.log(res);
      }
    });
  }

}
