import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-new-trip-page',
  templateUrl: './new-trip-page.component.html',
  styleUrls: ['./new-trip-page.component.scss']
})
export class NewTripPageComponent  {

  public page?: number;
  loading:boolean = true;

  constructor(breadcrumbService: BreadcrumbService,
    public authSrv: AuthenticationService,
    private route: ActivatedRoute,
    private translate: TranslateService) {
      breadcrumbService.setBreadCrumbUrl('trips', this.translate.instant('modules.trips.trip-management-device'));
      breadcrumbService.addBreadCrumb('trips/new', this.translate.instant('modules.trips.new-trip')
      );
      this.page = Number(this.route.snapshot.paramMap.get('page'))?? 0;

  }

}
