
<app-breadcrumb></app-breadcrumb>

<div class="container-fluid pt-3 pl-0 pr-0 espace">



  <app-block-group-dashboard >

    <div class="my-4">
      <div class="row align-items-center">
        <div class="col-8">
          <h1><b>{{'modules.trips.new-trip' | translate}}</b></h1>
        </div>
      

      </div>
    </div>



    <app-block-dashboard>

      <app-new-trip ></app-new-trip>
      
    </app-block-dashboard>

  </app-block-group-dashboard>


</div>


<app-footer></app-footer>
