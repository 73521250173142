<app-loading-custom [loading]="loading"></app-loading-custom>

<ng-container *ngIf="loading == false">

    <form class="text-start p-2" [formGroup]="deviceForm" (ngSubmit)="onSubmit()" autocomplete="off">

        <mat-card fxFlex fxLayoutAlign="start center" fxLayout="column" class="solution"
            [style.borderRadius.px]="'0'" [style.paddingTop.px]="'20'"
            [style.marginBottom.px]="'20'" formGroupName="Device">
            <!-- *ngIf="authSrv.isAdmin() == true || authSrv.isManufacturer() == true" -->

            <div class="form-group with-icon mt-3 mb-3" *ngIf="userInput != null && authSrv.isAdmin() == true">
              {{'modules.devices.associate-device-to' | translate}} {{ userInput!.Name??'' }} {{ userInput!.FirstName??'' }} {{ userInput!.LastName??'' }}.
            </div>

            <div class="form-group with-icon mt-3 mb-3" *ngIf="modelInput != null  && authSrv.isAdmin() == true">
              {{'modules.devices.associate-device-to-model' | translate}} {{ modelInput!.Name??'' }} {{'modules.shared.of' | translate}} {{ modelInput!.Manufacturer!.Name??'' }} {{ modelInput!.Manufacturer!.FirstName??'' }} {{ modelInput!.Manufacturer!.LastName??'' }}.
            </div>

            <!--
            <div class="form-group" *ngIf="authSrv.isAdmin() == true">
                <label for="Active">Activo</label>
                <div style="display:inline-block; width: 20px;"></div>
                <mat-slide-toggle color="primary" formControlName="Active"  [(ngModel)]="newDevice.Active"></mat-slide-toggle>
            </div>-->

            <div class="form-group" *ngIf="authSrv.isAdmin() == true">
                <label for="Name">{{'modules.tables.device-name' | translate}}</label>
                <input type="text" class="form-control" id="Name"  formControlName="Name" required [(ngModel)]="newDevice.Name" />

                    <!-- <div [hidden]="Name!.valid || Name!.pristine" class="alert alert-danger">
                        {{'modules.shared.name-required' | translate}}.
                    </div> -->
                    <div *ngIf="Name!.invalid && (Name!.dirty || Name!.touched)" class="alert alert-danger mt-3 text-start">
                        <div *ngIf="Name!.errors?.['required']">
                            {{'modules.shared.name-required' | translate}}.
                        </div>
                        <div *ngIf="Name!.errors?.['minlength']">
                            {{'modules.shared.min-characters' | translate}}.
                        </div>
                        <div *ngIf="Name!.errors?.['maxlength']">
                            {{'modules.shared.max-characters' | translate}}.
                        </div>
                    </div>
            </div>

            <div class="form-group">
                <label for="IMEI">IMEI</label>
                <input type="text" class="form-control" id="IMEI"  formControlName="IMEI" required [(ngModel)]="newDevice.IMEI" maxlength="15" />


                    <div *ngIf="IMEI!.invalid && (IMEI!.dirty || IMEI!.touched)" class="alert alert-danger mt-3 text-start">
                        <div *ngIf="IMEI!.errors?.['required']">
                            {{'modules.shared.imei-required' | translate}}.
                        </div>
                        <div *ngIf="IMEI!.errors?.['minlength']">
                          {{'modules.shared.imei-length' | translate}}.
                        </div>
                        <div *ngIf="IMEI!.errors?.['maxlength']">
                          {{'modules.shared.imei-length' | translate}}.
                        </div>
                    </div>
            </div>

            <div class="form-group" *ngIf="authSrv.isAdmin() == true" >
              <label for="ICCID">ICCID</label>
              <input type="text" class="form-control" id="ICCID"  formControlName="ICCID" required [(ngModel)]="newDevice.ICCID" maxlength="19" />


                  <div *ngIf="ICCID!.invalid && (ICCID!.dirty || ICCID!.touched)" class="alert alert-danger mt-3 text-start">
                      <div *ngIf="ICCID!.errors?.['required']">
                          {{'modules.shared.iccid-required' | translate}}.
                      </div>
                      <div *ngIf="ICCID!.errors?.['minlength']">
                        {{'modules.shared.iccid-length' | translate}}.
                      </div>
                      <div *ngIf="ICCID!.errors?.['maxlength']">
                        {{'modules.shared.iccid-length' | translate}}.
                      </div>
                  </div>
          </div>

          <div class="form-group" *ngIf="authSrv.isAdmin() == true || authSrv.isManufacturer() == true" >
            <label for="NumSerieManufacturer">{{'modules.tables.serial-number' | translate}}</label>
            <input type="text" class="form-control" id="NumSerieManufacturer"  formControlName="NumSerieManufacturer" [(ngModel)]="newDevice.NumSerieManufacturer" maxlength="100" />

            <div *ngIf="NumSerieManufacturer!.invalid && (ICCID!.dirty || ICCID!.touched)" class="alert alert-danger mt-3 text-start">
              <div *ngIf="NumSerieManufacturer!.errors?.['maxlength']">
                {{'modules.shared.serial-number-lenght' | translate}}.
              </div>
            </div>
          </div>

          <div class="form-group" *ngIf="authSrv.isAdmin() == true || authSrv.isManufacturer() == true" >
            <label for="Comments">{{'modules.tables.comments' | translate}}</label>
            <input type="text" class="form-control" id="Comments"  formControlName="Comments" [(ngModel)]="newDevice.Comments" maxlength="150" />

            <div *ngIf="Comments!.invalid && (ICCID!.dirty || ICCID!.touched)" class="alert alert-danger mt-3 text-start">
              <div *ngIf="Comments!.errors?.['maxlength']">
                {{'modules.shared.comments-length' | translate}}.
              </div>
            </div>
          </div>

            <div class="form-group" *ngIf="modelInput == null && modelsList && modelsList.length == 0 && authSrv.isAdmin()">
              <label for="DeviceModel" class="text-right-label">{{'modules.tables.account' | translate}} <span class="c-pointer" (click)="newModel()" *ngIf="authSrv.isAdmin()">{{'modules.account.exist' | translate}}</span></label>
              {{'modules.account.no-available' | translate}}.
            </div>
            <div class="form-group" *ngIf="modelInput == null && modelsList && modelsList.length == 1 && authSrv.isAdmin()" >
              <label for="DeviceModel" class="text-right-label">{{'modules.tables.account' | translate}} <span class="c-pointer" (click)="newModel()" *ngIf="authSrv.isAdmin()">{{'modules.account.exist' | translate}}</span></label>
              {{'modules.account.will-be-assigned' | translate}}: {{ modelsList[0].NameManufacturer }}.
            </div>
            <div class="form-group" *ngIf="modelInput == null && modelsList && modelsList.length > 0">
                <label  *ngIf="authSrv.isAdmin() == true"  for="DeviceModel" class="text-right-label">{{'modules.tables.account' | translate}} <span class="c-pointer" *ngIf="authSrv.isAdmin()" (click)="newManufacturer()">{{'modules.account.exist2' | translate}}</span></label>
                <label  *ngIf="authSrv.isManufacturer() == true"  for="DeviceModel" class="text-right-label">{{'modules.tables.model-device' | translate}} <span class="c-pointer" (click)="newModel()">{{'modules.devices.model-exist' | translate}}</span></label>

                <ng-multiselect-dropdown
                  class="dropup"
                  id="DeviceModel"
                  formControlName="DeviceModel"
                  [(ngModel)]="newDevice!.DeviceModel"
                  [placeholder]="authSrv.isManufacturer()?('modules.shared.search-model' | translate):'modules.shared.search-account' | translate"
                  [settings]="dropdownSettingsFabricante"
                  [data]="modelsList"
                  >

                  <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
                    <div style="padding:1px 10px;margin-right:10px;right:2px;top:-2px;cursor: pointer !important;">
                      <label *ngIf="authSrv.isManufacturer() == false" style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
                        {{option}}
                      </label>
                      <label *ngIf="authSrv.isManufacturer() == true" style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
                        {{ getModel[id].Name??'' }}
                      </label>
                    </div>
                  </ng-template>

                  <ng-template #optionSelectedTemplate let-option="option" let-id="id">
                    <div *ngIf="authSrv.isManufacturer() == false">
                        {{option}}
                    </div>
                    <div  *ngIf="authSrv.isManufacturer() == true">
                        {{ getModel[id].Name??'' }}
                    </div>
                  </ng-template>
                </ng-multiselect-dropdown>

            </div>

            <div class="form-group" *ngIf="modelError == true" >

                    <div class="alert alert-danger mt-3 text-start">
                      <div>
                        {{'modules.account.required' | translate}}.
                      </div>

                  </div>
            </div>


            <div class="form-group with-icon mt-3 mb-3 c-pointer color-link" *ngIf="modelInput == null && newDevice && newDevice!.IdModel != null" (click)="modalFabricante()">
                <mat-icon>person_search</mat-icon> {{'modules.devices.view-model-info' | translate}}
            </div>


            <div class="form-group" *ngIf="clientList && userInput == null && authSrv.isClient() == false">
                <label for="Client" class="text-right-label">{{'modules.users.user' | translate}} <span class="c-pointer" (click)="newCliente()" >{{'modules.users.exist' | translate}}</span></label>

                <ng-multiselect-dropdown
                  class="dropup"
                  id="Client"
                  formControlName="Client"
                  [(ngModel)]="newDevice!.Client"
                  [placeholder]="'modules.shared.search-user' | translate"
                  [settings]="dropdownSettingsClient"
                  [data]="clientList"
                  >

                  <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
                    <div style="padding:1px 10px;margin-right:10px;right:2px;top:-2px;cursor: pointer !important;">
                      <label style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
                        {{ getClient[id].Name??'' }} | {{ getClient[id].DNICIF??'' }}
                      </label>
                    </div>
                  </ng-template>

                  <ng-template #optionSelectedTemplate let-option="option" let-id="id">
                    <div>
                        {{ getClient[id].Name??'' }} | {{ getClient[id].DNICIF??'' }}
                    </div>
                  </ng-template>
                </ng-multiselect-dropdown>

              </div>


            <div class="form-group with-icon mt-3 mb-3 c-pointer color-link" *ngIf="newDevice && newDevice!.IdUser != null && newDevice!.IdModel != null && userInput == null" (click)="modalCliente()">
                    <mat-icon>person_search</mat-icon> {{'modules.users.view-info' | translate}}
            </div>



        </mat-card>



<section class="mt-2">
    <div class="text-right" style="flex-direction: row-reverse;">
        <button  mat-raised-button class="btn btn-primary" [disabled]="isValidForm? null: 'disabled'"  (click)="onSubmit()">{{'modules.shared.create' | translate}}</button>
        <button *ngIf="showButtonCancel" mat-raised-button class="btn btn-danger" type="button" style="margin-right: 10px;" (click)="cancel()">{{'modules.shared.cancel' | translate}}</button>
    </div>

    <!-- <div  class="btn btn-primary"  (click)="test()">test</div> -->

</section>

    </form>


</ng-container>
