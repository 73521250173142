import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListUsersPageComponent } from './pages/list-users-page/list-users-page.component';
import { MaterialCdkModule } from '../material-cdk/material-cdk.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListUsersComponent } from './components/list-users/list-users.component';
import { UsersRoutingModule } from './users-routing.module';
import { DataTablesModule } from "angular-datatables";
import { DetailUserPageComponent } from './pages/detail-user-page/detail-user-page.component';
import { DetailPublicUserComponent } from './components/detail-public-user/detail-public-user.component';
import { AddUserPageComponent } from './pages/add-user-page/add-user-page.component';
import { AddUserComponent } from './components/add-user/add-user.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { EditUserPageComponent } from './pages/edit-user-page/edit-user-page.component';
import { EditUserAddressComponent } from './components/edit-user-address/edit-user-address.component';
import { EditUserThemeComponent } from './components/edit-user-theme/edit-user-theme.component';
import { EditUserContactComponent } from './components/edit-user-contact/edit-user-contact.component';
import { EditUserSpecialComponent } from './components/edit-user-special/edit-user-special.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { EditUserDevicesComponent } from './components/edit-user-devices/edit-user-devices.component';
import { EditUserClientsComponent } from './components/edit-user-clients/edit-user-clients.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DevicesModule } from '../devices/devices.module';
import { EditUserDeviceModelsComponent } from './components/edit-user-device-models/edit-user-device-models.component';
import { SearchUsersComponent } from './components/search-users/search-users.component';
import { EditUserFormComponent } from './components/edit-user-form/edit-user-form.component';
import { EditClientUserComponent } from './components/edit-client-user/edit-client-user.component';
import { EditUserPasswordComponent } from './components/edit-user-password/edit-user-password.component';
import { EditUserManufacturersComponent } from './components/edit-user-manufacturers/edit-user-manufacturers.component';
import { EditUserBankInformationComponent } from './components/edit-user-bank-information/edit-user-bank-information.component';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter'



@NgModule({
  declarations: [
    ListUsersComponent,
    ListUsersPageComponent,
    DetailUserPageComponent,
    DetailPublicUserComponent,
    AddUserPageComponent,
    AddUserComponent,
    EditUserComponent,
    EditUserPageComponent,
    EditUserAddressComponent,
    EditUserThemeComponent,
    EditUserContactComponent,
    EditUserSpecialComponent,
    EditUserDevicesComponent,
    EditUserClientsComponent,
    EditUserDeviceModelsComponent,
    SearchUsersComponent,
    EditUserFormComponent,
    EditClientUserComponent,
    EditUserPasswordComponent,
    EditUserManufacturersComponent,
    EditUserBankInformationComponent,

  ],
  imports: [
    CommonModule,
    MaterialCdkModule,
    CoreModule,
    SharedModule,
    MaterialCdkModule,
    FormsModule,
    ReactiveFormsModule,
    UsersRoutingModule,
    DataTablesModule,
    ColorPickerModule,
    NgMultiSelectDropDownModule,
    DevicesModule,
    MatLuxonDateModule
  ],
  exports:[
    MaterialCdkModule,
    NgMultiSelectDropDownModule,
    AddUserComponent,
    DetailPublicUserComponent,
    SearchUsersComponent,
    EditUserFormComponent
  ]
})
export class UsersModule { }
