<ng-container *ngIf="info && withBlock == true">

  <div class="page w-100">

    <div class="group col-12 col-xl-12 col-lg-12">

      <app-block-dashboard>
        <h1>{{'modules.shared.something-goes-wrong' | translate}}...</h1>
        <h3><strong>Code:</strong> {{ info.code??'-' }}</h3>

        <p *ngIf="info.code != 500 && info.code != 400 && info.code != 401">
          {{ info.msg }}
        </p>

        <p *ngIf="info.code == 401">
          {{'modules.shared.no-device-associated-to-user' | translate}}.
        </p>

        <p *ngIf="info.code == 500">
          {{'modules.shared.server-error' | translate}}.
        </p>

        <p *ngIf="info.code == 400">
          {{'modules.shared.server-error' | translate}}.
        </p>
        </app-block-dashboard>
      </div>
  </div>

</ng-container>

<ng-container *ngIf="info && withBlock == false">
  <p *ngIf="info.code != 500  && info.code != 401">
    {{ info.msg }}
  </p>

  <p *ngIf="info.code == 401">
    {{'modules.shared.no-device-associated-to-user' | translate}}.
  </p>

  <p *ngIf="info.code == 500">
    {{'modules.shared.server-error' | translate}}.
  </p>
  <p *ngIf="info.code != 401"><strong>Code:</strong> {{ info.code??'-' }}</p>

</ng-container>
