import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { ChartInfo } from 'src/app/models/chart';

import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);

@Component({
  selector: 'app-generic-chart',
  templateUrl: './generic-chart.component.html',
  styleUrls: ['./generic-chart.component.scss']
})
export class GenericChartComponent implements OnInit {

  @Input() info: ChartInfo | null = null;
  loading: boolean = true;
  noData: boolean = false;
  useDateAux: boolean = false;


  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  constructor() { }

  ngOnInit(): void {
    this.loading = false;

    console.log("generic");
    console.log(this.info)

    if(this.info?.dataAux && this.info?.dataAux.length > 0 ){
      this.useDateAux = true;


    }

    if(!this.useDateAux && this.info?.datalabels){
      this.info!.options!.plugins!.datalabels! = this.info.datalabels
    }

    if(this.info?.type! == 'doughnut'){
      this.info!.options!.scales! = {};
    }
  }

}
