import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-form-range-default-values-alarm',
  templateUrl: './form-range-default-values-alarm.component.html',
  styleUrls: ['./form-range-default-values-alarm.component.scss']
})
export class FormRangeDefaultValuesAlarmComponent implements OnInit {
  @Input() minValue: number | null = 0;
  @Input() maxValue: number | null = 0;

  @Input() translation: string = "";
  @Input() name: String = "";
  @Input() tag: String = "";

  @Input() readOnly: boolean = false;

  loading:boolean = true;
  noValues: boolean = false;

  minValueAux: number | null   = 0;
  maxValueAux: number | null  = 0;


  valueRangeForm: FormGroup = this.formBuilder.group({
  });


  @Output() formShared = new EventEmitter<FormGroup>();
  private emitFormGeneral(){
    this.formShared.emit(this.valueRangeForm);
  }


  constructor(
    public authSrv: AuthenticationService,
    private formBuilder: FormBuilder,) { }

  ngOnInit(): void {

    this.valueRangeForm.addControl(
    'minValue', new FormControl(this.minValue, [
      Validators.required,
      Validators.pattern("^(-)?[0-9]+(.|,)?[0-9]*$")
    ]));


    this.valueRangeForm.addControl(
      'maxValue', new FormControl(this.maxValue, [
      Validators.required,
      Validators.pattern("^(-)?[0-9]+(.|,)?[0-9]*$")
    ]),
    );

    this.valueRangeForm.addControl(
      'noValues', new FormControl(this.noValues),
    );

    this.minValueAux = this.minValue;
    this.maxValueAux = this.maxValue;

    this.emitFormGeneral();
    this.validateCheckbox();

    if(this.tag == ""){
      this.tag = this.name;
    }

    if(this.readOnly == true){
      this.valueRangeForm.disable();
    }

    this.loading = false;
  }

  get minValueInput(){ return this.valueRangeForm.get("minValue"); }
  get maxValueInput(){ return this.valueRangeForm.get("maxValue"); }

  public validateCheckbox(){
    console.log("validateCheckbox" + this.tag);
    if(this.minValue == null && this.maxValue == null){
      this.noValues = true;
      this.changeAlarm(true);
    }
  }

  changeAlarm(completed: boolean){
    console.log(completed);
    if(completed == true){
      this.valueRangeForm.get("minValue")?.clearValidators();
      this.valueRangeForm.get("minValue")?.disable();
      this.valueRangeForm.get("maxValue")?.clearValidators();
      this.valueRangeForm.get("maxValue")?.disable();

      this.minValueAux = this.minValue;
      this.maxValueAux = this.maxValue;
      this.minValue = null;
      this.maxValue = null;

    }else{
      this.minValue = (this.minValueAux)?this.minValueAux:0;
      this.maxValue = this.maxValueAux??0;

      this.valueRangeForm.get("minValue")?.addValidators(Validators.required);
      this.valueRangeForm.get("minValue")?.addValidators(Validators.pattern("^(-)?[0-9]+(.|,)?[0-9]*$"));
      this.valueRangeForm.get("minValue")?.enable();
      this.valueRangeForm.get("maxValue")?.addValidators(Validators.required);
      this.valueRangeForm.get("maxValue")?.addValidators(Validators.pattern("^(-)?[0-9]+(.|,)?[0-9]*$"));
      this.valueRangeForm.get("maxValue")?.enable();


    }
    this.emitFormGeneral();
  }

  onSubmit(){
    this.valueRangeForm!.valid

    if (! this.valueRangeForm!.valid){
      console.log("ko");
      console.log(this.minValueInput?.errors);
      console.log(this.maxValueInput?.errors);
      return ;
    }
  }

  showForm(){

    console.log(this.name + ' minValueInput: ', this.minValueInput?.value);
    console.log(this.name + ' maxValueInput: ', this.maxValueInput?.value);

    console.log(this.name + ' valid: ', this.valueRangeForm?.valid);
    // console.log('workingHoursvaluesAlarmForm ', this.workingHoursvaluesAlarmForm?.valid);


    console.log(this.name + ' errors: ', this.valueRangeForm?.errors);
   // console.log('workingHoursvaluesAlarmForm ', this.workingHoursvaluesAlarmForm?.valid);



  }
}
