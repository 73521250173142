<app-breadcrumb></app-breadcrumb>


<div class="container-fluid pt-3 pl-0 pr-0 espace">

  <app-loading-custom [loading]="loading"></app-loading-custom>


  <ng-container *ngIf="authSrv.isAdmin() == true">

    <app-block-group-dashboard>
      <div class="col-12">
        <div class="my-4">
          <div class="row align-items-center">
            <div class="col-xxl-8">
              <h1><b>{{'modules.notifications.notifications' | translate}}</b></h1>
            </div>
            <div class="col-xxl-4 text-xl-end" *ngIf="totalNoProcessed>0">
              <div class="btn btn-primary m-2" (click)="checkReadAll()">
                {{'modules.notifications.all-as-read' | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-xl-3 col-lg-6">
        <div class="card card-inverse card-success mb-3">
          <div class="card-block bg-success">
            <div class="rotate">
              <i class="fa fa-user fa-5x"></i>
            </div>
            <a [routerLink]="['/devices/add']">
              <h6 class="text-uppercase txt-size-info">
                {{'modules.devices.add-device' | translate}}
              </h6>
            </a>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6">
        <div class="card card-inverse card-danger mb-3">
          <div class="card-block bg-info">
            <div class="rotate">
              <i class="fas fa-users fa-5x"></i>
            </div>
            <a [routerLink]="['/devices/models/add']">
              <h6 class="text-uppercase txt-size-info">
                {{'modules.devices.add-model' | translate}}
              </h6></a>
          </div>
        </div>
      </div> -->
    </app-block-group-dashboard>

  </ng-container>


  <ng-container *ngIf="authSrv.isAdmin() == false && totalNoProcessed>0">
    <div class="col-12">
      <div class="my-4">
        <div class="row align-items-center">
          <div class="col-12 text-end">
            <div class="btn btn-primary m-2" (click)="checkReadAll()">
              {{'modules.notifications.all-as-read' | translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <app-block-group-dashboard>

    <app-block-dashboard>
      <mat-tab-group mat-align-tabs="start" [selectedIndex]="page" (selectedIndexChange)="page = $event">
        <mat-tab>
            <ng-template mat-tab-label>
              {{'modules.notifications.pending' | translate}}
            </ng-template>
            <div class="mt-2">
              <app-list-notifications *ngIf="viewList1" #notificationsList [isManufacturer]="authSrv.isManufacturer()"  [isClient]="authSrv.isClient()"
                nameDatatable="notificationsNoProcessed" [onlyNoProcessed]="true" (confirmChange)="updateList(2)" (numberTotal)="updateNumberTotal($event)"></app-list-notifications>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
              {{'modules.notifications.read' | translate}}
            </ng-template>
            <div class="mt-2">
              <app-list-notifications  *ngIf="viewList2"  #notificationsList [isManufacturer]="authSrv.isManufacturer()"  [isClient]="authSrv.isClient()"
                nameDatatable="notificationsProcessed" [onlyProcessed]="true"  (confirmChange)="updateList(1)"></app-list-notifications>

            </div>
        </mat-tab>

      </mat-tab-group>
    </app-block-dashboard>

  </app-block-group-dashboard>
<!--
  <app-block-group-dashboard>

    <app-block-dashboard>
      <h2>{{'modules.devices.devices-list' | translate}}</h2>
      <app-list-devices></app-list-devices>
    </app-block-dashboard>

  </app-block-group-dashboard>-->




</div>


<app-footer></app-footer>
