import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AnimationOptions } from 'ngx-lottie';
import { ConfirmationModalComponent } from 'src/app/modals/confirmation-modal/confirmation-modal.component';
import { Modal } from 'src/app/models/modal';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ThemeService } from 'src/app/services/theme.services';
import { UserService } from 'src/app/services/user.service';
import { UsersService } from 'src/app/services/users.services';

@Component({
  selector: 'app-edit-user-page',
  templateUrl: './edit-user-page.component.html',
  styleUrls: ['./edit-user-page.component.scss']
})
export class EditUserPageComponent implements OnInit {

  private IdUser?: number;
  public page?: number;
  loading:boolean = true;
  public user:User | null = null;
  public originalUser:User | null = null;


  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private breadcrumbService: BreadcrumbService,
    public authSrv: AuthenticationService,
    private userSrv : UserService,
    private themeSrv: ThemeService,
    private route: ActivatedRoute,
    private translate: TranslateService) {
      breadcrumbService.setBreadCrumbUrl('users/list', this.translate.instant('modules.users.management'));
      this.IdUser = Number(this.route.snapshot.paramMap.get('id'))?? null;
      this.page = Number(this.route.snapshot.paramMap.get('page'))?? 0;

      console.log(this.IdUser);

     }


  ngOnInit(): void {
    this.loading = true;

    this.userSrv.getUserById(this.IdUser!).subscribe({
      next: (res: User) => {
        this.user = new User(res);


        this.originalUser = Object.assign({},res);
        if(this.user.vDevices == null){
          this.user.vDevices = [];
        }
        this.breadcrumbService.addBreadCrumb('users/' + this.IdUser! + '/detail', this.user.Name??'' + ' ' + this.user.FirstName??'' + ' ' + this.user.LastName??'');
        this.breadcrumbService.addBreadCrumb('users/' + this.IdUser! + '/edit', this.translate.instant('modules.shared.edit'));
        console.log(this.originalUser);
      },
      error: (err: any)=>{

      },
      complete: ()=>{
        this.loading = false;

        if(this.page !=0 ){

        }
      }
    });
  }

}
