import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { vDeviceModel } from 'src/app/models/device';
import { Modal } from 'src/app/models/modal';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-device-model-add-quick',
  templateUrl: './device-model-add-quick.component.html',
  styleUrls: ['./device-model-add-quick.component.scss']
})
export class DeviceModelAddQuickComponent implements OnInit {

  deviceModelOutput: vDeviceModel = new vDeviceModel();
  user: User | null = null;

  constructor(private dialogRef: MatDialogRef<DeviceModelAddQuickComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Modal) { 
      if(this.data!.Data){
        if(this.data!.Data['idUser']){
          this.user = new User(this.data!.User)??null;
        }        
      }
    }

  ngOnInit(): void {
  }

  emitDeviceModel(deviceModelOutput: vDeviceModel){
    let newModel: vDeviceModel = deviceModelOutput;
    this.deviceModelOutput = newModel;
    
    console.log("quick", newModel);
    this.dialogRef.close(newModel);
  }

  accept(){
    this.dialogRef.close();
  }

  cancel(){
    this.dialogRef.close();
  }


}
