<agm-map *ngIf="centralPointGet && centralPointGet[0] != 0 && centralPointGet[1] != 0"
  [mapTypeId]="'satellite'"
  [latitude]="centralPointGet[0]"  [longitude]="centralPointGet[1]" [zoom]="zoom">

  <ng-container *ngFor="let device of devices" >
    <agm-marker *ngIf="device.LatitudeLast"  [latitude]="device.LatitudeLast!" [longitude]="device.LongitudeLast!" [iconUrl]="(device.onlineState == 0 )?'assets/img/maps/location-pin.png':(device.onlineState == 1 )?'assets/img/maps/location-pin-online.png':'assets/img/maps/location-pin-orange.png'"  >
        <agm-info-window>
            <h4>{{ device.Name }}</h4>
            <br>
            <p><span class="fw-bold">{{'modules.users.user' | translate}}:</span> {{ device.NameClient }}</p>
            <p><span class="fw-bold">{{'modules.tables.account' | translate}}:</span> {{ device.NameManufacturer }}</p>
            <p><span class="fw-bold">IMEI:</span> {{ device.IMEI }}</p>

            <div class="text-center"><a [routerLink]="['/devices/' + device.Id + '/detail' ]">{{'modules.devices.go-to-detail' | translate}}</a></div>

        </agm-info-window>

    </agm-marker>

  </ng-container>


</agm-map>
