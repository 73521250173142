import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { vDeviceModel } from 'src/app/models/device';
import { Modal } from 'src/app/models/modal';
import { Manufacturer, Role, User } from 'src/app/models/user';


@Component({
  selector: 'app-device-model-edit-quick',
  templateUrl: './device-model-edit-quick.component.html',
  styleUrls: ['./device-model-edit-quick.component.scss']
})
export class DeviceModelEditQuickComponent implements OnInit {

  deviceModelInput?: vDeviceModel;
  deviceModelOutput: vDeviceModel = new vDeviceModel();
  user: User | null = null;

  constructor(private dialogRef: MatDialogRef<DeviceModelEditQuickComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Modal) {
      if(this.data!.Data){

        console.log(this.data!.Data);

        if(this.data!.Data['idUser']){
          this.user = new User(this.data!.User)??null;
          if(this.user.Role == null) {
            this.user.Role = new Role({'Tag':'fabricant'});
          }
          console.log(this.user);
        }


        if(this.data!.Data['DeviceModel']){
          this.deviceModelInput = new vDeviceModel(this.data!.Data.DeviceModel);

          this.deviceModelInput.Manufacturer = new Manufacturer(this.data!.User)??null;
          if(this.deviceModelInput.Manufacturer.Role == null) {
            this.deviceModelInput.Manufacturer.Role = new Role({'Tag':'fabricant'});
          }

          console.log(this.deviceModelInput);
        }


        console.log(this.deviceModelInput);
      }
    }

  ngOnInit(): void {
  }

  emitDeviceModel(deviceModelOutput: vDeviceModel | any){

    this.dialogRef.close(deviceModelOutput);
  }

  accept(){
    this.dialogRef.close();
  }

  cancel(){
    this.dialogRef.close();
  }


}
