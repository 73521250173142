<app-breadcrumb></app-breadcrumb>


<div class="container-fluid pt-3 pl-0 pr-0 espace">

  <ng-container *ngIf="authSrv.isAdmin() == true">

    <ng-container *ngIf="authSrv.isAdmin() == true">

      <app-block-group-dashboard>
        <div class="col-12">
          <div class="my-4">
            <div class="row align-items-center">
              <div class="col-xxl-8">
                <h1><b>{{'modules.shared.trips' | translate}}</b></h1>
              </div>
              <div class="col-xxl-4 text-xl-end">
                <div class="btn btn-primary m-2" [routerLink]="['/trips/new']">
                    {{'modules.trips.add-trip' | translate}}
                </div> 
              </div>
            </div>
          </div>
        </div>
   
      </app-block-group-dashboard>
  
      </ng-container>

    </ng-container>

    <app-block-group-dashboard>

    <app-block-dashboard>
      <mat-tab-group mat-align-tabs="start" [selectedIndex]="page" (selectedIndexChange)="page = $event">
        <mat-tab>
            <ng-template mat-tab-label>
                {{'modules.shared.trips' | translate}}
            </ng-template>
            <div class="mt-2">
                <app-list-trips [showFilter]="true"></app-list-trips>
            </div>
        </mat-tab>
      </mat-tab-group>
    </app-block-dashboard>

  </app-block-group-dashboard>



</div>


<app-footer></app-footer>
