import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS, MAT_LUXON_DATE_FORMATS } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSliderChange } from '@angular/material/slider';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable } from 'rxjs';
import { Information, Position } from 'src/app/models/device';
import { DeviceTripTrackInformations, Trip } from 'src/app/models/trip';
import { CustomLuxonDateAdapter } from 'src/app/modules/charts/components/timeline-apex-chart/customDateAdapter';
import { TripsService } from 'src/app/services/trips.service';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-split-map-trip',
  templateUrl: './split-map-trip.component.html',
  styleUrls: ['./split-map-trip.component.scss']
})
export class SplitMapTripComponent implements OnInit {

  @Input() trip?: Trip;
  splitDate?: DateTime;
  @Input() centralPoint?: [number,number];
  @Input() hasDevicePage: boolean = false;
  zoom: number = 19;
  StartDateValue: Date | null= null;
  FinishDateValue: Date  | null = null;
  SplitDateValue: DateTime | null= null; 
  loading: boolean = true;
  splitTripForm: FormGroup = this.formBuilder.group({
  });
  percentValue: number = 100;
  
  indexCurrentPosition?: number;
  indexCurrentPosition2 = new BehaviorSubject<number>(0);
  indexCurrentPosition3$: Observable<number> = this.indexCurrentPosition2.asObservable();
  currentPoint?: DeviceTripTrackInformations = undefined;
  

  constructor(
    private cdr: ChangeDetectorRef,
    private tripSrv: TripsService,
    private router: Router,
    private utilsSrv: UtilsService,
    private formBuilder: FormBuilder,
    private translate: TranslateService) { 

  }

  ngOnInit(): void {

    this.StartDateValue = new Date(this.trip!.DeviceTripTrackInformations![0].TrackInformation!.TrackPosition!.Date!);
    this.FinishDateValue = new Date(this.trip!.DeviceTripTrackInformations![this.trip!.DeviceTripTrackInformations!.length - 1].TrackInformation!.TrackPosition!.Date!);
    
    this.splitDate = DateTime.fromJSDate(this.FinishDateValue);
    this.SplitDateValue = this.splitDate;
    console.log('StartDateValue' , this.StartDateValue);
    console.log('FinishDateValue' , this.FinishDateValue);


    this.splitTripForm = this.formBuilder.group({
      StartDate: new FormControl(this.StartDateValue, [
        Validators.required
      ]),
      FinishDate: new FormControl(this.FinishDateValue, [
        Validators.required
      ]),
      SplitDate: new FormControl(this.FinishDateValue, [
        Validators.required
      ]),
      Percent: new FormControl(this.percentValue, [
        Validators.required
      ]),
    });

    this.splitTripForm.controls['SplitDate'].setValue(this.FinishDateValue );
    this.splitTripForm.get('SplitDate')!.setValue(this.FinishDateValue );


    if(this.trip!.DeviceTripTrackInformations && this.trip!.DeviceTripTrackInformations.length > 0){
      this.centralPoint = [ this.trip!.DeviceTripTrackInformations[0].TrackInformation!.TrackPosition!.LatitudeWGS84!,this.trip!.DeviceTripTrackInformations[0].TrackInformation!.TrackPosition!.LongitudeWGS84!  ];
    }

    console.log('diff' );
    console.log( DateTime.fromJSDate(new Date(this.trip!.DeviceTripTrackInformations![0].TrackInformation!.TrackPosition!.Date!)) );
    console.log( this.splitDate! );
    console.log( DateTime.fromJSDate(this.trip!.DeviceTripTrackInformations![0].TrackInformation!.TrackPosition!.Date!).diff(this.splitDate!));

    this.cdr.detach();
    this.loading = false;
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  changeSlider(event: MatSliderChange){
    console.log('changeSlider' , event.value);
    let dateAux: Date = this.getPercentageDate(this.StartDateValue! , this.FinishDateValue!, event.value!);
    this.splitDate = DateTime.fromJSDate(new Date(dateAux));
    console.log('this.splitDate' , this.splitDate);
    this.SplitDateValue = this.splitDate;
    this.SplitDate?.setValue(this.splitDate);
    console.log(dateAux);

    this.splitTripForm.controls['SplitDate'].setValue(dateAux);
    this.splitTripForm.get('SplitDate')!.setValue(dateAux);
    


  }

  changeSplitDate(event:any){
    console.log(event);
    console.log(
      this.splitTripForm.get('SplitDate')?.value);
  }

  get StartDate(){ return this.splitTripForm.get("StartDate"); }
  get FinishDate(){ return this.splitTripForm.get("FinishDate"); }
  get SplitDate(){ return this.splitTripForm.get("SplitDate"); }
  get Percent(){ return this.splitTripForm.get("Percent"); }

  /*
  get split1(): DeviceTripTrackInformations[]{

      return this.trip!.DeviceTripTrackInformations!.filter( (e) => { return e.TrackInformation!.TrackPosition!.Date! <= this.trip!.DeviceTripTrackInformations![20].TrackInformation!.TrackPosition!.Date! } );

  }
  get split2(): DeviceTripTrackInformations[]{
      return this.trip!.DeviceTripTrackInformations!.filter( (e) => { return e.TrackInformation!.TrackPosition!.Date! >= this.trip!.DeviceTripTrackInformations![20].TrackInformation!.TrackPosition!.Date! } );

  }*/
  
  
  get split1(): DeviceTripTrackInformations[]{
      return this.trip!.DeviceTripTrackInformations!.filter( (e) => { return DateTime.fromJSDate(new Date(e.TrackInformation!.TrackPosition!.Date!)).diff(this.splitDate!).milliseconds < 0 } );

    
  }
  get split2(): DeviceTripTrackInformations[]{
      return this.trip!.DeviceTripTrackInformations!.filter( (e) => { return DateTime.fromJSDate(new Date(e.TrackInformation!.TrackPosition!.Date!)).diff(this.splitDate!).milliseconds > 0 } );

  }

  get splitDateForm(){
    return this.splitTripForm.get('SplitDate')?.value;
  }
  
  getPercentageDate(start: Date, end: Date, percentage: number): Date {
    const difference = end.getTime() - start.getTime();
    const timeInMillis = (percentage / 100) * difference;
    const newDate = new Date(start.getTime() + timeInMillis);
    this.cdr.detectChanges();
    return newDate;
  }
  
  formatLabel(value: number): string {
    if (value >= 100) {
      return Math.round(value / 100) + 'k';
    }

    
    return `${value}`;
  }


  submit(){

    if(this.FinishDateValue!.getTime() == this.splitDate?.toJSDate().getTime()){
      console.log('no puede ser ultima traza para split');
      return;
    }

    console.log(this.trip!.DeviceTripTrackInformations![0].TrackInformation!.TrackPosition?.Date);

    this.SplitDateValue = this.splitTripForm.get("SplitDate")?.value;
    
    console.log('values' , this.StartDate?.value, this.FinishDate?.value , this.SplitDate?.value , this.Percent?.value);

    console.log('values' , this.StartDateValue, this.FinishDateValue , this.SplitDateValue, this.percentValue);


    //let url = `${environment.API_ENDPOINT}trips/${this.trip!.Id}/split?SplitDate=${this.SplitDateValue!.toISO()}`;
    console.log('url',this.splitDate?.toISO());
    console.log('url',this.splitDate?.toJSDate().toISOString());

    
    this.tripSrv.splitById(this.trip!, this.splitDate!.toJSDate(), this.splitDate?.toJSDate().toISOString()).subscribe(
      {
        next: (res: Trip) => {
          if(this.hasDevicePage){
            this.router.navigate([ '/devices/' + res.Device!.Id! + '/trips/' + res!.Id!  ]);

          }else{
            this.router.navigate(['/trips/' + res!.Id!  ]);
          }

        },
        error: (err) =>{
          this.loading = false;
          this.utilsSrv.infoModal(this.translate.instant('modules.modals.split-trip-error') + '.',undefined);

        }
      }
    );
  }

  clickPoint(e: DeviceTripTrackInformations){
    console.log('position', e )

    let dateAux: Date = new Date( e.TrackInformation!.TrackPosition!.Date!);
    console.log('position', dateAux )
    
    this.splitDate = DateTime.fromJSDate(new Date(dateAux)).plus({seconds: 1});
    console.log('this.splitDate' , this.splitDate);
    this.SplitDateValue = this.splitDate;
    this.SplitDate?.setValue(this.splitDate);
    console.log(dateAux);

    this.splitTripForm.controls['SplitDate'].setValue(dateAux);
    this.splitTripForm.get('SplitDate')!.setValue(dateAux);
  }

  resetPositions(){
    let dateAux: Date = new Date( this.FinishDateValue!);
    console.log('position', dateAux )
    
    this.splitDate = DateTime.fromJSDate(new Date(dateAux));
    console.log('this.splitDate' , this.splitDate);
    this.SplitDateValue = this.splitDate;
    this.SplitDate?.setValue(this.splitDate);
    console.log(dateAux);

    this.splitTripForm.controls['SplitDate'].setValue(dateAux);
    this.splitTripForm.get('SplitDate')!.setValue(dateAux);

  }


  overPoint(e: DeviceTripTrackInformations){
    console.log('++ overPoint', e);
    this.currentPoint=e;
  }

  outPoint(e: DeviceTripTrackInformations){
    console.log('++ outPoint');
    this.currentPoint=undefined;

  }
 
}
