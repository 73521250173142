import { Device, Information } from "./device";

export class vTrip {
  Id?: number;
  IdDevice?: number;
  NameDevice?: string;
  IMEI?: string;
  IdUser?: number;
  NameClient?: string;
  IdModel?: number;
  NameDeviceModel?: string;
  IdManufacturer?: number;
  NameManufacturer?: string;
  Active?: boolean;
  NumSerieManufacturer?: string;
  Comments?: string;
  Device?: Device;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  StartedDate?: Date;
  FinishedDate?: Date;
  WorkStartedDate?: Date;
  WorkFinishedDate?: Date;
  VoltageValue?: number;
  AlarmCount?: number;
  MobileOperator?: string;
  Events?: Array<TripEvent>;
  Status?: Array<TripTripStatus>;
  Informations?: Array<Information>;
  Selected: boolean = false;
  

  constructor(values: Object = {}) {
      (<any>Object).assign(this, values);
    }
}
export class Trip {
    Id?: number;
    IdDevice?: number;
    Device?: Device;
    CreatedAt?: Date;
    UpdatedAt?: Date;
    StartedDate?: Date;
    FinishedDate?: Date;
    Events?: Array<TripEvent>;
    Status?: Array<TripTripStatus>;
    Informations?: Array<Information>;
    DeviceTripTrackInformations?: Array<DeviceTripTrackInformations>;
    DeviceTripEvents?: Array<TripEvent>;
    VoltageValue?: number;
    AlarmCount?: number;
    MobileOperator?: string;
    Selected: boolean = false;
    
    Id2?: number;
    

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
      }
}
export class TripTripStatus {
    Id?: number;
    IdTrip?: number;
    IdTripStatus?: number;
    Trip?: Trip;
    TripStatis?: TripStatus;
    
    Date?: Date;
    CreatedAt?: Date;
    

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
      }
}

export class TripStatus {
    Id?: number;
    Name?: string;    

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
      }
}

export class TripEvent {
    Id?: number;
    IdDeviceTrip?: number;
    Title?: string;
    Comment?: string;
    Date?: Date;
    //CreatedAt?: Date;
    //UpdatedAt?: Date;
    Information1?: string;
    Information2?: string;
    Information?: Information;
    Type?: number;
    

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
      }
}

export class Timecard  {
    index: number= 0;
    title: string = '';
    description: string = '';
    timestamp: string = '';
    date?: Date;
    align: String = '';
    type: String = '';
    timecardIcon:String = '';
    timecardIconColor:String = '';    
    
    timecardFloatingIcon:String = '';
    timecardFloatingIconColor:String = '';
    timecardColor:String = '';
    timecardTitleColor:String = '';
    timecardTextColor:String = '';
    

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);
      }
}


export class DeviceTripTrackInformations {
  Id?: number;
  Active?: boolean;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  IdDeviceTrip?: string;
  IdTrackInformation?: Date;
  TrackInformation?: Information;

}