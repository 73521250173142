import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-add-user-page',
  templateUrl: './add-user-page.component.html',
  styleUrls: ['./add-user-page.component.scss']
})
export class AddUserPageComponent implements OnInit {

  constructor(
    private breadcrumbService: BreadcrumbService,
    public authSrv: AuthenticationService,
    private translate: TranslateService) {


    breadcrumbService.setBreadCrumbUrl('users/list', this.translate.instant('modules.users.management'));
    breadcrumbService.addBreadCrumb('users/add', this.translate.instant('modules.shared.new-register'));
  }

  ngOnInit(): void {
  }

}
