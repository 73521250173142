<app-breadcrumb></app-breadcrumb>


<div class="container-fluid pt-3 pl-0 pr-0 espace">

  <ng-container *ngIf="authSrv.isAdmin() == true">

    <app-block-group-dashboard>
      <div class="col-12">
        <div class="my-4">
          <div class="row align-items-center">
            <div class="col-xxl-8">
              <h1><b>{{'modules.devices.devices' | translate}}</b></h1>
            </div>
            <div class="col-xxl-4 text-xl-end">
              <div class="btn btn-primary m-2" [routerLink]="['/devices/add']">
                  {{'modules.devices.add-device' | translate}}
              </div>
              <div class="btn btn-primary m-2" [routerLink]="['/devices/models/add']">
                  {{'modules.devices.add-model' | translate}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-xl-3 col-lg-6">
        <div class="card card-inverse card-success mb-3">
          <div class="card-block bg-success">
            <div class="rotate">
              <i class="fa fa-user fa-5x"></i>
            </div>
            <a [routerLink]="['/devices/add']">
              <h6 class="text-uppercase txt-size-info">
                {{'modules.devices.add-device' | translate}}
              </h6>
            </a>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6">
        <div class="card card-inverse card-danger mb-3">
          <div class="card-block bg-info">
            <div class="rotate">
              <i class="fas fa-users fa-5x"></i>
            </div>
            <a [routerLink]="['/devices/models/add']">
              <h6 class="text-uppercase txt-size-info">
                {{'modules.devices.add-model' | translate}}
              </h6></a>
          </div>
        </div>
      </div> -->
    </app-block-group-dashboard>

    </ng-container>

    <app-block-group-dashboard>

    <app-block-dashboard>
      <mat-tab-group mat-align-tabs="start" [selectedIndex]="page" (selectedIndexChange)="page = $event">
        <mat-tab>
            <ng-template mat-tab-label>
                {{'modules.devices.devices' | translate}}
            </ng-template>
            <div class="mt-2">
              <app-list-devices [showNewDevice]="authSrv.isClient()?false:authSrv.isAdmin()?false:true" [isManufacturer]="authSrv.isManufacturer()"  [isClient]="authSrv.isClient()" ></app-list-devices>
            </div>
        </mat-tab>
        <mat-tab *ngIf="authSrv.isClient() == false">
            <ng-template mat-tab-label>
                {{'modules.devices.models' | translate}}
            </ng-template>
            <div class="mt-2">
              <app-list-device-models [showNewModel]="authSrv.isClient() || authSrv.isAdmin()?false:true" [isManufacturer]="authSrv.isManufacturer()"  ></app-list-device-models>
            </div>
        </mat-tab>
      </mat-tab-group>
    </app-block-dashboard>

  </app-block-group-dashboard>
<!--
  <app-block-group-dashboard>

    <app-block-dashboard>
      <h2>{{'modules.devices.devices-list' | translate}}</h2>
      <app-list-devices></app-list-devices>
    </app-block-dashboard>

  </app-block-group-dashboard>-->




</div>


<app-footer></app-footer>
