<app-loading-custom [loading]="loadingDelete"></app-loading-custom>

<section *ngIf="showNewModel" class="mt-2">
  <div class="text-right" style="flex-direction: row-reverse;">
      <button  mat-raised-button class="btn btn-primary"  (click)="newDeviceModel()">
        {{'modules.devices.add-model' | translate}}
      </button>
  </div>
</section>
<!-- <div *ngIf="showNewModel" class="text-right-label" style="flex-direction: row-reverse">  <span class="c-pointer"  (click)="newDeviceModel()" >{{'modules.devices.model-exist2' | translate}}</span></div>
-->

<div *ngIf="datatableConfigColumns && datatableConfigColumns.headerTable" class="m-3">



  <button *ngIf="selectedItems || selectedModelsList.length > 0"  mat-raised-button class="btn btn-danger d-none d-md-block p-absolute" (click)="deleteAll()">{{'modules.devices.delete-selected' | translate}}</button>

    <table #deviceList [id]="nameDatatable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
      class="display nowrap table table-striped table-sm row-border hover stripe" style="width: 100% !important">
      <thead>

        <tr>
            <!-- <th><input type="checkbox" name="selectAll" (click) = "checkuncheckall()"> select All</th> -->
            <th *ngFor="let headColumn of datatableConfigColumns.headerTable" [attr.class]="'column-' + headColumn.column">

              <ng-container *ngIf="headColumn.name != '#select'">
                {{ headColumn.name}}
              </ng-container>
              <ng-container *ngIf="headColumn.name == '#select'">
                <div id="selectAll" class="c-pointer-i">
                  <label>
                    <input type="checkbox" style="display: none;" (change)="selectUnselectAll()">
                    <i  class="far fa-lg pl-3"
                        [ngClass]="selectedState === 1 ? 'fa-square' : (selectedState === 2 ? 'fa-check-square text-success' : 'fa-minus-square text-warning')"
                        style="color: #000">
                    </i>
                  </label>
                </div>
              </ng-container>
            </th>
        </tr>
      </thead>

      <tbody>
        <tr *ngIf="loading == true">
          <td [attr.colspan]="datatableConfigColumns!.headerTable!.length">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </td>
        </tr>
        <tr *ngIf="loading == false && listaDispositivoModels.length == 0">
          <td [attr.colspan]="datatableConfigColumns!.headerTable!.length">{{'modules.shared.no-records' | translate}}.</td>
        </tr>
        <tr *ngFor="let model of listaDispositivoModels" (click)="checkuncheckall(model)">
            <td  class="text-center c-pointer">
                <i *ngIf="model.Selected == false" class="far fa-square fa-lg pl-3" style="color: #000"></i>
                <i *ngIf="model.Selected == true"  class="far fa-check-square text-success fa-lg pl-3"></i>

            </td>

            <td class="text-center"># {{ model.Id }}
            </td>
            <td class="c-pointer">
                <a [routerLink]="['/devices/models/' + model.Id + '/detail' ]">{{ model.Name }}</a>
            </td>
            <td class="c-pointer"  *ngIf="isViewManufacturer == false  && (isManufacturer == null || isManufacturer == false)">
                <a [routerLink]="['/users/' + model.IdManufacturer + '/detail' ]">{{ model.NameManufacturer }}</a>
            </td>
            <td >
                {{ model.CreatedAt | date: "dd-MM-yyyy HH:mm"  }}
            </td>
            <td *ngIf="false">
                {{ model.UpdatedAt | date: "dd-MM-yyyy HH:mm"  }}
            </td>
            <td class="text-center">
              {{ model.NumDevices }}
            </td>

            <td class="c-pointer-i text-right actions">
                <!-- <i (click)="deleteItem(model)" class="fa-solid fa-trash text-danger fa-lg" ></i> -->
                <i *ngIf="model.NumDevices === 0 && model.Name != 'Sin asignar'" (click)="deleteItem(model)" class="fa-solid fa-trash text-danger fa-lg" ></i>
                <i *ngIf="model.NumDevices != 0" class="fa-solid fa-trash fa-lg disabled"></i>
                <a [routerLink]="['/devices/models/' + model.Id + '/detail' ]"><i class="fas fa-angle-double-right text-primary fa-lg pl-3"></i></a>

            </td>
        </tr>
      </tbody>
    </table>

    <section class="m-1 mt-4" *ngIf="selectedItems || selectedModelsList.length > 0">
      <div class="text-left" style="flex-direction: row;">

        <button  mat-raised-button class="btn btn-danger" (click)="deleteAll()">{{'modules.devices.delete-selected' | translate}}</button>
      </div>
    </section>

  </div>
