import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlarmsService } from 'src/app/services/alarms.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { UsersService } from 'src/app/services/users.services';

@Component({
  selector: 'app-list-alarms-page',
  templateUrl: './list-alarms-page.component.html',
  styleUrls: ['./list-alarms-page.component.scss']
})
export class ListAlarmsPageComponent implements OnInit {


  public page?: number;

  constructor(
    private breadcrumbService: BreadcrumbService,
    public authSrv: AuthenticationService,
    private usersSrv : UsersService,
    private alarmSrv : AlarmsService,
    private route: ActivatedRoute,
    private translate: TranslateService) {
      breadcrumbService.setBreadCrumbUrl('alarms/list', this.translate.instant('modules.alarms.management'));
      this.page = Number(this.route.snapshot.paramMap.get('page'))?? 0;


    }

  ngOnInit(): void {
  }

  syncAlarms(){
    console.log("syncAlarms");
    this.alarmSrv.processRecords().subscribe({
      next: (res: any) =>{
        console.log(res);
      }
    });
  }

}
