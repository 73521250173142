<app-breadcrumb></app-breadcrumb>


<div class="container-fluid pt-3 pl-0 pr-0 espace">

  <app-loading-custom [loading]="loading"></app-loading-custom>


  <app-block-group-dashboard *ngIf="loading == false">

      <app-detail-trip-device [trip]="trip!" [centralPoint]="[positions[0].TrackPosition!.LatitudeWGS84!, positions[0].TrackPosition!.LongitudeWGS84!]" [positions]="positions" [hasAggregateView]="true" ></app-detail-trip-device>

  </app-block-group-dashboard>

</div>


<app-footer></app-footer>
