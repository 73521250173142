import { Component, HostListener, Input, OnInit, AfterViewInit, ViewChild, ChangeDetectionStrategy, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexYAxis,
  ApexFill,
  ApexMarkers,
  ApexStroke
} from "ng-apexcharts";
import { Device, Information, InformationChart } from 'src/app/models/device';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DevicesService } from 'src/app/services/devices.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ExportChartsService } from 'src/app/services/export-charts.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { Notification } from 'src/app/models/notifications';
import { DataSetApex } from 'src/app/models/alarms';
import { LuxonDateAdapter, MAT_LUXON_DATE_FORMATS, MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { DateTime, Settings, } from "luxon";
import { CustomLuxonDateAdapter } from './customDateAdapter';
import { sensors, sensorAccomulative } from 'src/app/const/sensor-const';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  fill: ApexFill;
  stroke: ApexStroke;
  markers: ApexMarkers;
  colors: string[];
};

@Component({
  selector: 'app-timeline-apex-chart',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './timeline-apex-chart.component.html',
  styleUrls: ['./timeline-apex-chart.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: DateAdapter,
      useClass: CustomLuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS},
  ]
})
export class TimelineApexChartComponent  implements OnInit, AfterViewInit, AfterContentChecked {

  reportData: any  = {};
  reportChart: any = {};

  @Input() device!: Device;
  @Input() showTitle: boolean = false;
  @Input() idAlert?: number;
  @Input() showInputDates: boolean = true;

  public cloneDevice?: Device;
  public isFirst: boolean = true;

  public firstInformation?: Information;
  public lastInformation?: Information;

  notification?: Notification;
  loading: boolean = true;
  isFirstTime: boolean = true;
  noData: boolean = false;
  infoChart: InformationChart = new InformationChart();
  showErrorAlert: boolean = false;

  //sensorTotalPeriodo = [ 'WorkingHours' , 'Aux2','Aux3','Aux5'];
  

  colors = ["#77B6EA", "#ff6384", "#ffd05e", "#545454"];
  auxSrc = "";

  listSensor: Array<string> = [
    this.translate.instant('modules.alarms.rpm'),
    this.translate.instant('modules.alarms.horas-trabajo'),
    this.translate.instant('modules.alarms.presion'),
    this.translate.instant('modules.alarms.caudal'),
    this.translate.instant('modules.alarms.temp-aceite'),
    this.translate.instant('modules.alarms.nivel-aceite'),
    this.translate.instant('modules.alarms.num-acciones'),
    this.translate.instant('modules.alarms.peso'),
    this.translate.instant('modules.alarms.rpm-2'),
    this.translate.instant('modules.alarms.rpm-3'),
    this.translate.instant('modules.alarms.presion-2'),
    this.translate.instant('modules.alarms.presion-3'),
    this.translate.instant('modules.alarms.caudal-2'),
    this.translate.instant('modules.alarms.caudal-3'),
    this.translate.instant('modules.alarms.peso-2'),
    this.translate.instant('modules.alarms.num-acciones-2'),
    this.translate.instant('modules.alarms.num-acciones-3'),
    this.translate.instant('modules.alarms.aux-1'),
    this.translate.instant('modules.alarms.aux-2'),
    this.translate.instant('modules.alarms.aux-3'),
    this.translate.instant('modules.alarms.aux-4'),
  ];

  listSensorRename: any = sensors;

  listSensorAccomulative: any = sensorAccomulative;

  listSensorView: Array<string> = [
    this.translate.instant('modules.alarms.rpm'),
    // this.translate.instant('modules.alarms.temp-aceite'),
  ];

  dropdownSensor : IDropdownSettings= {
    singleSelection: false,
    limitSelection: 4,
    selectAllText: this.translate.instant('modules.shared.select-all'),
    unSelectAllText: this.translate.instant('modules.shared.unselect-all'),
    allowSearchFilter: true,
    //noFilteredDataAvailablePlaceholderText: "No hay coincidencias",
    noDataAvailablePlaceholderText: this.translate.instant('modules.shared.no-available-sensors'),
    searchPlaceholderText: this.translate.instant('modules.shared.search'),
    clearSearchFilter: true,
  };

  //InformationFirstValue: moment.Moment = moment();
  //InformationLastValue: moment.Moment = moment();
  InformationFirstValue: DateTime | null= null;
  InformationLastValue: DateTime  | null = null;

  //InformationFirstValueZoom:  moment.Moment = moment();
  //InformationLastValueZoom: moment.Moment = moment();
  InformationFirstValueZoom:  DateTime = DateTime.now();
  InformationLastValueZoom: DateTime = DateTime.now();


  filterChartForm: FormGroup = this.formBuilder.group({
  });

  @ViewChild("mainChart", { static: false }) chart?: ChartComponent;
  @ViewChild("timelineChart", { static: false }) chart2?: ChartComponent;

  public chartOptions1!: Partial<ChartOptions> | any;
  public chartOptions2!: Partial<ChartOptions> | any;
  public chartOptionsPDF!: Partial<ChartOptions> | any;

  public datasets: DataSetApex[] = [];
  //public datasets2: DataSetApex[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private deviceSrv: DevicesService,
    private alertSrv: NotificationsService,
    public authSrv : AuthenticationService,
    private exportData: ExportChartsService,
    private exportChart: ExportChartsService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private _adapter: DateAdapter<any>
  ) {
    Settings.defaultZone = "europe/madrid";
    Settings.defaultLocale= "fr";
    this._adapter.setLocale(this.translate.getDefaultLang());



    if(this.authSrv.isAdmin()){
      this.listSensor.push(this.translate.instant('modules.alarms.aux-5'));
    }


  }

  get InformationFirst(){ return this.filterChartForm.get("InformationFirst"); }
  get InformationLast(){ return this.filterChartForm.get("InformationLast"); }

  ngOnInit(): void {
    console.log(this.device);

    this.exportData.currentData.subscribe(data => this.reportData = data)
    this.exportChart.currentChart.subscribe((chart: any) => this.reportChart = chart)

    if(this.device!.InformationFirst){
      //this.InformationFirstValue = moment(this.device?.Informations![0].TrackPosition?.Date);
      //this.InformationLastValue = moment(this.device?.Informations![this.device?.Informations!.length-1].TrackPosition?.Date);
      //this.InformationFirstValueZoom = moment(this.device!.InformationFirst);
      //this.InformationLastValueZoom = moment(this.device!.InformationLast);

      //this.InformationFirstValue = DateTime.fromJSDate(new Date(this.device?.Informations![0].TrackPosition!.Date!), {zone: 'UTC+1'});
      //this.InformationLastValue = DateTime.fromJSDate(new Date(this.device?.Informations![this.device?.Informations!.length-1].TrackPosition!.Date!), {zone: 'UTC+1'});

      this.InformationFirstValueZoom = DateTime.fromJSDate(new Date(this.device!.InformationFirst!), {zone: 'UTC+1'});
      this.InformationLastValueZoom = DateTime.fromJSDate(new Date(this.device!.InformationLast!), {zone: 'UTC+1'});
    }

    console.log(this.InformationFirstValue);
    console.log(this.InformationLastValue);

    this.filterChartForm = this.formBuilder.group({
      InformationFirst: new FormControl(this.InformationFirstValue, [
        Validators.required
      ]),
      InformationLast: new FormControl(this.InformationLastValue, [
        Validators.required
      ]),
      Sensors: new FormControl(this.listSensorView, [
        Validators.required
      ])
    });

    console.log(this.device);

    if(this.device!.InformationFirst != null && this.idAlert){
      this.getAlert();


    }else if(this.device!.InformationFirst != null){
      console.log("this.loadChart();");


      this.cloneDevice = new Device({ ...this.device});
      //this.cloneDevice.copyOf(this.device);


      console.log('Device ', this.device);
      console.log('cloneDevice ', this.cloneDevice);

      this.loadChart();
    }else{
      console.log("this.noData");
      this.noData = true;

      this.loading = false;
    }
  }

  ngAfterViewInit() {
  }


  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }


  public generateDayWiseTimeSeriesDynamic(dates: Array<DateTime>, valors: Array<any>): Array<any> {

    let r = [];
    for(let i = 0; i < dates.length ; i++){
      r.push({ "x" : dates[i].valueOf(), "y" : valors[i]});
    }

    //console.log("r", r);
    return r;
  }


  public getAlert(){
    console.log("getAlert");
    this.alertSrv.getById(new Notification( { 'Id' : this.idAlert })).subscribe({
      next: ( res: Notification) =>{

        console.log(res);
        if(res && this.device.IMEI == res.IMEI){
          this.notification = res;

          //let from = moment(res.TrackPosition!.Date).subtract(12, 'hours');
          //let to = moment(res.TrackPosition!.Date).add(12, 'hours');

          let from: DateTime= DateTime.fromJSDate(new Date(res.TrackPosition!.Date!), {zone: 'UTC+1'}).minus({ hours: 12});
          let to: DateTime = DateTime.fromJSDate(new Date(res.TrackPosition!.Date!), {zone: 'UTC+1'}).plus({ hours: 12});


          console.log(this.notification);
          console.log(from.toFormat("DD-MM-YYYY HH:mm:ss"));
          console.log(to.toFormat("DD-MM-YYYY HH:mm:ss"));
          console.log('value' , this.notification.Value);

          let auxSensor = this.listSensorRename.find( (x:any)=> { return x.value == this.notification!.Data! } )

          console.log(this.listSensorRename);
          console.log(this.notification!.Data!);
          console.log(auxSensor);

          if(auxSensor){
            console.log(auxSensor.key);
            this.listSensorView=[auxSensor.key];
          }

          this.InformationFirstValue = from;
          this.InformationLastValue = to;
        }else{
          this.showErrorAlert = true;
        }



      }, error: () => {
        this.showErrorAlert = true;
        console.log("error" , this.showErrorAlert);

      }, complete: () =>{

        this.reloadInfo(false);


      }
    });
  }



  public reloadInfo(refresh: boolean= true){

    this.isFirstTime = false;

    this.loading = true;

    console.log('loadDevice: ' , this.device!.Id );
    console.log('this.InformationFirstValue: ' , this.InformationFirstValue );
    console.log('this.InformationLastValue: ' , this.InformationLastValue );
    this.deviceSrv.getTrackInformations(this.device!.Id!, this.InformationFirstValue!.toFormat("dd/MM/yyyy"), this.InformationLastValue!.toFormat("dd/MM/yyyy"), (this.notification)?this.notification.Id:undefined).subscribe({
      next: (res: Array<Information>) => {

        console.log('res ', res);

        res.forEach( element => {
          console.log(' ++ ' + element.TrackPosition?.Date + ' :: ' + element.WorkingHours??''  );

        });


        this.cloneDevice = new Device();
        console.log('cloneDevice ', this.cloneDevice);

        this.cloneDevice.copyOf(this.device);


        console.log('Device ', this.device);
        console.log('cloneDevice ', this.cloneDevice);

        this.cloneDevice.clearInformations();
        this.cloneDevice.Informations = new Array<Information>();
        console.log('cloneDevice 2', this.cloneDevice);
        console.log('cloneDevice 2 Informations ', this.cloneDevice.Informations);

        this.cloneDevice.Informations.forEach( element => {
          console.log(' -- ' + element.TrackPosition?.Date + ' :: ' + element.WorkingHours??''  );

        });


        if(res.length > 0 ){

          this.firstInformation = <Information>cloneDeep(res[0]);
          this.lastInformation = <Information>cloneDeep(res[res.length - 1]);

          /* this.device!.InformationFirst = res[0].TrackPosition!.Date!;
          this.device!.InformationLast = res[res.length - 1].TrackPosition!.Date!;
          this.device!.InformationFirst = this.InformationFirstValue.toDate();
          this.device!.InformationLast = this.InformationLastValue.toDate();
          */
        }else{
          
          this.firstInformation = undefined;
          this.lastInformation = undefined;
        }

        // QQ: TODO
        //console.error(res);
        res.forEach( element => {
          this.cloneDevice!.Informations!.push(element);
          
          console.log('this.cloneDevice!.Informations!.length - ' + element.WorkingHours, this.cloneDevice!.Informations!.length);

          if(this.firstInformation!.Action1 && element.Action1 && element.Action1 < this.firstInformation!.Action1){
            this.firstInformation!.Action1 = element.Action1;
          }else if(!this.firstInformation!.Action1 && element.Action1){
            this.firstInformation!.Action1 = element.Action1;
          }
          if(this.firstInformation!.Aux2 && element.Aux2 && element.Aux2 < this.firstInformation!.Aux2){
            this.firstInformation!.Aux2 = element.Aux2;
          }else if(!this.firstInformation!.Aux2 && element.Aux2){
            this.firstInformation!.Aux2 = element.Aux2;
          }
          if(this.firstInformation!.Aux3 && element.Aux3 && element.Aux3 < this.firstInformation!.Aux3){
            this.firstInformation!.Aux3 = element.Aux3;
          }else if(!this.firstInformation!.Aux3 && element.Aux3){
            this.firstInformation!.Aux3 = element.Aux3;
          }
          if(this.firstInformation!.WorkingHours && element.WorkingHours && element.WorkingHours < this.firstInformation!.WorkingHours){
            this.firstInformation!.WorkingHours = element.WorkingHours;
          }else if(!this.firstInformation!.WorkingHours && element.WorkingHours){
            this.firstInformation!.WorkingHours = element.WorkingHours;
          }

          
          if(this.lastInformation!.Action1 && element.Action1 && element.Action1 > this.lastInformation!.Action1){
            this.lastInformation!.Action1 = element.Action1;
          }else if(!this.lastInformation!.Action1 && element.Action1){
            this.lastInformation!.Action1 = element.Action1;
          }
          if(this.lastInformation!.Aux2 && element.Aux2 && element.Aux2 > this.lastInformation!.Aux2){
            this.lastInformation!.Aux2 = element.Aux2;
          }else if(!this.lastInformation!.Aux2 && element.Aux2){
            this.lastInformation!.Aux2 = element.Aux2;
          }
          if(this.lastInformation!.Aux3 && element.Aux3 && element.Aux3 > this.lastInformation!.Aux3){
            this.lastInformation!.Aux3 = element.Aux3;
          }else if(!this.lastInformation!.Aux3 && element.Aux3){
            this.lastInformation!.Aux3 = element.Aux3;
          }
          if(this.lastInformation!.WorkingHours && element.WorkingHours && element.WorkingHours > this.lastInformation!.WorkingHours){
            this.lastInformation!.WorkingHours = element.WorkingHours;
          }else if(!this.lastInformation!.WorkingHours && element.WorkingHours){
            this.lastInformation!.WorkingHours = element.WorkingHours;
          }
          

        });



        console.log(this.device );
        console.log('cloneDevice 3', this.cloneDevice);
      }, error: ()=> {
        this.loading = false;

      },
      complete: ()=>{
        if(this.cloneDevice!.Informations!.length > 0){
          this.noData = false;
          this.loadChart(refresh);
        }else{
          this.noData = true;
          this.loading = false;
        }
      }
    });


  }

  public loadChart(refresh: boolean= false){

    console.log(refresh);

    console.log(refresh);
    this.loading = true;

    let opcionesColor : any= {
      'borderWidth': 2,
      'hoverOffset': 0,
      'cubicInterpolationMode': 'default', 'pointRadius': 0, 'hoverRadius': 0
    };
    console.log(this.cloneDevice?.Informations);

    this.infoChart!.RPM! = this.cloneDevice?.Informations?.map((element) => element.RPM);
    this.infoChart!.WorkingHours! = this.cloneDevice?.Informations?.map((element) => element.WorkingHours);
    this.infoChart!.Pressure1! = this.cloneDevice?.Informations?.map((element) => element.Pressure1);
    this.infoChart!.Pressure2! = this.cloneDevice?.Informations?.map((element) => element.Pressure2);
    this.infoChart!.Flow1! = this.cloneDevice?.Informations?.map((element) => element.Flow1);
    this.infoChart!.Flow2! = this.cloneDevice?.Informations?.map((element) => element.Flow2);
    this.infoChart!.Action1! = this.cloneDevice?.Informations?.map((element) => element.Action1);
    this.infoChart!.Action2! = this.cloneDevice?.Informations?.map((element) => element.Action2);
    this.infoChart!.Action3! = this.cloneDevice?.Informations?.map((element) => element.Action3);
    this.infoChart!.Action4! = this.cloneDevice?.Informations?.map((element) => element.Action4);
    this.infoChart!.Action5! = this.cloneDevice?.Informations?.map((element) => element.Action5);
    this.infoChart!.Action6! = this.cloneDevice?.Informations?.map((element) => element.Action6);
    this.infoChart!.Weight1! = this.cloneDevice?.Informations?.map((element) => element.Weight1);
    this.infoChart!.Weight2! = this.cloneDevice?.Informations?.map((element) => element.Weight2);
    this.infoChart!.Aux1! = this.cloneDevice?.Informations?.map((element) => element.Aux1);
    this.infoChart!.Aux2! = this.cloneDevice?.Informations?.map((element) => element.Aux2);
    this.infoChart!.Aux3! = this.cloneDevice?.Informations?.map((element) => element.Aux3);
    this.infoChart!.Aux4! = this.cloneDevice?.Informations?.map((element) => element.Aux4);
    this.infoChart!.Aux5! = this.cloneDevice?.Informations?.map((element) => element.Aux5);
    this.infoChart!.Aux6! = this.cloneDevice?.Informations?.map((element) => element.Aux6);
    this.infoChart!.Aux7! = this.cloneDevice?.Informations?.map((element) => element.Aux7);
    this.infoChart!.Aux8! = this.cloneDevice?.Informations?.map((element) => element.Aux8);

    //console.log("RPM", ...this.infoChart!.RPM! );

    let data = [
      {...this.infoChart!.RPM! },
      {...this.infoChart!.WorkingHours! },
      {...this.infoChart!.Pressure1! },
      {...this.infoChart!.Pressure2! },
      {...this.infoChart!.Flow1! },
      {...this.infoChart!.Flow2! },
      {...this.infoChart!.Action1! },
      {...this.infoChart!.Action2! },
      {...this.infoChart!.Action3! },
      {...this.infoChart!.Action4! },
      {...this.infoChart!.Action5! },
      {...this.infoChart!.Action6! },
      {...this.infoChart!.Weight1! },
      {...this.infoChart!.Weight2! },
      {...this.infoChart!.Aux1! },
      {...this.infoChart!.Aux2! },
      {...this.infoChart!.Aux3! },
      {...this.infoChart!.Aux4! },
      {...this.infoChart!.Aux5! },
      {...this.infoChart!.Aux6! },
      {...this.infoChart!.Aux7! },
      {...this.infoChart!.Aux8! },
    ];

    //this.infoChart!.Dates! = this.cloneDevice?.Informations?.map((element) => DateTime.fromJSDate(element.TrackPosition!.Date!));

    console.log("this.cloneDevice?.Informations", this.cloneDevice?.Informations );
    this.infoChart!.Dates! = this.cloneDevice?.Informations?.filter((element) => element.TrackPosition!.Date).map((element) => {

      let milis: number = new Date(element.TrackPosition!.Date!).getTime();
      //console.log(milis);
      //console.log(element.TrackPosition!.Date);
      //console.log(DateTime.fromMillis(milis));
      return DateTime.fromMillis(milis);
    });

    //console.log(this.cloneDevice?.Informations![0]);
    //console.log(this.infoChart!.Dates![0]);

    this.datasets = [];
    //this.datasets2 = [];

    console.log(data);
    let totalCount = 0;

    for(let i = 0; i < data.length; i++){
      //this.lineChartData.datasets[i].data = data[i];
      //this.lineChartData.datasets[i].label = labelDataset[i];
      
      let auxSensor = sensors.find( e => e.key == this.listSensor[i]);
      console.log('auxSensor' , auxSensor);

      if(this.listSensorView.includes(this.listSensor[i])){

        let dataset: DataSetApex = { name : this.listSensor[i] , data : [ ] };
        let arr: any = [];
        let dates: any = [];

        let element = 0;
        let auxI = auxSensor!.position;

        console.log(data[i]);
        Object.keys(data[i]).map((key) =>{

           
            if(data[i][key] != undefined && data[i][key] != null){

              let aux: DateTime = this.infoChart!.Dates![element];

              dataset.data.push({ y: data[i][key], x: aux.valueOf()  })
              arr.push(data[i][key])
              dates.push( this.infoChart!.Dates![element] );

            }else{
              console.log('problem data' , data[i] , key , 'value' , data[i][key]);
            }
            element = element + 1;
        });

        console.log(dataset);
        console.log(arr);
        console.log(dates);
        //console.log(this.listSensor[i]);
        //console.log(data[i]);

        //this.lineChartData.datasets = [];

        if(arr.length > 0){
          totalCount += 1;

          //dataset.data.push({ x: this.infoChart!.Dates![element].valueOf, y: data[i][key]});

          //dataset.data = this.generateDayWiseTimeSeriesDynamic(this.infoChart!.Dates!, arr);

          //this.generateDayWiseTimeSeriesDynamic(dates, arr);
          //this.lineChartData.datasets.push( { 'data': arr, 'label': this.listSensor[i], /*'hidden': (i > 1)?true:false,*/ ...opcionesColor } );


          //this.lineChartDataTimeline.datasets.push( { 'data': arr, 'label': this.listSensor[i], /*'hidden': (i > 1)?true:false, */ ...opcionesColor } );


          this.datasets.push(dataset);

        }

      }
    }

    console.log(this.datasets.length);

    let getData= false;
    this.datasets.forEach( x => {

      if(x !== undefined){
        getData = true;
      }

    });

    if(getData == false){
      this.loading = false;
      this.noData = true;
      return;
    }

    if(this.datasets[0]){
      console.log(this.datasets[0]);
      console.log(this.datasets[0].data[0].y??'');
    }



    if(totalCount==0){
      this.noData = true;
    }else{
      this.noData = false;
      /*
      if( this.InformationFirstValue == null && this.cloneDevice?.InformationFirst){
        //this.InformationFirstValue = moment(this.cloneDevice?.InformationFirst);
        this.InformationFirstValue = DateTime.local(this.cloneDevice?.InformationFirst!.getFullYear(), this.cloneDevice?.InformationFirst!.getMonth(), this.cloneDevice?.InformationFirst!.getDate())
      }
      if(this.InformationLastValue  == null && this.cloneDevice?.InformationLast){
        //this.InformationLastValue = moment(this.cloneDevice?.InformationLast);
        this.InformationLastValue = DateTime.local(this.cloneDevice?.InformationLast!.getFullYear(), this.cloneDevice?.InformationLast!.getMonth(), this.cloneDevice?.InformationLast!.getDate())
      }*/
    }
    if(false){
    //if(refresh == true){

      this.chartOptions1["series"] = [ ...this.datasets ];
      this.chartOptions2["series"] = [ ...this.datasets ];
      this.chart?.render();

      //let series: Array<ApexAxisChartSeries> = datasets.map( e => { return <ApexAxisChartSeries> e;  });
    }else{

      /*
      for(let i = this.datasets.length/2; i < this.datasets.length; i++){
        this.datasets2.push(this.datasets[i]);
      }*/


    //console.log(this.datasets2[0]);
    //console.log(this.datasets2[0].data[0]);
    //console.log(this.datasets2[0].data[1]);

      this.chartOptions1 = {
        series: [ ...this.datasets],
        animations: {
          initialAnimation: {
            enabled: false
          },
          enabled: false,
        },
        chart: {
          id: "chart2",
          type: "line",
          background: '#fff',
          height: 230,
          toolbar: {
            autoSelected: "zoom",
            show: false,
          },
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
        },
        colors: this.colors,
        stroke: {
          width: 3
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          opacity: 1
        },
        markers: {
          size: 0
        },
        xaxis: {
          type: "datetime",
          tooltip: {
              enabled: true,
              formatter: function (value: any, opt: any) {
                let time = DateTime.fromMillis(value.valueOf())

                //let maxTime = DateTime.fromMillis
                //let minTime = DateTime.fromMillis(opt.xaxis.min!.valueOf())
                //console.log(opt.w.config.series[0].data[0].x!);
                //console.log(opt.w.config.series[0].data[opt.w.config.series[0].data.length-1].x!);
                //return minTime.toFormat("dd/MM/yyyy - HH:mm:ss")

                /*
                let maxTime = DateTime.fromMillis(opt.w.config.series[0].data[opt.w.config.series[0].data.length-1].x!);
                let minTime = DateTime.fromMillis(opt.w.config.series[0].data[0].x!);


                let diffHour: number = maxTime.diff(minTime).as('hours');
                let diffDays: number = maxTime.diff(minTime).as('days');
                console.log(diffDays , ' - ' , diffHour);
                if(diffDays > 1){
                  console.log("1");
                  return time.toFormat("dd/MM/yyyy - HH:mm")
                }
                if(diffHour > 2){
                  console.log("2");
                  return time.toFormat("dd/MM/yyyy - HH:mm")
                }
                console.log("3");*/
                return time.toFormat("dd/MM/yyyy - HH:mm:ss")
              },
          },
          labels: {
            /*
              formatter: function (value: any) {
              let time = DateTime.fromMillis(value.valueOf())
              return time.toFormat("dd/MM/yyyy - HH:mm")
            },*/
            datetimeUTC: false,
            datetimeFormatter: {
                year: 'yyyy',
                month: "MMM 'yy",
                day: 'dd MMM',
                hour: 'HH:mm',
                minutes: 'HH:mm:ss',
            },
          },
        },
        yaxis: {
          min: 0,
          max: 700,
          labels: {
            formatter: function (value: number) {
              return value.toFixed(2);
            }
          },
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            format: 'dd/MM/yyyy - HH:mm',
          },
        }, legend: {

          position: 'top'
        }
      };

      this.chartOptions2 = {
        series: [
          ...this.datasets
        ],
        animations: {
          initialAnimation: {
            enabled: false
          },
          enabled: false,
        },
        chart: {
          id: "chart1",
          height: 130,
          type: "area",
          brush: {
            target: "chart2",
            enabled: true
          },
          selection: {
            enabled: true,
            xaxis: {
              //min: 0,
              //max: 10000
              min: (this.isFirstTime == true)?DateTime.fromMillis(this.datasets[0].data![this.datasets[0].data.length - 1].x!).minus({ minutes: 15}).toMillis():this.datasets[0].data![0].x!,
              //min: this.datasets2[0].data![this.datasets2[0].data.length/2].x!,
              max: this.datasets[0].data![this.datasets[0].data.length - 1].x!.valueOf(),
            }
          }
        },
        colors: this.colors,
        xaxis: {
          type: "datetime",
          tooltip: {
            enabled: false
          },
          labels: {
            datetimeUTC: false,
            /*
            formatter: function (value: any) {
              let time = DateTime.fromMillis(value.valueOf())
              return time.toFormat("dd/MM/yyyy - HH:mm")
            }*/
          }
        },
        yaxis: {
          forceNiceScale: true,
          tickAmount: 2,
          labels: {
            formatter: function (value: number) {
              return value.toFixed(2);
            }
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          opacity: 0
        },
        legend: {
          show: false
        }
      };

      this.chartOptionsPDF = {
        series: [ ...this.datasets
        ],
        chart: {
          id: "chart2",
          type: "line",
          height: 400,
          background: '#fff',
          toolbar: {
            autoSelected: "zoom",
            show: false,
          },
          selection: {
            enabled: true,
            xaxis: {
              min: (this.isFirstTime == true)?DateTime.fromMillis(this.datasets[0].data![this.datasets[0].data.length - 1].x!).minus({ minutes: 15}).toMillis():this.datasets[0].data![0].x!,
              max: this.datasets[0].data![this.datasets[0].data.length - 1].x!
              //min: this.generateDayWiseTimeSeriesDynamic(this.infoChart!.Dates!, this.infoChart!.RPM!)[0]["x"],
              //max: this.generateDayWiseTimeSeriesDynamic(this.infoChart!.Dates!, this.infoChart!.RPM!)[this.generateDayWiseTimeSeriesDynamic(this.infoChart!.Dates!, this.infoChart!.RPM!).length -1]["x"],
            }
          },
          animations: {
            initialAnimation: {
              enabled: false
            },
            enabled: false,
          }
        },
        colors: this.colors,
        stroke: {
          width: 2
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          opacity: 1
        },
        markers: {
          size: 0
        },
        xaxis: {
          type: "datetime",
          tooltip: {
            enabled: false
          },
          labels: {
            datetimeUTC: false
          }
        },
        yaxis: {
          labels: {
            formatter: function (value: number) {
              return value.toFixed(2);
            }
          },
        },
        legend: {
          position: 'top'
        },
      };


      console.log("this.loading" , this.loading);
    }


    if(false){
    //if(this.isFirstTime){
      console.log('isFirstTime', this.isFirstTime);
      this.isFirstTime = false;
      this.reloadInfo();
      //this.chart?.updateOptions(this.chartOptions1);
    }else{


      console.log("this.loading" , this.notification);
      if(this.notification && this.notification?.Value){
        const that = this;
        try{
          setTimeout(() => {

            console.log('value' , this.notification!.Value);

            let datePoint = DateTime.fromJSDate(new Date(this.notification!.TrackPosition!.Date!), {zone: 'UTC+1'});
            console.log('datePoint' , datePoint);


            that.chart!.addPointAnnotation({
              x: datePoint.valueOf(),
              y: +(this.notification!.Value!.replace(",",".")),
              borderColor: "#FEB019",
                  label: {
                    borderColor: "#FEB019",
                    style: {
                      color: "#fff",
                      background: "#FEB019"
                    },
                    orientation: "horizontal",
                    text: "Alerta"
                  }
            });

          }, 500);
        }catch(e){
          console.log('error ' , e);
        }
      }else{
        // QQ: feia petar..
        // console.log('noValue' , this.notification!.Value);
        console.log('noValue');
      }

      this.loading = false;

    }
  }

  get dataMaxChart(): string{

    if(this.chart){

      return this.chart.xaxis.min?.toString() + " - " + this.chart.xaxis.max?.toString();
    }
    return "";
  }

  downloadPDF(){

    //console.log(this.chartOptions1.chart);
    //this.chart?.zoomX(this.chartOptions1.chart.selection.min, this.chartOptions1.chart.selection.max )

    this.noData = true;
    this.loading = true;

    this.exportData.changeData(
      {
        sensor:     this.chartOptions1.series,
        imei:       this.device.IMEI,
        nombre:     this.device.Name,
        modelo:     this.device?.DeviceModel.Name,
        cliente:    this.device?.User?.Name,
        fabricante: this.device?.Manufacturer.Name,
        desde:      this.InformationFirstValue,
        hasta:      this.InformationLastValue,
        numeroSerie: this.device?.NumSerieManufacturer,
        download:   true,
        notification: (this.idAlert != null)?this.notification!.Value:''
      }
    );
    setTimeout( ()=> { 

      this.noData = false;
      this.loading = false;
  
      this.exportChart.changeChart(this.chartOptionsPDF);

    } , 200);
  }

  // Recarga grafica al mostrar/ocultar sidenav
  @HostListener('document:click', ['$event'])
  clickout(event: { target: any; }) {
    if (event.target.closest('button') && event.target.closest('button').id === 'menu') {
      this.reloadInfo();
    }
  }

  removeFilter(){
    console.log("removeFilter");
    this.idAlert = undefined;
    this.notification = undefined;
  }

  changeSensorSelected(e: any){
    console.log('changeSensorSelected' , e);
    this.loadChart(true);
  }

  public calcularHorasYMinutos(numero: number) {
    // Obtén las horas enteras (parte entera del número)
    const horas = Math.floor(numero);
  
    // Obtén los minutos a partir de la parte decimal del número
    const minutosDecimal = numero - horas;
    const minutos = Math.round(minutosDecimal * 60); // Convierte el decimal a minutos
  
    return { horas: horas, minutos: minutos };
  }
}

