<app-breadcrumb></app-breadcrumb>

<div class="container-fluid pt-3 pl-0 pr-0 espace">

  <ng-container *ngIf="authSrv.isAdmin() == true">
    <div class="my-4">
      <div class="container-fluid main">
        <div class="row row-eq-height">
          <div class="col-12">
            <div class="row align-items-center">
              <div class="col-xxl-4">
                <h1><b>{{'modules.users.users' | translate}}</b></h1>
              </div>
              <div class="col-xxl-8 text-xxl-end">
                <div class="btn btn-primary m-2" [routerLink]="['/users/add/user']">
                    {{'modules.users.add-user' | translate}}
                </div>
                <div class="btn btn-primary m-2" [routerLink]="['/users/add/fabricant']">
                    {{ 'modules.account.edit-account' | translate }}
                </div>
                <div class="btn btn-primary m-2" [routerLink]="['/users/add/admin']">
                  {{ 'modules.users.add-admin' | translate }}
                </div>

                <app-generic-button *ngIf="authSrv.isAdmin() == true" txtBtn="{{ 'modules.shared.sync' | translate }}" title="{{'modules.shared.warning' | translate}}" txt="{{'modules.alarms.are-you-sure-sync' | translate}} ?"
              btnAccept="{{ 'modules.shared.sync' | translate }}" (confirmDelete)="syncUsers()" ></app-generic-button>

                <!-- <div class="btn btn-primary m-2" (click)="syncUsers()">
                  {{ 'modules.shared.sync' | translate }}
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="container-fluid pt-3 pl-0 pr-0 espace">
      <app-block-group-dashboard>
        <div class="col-xl-3 col-lg-6">
          <div class="card card-inverse card-success mb-3">
            <div class="card-block bg-success">
              <div class="rotate">
                <i class="fa fa-user fa-5x"></i>
              </div>
              <a [routerLink]="['/users/add/user']">
                <h6 class="text-uppercase txt-size-info">
                  {{ 'modules.users.add-client' | translate }}
                </h6></a>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6">
          <div class="card card-inverse card-danger mb-3">
            <div class="card-block bg-info">
              <div class="rotate">
                <i class="fas fa-users fa-5x"></i>
              </div>
              <a [routerLink]="['/users/add/fabricant']">
                <h6 class="text-uppercase txt-size-info">
                  {{ 'modules.users.add-manufacturer' | translate }}
                </h6></a>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6">
          <div class="card card-inverse card-warning mb-3">
            <div class="card-block bg-warning">
              <div class="rotate">
                <i class="fas fa-user-shield fa-5x"></i>
              </div>
              <a [routerLink]="['/users/add/admin']">
                <h6 class="text-uppercase txt-size-info">
                  {{ 'modules.users.add-admin' | translate }}
                </h6></a>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6">
          <div class="card card-inverse card-primary mb-3">
            <div class="card-block bg-primary">
              <div class="rotate">
                <i class="fas fa-sync-alt fa-3x"></i>
              </div>
                <h6 class="text-uppercase txt-size-info c-pointer" (click)="syncUsers()">
                  {{ 'modules.shared.sync' | translate }}
                </h6>
            </div>
          </div>
        </div>
      </app-block-group-dashboard>
    </div> -->

  </ng-container>

  <app-block-group-dashboard>
    <app-block-dashboard>
      <mat-tab-group mat-align-tabs="start" [selectedIndex]="page" (selectedIndexChange)="page = $event">
        <mat-tab>
            <ng-template mat-tab-label>
                {{'modules.users.users' | translate}}
            </ng-template>
            <div class="mt-3">
              <app-list-users [showNewUser]="authSrv.isManufacturer()"></app-list-users>
            </div>
        </mat-tab>
      </mat-tab-group>
    </app-block-dashboard>
  </app-block-group-dashboard>

</div>

<!--   <app-block-group-dashboard>

    <app-block-dashboard>
      <h2>{{'modules.users.users-list' | translate}}</h2>
      <app-list-users></app-list-users>
    </app-block-dashboard>

  </app-block-group-dashboard>
-->

<app-footer></app-footer>
