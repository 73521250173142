import {Injectable} from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';

@Injectable()
export class CustomLuxonDateAdapter extends LuxonDateAdapter {

  override getFirstDayOfWeek(): number {
    return 1;
  }

}
