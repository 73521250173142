import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { User } from 'src/app/models/user';
import { Subject } from 'rxjs';
import { DatatableColumn, DatatableConfig } from 'src/app/models/datatableColumn';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.services';
import { UtilsService } from 'src/app/services/utils.service';
import { AlarmsService } from 'src/app/services/alarms.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ListResult } from 'src/app/models/apiRequestObject';
import { AlarmDeviceValues, vAlarmDeviceModelDefaultValue } from 'src/app/models/alarms';
import { Device } from 'src/app/models/device';
import { Modal } from 'src/app/models/modal';
import { ConfirmationModalComponent } from 'src/app/modals/confirmation-modal/confirmation-modal.component';
@Component({
  selector: 'app-list-device-alarms',
  templateUrl: './list-device-alarms.component.html',
  styleUrls: ['./list-device-alarms.component.scss']
})
export class ListDeviceAlarmsComponent implements OnInit , OnDestroy, AfterViewInit {
  public page?: number;
  @Input() showNewUser: boolean = true;
  @ViewChild(DataTableDirective, {static: false}) datatableElement?: DataTableDirective;
  @Input() userInput: User | null = null;
  @Input() deviceInput: Device | null = null;
  @Input() nameDatatable: string = "DeviceAlarms";

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  datatableConfigColumns: DatatableConfig = new DatatableConfig();
  isDtInitialized: boolean = false;
  viewPaging: boolean = true;

  listaAlarmas: vAlarmDeviceModelDefaultValue[] = [];

  loadingDelete: boolean = false;

  loading: boolean = true;

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private router : Router,
    private usersSrv : UsersService,
    private utilsSrv : UtilsService,
    private alarmsSrv: AlarmsService,
    public authSrv: AuthenticationService) { }



  ngAfterViewInit(): void {
    console.log("ngAfterViewInit");
      const that = this;
      that.dtTrigger.next(0);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngOnInit(): void {
    this.dtOptions = this.optionsDatatable();

  }

  public optionsDatatable() {

    this.datatableConfigColumns.headerTable = [
      new DatatableColumn(0,"#", "Id", "asc"),
      new DatatableColumn(1,this.translate.instant('modules.tables.name'), "Name", "asc"),
      new DatatableColumn(2,"IMEI", "IMEI", "asc"),
      new DatatableColumn(3,this.translate.instant('modules.tables.model-device'), "NameModelDevice", "asc"),
      new DatatableColumn(4,this.translate.instant('modules.tables.account'), "NameModelDevice", "asc"),
      new DatatableColumn(5,this.translate.instant('modules.tables.created-at'), "CreatedAt", "desc"),
      new DatatableColumn(6,this.translate.instant('modules.tables.active'), "Active", "desc"),
      new DatatableColumn(7,this.translate.instant('modules.tables.actions'), "", "asc")
    ];

    console.log("list-users input ", this.userInput);


    this.datatableConfigColumns.changeOrderByTag("CreatedAt");
    this.datatableConfigColumns.orderDesc();

    const that = this;

    console.log("DATATABLES", this.datatableConfigColumns.order, this.datatableConfigColumns.columnDefs );

    console.log(this.datatableConfigColumns.columnDefs);

    return {
      // Use this attribute to enable the responsive extension
      name: this.nameDatatable,
      order: this.datatableConfigColumns.order,
      columnDefs: this.datatableConfigColumns.columnDefs,
      lengthChange: true,
      serverSide: true,
      processing: false,
      retrieve: true,
      //stateSave: true,
      deferRender: true,
      initComplete: (settings: DataTables.Settings, json: any) => {
        $("#" + this.nameDatatable).wrap("<div class='demo' style='overflow:auto; width:100%;position:relative;'></div>");
        if(!this.viewPaging){
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().parent().find("div").eq(0).removeClass("col-md-5").addClass("col-md-12");
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().css("display", "none" );
        }else{
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().parent().find("div").eq(0).removeClass("col-md-12").addClass("col-md-5");
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().css("display", "block" );
        }
      },
      ajax: (dataTablesParameters: any, callback: any) => {

        that.loading = true;
        that.listaAlarmas = [];

        let column = dataTablesParameters.order[0].column;
        let colmunOrder = this.datatableConfigColumns.getColumnById(column);
        let colmunOrderTag = colmunOrder?.tag!;
        let order = dataTablesParameters.order[0].dir;
        let draw = dataTablesParameters.draw;

        dataTablesParameters.search.regex = true
        let page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length);
        this.listaAlarmas = [];

        that.alarmsSrv.getListByDevice(page, dataTablesParameters.length, colmunOrderTag, dataTablesParameters.order[0].dir, dataTablesParameters.search.value, this.deviceInput??undefined).subscribe(
          {
            next: (res: ListResult) => {

            that.listaAlarmas = <vAlarmDeviceModelDefaultValue[]>res.Result.map( x => new vAlarmDeviceModelDefaultValue(x));
            //that.listaUsuarios = <vUser[]>res.Result;

            this.viewPaging = false;
            if(res.Filter > 10){
              this.viewPaging = true;
            }

            callback({
              recordsTotal: res.Total,
              recordsFiltered: res.Filter,
              data: []
            });

            that.loading = false;
            if (res.Result.length > 0) {
              $('.dataTables_empty').css('display', 'none');
            }

          },
          error: ( err : any) => {
            that.loading = false;
          }
        });


      },
      language: {
        url: this.utilsSrv.DatatablesFileLang()
      },
      infoCallback: function( settings: any, start:number, end:number, max:number, total:number, pre: any ) {
        return that.datatableConfigColumns.DatatablesFooterShowText(settings, start,end, max, total , pre );
      },
    };

  }

  rerender(): void {
    this.datatableElement!.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next(0);
    });
  }

  deleteItem(alarm: vAlarmDeviceModelDefaultValue){

    let modal = new Modal();
    modal.Title = this.translate.instant('modules.shared.warning');
    modal.Body = this.translate.instant('modules.modals.delete-alarm') + " #" + alarm.Id + " " + this.translate.instant('modules.modals.of-device-with-model') + " " + alarm.NameModelDevice??this.translate.instant('modules.devices.no-name');
    modal.BtnAccept = this.translate.instant('modules.shared.delete')
    modal.BtnCancel = this.translate.instant('modules.shared.cancel');
    modal.ClassType = "danger";

    const dialogRef = this.dialog.open(
      ConfirmationModalComponent,
      {
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: modal,
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe( (result: boolean) => {
      if(result){

        this.loadingDelete = true;
        this.alarmsSrv.deleteByDevice(<AlarmDeviceValues>new AlarmDeviceValues({ 'Id' : alarm!.Id! })).subscribe(
          {
            next: (res: boolean) => {
              if(res==true){
                this.rerender();

              }
            },
            error: (err: any) =>{
              if(err.status! == "MODAL"){
                this.utilsSrv.infoModal(err.msg!,undefined);
              }
              this.loadingDelete = false;
            },
            complete: () =>{
              this.loadingDelete = false;
            }
          }
        );
      }
    });

  }

}
