import { Component, OnInit,Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AnimationOptions } from 'ngx-lottie';
import { ConfirmationModalComponent } from 'src/app/modals/confirmation-modal/confirmation-modal.component';
import { InputTextModalComponent } from 'src/app/modals/input-text-modal/input-text-modal.component';
import { ApiRequestObject, ListResult } from 'src/app/models/apiRequestObject';
import { Modal } from 'src/app/models/modal';
import { User, UserWithPassword, vUser } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ThemeService } from 'src/app/services/theme.services';
import { UserService } from 'src/app/services/user.service';
import { UsersService } from 'src/app/services/users.services';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-edit-user-form',
  templateUrl: './edit-user-form.component.html',
  styleUrls: ['./edit-user-form.component.scss']
})
export class EditUserFormComponent implements OnInit {

  @Input() userInput: User | null = null;
  @Input() pageInput?: number;
  @Input() IdUser?: number;


  public currentPassword: string  = '';
  public lastMail: string  = '';
  editFormShared: FormGroup = this.formBuilder.group({  });
  addressFormShared: FormGroup = this.formBuilder.group({  });
  contactFormShared: FormGroup = this.formBuilder.group({  });
  specialFormShared: FormGroup = this.formBuilder.group({  });
  bankInformationFormShared: FormGroup = this.formBuilder.group({  });
  themeFormShared: FormGroup = this.formBuilder.group({  });
  devicesFormShared: FormGroup = this.formBuilder.group({  });
  passwordFormShared: FormGroup = this.formBuilder.group({});
  editClientFormShared: FormGroup = this.formBuilder.group({  });

  clientsFormShared: FormGroup = this.formBuilder.group({  });
  manufacturersFormShared: FormGroup = this.formBuilder.group({  });

  newPassword?: String = "";
  newPasswordRepeat?: String = "";

  updateEditFormShared(form: FormGroup) {
    console.log("updateEditFormShared");
    this.editFormShared = form;
  }
  updateAddressFormShared(form: FormGroup) {
    this.addressFormShared = form;
  }
  updateContactFormShared(form: FormGroup) {
    console.log("updateContactFormShared");
    this.contactFormShared = form;
  }
  updateSpecialFormShared(form: FormGroup) {
    this.specialFormShared = form;
  }
  updateBankInformationFormShared(form: FormGroup) {
    this.bankInformationFormShared = form;
  }
  updateThemeFormShared(form: FormGroup) {
    console.log("getFormGeneral");
    this.themeFormShared = form;
  }
  updateDevicesFormShared(form: FormGroup) {
    console.log("updateDevicesFormShared");
    this.devicesFormShared = form;

    if(this.devicesFormShared.get("Devices")!.get("vDevices") == undefined){
      console.log("vDevices unde")
    }
  }

  updatePasswordFormShared(form: FormGroup){
    this.passwordFormShared = form;
  }


  updateEditClientFormShared(form: FormGroup) {
    console.log("EditClient");
    this.editClientFormShared = form;
  }

  updateClientsFormShared(form: FormGroup) {
    console.log("getFormGeneral");
    this.clientsFormShared = form;
  }
  updateManufacturerFormShared(form: FormGroup) {
    console.log("getFormGeneral");
    this.manufacturersFormShared = form;
  }


  public page?: number;
  loading:boolean = true;
  public user:User | null = null;
  public originalUser:User | null = null;

  isManufacturerOfClient: boolean = false;
  listaUsuarios: vUser[] = [];

  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private breadcrumbService: BreadcrumbService,
    public authSrv: AuthenticationService,
    private userSrv : UserService,
    private usersSrv: UsersService,
    private themeSrv: ThemeService,
    private utilsSrv: UtilsService,
    private route: ActivatedRoute,
    private translate: TranslateService) {

      this.IdUser = Number(this.route.snapshot.paramMap.get('id'))?? this.IdUser??null;
      this.page = Number(this.route.snapshot.paramMap.get('page'))?? 0;

    }

  ngOnInit(): void {
    this.loading = true;

    if(this.userInput){
      this.IdUser = this.userInput.Id;
    }else{
      this.breadcrumbService.setBreadCrumbUrl('users/list', this.translate.instant('modules.users.management'));
    }

    this.userSrv.getUserById(this.IdUser!).subscribe({
      next: (res: User) => {
        this.user = new User(res);
        this.lastMail = this.user.Email??'';

        this.originalUser = Object.assign({},res);
        if(this.user.vDevices == null){
          this.user.vDevices = [];
        }
        if(this.userInput == null){
          this.breadcrumbService.addBreadCrumb('users/' + this.IdUser! + '/detail', this.user.Name??'' + ' ' + this.user.FirstName??'' + ' ' + this.user.LastName??'');
          this.breadcrumbService.addBreadCrumb('users/' + this.IdUser! + '/edit', this.translate.instant('modules.shared.edit'));
        }
        console.log(this.originalUser);

        if(this.authSrv.isManufacturer() && this.user!.isClient){
          console.log("isManufacturer");
          this.usersSrv.getList(0, 100000, 'Name', 'ASC').subscribe(
            {
              next: (res: ListResult) => {
                this.listaUsuarios = <vUser[]>res.Result.map( x => new vUser(x));

                this.listaUsuarios.forEach( element => {

                  if(element.Id == this.user!.Id){
                    this.isManufacturerOfClient = true;
                  }

                });

              },
              error: (err: any) =>{

              },
              complete: ()  => {
                this.loading = false;
              }
          });

        }else{
          this.loading = false;

        }


      },
      error: (err: any)=>{
        this.loading = false;

      },
      complete: ()=>{

        if(this.page !=0 ){

        }
      }
    });
  }

  get editFormSharedValid():boolean{
    return this.editFormShared.get('User')?.valid??false;
  }
  get contactFormSharedValid():boolean{
    return this.contactFormShared.get('Contact')?.valid??false;
  }

  get themeFormSharedValid():boolean{
    return this.themeFormShared.get('Theme')?.valid??false;
  }

  get addressFormSharedValid():boolean{
    return this.addressFormShared.get('Address')?.valid??false;
  }

  get specialFormSharedValid():boolean{
    return this.specialFormShared.get('Special')?.valid??false;
  }

  get bankInformationFormSharedValid():boolean{
    return this.specialFormShared.get('BankInformation')?.valid??false;

  }
  get editClientFormSharedValid():boolean{
    return this.editClientFormShared.get('EditClient')?.valid??false;
  }

  get clientsFormSharedValid():boolean{
    return this.clientsFormShared.get('Clients')?.valid??false;
  }
  get manufacturersFormSharedValid():boolean{
    return this.manufacturersFormShared.get('Manufacturers')?.valid??false;
  }


  get devicesFormSharedValid():boolean{
    return this.devicesFormShared.get('Devices')?.valid??false;
  }

  get passwordFormSharedValid(): boolean{
    return this.passwordFormShared.get('Password')?.valid??false;
  }



  get validAllForm():boolean{
    return this.editFormSharedValid && this.contactFormSharedValid && this.themeFormSharedValid && this.addressFormSharedValid  && this.specialFormSharedValid && this.bankInformationFormSharedValid ;
  }

  get validClientForm():boolean{
    return this.contactFormSharedValid  && this.addressFormSharedValid && this.editClientFormSharedValid;
  }
  get validManufacturerForm():boolean{
    return this.contactFormSharedValid  && this.addressFormSharedValid && this.themeFormSharedValid;
  }


  onSubmit(){


    console.log(this.user!);
    console.log('editFormShared ', this.editFormShared.get('User')?.valid);
    console.log('contactFormShared ' , this.contactFormShared.get('Contact')?.valid);
    console.log('themeFormShared ', this.themeFormShared.get('Theme')?.valid);
    console.log('addressFormShared ' , this.addressFormShared.get('Address')?.valid);
    console.log('specialFormShared' , this.specialFormShared.get('Special')?.valid);
    console.log('devicesFormShared' , this.devicesFormShared.get('Devices')?.valid);
    console.log('devicesFormShared' , this.editClientFormShared.get('EditClient')?.valid);
    console.log('bankInformationFormShared' , this.editClientFormShared.get('BankInformation')?.valid);


    console.log('Clients' , this.clientsFormShared.get('Clients'));
    console.log('Manufacturers' , this.manufacturersFormShared.get('Manufacturers'));
    console.log(this.validAllForm);


    if(this.authSrv.currentUserValue?.UserId == this.user!.Id){

      if(this.passwordFormSharedValid == false){

        console.log(this.passwordFormShared.get('Password'));

        this.utilsSrv.infoModal(this.translate.instant('modules.users.new-password-not-match') + '.', undefined, this.translate.instant('modules.users.save-data-error'));
        return;
      }

    }

      if(this.authSrv.isAdmin()){
        if(this.validAllForm){
          this.saveChange();
        }else{
          this.openSaveModal();
        }
      }


      if(this.authSrv.isClient()){
        if(this.validClientForm){
          this.saveChange();
        }else{
          this.openSaveModal();
        }
      }

      if(this.authSrv.isManufacturer()){
        if(this.validManufacturerForm){
          this.saveChange();
        }else{
          this.openSaveModal();
        }
      }




  }

  saveChange(){

    if( ( this.authSrv.currentUserValue?.UserId == this.user!.Id && this.lastMail != this.user!.Email && this.currentPassword == '')){

      this.openPasswordModal();
      return;
    }

    if( ( this.authSrv.currentUserValue?.UserId == this.user!.Id  && this.currentPassword == '' &&  (this.passwordFormShared.get('Password')?.get("NewPassword")?.value != '' && this.passwordFormShared.get('Password')?.get("NewPassword")?.value != null) )){

      console.log("NewPasswor");
      this.openPasswordModal();
      return;
    }

    this.loading = true;
    console.log("savechanges");

    // Cambiar sus propios datos
    if(this.authSrv.currentUserValue?.UserId == this.user!.Id ){


      let aux: UserWithPassword = <UserWithPassword>this.user!;
      aux.CurrentPassword = this.currentPassword;

      if(this.passwordFormShared.get('Password')?.get("NewPassword")?.value != '' && this.passwordFormShared.get('Password')?.get("NewPassword")?.value != null){
        aux.NewPassword = this.passwordFormShared.get('Password')?.get("NewPassword")?.value;
        aux.NewPassordRepeat = this.passwordFormShared.get('Password')?.get("NewPasswordRepeat")?.value;
      }
      console.log("aux");
      console.log(aux);

      this.userSrv.saveChanges(aux!).subscribe({
        next: (res: User) => {
          this.user= null;
          console.log("OK")
          this.user = new User(res);


          this.themeSrv.deletePreviewTheme();

          if(this.user.Id == this.authSrv.currentUserValue?.UserId){
            if(this.user.Theme){
              this.themeSrv.update(this.user.Theme);
            }
          }

          //this.user = Object.assign({},res);
          this.originalUser = new User(Object.assign({},res));
          if(this.userInput == null){

            this.breadcrumbService.setBreadCrumbUrl('users/list', this.translate.instant('modules.users.management'));
            this.breadcrumbService.addBreadCrumb('users/' + this.IdUser! + '/detail', this.user!.Name??'' + ' ' + this.user!.FirstName??'' + ' ' + this.user!.LastName??'');
            this.breadcrumbService.addBreadCrumb('users/' + this.IdUser! + '/edit', this.translate.instant('modules.shared.edit'));
          }
        }, error: (err: ApiRequestObject) =>{
          this.currentPassword = '';
          if(err.status! == "MODAL"){


            this.utilsSrv.infoModal(err.msg!, undefined, err.title);
          }
          this.loading = false;
        },
        complete: () =>{
          this.loading = false;
          this.currentPassword = '';
        }
      });
    }else{

      if(this.user!.isClient){
        this.user!.Manufacturers = this.manufacturersFormShared.get("Manufacturers")?.get("SelectedManufacturersList")?.value;
      }
      if(this.user!.isManufacturer){
        this.user!.Clients = this.clientsFormShared.get("Clients")?.get("SelectedClientsList")?.value;
      }

      this.userSrv.saveChangesById(this.user!).subscribe({
        next: (res: User) => {
          this.user= null;
          console.log("OK");
          this.user = new User(res);


          this.themeSrv.deletePreviewTheme();

          if(this.user.Id == this.authSrv.currentUserValue?.UserId){
            if(this.user.Theme){
              this.themeSrv.update(this.user.Theme);
            }
          }

          //this.user = Object.assign({},res);
          this.originalUser = new User(Object.assign({},res));
          if(this.userInput == null){

            this.breadcrumbService.setBreadCrumbUrl('users/list', this.translate.instant('modules.users.management'));
            this.breadcrumbService.addBreadCrumb('users/' + this.IdUser! + '/detail', this.user!.Name??'' + ' ' + this.user!.FirstName??'' + ' ' + this.user!.LastName??'');
            this.breadcrumbService.addBreadCrumb('users/' + this.IdUser! + '/edit', this.translate.instant('modules.shared.edit'));
          }
        },
        error: (err: any) =>{
          if(err.status! == "MODAL"){
            this.utilsSrv.infoModal(err.msg!, undefined, err.Title);
          }else{
            this.utilsSrv.infoModal(this.translate.instant('modules.users.send-email-error') + '.', undefined, this.translate.instant('modules.shared.warning'));
          }
          console.log("ERROR")
          this.loading = false;
        },
        complete: () =>{
          this.loading = false;
        }
      });
    }
  }


  openPasswordModal(){

    let modal = new Modal();
    modal.Title = this.translate.instant('modules.shared.warning');
    modal.Body = this.translate.instant('modules.modals.enter-password-for-modify') + ":";
    modal.BtnAccept = this.translate.instant('modules.modals.continue');
    modal.ClassType = "warning";

    const dialog = this.dialog.open(
      InputTextModalComponent,
      {
      autoFocus : 'dialog',
      width: "auto",
      height: "auto",
      data: modal,
      closeOnNavigation: false,
      disableClose: true,
      panelClass: 'custom-modalbox',
      position: { top: '150px'}
    });

    dialog.afterClosed().subscribe(
      (result: string) => {

        console.log("Input: ", result);
        if(result != ''){
          this.currentPassword = result;
          this.saveChange();
        }
      }
    );
  }


  openSaveModal(){

    let modal = new Modal();
    modal.Title = this.translate.instant('modules.shared.warning');
    modal.Body = this.translate.instant('modules.modals.required-fields-missing');
    modal.BtnAccept = this.translate.instant('modules.shared.save')
    modal.BtnCancel = this.translate.instant('modules.shared.cancel');
    modal.ClassType = "warning";

    const dialog = this.dialog.open(
      ConfirmationModalComponent,
      {
      autoFocus : 'dialog',
      width: "auto",
      height: "auto",
      data: modal,
      closeOnNavigation: false,
      disableClose: true,
      panelClass: 'custom-modalbox',
      position: { top: '150px'}
    });

    dialog.afterClosed().subscribe(
      (result: boolean) => {
        if(result){
          this.saveChange();
        }
      }
    );
  }

}
