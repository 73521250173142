import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Role, User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EmailService } from 'src/app/services/email.service';
import { UsersService } from 'src/app/services/users.services';
import { UtilsService } from 'src/app/services/utils.service';
import { ListResult } from 'src/app/models/apiRequestObject';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  @Input() rolInput?: string;
  @Input() showButtonCancel: boolean = false;
  @Output() userOutput = new EventEmitter<User>();

  public role: string | null;
  public rolesList: Array<Role> | null = null;
  public selectedRole?: Role | null = null;

  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public authSrv : AuthenticationService,
    private emailSrv: EmailService,
    private usersSrv : UsersService,
    private utilsSrv : UtilsService,
    private route: ActivatedRoute,
    private router : Router) {


      this.role = this.route.snapshot.paramMap.get('role');

    }

    loading: boolean = false;
    public LoginError? : boolean;
    public newUser: User = new User({'IsCompany' : false, 'TypeDocument' : false, 'IsSeller' : false  });

    addForm: FormGroup = this.formBuilder.group({
    });

    addUserForm() {
      return this.formBuilder.group({
        DNICIF:  this.formControlDNICIF(),
        TypeDocument:  new FormControl(this.newUser.TypeDocument, [
          Validators.required
        ]),
        IsCompany: new FormControl(this.newUser.IsCompany, [
          Validators.required
        ]),
        Name: this.formControlName(),
        FirstName: this.formControlFirstName(),
        LastName: this.formControlLastName(),
        IsSeller: new FormControl(this.newUser.IsSeller, [
          Validators.required
        ]),

      });
    }


    get Name(){ return this.addForm.get("User")!.get("Name"); }
    get FirstName(){ return this.addForm.get("User")!.get("FirstName"); }
    get LastName(){ return this.addForm.get("User")!.get("LastName"); }
    get WorkerCode(){ return this.addForm.get("User")!.get("WorkerCode"); }
    get TypeDocument(){ return this.addForm.get("User")!.get("TypeDocument")}
    get DNICIF(){ return this.addForm.get("User")!.get("DNICIF")}
    get IsSeller(){ return this.addForm.get("User")!.get("IsSeller"); }





    addFabricantForm() {return this.formBuilder.group({
      DNICIF:  this.formControlDNICIF(),
      TypeDocument:  new FormControl(this.newUser.TypeDocument, [
        Validators.required
      ]),
      Name: this.formControlName(),

      });
    }

    addAdminForm() { return this.formBuilder.group({

      Name: this.formControlName(),
      FirstName: this.formControlFirstName(),
      LastName: this.formControlLastName(),
        WorkerCode: new FormControl(this.newUser.WorkerCode, [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(15)
        ])
      });
    }


    formControlName(){
      return new FormControl(this.newUser!.Name, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(25)
      ]);
    }
    formControlFirstName(){
      return new FormControl(this.newUser!.FirstName, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ]);
    }
    formControlLastName(){
      new FormControl(this.newUser!.LastName, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ])
    }

  formControlDNICIF(){
    return new FormControl(this.newUser!.DNICIF, [
      Validators.required,
      Validators.minLength(9),
      Validators.maxLength(15)
    ])
  }



  ngOnInit(): void {

    console.log("rolInput", this.rolInput);
    if(this.role == null && this.rolInput != null){
      this.role = this.rolInput
    }

    this.loading = true;
    this.rolesList = null;
    this.usersSrv.getRoles().subscribe({
    next: (res: ListResult) => {
      this.rolesList =  <Role[]> res.Result;


      console.log("role: " + this.role);
      if(this.role){

        this.selectedRole = this.rolesList.find(r => { return r.Tag! == this.role! });
        //this.selectedRole = this.rolesList!.find( (r:Role) => {  r.Tag! == this.role! });
      }else{
        console.log(this.rolesList);

        if(this.authSrv.isAdmin()){
          this.selectedRole = this.rolesList[0];
        }else if(this.authSrv.isManufacturer()){
          this.selectedRole = this.rolesList.find(r => { return r.Tag! == 'user' });
        }else if(this.authSrv.isClient()){
          this.rolesList = null;
          this.selectedRole = null;
          this.router.navigate(['home']);

        }
      }

      this.changeForm();




    },error: (err: any) =>{
      console.log("error getRoles");
    }, complete: () =>{
      this.loading = false;
    }
    });
  }

  changeRole(){
    this.changeForm();
  }


  changeCompany(){

    console.log("changeCompany " , this.newUser.IsCompany);
    if(this.newUser.IsCompany == true){

      console.log("validators null");
      this.addForm.get("User")!.get("FirstName")!.setValidators(null);
      this.addForm.get("User")!.get("LastName")!.setValidators(null);
      this.addForm.get("User")!.get("FirstName")!.updateValueAndValidity();
      this.addForm.get("User")!.get("LastName")!.updateValueAndValidity();
    }else{

      console.log("validators in");

      this.addForm.get("User")!.get("FirstName")!.setValidators([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(25)
      ]);
      this.addForm.get("User")!.get("LastName")!.setValidators([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ]);

      this.addForm.get("User")?.get("Name")!.updateValueAndValidity();
      this.addForm.get("User")?.get("FirstName")!.updateValueAndValidity();
      this.addForm.get("User")?.get("LastName")!.updateValueAndValidity();
    }
  }

  changeForm(){
    console.log('changeForm');
    this.addForm.removeControl('User');

    if(this.selectedRole!.Tag! == 'user'){
      this.addForm.addControl('User',this.addUserForm());
    }else if(this.selectedRole!.Tag! == 'admin'){
      this.addForm.addControl('User',this.addAdminForm());
    }else if(this.selectedRole!.Tag! == 'fabricant'){
      console.log('addFabricantForm')
      this.addForm.addControl('User',this.addFabricantForm());
    }
  }

  cancel() {

      this.dialog.getDialogById('UserAddQuickComponent')?.close();


  }

  onSubmit(){



    if(this.selectedRole!.Tag! == 'user'){

      this.newUser.WorkerCode = undefined;

    }else if(this.selectedRole!.Tag! == 'fabricant'){

      this.newUser.IsCompany = false;
      this.newUser.WorkerCode = undefined;
      this.newUser.FirstName = undefined;
      this.newUser.LastName = undefined;

    }else if(this.selectedRole!.Tag! == 'admin'){

      this.newUser.IsCompany = false;
    }else{
      return;
    }

    this.loading = true;



    console.log(this.addForm.value);

    if (! this.addForm!.valid){
      console.log("ko");
      console.log(this.Name?.errors);
      console.log(this.FirstName?.errors);
      console.log(this.LastName?.errors);
      this.loading = false;
      return ;
    }
    console.log("ok");

    this.newUser.IdRole = this.selectedRole?.Id;

    this.loading = true;

    this.usersSrv.create(this.newUser).subscribe({
      next: (resultUser: User) =>{
        console.log("OK createUser");

        if(this.rolInput){
          this.emitUser(resultUser)
        }else{
          this.router.navigate(['/users/' + resultUser.Id  + '/edit/1']);
        }

      },error: (err: any) =>{
        console.log("error createUser");
      }, complete: () =>{
        this.loading = false;
      }

    });
  }


  private emitUser(resultUser: User) {
    this.userOutput.emit(resultUser);
  }

}
