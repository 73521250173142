import { Role, User } from "./user";

export class DocManagerFolder {
    Files?:        DocManagerFile[];
    ChildFolders?: DocManagerFolder[];
    ParentFolder?: DocManagerFolder | null;
    Role?:          Role;
    Users?:         User[];
    Id?:            number;
    Name?:          string;
    InternalName?:  string;
    IsVisible?:     boolean;
    IsPublic?:      boolean;
    IdParent?:     number | null;
    IdRole?:        number | null;
    CreatedAt?:     Date;
    UpdatedAt?:     Date;
    Priority?:     number;
    IdFolder?:     number;

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);    
    }
    
}

export class DocManagerFile {
    Role?:         Role;
    Users?:        User[];
    DocManagerFolder?:       DocManagerFolder;
    Id?:           number;
    Name?:         string;
    InternalName?: string;
    Path?:         string;
    Priority?:     number;
    IsVisible?:    boolean;
    IsPublic?:     boolean;
    IdRole?:       number;
    IdFolder?:     number;
    CreatedAt?:    Date;
    UpdatedAt?:    Date;

    constructor(values: Object = {}) {
        (<any>Object).assign(this, values);    
    }
}
