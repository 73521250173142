import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Position, vDevice } from 'src/app/models/device';

@Component({
  selector: 'app-map-list-devices',
  templateUrl: './map-list-devices.component.html',
  styleUrls: ['./map-list-devices.component.scss']
})
export class MapListDevicesComponent implements OnInit {

  @Input() centralPoint?: [number,number];
  @Input() devices?: Array<vDevice>;
  @Input() positions?: Array<Position>;
  zoom: number = 7;

  constructor() { }

  ngOnInit(): void {

  }

  /*
  get zoom(): number {
    

    let GLOBE_WIDTH = 256; // a constant in Google's map projection
    let west = sw.lng();
    var east = ne.lng();
    var angle = east - west;
    if (angle < 0) {
      angle += 360;
    }
    var zoom = Math.round(Math.log(pixelWidth * 360 / angle / GLOBE_WIDTH) / Math.LN2);
  }*/

  get centralPointGet():  [number,number] {
    if(this.devices){
      return this.midpointCalculator(this.devices)!;
    }else{
      return this.centralPoint!;
    }
  }

    
  midpointCalculator(coordinates: vDevice[]): [number,number] | undefined {
    if (coordinates.length === 0) {
      return undefined;
    }

    // Initialize variables to store the sum of latitude and longitude values
    let sumLatitude = 0;
    let sumLongitude = 0;
    let countCoord = 0;

    // Calculate the sum of latitude and longitude values
    for (const coordinate of coordinates) {
      sumLatitude += coordinate.LatitudeLast??0;
      sumLongitude += coordinate.LongitudeLast??0;

      if(coordinate.LatitudeLast){
        countCoord += 1;
      }
    }

    // Calculate the average latitude and longitude
    const averageLatitude = sumLatitude / countCoord;
    const averageLongitude = sumLongitude / countCoord;

    // Return the midpoint as a new Coordinate object
    console.log('averageLatitude: ' + averageLatitude);
    console.log('averageLongitude: ' + averageLongitude);
    return [
      averageLatitude,
      averageLongitude
    ];
  }

}
