<app-loading-custom [loading]="loading"></app-loading-custom>

<ng-container *ngIf="device">
  <div class="page w-100">
    <div class="group col-12 col-xl-12 col-lg-12">

      <div class="my-4">
        <div class="row align-items-center">
          <div class="col-xl-8">
            <h1><b>{{ 'modules.devices.device-console' | translate }}</b> {{ device.Name }}</h1>
          </div>

        </div>
      </div>


      <app-block-dashboard>

        <ng-terminal #term></ng-terminal>


        <!--
        <section class="mt-2">
          <div class="text-right" style="flex-direction: row-reverse;">
              <button  mat-raised-button class="btn btn-primary" type="button" style="margin-right: 10px;" (click)="sendmessage('test')">SendMessage</button>
          </div>
          <div class="text-right" style="flex-direction: row-reverse;">
              <button  mat-raised-button class="btn btn-danger" type="button" style="margin-right: 10px;" (click)="test()">Test</button>
          </div>
        </section>-->
      </app-block-dashboard>
    </div>
  </div>
</ng-container>
