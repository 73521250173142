import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { ApiRequestObject } from '../models/apiRequestObject';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService  extends ApiService{

  constructor(private http: HttpClient, router: Router, authSrv: AuthenticationService) {
    super(router, authSrv);
  }



  saveChangesById(user:User){
    return this.http.patch<User>(`${environment.API_ENDPOINT}user/${user.Id}`,user).pipe(
      catchError(this.error)
   );
  }

  saveChanges(user:User){
    return this.http.patch<User>(`${environment.API_ENDPOINT}user`,user).pipe(
      catchError(this.error)
   );
  }

  // Cambiar idioma
  changeLanguage(user: User, language:string) {
    return this.http.patch<User>(`${environment.API_ENDPOINT}user/${language}/changeLanguage`, user).pipe(
      catchError(this.error)
    );
  }

  getUserById(id: number): Observable<User> {
    return this.http.get<User>(`${environment.API_ENDPOINT}user/${id}`).pipe(
      catchError(this.error)
    );
  }

  sendNewPasswordById(id: number) {
    return this.http.get<boolean>(`${environment.API_ENDPOINT}user/${id}/sendNewPassword`).pipe(
       catchError(this.error)
    );
  }

  sendWelcomePasswordById(id: number) {
    return this.http.get<boolean>(`${environment.API_ENDPOINT}user/${id}/sendWelcomePassword`).pipe(
       catchError(this.error)
    );
  }

  sendSimulateAlertById(id: number){
    return this.http.get<boolean>(`${environment.API_ENDPOINT}user/${id}/sendSimulateAlert`).pipe(
       catchError(this.error)
    );

  }

  checkAvailableEmail(email: string) {
    email = email.replace("/\./g", "");
    return this.http.get<boolean>(`${environment.API_ENDPOINT}login/check/mail?mail=${email}`).pipe(
       catchError(this.error)
    );
  }


  uploadLogoTheme(image: FormData, idUser: number){
    return this.http.post<any>(`${environment.API_ENDPOINT}user/${idUser}/theme/logoUpload`, image, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
       catchError(this.error)
      );

  }

  getManufacturesByIdClient(user:User){
    return this.http.get<User[]>(`${environment.API_ENDPOINT}user/${user.Id}/manufacturers`).pipe(
      catchError(this.error)
   );

  }

  getClientsByIdManufacturer(user:User){
    return this.http.get<User[]>(`${environment.API_ENDPOINT}user/${user.Id}/clients`).pipe(
      catchError(this.error)
   );

  }


}
