import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class RolesGuard implements CanActivate {

  constructor(
    private router: Router,
    public authenticationService: AuthenticationService){

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      let url: string = state.url;
      let auth : boolean = false;

      return this.checkRole(route, url);
    
  }

  

  checkRole(route: ActivatedRouteSnapshot, url: any): boolean {

    if(this.authenticationService.currentUserValue && this.authenticationService.currentUserValue!.Payload){
        
      const userRole = this.authenticationService.currentUserValue!.Payload!.role;
      
      if(route.data?.['roleOut'] && route.data?.['roleOut'].indexOf(userRole) !== -1){
          this.router.navigate(['/home']);
          return false;
      }else if (route.data?.['role'] && route.data?.['role'].indexOf(userRole) === -1) {
          this.router.navigate(['/home']);
          return false;
      }
      return true;
    }
    return false;
  }
  
}
