<app-breadcrumb></app-breadcrumb>


<div class="container-fluid pt-3 pl-0 pr-0 espace">

  <ng-container *ngIf="authSrv.isAdmin() == true">

    <ng-container *ngIf="authSrv.isAdmin() == true">

      <app-block-group-dashboard>
        <div class="col-12">
          <div class="my-4">
            <div class="row align-items-center">
              <div class="col-xxl-8">
                <h1><b>{{'modules.docManager.title-page' | translate}}</b></h1>
              </div>
              <div class="col-xxl-4 text-xl-end">
                <div class="btn btn-primary m-2" (click)="newFile()">
                    {{'modules.docManager.add-file' | translate}}
                </div> 
                <div class="btn btn-primary m-2" (click)="newFolder()">
                    {{'modules.docManager.add-folder' | translate}}
                </div> 
              </div>
            </div>
          </div>
        </div>
   
      </app-block-group-dashboard>
  
      </ng-container>

    </ng-container>

    <app-block-group-dashboard>

    <app-block-dashboard>
      <mat-tab-group mat-align-tabs="start" [selectedIndex]="page" (selectedIndexChange)="page = $event">
        <mat-tab>
            <ng-template mat-tab-label>
                {{'modules.docManager.title-menu' | translate}}
            </ng-template>
            <!-- <div>
              <app-add-folder-media></app-add-folder-media>
            </div> -->
            <div class="mt-2">
              
                <app-loading-custom [loading]="loading"></app-loading-custom>
                <app-list-media *ngIf="!loading" [listMedia]="listMedia" ></app-list-media>
            </div>
        </mat-tab>
      </mat-tab-group>
    </app-block-dashboard>

  </app-block-group-dashboard>



</div>


<app-footer></app-footer>
