<div id="side-nav-shared" class="sidenav sidebar-wrapper">

    <div class="sidebar-content ">

      <div class="sidebar-menu">
        <div class="user-info">
          <div class="name color-text overflow">{{ nameUser }}</div>
          <div class="rol color-text overflow">{{ mail }}</div>
          <div *ngIf="isAdmin" class="rol color-text ">ADMIN</div>
        </div>
        <div class="divider"></div>

        <ul>
          <li class="header-menu">
            <span>{{'modules.shared.general' | translate}}</span>
          </li>

          <li id="menu-home" *ngIf="authSrv.isClient() == false" class="sidebar-dropdown" (mouseover)="hoverCss('menu-home')" (mouseleave)="leaveCss('menu-home')">
            <a [routerLink]="['/home']" routerLinkActive="active" [ngClass]="(router.url.startsWith('/home'))?'active':''" [routerLinkActiveOptions]="{exact: true}" >
              <i class="fa fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </a>
          </li>

          <li   class="sidebar-dropdown" id="menu-device"  (mouseover)="hoverCss('menu-device')" (mouseleave)="leaveCss('menu-device')">
            <a [routerLink]="['/devices/list']"  routerLinkActive="active" [ngClass]="(router.url.startsWith('/devices'))?'active':''" [routerLinkActiveOptions]="{exact: false}" >
              <i class="fas fa-route"></i>
              <span>{{'modules.devices.devices' | translate}}</span>
            </a>
          </li>

          <li  *ngIf="showTrips"   class="sidebar-dropdown" id="menu-trips"  (mouseover)="hoverCss('menu-trips')" (mouseleave)="leaveCss('menu-trips')">
            <a [routerLink]="['/trips']"  routerLinkActive="active" [ngClass]="(router.url.startsWith('/trips'))?'active':''" [routerLinkActiveOptions]="{exact: false}" >
              <i class="fas fa-map"></i>
              <span>{{'modules.shared.trips' | translate}}</span>
            </a>
          </li>

          <li  class="sidebar-dropdown" *ngIf="authSrv.isClient() == false" id="menu-user"  (mouseover)="hoverCss('menu-user')" (mouseleave)="leaveCss('menu-user')">
            <a [routerLink]="['/users/list']" routerLinkActive="active" [ngClass]="(router.url.startsWith('/users'))?'active':''">
              <i class="fas fa-users"></i>
              <span>{{'modules.users.users' | translate}}</span>
            </a>
          </li>

          <li  class="sidebar-dropdown"  id="menu-alarm"  (mouseover)="hoverCss('menu-alarm')" (mouseleave)="leaveCss('menu-user')">
            <a [routerLink]="['/alarms/list']" routerLinkActive="active" [ngClass]="(router.url.startsWith('/alarms'))?'active':''">
              <i class="fas fa-clock"></i>
              <span>{{'modules.alarms.alarms' | translate}}</span>
            </a>
          </li>

          <li  class="sidebar-dropdown" id="menu-alert"  (mouseover)="hoverCss('menu-alert')" (mouseleave)="leaveCss('menu-user')">
            <a [routerLink]="['/notifications/list']" routerLinkActive="active" [ngClass]="(router.url.startsWith('/notifications'))?'active':''">
              <i class="fas fa-bell"></i>
              <span>{{'modules.notifications.notifications' | translate}}</span>
            </a>
          </li>

          <li  class="sidebar-dropdown" id="menu-account"  (mouseover)="hoverCss('menu-account')" (mouseleave)="leaveCss('menu-account')">
            <a [routerLink]="['/account']" routerLinkActive="active" [ngClass]="(router.url.startsWith('/account'))?'active':''">
              <i class="fas fa-user-shield"></i>
              <span>{{'modules.tables.account' | translate}}</span>
            </a>
          </li>

          
          <li  *ngIf="showDocManager"   class="sidebar-dropdown" id="menu-docManager"  (mouseover)="hoverCss('menu-docManager')" (mouseleave)="leaveCss('menu-docManager')">
            <a [routerLink]="['/files']"  routerLinkActive="active" [ngClass]="(router.url.startsWith('/files'))?'active':''" [routerLinkActiveOptions]="{exact: false}" >
              <i class="fas fa-folder-tree"></i>
              <span>{{'modules.docManager.title-menu' | translate}}</span>
            </a>
          </li>
         <!-- <li class="sidebar-dropdown">
            <a routerLink="/viaje2/1">
              <i class="fa fa-tachometer-alt"></i>
              <span>Test</span>
            </a>
          </li> -->

          <li class="d-md-inline d-lg-none" (click)="logout()">
            <a href="#" >
              <i class="fas fa-sign-out-alt"></i>
              <span>{{'modules.shared.exit' | translate}}</span>
            </a>
          </li>


  <!--

          <li class="sidebar-dropdown">
            <a href="#">
              <i class="fa fa-shopping-cart"></i>
              <span>E-commerce</span>
              <span class="badge badge-pill badge-danger">3</span>
            </a>
            <div class="sidebar-submenu">
              <ul>
                <li>
                  <a href="#">Products

                  </a>
                </li>
                <li>
                  <a href="#">Orders</a>
                </li>
                <li>
                  <a href="#">Credit cart</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="sidebar-dropdown">
            <a href="#">
              <i class="far fa-gem"></i>
              <span>Components</span>
            </a>
            <div class="sidebar-submenu">
              <ul>
                <li>
                  <a href="#">{{'modules.shared.general' | translate}}</a>
                </li>
                <li>
                  <a href="#">Panels</a>
                </li>
                <li>
                  <a href="#">Tables</a>
                </li>
                <li>
                  <a href="#">Icons</a>
                </li>
                <li>
                  <a href="#">Forms</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="sidebar-dropdown">
            <a href="#">
              <i class="fa fa-chart-line"></i>
              <span>Charts</span>
            </a>
            <div class="sidebar-submenu">
              <ul>
                <li>
                  <a href="#">Pie chart</a>
                </li>
                <li>
                  <a href="#">Line chart</a>
                </li>
                <li>
                  <a href="#">Bar chart</a>
                </li>
                <li>
                  <a href="#">Histogram</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="sidebar-dropdown">
            <a href="#">
              <i class="fa fa-globe"></i>
              <span>Maps</span>
            </a>
            <div class="sidebar-submenu">
              <ul>
                <li>
                  <a href="#">Google maps</a>
                </li>
                <li>
                  <a href="#">Open street map</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="header-menu">
            <span>Extra</span>
          </li>
          <li>
            <a href="#">
              <i class="fa fa-book"></i>
              <span>Documentation</span>
              <span class="badge badge-pill badge-primary">Beta</span>
            </a>
          </li>
          <li>
            <a href="#">
              <i class="fa fa-calendar"></i>
              <span>Calendar</span>
            </a>
          </li>
          <li>
            <a href="#">
              <i class="fa fa-folder"></i>
              <span>Examples</span>
            </a>
          </li> -->
        </ul>
      </div>
    </div>

    <!--
    <ul  class="nav list-unstyled">
      <li class="active">
        <a routerLink="/home" ><i class="fas fa-tachometer-alt"></i> Dashboard</a>
      </li>
      <li class="d-md-inline d-lg-none"  (click)="logout()">
        <a href=""><i class="fas fa-sign-out-alt"></i> {{'modules.shared.exit' | translate}} </a>

      </li>
    </ul> -->

    <div class="sidebar-content-footer">
      <div class="sidebar-footer">
        <a  id="menu-bottom-1" [routerLink]="['/notifications/list']" routerLinkActive="active"  (mouseover)="hoverBottomCss('menu-bottom-1')" (mouseleave)="leaveBottomCss('menu-bottom-1')">
          <i class="fa fa-bell"></i>
        </a>

        <a  id="menu-bottom-3" [routerLink]="['/account']" routerLinkActive="active" (mouseover)="hoverBottomCss('menu-bottom-3')" (mouseleave)="leaveBottomCss('menu-bottom-3')">
          <i class="fa fa-cog"></i>
        </a>
        <div (click)="logout()" id="menu-bottom-4"  (mouseover)="hoverBottomCss('menu-bottom-4')" (mouseleave)="leaveBottomCss('menu-bottom-4')">
          <i class="fa fa-power-off"></i>
        </div>
      </div>
    </div>

  </div>
