import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hoursMinutes'
})
export class CustomHoursMinutesPipe implements PipeTransform {

  transform(value: number | string, locale?: string): string {

    let val = value!.toString().split(".");
    let aux: number = 0;

    if(val.length > 0 && val[1]){
      aux = +val[1];

      //console.log(val[1]);
      //console.log(aux);

      if(val[1].length > 2){
        aux = aux / Math.pow(10, val[1].length - 2);
      }else if(val[1].length  == 1){
        aux = aux * 10;
      }

      // Parche per que els decimals periodics passin al següent minut
      if(val[1].length == 4){
        if(val[1][1] == val[1][2] && val[1][2] == val[1][3]){
          aux = aux + 0.01;
        }
      }


      if( Math.trunc((60/100)*(aux)) < 10){
          return this.ponerPuntos(val[0])+":0" + Math.trunc((60/100)*(aux)).toString();

      }else{
          return this.ponerPuntos(val[0])+":" + Math.trunc((60/100)*(aux)).toString();
      }

    }

    if(val[0]){
      return this.ponerPuntos(val[0])+":00";
    }

    return "00:00";


  }

  ponerPuntos(n: string){
    let newN = "";
    let isNegative: boolean = false;

    if(n[0] == "-"){
      isNegative = true;
      n = n.substring(1, n.length)
    }

    if(n.toString().length > 3){

      let iAux = 0;
      for(let i= n.toString().length; i > 0; i--){

        iAux++;
        newN = n.toString()[i-1] + newN;

        if(iAux == 3){
          newN = "." + newN;
          iAux = 0;
        }
        console.log(newN);

      }

    }


    if(n[0] == "."){
      n = n.substring(1, n.length)
    }
    if(newN[0] == "."){
      newN = newN.substring(1, newN.length)
    }

    if(isNegative){
      return newN==""?"-" + n: "-" + newN;
    }

    return newN==""?n:newN;
  }

}
