import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UserAddQuickComponent } from 'src/app/modals/user-add-quick/user-add-quick.component';
import { ListResult } from 'src/app/models/apiRequestObject';
import { DeviceModel, vDevice, vDeviceModel } from 'src/app/models/device';
import { Modal } from 'src/app/models/modal';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DevicesService } from 'src/app/services/devices.service';
import { EmailService } from 'src/app/services/email.service';
import { UsersService } from 'src/app/services/users.services';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-device-model',
  templateUrl: './edit-device-model.component.html',
  styleUrls: ['./edit-device-model.component.scss']
})
export class EditDeviceModelComponent implements OnInit {
  @Input() editQuick: boolean = false;
  @Input() showButtonCancel: boolean = false;
  @Input() userInput: User | null = null;
  @Input() rolInput?: string;
  @Input() autoClose: boolean = true;
  @Input() editDeviceModel: vDeviceModel = new vDeviceModel({ });

  @Output() deviceModelOutput = new EventEmitter<vDeviceModel>();



  public user: string | null;
  loading: boolean = true;
  public LoginError?: boolean;

  public manufacturerList: Array<User> | null= null;

  modelForm: FormGroup = this.formBuilder.group({
  });

  dropdownSettingsFabricante : IDropdownSettings= {};

  get isClient(){
    if(this.userInput != null){
      if(this.userInput.isClient){
        return true;
      }
    }
    return false;
  }

  get isManufacturer(){
    if(this.userInput != null){
      if(this.userInput.isManufacturer){
        return true;
      }
    }
    return false;
  }
  get nameDeviceModelMain(): string{
    return environment.NameDeviceModelMain;
  }

  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public authSrv: AuthenticationService,
    private emailSrv: EmailService,
    private usersSrv: UsersService,
    private deviceSrv: DevicesService,
    private utilsSrv: UtilsService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService) {
    this.user = this.route.snapshot.paramMap.get('user');
  }


  ngOnInit(): void {
    this.loading = true;

    console.log(this.editDeviceModel);
    console.log(this.userInput);

    this.dropdownSettingsFabricante = {
      singleSelection: true,
      idField: 'Id',
      textField: 'Name',
      selectAllText: this.translate.instant('modules.shared.select-all'),
      unSelectAllText: this.translate.instant('modules.shared.unselect-all'),
      allowSearchFilter: true,
      //noFilteredDataAvailablePlaceholderText: "No hay coincidencias",
      noDataAvailablePlaceholderText: this.translate.instant('modules.shared.no-accounts'),
      searchPlaceholderText: this.translate.instant('modules.shared.search'),
      clearSearchFilter: true,
      closeDropDownOnSelection: this.autoClose
    };




    //this.loadDevices();
    this.getManufacturers();

  }

  getManufacturers(){
    this.loading = true;
    this.manufacturerList = null;
    this.usersSrv.getManufacturers(0, 10000, 'Name' , 'ASC' ).subscribe({
      next: (res: ListResult) => {

        this.manufacturerList = <User[]>res.Result;
        console.log(this.manufacturerList);
        this.modelForm.addControl('Model',this.addModelDeviceForm());

        if(this.isManufacturer){
          this.editDeviceModel.IdManufacturer = this.userInput!.Id;
          this.editDeviceModel.Manufacturer = [ this.userInput! ];
        }

      },error: (err: any) =>{
        console.log("error getClients");
      }, complete: () =>{
        this.loading = false;

      }
    });

  }

  get Name(){ return this.modelForm.get("Model")!.get("Name"); }
  get Manufacturer(){ return this.modelForm.get("Model")!.get("Manufacturer"); }

  addModelDeviceForm() {
    return this.formBuilder.group({
      Name: new FormControl(this.editDeviceModel!.Name, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(15)
      ]),
      Manufacturer: new FormControl(this.editDeviceModel!.Manufacturer, [
      ])
    });

  }

  get getManufacturer() {
    return this.manufacturerList!.reduce((acc:any , curr: vDeviceModel) => {
      acc[curr.Id!] = curr;
      return acc??'';
    }, {});
  }

  onSubmit() {


    if (! this.modelForm!.valid){
      console.log("ko");
      return ;
    }
    console.log("ok");
    console.log(this.editDeviceModel);

    let auxDeviceModel = new DeviceModel(<DeviceModel>this.editDeviceModel);

    this.loading = true;

    let NameManufacturerAux = "";
    if(this.authSrv.isAdmin()){
      this.editDeviceModel.IdManufacturer = this.editDeviceModel!.Manufacturer[0]!.Id;
      NameManufacturerAux = this.editDeviceModel!.Manufacturer[0]!.Name??'';
      auxDeviceModel.IdUser = this.editDeviceModel!.Manufacturer[0]!.Id;
    }


    console.log(NameManufacturerAux);

    console.log(this.editDeviceModel);


    this.deviceSrv.changeModel(auxDeviceModel).subscribe({
      next: (result: DeviceModel) =>{
        console.log("OK edit DeviceModel");
        console.log(NameManufacturerAux);

        if(this.rolInput){
          //resultUser.NameManufacturer = NameManufacturerAux;

          this.emitDeviceModel(result)
        }else{
          this.router.navigate(['/devices/list']);
        }

      },error: (err: any) =>{
        console.log("error createUser");
      }, complete: () =>{
        this.loading = false;
      }

    });
  }

  modalFabricante(){
    console.log("modalFabricante");
  }

  newManufacturer(){
    const dialogRef = this.dialog.open(
      UserAddQuickComponent,
      {
        id: 'UserAddQuickComponent',
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        height: 'auto',
        data: new Modal({ 'Data' : 'fabricant' }),
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe( {
      next: (result?: vDeviceModel) => {
      if(result){
        console.log(`Dialog result: ${result.Name!}`);
        let newUser: vDeviceModel = Object.assign({},result);

        this.getManufacturers();
        this.editDeviceModel!.Manufacturer = [{ 'Id': newUser.Id, 'Name': newUser.Name }];
      }else{
        console.log("ERRORR 1");

      }
    },
    error: (result?: any) => {
      if(result && result.modal){

      }
      console.log("ERRORR 2");
    }, complete: () => {
      this.loading = false;
    }
    });

  }

  private emitDeviceModel(resultUser: vDeviceModel) {
    this.deviceModelOutput.emit(resultUser);
  }



  cancel(){
    this.dialog.getDialogById('DeviceModelEditQuickComponent')?.close();

  }


}
