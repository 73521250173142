
<app-breadcrumb></app-breadcrumb>

<div class="container-fluid pt-3 pl-0 pr-0 espace">

  <app-loading-custom [loading]="loading"></app-loading-custom>


  <app-block-group-dashboard *ngIf="loading == false">

    <div class="my-4">
      <div class="row align-items-center">
        <div class="col-8">
          <h1><b>{{title}}</b></h1>
        </div>
        <div class="col-4 text-end" *ngIf="showDeleteButton==true || (authSrv.isAdmin() && trip && trip.Id != 0)">
          <app-generic-button [hasModal]="false" txtBtn="{{'modules.modals.join' | translate}}" classType="warning"
          (confirmDelete)="joinTrip()" ></app-generic-button>
          <app-generic-button [hasModal]="false" txtBtn="{{'modules.modals.split' | translate}}" classType="warning"
          (confirmDelete)="splitTrip()" ></app-generic-button>
          <app-generic-button title="{{'modules.shared.warning' | translate}}" txt="{{'modules.modals.delete-current-trip' | translate}}" classType="danger"
          (confirmDelete)="deleteTrip()" ></app-generic-button>
        </div>
   

      </div>
    </div>



    <app-block-dashboard>

      <app-detail-trip *ngIf="positions.length > 0"  [trip]="trip" [centralPoint]="[positions[0].TrackPosition!.LatitudeWGS84!, positions[0].TrackPosition!.LongitudeWGS84!]" [positions]="positions"></app-detail-trip>
      <div *ngIf="positions.length == 0">
        {{'modules.trips.no-traces-trip' | translate}}.
      </div>
    </app-block-dashboard>

  </app-block-group-dashboard>


</div>


<app-footer></app-footer>
