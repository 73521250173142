import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListMediaPageComponent } from './pages/list-media-page/list-media-page.component';


const routes: Routes = [
  {
    path: '',
    children:  [
      { path: '', component: ListMediaPageComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocManagerRoutingModule { }
