import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[inputOnlyNumber]'
})
export class OnlyNumberDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initalValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');

    if(this.el.nativeElement.value == ""){
      this.el.nativeElement.value = 1;
    }
    if(this.el.nativeElement.value.startsWith('0') && this.el.nativeElement.value.length > 1){
      this.el.nativeElement.value = this.el.nativeElement.value.substring(1);

    }

    if (initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }


}
