import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ListResult } from 'src/app/models/apiRequestObject';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';
import { UsersService } from 'src/app/services/users.services';

@Component({
  selector: 'app-edit-user-clients',
  templateUrl: './edit-user-clients.component.html',
  styleUrls: ['./edit-user-clients.component.scss']
})
export class EditUserClientsComponent implements OnInit {

  @Input() user?: User;
  @Input() autoCloseModels: boolean = false;
  loading:boolean = true;

  clientsForm: FormGroup = this.formBuilder.group({
  });

  public clientsList: Array<User> = [];
  public selectedClientsList: Array<User> = [];
  loadClients: boolean = false;
  loadClientsSelecteds: boolean = false;

  dropdownClients: IDropdownSettings= {};

  @Output() clientsFormShared = new EventEmitter<FormGroup>();

  private emitFormGeneral() {
    this.clientsFormShared.emit(this.clientsForm);
  }


  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private usersSrv: UsersService,
    private userSrv: UserService,
    public authSrv : AuthenticationService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.loading = true;

    this.dropdownClients = {
      singleSelection: false,
      closeDropDownOnSelection: this.autoCloseModels,
      showSelectedItemsAtTop: true,
      idField: 'Id',
      textField: 'Name',
      selectAllText: this.translate.instant('modules.shared.select-all'),
      unSelectAllText: this.translate.instant('modules.shared.unselect-all'),
      allowSearchFilter: true,
      //noFilteredDataAvailablePlaceholderText: "No hay coincidencias",
      noDataAvailablePlaceholderText: this.translate.instant('modules.shared.no-users'),
      searchPlaceholderText: this.translate.instant('modules.shared.search-user'),
      clearSearchFilter: true,
    };





    this.clientsForm.addControl('Clients', this.formBuilder.group({
      SelectedClientsList: new FormControl(this.selectedClientsList, [
    ])}));


    this.getClientsUser();
    this.getDefaultClients();
  }

  get SelectedClientsList(){ return this.clientsForm.get("Clients")!.get("SelectedClientsList"); }

  getDefaultClients(){
    this.loading = true;
    this.selectedClientsList = [];
    this.userSrv.getClientsByIdManufacturer(this.user!).subscribe({
      next: (res: User[] ) => {

        this.selectedClientsList = <User[]>res;
        console.log(this.selectedClientsList);

      },error: (err: any) =>{
        console.log("error getClients");
        this.loadClientsSelecteds = true;
        this.loadComplete();
      }, complete: () =>{
        this.loadClientsSelecteds = true;
        this.loadComplete();

      }
    });
  }
  getClientsUser(){
    this.loading = true;
    this.clientsList = [];
    this.usersSrv.getClients(0, 10000, 'Name' , 'ASC' ).subscribe({
      next: (res: ListResult) => {

        this.clientsList = <User[]>res.Result;
        console.log(this.clientsList);

      },error: (err: any) =>{
        console.log("error getClients");
        this.loadClients = true;
        this.loadComplete();
      }, complete: () =>{
        this.loadClients = true;
        this.loadComplete();

      }
    });
  }

  loadComplete(){
    if(this.loadClients == true && this.loadClientsSelecteds == true){
      this.loading = false;
      this.emitFormGeneral();
    }
  }

}
