<app-loading-custom [loading]="loading"></app-loading-custom>

<div class="form-group" *ngIf="deviceModelList">
    <label for="Client" class="text-right-label" *ngIf="nameField != ''">{{ nameField }}</label>

    <ng-multiselect-dropdown
      id="Client"
      [(ngModel)]="deviceModelListSelected"
      [placeholder]="placeholder"
      [settings]="dropdownSettingsUser"
      [data]="deviceModelList"
      (onSelect)="onItemSelect($event)"
      >

      <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
        <div style="padding:1px 10px;margin-right:10px;right:2px;top:-2px;cursor: pointer !important;">
          <label *ngIf="userInput?.isAdmin" style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
            {{ getModel[id].Name??'' }} | {{ getModel[id].NameManufacturer??'' }}
          </label>
          <label *ngIf="userInput?.isManufacturer" style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
            {{ getModel[id].Name??'' }}
          </label>
        </div>
      </ng-template>

      <ng-template #optionSelectedTemplate let-option="option" let-id="id">
        <div *ngIf="userInput?.isAdmin">
            {{ getModel[id].Name??'' }} | {{ getModel[id].NameManufacturer??'' }}
        </div>
        <div *ngIf="userInput?.isManufacturer">
            {{ getModel[id].Name??'' }}
        </div>
      </ng-template>
    </ng-multiselect-dropdown>

  </div>
