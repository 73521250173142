/**
 * Classe para controlar la facil implementación de Datatable
 * @param {number} columnInitial - Posicion columna ordenación inicial
 * @param {string} direciotonInitial - Dirección ordenación inicial { 'asc' | 'desc' }
 * @param {Array<DatatableColumn>} headerTable - Array con la defininicón de las columnas
*/
export class DatatableConfig {
    columnInitial: number = 0;
    direciotonInitial: string = "desc";
    headerTable: Array<DatatableColumn> = [];


    /**
     * Devuelve el orden de las columnas esperado por el componente a partir del array de columnas
     * @return {(string | number)[][]} Devuelve array con orden
     */
    get order(): (string | number)[][]{

        let col: number = this.columnInitial;
        /*let ord : string;
        this.headerTable.forEach( (element) =>{
            if(element.column == this.columnInitial){
                if(element.order){
                    ord = "asc";
                }else{
                    ord = "desc";
                }
            }
        });*/

        return [ [ col, this.direciotonInitial ]];
    }

    /**
     * Devuelve la definición de las columnas esperada por el componente a partir del array de columnas
     * @return {Array<any>} Devuelve array con la definición de las columnas
     */
    get columnDefs(): Array<any>{

        let aux: Array<number> = []
        let aux2: Array<number> = []
        let orderableFalse: Array<number> =[];
        let hidden: Array<number> =[];
        this.headerTable.forEach( (element) =>{
            if(element.visible){
                if(element.tag != ""){
                    if(element.order == "asc"){
                        aux.push(element.column!)
                    }else{
                        aux2.push(element.column!)
                    }
                }else{
                    orderableFalse.push(element.column!)
                }
            }else{
                hidden.push(element.column!)
            }
        });

        return [
            { visible: false, searchable: false, targets: [...hidden]},
            { orderable: false, targets: [...orderableFalse]},
            /*{ orderSequence: [ "asc" , "desc" ], targets: [...aux]},*/
            { orderSequence: [ "desc", "asc" ], targets: [...aux2]}
        ];
    }


    /**
     * Cambia la columna principal de ordenación mediante el Nombre de esta
     * @param {string} name - Nombre de la columna
     * @example
     * changeOrderByTag('Nombre')
     */
    changeOrderByName(name: string){
        this.headerTable.forEach( (element) =>{
            if(element.name == name){
                this.columnInitial = element.column!;
            }
        });
    }
    /**
     * Cambia la columna principal de ordenación mediante el Tag de esta
     * @param {string} tag - Tag de la columna
     * @example
     * changeOrderByTag('Name')
     */
    changeOrderByTag(tag: string){
        this.headerTable.forEach( (element) =>{
            if(element.tag == tag){
                this.columnInitial = element.column!;
            }
        });
    }


    /**
     * Cambia la direccion inicial de la columna inicial por la contraria actual
     */
    changeOrder(){
        this.direciotonInitial = (this.direciotonInitial=="desc")?"asc":"desc";
    }

    /**
     * Establece la direccion inicial en ascendente
     */
    orderAsc(){
        this.direciotonInitial = "asc";
    }

    /**
     * Establece la direccion inicial en descendente
     */
    orderDesc(){
        this.direciotonInitial = "desc";
    }

    /**
     * Devuelve la configuración de una columna a partir de su posición
     * @param {number} column - Posición columna
     * @return {DatatableColumn | null} Return configuración de columna
     * @example
     * getColumnById(0)
     */
    getColumnById(id: number): DatatableColumn | null{

        let res: DatatableColumn | null = null;
        this.headerTable.forEach( (element) =>{
            if(element.column == id){
                res = element;
            }
        });
        return res;
    }


    /**
     * Oculta una columna para que no se vea en la vista a partir del nombre
     * @param {number} name - Nombre columna
     * @example
     * hideColumnByName('Nombre')
     */
    hideColumnByName(name: string){
        this.headerTable.forEach( (element) =>{
            if(element.name == name){
                element.visible = false;
            }
        });
    }

    /**
     * Oculta una columna para que no se vea en la vista a partir del tag
     * @param {number} tag - Tag columna
     * @example
     * hideColumnByName('Name')
     */
    hideColumnByTag(tag: string){
        this.headerTable.forEach( (element) =>{
            if(element.tag == tag){
                element.visible = false;
            }
        });
    }

    /**
     * Muestra el texto del footer izquierdo
     * @param {any} settings - Configuración datatable
     * @param {number} start - Registro inicial
     * @param {number} end - Registro final
     * @param {number} max - Número de registros a mostrar
     * @param {number} total - Total de registros
     * @param {any} pre - Pre datatable
     */
    DatatablesFooterShowText(settings: any, start:number, end:number, max:number, total:number, pre: any ): string{
        let lang = localStorage.getItem("lang")

        if(max==0){
            return "";
        }
        if(max==1){
            return lang == 'es' ? "Mostrando el único registro." : "Showing the only record.";        }

        if((end - start + 1) == max){
            return lang == 'es' ? "Mostrando todos ("+end+") los registros." : "Showing all ("+end+") records.";
        }

        return  lang == 'es' ? "Mostrando registros del " + start + " al " + end +" de un total de " + total + "." :  "Showing records from " + start + " to " + end +" out of a total of " + total + ".";
    }

}

/**
 * Classe para controlar la facil implementación de las columnas del Datatable
 * @param {number} column - Posición columna
 * @param {string} name - Nombre que se visualizara en la columna
 * @param {string} tag - Campo que hara match con el atributo de la base de datos
 * @param {string} order - Dirección ordenación inicial { 'asc' | 'desc' }
 * @param {boolean} visible - Flag para mostrar o no la columna
*/
export class DatatableColumn {
    column?: number;
    name?: string;
    tag?:string;
    order: string = "desc";
    visible:boolean = true;

    // Puede usarse un objecto o varios atributos para construirse
    constructor(...args: any[]) {

        if (args.length === 1) {
            (<any>Object).assign(this, args);
        }else if(args.length === 4){
            this.column = args[0];
            this.name = args[1];
            this.tag = args[2];
            this.order = args[3];
        }
    }


    /**
     * Cambia la direccion inicial de la columna por la contraria actual
     */
    changeOrder(){
        this.order = (this.order=="desc")?"asc":"desc";
    }

    /**
     * Establece la direccion inicial en ascendente
     */
    orderAsc(){
        this.order = "asc";
    }

    /**
     * Establece la direccion inicial en descendente
     */
    OrderDesc(){
        this.order = "desc";
    }

}


