

<div *ngIf="trip">
    <!-- <h4 class="mb-3">{{'modules.trips.info' | translate}}</h4> --> 
    <div class="row g-0 row-cols-1 row-cols-md-2 row-cols-lg-3 text-center" >
      
            <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
              <div class="small text-black-50">{{'modules.trips.start-date' | translate}}</div>
              <div class="valor-1">{{ trip!.Device!.Informations![0].TrackPosition!.Date??'-' | date: "dd/MM/yyyy ' - ' HH:mm"   }}</div>
            </div>
            <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
              <div class="small text-black-50">{{'modules.trips.end-date' | translate}}</div>
              <div class="valor-1">{{ trip!.Device!.Informations![trip!.Device!.Informations!.length - 1].TrackPosition!.Date??'-'  | date: "dd/MM/yyyy ' - ' HH:mm"  }}</div>
            </div>
            <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
              <div class="small text-black-50">{{'modules.trips.num-records' | translate}}</div>
              <div class="valor-1">{{ trip.DeviceTripTrackInformations!.length   }}</div>
            </div>

            <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
              <div class="small text-black-50">{{'modules.trips.num-alarms' | translate}}</div>
              <div class="valor-1">{{ trip.AlarmCount??0  }}</div>
            </div>
            <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
              <div class="small text-black-50">{{'modules.trips.avg-voltage' | translate}}</div>
              <div class="valor-1">{{ trip.VoltageValue??'-'   }}</div>
            </div>
            <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
              <div class="small text-black-50">{{'modules.trips.operator' | translate}}</div>
              <div class="valor-1">{{ trip.MobileOperator??'-'   }}</div>
            </div>
    </div>
    <ng-container *ngFor="let sensor of availableSensors; index as i">
      <div class="row g-0 row-cols-1 row-cols-md-2 row-cols-lg-4 text-center" >
        <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
          <div class="small text-black-50">{{'modules.tables.value' | translate}}</div>
          <div class="valor-1" [attr.data-sensor]="sensor">{{ nameSensorsTranslations.get(mapNameSensors!.get(sensor!)!)! | translate }} </div>
          <!-- - {{ mapNameSensors!.get(sensor!) }} -->
        </div>

        <ng-container *ngIf="getSensorAccomulative.includes(sensor)">

          <ng-container *ngIf="sensor == 'WorkingHours'">
            <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
              <div class="small text-black-50">{{'modules.trips.start' | translate}}</div>
              <div class="valor-1">{{ utilSrv.calcularHorasYMinutos(tripSrv.getDiff(positions!, sensor)![0]).horas }}h. {{ utilSrv.calcularHorasYMinutos(tripSrv.getDiff(positions!, sensor)![0]).minutos }}min.</div>
            </div>
            <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
              <div class="small text-black-50">{{'modules.trips.end' | translate}}</div>
              <div class="valor-1">{{ utilSrv.calcularHorasYMinutos(tripSrv.getDiff(positions!, sensor)![1]).horas }}h. {{ utilSrv.calcularHorasYMinutos(tripSrv.getDiff(positions!, sensor)![1]).minutos }}min.</div>
            </div>
            <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
              <div class="small text-black-50">{{'modules.trips.total-frame' | translate}}</div>
              <div class="valor-1">{{ utilSrv.calcularHorasYMinutos(tripSrv.getDiff(positions!, sensor)![2]).horas }}h. {{ utilSrv.calcularHorasYMinutos(tripSrv.getDiff(positions!, sensor)![2]).minutos }}min.</div>
            </div>

          </ng-container>
          <ng-container *ngIf="sensor != 'WorkingHours'">
            <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
              <div class="small text-black-50">{{'modules.trips.start' | translate}}</div>
              <div class="valor-1">{{ tripSrv.getDiff(positions!, sensor)![0] | number: '1.0-2'  }}</div>
            </div>
            <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
              <div class="small text-black-50">{{'modules.trips.end' | translate}}</div>
              <div class="valor-1">{{ tripSrv.getDiff(positions!, sensor)![1] | number: '1.0-2'  }}</div>
            </div>
            <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
              <div class="small text-black-50">{{'modules.trips.total-frame' | translate}}</div>
              <div class="valor-1">{{ tripSrv.getDiff(positions!, sensor)![2] | number: '1.0-2'  }}</div>
            </div>

          </ng-container>

        </ng-container>
        <ng-container *ngIf="!getSensorAccomulative.includes(sensor)">
          <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
            <div class="small text-black-50">{{'modules.shared.min' | translate}}</div>
            <div class="valor-1">{{ tripSrv.getAVG(positions!, sensor)![0] | number: '1.0-2'  }}</div>
          </div>
          <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
            <div class="small text-black-50">{{'modules.shared.max' | translate}}</div>
            <div class="valor-1">{{ tripSrv.getAVG(positions!, sensor)![1] | number: '1.0-2'  }}</div>
          </div>
          <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
            <div class="small text-black-50">{{'modules.shared.average' | translate}}</div>
            <div class="valor-1">{{ tripSrv.getAVG(positions!, sensor)![2] | number: '1.0-2' }}</div>
          </div>

        </ng-container>
        
      </div>

    </ng-container>
    <!-- -
    <div class="row g-0 row-cols-1 row-cols-md-2 row-cols-lg-4 text-center" >
      <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
        <div class="small text-black-50">Valor</div>
        <div class="valor-1">Horas</div>
      </div>
      <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
        <div class="small text-black-50">Inicio</div>
        <div class="valor-1">10h 0m</div>
      </div>
      <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
        <div class="small text-black-50">Fin</div>
        <div class="valor-1">30h 0m</div>
      </div>
      <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
        <div class="small text-black-50">Total periodo</div>
        <div class="valor-1">20h 0m</div>
      </div>
    </div>
    <div class="row g-0 row-cols-1 row-cols-md-2 row-cols-lg-4 text-center" >
      <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
        <div class="small text-black-50">Valor</div>
        <div class="valor-1">RPM</div>
      </div>
      <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
        <div class="small text-black-50">Máximo</div>
        <div class="valor-1">30</div>
      </div>
      <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
        <div class="small text-black-50">Mínimo</div>
        <div class="valor-1">0</div>
      </div>
      <div class="col p-4 border border-white" style="background-color:#f1f1f1;">
        <div class="small text-black-50">Promedio</div>
        <div class="valor-1">15</div>
      </div>
    </div>
  --> 
  <div class="map-view view-type-2">

  <app-generic-button [hasModal]="false" txtBtn="{{'modules.trips.change-view' | translate}}" classType="warning"
          (confirmDelete)="changeView()" ></app-generic-button> 

    <app-timeline-apex-v2-chart  *ngIf="trip?.Device && typeOfView == true" [informations]="trip!.Device!.Informations!" [events]="this.trip!.Events!"
      [limitSensors] = "availableSensors" [showInfoPeriod]="false" [showChangeSensors]="typeOfView"
      (indexCurrentPoint)="changeCurrentPoint($event)" (timeCurrentPoint)="changeCurrentPointDate($event)" ></app-timeline-apex-v2-chart>


    <!-- <div *ngIf="indexCurrentPosition">indexCurrentPosition: {{ indexCurrentPosition2.value }}</div> -->
    <!-- <app-timeline-apex-chart *ngIf="trip?.Device" [showInputDates]="false"
   
    [device]="trip?.Device!"
    [showTitle]="false" ></app-timeline-apex-chart> -->

<agm-map *ngIf="centralPoint && centralPoint[0] != 0 && centralPoint[1] != 0"
  [mapTypeId]="'satellite'"
  [latitude]="centralPoint[0]"  [longitude]="centralPoint[1]" [zoom]="zoom">

    <agm-polyline [strokeWeight]="3" [strokeColor]="'#07b57a'">
        <ng-container *ngFor="let position of positions; let i = index;" >

            <agm-polyline-point [latitude]="position.TrackPosition!.LatitudeWGS84!" [longitude]="position.TrackPosition!.LongitudeWGS84!">
            </agm-polyline-point>
   
            <agm-marker [agmFitBounds]="true" *ngIf="i == 0"  [latitude]="position.TrackPosition!.LatitudeWGS84!" [longitude]="position.TrackPosition!.LongitudeWGS84!"  [iconUrl]="'assets/img/maps/location-pin-online.png'"  >
            </agm-marker>
   
            <agm-marker [agmFitBounds]="true" *ngIf="i == positions!.length - 1"  [latitude]="position.TrackPosition!.LatitudeWGS84!" [longitude]="position.TrackPosition!.LongitudeWGS84!" [iconUrl]="'assets/img/maps/location-pin.png'"  >
            </agm-marker>
            
            <agm-circle  [latitude]="position.TrackPosition!.LatitudeWGS84!" [longitude]="position.TrackPosition!.LongitudeWGS84!" 
            [radius]="1"
            [strokeColor]="(i == indexCurrentPosition2.value)?'red':'#07b57a'"
            [fillColor]="'white'"
            [fillOpacity]="1"
            [strokeWeight]="1"
            [circleDraggable]="false"
            (mouseOver)="infoWindow.open();"
            (mouseOut)="infoWindow.close();"
            [editable]="false">

            <agm-info-window [latitude]="position.TrackPosition!.LatitudeWGS84!" [longitude]="position.TrackPosition!.LongitudeWGS84!" 
              [disableAutoPan]="false" #infoWindow>
              <p>{{'modules.trips.position' | translate}} {{i}}</p>
              <br>
              <ul>
              <ng-container *ngFor="let sensor of availableSensors; let u = index;" >
                <ng-container *ngIf="sensor != 'WorkingHours'">
                  <li><span class="fw-bold">{{ mapNameSensors!.get(sensor)  }}:</span> {{  position[sensor] }} </li>
                </ng-container>
                <ng-container *ngIf="sensor == 'WorkingHours'">
                  <li><span class="fw-bold">{{ mapNameSensors!.get(sensor)  }}:</span> 
                    {{ utilSrv.calcularHorasYMinutos(position[sensor]!).horas }}h. {{ utilSrv.calcularHorasYMinutos(position[sensor]!).minutos }}min. </li>
                  
                </ng-container>
              </ng-container>
            </ul>
   
  
          </agm-info-window>
          </agm-circle>


        </ng-container>

        <!-- 
        <agm-icon-sequence [strokeColor]="'#00d21d'" [repeat]="'1px'" [offset]="'0'" [strokeWeight]="3"  [scale]="2" [strokeOpacity]="100"  [path]="'M 0,-1 0,1'">
        </agm-icon-sequence>-->
    </agm-polyline>

    <agm-polyline [strokeWeight]="3" [strokeColor]="'red'">
      <ng-container *ngFor="let position of positions; let i = index;" >

          <agm-polyline-point *ngIf="(i > 0 && i < (positions!.length - 1) && i >= (indexCurrentPosition2.value - 1) && (i <= indexCurrentPosition2.value + 1) ) || (i == 0 && indexCurrentPosition2.value < 2 )  || (i == (positions!.length - 1) && indexCurrentPosition2.value > (positions!.length - 2) )"  [latitude]="position.TrackPosition!.LatitudeWGS84!" [longitude]="position.TrackPosition!.LongitudeWGS84!">
          </agm-polyline-point>
      </ng-container>


    </agm-polyline>
    <agm-polyline [strokeWeight]="3" [strokeColor]="'red'">
     
      <ng-container *ngFor="let eventTrip of trip?.Events!; let i = index;" >
        <ng-container *ngIf="eventTrip!.Information">
          <agm-marker *ngIf="eventTrip!.Type! == 2"  [agmFitBounds]="true"  [latitude]="eventTrip!.Information!.TrackPosition!.LatitudeWGS84!" [longitude]="eventTrip!.Information!.TrackPosition!.LongitudeWGS84!" [iconUrl]="'assets/img/maps/alarma.png'"  >
          </agm-marker>
  
          
          <agm-marker *ngIf="eventTrip!.Type! == 3"  [agmFitBounds]="true"  [latitude]="eventTrip!.Information!.TrackPosition!.LatitudeWGS84!" [longitude]="eventTrip!.Information!.TrackPosition!.LongitudeWGS84!" [iconUrl]="'assets/img/maps/semaforo-verde.png'"  >
          </agm-marker>
          <agm-marker *ngIf="eventTrip!.Type! == 4"  [agmFitBounds]="true"  [latitude]="eventTrip!.Information!.TrackPosition!.LatitudeWGS84!" [longitude]="eventTrip!.Information!.TrackPosition!.LongitudeWGS84!" [iconUrl]="'assets/img/maps/semaforo-rojo.png'"  >
          </agm-marker>
        </ng-container>
        
      </ng-container>
    </agm-polyline>


</agm-map>



<app-timeline-apex-v2-chart  *ngIf="trip?.Device && typeOfView == false" [informations]="trip!.Device!.Informations!" [events]="this.trip!.Events!"
[limitSensors] = "availableSensors" [showInfoPeriod]="false" [showChangeSensors]="typeOfView"
(indexCurrentPoint)="changeCurrentPoint($event)" (timeCurrentPoint)="changeCurrentPointDate($event)" ></app-timeline-apex-v2-chart>
</div>


<div class="container" *ngIf="events">
  <ul class="timeline">
    <ng-container *ngFor="let event of events; let i = index;">

    
      <li [ngClass]="event.type != '2'?'':'timeline-inverted'">
        <div [ngClass]="event.type != '2'?'timeline-badge green':'timeline-badge red'">
          <ng-container *ngIf="event.type == '2'">
            <img src="assets/img/maps/alarma.png" style="width: 32px;" />
          </ng-container>
          <ng-container *ngIf="i == 0  && event.type == '1'">
            <img src="assets/img/maps/location-pin-online.png"  style="width: 32px;" />
          </ng-container>
          <ng-container *ngIf="i == events.length - 1 && event.type == '1' ">
            <img src="assets/img/maps/location-pin.png" style="width: 32px;" />
          </ng-container>
          <ng-container *ngIf="event.type == '3'">
            <img src="assets/img/maps/semaforo-verde.png" style="width: 32px;" />
          </ng-container>
          <ng-container *ngIf="event.type == '4'">
            <img src="assets/img/maps/semaforo-rojo.png" style="width: 32px;" />
          </ng-container>
        </div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <ng-container *ngIf="event.title.indexOf(' ') >= 0 ">
              <h4  class="timeline-title"> 
                <ng-container *ngFor="let word of splitString(event.title)">
                   {{ word | translate }}
                </ng-container>
              </h4>

            </ng-container>
            <h4 *ngIf="event.title.indexOf(' ') == -1" class="timeline-title">{{ event.title | translate }}</h4>
            <p><small class="text-muted"><i class="glyphicon glyphicon-time"></i> {{ event.date  | date: "dd-MM-yyyy" }} {{ 'modules.trips.date-aux' | translate }} {{ event.date  | date: "HH:mm 'h'" }}</small></p>
          </div>
          <!-- <div class="timeline-body">
            <p>{{ event.type }}</p>
          </div> -->
        </div>
      </li>
    </ng-container>
   
  </ul>
</div>


</div>