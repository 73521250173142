import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { NgTerminal } from 'ng-terminal';
import { UploadFileModalComponent } from 'src/app/modals/upload-file-modal/upload-file-modal.component';
import { ApiRequestObject } from 'src/app/models/apiRequestObject';
import { ConsoleTrack, ConsoleTrackCommand } from 'src/app/models/console';
import { Modal } from 'src/app/models/modal';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DevicesService } from 'src/app/services/devices.service';
import { TerminalService } from 'src/app/services/terminal.service';
import { Md5 } from 'ts-md5';
import { Device, vDevice } from '../../../../models/device';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-load-firmware',
  templateUrl: './load-firmware.component.html',
  styleUrls: ['./load-firmware.component.scss']
})
export class LoadFirmwareComponent implements OnInit {

  @ViewChild('term', {static: false}) child?: NgTerminal;
  @Input() ids: Array<number> = [];
  devices: Array<vDevice> = [];
  loading: boolean = true;
  error: boolean = false;

  urlFirmware: string = "";

  showTerminal: boolean = false;

  comandos: Array<string> | null = null;
  testTerminal?: ConsoleTrack;
  terminalSrv?: TerminalService;

  prompt = "";

  constructor(
    public dialog: MatDialog,
    private router : Router,
    public authSrv: AuthenticationService,
    private deviceSrv: DevicesService,
    private translate: TranslateService) {

  }

  ngOnInit(): void {

    this.ids.forEach( (x: number) => {
      this.loadDevice(x);
    });
  }


  verify(){
    if(this.devices.length == this.ids.length){
      this.loading = false;
    }
  }

  showError(){
    this.loading = false;
    this.error = true;
  }




  loadDevice(id: number){
    this.loading = true;
    console.log('loadDevice: ' , id );
    this.deviceSrv.getDeviceById(id).subscribe({
      next: (res: Device) => {
        console.log('res: ' , res );
        this.devices.push(<vDevice>new vDevice(res));
      }, error: (err: ApiRequestObject)=> {
        this.showError();
      }, complete: () =>{
        this.verify();
      }
    });
  }

  openUploadFileModal(){

    let modal = new Modal();
    modal.Title = this.translate.instant('modules.modals.upload-firmware');
    modal.Body = "";
    modal.BtnAccept = this.translate.instant('modules.modals.confirm')
    modal.ClassType = "primary";
    modal.Data = { 'textInput' : 'Selecionar Firmware', 'typeForm' : 'firmware' };
    const dialog = this.dialog.open( UploadFileModalComponent,{
      autoFocus : 'dialog',
      width: "400px",
      height: "auto",
      data: modal,
      closeOnNavigation: false,
      disableClose: true,
      panelClass: 'custom-modalbox',
      position: { top: '150px'}
    });

    dialog.afterClosed().subscribe(
      (result: string | null) => {
        console.log('new url: ' + result);
        if(result!){
          this. urlFirmware = result;

        }
      }
    );
  }

  goToLink(){
    window.open(this.urlFirmware, "_blank");
  }



  runScript(imei: string){


    this.testTerminal = new ConsoleTrack();
    this.testTerminal.imei = imei;
    this.testTerminal.timeSequence = 1000;
    this.testTerminal.commands = [];
    this.testTerminal!.commands?.push(new ConsoleTrackCommand({ command: "$PFAL,SYS.WebUpdate.Start,\"" + this.urlFirmware + "\",80", createdAt: Date.now() }));



    this.runTerminal(this.testTerminal);

    //this.terminalSrv = new TerminalService(this.testTerminal.imei!, String(tokenEncrypt) , this.authSrv.currentUserValue!.UserId!.toString());


  }


  runTerminal(console: ConsoleTrack){
    const md5 = new Md5();

    this.showTerminal = true;

    let date: DateTime = DateTime.now();
    let token: string = this.authSrv.currentUserValue!.Username! + date.toFormat('yyyy');
    let tokenEncrypt = md5.appendStr(  token ).end();

    this.prompt = "$..." + console!.imei!.slice(-3) + "> ";

    try{
      this.terminalSrv = new TerminalService(console!.imei!, String(tokenEncrypt) , this.authSrv.currentUserValue!.UserId!.toString());





      this.terminalSrv!.callback.subscribe( (res: any) => {

        this.child!.write('\x1b[2K')
        //this.child!.write('\r' + this.getPrompt() + res + '\r\n' + this.prompt );

        this.child!.write('\r\x1b[1;32m' + this.getPrompt() + ' \x1b[36m' + res + '\r\n\x1b[37m' + this.prompt );
        //this.input = "";


      });


    this.terminalSrv!.connected.subscribe( async (res: any) => {
      this.child!.write('$ TERMINAL \x1b[33mIMEI ' + console!.imei! + '\x1b[37m' + '\r\n' + this.prompt);

      this.child!.write('\r\x1b[1;32m' + this.getPrompt() + ' \x1b[37m' + this.translate.instant('modules.devices.connecting') + '...' + '\r\n\x1b[37m' + this.prompt );

      this.child!.write('\r\x1b[1;32m' + this.getPrompt() + ' \x1b[31m' + res + '\r\n\x1b[37m' + this.prompt );
      if(res == "DESCONECTADO"){
        this.child!.write('\r\x1b[1;32m' + this.getPrompt() + ' \x1b[37m' + this.translate.instant('modules.devices.connecting') + '...' + '\r\n\x1b[37m' + this.prompt );


      }else{

        if(this.testTerminal!.commands!.length > 0){

          this.child!.write(this.translate.instant('modules.devices.starting-sequence') + " ( " + this.testTerminal?.timeSequence + "ms )");
          this.child!.write('\r\n' + this.prompt);

          for(let i = 0 ; i < this.testTerminal!.commands!.length; i++ ){

            this.child!.write(this.testTerminal!.commands![i].command!);
            this.child!.write('\r\n' + this.prompt);
            this.sendmessage(this.testTerminal!.commands![i].command!);

            await this.sleep(this.testTerminal!.timeSequence!);
          }

          this.child!.write('\r\x1b[1;32m' + this.getPrompt() + ' \x1b[31m' + this.testTerminal!.commands!.length + " " + this.translate.instant('modules.devices.commands-executed') + "." + '\r\n\x1b[37m' + this.prompt );
          await this.sleep(5000);

      }


      }


      //this.showTerminal = false;
    });

    }catch(e){
    }
  }

  getPrompt(): string{
    return "$" + DateTime.now().toFormat('HH:mm:ss')+ ">";
  }

  sendmessage(i: string) {
    console.log("sendmessage");
    this.terminalSrv!.emitEvent(i);
    //this.socket!.emit('new-message', 'Hi-flask');
  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
