import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { UtilsService } from './utils.service';
import { Theme } from '../models/theme';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class ThemeService extends ApiService {

  private themeSubject?: BehaviorSubject<Theme>;
  public theme?: Observable<Theme>;

  private themePreviewSubject?: BehaviorSubject<Theme>;
  public themePreview?: Observable<Theme>;



  constructor(
    private http: HttpClient,
    router: Router,
    private utils: UtilsService, authSrv: AuthenticationService
  ) {

    super(router, authSrv);

    if (localStorage.getItem('currentUser')) {
      this.themeSubject = new BehaviorSubject<Theme>(new Theme(JSON.parse(<string>localStorage.getItem('theme'))));
    } else {
      this.themeSubject = new BehaviorSubject<Theme>(new Theme());
    }
    this.theme = this.themeSubject!.asObservable();

    this.themePreviewSubject = new BehaviorSubject<Theme>(new Theme());
    this.themePreview =  this.themePreviewSubject!.asObservable();

  }


  public get currentTheme(): Theme {
    return this.themeSubject!.value;
  }
  public get currentThemePreview(): Theme {
    return this.themePreviewSubject!.value;
  }

  public currentPreviewTheme(): Theme | null{

    if (localStorage.getItem("theme_preview") === null) {
      return null;
    }
    return new Theme(JSON.parse(<string>localStorage.getItem('theme_preview')));
  }

  public deletePreviewTheme(){
    let t = new Theme(JSON.parse(<string>localStorage.getItem('theme_preview')));
    t.State = 0;

    localStorage.removeItem('theme_preview');
    this.themeSubject!.next(t);
  }

  public setPreviewTheme(theme: Theme){
    localStorage.setItem('theme_preview', JSON.stringify(theme));
    this.themePreviewSubject!.next(theme);
  }

  public update(theme: Theme){
    localStorage.setItem('theme', JSON.stringify(theme));
    this.themeSubject!.next(theme);
  }

}
