<app-loading-custom [loading]="loadingDelete"></app-loading-custom>


<div *ngIf="datatableConfigColumns && datatableConfigColumns.headerTable" class="m-3">

  <button *ngIf="selectedItems || selectedNotificationsList.length > 0"  mat-raised-button class="btn btn-warning d-none d-xl-block p-absolute" (click)="readCheck()">
    {{ (this.onlyNoProcessed==true)?('modules.notifications.mark-as-read' | translate):'' }}
    {{ (this.onlyProcessed==true)?('modules.notifications.mark-as-unread' | translate):'' }}
  </button>

  <table [id]="nameDatatable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
    class="display nowrap table row-border hover stripe table-striped table-sm" style="width: 100% !important">
    <thead>
      <tr>
        <th *ngFor="let headColumn of datatableConfigColumns.headerTable" [attr.class]="'column-' + headColumn.column + ' column-' + headColumn.tag" >
          <ng-container *ngIf="headColumn.name != '#select'">
            {{ headColumn.name}}
          </ng-container>
          <ng-container *ngIf="headColumn.name == '#select'">
            <div class="c-pointer-i">
              <label>
                <input type="checkbox" style="display: none;" (change)="selectUnselectAll()">
                <i  class="far fa-lg pl-3"
                    [ngClass]="selectedState === 1 ? 'fa-square' : (selectedState === 2 ? 'fa-check-square text-success' : 'fa-minus-square text-warning')"
                    style="color: #000">
                </i>
              </label>
            </div>
          </ng-container>
        </th>
      </tr>
    </thead>

    <tbody>
      <tr *ngIf="loading == true">
        <td [attr.colspan]="datatableConfigColumns!.headerTable!.length">{{'modules.shared.loading-info' | translate}}...</td>
      </tr>
      <tr *ngIf="loading == false && listaNotificaciones.length == 0">
        <td [attr.colspan]="datatableConfigColumns!.headerTable!.length">{{'modules.shared.no-records' | translate}}.</td>
      </tr>
      <tr *ngFor="let notification of listaNotificaciones"  (click)="checkuncheckall(notification)">

        <td  class="text-center c-pointer">
          <i *ngIf="notification.Selected == false" class="far fa-square fa-lg pl-3" style="color: #000"></i>
          <i *ngIf="notification.Selected == true"  class="far fa-check-square text-success fa-lg pl-3"></i>

        </td>

        <td>{{ notification.IMEI }}
        </td>
        <td class="c-pointer-i text-center">
          {{ notification.Data }}
        </td>
        <td class="c-pointer-i text-center">
          {{ notification.Value }}
        </td>

        <td>
          {{ notification.CreatedAt | date: "dd-MM-yyyy HH:mm" }}
        </td>
        <td class="c-pointer-i text-right actions">
          <a [routerLink]="['/devices/' + notification.IdDevice +'/detail/grafica/alert/' + notification.Id  ]"><i class="fas fa-angle-double-right text-primary fa-lg pl-3"></i></a>
        </td>
      </tr>
    </tbody>
  </table>


  <section class="m-1  mt-4 " *ngIf="selectedItems || selectedNotificationsList.length > 0">
    <div class="text-left" style="flex-direction: row;">
      <button *ngIf="selectedItems || selectedNotificationsList.length > 0"  mat-raised-button class="btn btn-warning" (click)="readCheck()">
          {{ (this.onlyNoProcessed==true)?('modules.notifications.mark-as-read' | translate):'' }}
          {{ (this.onlyProcessed==true)?('modules.notifications.mark-as-unread' | translate):'' }}
        </button>
    </div>
  </section>

</div>

