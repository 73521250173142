import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialCdkModule } from '../material-cdk/material-cdk.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from "angular-datatables";
import { ColorPickerModule } from 'ngx-color-picker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


import { ListAlarmsPageComponent } from './pages/list-alarms-page/list-alarms-page.component';
import { AddAlarmPageComponent } from './pages/add-alarm-page/add-alarm-page.component';
import { DetailAlarmPageComponent } from './pages/detail-alarm-page/detail-alarm-page.component';
import { EditAlarmPageComponent } from './pages/edit-alarm-page/edit-alarm-page.component';
import { EditAlarmComponent } from './components/edit-alarm/edit-alarm.component';
import { AddAlarmComponent } from './components/add-alarm/add-alarm.component';
import { DetailAlarmComponent } from './components/detail-alarm/detail-alarm.component';
import { AlarmsRoutingModule } from './alarms-routing.module';
import { ListModelAlarmsComponent } from './components/list-model-alarms/list-model-alarms.component';
import { ListDeviceAlarmsComponent } from './components/list-device-alarms/list-device-alarms.component';
import { ListDefaultModelAlarmsComponent } from './components/list-default-model-alarms/list-default-model-alarms.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { DetailDefaultValuesAlarmPageComponent } from './pages/detail-default-values-alarm-page/detail-default-values-alarm-page.component';
import { DetailDefaultValuesAlarmComponent } from './components/detail-default-values-alarm/detail-default-values-alarm.component';
import { FormValuesAlarmComponent } from './components/form-values-alarm/form-values-alarm.component';
import { FormRangeDefaultValuesAlarmComponent } from './components/form-range-default-values-alarm/form-range-default-values-alarm.component';
import { DetailModelAlarmComponent } from './components/detail-model-alarm/detail-model-alarm.component';
import { DetailDeviceAlarmComponent } from './components/detail-device-alarm/detail-device-alarm.component';
import { DetailDeviceAlarmPageComponent } from './pages/detail-device-alarm-page/detail-device-alarm-page.component';
import { DetailModelAlarmPageComponent } from './pages/detail-model-alarm-page/detail-model-alarm-page.component';
import { ListAllAlarmsComponent } from './components/list-all-alarms/list-all-alarms.component';
import { SimulateTracePageComponent } from './pages/simulate-trace-page/simulate-trace-page.component';
import { SimulateTraceComponent } from './components/simulate-trace/simulate-trace.component';


@NgModule({
  declarations: [
    ListAlarmsPageComponent,
    AddAlarmPageComponent,
    DetailAlarmPageComponent,
    EditAlarmPageComponent,
    EditAlarmComponent,
    AddAlarmComponent,
    DetailAlarmComponent,
    ListModelAlarmsComponent,
    ListDeviceAlarmsComponent,
    ListDefaultModelAlarmsComponent,
    DetailDefaultValuesAlarmPageComponent,
    DetailDefaultValuesAlarmComponent,
    FormValuesAlarmComponent,
    FormRangeDefaultValuesAlarmComponent,
    DetailModelAlarmComponent,
    DetailDeviceAlarmComponent,
    DetailDeviceAlarmPageComponent,
    DetailModelAlarmPageComponent,
    ListAllAlarmsComponent,
    SimulateTracePageComponent,
    SimulateTraceComponent

  ],
  imports: [
    CommonModule,
    MaterialCdkModule,
    CoreModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AlarmsRoutingModule,
    DataTablesModule,
    ColorPickerModule,
    NgMultiSelectDropDownModule,
    FontAwesomeModule
  ],
  exports:[
    MaterialCdkModule,
    NgMultiSelectDropDownModule,
    FontAwesomeModule,
    ListDeviceAlarmsComponent,
    DetailDeviceAlarmPageComponent,
    ListAllAlarmsComponent,
    DetailDefaultValuesAlarmComponent
  ]
})
export class AlarmsModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);

  }


}
