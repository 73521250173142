
export class ConsoleTrack{
  imei?: string;
  commands?: Array<ConsoleTrackCommand>;
  timeSequence?: number;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}


export class ConsoleTrackCommand{
  id?: string
  command?: string
  createdAt?: Date
  finishedAt?: Date
  result?: string

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}



export interface resultConsole{
  status: string;
  result: any;
}
