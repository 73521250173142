<div class="main main-box">
  <div class="d-flex" style="flex: 1 1 auto; justify-content: space-between;">
    <h4 class="mb-3 d-flex">{{'modules.devices.device-details' | translate}}</h4>


  </div>

  <div class="row">
    <div *ngIf="device?.Name && device?.Name!=''" class="col-md-12 mb-3">
      <div><strong>{{'modules.tables.name' | translate}}:</strong></div>
      <div>{{ device?.Name??'modules.devices.no-name2' | translate }}</div>
    </div>
    <div class="col-md-6 mb-3">
      <div><strong>{{'modules.devices.imei-number' | translate}}:</strong></div>
      <div>{{ device?.IMEI }}</div>
    </div>
    <div class="col-md-6 mb-3">
      <div><strong>{{'modules.alarms.model' | translate}}:</strong></div>
      <div class="c-pointer" [routerLink]="['/devices/models/' + device?.DeviceModel.Id +'/detail']">{{ device?.DeviceModel.Name }}</div>
    </div>


    <div class="col-md-6 mb-3">
      <div><strong>{{'modules.tables.serial-number' | translate}}:</strong></div>
      <div>{{ device?.NumSerieManufacturer??'-' }}</div>
    </div>
    <div class="col-md-6 mb-3">
      <div><strong>{{'modules.tables.comments' | translate}}:</strong></div>
      <div>{{ device?.Comments??'-' }}</div>
    </div>

    <!-- <div class="col-md-6 mb-3">
      <div><strong>{{'modules.devices.state' | translate}}:</strong></div>
      <div>{{ (device?.Active)?"'modules.devices.enabled' | translate":"'modules.devices.disabled' | translate" }}</div>
    </div> -->
    <div class="col-md-6 mb-3">
      <div><strong>{{'modules.shared.last-data-recorded' | translate}}:</strong></div>
      <!-- <div>{{ (device?.InformationLast == null)?"-":device?.InformationLast | date: "dd/MM/yyyy 'a las' HH:mm" }}</div> -->
      <div *ngIf="device?.InformationLast == null">-</div>
      <div *ngIf="device?.InformationLast != null">{{ device?.InformationLast | date: "dd/MM/yyyy" }} {{ 'modules.trips.date-aux' | translate }} {{ device?.InformationLast | date: "HH:mm" }}</div>
    </div>
    <!-- <div class="col-md-6 mb-3">
      <div><strong>{{'modules.tables.total-records' | translate}}:</strong></div>
      <div>{{ device?.InformationsCount! | customDecimal }} {{'modules.tables.records' | translate}}</div>
    </div> -->
    <div class="col-md-6 mb-3">
      <div><strong>{{'modules.devices.coordinates' | translate}}:</strong></div>
      <div *ngIf="information == null">
        {{ 'modules.shared.no-info' | translate }}
      </div>
      <div *ngIf="information">
        <a *ngIf="information.TrackPosition!.LatitudeWGS84 && information!.TrackPosition!.LongitudeWGS84" target="_blank" [href]="urlGoogleMaps">
          {{ information.TrackPosition?.LatitudeWGS84??'' }}, {{ information.TrackPosition?.LongitudeDirection=='E'?'':'-'}}{{ information.TrackPosition?.LongitudeWGS84??'' }}
        </a>
        <span *ngIf="information!.TrackPosition!.LatitudeWGS84 == undefined || information!.TrackPosition!.LongitudeWGS84 == undefined">{{'modules.devices.without-data' | translate}}</span>    </div>
    </div>
  </div>
</div>
