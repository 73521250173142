import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/models/user';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-detail-user-page',
  templateUrl: './detail-user-page.component.html',
  styleUrls: ['./detail-user-page.component.scss']
})
export class DetailUserPageComponent implements OnInit {

  id?: number;
  user?: User;
  loading:boolean = true;

  constructor(private breadcrumbService: BreadcrumbService,
    private userSrv: UserService,
    private route: ActivatedRoute,
    private translate: TranslateService) { 
    
    this.breadcrumbService.setBreadCrumbUrl('users/list', this.translate.instant('modules.users.management'));
    //breadcrumbService.addBreadCrumb('user', this.translate.instant('modules.users.user-detail'));

    this.route.params.subscribe(params => {
      this.id = params['id'];
      console.log(this.id)
      this.loadUser(this.id! );

    });

  }

  ngOnInit(): void {
    this.loading = true;
  }

  

  loadUser(id: number){
    this.user = undefined;
    console.log('loadUser: ' , id );
    this.userSrv.getUserById(id).subscribe({
      next: (res: User) => {
        this.user = new User(<User>res);
        this.breadcrumbService.setBreadCrumbUrl('users/list', this.translate.instant('modules.users.management'));
        this.breadcrumbService.addBreadCrumb('users/' + this.user!.Id  + '/detail', this.user!.Name??'' + ' ' + this.user!.FirstName??'' + ' ' + this.user!.LastName??'' );


      }, error: ()=> {

      },
      complete: ()=>{
        this.loading = false;
      }
    });
  }
}
