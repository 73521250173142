import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor(private router: Router, private authSrv: AuthenticationService){

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      console.log("AuthGuard: canActivate" );

      //this.router.navigate(['/home']);
      //return of(true);

      if(this.authSrv.isLogedUser){
        this.router.navigate(['/home']);
      };

      return of(true);


      this.authSrv.loginAutenticacion().pipe(
        tap( (estaAuth: boolean) => {
          console.log(estaAuth);
          if(!estaAuth){
            console.log("No esta auth");
            this.authSrv.clear();

          }else{

            console.log("Go admin");
            this.router.navigate(['/home']);

          }
        })
      );

      //return of(true);
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      console.log("AuthGuard: canLoad" );

      if(this.authSrv.isLogedUser){
        this.router.navigate(['/home']);
      };

      return of(true);

      this.authSrv.loginAutenticacion().pipe(
        tap( (estaAuth: boolean)  => {
          console.log(!estaAuth);
          if(estaAuth){
            //this.authSrv.clear();
            return of(true);
            //return true;
          }else{
            console.log("redirect");
            this.router.navigate(['/home']);
            return of(false);
          }
        }, (error: any) => {
          return false;
        })
      );

  }
}
