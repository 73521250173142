import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit, Input, SimpleChanges, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/services/users.services';
import { UtilsService } from 'src/app/services/utils.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ListResult } from 'src/app/models/apiRequestObject';
import { DevicesService } from 'src/app/services/devices.service';
import { Device, DeviceModel, vDevice } from 'src/app/models/device';
import { User } from 'src/app/models/user';
import { DatatableColumn, DatatableConfig } from 'src/app/models/datatableColumn';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/modals/confirmation-modal/confirmation-modal.component';
import { LinkModal, Modal } from 'src/app/models/modal';
import { DeviceAddQuickComponent } from 'src/app/modals/device-add-quick/device-add-quick.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Trip } from 'src/app/models/trip';
import { TripsService } from 'src/app/services/trips.service';

declare const $: any

@Component({
  selector: 'app-list-trips-devices',
  templateUrl: './list-trips-devices.component.html',
  styleUrls: ['./list-trips-devices.component.scss']
})
export class ListTripsDevicesComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() device?: Device;
  @Input() showNewDevice: boolean = true;
  @Input() userInput: User | null = null;
  @Input() modelInput: DeviceModel | null = null;

  @Input() isManufacturer: boolean | null = null;
  @Input() isClient: boolean | null = null;
  selectedState: number = 1;

  @ViewChild(DataTableDirective, {static: false}) datatableElement?: DataTableDirective;

  /* Datatable parameters */
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  nameDatatable: string = "DeviceList";
  isDtInitialized: boolean = false;
  datatableConfigColumns: DatatableConfig = new DatatableConfig();
  viewPaging: boolean = true;
  selectedItems: boolean = false;

  loading: boolean = true;
  loadingDelete: boolean = false;
  loadingDtOptions: boolean = true;
  
  tripList: Trip[] = [];

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private router : Router,
    public authSrv: AuthenticationService,
    private usersSrv : UsersService,
    private deviceSrv : DevicesService,
    private tripSrv: TripsService,
    private utilsSrv : UtilsService) {


    }


  public ngOnChanges(changes: SimpleChanges): void {
    console.log("ngOnChanges");
    this.rerender();
  }

  ngAfterViewInit(): void {


    if(this.userInput){
      console.log("userInput");
      console.log(this.userInput);
    }

    console.log("ngAfterViewInit");
      const that = this;

      that.dtTrigger.next(0);
      /*
      setTimeout(function () {
        $('#' + that.nameDatatable).DataTable().destroy();
        that.dtTrigger.next(0);
        that.datatableElement!.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.draw();
        });

      }, 300); */
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  ngOnInit(): void {

    console.log('## List trip ' , this.device);

    this.dtOptions = this.optionsDatatable();
  }

  public optionsDatatable() {
    const that = this;


    this.datatableConfigColumns.headerTable = [
      new DatatableColumn(0,"IMEI", "IMEI", "asc"),
      new DatatableColumn(1,this.translate.instant('modules.tables.actions'), "", "asc")
    ];

    console.log("isManufacturer" , this.isManufacturer);
    console.log("isClient" , this.isClient);
    console.log("userInput" , this.userInput);

    if(this.userInput){
      if(this.userInput.isClient){
        //this.datatableConfigColumns.hideColumnByName("Usuario");
      }
    }

    if(this.authSrv.isAdmin() == false && this.authSrv.isManufacturer() == false ){
      //this.datatableConfigColumns.hideColumnByTag("NumSerieManufacturer");
      //this.datatableConfigColumns.hideColumnByTag("Comments");
    }

    if(this.authSrv.isAdmin() == false){

      //this.datatableConfigColumns.hideColumnByTag("Name");
      //this.datatableConfigColumns.hideColumnByTag("ICCID");
      //this.datatableConfigColumns.hideColumnByTag("FirmwareVersion");
    }

    if(this.authSrv.isClient()){
      //this.datatableConfigColumns.hideColumnByTag("OnlineDate");
    }


    if( (this.isManufacturer != null && this.isManufacturer == true) || (this.userInput != null && this.userInput.isManufacturer == true) ){
      //this.datatableConfigColumns.hideColumnByTag("NameManufacturer");
    }

    if(this.isClient != null && this.isClient == true){
      //this.datatableConfigColumns.hideColumnByName("Usuario");
    }

    //this.datatableConfigColumns.changeOrderByName("Online");
    //this.datatableConfigColumns.orderDesc();

    console.log(this.datatableConfigColumns.order);
    console.log(this.datatableConfigColumns.columnDefs);

    this.loadingDtOptions = false;

    return {
      name: this.nameDatatable,
      order: this.datatableConfigColumns.order,
      columnDefs: this.datatableConfigColumns.columnDefs,
      lengthChange: true,
      serverSide: true,
      processing: false,
      retrieve: true,
      //stateSave: true,
      deferRender: true,
      initComplete: (settings: DataTables.Settings, json: any) => {
        $("#" + this.nameDatatable).wrap("<div class='demo' style='overflow:auto; width:100%;position:relative;'></div>");
        if(!this.viewPaging){
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().parent().find("div").eq(0).removeClass("col-md-5").addClass("col-md-12");
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().css("display", "none" );
        }else{
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().parent().find("div").eq(0).removeClass("col-md-12").addClass("col-md-5");
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().css("display", "block" );
        }
      },
      ajax: (dataTablesParameters: any, callback: any) => {

        that.loading = true;

        let column = dataTablesParameters.order[0].column;
        let colmunOrder = this.datatableConfigColumns.getColumnById(column);
        let colmunOrderTag = colmunOrder?.tag!;
        let order = dataTablesParameters.order[0].dir;
        let draw = dataTablesParameters.draw;

        dataTablesParameters.search.regex = true
        let page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length);
        this.tripList = [];


        console.log(colmunOrder);

        that.tripSrv.getList(page, dataTablesParameters.length, colmunOrderTag, order, dataTablesParameters.search.value,this.userInput??undefined, this.device?.Id??undefined).subscribe(
          {
            next: (res: ListResult) => {

            //that.listaUsuarios = res.result.map(x => new User (x));
            //that.listaDispositivos = <vDevice[]>res.Result;
            that.tripList = <Trip[]>res.Result.map( x => new Trip(x));

            // Activa los checklist de los modelos seleccionados entre paginas
            for (let index = 0; index < this.tripList.length; index++) {
              const modelo = this.tripList[index];
              
            }

            this.viewPaging = false;
            if(res.Filter > 10){
              this.viewPaging = true;
            }

            callback({
              recordsTotal: res.Total,
              recordsFiltered: res.Filter,
              data: []
            });

            //this.rerender();
            //this.dtTrigger.next(0);
            that.loading = false;

            //$('.dataTables_empty').css('display', 'none');

            if (res.Result.length > 0) {
              $('.dataTables_empty').css('display', 'none');
            }else{
              //this.dtTrigger.next(0);
              //$('.dataTables_empty').css('display', 'none');

            }
            //document.querySelectorAll<HTMLElement>('.dataTables_empty')[0].style.display = 'none';
            that.columnAdjust();


          },
          error: ( err : any) => {
            that.loading = false;
          }
        });


      },

      headerCallback: (thead: any, data: any, start: any, end: any, display: any) => {

      },
      language: {
        url: this.utilsSrv.DatatablesFileLang()
      },
      infoCallback: function( settings: any, start:number, end:number, max:number, total:number, pre: any ) {
        return that.datatableConfigColumns.DatatablesFooterShowText(settings, start,end, max, total , pre );
      },
    };

  }

  columnAdjust(): void {
    $($.fn.dataTable.tables(true)).DataTable().columns.adjust().responsive.recalc();
  }

  rerender(){
    if(this.datatableElement){
      this.datatableElement!.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear().destroy();
        this.dtTrigger.next(0);
      });
    }
  }

}
