import { Component, Input, OnInit } from '@angular/core';
import { ApiRequestObject } from 'src/app/models/apiRequestObject';

@Component({
  selector: 'app-display-api-request-object',
  templateUrl: './display-api-request-object.component.html',
  styleUrls: ['./display-api-request-object.component.scss']
})
export class DisplayApiRequestObjectComponent implements OnInit {

  @Input() info?: ApiRequestObject;
  @Input() withBlock: boolean = true;
  constructor() { }

  ngOnInit(): void {

    console.log(this.info);
  }

}
