import { Component, Input, OnInit } from '@angular/core';
import { AlarmDeviceModelDefaultValues } from 'src/app/models/alarms';

@Component({
  selector: 'app-detail-default-values-alarm',
  templateUrl: './detail-default-values-alarm.component.html',
  styleUrls: ['./detail-default-values-alarm.component.scss']
})
export class DetailDefaultValuesAlarmComponent implements OnInit {

  @Input() information?: AlarmDeviceModelDefaultValues;
  @Input() showChangeModel: boolean = true;

  loading:boolean = true;

  constructor() { }


  get showloading(){
    return this.information === undefined?true: false;
  }

  ngOnInit(): void {
  }

}
