import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DeviceModelAddQuickComponent } from 'src/app/modals/device-model-add-quick/device-model-add-quick.component';
import { UserAddQuickComponent } from 'src/app/modals/user-add-quick/user-add-quick.component';
import { ListResult } from 'src/app/models/apiRequestObject';
import { Device, DeviceModel, vDevice, vDeviceModel } from 'src/app/models/device';
import { Modal } from 'src/app/models/modal';
import { User, vUser } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DevicesService } from 'src/app/services/devices.service';
import { EmailService } from 'src/app/services/email.service';
import { UsersService } from 'src/app/services/users.services';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-edit-device',
  templateUrl: './edit-device.component.html',
  styleUrls: ['./edit-device.component.scss']
})
export class EditDeviceComponent implements OnInit {

  @Input() editDevice?: Device  = new Device({ 'Active': true });

  @Input() fromList: boolean = false;
  @Input() modelInput: DeviceModel | null = null;
  @Input() manufacturerInput: User | null = null;
  @Input() userInput: User | null = null;
  @Input() showButtonCancel: boolean = false;
  @Output() deviceOutput = new EventEmitter<vDevice>();

  @Input() autoCloseManufacturer: boolean = true;
  @Input() autoCloseClient: boolean = true;

  public user: string | null;

  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public authSrv: AuthenticationService,
    private emailSrv: EmailService,
    private usersSrv: UsersService,
    private deviceSrv: DevicesService,
    private utilsSrv: UtilsService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService) {
    this.user = this.route.snapshot.paramMap.get('user');
  }

  loading: boolean = true;
  public LoginError?: boolean;
  public modelError = false;
  public clientError = false;

  public modelsList: Array<vDeviceModel> | null= null;
  public clientList: Array<vUser> | null= null;

  deviceForm: FormGroup = this.formBuilder.group({
  });

  dropdownSettingsClient : IDropdownSettings= {};
  dropdownSettingsFabricante : IDropdownSettings= {};



  ngOnInit(): void {

    console.log(this.editDevice);
    
    console.log(this.fromList);
    this.loading = true;

    this.dropdownSettingsClient = {
      singleSelection: true,
      closeDropDownOnSelection: this.autoCloseClient,
      idField: 'Id',
      textField: 'Name',
      selectAllText: this.translate.instant('modules.shared.select-all'),
      unSelectAllText: this.translate.instant('modules.shared.unselect-all'),
      allowSearchFilter: true,
      //noFilteredDataAvailablePlaceholderText: "No hay coincidencias",
      noDataAvailablePlaceholderText: this.translate.instant('modules.shared.no-users'),
      searchPlaceholderText: this.translate.instant('modules.shared.search'),
      clearSearchFilter: true,
    };

    this.dropdownSettingsFabricante = {
      singleSelection: true,
      closeDropDownOnSelection: this.autoCloseManufacturer,
      idField: 'Id',
      textField: 'NameManufacturer',
      selectAllText: this.translate.instant('modules.shared.select-all'),
      unSelectAllText: this.translate.instant('modules.shared.unselect-all'),
      allowSearchFilter: true,
      //noFilteredDataAvailablePlaceholderText: "No hay coincidencias",
      noDataAvailablePlaceholderText: this.translate.instant('modules.shared.no-accounts'),
      searchPlaceholderText: this.translate.instant('modules.shared.search'),
      clearSearchFilter: true,
    };

    //this.loadDevices();

    if(this.authSrv.isAdmin() || this.authSrv.isManufacturer()){
      this.getClients();
      this.getModelsManufacturers(this.editDevice?.IdModel);
    
    }else{
      this.deviceForm.addControl('Device',this.addDeviceForm());
      this.loading = false;

    }


  }

  get isClient(){
    if(this.userInput != null){
      if(this.userInput.isClient){
        return true;
      }
    }
    return false;
  }

  get isManufacturer(){
    if(this.userInput != null){
      if(this.userInput.isManufacturer){
        return true;
      }
    }
    return false;
  }


  getClients(){
    this.loading = true;
    this.clientList = null;
    this.usersSrv.getClients(0, 10000, 'Name' , 'ASC' ).subscribe({
      next: (res: ListResult) => {

        this.clientList = <User[]>res.Result;
        console.log(this.clientList);

        if(this.isClient){
          this.editDevice!.IdUser = this.userInput!.Id;
          this.editDevice!.Client = [ this.userInput! ];

        }

      },error: (err: any) =>{
        console.log("error getClients");
      }, complete: () =>{
        if(this.isCompleteLoading){
          this.deviceForm.addControl('Device',this.addDeviceForm());
          this.loading = false;
        }
      }
    });

  }

  getModelsManufacturers(id?: number){
    this.modelsList = null;
    this.loading = true;

    this.deviceSrv.getAllModels().subscribe({
      next: (res: ListResult) => {

        this.modelsList = <vDeviceModel[]>res.Result;

        if(this.modelsList.length == 1){
          //this.modelsList = this.modelsList.filter(x=> x.Name == "Sin asignar");
        }
        console.log(this.modelsList);

        if(id){

          this.modelsList.forEach( item =>{
            if(item.Id == id){
              this.editDevice!.DeviceModel = [];
              this.editDevice!.DeviceModel!.push(item);

            }

          })
        }else if(this.modelsList.length == 1){
          this.editDevice!.DeviceModel = [];
          this.editDevice!.DeviceModel!.push(this.modelsList[0]);
        }

      },error: (err: any) =>{
        console.log("error getManufacturers");
      }, complete: () =>{
        if(this.isCompleteLoading){
          this.deviceForm.addControl('Device',this.addDeviceForm());

          if(this.modelInput){
            //this.formBuilder.
          }

          this.loading = false;
        }
      }
    });
  }

  get isCompleteLoading(): boolean{
    if(this.clientList != null && this.modelsList != null){
      return true;
    }
    return false;
  }

  get Active(){ return this.deviceForm.get("Device")!.get("Active")}
  get Name(){ return this.deviceForm.get("Device")!.get("Name"); }
  get Model(){ return this.deviceForm.get("Device")!.get("Model"); }
  get Client(){ return this.deviceForm.get("Device")!.get("Client"); }
  get IMEI(){ return this.deviceForm.get("Device")!.get("IMEI"); }
  get ICCID(){ return this.deviceForm.get("Device")!.get("ICCID")}
  get NumSerieManufacturer(){ return this.deviceForm.get("Device")!.get("NumSerieManufacturer"); }
  get Comments(){ return this.deviceForm.get("Device")!.get("Comments")}


  addDeviceForm() {

    if(this.authSrv.isAdmin()){


      return this.formBuilder.group({
        Name: new FormControl(this.editDevice!.Name, [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50)
        ]),
        Active: new FormControl(this.editDevice!.Active, [
          Validators.required
        ]),
        DeviceModel: new FormControl(this.editDevice!.DeviceModel, [
        ]),
        Client: new FormControl(this.editDevice!.Client, [
        ]),
        IMEI: new FormControl(this.editDevice!.IMEI, [
          Validators.required,
          Validators.minLength(15),
          Validators.maxLength(15)
        ]),
        ICCID: new FormControl(this.editDevice!.ICCID, [
          Validators.required,
          Validators.minLength(19),
          Validators.maxLength(19)
        ]),
        NumSerieManufacturer: new FormControl(this.editDevice!.NumSerieManufacturer, [
          Validators.maxLength(100)
        ]),
        Comments: new FormControl(this.editDevice!.Comments, [
          Validators.maxLength(150)
        ]),
      });
    }else if(this.authSrv.isManufacturer()){
      this.editDevice!.Active = false;

      return this.formBuilder.group({
        Name: new FormControl(this.editDevice!.Name, [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50)
        ]),
        Active: new FormControl(this.editDevice!.Active, [
          Validators.required
        ]),
        DeviceModel: new FormControl(this.editDevice!.DeviceModel, [
        ]),
        Client: new FormControl(this.editDevice!.Client, [
        ]),
        IMEI: new FormControl(this.editDevice!.IMEI, [
          Validators.required,
          Validators.minLength(15),
          Validators.maxLength(15)
        ]),
        NumSerieManufacturer: new FormControl(this.editDevice!.NumSerieManufacturer, [
          Validators.maxLength(100)
        ]),
        Comments: new FormControl(this.editDevice!.Comments, [
          Validators.maxLength(150)
        ]),
      });

    }else{

      return this.formBuilder.group({
        Name: new FormControl(this.editDevice!.Name, [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50)
        ]),        
      });

    }
  }



  get getClient() {
    return this.clientList!.reduce((acc:any , curr: vUser) => {
      acc[curr.Id!] = curr;
      return acc;
    }, {});
  }

  get getModel() {
    return this.modelsList!.reduce((acc:any , curr: vDeviceModel) => {
      acc[curr.Id!] = curr;
      return acc??'';
    }, {});
  }

  newCliente(){

    const dialogRef = this.dialog.open(
      UserAddQuickComponent,
      {
        id: 'UserAddQuickComponent',
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: new Modal({ 'Data' : 'client' }),
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox',

    });

    dialogRef.afterClosed().subscribe( (result?: vUser) => {
      if(result){

        this.getClients();
        this.editDevice!.Client = [{ 'Id': result.Id, 'Name': result.Name }];
      }
    });

  }

  newModel(){

    let data;
    let minHeight = 'auto'
    if(this.userInput){
      data = { 'Data' : { "idUser" : this.userInput!.Id }, 'User' : { ...this.userInput! } };
      minHeight = 'auto';
    }

    const dialogRef = this.dialog.open(
      DeviceModelAddQuickComponent,
      {
        id: 'DeviceModelAddQuickComponent',
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        minHeight: minHeight,
        data: new Modal(data),
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe( (result?: vDeviceModel) => {
      if(result){
        console.log(`Dialog result: ${result.Name!}`);

        let newModel: vDeviceModel = Object.assign({},result);

        this.getModelsManufacturers();
        this.editDevice!.DeviceModel = [{ 'Id': result.Id, 'NameManufacturer': result.NameManufacturer }];

      }

    });

  }

  newManufacturer(){
    const dialogRef = this.dialog.open(
      UserAddQuickComponent,
      {
        id: 'UserAddQuickComponent',
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        height: 'auto',
        data: new Modal({ 'Data' : 'fabricant' }),
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe( {
      next: (result?: vUser) => {
      if(result){
        console.log(result);
        console.log(`Dialog result: ${result.Name!}`);
        let newUser: vUser = Object.assign({},result);

        this.getModelsManufacturers(newUser.Id);
      }else{
        console.log("ERRORR 1");

      }
    },
    error: (result?: any) => {
      if(result && result.modal){

      }
      console.log("ERRORR 2");
    }, complete: () => {
      this.loading = false;
    }
    });

  }

  modalCliente(){

    console.log("Cliente: " , this.editDevice!.IdUser!);
    console.log(this.clientList);

  }

  modalFabricante(){

  }

  test(){
    console.log(this.userInput);
    console.log(this.editDevice);
    //console.log(this.editDevice!.DeviceModel[0].Id);
    console.log(this.deviceForm);

    console.log( this.deviceForm.get('Device')!.valid );
    console.log(this.editDevice!.DeviceModel.length);

  }

  cancel() {

    this.test();

    this.dialog.closeAll();
  }

  onSubmit() {

    console.log("submit");


    if (! this.deviceForm!.valid){
      console.log("ko");
      return ;
    }
    console.log("ok");
    console.log(this.deviceForm);
    this.loading = true;

    console.log(this.editDevice);

    if(!this.authSrv.isClient()){
      if(this.modelsList?.length == 1){
        this.editDevice!.IdModel = this.modelsList[0].Id;

      }else{

        if(this.modelInput == null){
          if(this.editDevice!.DeviceModel && this.editDevice!.DeviceModel.length > 0){
            this.editDevice!.IdModel = this.editDevice!.DeviceModel[0]!.Id??null;
            this.modelError = false;
          }else{
            this.modelError = true;
            this.loading = false;
            return;
          }
        }
      }
    }


    if(this.userInput == null){
      //if(this.isClient){
        if(this.editDevice!.Client && this.editDevice!.Client.length > 0){
          this.editDevice!.Client = this.editDevice!.Client[0];
          this.editDevice!.IdUser = this.editDevice!.Client.Id

          this.editDevice!.Manufacturer = this.editDevice!.Client[0];
        }

      //}else if(this.isManufacturer){
      //}
    }

    if(!this.authSrv.isClient()){
      //this.editDevice!.IdModel = this.editDevice!.DeviceModel.Id;
      this.editDevice!.IdModel = this.editDevice!.DeviceModel[0].Id
      this.editDevice!.Manufacturer = this.manufacturerInput;
    }

    console.log(this.editDevice);

    //this.loading = false;
    //return;

    if(this.editDevice!.NumSerieManufacturer == null){
      this.editDevice!.NumSerieManufacturer = "";
    }
    if(this.editDevice!.Comments == null){
      this.editDevice!.Comments = "";
    }

    this.deviceSrv.change(this.editDevice!).subscribe({
      next: (resultDevice: Device) =>{
        console.log("OK createDevice");
        console.log(resultDevice);

        if( (this.userInput || this.modelInput) || this.fromList == true){
          this.emitDevice(<vDevice>new vDevice({ ...resultDevice }));
        }else{

          this.router.navigate(['/devices/' + resultDevice.Id + '/detail']);
        }


      },error: (err: any) =>{
        if(err.status! == "MODAL"){
          this.utilsSrv.infoModal(err.msg!, undefined, err.title);
        }
        this.loading = false;
      }, complete: () =>{
        this.loading = false;
      }

    });

  }

  private emitDevice(resultUser: vDevice) {
    this.deviceOutput.emit(resultUser);
  }

  get isValidForm(): boolean{
    if(this.deviceForm && this.authSrv.isClient()){
      return this.deviceForm.get('Device')!.valid;

    }else{
      return this.deviceForm.get('Device')!.valid && this.editDevice!.DeviceModel && this.editDevice!.DeviceModel.length > 0;
    }
    return false;
  }
}

