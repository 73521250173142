import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmailValidator, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-edit-user-contact',
  templateUrl: './edit-user-contact.component.html',
  styleUrls: ['./edit-user-contact.component.scss']
})
export class EditUserContactComponent implements OnInit {
  @Input() user?: User;
  loading:boolean = true;

  contactForm: FormGroup = this.formBuilder.group({
  });
  @Output() contactFormShared = new EventEmitter<FormGroup>();

  public emitFormGeneral() {
    this.contactFormShared.emit(this.contactForm);
  }

  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public authSrv : AuthenticationService) { }


    ngOnInit(): void {

      this.contactForm.addControl('Contact',this.addContactForm());
      this.emitFormGeneral();
      this.loading = false;
    }

    onSubmit(){

    }


    get Email(){ return this.contactForm.get("Contact")!.get("Email"); }
    get Phone(){ return this.contactForm.get("Contact")!.get("Phone"); }
    get ContactName(){ return this.contactForm.get("Contact")!.get("ContactName"); }

    addContactForm() {
      return this.formBuilder.group({
        Email: new FormControl(this.user!.Email, [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
        ]),
        Phone: new FormControl(this.user!.Phone1, [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(20)
        ]),
        ContactName : new FormControl(this.user!.ContactName, [
          Validators.required,
          Validators.minLength(3)
        ])
      });
    }
}
