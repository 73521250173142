import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ExportChartsService } from 'src/app/services/export-charts.service';
import { GeneratingModalComponent } from 'src/app/modals/generating-modal/generating-modal.component';
import { Modal } from 'src/app/models/modal';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexYAxis,
  ApexFill,
  ApexMarkers,
  ApexStroke
} from "ng-apexcharts";
import { TranslateService } from '@ngx-translate/core';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  fill: ApexFill;
  stroke: ApexStroke;
  markers: ApexMarkers;
  colors: string[];
};

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
  providers: [DatePipe],
})
export class ReportComponent implements OnInit, OnDestroy {
  reportData: any = {};
  reportChart: any = {};
  date: Date | undefined;
  formatedDate: string | null = '';
  formatedHour: string | null = '';
  sensorsList: any = [];
  sensorsValues: any = [];
  max: any = [];
  min: any = [];
  med: any = [];


  constructor(
    private exportData: ExportChartsService,
    private exportChart: ExportChartsService,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.exportChart.destroy();
  }

  ngOnInit(): void {
    this.exportData.currentData.subscribe(data => this.reportData = data)
    this.exportChart.currentChart.subscribe(response => {
      if (response) {
        this.date = new Date();
        this.reportChart = response;
        this.sensorsList = this.reportData.sensor.map((obj: { name: any; }) => obj.name);
        this.sensorsValues = this.reportData.sensor.map((obj: { data: any; }) => obj.data.map((val: any) => val.y));
        for (let index = 0; index < this.sensorsValues.length; index++) {
          this.max.push(Math.max(...this.sensorsValues[index]));
          this.min.push(Math.min(...this.sensorsValues[index]));
          this.med.push(Math.round(this.sensorsValues[index].reduce((a: any,b: any) => Number(a) + Number(b), 0) / this.sensorsValues[index].length).toFixed(2));
        }
        if (this.reportData.download === true) {
          this.reportData.download = false;
          this.openPDF();
          this.openGeneratingPDF();
        }
      }
    });
  }

  public openGeneratingPDF(): void{
    let modal = new Modal();
    modal.Title = this.translate.instant('modules.modals.generating-report');
    modal.Body = this.translate.instant('modules.modals.generating-report-wait') + "...";
    modal.ClassType = "warning";
    modal.BtnAccept = this.translate.instant('modules.modals.continue');

    this.dialog.open(
      GeneratingModalComponent,
      {
        autoFocus : 'dialog',
        width: "400px",
        data: modal,
        closeOnNavigation: false,
        disableClose: true,
        panelClass: 'custom-modalbox',
        position: { top: '150px'}
      }
    );
    window.setTimeout(() => {
      this.dialog.closeAll();
    }, 5000)
  }

  public openPDF(): void {
    window.setTimeout(() => {

      let DATA: any = document.getElementById('htmlData');
      html2canvas(DATA, { scale: 3 }).then((canvas) => {
        let fileWidth = 208;
        let fileHeight = (canvas.height * fileWidth) / canvas.width;
        const FILEURI = canvas.toDataURL('image/png');
        let PDF = new jsPDF('p', 'mm', 'a4');
        let position = 0;
        PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
        PDF.save(this.reportData.nombre + this.datepipe.transform(this.date, "_HH-mm'h_'dd-MM-yyyy") + '.pdf');
      });
    }, 100)

  }

}
