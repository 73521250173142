<app-breadcrumb></app-breadcrumb>


<div class="container-fluid pt-3 pl-0 pr-0 espace">

  <app-loading-custom [loading]="loading"></app-loading-custom>


  <app-block-group-dashboard *ngIf="loading == false">

    <app-detail-device *ngIf="device" [pageInput]="page"  [idAlert]="idAlert"  [device]="device"></app-detail-device>

    <app-display-api-request-object [info]="resultApi"></app-display-api-request-object>

  </app-block-group-dashboard>

</div>


<app-footer></app-footer>
