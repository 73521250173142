
<app-loading-custom [loading]="loading"></app-loading-custom>


<ng-container *ngIf="loading == false && information">

  <form class="text-start p-2" [formGroup]="alarmForm" (ngSubmit)="onSubmit()" autocomplete="off" >
    <!-- <h2 class="text-center mt-3 mb-3">{{'modules.alarms.config-default-values' | translate}} {{ information!.DeviceModel?.Name??'' }}</h2> -->

    <ng-container formGroupName="Alarm" >
    <div class="form-group" *ngIf=" modelsList && modelsList.length > 1" >

<!--
  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Assignee</mat-label>
    <input type="text" matInput formControlName="DeviceModel" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let option of modelsList" [value]="option">
        {{option.Name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field> -->


      <ng-multiselect-dropdown [ngClass]="(showChangeModel==false)?'d-none':''"
        id="DeviceModel"
        formControlName="DeviceModel"
        [(ngModel)]="selectedModelsList"
        [placeholder]="'modules.shared.search-model' | translate"
        [settings]="dropdownSettingsFabricante"
        [data]="modelsList"
        >

        <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
          <div style="padding:1px 10px;margin-right:10px;right:2px;top:-2px;cursor: pointer !important;">
            <label *ngIf="authSrv.isManufacturer() == false" style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
              {{option}} | {{ getModel[id].Name??'' }}
            </label>
            <label *ngIf="authSrv.isManufacturer() == true" style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
              {{ getModel[id].Name??'' }}
            </label>
          </div>
        </ng-template>

        <ng-template #optionSelectedTemplate let-option="option" let-id="id">
          <div *ngIf="authSrv.isManufacturer() == false">
              {{option}} | {{ getModel[id].Name??'' }}
          </div>
          <div  *ngIf="authSrv.isManufacturer() == true">
              {{ getModel[id].Name??'' }}
          </div>
        </ng-template>
      </ng-multiselect-dropdown>

  </div>

  <div class="form-group" *ngIf="modelError == true" >

          <div class="alert alert-danger mt-3 text-start">
            <div>
              {{'modules.alarms.model-required' | translate}}.
            </div>

        </div>
  </div>

</ng-container>


      <!--
        <div class="row">
          <div class="form-group col-6">
              <label for="RPMMin"  class="sr-only">RPM</label>
              <input type="text" class="form-control  input-group-lg" id="RPMMin"
              formControlName="RPMMin" required [(ngModel)]="information!.AlarmValue!.RPMMin">

              <div *ngIf="RPMMin!.invalid && (RPMMin!.dirty || RPMMin!.touched)" class="alert alert-danger mt-3 text-start">
                  <div [hidden]="!RPMMin!.errors?.['required']">{{'modules.devices.rpm-min-required' | translate}}.</div>
                  <div [hidden]="!RPMMin!.errors?.['pattern']">{{'modules.devices.rpm-min-invalid' | translate}}.</div>
              </div>

            <input type="text" class="form-control  input-group-lg" id="RPMMax"
            formControlName="RPMMax" required [(ngModel)]="information!.AlarmValue!.RPMMax">

            <div *ngIf="RPMMax!.invalid && (RPMMax!.dirty || RPMMax!.touched)" class="alert alert-danger mt-3 text-start">
                <div [hidden]="!RPMMax!.errors?.['required']">{{'modules.devices.rpm-max-required' | translate}}.</div>
                <div [hidden]="!RPMMax!.errors?.['pattern']">{{'modules.devices.rpm-max-invalid' | translate}}.</div>
            </div>
        </div>
      </div>-->

      <app-form-range-default-values-alarm translation="{{'modules.alarms.rpm' | translate}}" name="RPM" [minValue]="information!.AlarmValue!.RPMMin!"  [maxValue]="information!.AlarmValue!.RPMMax!" (formShared)="updateRPMValid($event)" ></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm translation="{{'modules.alarms.horas-trabajo' | translate}}" name="Horas trabajo" tag="WorkingHours" [minValue]="information!.AlarmValue!.WorkingHoursMin!"  [maxValue]="information!.AlarmValue!.WorkingHoursMax!" (formShared)="updateWorkingHoursValid($event)"></app-form-range-default-values-alarm>

      <app-form-range-default-values-alarm translation="{{'modules.alarms.presion' | translate}}" name="Presión" tag="Pressure1" [minValue]="information!.AlarmValue!.Pressure1Min!"  [maxValue]="information!.AlarmValue!.Pressure1Max!" (formShared)="updatePressure1Valid($event)"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm translation="{{'modules.alarms.caudal' | translate}}" name="Caudal" tag="Pressure2" [minValue]="information!.AlarmValue!.Pressure2Min!"  [maxValue]="information!.AlarmValue!.Pressure2Max!" (formShared)="updatePressure2Valid($event)"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm translation="{{'modules.alarms.temp-aceite' | translate}}" name="Temp. aceite" tag="Flow1" [minValue]="information!.AlarmValue!.Flow1Min!"  [maxValue]="information!.AlarmValue!.Flow1Max!" (formShared)="updateFlow1Valid($event)"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm translation="{{'modules.alarms.nivel-aceite' | translate}}" name="Nivel aceite" tag="Flow2" [minValue]="information!.AlarmValue!.Flow2Min!"  [maxValue]="information!.AlarmValue!.Flow2Max!" (formShared)="updateFlow2Valid($event)"></app-form-range-default-values-alarm>

      <app-form-range-default-values-alarm translation="{{'modules.alarms.num-acciones' | translate}}" name="Nº acciones" tag="Action1" [minValue]="information!.AlarmValue!.Action1Min!"  [maxValue]="information!.AlarmValue!.Action1Max!" (formShared)="updateAction1Valid($event)"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm translation="{{'modules.alarms.peso' | translate}}" name="Peso" tag="Action2" [minValue]="information!.AlarmValue!.Action2Min!"  [maxValue]="information!.AlarmValue!.Action2Max!" (formShared)="updateAction2Valid($event)"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm translation="{{'modules.alarms.rpm-2' | translate}}" name="RPM 2" tag="Action3" [minValue]="information!.AlarmValue!.Action3Min!"  [maxValue]="information!.AlarmValue!.Action3Max!" (formShared)="updateAction3Valid($event)"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm translation="{{'modules.alarms.rpm-3' | translate}}" name="RPM 3" tag="Action4" [minValue]="information!.AlarmValue!.Action4Min!"  [maxValue]="information!.AlarmValue!.Action4Max!" (formShared)="updateAction4Valid($event)"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm translation="{{'modules.alarms.presion-2' | translate}}" name="Presión 2" tag="Action5" [minValue]="information!.AlarmValue!.Action5Min!"  [maxValue]="information!.AlarmValue!.Action5Max!" (formShared)="updateAction5Valid($event)"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm translation="{{'modules.alarms.presion-3' | translate}}" name="Presión 3" tag="Action6" [minValue]="information!.AlarmValue!.Action6Min!"  [maxValue]="information!.AlarmValue!.Action6Max!" (formShared)="updateAction6Valid($event)"></app-form-range-default-values-alarm>

      <app-form-range-default-values-alarm translation="{{'modules.alarms.caudal-2' | translate}}" name="Caudal 2" tag="Weight1" [minValue]="information!.AlarmValue!.Weight1Min!"  [maxValue]="information!.AlarmValue!.Weight1Max!" (formShared)="updateWeight1Valid($event)"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm translation="{{'modules.alarms.caudal-3' | translate}}" name="Caudal 3" tag="Weight2" [minValue]="information!.AlarmValue!.Weight2Min!"  [maxValue]="information!.AlarmValue!.Weight2Max!" (formShared)="updateWeight2Valid($event)"></app-form-range-default-values-alarm>

      <app-form-range-default-values-alarm translation="{{'modules.alarms.peso-2' | translate}}" name="Peso 2" tag="Aux1" [minValue]="information!.AlarmValue!.Aux1Min!"  [maxValue]="information!.AlarmValue!.Aux1Max!" (formShared)="updateAux1Valid($event)"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm translation="{{'modules.alarms.num-acciones-2' | translate}}" name="Nº acciones 2" tag="Aux2" [minValue]="information!.AlarmValue!.Aux2Min!"  [maxValue]="information!.AlarmValue!.Aux2Max!" (formShared)="updateAux2Valid($event)"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm translation="{{'modules.alarms.num-acciones-3' | translate}}" name="Nº acciones 3" tag="Aux3" [minValue]="information!.AlarmValue!.Aux3Min!"  [maxValue]="information!.AlarmValue!.Aux3Max!" (formShared)="updateAux3Valid($event)"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm translation="{{'modules.alarms.aux-1' | translate}}" name="Aux 1" tag="Aux4" [minValue]="information!.AlarmValue!.Aux4Min!"  [maxValue]="information!.AlarmValue!.Aux4Max!" (formShared)="updateAux4Valid($event)"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm translation="{{'modules.alarms.aux-2' | translate}}" name="Aux 2" tag="Aux5" [minValue]="information!.AlarmValue!.Aux5Min!"  [maxValue]="information!.AlarmValue!.Aux5Max!" (formShared)="updateAux5Valid($event)"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm translation="{{'modules.alarms.aux-3' | translate}}" name="Aux 3" tag="Aux6" [minValue]="information!.AlarmValue!.Aux6Min!"  [maxValue]="information!.AlarmValue!.Aux6Max!" (formShared)="updateAux6Valid($event)"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm translation="{{'modules.alarms.aux-4' | translate}}" name="Aux 4" tag="Aux7" [minValue]="information!.AlarmValue!.Aux7Min!"  [maxValue]="information!.AlarmValue!.Aux7Max!" (formShared)="updateAux7Valid($event)"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm translation="{{'modules.alarms.aux-5' | translate}}" name="Aux 5" tag="Aux8" [minValue]="information!.AlarmValue!.Aux8Min!"  [maxValue]="information!.AlarmValue!.Aux8Max!" (formShared)="updateAux8Valid($event)"></app-form-range-default-values-alarm>

<!--
  <table class="full-width" cellspacing="0">
    <tr>
      <td>
        RPM
      </td>
      <td>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>{{'modules.shared.min' | translate}}</mat-label>
          <input matInput>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>{{'modules.shared.max' | translate}}</mat-label>
          <input matInput>
        </mat-form-field>
      </td>
    </tr>
  </table>-->
<!--
  <button id="basic" type="button" mat-button (click)="showForm()">
    show
  </button>

  <p>rpmValuesAlarmForm Status: {{ rpmValuesAlarmForm.status }}</p>
  <p>workingHoursvaluesAlarmForm Status: {{ workingHoursValuesAlarmForm.status }}</p>
  <p>pressure1ValuesAlarmForm Status: {{ pressure1ValuesAlarmForm.status }}</p>
  <p>pressure2ValuesAlarmForm Status: {{ pressure2ValuesAlarmForm.status }}</p>
  <p>flow1ValuesAlarmForm Status: {{ flow1ValuesAlarmForm.status }}</p>
  <p>flow2ValuesAlarmForm Status: {{ flow2ValuesAlarmForm.status }}</p>
  <p>action1ValuesAlarmForm Status: {{ action1ValuesAlarmForm.status }}</p>
  <p>action2ValuesAlarmForm Status: {{ action2ValuesAlarmForm.status }}</p>
  <p>action3ValuesAlarmForm Status: {{ action3ValuesAlarmForm.status }}</p>
  <p>action4ValuesAlarmForm Status: {{ action4ValuesAlarmForm.status }}</p>
  <p>action5ValuesAlarmForm Status: {{ action5ValuesAlarmForm.status }}</p>
  <p>action6ValuesAlarmForm Status: {{ action6ValuesAlarmForm.status }}</p>
  <p>weight1ValuesAlarmForm Status: {{ weight1ValuesAlarmForm.status }}</p>
  <p>weight2ValuesAlarmForm Status: {{ weight2ValuesAlarmForm.status }}</p>
  <p>aux1ValuesAlarmForm Status: {{ aux1ValuesAlarmForm.status }}</p>
  <p>aux2ValuesAlarmForm Status: {{ aux2ValuesAlarmForm.status }}</p>
  <p>aux3ValuesAlarmForm Status: {{ aux3ValuesAlarmForm.status }}</p>
  <p>aux4ValuesAlarmForm Status: {{ aux4ValuesAlarmForm.status }}</p>
  <p>aux5ValuesAlarmForm Status: {{ aux5ValuesAlarmForm.status }}</p>
  <p>aux6ValuesAlarmForm Status: {{ aux6ValuesAlarmForm.status }}</p>
  <p>aux7ValuesAlarmForm Status: {{ aux7ValuesAlarmForm.status }}</p>
  <p>aux8ValuesAlarmForm Status: {{ aux8ValuesAlarmForm.status }}</p>

-->

  <section class="mt-2">
    <div class="text-right" style="flex-direction: row-reverse;">
        <button  mat-raised-button class="btn btn-primary" [disabled]="validAllForm && authSrv.isAdmin() ? null: 'disabled'"  (click)="onSubmit()">{{ (information.Id == 0)?('modules.shared.create' | translate): 'modules.alarms.apply-changes' | translate }}</button>
    </div>

    <!-- <div  class="btn btn-primary"  (click)="test()">test</div> -->

</section>

  </form>

</ng-container>
