import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { UtilsService } from './utils.service';
import { Register, User } from '../models/user';
import { ApiRequestObject } from '../models/apiRequestObject';
import { RememberPasswordToken } from '../models/rememberPasswordToken';
import { UserLogged } from '../models/userLogged';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject?: BehaviorSubject<UserLogged | null>;
  public currentUser?: Observable<UserLogged | null>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private utils: UtilsService,
    private translate: TranslateService,
  ) {
    if (localStorage.getItem('currentUser')) {
      this.currentUserSubject = new BehaviorSubject<UserLogged | null>(new UserLogged(JSON.parse(<string>localStorage.getItem('currentUser'))));
    } else {
      this.currentUserSubject = new BehaviorSubject<UserLogged | null>(null);
    }
    this.currentUser = this.currentUserSubject!.asObservable();
  }


  /**
   * Devuelve el usuario actual del servicio
   *
   * @readonly
   * @type {User}
   * @memberof AuthenticationService
   */
  public get currentUserValue(): UserLogged | null {
    return this.currentUserSubject!.value;
  }
  public get currentUserTo(): User | null {
    return <User>this.currentUserSubject!.value;
  }

  isAdmin(): boolean {
    // 1: Administrador
    if(this.currentUserValue && this.currentUserValue!.Payload){
      return (this.currentUserValue!.Payload!.role! ==  'admin') ? true : false;
    }
    return false;
  };
  isManufacturer(): boolean {
    // 1: Fabricant
    if(this.currentUserValue && this.currentUserValue!.Payload){
      return (this.currentUserValue!.Payload!.role! ==  'fabricant') ? true : false;
    }
    return false;

  };

  isClient(): boolean {
    // 1: Client
    if(this.currentUserValue && this.currentUserValue!.Payload){
      return (this.currentUserValue!.Payload!.role! ==  'user') ? true : false;
    }
    return false;

  };

  hasTripsModuleEnabled(): boolean{
    if(this.currentUserValue?.TripsModuleEnabled){
      return this.currentUserValue.TripsModuleEnabled;
    }

    return false;
  }

  hasDocManagerModuleEnabled(): boolean{
    if(this.currentUserValue?.DocManagerModuleEnabled){
      return this.currentUserValue.DocManagerModuleEnabled;
    }

    return false;
  }

  /**
   * Devuelve si el usuario actual está logado en el sistema
   *
   * @readonly
   * @type {boolean}
   * @memberof AuthenticationService
   */
  public get isLogedUser(): boolean {

    console.log('currentUserValue ', this.currentUserValue);

    if (this.currentUserValue) {
      let dNow = new Date();

      let dExp = new Date(this.currentUserValue.AlertExpirydate!);
      if (dExp.getTime() < dNow.getTime()){

        console.log('isLogedUser ', false);
        return false;
      }
    } else {
      console.log('isLogedUser ', false);
      return false;
    }
    console.log('isLogedUser ', true);
    return true;
  }
  /**
   * Ejecuta el login en el sistema
   *
   * @param {string} username
   * @param {string} password
   * @returns
   * @memberof AuthenticationService
   */

   public login(username: string, password: string): Observable< UserLogged > {

    //return this.http.post<User>( `${environment.API_ENDPOINT}login/authenticate`, { 'username': username, 'password': password }).pipe();
      return this.http.post<UserLogged>( `${environment.API_ENDPOINT}login/authenticate`,
      { 'username': username, 'password': password }
      ).pipe(
        catchError(this.error)
      );
  }
  public tokenFirstTime(token: string): Observable< boolean > {

   //return this.http.post<User>( `${environment.API_ENDPOINT}login/authenticate`, { 'username': username, 'password': password }).pipe();
     return this.http.get<boolean>( `${environment.API_ENDPOINT}login/${token}/isFirstTime`
     ).pipe(
       catchError(this.error)
     );
 }


  public changeNotification(add: boolean){
    const helper = new JwtHelperService();
    let user: UserLogged = <UserLogged>JSON.parse(localStorage.getItem('currentUser')!);

    if(add == true){
      user.NotificationsCount = user.NotificationsCount!+1;
    }else{
      if(user.NotificationsCount! > 0){
        user.NotificationsCount = user.NotificationsCount!-1;
      }
    }

    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject!.next(user);
  }
  public clearNotification(){
    const helper = new JwtHelperService();
    let user: UserLogged = <UserLogged>JSON.parse(localStorage.getItem('currentUser')!);

    user.NotificationsCount = 0;

    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject!.next(user);
  }

  public updateToken(user: UserLogged){
    const helper = new JwtHelperService();
    user.Payload = helper.decodeToken(user.JwtTokenString);
    localStorage.setItem('currentUser', JSON.stringify(user));
    localStorage.setItem('currentToken', user!.JwtTokenString!);
    this.currentUserSubject!.next(user);
  }

  getCurrentToken(): string | null {

    var token = localStorage.getItem('currentUser');
    //console.log("token " , token);
    return (token) ? <string>JSON.parse(token).JwtTokenString : null;
  };


  /**
   * Quita el login del sistema, pero sólo en local.
   *
   * @memberof AuthenticationService
   */
  logout() {

    localStorage.clear();
    localStorage.setItem('lang', this.translate.getDefaultLang());
    this.currentUserSubject!.next({});
    window.location.href = "/";
    //this.router.navigate(['/auth/login']);
    //console.log("Sesión terminada.");
  }

  clear() {

    localStorage.clear();
    localStorage.setItem('lang', this.translate.getDefaultLang());
    this.currentUserSubject!.next({});
  }


  // Generar token per resetejar password por email
  rememberPass(mail: string): Observable<RememberPasswordToken> {
    return this.http.post<RememberPasswordToken>(`${environment.API_ENDPOINT}login/rememberPass`, { 'Username' :  mail }).pipe(
      catchError(this.error)
    );
  }

  // Comprovar existeix token
  isValidToken(token: string) {
    return this.http.get<boolean>(`${environment.API_ENDPOINT}login/IsValidToken?token=${token}`).pipe();
  }

  // Camviar contrasenya by token
  changePassword(token: string, password: string): Observable<User> {
    let us: RememberPasswordToken;
    us = new RememberPasswordToken;
    us.Token = token;
    us.Username = password;
    us.Email = "";
    return this.http.post<User>(`${environment.API_ENDPOINT}login/changePassword`, us).pipe(
      catchError(this.error)
    );
  }


  // Error handling
  error(error: HttpErrorResponse) {

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);

    let res : ApiRequestObject = ApiRequestObject.error(errorMessage);

    return throwError(() => res);
  }

  verificaAutenticacion(online: boolean): Observable<boolean> {

    //console.log('isLogedUser ', this.isLogedUser);

    if(!online){
      return of(this.isLogedUser)
    }

    if( !this.isLogedUser ){
      return of(false);
    }

      return this.info().pipe(
        map( (resp: UserLogged ) => {
          console.log("verificaAutenticacion");
          console.log(resp);
          if(resp){
            resp.Email = this.currentUserValue?.Email??'';
            this.updateToken( resp );
            return true;
          }
          return false;
        })
      );
  }


  info(): Observable<UserLogged> {
    return this.http.get<UserLogged>(`${environment.API_ENDPOINT}user/info`).pipe();
  }

  loginAutenticacion(): Observable<boolean> {
    console.log("isLogedUser", this.isLogedUser);
    return of(this.isLogedUser)
  }

}
