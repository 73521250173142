import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { MaterialCdkModule } from '../material-cdk/material-cdk.module';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BlockDashboardComponent } from './components/block-dashboard/block-dashboard.component';
import { BlockGroupDashboardComponent } from './components/block-group-dashboard/block-group-dashboard.component';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingCustomComponent } from './components/loading-custom/loading-custom.component';
import { LottieModule } from 'ngx-lottie';
import { FileUploadComponentComponent } from './components/file-upload-component/file-upload-component.component';
import { UserIconComponent } from './components/user-icon/user-icon.component';
import { DisplayApiRequestObjectComponent } from './components/display-api-request-object/display-api-request-object.component';
import { GenericButtonComponent } from './components/generic-button/generic-button.component';
import { FileConfigUploadComponent } from './components/file-config-upload/file-config-upload.component';
import { TerminalTestComponent } from './components/terminal-test/terminal-test.component';
import { NgTerminalModule } from 'ng-terminal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RangeSliderDateComponent } from './components/range-slider-date/range-slider-date.component';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SideNavComponent,
    BlockDashboardComponent,
    BlockGroupDashboardComponent,
    BreadcrumbComponent,
    LoadingCustomComponent,
    FileUploadComponentComponent,
    UserIconComponent,
    DisplayApiRequestObjectComponent,
    GenericButtonComponent,
    FileConfigUploadComponent,
    TerminalTestComponent,
    RangeSliderDateComponent,
    LanguageSelectorComponent
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    MaterialCdkModule,
    RouterModule,
    NgTerminalModule,
    LottieModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule

  ],
  exports: [
    NgSelectModule,
    HeaderComponent,
    FooterComponent,
    SideNavComponent,
    BlockDashboardComponent,
    BlockGroupDashboardComponent,
    BreadcrumbComponent,
    LoadingCustomComponent,
    LottieModule,
    FileUploadComponentComponent,
    UserIconComponent,
    DisplayApiRequestObjectComponent,
    GenericButtonComponent,
    FileConfigUploadComponent,
    TerminalTestComponent,
    RangeSliderDateComponent,
    LanguageSelectorComponent,
    TranslateModule
  ]
})
export class SharedModule { }
