import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { ApiRequestObject, ListResult } from '../models/apiRequestObject';
import { User } from '../models/user';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { Trip } from '../models/trip';
import { Device, Information } from '../models/device';
import { UtilsService } from './utils.service';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class TripsService  extends ApiService {

  constructor(private http: HttpClient, router: Router, authSrv: AuthenticationService, utilSrv: UtilsService) {
    super(router, authSrv);
  }


  getActiveSensors(info: Array<Information>): Array<string>{

    let permitSensors: string[] = environment.sensorTag;
    let availableSensors: string[] = new Array<string>();

    const sensors = new Set<string>();
    for (const information of info) {
      for (const key in information) {
        if (information.hasOwnProperty(key) && permitSensors.includes(key) && information[key] !== null && information[key] !== undefined ) {
          sensors.add(key);
        }
      }
    }
    return Array.from(sensors);
    return [];
  }

  getAVG(info: Array<Information>, name: string): any {

    let permitSensors: string[] = environment.sensorTag;
    let hasExist = false;
    let result = 0;
    let items = 0;
    let max = 0;
    let min = 0;

    const sensors = new Set<string>();
    for (const information of info) {
      for (const key in information) {
        if (key == name && information.hasOwnProperty(key) && permitSensors.includes(key) && information[key] !== null && information[key] !== undefined ) {
          
          result += +information[key];
          items += 1;

          if(max<information[key] || hasExist == false){
            max = information[key];
          }
          if(min > information[key] || hasExist == false){
            min = information[key];
          }

          hasExist = true;
        }
      }
    }
    if(hasExist == false){
      return undefined;
    }
    return [ min, max , result / items ];
  }

  getDiff(info: Array<Information>, name: string): any {

    let permitSensors: string[] = environment.sensorTag;
    let hasExist = false;
    let max = 0;
    let min = 0;

    const sensors = new Set<string>();
    for (const information of info) {
      for (const key in information) {
        if (key == name && information.hasOwnProperty(key) && permitSensors.includes(key) && information[key] !== null && information[key] !== undefined ) {
          
          if(max<information[key]  || hasExist == false ){
            max = information[key];
          }
          if(min > information[key]  || hasExist == false ){
            min = information[key];
          }
          hasExist = true;
        }
      }
    }
    if(hasExist == false){
      return undefined;
    }
    return [ min, max , max-min ];
  }


  getList(page: number, offset: number, order: string, direction: string,  filter?: string, user?:User, device?:number , filterRangeDateFrom?: DateTime, filterRangeDateTo?: DateTime ) : Observable<ListResult> {

   
    let deviceFilter = ""
    if (device) {
      deviceFilter = "&DeviceId=" + device
    }

    let userFilter=""
    if(user){
      userFilter="&UserId="+user.Id
    }
    if(!filter){
      filter="";
    }

    let dateFilter=""
    if(filterRangeDateFrom && filterRangeDateTo){
      dateFilter=`&StartDate=${filterRangeDateFrom.toISODate()}&FinishDate=${filterRangeDateTo.toISODate()}`;
    }

    let url = `${environment.API_ENDPOINT}trips/list/${page}/${offset}/${order}/${direction}?filter=${filter}${deviceFilter}${userFilter}${dateFilter}`;

    if(device){
      url = `${environment.API_ENDPOINT}device/${device}/trips/list/${page}/${offset}/${order}/${direction}?filter=${filter}${deviceFilter}${userFilter}${dateFilter}`;
    }


    console.log('## URL: ' + url);


    return this.http.get<ListResult>(url).pipe(
       catchError(this.error)
    );
  }

  getById( idTrip?:number  ) : Observable<Trip> {   

    let url = `${environment.API_ENDPOINT}trips/${idTrip}`;

    return this.http.get<Trip>(url).pipe(
       catchError(this.error)
    );
  }

  getByIds( idTrip?:number, idsAdd?: Array<number>  ) : Observable<Trip> {   

    let url = `${environment.API_ENDPOINT}trips/${idTrip}`;
    let trips = '';
    idsAdd?.forEach( x=> {
      if(trips != ""){
        trips += "&"
      }
      trips += "AddTrip=" + x;
    });

    console.log(`${url}?${trips}`);

    return this.http.get<Trip>(`${url}?${trips}`).pipe(
       catchError(this.error)
    );
  }

  create(imei: string, start: DateTime, finish: DateTime) : Observable<Trip> {

    return this.http.post<Trip>(`${environment.API_ENDPOINT}trips/IMEI/${imei}?StartDate=${start}&FinishDate=${finish}`, null).pipe(
       catchError(this.error)
    );
  }
  analyze(imei: string, start: DateTime, finish: DateTime) : Observable<Trip> {

    return this.http.post<Trip>(`${environment.API_ENDPOINT}trips/analyze/${imei}?StartDate=${start}&FinishDate=${finish}`, null).pipe(
       catchError(this.error)
    );
  }

  deleteById(trip: Trip): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.API_ENDPOINT}trips/${trip.Id}`).pipe(
      catchError(this.error)
    );
  }

  splitById(trip: Trip, date: Date, dateString?: string): Observable<Trip> {

    let url = `${environment.API_ENDPOINT}trips/${trip.Id}/split?SplitDate=${date}`;
    if(dateString){
      url = `${environment.API_ENDPOINT}trips/${trip.Id}/split?SplitDate=${dateString}`;
    }
    return this.http.post<Trip>(url, null).pipe(
      catchError(this.error)
    );
  }

  joinById(trip: Trip, tripJoin: string): Observable<Trip> {

    let url = `${environment.API_ENDPOINT}trips/${trip.Id}/join/${tripJoin}`;
 
    return this.http.post<Trip>(url, null).pipe(
      catchError(this.error)
    );
  }

  
}