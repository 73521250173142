
<app-loading-custom [loading]="loading"></app-loading-custom>

<ng-container *ngIf="loading == false && user">

<form class="text-start p-2" [formGroup]="contactForm" (ngSubmit)="onSubmit()" autocomplete="off" >

<h2 class="text-center mt-3 mb-3">{{'modules.users.contact-info' | translate}}</h2>
<mat-card fxFlex fxLayoutAlign="start center" fxLayout="column" class="solution"
    [style.borderRadius.px]="'0'"  [style.paddingTop.px]="'20'" [style.marginBottom.px]="'20'" formGroupName="Contact">


    <div class="form-group">
        <label for="Email">{{'modules.tables.email2' | translate}}</label>
        <input type="text" class="form-control" id="Email"
        formControlName="Email" required [(ngModel)]="user!.Email">

        <div *ngIf="Email!.invalid && (Email!.dirty || Email!.touched)" class="alert alert-danger mt-3 text-start">
            <div [hidden]="!Email!.errors?.['required']">{{'modules.shared.email-required' | translate}}.</div>
            <div [hidden]="!Email!.errors?.['pattern']">{{'modules.shared.email-invalid' | translate}}.</div>
        </div>
    </div>

    <div class="form-group" >
        <label for="Phone">{{'modules.tables.phone' | translate}}</label>
        <input type="text" class="form-control" id="Phone"  formControlName="Phone" required [(ngModel)]="user!.Phone1">

        <div *ngIf="Phone!.invalid && (Phone!.dirty || Phone!.touched)" class="alert alert-danger mt-3 text-start">
            <div [hidden]="!Phone!.errors?.['required']">{{'modules.shared.phone-required' | translate}}.</div>
            <div [hidden]="!Phone!.errors?.['minlength']">{{'modules.shared.min' | translate}} {{ Phone!.errors?.['minlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
            <div [hidden]="!Phone!.errors?.['maxlength']">{{'modules.shared.max' | translate}} {{ Phone!.errors?.['maxlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
        </div>
    </div>

    <div class="form-group" >
        <label for="ContactName">{{'modules.users.contact-person' | translate}}</label>
        <input type="text" class="form-control" id="ContactName"  formControlName="ContactName" required [(ngModel)]="user!.ContactName">

        <div *ngIf="ContactName!.invalid && (ContactName!.dirty || ContactName!.touched)" class="alert alert-danger mt-3 text-start">
            <div [hidden]="!ContactName!.errors?.['required']">{{'modules.users.contact-person-required' | translate}} requerido</div>
            <div [hidden]="!ContactName!.errors?.['minlength']">{{'modules.shared.min' | translate}} {{ ContactName!.errors?.['minlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
        </div>
    </div>




</mat-card>

</form>

</ng-container>
