import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlarmDeviceModelDefaultValues, AlarmDeviceModelValues, AlarmDeviceValues, AlarmValue } from 'src/app/models/alarms';
import { ListResult } from 'src/app/models/apiRequestObject';
import { Device, vDevice } from 'src/app/models/device';
import { Manufacturer, Role, User } from 'src/app/models/user';
import { AlarmsService } from 'src/app/services/alarms.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { DevicesService } from 'src/app/services/devices.service';
import { UsersService } from 'src/app/services/users.services';

@Component({
  selector: 'app-detail-device-alarm-page',
  templateUrl: './detail-device-alarm-page.component.html',
  styleUrls: ['./detail-device-alarm-page.component.scss']
})
export class DetailDeviceAlarmPageComponent implements OnInit {

  showDeleteButton: boolean = false;

  id?: number;
  idDevice?: number;
  loading:boolean = true;
  information?: AlarmDeviceValues;
  manufacturerList?: Manufacturer[];
  title: string = "";
  selectedManufacturerList: Manufacturer[] = [];
  showChangeManufacturer = true;
  showChangeDevice = true;

  loadManufacturers = false;
  loadDefaultValues = false;

  loadComplete(){

    console.log("loadComplete", this.loadManufacturers, this.loadDefaultValues);
    if(this.loadManufacturers == true && this.loadDefaultValues == true){
      this.loading = false;
    }
  }

  constructor(private router: Router,
    private breadcrumbService: BreadcrumbService,
    public authSrv: AuthenticationService,
    private usersSrv: UsersService,
    private deviceSrv: DevicesService,
    private alarmrSrv : AlarmsService,
    private route: ActivatedRoute,
    private translate: TranslateService) {
      this.title = this.translate.instant('modules.alarms.management')
      breadcrumbService.setBreadCrumbUrl('alarms/list', this.title);

      this.route.params.subscribe(params => {
        this.id = params['id'];

        let auxId = params['idAlarm'];

        console.log(this.id)
        if(this.id && (auxId == null || (auxId != null && auxId != "add" ))){

          if(auxId){
            this.idDevice = this.id;
            this.id = auxId;
          }

          this.loadValues(this.id! );
          this.getManufacturers();



        }else{


          if(auxId){
            this.idDevice = this.id;
          }
          this.id = 0;
          console.log("idDevice", this.idDevice);

          this.information = new AlarmDeviceValues();
          this.information.Device = new Device();
          this.information.Id = 0;
          this.information.AlarmDeviceModelValues = new AlarmDeviceModelValues();
          this.information.AlarmDeviceModelValues.AlarmDeviceModelDefaultValues = new AlarmDeviceModelDefaultValues();
          this.information.AlarmDeviceModelValues.AlarmDeviceModelDefaultValues.defaultValues();

          this.information.AlarmValue =  new AlarmValue();
          this.information.AlarmValue.defaultValues();

          if(this.idDevice){

            this.loadDevice(this.idDevice);
          }else{
            this.title = this.translate.instant('modules.alarms.new-alarm-device');
            this.breadcrumbService.setBreadCrumbUrl('alarms/list', this.translate.instant('modules.alarms.management'));
            this.breadcrumbService.addBreadCrumb('alarms/add', this.title );

          }
          this.getManufacturers();

          this.loadDefaultValues = true;


          console.log(this.information);
        }

      });
    }



  ngOnInit(): void {
    this.loading = true;
  }

  getManufacturers(){
    this.loading = true;
    this.manufacturerList = undefined;
    this.usersSrv.getManufacturers(0, 10000, 'Name' , 'ASC' ).subscribe({
      next: (res: ListResult) => {

        if (this.authSrv.isAdmin()){
          this.manufacturerList = <Manufacturer[]>res.Result;
        }else if(this.authSrv.isManufacturer()){
          this.manufacturerList = <Manufacturer[]>res.Result.filter( (element: Manufacturer) => { return element.Id == this.authSrv.currentUserValue?.UserId });
          this.selectedManufacturerList?.push(this.manufacturerList[0]);
        }else{
          this.manufacturerList = <Manufacturer[]>res.Result;
        }
        console.log(this.manufacturerList);

      },error: (err: any) =>{
        console.log("error getClients");
        this.loadManufacturers = true;
        this.loadComplete();
      }, complete: () =>{
        this.loadManufacturers = true;
        this.loadComplete();

      }
    });

  }

  loadValues(id: number){
    this.loading = true;
    this.information = undefined;
    console.log('load information: ' , id );

    this.alarmrSrv.getDeviceValuesById(id).subscribe({
      next: (res: AlarmDeviceValues) => {
        this.information = new AlarmDeviceValues(<AlarmDeviceValues>res);

        this.information.User = new User( this.information.User );
        this.information.User.Role = new Role( this.information.User.Role );

        let imei = this.information!.Device!.IMEI??'';
        let nameDisp = this.information!.Device!.Name??this.translate.instant('modules.devices.no-name');
        let titulo = this.translate.instant('modules.devices.device') + ` ${nameDisp} | ${imei}`;
        if(this.idDevice){
          this.breadcrumbService.setBreadCrumbUrl('devices/list', this.translate.instant('modules.devices.management'));
          this.breadcrumbService.addBreadCrumb('devices/' + this.information!.Device!.Id + '/detail/alarmas' , titulo );
          this.title = this.translate.instant('modules.alarms.edit-alarm');
          this.breadcrumbService.addBreadCrumb('devices/' + this.information!.Device!.Id + '/alarms/' + this.information!.Id  ,this.title  );

          this.showChangeDevice = false;
        }else{

          this.breadcrumbService.setBreadCrumbUrl('alarms/list', this.translate.instant('modules.alarms.management'));
          this.title =  this.translate.instant('modules.alarms.alarm-for-device') + ' ' + nameDisp;
          this.breadcrumbService.addBreadCrumb('alarms/default/' + this.information!.Id  ,this.title  );
        }

        this.selectedManufacturerList?.push(res.Device.DeviceModel.Manufacturer);


        console.log(this.information?.Device.Id);
      }, error: ()=> {
        this.loadDefaultValues = true;
        this.loadComplete();

      },
      complete: ()=>{
        this.loadDefaultValues = true;
        this.loadComplete();

      }
    });

  }


  loadDevice(id: number){
    this.loading = true;
    console.log('load information: ' , id );

    this.deviceSrv.getDeviceById(id).subscribe({
      next: (res: Device) => {
        this.information!.Device = new Device(<Device>res);
        this.information!.IdDevice = this.information!.Device.Id;
        this.information!.AlarmDeviceModelValues.DeviceModel = this.information!.Device.DeviceModel;
        this.information!.AlarmDeviceModelValues.IdModel = this.information!.Device.DeviceModel.Id;


        this.breadcrumbService.setBreadCrumbUrl('devices/list', this.translate.instant('modules.devices.management'));

        let imei = this.information!.Device!.IMEI??'';
        let nameDisp = this.information!.Device!.Name??this.translate.instant('modules.devices.no-name');
        let titulo = this.translate.instant('modules.devices.device') + ` ${nameDisp} | ${imei}`;

        this.breadcrumbService.addBreadCrumb('devices/' + this.information!.Device!.Id + '/detail/alarmas' , titulo );

        this.title = this.translate.instant('modules.alarms.new-alarm-device');
        this.breadcrumbService.addBreadCrumb('devices/' + this.information!.Device!.Id + '/alarms/' + this.information!.Id  ,this.title  );

        this.selectedManufacturerList?.push(res.DeviceModel.Manufacturer);
        this.showChangeManufacturer = false;
        this.showChangeDevice = false;

        console.log(this.information?.Device.Id);
      }, error: ()=> {
        this.loadDefaultValues = true;
        this.loadComplete();

      },
      complete: ()=>{
        this.loadDefaultValues = true;
        this.loadComplete();

      }
    });

  }

  deleteAlarm(){
    console.log("deleteAlarm");
    this.alarmrSrv.deleteByDevice(this.information!).subscribe(
      {
        next: (res: boolean) => {
          if(res==true){
            if(this.idDevice){
              this.router.navigate(['/devices/' + this.idDevice + '/detail/alarmas']);
            }else{
              this.router.navigate(['/alarms/list']);
            }
          }
        }
      }
    )
  }

}
