import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Modal } from 'src/app/models/modal';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-user-add-quick',
  templateUrl: './user-add-quick.component.html',
  styleUrls: ['./user-add-quick.component.scss']
})
export class UserAddQuickComponent implements OnInit {

  userOutput: User = new User();
  rolUser: string | null = null;

  constructor(private dialogRef: MatDialogRef<UserAddQuickComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Modal) { 

      if(this.data!.Data == "client"){
        this.rolUser = "user";
      }else if(this.data!.Data == "fabricant"){
        this.rolUser = "fabricant";
      }

    }

    ngOnInit(): void {
    }

    emitUser(user: User){
      this.userOutput = user;
      this.dialogRef.close(user);
    }
  
    accept(){
      this.dialogRef.close();
    }
  
    cancel(){
      this.dialogRef.close(null);
    }

    onSubmit(){
      console.log(this.userOutput);
    }

}
