import { Position } from "./device";
import { User } from "./user";

export class Notification   {
  User?:          User;
  TrackPosition?: Position;
  Id?:            number;
  RealDate?:          Date;
  IMEI?:          string;
  Data?:          string;
  Value?:         string;
  Checksum?:      string;
  IdPosition?:    number;
  CreatedAt?:     Date;
  UpdatedAt?:     Date;
  Processed?:     boolean;
  IdUser?:        number;
  TypeAlarm?:     number;
  IdAlarm?:       number;
  Selected?:  boolean = false;
  IdDevice?:       number;
  IdDeviceModel?:       number;
  NameUser?:      string;
  IdRole?:       number;
  TagRole?:      string;


  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }
}
