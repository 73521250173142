import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[wheelMouse]'
})
export class WheelMouseDirective {

  constructor(private el: ElementRef) { }

  @HostListener('mousewheel', ['$event']) scroll(event: MouseEvent) {

    console.log("Entered mouse wheel");
    return false;

  }


}
