import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalComponent } from 'src/app/modals/confirmation-modal/confirmation-modal.component';
import { FileMediaAddQuickComponent } from 'src/app/modals/file-media-add-quick/file-media-add-quick.component';
import { FolderMediaAddQuickComponent } from 'src/app/modals/folder-media-add-quick/folder-media-add-quick.component';
import { ApiRequestObject, ListResult } from 'src/app/models/apiRequestObject';
import { DocManagerFile, DocManagerFolder } from 'src/app/models/docManager';
import { LinkModal, Modal } from 'src/app/models/modal';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { DocManagerService } from 'src/app/services/doc-manager.service';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-media',
  templateUrl: './list-media.component.html',
  styleUrls: ['./list-media.component.scss']
})
export class ListMediaComponent implements OnInit {

  @Input() listMedia: Array<DocManagerFolder> | undefined;
  @Input() idParent: number | undefined;
  public isLoading = false;
  loadingDelete: boolean = false;

  constructor(
    public dialog: MatDialog,
    private breadcrumbService: BreadcrumbService,
    private router : Router,
    private utilsSrv: UtilsService,
    public authSrv: AuthenticationService,
    private translate: TranslateService,
    private docMediaService: DocManagerService) { }

  ngOnInit(): void {

    
  }

  

  get idParentId (): string{
    return `parentAccordion_${ (this.idParent)?this.idParent!.toString():"0"}`;
  }

  newOrEditFile(parent: DocManagerFolder,  file?: DocManagerFile){

  
    const dialogRef = this.dialog.open(
      FileMediaAddQuickComponent,
      {
        id: 'FileMediaAddQuickComponent',
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: new Modal({ 'Data' : { ParentFolder: { ...parent },  ...file ,  eventOnSubmit: true }  }),
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe( (result?: DocManagerFile) => {
      if(result){
        console.log('result: ' + result.Name);

        console.log('folder: ' + result.Name);
        console.log('parent.Id: ' + parent.Id);
        if(file){
          
          file.DocManagerFolder = parent;

          let index = this.listMedia!.findIndex(r => { return r.Id == file.DocManagerFolder!.Id });
          console.log('index' , index);

        /*
          this.listMedia![index].Name = result.Name;
          this.listMedia![index].IsPublic = result.IsPublic;
          this.listMedia![index].IsVisible = result.IsVisible;
          this.listMedia![index].Role = result.Role;
          this.listMedia![index].IdRole = result.IdRole;
          this.listMedia![index].Users = [ ...result.Users! ];
        */
          const modifiedFolder = this.findAndModifyFileById(this.listMedia!, result);
          if (modifiedFolder) {
            console.log("Folder modified:", modifiedFolder);
          } else {
            console.log("Folder with ID", result.Id , "not found");
          }
          this.utilsSrv.infoModal('Documento modificado correctamente',undefined);

        }else{
          
          const modifiedFolder = this.findAndCreateFileById(this.listMedia!, result.DocManagerFolder! ,result);
          if (modifiedFolder) {
            console.log("File modified:", modifiedFolder);
          } else {
            console.log("Folder with ID", result.Id , "not found");
          }
          this.utilsSrv.infoModal('Documento creado correctamente',undefined);

        }
      }
       
    });
  }

  newOrEditFolder( parent: DocManagerFolder, isNew: boolean){

    const dialogRef = this.dialog.open(
      FolderMediaAddQuickComponent,
      {
        id: 'FolderMediaAddQuickComponent',
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: (isNew)?  new Modal({ 'Data' : { ParentFolder: { ...parent }, eventOnSubmit: true }}) : new Modal({ 'Data' : { ...parent, eventOnSubmit: true }}),
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe( (result?: DocManagerFolder) => {
      if(result){


        console.log('folder: ' + result.Name);
        console.log('parent.Id: ' + parent.Id);
        if(!isNew){

            let index = this.listMedia!.findIndex(r => { return r.Id == result.Id });
            console.log('index' , index);

          /*
            this.listMedia![index].Name = result.Name;
            this.listMedia![index].IsPublic = result.IsPublic;
            this.listMedia![index].IsVisible = result.IsVisible;
            this.listMedia![index].Role = result.Role;
            this.listMedia![index].IdRole = result.IdRole;
            this.listMedia![index].Users = [ ...result.Users! ];
          */
            const modifiedFolder = this.findAndModifyFolderById(this.listMedia!, result);
            if (modifiedFolder) {
              console.log("Folder modified:", modifiedFolder);
            } else {
              console.log("Folder with ID", result.Id , "not found");
            }
            this.utilsSrv.infoModal('Directorio modificado correctamente',undefined);
            // = { ...result};
            // this.listMedia![index].Name = result.Name;
        }else{      
          const modifiedFolder = this.findAndCreateFolderById(this.listMedia!, result);
          if (modifiedFolder) {
            console.log("Folder modified:", modifiedFolder);
          } else {
            console.log("Folder with ID", result.Id , "not found");
          }
          
          this.utilsSrv.infoModal('Directorio creado correctamente',undefined);      
        }

   

      }
    });

  }
  
  deleteItem(folder: DocManagerFolder){

    let modal = new Modal();
    modal.Title = this.translate.instant('modules.shared.warning');
    modal.Body = 'Se va a eliminar el directorio ' + folder.Name + ' y su contenido.' ;
    modal.BtnAccept = this.translate.instant('modules.shared.delete')
    modal.BtnCancel = this.translate.instant('modules.shared.cancel');
    modal.ClassType = "danger";

    const dialogRef = this.dialog.open(
      ConfirmationModalComponent,
      {
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: modal,
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe( (result: boolean) => {
      if(result){

        console.log("Folder:", folder)
        this.loadingDelete = true;

        this.docMediaService.delete(folder).subscribe({
          next: (res: boolean) => {
            if(res == true){
              this.utilsSrv.infoModal('Directorio eliminado correctamente',undefined);

              const modifiedFolder = this.findAndDeleteFolderById(this.listMedia!, folder);
              if (modifiedFolder) {
                console.log("Folder eliminado:", modifiedFolder);
              } else {
                console.log("Folder with ID", folder.Id , "not found");
              }

            }else{
              this.utilsSrv.infoModal('Problemas al eliminar el directorio',undefined);

            }
          },
          error: (err: ApiRequestObject) =>{

            if(err.status! == "MODAL"){
              this.utilsSrv.infoModal(err.msg!, undefined, err.title!);
              
            }
         

            this.loadingDelete = false;
          },
          complete: () =>{
            this.loadingDelete = false;
          }
        });



      }

    });

  }

  deleteItemFile(file: DocManagerFile, folder: DocManagerFolder){

    let modal = new Modal();
    file.DocManagerFolder = folder;
    modal.Title = this.translate.instant('modules.shared.warning');
    modal.Body = 'Se va a eliminar el documento ' + file.Name + '.' ;
    modal.BtnAccept = this.translate.instant('modules.shared.delete')
    modal.BtnCancel = this.translate.instant('modules.shared.cancel');
    modal.ClassType = "danger";

    const dialogRef = this.dialog.open(
      ConfirmationModalComponent,
      {
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: modal,
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe( (result: boolean) => {
      if(result){

        console.log("File:", file)
        this.loadingDelete = true;

        

        this.docMediaService.deleteFile(file).subscribe({
          next: (res: boolean) => {
            if(res == true){
              this.utilsSrv.infoModal('Documento eliminado correctamente',undefined);

              const modifiedFolder = this.findAndDeleteFileById(this.listMedia!, file);
              if (modifiedFolder) {
                console.log("Document eliminado:", modifiedFolder);
              } else {
                console.log("Folder with ID", file.Id , "not found");
              }

            }else{
              this.utilsSrv.infoModal('Problemas al eliminar el documento',undefined);

            }
          },
          error: (err: any) =>{
         
            this.loadingDelete = false;
          },
          complete: () =>{
            this.loadingDelete = false;
          }
        });



      }

    });

  }


  

  findAndDeleteFolderById(folders: DocManagerFolder[], target: DocManagerFolder): DocManagerFolder | undefined {
    for (const folder of folders) {
      const childIndex = folder.ChildFolders?.findIndex(child => child.Id === target.Id);
      // Check if child folder found in this folder's ChildFolders
      if (childIndex && childIndex !== -1) {
        // Remove the child folder from the array
        folder.ChildFolders!.splice(childIndex, 1);
        return folder; // Early return if found at the top level
      }
      // Recursive call for child folders
      const modifiedChild = this.findAndDeleteFolderById(folder.ChildFolders || [], target);
      if (modifiedChild) {
        return modifiedChild; // Early return if found in child folders
      }

      // If the folder contains the target ID, delete it (cascading deletion)
      if (folder.Id === target.Id) {
        // Delete associated DocManagerFiles (cascading delete)
        if (folder.Files) {
          for (const file of folder.Files) {
            // ... (delete the file using appropriate method)
          }
        }

        // Delete associated DocManagerFolderUser entries (cascading delete)
        if (folder.Users) {
          for (const folderUser of folder.Users) {
            // ... (delete the folderUser using appropriate method)
          }
        }

        // Delete child folders recursively (cascading delete)
        if (folder.ChildFolders) {
          for (const childFolder of folder.ChildFolders) {
            this.findAndDeleteFolderById(childFolder!.ChildFolders!, target); // Recursive call
          }
        }

        // Finally, delete the folder itself
        const folderIndex = folders.findIndex(f => f.Id === folder.Id);
        if (folderIndex !== -1) {
          folders.splice(folderIndex, 1);
        }

        return folder; // Early return if deleted (cascading)
      }
    }

    // Not found
    return undefined;
  }

  findAndModifyFolderById(folders: DocManagerFolder[], target: DocManagerFolder): DocManagerFolder | undefined {
    for (const folder of folders) {
      if (folder.Id === target.Id) {
        folder.Name = target.Name;
        folder.InternalName = target.InternalName;
        folder.IsPublic = target.IsPublic;
        folder.IsVisible = target.IsVisible;
        folder.Role = target.Role;
        folder.IdRole = target.IdRole;
        folder.Users = [ ...target.Users! ];
        return folder; // Early return if found at the top level
      }
  
      // Recursive call for child folders
      const modifiedChild = this.findAndModifyFolderById(folder.ChildFolders || [], target);
      if (modifiedChild) {
        return modifiedChild; // Early return if found in child folders
      }
    }
  
    // Not found
    return undefined;
  }

  findAndCreateFolderById(folders: DocManagerFolder[], target: DocManagerFolder): DocManagerFolder | undefined {
    for (const folder of folders) {
      if (folder.Id === target.ParentFolder!.Id) {
        let folderAdd = new DocManagerFolder();
        folderAdd.Id = target.Id;
        folderAdd.Name = target.Name;
        folderAdd.InternalName = target.InternalName;
        folderAdd.IsPublic = target.IsPublic;
        folderAdd.IsVisible = target.IsVisible;
        folderAdd.Role = target.Role;
        folderAdd.IdRole = target.IdRole;
        folderAdd.Users = [ ...target.Users! ];
        folderAdd.ChildFolders = [];
        folderAdd.Files= [];
        folder.ChildFolders?.push(folderAdd);
        return folder; // Early return if found at the top level
      }
  
      // Recursive call for child folders
      const modifiedChild = this.findAndCreateFolderById(folder.ChildFolders || [], target);
      if (modifiedChild) {
        return modifiedChild; // Early return if found in child folders
      }
    }
  
    // Not found
    return undefined;
  }

  
  findAndModifyFileById(folders: DocManagerFolder[], file: DocManagerFile): DocManagerFolder | undefined {
    for (const folder of folders) {
      if (folder.Files) {
        const fileIndex = folder.Files.findIndex(fileAux => fileAux.Id === file.Id);

        if (fileIndex !== -1) {
          folder.Files[fileIndex].Name = file.Name;
          folder.Files[fileIndex].InternalName = file.InternalName;
          folder.Files[fileIndex].IsPublic = file.IsPublic;
          folder.Files[fileIndex].IsVisible = file.IsVisible;
          folder.Files[fileIndex].Role = file.Role;
          folder.Files[fileIndex].IdRole = file.IdRole;
          folder.Files[fileIndex].Users = [ ...file.Users! ];

          return folder; // Early return if found at the top level
        } 
      }
  
      // Recursive call for child folders
      const modifiedChild = this.findAndModifyFileById(folder.ChildFolders || [], file);
      if (modifiedChild) {
        return modifiedChild; // Early return if found in child folders
      }
    }
  
    // Not found
    return undefined;
  }

  findAndDeleteFileById(folders: DocManagerFolder[], file: DocManagerFile): DocManagerFolder | undefined {

    
    for (const folder of folders) {
      const childIndex = folder.ChildFolders?.findIndex(child => child.Id === file.DocManagerFolder!.Id!);
      // Check if child folder found in this folder's ChildFolders
      if (childIndex && childIndex !== -1) {
        // Remove the child folder from the array
        const fileIndex = folder.ChildFolders![childIndex]!.Files!.findIndex(fileFind => fileFind.Id === file.Id);

        if (fileIndex !== -1) {
          console.log('fileIndex 1' , fileIndex);
          folder.ChildFolders![childIndex]!.Files!.splice(fileIndex, 1);
          
        } 
        return folder; // Early return if found at the top level
      }
      // Recursive call for child folders
      const modifiedChild = this.findAndDeleteFileById(folder.ChildFolders || [], file);
      if (modifiedChild) {
        return modifiedChild; // Early return if found in child folders
      }

      // If the folder contains the target ID, delete it (cascading deletion)
      if (folder.Id === file.DocManagerFolder!.Id!) {
        // Delete associated DocManagerFiles (cascading delete)
        if (folder.Files) {
          const fileIndex = folder.Files.findIndex(fileFind => fileFind.Id === file.Id);

          if (fileIndex !== -1) {
            console.log('fileIndex 2' , fileIndex);
            folder.Files.splice(fileIndex, 1);
            
          } 
          return folder; 
        }

        
        // Delete child folders recursively (cascading delete)
        if (folder.ChildFolders) {
          for (const childFolder of folder.ChildFolders) {
            this.findAndDeleteFileById(childFolder!.ChildFolders! || [], file); // Recursive call
          }
        }

        // Finally, delete the folder itself
        const folderIndex = folders.findIndex(f => f.Id === file.DocManagerFolder!.Id!);
        if (folderIndex !== -1) {

          const fileIndex = folder.ChildFolders![folderIndex]!.Files!.findIndex(fileFind => fileFind.Id === file.Id);

          if (fileIndex !== -1) {
            console.log('fileIndex 3' , fileIndex);
            folder.ChildFolders![folderIndex]!.Files!.splice(fileIndex, 1);
            
          }  
        }

        return folder; // Early return if deleted (cascading)
        
      }
    }

    // Not found
    return undefined;



  }

  
  findAndCreateFileById(folders: DocManagerFolder[], target: DocManagerFolder, file: DocManagerFile): DocManagerFolder | undefined {
    for (const folder of folders) {
      if (folder.Id === target.Id) {
        folder.Files?.push(file); 
        return folder; // Early return if found at the top level
      }
  
      // Recursive call for child folders
      const modifiedChild = this.findAndCreateFileById(folder.ChildFolders || [], target, file);
      if (modifiedChild) {
        return modifiedChild; // Early return if found in child folders
      }
    }
  
    // Not found
    return undefined;
  }


  download(file: DocManagerFile){
    // let url = `${environment.BASE_URL}/DocManager/${file.IdFolder??'public'}/${file.Path}`;
    // console.log('url' , url)
    // window.open(url, "_blank");
    
    // Get file token for generate unique url
    this.docMediaService.getFileToken(file).subscribe ( token =>{
      const link = document.createElement("a");
      link.href = `${this.docMediaService.getFileUrl(file, token)}`;      
      link.target = "_blank_";
      link.click();
    });
  }


}
