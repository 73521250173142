import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EmailService } from 'src/app/services/email.service';
import { UsersService } from 'src/app/services/users.services';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  @Input() user?: User;
  @Input() page?: number;
  userEdit?: User;
  loading:boolean = true;

  editForm: FormGroup = this.formBuilder.group({
  });
  @Output() editFormShared = new EventEmitter<FormGroup>();

  private emitFormGeneral() {
    this.editFormShared.emit(this.editForm);
  }

  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public authSrv : AuthenticationService,
    private emailSrv: EmailService,
    private usersSrv : UsersService,
    private utilsSrv : UtilsService) {
    }

  ngOnInit(): void {

    this.userEdit = this.user;

    //if(this.user && this.user.Role){
      this.changeForm();
      if(this.user?.isClient){
        this.changeCompany();
      }
      this.emitFormGeneral();
      this.loading = false;
    //}
  }

  updateParen(){

  }

  onSubmit(){
    console.log(this.userEdit);
    console.log(this.Name!.errors?.['minlength']?.requiredLength);
    console.log(this.Name!.errors?.['maxlength']?.requiredLength);
    console.log(this.editForm!.errors);
  }



  get Name(){ return this.editForm.get("User")!.get("Name"); }
  get FirstName(){ return this.editForm.get("User")!.get("FirstName"); }
  get LastName(){ return this.editForm.get("User")!.get("LastName"); }
  get WorkerCode(){ return this.editForm.get("User")!.get("WorkerCode");   }
  get TypeDocument(){ return this.editForm.get("User")!.get("TypeDocument")}
  get DNICIF(){ return this.editForm.get("User")!.get("DNICIF")}
  //get CreditCard(){ return this.editForm.get("User")!.get("CreditCard")}
  get IsSeller(){ return this.editForm.get("User")!.get("IsSeller"); }

  changeForm(){
    console.log('changeForm');

    this.editForm.removeControl('User');

    if(this.user!.Role!.Tag! == 'user' && ! this.authSrv.isManufacturer()){
      this.editForm.addControl('User',this.addUserForm());
    }else if(this.user!.Role!.Tag! == 'user' && this.authSrv.isManufacturer() ){
      this.editForm.addControl('User',this.addUserFabricantForm());
    }else if(this.user!.Role!.Tag! == 'admin'){
      this.editForm.addControl('User',this.addAdminForm());
    }else if(this.user!.Role!.Tag! == 'fabricant'){
      this.editForm.addControl('User',this.addFabricantForm());
    }
  }

  formControlName(){
    return new FormControl(this.user!.Name, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(35)
    ]);
  }
  formControlFirstName(){
    return new FormControl(this.user!.FirstName, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50)
    ]);
  }
  formControlLastName(){
    new FormControl(this.user!.LastName, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50)
    ])
  }

  formControlDNICIF(){
    return new FormControl(this.user!.DNICIF, [
      Validators.required,
      Validators.minLength(9),
      Validators.maxLength(15)
    ])
  }

  addUserForm() {
    return this.formBuilder.group({
      DNICIF:  this.formControlDNICIF(),
      TypeDocument:  new FormControl(this.user!.TypeDocument, [
        Validators.required
      ]),
      IsCompany: new FormControl(this.user!.IsCompany, [
        Validators.required
      ]),
      /*CreditCard: new FormControl(this.user!.CreditCard, [
        Validators.required
      ]),*/
      Name: this.formControlName(),
      FirstName: this.formControlFirstName(),
      LastName: this.formControlLastName(),
      IsSeller: new FormControl(this.user!.IsSeller, [
        Validators.required
      ]),
    });
  }

  addUserFabricantForm() {
    return this.formBuilder.group({
      DNICIF:  this.formControlDNICIF(),
      TypeDocument:  new FormControl(this.user!.TypeDocument, [
        Validators.required
      ]),
      IsCompany: new FormControl(this.user!.IsCompany, [
        Validators.required
      ]),
      Name: this.formControlName(),
      FirstName: this.formControlFirstName(),
      LastName: this.formControlLastName(),
      IsSeller: new FormControl(this.user!.IsSeller, [
        Validators.required
      ]),
    });
  }

  addFabricantForm() {
    return this.formBuilder.group({
      Name: this.formControlName(),
      DNICIF:  this.formControlDNICIF(),
      TypeDocument:  new FormControl(this.user!.TypeDocument, [
        Validators.required
      ]),
    });
  }

  addAdminForm() {
    return this.formBuilder.group({
      Name: this.formControlName(),
      FirstName: this.formControlFirstName(),
      LastName: this.formControlLastName(),
      WorkerCode: new FormControl(this.user!.WorkerCode, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(15)
      ])
    });
  }

  changeCompany(){

    console.log("changeCompany " , this.user!.IsCompany);
    if(this.user!.IsCompany == true){

      console.log("validators null");
      this.editForm.get("User")!.get("FirstName")!.setValidators(null);
      this.editForm.get("User")!.get("LastName")!.setValidators(null);
      this.editForm.get("User")!.get("FirstName")!.updateValueAndValidity();
      this.editForm.get("User")!.get("LastName")!.updateValueAndValidity();
    }else{

      console.log("validators in");

      this.editForm.get("User")!.get("FirstName")!.setValidators([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ]);
      this.editForm.get("User")!.get("LastName")!.setValidators([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ]);

      this.editForm.get("User")?.get("Name")!.updateValueAndValidity();
      this.editForm.get("User")?.get("FirstName")!.updateValueAndValidity();
      this.editForm.get("User")?.get("LastName")!.updateValueAndValidity();

    }
  }


}
