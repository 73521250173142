import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDecimal'
})
export class CustomDecimalPipe implements PipeTransform {

  transform(value: number | string, locale?: string): string {

    if(value == undefined)
    {
        return '';
    }
    let n = value.toString().split(".");

    if(n.length == 1){
      return this.ponerPuntos(n[0]);
    }

    let res = "";

    let decimal = 0;

    if(n[1]){
      decimal = +n[1];
    }


    if(decimal == 0){
      res = this.ponerPuntos(n[0])
    }else{

      let ff = true;
      for(let i = n[1].length -1; i > 0; i--){
        if(n[1][i] != "0"){
          ff = false;
        }
        if(ff == true){
          n[1] = n[1].substring(0, n.length -1)
        }
      }

      res = this.ponerPuntos(n[0])  + "," + n[1]

    }



    return res;
  }


  ponerPuntos(n: string){
    let newN = "";
    let isNegative: boolean = false;

    if(n[0] == "-"){
      isNegative = true;
      n = n.substring(1, n.length)
    }

    if(n.toString().length > 3){

      let iAux = 0;
      for(let i= n.toString().length; i > 0; i--){

        iAux++;
        newN = n.toString()[i-1] + newN;

        if(iAux == 3){
          newN = "." + newN;
          iAux = 0;
        }
        console.log(newN);

      }

    }


    if(n[0] == "."){
      n = n.substring(1, n.length)
    }
    if(newN[0] == "."){
      newN = newN.substring(1, newN.length)
    }

    if(isNegative){
      return newN==""?"-" + n: "-" + newN;
    }

    return newN==""?n:newN;
  }
}
