<app-loading-custom [loading]="loading"></app-loading-custom>

<ng-container *ngIf="loading == false">

    <form class="text-start p-2" [formGroup]="modelForm" (ngSubmit)="onSubmit()" autocomplete="off">

        <mat-card fxFlex fxLayoutAlign="start center" fxLayout="column" class="solution"
            *ngIf="authSrv.isAdmin() == true || authSrv.isManufacturer() == true" [style.borderRadius.px]="'0'" [style.paddingTop.px]="'20'"
            [style.marginBottom.px]="'20'" formGroupName="Model">


            <div class="form-group" [ngClass]="editDeviceModel.Name==nameDeviceModelMain?'d-none':''">
                <label for="Name">{{'modules.devices.machine-name' | translate}}</label>
                <input type="text" class="form-control" id="Name"  formControlName="Name" required [(ngModel)]="editDeviceModel.Name">

                    <!-- <div [hidden]="Name!.valid || Name!.pristine" class="alert alert-danger">
                        {{'modules.shared.name-required' | translate}}.
                    </div> -->
                    <div *ngIf="Name!.invalid && (Name!.dirty || Name!.touched)" class="alert alert-danger mt-3 text-start">
                        <div *ngIf="Name!.errors?.['required']">
                            {{'modules.shared.name-required' | translate}}.
                        </div>
                        <div *ngIf="Name!.errors?.['minlength']">
                            {{'modules.shared.min-characters' | translate}}.
                        </div>
                        <div *ngIf="Name!.errors?.['maxlength']">
                            {{'modules.shared.max-characters' | translate}}.
                        </div>
                    </div>
            </div>

            <div class="form-group" *ngIf="manufacturerList  &&
            (userInput == null || userInput!.isAdmin || userInput!.isManufacturer) && authSrv.isManufacturer() == false">
                <label for="Manufacturer" class="text-right-label">{{'modules.tables.account' | translate}} </label>

                <ng-multiselect-dropdown
                  id="Manufacturer"
                  formControlName="Manufacturer"
                  [(ngModel)]="editDeviceModel!.Manufacturer"
                  [placeholder]="'modules.shared.search-account' | translate"
                  [settings]="dropdownSettingsFabricante"
                  [data]="manufacturerList"
                  >

                  <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
                    <div style="padding:1px 10px;margin-right:10px;right:2px;top:-2px;cursor: pointer !important;">
                      <label style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
                        {{option}}
                      </label>
                    </div>
                  </ng-template>

                  <ng-template #optionSelectedTemplate let-option="option" let-id="id">
                    <div>
                        {{option}}
                    </div>
                  </ng-template>
                </ng-multiselect-dropdown>

            </div>


            <div class="form-group with-icon mt-3 mb-3 c-pointer color-link" *ngIf="editDeviceModel && editDeviceModel!.IdManufacturer != null  && userInput == null" (click)="modalFabricante()">
                <mat-icon>person_search</mat-icon> {{'modules.account.view-info' | translate}}
            </div>



        </mat-card>



<section class="mt-2">
    <div class="text-right" style="flex-direction: row-reverse;">

      <button  mat-raised-button class="btn btn-primary " [disabled]="!modelForm.get('Model')?.valid" (click)="onSubmit()">{{'modules.shared.save' | translate}}</button>
      <button *ngIf="showButtonCancel" mat-raised-button class="btn btn-danger" type="button" style="margin-right: 10px;" (click)="cancel()">{{'modules.shared.cancel' | translate}}</button>

    </div>
</section>

    </form>

</ng-container>
