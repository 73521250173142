
  <div *ngIf="showTitle">
    <h1>{{'modules.shared.chart' | translate}} {{ device?.Name??'~' }} {{'modules.shared.with' | translate}} IMEI: {{ device?.IMEI }}</h1>
    <h2>{{'modules.tables.account' | translate}}: {{ device?.Manufacturer.Name }} - {{'modules.alarms.model' | translate}}: {{ device?.DeviceModel.Name }} - {{'modules.users.user' | translate}}: {{ device?.IdUser??'~' }}</h2>
  </div>

<div *ngIf="loading == false && (noData == false || ( noData == true && this.device?.InformationFirst != null))" class="grid-container">


  <form class="text-start p-2" [formGroup]="filterChartForm"  autocomplete="off" >
  <!-- flex item: block child -->

    <div>



      <div class="mr-2 ml-2 pl-3">
        <button mat-fab class="my-fab" aria-label="Filtrar" (click)="reloadInfo()">
          <mat-icon>search</mat-icon>
        </button>
      </div>






  <div class="mr-2 pl-3">


    <mat-form-field class="form-field" appearance="fill">
        <mat-label>{{'modules.shared.time-frame' | translate}}</mat-label>
        <mat-date-range-input
    [rangePicker]="campaignOnePicker"
    [comparisonStart]="InformationFirstValue"
    [comparisonEnd]="InformationLastValue">
    <input matStartDate placeholder="{{'modules.shared.start-data' | translate}}" formControlName="InformationFirst" [(ngModel)]="InformationFirstValue" >
    <input matEndDate placeholder="{{'modules.shared.end-data' | translate}}" formControlName="InformationLast" [(ngModel)]="InformationLastValue" >
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
  <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
  </mat-form-field>
  </div>
</div>

<div class="mr-2 ml-2 pl-3 pt-2" style="min-width: 250px;" >
  <ng-multiselect-dropdown
    id="Sensors"
    formControlName="Sensors"
    [(ngModel)]="listSensorView"
    [placeholder]="'modules.shared.sensors' | translate"
    [settings]="dropdownSensor"
    [data]="listSensor"

    >

    <!-- <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
      <div style="padding:1px 10px;margin-right:10px;right:2px;top:-2px;cursor: pointer !important;">
        <label style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
          {{option}}
        </label>
      </div>
    </ng-template>

    <ng-template #optionSelectedTemplate let-option="option" let-id="id">
      <div>
        {{option}}
      </div>
    </ng-template> -->
  </ng-multiselect-dropdown>
</div>

</form>
</div>

<div style="display: block;" style="height: 400px" *ngIf="loading == false && noData == false" >

  <canvas #mainChart id="mainChart"  baseChart width="400" height="400"
  [data]="lineChartData"
  [options]="lineChartOptions"
  [type]="lineChartType"
  (mousewheel)="chartZoom($event )"
  (chartHover)="chartHovered($event)"
  (chartClick)="chartClicked($event)"></canvas>

</div>

<div style="display: block;" style="height: 100px" *ngIf="loading == false && noData == false" >

  <canvas #timelineChart id="timelineChart"  baseChart width="400" height="100"
  [data]="lineChartDataTimeline"
  [options]="lineChartOptionsTimeline"
  (chartHover)="chartTimeHovered($event)"
  [type]="lineChartType"></canvas>

</div>

<button (click)="zoomin()">zoom in</button>
<button (click)="zoomout()">zoom out</button>

<div *ngIf="loading == false && noData == true">


  {{'modules.shared.no-info-chart' | translate}}.
</div>
