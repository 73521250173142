import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Modal } from 'src/app/models/modal';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {


  constructor(
    private dialogRef: MatDialogRef<InfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Modal,
    private route: ActivatedRoute,
    private router: Router) { }



  ngOnInit(): void {
  }

  accept(){
    this.dialogRef.close(true);
  }

  cancel(){
    this.dialogRef.close(false);
  }

  goToLink(){
    
    this.router.navigate([this.data.Link!.Link!]);
    this.dialogRef.close(false);
    //window.location.href = this.data.Link!.Link!;
  }

}
