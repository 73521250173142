import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {


  constructor(private breadcrumbService : BreadcrumbService) { }

  get breadcrumb(): any{
    return this.breadcrumbService.getBreadCrumb();
  }
  ngOnInit(): void {
  }

}
