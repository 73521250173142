<mat-toolbar color="{{ data.ClassType}}"  class="bg-gradient-custom">
  <mat-toolbar-row>
    <span>{{ data.Title }}</span>
    <span class="example-spacer"></span>
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="cancel()" >
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
  </mat-toolbar>

<!-- <h2 mat-dialog-title class="bg-{{ data.ClassType}}">{{ data.Title }}</h2> -->
<div>

  <div class="container">

    <div  class="row">
      <div class="col-12">
        {{ data.Body }}
      </div>


    </div>


    <div class="d-block text-center" *ngIf="commandsList">
      {{'modules.modals.commands-detected' | translate}}: {{ commandsList!.length }}
    </div>

    <app-file-config-upload [typeForm]="typeForm" [textInput]="textInput" (waitUploadShared)="updateWaitUpload($event)" (commands)="updateCommandsNewFile($event)" [user]="data.User!"></app-file-config-upload>

  <div class="row">
    <div class="col-6">

      <button class="btn btn-secondary btn-block my-4 w-100 mb-0" type="submit" (click)="cancel()" >
        {{ data.BtnCancel | translate }}
      </button>

    </div>
    <div class="col-6">
      <button class="btn btn-primary btn-block my-4 w-100 mb-0" type="submit" (click)="accept()"  [disabled]="waitUpload || (commandsList == null)? 'disabled': null"  >
        {{ data.BtnAccept | translate }}
      </button>


    </div>

  </div>


</div>

</div>
