import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-range-slider-date',
  templateUrl: './range-slider-date.component.html',
  styleUrls: ['./range-slider-date.component.scss']
})
export class RangeSliderDateComponent implements OnInit {

  
  start?: Date;
  end?: Date;
  rangeBar?: ElementRef;

  constructor(private element: ElementRef, ) { }

  ngOnInit() {
    this.start = new Date('2023-01-01');
    this.end = new Date('2023-12-31');
    //this.rangeBar = this.element.querySelector('.range-slider-bar');
  }

  onRangeBarDrag(event: any) {
    //const width = event.target.offsetWidth;
    //this.rangeBar.style.width = width + 'px';
  }

}
