import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { Information, Position } from 'src/app/models/device';
import { DevicesService } from 'src/app/services/devices.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-simulate-trace',
  templateUrl: './simulate-trace.component.html',
  styleUrls: ['./simulate-trace.component.scss']
})



export class SimulateTraceComponent implements OnInit {

  simulateTrace: Information = new Information({'IMEI' : '357796107933626'});
  sensor?: string = "RPM";
  value?: number = 150;

  nameSensors: string[] = [];

  loading:boolean = true;
  traceForm: FormGroup = this.formBuilder.group({
  });


  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private deviceSrv: DevicesService,
    private utilsSrv: UtilsService,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<Date>,) {
      this.dateAdapter.setLocale('es-ES');

    this.nameSensors = utilsSrv.getPermitNameSensors();
  }

  ngOnInit(): void {
    this.simulateTrace.TrackPosition = new Position();
    this.simulateTrace.TrackPosition.Date = DateTime.now().toJSDate();
    this.traceForm.addControl('Trace',this.addTraceForm());
    this.loading = false;
  }

  sendTrace(){
    console.log("sendTrace()");
    console.log(this.traceForm);

    this.loading = true;

    let aux = 0;
    if(this.nameSensors!.includes(this.sensor!)){
      aux++;
    }
    if(aux == 0){
      this.utilsSrv.infoModal(this.translate.instant('modules.modals.sensor-not-recognized') + " " + this.sensor! + ". " + this.translate.instant('modules.modals.available-sensors') + ": " + this.nameSensors.join(", ") + '.' , undefined, this.translate.instant('modules.shared.warning'));

      this.loading = false;
      return;
    }

    this.nameSensors.forEach( x => {
      this.simulateTrace[x] = null;
    })

    this.simulateTrace[this.sensor!] = this.value;



    this.deviceSrv.traceSimulate(this.simulateTrace!).subscribe(
        {
          next: (res: boolean) => {
            if(res == true){
              this.utilsSrv.infoModal(this.translate.instant('modules.alarms.simulate-trace-success') + '.', undefined, this.translate.instant('modules.modals.info'));
            }else{
              this.utilsSrv.infoModal(this.translate.instant('modules.alarms.simulate-trace-error') + '.', undefined, this.translate.instant('modules.shared.warning'));
            }
          },
          error: (err: any) =>{
            if(err.status! == "MODAL"){
              this.utilsSrv.infoModal(err.msg!, undefined, this.translate.instant('modules.shared.warning'));
            }else{
              this.utilsSrv.infoModal(this.translate.instant('modules.alarms.simulate-trace-error') + '.', undefined, this.translate.instant('modules.shared.warning'));

            }
            this.loading = false;
          },
          complete: ()  => {
            this.loading = false;
          }
      });
  }



  get RealDate(){ return this.traceForm.get("Trace")!.get("RealDate"); }
  get Sensor(){ return this.traceForm.get("Trace")!.get("Sensor"); }
  get Value(){ return this.traceForm.get("Trace")!.get("Value"); }
  get IMEI(){ return this.traceForm.get("Trace")!.get("IMEI"); }

  get IsValidForm(){
    return this.traceForm.valid;
  }

  addTraceForm() {

    return this.formBuilder.group({
      RealDate: new FormControl(this.simulateTrace!.TrackPosition!.Date, [
        Validators.required
      ]),
      IMEI: new FormControl(this.simulateTrace!.IMEI, [
        Validators.required
      ]),
        Sensor: new FormControl(this.sensor, [
          Validators.required
        ]),
        Value: new FormControl(this.value, [
          Validators.required
        ])
      });

  }

}

