
<app-loading-custom [loading]="loading"></app-loading-custom>

<app-loading-custom [loading]="loadingPassword"></app-loading-custom>


<ng-container *ngIf="loading == false && user">

<form class="text-start p-2" [formGroup]="specialForm" (ngSubmit)="onSubmit()" autocomplete="off" >

<h2 class="text-center  mt-3 mb-3">{{'modules.users.more-info' | translate}}</h2>
<mat-card fxFlex fxLayoutAlign="start center" fxLayout="column" class="solution"
    [style.borderRadius.px]="'0'"  [style.paddingTop.px]="'20'" [style.marginBottom.px]="'20'" formGroupName="Special">

    <div class="form-group" *ngIf="authSrv.isAdmin() == true || authSrv.isManufacturer()">
        <label for="Active">{{'modules.users.web-permission' | translate}}</label>
        <div style="display:inline-block; width: 20px;"></div>
        <mat-slide-toggle  color="primary" formControlName="Active" [(ngModel)]="user!.Active" ></mat-slide-toggle>

        <div  style="display:inline-block; padding-left: 20px;">

          <app-generic-button txtBtn="{{'modules.users.welcome-email' | translate}}" title="{{'modules.shared.warning' | translate}}" txt="{{'modules.users.send-welcome-email' | translate}}?"
              [disabled]="user!.Active? null: 'disabled'"    btnAccept="{{'modules.shared.send' | translate}}" (confirmDelete)="sendEmail()" ></app-generic-button>

        </div>
    </div>

    <div class="form-group" *ngIf="authSrv.isAdmin() == true  ">
        <label for="Active">{{'modules.users.trips-module' | translate}}</label>
        <div style="display:inline-block; width: 20px;"></div>
        <mat-slide-toggle  color="primary" formControlName="TripsModuleEnabled" [(ngModel)]="user!.TripsModuleEnabled" ></mat-slide-toggle>
    </div> 
    
    <div class="form-group" *ngIf="authSrv.isAdmin() == true  ">
        <label for="Active">Modulo gestor documental</label>
        <div style="display:inline-block; width: 20px;"></div>
        <mat-slide-toggle  color="primary" formControlName="DocManagerModuleEnabled" [(ngModel)]="user!.DocManagerModuleEnabled" ></mat-slide-toggle>
    </div> 


    <div class="form-group text-start" *ngIf="user!.isClient">
        <label for="IsSeller">{{'modules.users.is-seller' | translate}}?</label>

        <div class="container">
            <div class="pretty p-icon p-round">
                <input type="radio" formControlName="IsSeller"  [value]="true"  required [(ngModel)]="user.IsSeller"
                />
                <div class="state p-success  text-start">
                    <i class="icon mdi mdi-check"></i>
                    <label>{{'modules.shared.yes' | translate}}</label>
                </div>
            </div>

            <div class="pretty p-icon p-round">
                <input type="radio" formControlName="IsSeller" [value]="false"  required [(ngModel)]="user.IsSeller"
               />
                <div class="state p-danger">
                    <i class="icon mdi mdi-check"></i>
                    <label>{{'modules.shared.no' | translate}}</label>
                </div>
            </div>
        </div>
    </div>

        <!-- <input type="text" class="form-control" id="ServiceStart"  formControlName="ServiceStart" required [(ngModel)]="user!.ServiceStart"> -->


    <div class="form-group" *ngIf="(authSrv.isAdmin() == true && ( user!.isClient  ||  user!.isManufacturer)) || (authSrv.isManufacturer() == true && user!.isClient)">

        <mat-form-field class="form-field" appearance="fill">
            <mat-label>{{'modules.users.service' | translate}}</mat-label>
            <mat-date-range-input
        [rangePicker]="campaignOnePicker"
        [comparisonStart]="user!.ServiceStart"
        [comparisonEnd]="user!.ServiceEnd">
        <input matStartDate placeholder="{{'modules.users.service-start' | translate}}" formControlName="ServiceStart" [(ngModel)]="user.ServiceStart" >
        <input matEndDate placeholder="{{'modules.users.service-end' | translate}}" formControlName="ServiceEnd" [(ngModel)]="user.ServiceEnd" >
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
    <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
    </mat-form-field>

    </div>




</mat-card>

</form>



</ng-container>
