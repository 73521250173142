import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RolesGuard } from 'src/app/guards/roles.guard';
import { AddAlarmPageComponent } from './pages/add-alarm-page/add-alarm-page.component';
import { DetailAlarmPageComponent } from './pages/detail-alarm-page/detail-alarm-page.component';
import { DetailDefaultValuesAlarmPageComponent } from './pages/detail-default-values-alarm-page/detail-default-values-alarm-page.component';
import { DetailDeviceAlarmPageComponent } from './pages/detail-device-alarm-page/detail-device-alarm-page.component';
import { DetailModelAlarmPageComponent } from './pages/detail-model-alarm-page/detail-model-alarm-page.component';
import { EditAlarmPageComponent } from './pages/edit-alarm-page/edit-alarm-page.component';
import { ListAlarmsPageComponent } from './pages/list-alarms-page/list-alarms-page.component';
import { SimulateTracePageComponent } from './pages/simulate-trace-page/simulate-trace-page.component';

const routes: Routes = [
  {
    path: '',
    children:  [
      { path: 'list', component: ListAlarmsPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant', 'user'] }},
      { path: ':id/edit', component: EditAlarmPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant'] }},
      { path: ':id/edit/:page', component: EditAlarmPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant'] }},
      { path: ':id/detail', component: DetailAlarmPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant','user'] }},
      { path: 'device/add', component: DetailDeviceAlarmPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant', 'user'] }},
      { path: 'device/:id', component: DetailDeviceAlarmPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant', 'user'] }},
      { path: 'model/add', component: DetailModelAlarmPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant'] }},
      { path: 'model/:id', component: DetailModelAlarmPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant', 'user'] }},
      //{ path: 'add/:default', component: AddAlarmPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant'] }},
      { path: 'default/add', component: DetailDefaultValuesAlarmPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin'] }},
      { path: 'default/:id', component: DetailDefaultValuesAlarmPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant'] }},
      { path: 'trace-simulator', component: SimulateTracePageComponent, canActivate: [ RolesGuard ], data: { role: ['admin'] }},

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AlarmsRoutingModule { }
