import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgTerminal } from 'ng-terminal';
import { Device } from 'src/app/models/device';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { DevicesService } from 'src/app/services/devices.service';
import { io, Socket } from "socket.io-client";
import { TerminalService } from 'src/app/services/terminal.service';
import { environment } from '../../../../../environments/environment';
import { Md5 } from 'ts-md5';
import { DateTime } from 'luxon';
import {  Terminal } from 'xterm';
import { WebLinksAddon } from 'xterm-addon-web-links';
import { ConsoleTrack, resultConsole } from 'src/app/models/console';
import { ConsoleTrackCommand } from '../../../../models/console';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-console-device',
  templateUrl: './console-device.component.html',
  styleUrls: ['./console-device.component.scss']
})
export class ConsoleDeviceComponent implements OnInit,  AfterViewInit{
  @Input() device?: Device;
  @Input() pageInput?: string = "";
  @Input() idAlert?: number;
  loading: boolean = false;
  @ViewChild('term', {static: false}) child?: NgTerminal;
  prompt: string = "";
  input: string = "";

  socket?: Socket;
  terminalSrv?: TerminalService;
  underlying?: Terminal;

  testTerminal?: ConsoleTrack;

  terminalBlock: boolean = true;

  specialKeys: Array<string> = [
    '\x1B[A',
    '\x1B[C',
    '\x1B[B',
    '\x1B[D'
  ];


  constructor(
    public dialog: MatDialog,
    private deviceSrv: DevicesService,
    private breadcrumbService: BreadcrumbService,
    private router : Router,
    public authSrv: AuthenticationService,
    private translate: TranslateService) { }


  initialTerminal(){
    this.testTerminal = new ConsoleTrack();
    this.testTerminal.imei = "imei8"
    this.testTerminal.timeSequence = 1000;
    this.testTerminal.commands = [
      new ConsoleTrackCommand({ command: "command 1", createdAt: Date.now() }),
      new ConsoleTrackCommand({ command: "command 2", createdAt: Date.now() }),
      new ConsoleTrackCommand({ command: "command 3", createdAt: Date.now() }),
      new ConsoleTrackCommand({ command: "command 4", createdAt: Date.now() })
    ]
  }

  async  ngOnInit(): Promise<void> {

    const md5 = new Md5();
    let date: DateTime = DateTime.now();

    // test array commands
    //this.initialTerminal();

    if(this.device){
      let nombre = this.device?.Name??this.translate.instant('modules.devices.no-name');
      let imei = this.device?.IMEI??'';
      let title: string = this.translate.instant('modules.devices.device-console') + ' ';
      this.breadcrumbService.addBreadCrumb('devices/' + this.device!.Id??''  + '/detail', title + nombre + ' | ' + imei  );
    }

    this.loading = false;
    let token: string = this.authSrv.currentUserValue!.Username! + date.toFormat('yyyy');
    console.log(token);
    let tokenEncrypt = md5.appendStr(  token ).end();
    console.log(tokenEncrypt);

    this.prompt = this.getPrompt();

    try{
      this.terminalSrv = new TerminalService(this.device!.IMEI!, String(tokenEncrypt) , this.authSrv.currentUserValue!.UserId!.toString());

      this.terminalSrv!.callback.subscribe( (res: resultConsole) => {

        console.log(res);
        this.child!.write('\x1b[2K')
        //this.child!.write('\r' + this.getPrompt() + res + '\r\n' + this.prompt );

        if(res.status == "OK"){
          this.child!.write('\r\x1b[1;32m' + this.getPrompt() + ' \x1b[36m' + res.result.result + '\r\n\x1b[37m' + this.getPrompt() );
        }else{

          this.child!.write('\r\x1b[1;32m' + this.getPrompt() + ' \x1b[31m' + res.result.result + '\r\n\x1b[37m' + this.getPrompt() );
        }

          //this.input = "";
        this.child!.write(this.input);

        console.log(DateTime.now().toFormat('HH:mm:ss'))

      });


    this.terminalSrv!.connected.subscribe( async (res: any) => {
      this.prompt = this.getPrompt();
      console.log("connected");
      this.child!.write('\r\x1b[1;32m' + this.getPrompt() + ' \x1b[31m' + res + '\r\n\x1b[37m' + this.getPrompt() );
      if(res == "DESCONECTADO"){
        this.child!.write('\r\x1b[1;32m' + this.getPrompt() + ' \x1b[37m' + this.translate.instant('modules.devices.connecting') + '...' + '\r\n\x1b[37m' + this.getPrompt() );

        this.terminalBlock = true;
        this.input = "";

      }else{
        this.terminalBlock = false;

        if(this.testTerminal && this.testTerminal!.commands!.length > 0){

          this.child!.write(this.translate.instant('modules.devices.starting-sequence') + " ( " + this.testTerminal?.timeSequence + "ms )");
          this.child!.write('\r\n' + this.getPrompt());

          for(let i = 0 ; i < this.testTerminal!.commands!.length; i++ ){

            this.child!.write(this.testTerminal!.commands![i].command!);
            this.child!.write('\r\n' + this.getPrompt());
            this.sendmessage(this.testTerminal!.commands![i].command!);
            this.input = "";

            await this.sleep(this.testTerminal!.timeSequence!);
          }

          this.testTerminal!.commands!.forEach( async (x: ConsoleTrackCommand) => {
          });
        }

      }
    });

    }catch(e){
      console.log('err' , e);
    }


    //this.connect();
    //this.setReceiveMethod();
    //this.terminalSrv.iniServerSocket();
  }


  ngAfterViewInit(){

    this.child!.onKey().subscribe( (x: any) => {
      console.log('x',x);
    });


    //this.underlying = this.child!.underlying;
    //this.underlying.loadAddon(new WebLinksAddon());
    /*this.child!.setXtermOptions({
      fontFamily: '"Cascadia Code", Menlo, monospace',
      theme: this.baseTheme,
      cursorBlink: true
    });*/

    this.child!.write('$ TERMINAL \x1b[33mIMEI ' + this.device!.IMEI + '\x1b[37m' + '\r\n' + this.getPrompt());


    console.log(this.terminalSrv);
    this.child!.write('\r\x1b[1;32m' + this.getPrompt() + ' \x1b[37m' + this.translate.instant('modules.devices.connecting') + '...' + '\r\n\x1b[37m' + this.getPrompt() );




    //const patternValid = /^[ a-zA-ZñÑáéíóúÁÉÍÓÚ$1234567890\-\_\$\,\=\.\*]+$/;
    const patternValid = /^[ a-zA-ZñÑáéíóúÁÉÍÓÚ$1234567890\-\_\$\,\=\.\*\'\:\<\>\ \&\"\(\)]+$/;

    let excludedKey = [ '\x1B[B' , '\x1B[C' , '\x1B[D' , '\x1B[A'  , '\x16']

    this.child!.onData().subscribe((input: any) => {

      if(this.terminalBlock == false){
        console.log((input));

        if (excludedKey.includes(input)) {
          console.log('excluded');
        } 
        else if (input === '\r') { // Carriage Return (When Enter is pressed)
          console.log(this.input);
          this.child!.write('\r\n' + this.getPrompt());
          this.sendmessage(this.input);
          this.input = "";
        } else if (input === '\u007f'  ) { // Delete (When Backspace is pressed)
          if (this.input.length > 0 && this.child!.underlying.buffer.active.cursorX > 2) {
            this.child!.write('\b \b');
            this.input = this.input.slice(0, -1);
          }
        //} else if ( patternValid.test(input) ) {
        }else{
            this.input = this.input + input;
            this.child!.write(input);
        }

        console.log(this.input);
      }
    });
  }

  test(){
    console.log(this.getPrompt());
    console.log(this.child);
  }


  connect(){
    // this.socket = io(environment.SOCKET_SERVICE, { withCredentials: false, extraHeaders: { 'test':'test' } });
  }


  setReceiveMethod() {
    /*this.socket!.on('data-tmp', (data) => {
        console.log(data);
    });*/
  }


  sendmessage(i: string) {
    console.log("sendmessage");
    this.terminalSrv!.emitEvent(i);
    //this.socket!.emit('new-message', 'Hi-flask');
  }

  getPrompt(): string{
    return "$" + DateTime.now().toFormat('HH:mm:ss')+ ">";
  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  baseTheme = {
    foreground: '#F8F8F8',
    background: '#2D2E2C',
    selection: '#5DA5D533',
    black: '#1E1E1D',
    brightBlack: '#262625',
    red: '#CE5C5C',
    brightRed: '#FF7272',
    green: '#5BCC5B',
    brightGreen: '#72FF72',
    yellow: '#CCCC5B',
    brightYellow: '#FFFF72',
    blue: '#5D5DD3',
    brightBlue: '#7279FF',
    magenta: '#BC5ED1',
    brightMagenta: '#E572FF',
    cyan: '#5DA5D5',
    brightCyan: '#72F0FF',
    white: '#F8F8F8',
    brightWhite: '#FFFFFF',
    border: '#85858a'
  };
}
