import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable } from 'rxjs';
import { mapNameSensors, mapNameSensorsTranslations, sensors } from 'src/app/const/sensor-const';
import { Device, Information, Position } from 'src/app/models/device';
import { Timecard, Trip, TripEvent } from 'src/app/models/trip';
import { TripsService } from 'src/app/services/trips.service';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-detail-trip',
  templateUrl: './detail-trip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./detail-trip.component.scss']
})
export class DetailTripComponent implements OnInit,AfterContentChecked {

  @Input() trip?: Trip;
  @Input() centralPoint?: [number,number];
  @Input() positions?: Array<Information>;
  @Input() addTrips: Array<number> = [];
  availableSensors: string[] = [];
  zoom: number = 19;
  deviceChart?: Device;
  events?: Array<Timecard>;
  typeOfView: boolean = true;

  indexCurrentPosition?: number;
  indexCurrentPosition2 = new BehaviorSubject<number>(0);
  indexCurrentPosition3$: Observable<number> = this.indexCurrentPosition2.asObservable();
  mapNameSensors? : Map<string, string> = mapNameSensors ;
  
  nameSensorsTranslations: Map<string, string> = mapNameSensorsTranslations;
  sensorAccomulative?: any = sensors;
  
  constructor(
    private cdr: ChangeDetectorRef,
    public utilSrv: UtilsService,
    public tripSrv : TripsService,) { 

   }
   

  ngOnInit(): void {

    //this.positions[0].TrackPosition
    this.events = new Array<Timecard>();

    if(this.positions){    
      console.log('mapNameSensors' , this.mapNameSensors);
      console.log(  this.positions);
      this.availableSensors = this.tripSrv.getActiveSensors(this.positions);
      console.log('availableSensors' , this.availableSensors);

    
      let rpmAVG: number | undefined = this.tripSrv.getAVG(this.positions, 'RPM');
      console.log('rpmAVG' , rpmAVG);

      let horasDiff: number | undefined = this.tripSrv.getDiff(this.positions, 'WorkingHours');
      console.log('horasDiff' , horasDiff);

    }

      
    this.trip?.Events!.forEach( (e) => {
      
      this.events!.push(new Timecard({
        date: new Date(e.Date!),
        title: e.Title!,
        description: e.Comment,
        type: e.Type
        

      }));


      console.log('+++ add evenmt', this.events?.length);
    });


    let permitSensors: string[] = environment.sensorTag;
    let hasExist = false;
    let max = 0;
    let min = 0;

    console.log('availableSensors' , this.availableSensors);
    console.log('this.availableSensors[1]' , this.availableSensors[1]);

    const sensors = new Set<string>();
    for (const information of this.positions!) {
      for (const key in information) {
        if (key == this.availableSensors[1] && information.hasOwnProperty(key) && permitSensors.includes(key) && information[key] !== null && information[key] !== undefined ) {
          
          if(max<information[key]  || hasExist == false ){
            max = information[key];
          }
          if(min > information[key]  || hasExist == false ){
            min = information[key];
          }
        }
      }
    }
    console.log('max', max);
    console.log('min', min); 

    console.log('getDiff', this.tripSrv.getDiff(this.positions!, this.availableSensors[1]));

    this.cdr.detach();

    

  }
  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  changeCurrentPointDate(date: DateTime){

    let auxIndex = 0;
    let auxIndex1 = -1;
    let auxIndex2 = -1;

    //console.log('++ changeCurrentPointDate' , date);
  

    this.positions?.forEach(e =>{
      
      let time = DateTime.fromJSDate(new Date(e.TrackPosition!.Date!));
      //console.log('++ changeCurrentPointDate time' , time);
      if(date.toMillis() == time.toMillis()){
        auxIndex1 = auxIndex;
      }

      if(date.toMillis() > time.toMillis()){
        auxIndex2 = auxIndex;
      }

      auxIndex++;

    });

    if(auxIndex1 == -1){
      auxIndex1 = auxIndex2;
    }

    this.indexCurrentPosition = auxIndex1;
    this.indexCurrentPosition2.next(auxIndex1);
    console.log('++ changeCurrentPoint OUT' , auxIndex1); 
    this.indexCurrentPosition3$.subscribe( e => { console.log(e); });
    this.cdr.detectChanges();

  }
  changeCurrentPoint(index: number){

    /*
    this.indexCurrentPosition = index;
    this.indexCurrentPosition2.next(index);
    console.log('++ changeCurrentPoint OUT' , index); 
    this.indexCurrentPosition3$.subscribe( e => { console.log(e); });
    this.cdr.detectChanges();*/
  }

  get getSensorAccomulative():Array<string>{

    return this.utilSrv.getSensorAccomulative();

  }

  changeView(){
    console.log('change')
    this.typeOfView = !this.typeOfView;
  }

  splitString(str: string, separator: string = " "): string[] {
    return str.split(separator);
  }

}
