
<app-loading-custom [loading]="loading || loadingUser"></app-loading-custom>


<ng-container *ngIf="loading == false && loadingUser == false && information">

  <form class="text-start p-2" [formGroup]="alarmForm"  autocomplete="off" >
    <!-- <h2 class="text-center mt-3 mb-3">{{'modules.alarms.config-alarm' | translate}} {{ information!.DeviceModel?.Name??'' }}</h2> -->

    <ng-container formGroupName="Alarm" *ngIf="pageDeviceInto == false && (authSrv.isAdmin() || authSrv.isManufacturer())"  >

      <div class="form-group" *ngIf=" modelsList && manufacturerList && modelsList.length > 1" >

        <!--
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Assignee</mat-label>
            <input type="text" matInput formControlName="DeviceModel" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let option of modelsList" [value]="option">
                {{option.Name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field> -->


              <ng-multiselect-dropdown  [ngClass]="(authSrv.isAdmin() == false || showChangeManufacturer==false)?'d-none':''"
                id="Manufacturer"
                formControlName="Manufacturer"
                [(ngModel)]="selectedManufacturerList"
                [placeholder]="'modules.shared.search-account' | translate"
                [settings]="dropdownSettingsManufacturer"
                [data]="manufacturerList"
                (onSelect)="changeModel()"
                >

                <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
                  <div style="padding:1px 10px;margin-right:10px;right:2px;top:-2px;cursor: pointer !important;">
                    <label *ngIf="authSrv.isManufacturer() == false" style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
                      {{option}}{{ getManufacturer[id]?.Email?' | ' +getManufacturer[id]?.Email:'' }} {{ getManufacturer[id]?.ContactName?' | ' +getManufacturer[id]?.ContactName:'' }}
                    </label>
                    <label *ngIf="authSrv.isManufacturer() == true" style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
                      {{ getManufacturer[id]?.Name??'' }}
                    </label>
                  </div>
                </ng-template>

                <ng-template #optionSelectedTemplate let-option="option" let-id="id">
                  <div *ngIf="authSrv.isManufacturer() == false">
                    {{option}}{{ getManufacturer[id].Email?' | ' +getManufacturer[id].Email:'' }} {{ getManufacturer[id].ContactName?' | ' +getManufacturer[id].ContactName:'' }}
                  </div>
                  <div  *ngIf="authSrv.isManufacturer() == true">
                      {{ getManufacturer[id]?.Name??'' }}
                  </div>
                </ng-template>
              </ng-multiselect-dropdown>

          </div>

          <div class="form-group" *ngIf="modelError == true" >

                  <div class="alert alert-danger mt-3 text-start">
                    <div>
                      {{'modules.account.required' | translate}}.
                    </div>

                </div>
          </div>

    <div class="form-group pt-3 pb-2" *ngIf=" modelsList && modelsList.length > 1" >

<!--
  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Assignee</mat-label>
    <input type="text" matInput formControlName="DeviceModel" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let option of modelsList" [value]="option">
        {{option.Name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field> -->


      <ng-multiselect-dropdown [ngClass]="(showChangeModel==false || (authSrv.isAdmin() == false && information.Id! != 0))?'d-none':''"
        id="DeviceModel"
        formControlName="DeviceModel"
        [(ngModel)]="selectedModelsList"
        [placeholder]="'modules.shared.search-model-machine' | translate"
        [settings]="dropdownSettingsModels"
        [data]="modelsListFilter"
        (onSelect)="changeManufacturer()"
        >

        <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
          <div style="padding:1px 10px;margin-right:10px;right:2px;top:-2px;cursor: pointer !important;">
            <label *ngIf="authSrv.isManufacturer() == false" style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
              {{option}} | {{ getModel[id]?.NameManufacturer??'' }}
            </label>
            <label *ngIf="authSrv.isManufacturer() == true" style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
              {{ getModel[id]?.Name??'' }}
            </label>
          </div>
        </ng-template>

        <ng-template #optionSelectedTemplate let-option="option" let-id="id">
          <div *ngIf="authSrv.isManufacturer() == false">
              {{option}} | {{ getModel[id]?.NameManufacturer??'' }}
          </div>
          <div  *ngIf="authSrv.isManufacturer() == true">
              {{ getModel[id]?.Name??'' }}
          </div>
        </ng-template>
      </ng-multiselect-dropdown>

  </div>

  <div class="form-group" *ngIf="modelError == true" >

          <div class="alert alert-danger mt-3 text-start">
            <div>
              {{'modules.devices.model-required' | translate}}.
            </div>

        </div>
  </div>

</ng-container>

<ng-container formGroupName="Alarm">

  <div style="max-width: 600px;"  *ngIf="authSrv.isAdmin()">
    <div class="mb-3" style="margin: auto; text-align: left !important; width: fit-content;">
      <div *ngIf="this.information!.Id != 0">
        <strong>{{ textRole }}</strong> {{ information.User.Name }}
      </div>
      <div class="form-group roles">
        <label *ngIf="this.information!.Id != 0" for="Role" class="text-right-label">{{'modules.users.change-user' | translate}}</label>
        <label *ngIf="this.information!.Id == 0" for="Role" class="text-right-label">{{'modules.users.assign-user' | translate}}</label>

        <mat-radio-group formControlName="Role"
          aria-labelledby="example-radio-group-label"
          class="example-radio-group"
          [(ngModel)]="itemRol">
          <mat-radio-button  class="example-radio-button" value="0" [checked]="itemRol === 0" >
            {{'modules.users.admin' | translate}}
          </mat-radio-button>
          <mat-radio-button  class="example-radio-button" [value]="information!.DeviceModel?.Manufacturer.Id" [checked]="itemRol === information!.DeviceModel?.Manufacturer.Id" *ngIf="information!.DeviceModel?.Manufacturer">
            {{'modules.tables.account' | translate}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>


        <div style="max-width: 600px;">


          <div class="mb-3" style="margin: auto; text-align: left !important; width: min-content;">
            <div class="text-left d-block">
              <div class="text-left d-block m-2" [ngClass]="(authSrv.isAdmin()  || authSrv.isManufacturer())?'':'d-none'">
                <mat-checkbox color="primary" class="example-margin" [(ngModel)]="information!.AlarmValue!.ManufacturerActive" formControlName="ManufacturerActive" >{{ authSrv.isManufacturer()?('modules.notifications.enable' | translate) + '.':('modules.notifications.enable-for-account' | translate) + '.'}}</mat-checkbox>

              </div>
              <div class="text-left d-block m-2"  [ngClass]="(authSrv.isAdmin()  || authSrv.isClient() ||  authSrv.currentUserValue?.UserId == information.IdUserCreated || information.Id == 0)?'':'d-none'">
                <mat-checkbox color="primary" class="example-margin" [(ngModel)]="information!.AlarmValue!.ClientActive" formControlName="ClientActive" >{{ authSrv.isClient()?('modules.notifications.enable' | translate) + '.':('modules.notifications.enable-for-user' | translate) + '.'}}</mat-checkbox>

              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.rpm' | translate}}" name="RPM" [minValue]="information!.AlarmValue!.RPMMin!"  [maxValue]="information!.AlarmValue!.RPMMax!" (formShared)="updateRPMValid($event)" [readOnly]="readOnly" ></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.horas-trabajo' | translate}}" name="Horas trabajo" tag="WorkingHours" [minValue]="information!.AlarmValue!.WorkingHoursMin!"  [maxValue]="information!.AlarmValue!.WorkingHoursMax!" (formShared)="updateWorkingHoursValid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>

      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.presion' | translate}}" name="Presión" tag="Pressure1" [minValue]="information!.AlarmValue!.Pressure1Min!"  [maxValue]="information!.AlarmValue!.Pressure1Max!" (formShared)="updatePressure1Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.caudal' | translate}}" name="Caudal" tag="Pressure2" [minValue]="information!.AlarmValue!.Pressure2Min!"  [maxValue]="information!.AlarmValue!.Pressure2Max!" (formShared)="updatePressure2Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.temp-aceite' | translate}}" name="Temp. aceite" tag="Flow1" [minValue]="information!.AlarmValue!.Flow1Min!"  [maxValue]="information!.AlarmValue!.Flow1Max!" (formShared)="updateFlow1Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.nivel-aceite' | translate}}" name="Nivel aceite'" tag="Flow2" [minValue]="information!.AlarmValue!.Flow2Min!"  [maxValue]="information!.AlarmValue!.Flow2Max!" (formShared)="updateFlow2Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>

      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.num-acciones' | translate}}" name="Nº acciones" tag="Action1" [minValue]="information!.AlarmValue!.Action1Min!"  [maxValue]="information!.AlarmValue!.Action1Max!" (formShared)="updateAction1Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.peso' | translate}}" name="Peso" tag="Action2" [minValue]="information!.AlarmValue!.Action2Min!"  [maxValue]="information!.AlarmValue!.Action2Max!" (formShared)="updateAction2Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.rpm-2' | translate}}" name="RPM 2" tag="Action3" [minValue]="information!.AlarmValue!.Action3Min!"  [maxValue]="information!.AlarmValue!.Action3Max!" (formShared)="updateAction3Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.rpm-3' | translate}}" name="RPM 3" tag="Action4" [minValue]="information!.AlarmValue!.Action4Min!"  [maxValue]="information!.AlarmValue!.Action4Max!" (formShared)="updateAction4Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.presion-2' | translate}}" name="Presión 2" tag="Action5" [minValue]="information!.AlarmValue!.Action5Min!"  [maxValue]="information!.AlarmValue!.Action5Max!" (formShared)="updateAction5Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.presion-3' | translate}}" name="Presión 3" tag="Action6" [minValue]="information!.AlarmValue!.Action6Min!"  [maxValue]="information!.AlarmValue!.Action6Max!" (formShared)="updateAction6Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>

      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.caudal-2' | translate}}" name="Caudal 2" tag="Weight1" [minValue]="information!.AlarmValue!.Weight1Min!"  [maxValue]="information!.AlarmValue!.Weight1Max!" (formShared)="updateWeight1Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.caudal-3' | translate}}" name="Caudal 3" tag="Weight2" [minValue]="information!.AlarmValue!.Weight2Min!"  [maxValue]="information!.AlarmValue!.Weight2Max!" (formShared)="updateWeight2Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
 
      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.peso-2' | translate}}" name="Peso 2" tag="Aux1" [minValue]="information!.AlarmValue!.Aux1Min!"  [maxValue]="information!.AlarmValue!.Aux1Max!" (formShared)="updateAux1Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.num-acciones-2' | translate}}" name="Nº acciones 2" tag="Aux2" [minValue]="information!.AlarmValue!.Aux2Min!"  [maxValue]="information!.AlarmValue!.Aux2Max!" (formShared)="updateAux2Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.num-acciones-3' | translate}}" name="Nº acciones 3" tag="Aux3" [minValue]="information!.AlarmValue!.Aux3Min!"  [maxValue]="information!.AlarmValue!.Aux3Max!" (formShared)="updateAux3Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.aux-1' | translate}}" name="Aux 1" tag="Aux4" [minValue]="information!.AlarmValue!.Aux4Min!"  [maxValue]="information!.AlarmValue!.Aux4Max!" (formShared)="updateAux4Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.aux-2' | translate}}" name="Aux 2" tag="Aux5" [minValue]="information!.AlarmValue!.Aux5Min!"  [maxValue]="information!.AlarmValue!.Aux5Max!" (formShared)="updateAux5Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.aux-3' | translate}}" name="Aux 3" tag="Aux6" [minValue]="information!.AlarmValue!.Aux6Min!"  [maxValue]="information!.AlarmValue!.Aux6Max!" (formShared)="updateAux6Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.aux-4' | translate}}" name="Aux 4" tag="Aux7" [minValue]="information!.AlarmValue!.Aux7Min!"  [maxValue]="information!.AlarmValue!.Aux7Max!" (formShared)="updateAux7Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
      <app-form-range-default-values-alarm #inputsChild translation="{{'modules.alarms.aux-5' | translate}}" name="Aux 5" tag="Aux8" [minValue]="information!.AlarmValue!.Aux8Min!"  [maxValue]="information!.AlarmValue!.Aux8Max!" (formShared)="updateAux8Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>


  <section class="mt-2" *ngIf="information">
    <div class="text-right" style="flex-direction: row-reverse;" *ngIf="authSrv.isAdmin() || authSrv.currentUserValue!.UserId == information!.IdUserCreated">
        <button  mat-raised-button class="btn btn-primary" [disabled]="validAllForm == true? null: 'disabled'"  (click)="onSubmit()">{{ (information.Id! == 0)?('modules.shared.create' | translate): 'modules.alarms.apply-changes' | translate }}</button>
    </div>
    <div class="text-right" style="flex-direction: row-reverse;" *ngIf="!authSrv.isAdmin() && authSrv.currentUserValue!.UserId != information!.IdUserCreated">
        <button  mat-raised-button class="btn btn-primary"  (click)="onSubmit(false)">{{ (information.Id! == 0)?('modules.shared.create' | translate): 'modules.alarms.apply-changes' | translate }}</button>
    </div>

    <!-- <div  class="btn btn-primary"  (click)="test()">test</div> -->

</section>

  </form>

</ng-container>
