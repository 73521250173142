<app-loading-custom [loading]="loadingCreate"></app-loading-custom>


<form  [formGroup]="newTripForm"  autocomplete="off" >

    <div id="hide-inputs">
        <div  class="mr-2 pl-3">
            <mat-form-field  class="form-field" appearance="fill">
                <mat-label>{{'modules.trips.start-date' | translate}}</mat-label>
                <input id="dtStart" matInput type="datetime-local" placeholder="start date" formControlName="StartDate" [(ngModel)]="StartDateValue">
            </mat-form-field>
            <mat-form-field  class="form-field ml-3" appearance="fill">
                <mat-label>{{'modules.trips.end-date' | translate}}</mat-label>
                <input id="dtFinish" matInput type="datetime-local" placeholder="finish date" formControlName="FinishDate" [(ngModel)]="FinishDateValue">
            </mat-form-field>
            <mat-form-field class="form-field ml-3" appearance="fill">
            <mat-label>IMEI</mat-label>
            <input matInput formControlName="IMEI" [(ngModel)]="IMEIValue">
            </mat-form-field>

            <button mat-raised-button color="primary" (click)="submit(false)">{{'modules.shared.create' | translate}}</button>
            <button mat-raised-button color="secundary" class="ml-2" (click)="submit(true)">{{'modules.trips.analyze' | translate}}</button>

        </div>
    </div>

    <div class="d-flex flex-column flex-wrap gap-3">
        <div class="d-flex flex-wrap gap-3 pl-3 align-items-baseline">
            <div id="StartDateMoment" class="date-picker">
                <label>{{'modules.trips.start-date' | translate}} *</label>
                <input [owlDateTime]="dt1" placeholder="dd/mm/aaaa --:--" formControlName="StartDateMoment" [(ngModel)]="StartDateValueMoment" (ngModelChange)="change('dtStart')">
                <span class="" [owlDateTimeTrigger]="dt1">
                    <i class="fas fa-calendar-alt"></i>
                </span>
                <owl-date-time #dt1></owl-date-time>
            </div>
            <div id="FinishDateMoment" class="date-picker">
                <label>{{'modules.trips.end-date' | translate}} *</label>
                <input [owlDateTime]="dt2" placeholder="dd/mm/aaaa --:--" formControlName="FinishDateMoment" [(ngModel)]="FinishDateValueMoment" (ngModelChange)="change('dtFinish')">
                <span class="" [owlDateTimeTrigger]="dt2">
                    <i class="fas fa-calendar-alt"></i>
                </span>
                <owl-date-time #dt2></owl-date-time>
            </div>
            <div class="">
                <mat-form-field class="form-field" appearance="fill">
                <mat-label>IMEI</mat-label>
                <input matInput formControlName="IMEI" [(ngModel)]="IMEIValue">
                </mat-form-field>
            </div>
            <div class="d-flex flex-wrap gap-3">
                <div>
                    <button mat-raised-button color="primary" (click)="submit(false)">{{'modules.shared.create' | translate}}</button>
                </div>
                <div class="">
                    <button mat-raised-button color="secundary" (click)="submit(true)">{{'modules.trips.analyze' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
        
    <!-- 
    <div class="mr-2 pl-3" >
        <mat-form-field class="form-field" appearance="fill">
            <mat-label>{{'modules.shared.time-frame' | translate}}</mat-label>
            <mat-date-range-input
            [rangePicker]="campaignOnePicker">
            <input matStartDate placeholder="{{'modules.shared.start-data' | translate}}" formControlName="StartDate" [(ngModel)]="StartDateValue" >
            <input matEndDate placeholder="{{'modules.shared.end-data' | translate}}" formControlName="FinishDate" [(ngModel)]="FinishDateValue" >
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="campaignOnePicker" ></mat-datepicker-toggle>
        <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
        </mat-form-field>
      </div>-->



</form>