import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Modal } from 'src/app/models/modal';
import { ConfirmationModalComponent } from 'src/app/modals/confirmation-modal/confirmation-modal.component';
import { ThemeService } from 'src/app/services/theme.services';
import { UtilsService } from 'src/app/services/utils.service';
import { Theme } from 'src/app/models/theme';
import { NgTerminal } from 'ng-terminal';
import { DateTime } from 'luxon';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { Md5 } from 'ts-md5';
import { TranslateService } from '@ngx-translate/core';
declare const $: any

@Component({
  selector: 'app-terminal-test',
  templateUrl: './terminal-test.component.html',
  styleUrls: ['./terminal-test.component.scss']
})
export class TerminalTestComponent implements OnInit {

    @ViewChild('term', {static: false}) child?: NgTerminal;
 
    loading: boolean = true;
    input: string = "";
    prompt: string = "";
    terminalBlock: boolean = false;

    copyText: string = "";
    terminalForm: UntypedFormGroup;

    specialKeys: Array<string> = [
      '\x1B[A',
      '\x1B[C',
      '\x1B[B',
      '\x1B[D'
    ];

  constructor(public router: Router,
    private formBuilder: FormBuilder,
    public authSrv: AuthenticationService,
    private utilsSrv: UtilsService,
    private translate: TranslateService
    ) {
        this.terminalForm = this.formBuilder.group({ 
            textCopy: this.copyText
         }); 
    }

  async ngOnInit(): Promise<void>  {
    this.loading = false;

    const md5 = new Md5();
    let date: DateTime = DateTime.now();
    this.loading = false;

    this.child?.write('\x1b[2K');
    this.child?.write('\r\x1b[1;32m' + this.getPrompt() + ' \x1b[36m test\r\n\x1b[37m' + this.prompt );

    this.child?.write(this.input);
    this.terminalBlock = false;

    console.log(DateTime.now().toFormat('HH:mm:ss'))
  }


  get copyTextForm(){ return this.terminalForm!.get("textCopy")?.value; }

  ngAfterViewInit(){

    this.child!.onKey().subscribe( (x: any) => {
      console.log('x',x);
    });


    //this.underlying = this.child!.underlying;
    //this.underlying.loadAddon(new WebLinksAddon());
    /*this.child!.setXtermOptions({
      fontFamily: '"Cascadia Code", Menlo, monospace',
      theme: this.baseTheme,
      cursorBlink: true
    });*/

    this.child!.write('$ TERMINAL \x1b[33mIMEI \x1b[37m' + '\r\n' + this.prompt);


    this.child!.write('\r\x1b[1;32m' + this.getPrompt() + ' \x1b[37m' + this.translate.instant('modules.devices.connecting') + '...' + '\r\n\x1b[37m' + this.prompt );




    const patternValid = /^[ a-zA-ZñÑáéíóúÁÉÍÓÚ$1234567890\-\_\$\,\=\.\*]+$/;

    this.child!.onData().subscribe((input: any) => {

      if(this.terminalBlock == false){
        console.log((input));

        if (input === '\r') { // Carriage Return (When Enter is pressed)
          console.log(this.input);
          this.child!.write('\r\n' + this.prompt);
          this.input = "";
        } else if (input === '\u007f' && this.input.length > 0 ) { // Delete (When Backspace is pressed)
          if (this.child!.underlying.buffer.active.cursorX > 2) {
            this.child!.write('\b \b');
            this.input = this.input.slice(0, -1);
          }
        } else if ( patternValid.test(input) ) {
            this.input = this.input + input;
            this.child!.write(input);
        }

        console.log(this.input);
      }
    });
  }

  getPrompt(): string{
    return "$" + DateTime.now().toFormat('HH:mm:ss')+ ">";
  }

  showInput(){
    console.log('showInput', this.input);
  }

  copyInput(){

    console.log('copyTextForm' , this.copyTextForm);
    console.log('copyInput' , this.copyText);
  }

}
