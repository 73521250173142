import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

  languagesList?: string[] | null = null;
  activeLanguage?: string;
  userId?: number = this.authSrv.currentUserValue?.UserId;
  user?: User;

  constructor(
    private translate: TranslateService,
    private userSrv: UserService,
    public authSrv: AuthenticationService
  ) {
    
  }
  
  ngOnInit(): void {
    this.languagesList = this.translate.getLangs();    

    if (this.userId) {     
      this.userSrv.getUserById(this.authSrv.currentUserValue?.UserId!).subscribe({
        next: (res: User) => {
          this.user = res;
          this.activeLanguage = res.Language || this.translate.getDefaultLang();
          this.translate.setDefaultLang(res.Language!);
          this.translate.use(res.Language!);
        }
      });
    }
  }

  changeLanguage() {
    this.userSrv.changeLanguage(this.user!, this.activeLanguage!).subscribe({
      next: () => {
        this.translate.setDefaultLang(this.activeLanguage!);
        localStorage.setItem('lang', this.activeLanguage!);
        setTimeout( () => {
          window.location.reload();
        }, 500 );
      }
    });
  }

}
