import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListTripsPageComponent } from './pages/list-trips-page/list-trips-page.component';
import { DetailTripPageComponent } from './pages/detail-trip-page/detail-trip-page.component';
import { NewTripPageComponent } from './pages/new-trip-page/new-trip-page.component';
import { SplitTripPageComponent } from './pages/split-trip-page/split-trip-page.component';
import { DetailJoinTripPageComponent } from './pages/detail-join-trip-page/detail-join-trip-page.component';


const routes: Routes = [
  {
    path: '',
    children:  [
      { path: '', component: ListTripsPageComponent},
      { path: 'new', component: NewTripPageComponent},
      { path: ':id', component: DetailTripPageComponent},
      { path: ':id/split', component: SplitTripPageComponent},
      { path: ':id/join', component: DetailJoinTripPageComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TripsRoutingModule { }
