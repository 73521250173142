export const mapNameSensors: Map<string,string> = new Map([
    ["RPM", "RPM"],
    ["WorkingHours", "Horas trabajo"],
    ["Pressure1", "Presión"],
    ["Pressure2", "Caudal"],
    ["Flow1", "Temp. aceite"],
    ["Flow2", "Nivel aceite"],
    ["Action1", "Nº acciones"],
    ["Action2", "Peso"],
    ["Action3", "RPM 2"],
    ["Action4", "RPM 3"],
    ["Action5", "Presión 2"],
    ["Action6", "Presión 3"],
    ["Weight1", "Caudal 2"],
    ["Weight2", "Caudal 3"],
    ["Aux1", "Peso 2"],
    ["Aux2", "Nº acciones 2"],
    ["Aux3", "Nº acciones 3"],
    ["Aux4", "Aux1"],
    ["Aux5", "Aux2"],
    ["Aux6", "Aux3"],
    ["Aux7", "Aux4"],
    ["Aux8", "Aux5"],
  ]);

export const mapNameSensors2: Map<string,string> = new Map([
    ["RPM", "modules.alarms.rpm"],
    ["WorkingHours", "modules.alarms.horas-trabajo"],
    ["Pressure1", "modules.alarms.presion"],
    ["Pressure2", "modules.alarms.caudal"],
    ["Flow1", "modules.alarms.temp-aceite"],
    ["Flow2", "modules.alarms.nivel-aceite"],
    ["Action1", "modules.alarms.num-acciones"],
    ["Action2", "modules.alarms.peso"],
    ["Action3", "modules.alarms.rpm-2"],
    ["Action4", "modules.alarms.rpm-3"],
    ["Action5", "modules.alarms.presion-2"],
    ["Action6", "modules.alarms.presion-3"],
    ["Weight1", "modules.alarms.caudal-2"],
    ["Weight2", "modules.alarms.caudal-3"],
    ["Aux1", "modules.alarms.peso-2"],
    ["Aux2", "modules.alarms.num-acciones-2"],
    ["Aux3", "modules.alarms.num-acciones-3"],
    ["Aux4", "modules.alarms.aux-1"],
    ["Aux5", "modules.alarms.aux-2"],
    ["Aux6", "modules.alarms.aux-3"],
    ["Aux7", "modules.alarms.aux-4"],
    ["Aux8", "modules.alarms.aux-5"],
  ]);

  export const mapNameSensorsTranslations: Map<string,string> = new Map([
    ["RPM", "modules.alarms.rpm"],
    ["Horas trabajo", "modules.alarms.horas-trabajo"],
    ["Presión", "modules.alarms.presion"],
    ["Caudal", "modules.alarms.caudal"],
    ["Temp. aceite", "modules.alarms.temp-aceite"],
    ["Nivel aceite", "modules.alarms.nivel-aceite"],
    ["Nº acciones", "modules.alarms.num-acciones"],
    ["Peso", "modules.alarms.peso"],
    ["RPM 2", "modules.alarms.rpm-2"],
    ["RPM 3", "modules.alarms.rpm-3"],
    ["Presión 2", "modules.alarms.presion-2"],
    ["Presión 3", "modules.alarms.presion-3"],
    ["Caudal 2", "modules.alarms.caudal-2"],
    ["Caudal 3", "modules.alarms.caudal-3"],
    ["Peso 2", "modules.alarms.peso-2"],
    ["Nº acciones 2", "modules.alarms.num-acciones-2"],
    ["Nº acciones 3", "modules.alarms.num-acciones-3"],
    ["Aux1", "modules.alarms.aux-1"],
    ["Aux2", "modules.alarms.aux-2"],
    ["Aux3", "modules.alarms.aux-3"],
    ["Aux4", "modules.alarms.aux-4"],
    ["Aux5", "modules.alarms.aux-5"],
  ]);

export const sensors = [
    { key: 'RPM', value: 'RPM', accomulative: false, tag: 'RPM', position: 0 },
    { key: 'Horas trabajo', value: 'Horas de trabajo', accomulative: true, tag: 'WorkingHours', position: 1 },
    { key: 'Presión', value: 'Presión', accomulative: false, tag: 'Pressure1', position: 2 },
    { key: 'Caudal', value: 'Caudal', accomulative: false, tag: 'Pressure2', position: 3 },
    { key: 'Temp. aceite', value: 'Temperatura de aceite', accomulative: false, tag: 'Flow1', position: 4 },
    { key: 'Nivel aceite', value: 'Nivel de aceite', accomulative: false, tag: 'Flow2', position: 5 },
    { key: 'Nº acciones', value: 'Número de acciones', accomulative: true, tag: 'Action1', position: 6 },
    { key: 'Peso', value: 'Peso', accomulative: false, tag: 'Action2', position: 7 },
    { key: 'RPM 2', value: 'RPM 2', accomulative: false, tag: 'Action3', position: 8 },
    { key: 'RPM 3', value: 'RPM 3', accomulative: false, tag: 'Action4', position: 9 },
    { key: 'Presión 2', value: 'Presión 2', accomulative: false, tag: 'Action5', position: 10 },
    { key: 'Presión 3', value: 'Presión 3', accomulative: false, tag: 'Action6', position: 11 },
    { key: 'Caudal 2', value: 'Caudal 2', accomulative: false, tag: 'Weight1', position: 12 },
    { key: 'Caudal 3', value: 'Caudal 3', accomulative: false, tag: 'Weight2', position: 13 },
    { key: 'Peso 2', value: 'Peso 2', accomulative: false, tag: 'Aux1', position: 14 },
    { key: 'Nº acciones 2', value: 'Número de acciones 2', accomulative: true, tag: 'Aux2', position: 15 },
    { key: 'Nº acciones 3', value: 'Número de acciones 3', accomulative: true, tag: 'Aux3', position: 16 },
    { key: 'Aux1', value: 'Parámetro auxiliar 1', accomulative: false, tag: 'Aux4', position: 17 },
    { key: 'Aux2', value: 'Parámetro auxiliar 2', accomulative: false, tag: 'Aux5', position: 18 },
    { key: 'Aux3', value: 'Parámetro auxiliar 3', accomulative: true, tag: 'Aux6', position: 19 },
    { key: 'Aux4', value: 'Parámetro auxiliar 4', accomulative: true, tag: 'Aux7', position: 20 },
    { key: 'Aux5', value: 'Parámetro auxiliar 5', accomulative: false, tag: 'Aux8', position: 21 },
];

export const sensorAccomulative = [
    { key: 'Horas trabajo', value: 'Horas de trabajo' , info: 'WorkingHours'},
    { key: 'Nº acciones', value: 'Número de acciones', info: 'Action1'},
    { key: 'Nº acciones 2', value: 'Número de acciones 2', info: 'Aux2'},
    { key: 'Nº acciones 3', value: 'Número de acciones 3', info: 'Aux3'},
    { key: 'Aux3', value: 'Parámetro auxiliar 3', info: 'Aux6'},
    { key: 'Aux4', value: 'Parámetro auxiliar 4', info: 'Aux7'}
    // test ,{ key: 'Aux5', value: 'Parámetro auxiliar 5', info: 'Aux8'}
];
