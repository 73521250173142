
<app-breadcrumb></app-breadcrumb>

<div class="container-fluid pt-3 pl-0 pr-0 espace">

  <app-loading-custom [loading]="loading"></app-loading-custom>

  <app-block-group-dashboard *ngIf="loading == false">

    <div class="my-4">
      <div class="row align-items-center">
        <div class="col-xl-12">
          <h1><b>{{title}}</b></h1>
        </div>
        <div class="col-xxl-4 text-xxl-end" *ngIf="showDeleteButton==true || (authSrv.isAdmin() && information && information.Id != 0)">
          <app-generic-button title="{{'modules.shared.warning' | translate}}" txt="{{'modules.modals.delete-default-values-model' | translate}}" classType="danger"
          (confirmDelete)="deleteAlarm()" ></app-generic-button>

        </div>

      </div>
    </div>



    <app-block-dashboard>

      <app-detail-default-values-alarm [information]="information" [showChangeModel]="showChangeModel">

      </app-detail-default-values-alarm>

    </app-block-dashboard>

  </app-block-group-dashboard>

<!--   <app-block-group-dashboard>

    <app-block-dashboard>
      <h2>{{'modules.users.users-list' | translate}}</h2>
      <app-list-users></app-list-users>
    </app-block-dashboard>

  </app-block-group-dashboard>
-->

</div>


<app-footer></app-footer>
