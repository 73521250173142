import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalComponent } from 'src/app/modals/confirmation-modal/confirmation-modal.component';
import { InfoModalComponent } from 'src/app/modals/info-modal/info-modal.component';
import { ApiRequestObject } from 'src/app/models/apiRequestObject';
import { Device, Information } from 'src/app/models/device';
import { Modal } from 'src/app/models/modal';
import { Trip, TripEvent } from 'src/app/models/trip';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { DevicesService } from 'src/app/services/devices.service';
import { TripsService } from 'src/app/services/trips.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-detail-join-trip-device-page',
  templateUrl: './detail-join-trip-device-page.component.html',
  styleUrls: ['./detail-join-trip-device-page.component.scss']
})
export class DetailJoinTripDevicePageComponent implements OnInit {

  public page?: string;
  device?: Device;
  idTrip?: number;
  id?: number;
  loading:boolean = true;
  idModel?: number;
  trip?: Trip;
  title: string = "";
  showChangeModel= true;
  showDeleteButton: boolean = false;
  hasDevicePage: boolean = false;
  addTrips: Array<number> = [];
  arrayViajes: Array<number> = [];
  resultApi?: ApiRequestObject;
  
  constructor(private breadcrumbService: BreadcrumbService,
    public dialog: MatDialog,
    public authSrv: AuthenticationService,
    private deviceSrv: DevicesService,
    private tripSrv : TripsService,
    private router: Router,
    private utilsSrv: UtilsService,
    private route: ActivatedRoute,
    private translate: TranslateService) { 

    breadcrumbService.setBreadCrumbUrl('devices/list', this.translate.instant('modules.trips.trip-management')  );

    this.route.queryParamMap.subscribe(params => {
      params.get('addTrips')?.split(',').forEach( x => {
        this.addTrips.push(+x);
      });

      this.route.params.subscribe(params => {

      
        this.id = params['id'];
        this.idTrip = params['idTrip'];
        console.log('Id device: ' + this.id)
        console.log('Id trip: ' + this.idTrip)
        this.page = this.route.snapshot.paramMap.get('page')?? "";
  
        this.arrayViajes.push(this.idTrip! );
        this.addTrips.forEach( x =>{
          this.arrayViajes.push(x);
        });

       
  
      });
    });

    
    
    breadcrumbService.setBreadCrumbUrl('devices/list',  this.translate.instant('modules.trips.trips-management')  );
    
  }

  ngOnInit(): void {
    this.loading = true;
    this.loadValues(this.id!, this.idTrip!, this.addTrips );
  }


  loadValues(idDevice: number, idTrip: number, addTrips: Array<number>){
    
    this.deviceSrv.getDeviceById(idDevice).subscribe({
      next: (res: Device) => {
        this.device = res;
            
        if(this.device){
          let nombre = this.device?.Name??this.translate.instant('modules.devices.no-name');
          let imei = this.device?.IMEI??'';
          let title: string = this.translate.instant('modules.devices.device')+ ' ';
          this.breadcrumbService.addBreadCrumb('devices/' + this.device!.Id!  + '/detail' , title + nombre + ' | ' + imei  );
        }

        this.tripSrv.getByIds(idTrip, addTrips).subscribe({
          next: (res: Trip) => {
            this.trip = new Trip(<Trip>res);
                
         
            if(this.hasDevicePage){

            }else{

              this.title = this.translate.instant('modules.trips.aggregate-view-trip') + ': ' + this.arrayViajes.join(',');
              this.breadcrumbService.addBreadCrumb('devices/' + this.device!.Id??''  + '/trips/' + this.trip.Id,this.title  );
            }
            
            this.trip!.Device!.Informations! = new Array<Information>();
            
            this.trip!.Events = new Array<TripEvent>;
            this.trip.DeviceTripEvents?.forEach( (e) =>{
              this.trip!.Events!.push(e);

            });
            this.trip?.DeviceTripTrackInformations?.forEach((e)=>{
              this.trip!.Device!.Informations!.push(e.TrackInformation!);
            });
    
    
            this.trip.Id2 = addTrips[0];
    
            
            if(this.trip!.Device!.Informations && this.trip!.Device!.Informations!.length > 0 ){
              this.trip!.Device!.InformationFirst = this.trip!.Device!.Informations![0].TrackPosition?.Date;
              this.trip!.Device!.InformationLast = this.trip!.Device!.Informations![this.trip!.Device!.Informations!.length-1].TrackPosition?.Date;
            }
            this.loading = false;
          }
        });
        
        
      }, error: (err: ApiRequestObject)=> {

        console.log(err);
        this.resultApi = err;
        this.loading = false;
      }
    });
    
  }



  get positions(): Array<Information>{

    let pos = new Array<Information>();
    
    this.trip?.DeviceTripTrackInformations?.forEach((e)=>{
      pos.push(e.TrackInformation!);
    });
    return pos;

  }

}
