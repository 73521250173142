import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { ApiRequestObject, ListResult } from '../models/apiRequestObject';
import { User } from '../models/user';
import { Device, DeviceModel, Information, vDevice, vDeviceModel } from '../models/device';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { DocManagerFile, DocManagerFolder } from '../models/docManager';

@Injectable({
  providedIn: 'root'
})
export class DocManagerService extends ApiService{

  constructor(private http: HttpClient, router: Router, authSrv: AuthenticationService) {
    super(router, authSrv);
  }

  getMedia() {
    return this.http.get<ListResult>(`${environment.API_ENDPOINT}media`).pipe(
       catchError(this.error)
    );
  }

  

  create(folder: DocManagerFolder) : Observable<DocManagerFolder> {
    return this.http.post<DocManagerFolder>(`${environment.API_ENDPOINT}media`, folder).pipe(
       catchError(this.error)
    );
  }

  saveChangesById(media:DocManagerFolder){

    console.log(`${environment.API_ENDPOINT}media/${media.Id}`);
    return this.http.patch<DocManagerFolder>(`${environment.API_ENDPOINT}media/${media.Id}`,media).pipe(
      catchError(this.error)
   );
  }

  

  delete(media: DocManagerFolder ) : Observable<boolean> {
    return this.http.delete<boolean>(`${environment.API_ENDPOINT}media/${media.Id}`).pipe(
       catchError(this.error)
    );
  }

  upload(folder: DocManagerFolder) : Observable<DocManagerFolder> {
    return this.http.post<DocManagerFolder>(`${environment.API_ENDPOINT}media`, folder).pipe(
       catchError(this.error)
    );
  }

  file(info: FormData) : Observable<DocManagerFile>  {
    return this.http.post<DocManagerFile>(`${environment.API_ENDPOINT}media/file`, info).pipe(
       catchError(this.error)
      );

  }

  getFileToken(file : DocManagerFile): Observable<string> {
    return this.http.get<string>(`${environment.API_ENDPOINT}media/token/${file.Id}`).pipe();
  }

  getFileUrl(file : DocManagerFile, token: string):string {
    return `${environment.API_ENDPOINT}media/url/${file.Name}?token=${token}`;
  }
  
  saveFileById(media:DocManagerFile): Observable<DocManagerFile> {

    console.log(`${environment.API_ENDPOINT}media/file/${media.Id}`);
    return this.http.patch<DocManagerFile>(`${environment.API_ENDPOINT}media/file/${media.Id}`,media).pipe(
      catchError(this.error)
   );
  }

  deleteFile(media: DocManagerFile ) : Observable<boolean> {
    return this.http.delete<boolean>(`${environment.API_ENDPOINT}media/file/${media.Id}`).pipe(
       catchError(this.error)
    );
  }
}