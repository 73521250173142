<app-loading-custom [loading]="loadingDelete"></app-loading-custom>

<div *ngIf="showDefaultAlarm == true && authSrv.isAdmin() == true" class="text-right-label">.
  <!-- TODO: Se puede quitar o se tiene que obtener el id de modelo-->
  <div class="btn btn-primary m-2" [routerLink]="['/devices/models/' + '/alarms/default/add']">
    {{'modules.alarms.add-default-value' | translate}}
  </div>

</div>

<div *ngIf="datatableConfigColumns && datatableConfigColumns.headerTable" class="m-3">
  <table #userList [id]="nameDatatable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
    class="display nowrap table row-border hover stripe table-striped" style="width: 100% !important">
    <thead>
      <tr>
        <th *ngFor="let headColumn of datatableConfigColumns.headerTable" [attr.class]="'column-' + headColumn.column + ' column-' + headColumn.tag">
          {{ headColumn.name}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loading == true">
        <td [attr.colspan]="datatableConfigColumns!.headerTable!.length">{{'modules.shared.loading-info' | translate}}...</td>
      </tr>
      <tr *ngIf="loading == false && listaAlarmas.length == 0">
        <td [attr.colspan]="datatableConfigColumns!.headerTable!.length">{{'modules.shared.no-records' | translate}}.</td>
      </tr>

      <tr *ngFor="let alarm of listaAlarmas" [ngClass]="(alarm.Active == false)?'user-disabled':''">
        <td class="text-center">
          <i *ngIf="alarm.RoleUserCreated == 'admin' || alarm.RoleUserCreated == 'admin-dev'" class="fas fa-user-shield fa-lg m-2"></i>
          <i class="fas fa-layer-group fa-lg m-2" [ngClass]="alarm.RoleUserCreated == 'fabricant'?'text-danger':''"></i>
        </td>

        <td class="c-pointer">
          <a [routerLink]="['/alarms/model/' + alarm.Id  ]">{{ alarm.Name == '' ? (alarm.RoleUserCreated == 'admin'?('modules.alarms.alarm-admin' | translate) : alarm.RoleUserCreated == 'fabricant'?('modules.alarms.alarm-account' | translate):('modules.alarms.alarm-user' | translate) ) : alarm.Name??'-' }}</a>
        </td>
        <td class="c-pointer">
          <a [routerLink]="['/devices/models/' + alarm.IdModel + '/detail' ]">{{ alarm.NameModelDevice }}</a>
        </td>
        <td class="c-pointer">
          <a [routerLink]="['/users/' + alarm.IdManufacturer + '/detail' ]">{{ alarm.NameManufacturer }}</a>
        </td>


        <td>{{ alarm.CreatedAt | date:"dd-MM-yyyy" }}</td>


        <td class="c-pointer-i text-center">
          <i *ngIf="alarm.Active == false" class="far fa-square fa-lg pl-3" style="color: #000"></i>
          <i *ngIf="alarm.Active == true" class="far fa-check-square text-success fa-lg pl-3"></i>

        </td>

        <td class="c-pointer-i text-right">
          <i *ngIf="authSrv.isAdmin() || authSrv.currentUserValue?.UserId == alarm.IdUserCreated" (click)="deleteItem(alarm)" class="fa-solid fa-trash text-danger fa-lg" ></i>
          <a [routerLink]="['/alarms/model/' + alarm.Id  ]"><i
              class="fas fa-angle-double-right text-primary fa-lg pl-3"></i></a>
        </td>
      </tr>
    </tbody>
  </table>


</div>
