import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-simulate-trace-page',
  templateUrl: './simulate-trace-page.component.html',
  styleUrls: ['./simulate-trace-page.component.scss']
})
export class SimulateTracePageComponent implements OnInit {
  title: string = "";

  constructor(
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService
    ) {
    this.title = this.translate.instant('modules.alarms.management')
    breadcrumbService.setBreadCrumbUrl('alarms/list', this.title);

    this.title = this.translate.instant('modules.alarms.simulate-new-trace')
    this.breadcrumbService.addBreadCrumb('alarms/list',  this.title );
  }

  ngOnInit(): void {
  }

}
