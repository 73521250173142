import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-list-devices-page',
  templateUrl: './list-devices-page.component.html',
  styleUrls: ['./list-devices-page.component.scss']
})
export class ListDevicesPageComponent implements OnInit {

  public page?: number;
  loading:boolean = true;

  constructor(breadcrumbService: BreadcrumbService,
    public authSrv: AuthenticationService,
    private route: ActivatedRoute,
    private translate: TranslateService) {

    breadcrumbService.setBreadCrumbUrl('devices/list', this.translate.instant('modules.devices.management'));
    this.page = Number(this.route.snapshot.paramMap.get('page'))?? 0;
  }

  ngOnInit(): void {

  }

}
