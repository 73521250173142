import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { finalize, Subscription } from 'rxjs';
import { User } from 'src/app/models/user';
import { FilesService } from 'src/app/services/files.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-file-config-upload',
  templateUrl: './file-config-upload.component.html',
  styleUrls: ['./file-config-upload.component.scss']
})
export class FileConfigUploadComponent implements OnInit {
  @Input() typeForm:string = "ThemeFile";
  @Input() requiredFileType?:string;
  @Input() user?: User;
  @Input() textInput: string = this.translate.instant('modules.modals.select-image') + '.';




   @Output() commands =  new EventEmitter<Array<string>>();
   @Output() waitUploadShared =  new EventEmitter<boolean>();


  private emitNewFileShared() {
    this.commands.emit(this.commandsList!);
  }
  private emitWaitUploadShared() {
    this.waitUploadShared.emit(this.waitUpload);
  }

  waitUpload = false;
  commandsList: Array<string>|null = null;
  reader = new FileReader();
  fileName = '';
  uploadProgress:number|null = null;
  uploadSub: Subscription|null = null;

  constructor(
    private http: HttpClient,
    private filesSrv: FilesService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.emitWaitUploadShared();
  }

  onFileSelected(event: any) {

    const file:File = event.target.files[0];
    this.waitUpload = true;
    this.commandsList  = null;

    if (file) {
        this.fileName = file.name;
        const formData = new FormData();
        formData.append("File", file);
        formData.append("TypeFile", this.typeForm);


        console.log(this.fileName);

        //const upload$ = this.userSrv.uploadLogoTheme(file , this.user!.Id!);
        //upload$.subscribe();

        const upload$ = this.filesSrv.uploadConfig(formData );

        this.uploadSub = upload$.subscribe(event => {
          if (event.type == HttpEventType.UploadProgress) {
            this.uploadProgress = Math.round(100 * (event.loaded / event!.total!));
          }
          if (event.type == HttpEventType.Response){
            console.log("finish");
            console.log(event.body);
            this.commandsList = event.body;
            this.emitNewFileShared();
            this.waitUpload = false;

          }
        },error =>{
          console.log("Error")
        }
        );
    }else{

      this.waitUpload = false;
    }
}

cancelUpload() {
  this.uploadSub!.unsubscribe();
  this.reset();
}

reset() {
  this.uploadProgress = null;
  this.uploadSub = null;
}

}
