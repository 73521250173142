import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit, Input, SimpleChanges, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/services/users.services';
import { UtilsService } from 'src/app/services/utils.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ListResult } from 'src/app/models/apiRequestObject';
import { DevicesService } from 'src/app/services/devices.service';
import { Device, DeviceModel, vDevice } from 'src/app/models/device';
import { User } from 'src/app/models/user';
import { DatatableColumn, DatatableConfig } from 'src/app/models/datatableColumn';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/modals/confirmation-modal/confirmation-modal.component';
import { LinkModal, Modal } from 'src/app/models/modal';
import { DeviceAddQuickComponent } from 'src/app/modals/device-add-quick/device-add-quick.component';
import { AuthenticationService } from 'src/app/services/authentication.service';

declare const $: any

@Component({
  selector: 'app-list-devices',
  templateUrl: './list-devices.component.html',
  styleUrls: ['./list-devices.component.scss']
})
export class ListDevicesComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {


  @Input() showNewDevice: boolean = true;
  @Input() userInput: User | null = null;
  @Input() modelInput: DeviceModel | null = null;

  @Input() isManufacturer: boolean | null = null;
  @Input() isClient: boolean | null = null;
  selectedState: number = 1;

  @ViewChild(DataTableDirective, {static: false}) datatableElement?: DataTableDirective;

  /* Datatable parameters */
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  nameDatatable: string = "DeviceList";
  isDtInitialized: boolean = false;
  datatableConfigColumns: DatatableConfig = new DatatableConfig();
  viewPaging: boolean = true;
  selectedItems: boolean = false;


  public ngOnChanges(changes: SimpleChanges): void {
    console.log("ngOnChanges");
    this.rerender();
  }

  listaDispositivos: vDevice[] = [];
  selectedDevicesList: vDevice[] = [];

  loading: boolean = true;
  loadingDelete: boolean = false;
  loadingDtOptions: boolean = true;

  get isViewManufacturer(): boolean{

    if(this.userInput){
      return this.userInput.isManufacturer;
    }
    return false;
  }

  get isViewClient(): boolean{

    if(this.userInput){
      return this.userInput.isClient;
    }
    return false;
  }

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private router : Router,
    public authSrv: AuthenticationService,
    private usersSrv : UsersService,
    private deviceSrv : DevicesService,
    private utilsSrv : UtilsService) {


    }

    ngAfterViewInit(): void {


      if(this.userInput){
        console.log("userInput");
        console.log(this.userInput);
      }

      console.log("ngAfterViewInit");
        const that = this;

        that.dtTrigger.next(0);
        /*
        setTimeout(function () {
          $('#' + that.nameDatatable).DataTable().destroy();
          that.dtTrigger.next(0);
          that.datatableElement!.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
          });

        }, 300); */
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  ngOnInit(): void {

    this.dtOptions = this.optionsDatatable();
  }

  public optionsDatatable() {
    const that = this;


    this.datatableConfigColumns.headerTable = [
      new DatatableColumn(0,"#select", "", "asc"),
      new DatatableColumn(1,"IMEI", "IMEI", "asc"),
      new DatatableColumn(2,this.translate.instant('modules.tables.device-name'), "Name", "asc"),
      new DatatableColumn(3,this.translate.instant('modules.tables.model-device'), "NameDeviceModel", "asc"),
      new DatatableColumn(4,"ICCID", "ICCID", "asc"),
      new DatatableColumn(5,this.translate.instant('modules.tables.serial-number'), "NumSerieManufacturer", "asc"),
      new DatatableColumn(6,this.translate.instant('modules.tables.comments'), "Comments", "asc"),
      new DatatableColumn(7,"Firm.", "FirmwareVersion", "asc"),
      new DatatableColumn(8,this.translate.instant('modules.tables.name-client'), "NameClient", "asc"),
      new DatatableColumn(9,this.translate.instant('modules.tables.account'), "NameManufacturer", "asc"),
      new DatatableColumn(10,this.translate.instant('modules.tables.created-at'), "CreatedAt", "desc"),
      new DatatableColumn(11,"Online", "OnlineDate", "desc"),
      new DatatableColumn(12,this.translate.instant('modules.tables.actions'), "", "asc")
    ];

    console.log("isManufacturer" , this.isManufacturer);
    console.log("isClient" , this.isClient);
    console.log("userInput" , this.userInput);

    if(this.userInput){
      if(this.userInput.isClient){
        this.datatableConfigColumns.hideColumnByTag("NameClient");
      }
    }

    if(this.authSrv.isAdmin() == false && this.authSrv.isManufacturer() == false ){
      this.datatableConfigColumns.hideColumnByTag("NumSerieManufacturer");
      this.datatableConfigColumns.hideColumnByTag("Comments");
    }

    if(this.authSrv.isAdmin() == false){

      this.datatableConfigColumns.hideColumnByTag("Name");
      this.datatableConfigColumns.hideColumnByTag("ICCID");
      this.datatableConfigColumns.hideColumnByTag("FirmwareVersion");
    }

    if(this.authSrv.isClient()){
      this.datatableConfigColumns.hideColumnByTag("OnlineDate");
    }


    if( (this.isManufacturer != null && this.isManufacturer == true) || (this.userInput != null && this.userInput.isManufacturer == true) ){
      this.datatableConfigColumns.hideColumnByTag("NameManufacturer");
    }

    if(this.isClient != null && this.isClient == true){
      this.datatableConfigColumns.hideColumnByTag("NameClient");
    }

    this.datatableConfigColumns.changeOrderByName("Online");
    this.datatableConfigColumns.orderDesc();

    console.log(this.datatableConfigColumns.order);
    console.log(this.datatableConfigColumns.columnDefs);

    this.loadingDtOptions = false;

    return {
      name: this.nameDatatable,
      order: this.datatableConfigColumns.order,
      columnDefs: this.datatableConfigColumns.columnDefs,
      lengthChange: true,
      serverSide: true,
      processing: false,
      retrieve: true,
      //stateSave: true,
      deferRender: true,
      initComplete: (settings: DataTables.Settings, json: any) => {
        $("#" + this.nameDatatable).wrap("<div class='demo' style='overflow:auto; width:100%;position:relative;'></div>");
        if(!this.viewPaging){
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().parent().find("div").eq(0).removeClass("col-md-5").addClass("col-md-12");
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().css("display", "none" );
        }else{
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().parent().find("div").eq(0).removeClass("col-md-12").addClass("col-md-5");
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().css("display", "block" );
        }
      },
      ajax: (dataTablesParameters: any, callback: any) => {

        that.loading = true;

        let column = dataTablesParameters.order[0].column;
        let colmunOrder = this.datatableConfigColumns.getColumnById(column);
        let colmunOrderTag = colmunOrder?.tag!;
        let order = dataTablesParameters.order[0].dir;
        let draw = dataTablesParameters.draw;

        dataTablesParameters.search.regex = true
        let page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length);
        this.listaDispositivos = [];


        console.log(colmunOrder);

        that.deviceSrv.getLiteList(page, dataTablesParameters.length, colmunOrderTag, order, dataTablesParameters.search.value, this.userInput??undefined, this.modelInput??undefined).subscribe(
          {
            next: (res: ListResult) => {

            //that.listaUsuarios = res.result.map(x => new User (x));
            //that.listaDispositivos = <vDevice[]>res.Result;
            that.listaDispositivos = <vDevice[]>res.Result.map( x => new vDevice(x));

            // Activa los checklist de los modelos seleccionados entre paginas
            for (let index = 0; index < this.listaDispositivos.length; index++) {
              const modelo = this.listaDispositivos[index];
              if (this.selectedDevicesList.some(selModel => selModel.Id === modelo.Id)) {
                modelo.Selected = true;
              }
            }
            this.checkSelectedState();

            this.viewPaging = false;
            if(res.Filter > 10){
              this.viewPaging = true;
            }

            callback({
              recordsTotal: res.Total,
              recordsFiltered: res.Filter,
              data: []
            });

            //this.rerender();
            //this.dtTrigger.next(0);
            that.loading = false;

            //$('.dataTables_empty').css('display', 'none');

            if (res.Result.length > 0) {
              $('.dataTables_empty').css('display', 'none');
            }else{
              //this.dtTrigger.next(0);
              //$('.dataTables_empty').css('display', 'none');

            }
            //document.querySelectorAll<HTMLElement>('.dataTables_empty')[0].style.display = 'none';
            that.columnAdjust();


          },
          error: ( err : any) => {
            that.loading = false;
          }
        });


      },

      headerCallback: (thead: any, data: any, start: any, end: any, display: any) => {

      },
      language: {
        url: this.utilsSrv.DatatablesFileLang()
      },
      infoCallback: function( settings: any, start:number, end:number, max:number, total:number, pre: any ) {
        return that.datatableConfigColumns.DatatablesFooterShowText(settings, start,end, max, total , pre );
      },
    };

  }

  columnAdjust(): void {
    $($.fn.dataTable.tables(true)).DataTable().columns.adjust().responsive.recalc();
  }

  deleteItem(device: vDevice){


    let modal = new Modal();
    modal.Title = this.translate.instant('modules.shared.warning');
    modal.Body = this.translate.instant('modules.modals.delete-device') + " " + device.Name??'';
    modal.BtnAccept = this.translate.instant('modules.shared.delete')
    modal.BtnCancel = this.translate.instant('modules.shared.cancel');
    modal.ClassType = "danger";

    const dialogRef = this.dialog.open(
      ConfirmationModalComponent,
      {
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: modal,
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe( (result: boolean) => {
      if(result){

        this.loadingDelete = true;

        this.deviceSrv.delete(device).subscribe({
          next: (res: boolean) => {
            if(res == true){
             // this.dtTrigger.next(0);
              this.rerender();
            }
          },
          error: (err: any) =>{
            console.log(err);
            if(err.status! == "MODAL"){

              this.utilsSrv.infoModal(err.msg!, undefined, err.title);
            }
            this.loadingDelete = false;
          },
          complete: () =>{
            this.loadingDelete = false;
          }
        });



        console.log("eliminar dispositivo " , device.Name , " " , device.Id)
      }
    });

  }

  rerender(){


    if(this.datatableElement){
      this.datatableElement!.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.clear().destroy();
        this.dtTrigger.next(0);
      });
    }
  }

  newDevice()
  {

    console.log(this.userInput);
    let data;

    data = {'Data' : { 'fromList' : true  }};

    if(this.userInput){
      data = { 'Data' : { "idUser" : this.userInput!.Id }, 'User' : { ...this.userInput! } };
    }

    if(this.modelInput){
      data = { 'Data' : { "idModel" : this.modelInput!.Id, 'DeviceModel' : { ...this.modelInput! }}};
    }

    console.log("data", data);

    const dialogRef = this.dialog.open(
      DeviceAddQuickComponent,
      {
        id: 'DeviceAddQuickComponent',
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: new Modal( data ),
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe( (result?: vDevice) => {
      if(result){
        this.rerender();

      }

    });
  }

  selectUnselectAll() {
    if (this.listaDispositivos.some( m => m.Selected === false )) {
      if (this.selectedDevicesList.length === 0){
        this.selectedDevicesList = [...this.listaDispositivos];
        for (let index = 0; index < this.listaDispositivos.length; index++) {
          this.listaDispositivos[index].Selected = true;
        }
      } else {
        for (let index = 0; index < this.listaDispositivos.length; index++) {
          const unique = this.selectedDevicesList.findIndex(model => model.Id === this.listaDispositivos[index].Id);
          if (unique === -1) {
            this.selectedDevicesList.push(this.listaDispositivos[index]);
            this.selectedDevicesList[ this.selectedDevicesList.length-1].Selected = true;
          }
        }
      }
      this.selectedItems = true;
    } else {
      for (let index = 0; index < this.listaDispositivos.length; index++) {
        this.listaDispositivos[index].Selected = false;
        this.selectedDevicesList.splice(this.selectedDevicesList.findIndex(model => model.Id === this.listaDispositivos[index].Id), 1);
      }
      this.selectedItems = false;
    }
    this.checkSelectedState();
  }

  checkSelectedState() {
    if (this.selectedDevicesList.length === 0) {
      this.selectedState = 1;
    } else if (!this.listaDispositivos.some( m => m.Selected === false )) {
      this.selectedState = 2
    } else {
      this.selectedState = 3;
    }
  }



  checkuncheckall(device: vDevice)
  {
    device.Selected = !device.Selected;

    this.selectedItems = false;
    for(let i = 0; i < this.listaDispositivos.length; i++){
      if(this.listaDispositivos[i].Selected == true){
        this.selectedItems = true;
        break;
      }
    }
    if (device.Selected == true) {
      if (!this.selectedDevicesList.some(model => model.Id === device.Id)) {
        this.selectedDevicesList.push(device);
      }
    } else {
      this.selectedDevicesList.splice(this.selectedDevicesList.findIndex(model => model.Id === device.Id), 1);
    }
    this.checkSelectedState();
  }


  loadFirmaware(){

    let text1 = `${this.translate.instant('modules.modals.load-firmware')}:
                <ul>
                ${this.selectedDevicesList.map(m => '<li>#' + m.Id + ' - ' + m.IMEI) + '</li>'}
                </ul>`;


    let bodyText =  text1;

    let modal = new Modal();
    modal.Title = this.translate.instant('modules.shared.warning');
    modal.Body = bodyText;
    modal.BtnAccept = this.translate.instant('modules.modals.next');
    modal.BtnCancel = this.translate.instant('modules.shared.cancel');
    modal.ClassType = "danger";

    const dialogRef = this.dialog.open(
      ConfirmationModalComponent,
      {
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: modal,
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'
    });

    // Desabilita botó 'Eliminar' si no hi ha elements a eliminar
    if (this.selectedDevicesList.length === 0) {
      const btn = document.querySelector('.btn-primary') as HTMLButtonElement | null;
      btn?.setAttribute('disabled', '');
    }

    dialogRef.afterClosed().subscribe( (result: boolean) => {

      let arrayIds: Array<number> = []
      if(result == true){
        this.selectedDevicesList.forEach( (x: vDevice) => {
          arrayIds.push(x.Id!)
        });
        console.log(arrayIds.join(","));
        this.router.navigate(['/devices/load-firmware'], { queryParams: { 'ids' : arrayIds } } );
      }
    });
  }

  loadConfiguration(){

    let text1 = `${this.translate.instant('modules.modals.load-config')}:
                <ul>
                ${this.selectedDevicesList.map(m => '<li>#' + m.Id + ' - ' + m.IMEI) + '</li>'}
                </ul>`;


    let bodyText =  text1;

    let modal = new Modal();
    modal.Title = this.translate.instant('modules.shared.warning');
    modal.Body = bodyText;
    modal.BtnAccept = this.translate.instant('modules.modals.next');
    modal.BtnCancel = this.translate.instant('modules.shared.cancel');
    modal.ClassType = "danger";

    const dialogRef = this.dialog.open(
      ConfirmationModalComponent,
      {
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: modal,
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'
    });

    // Desabilita botó 'Eliminar' si no hi ha elements a eliminar
    if (this.selectedDevicesList.length === 0) {
      const btn = document.querySelector('.btn-primary') as HTMLButtonElement | null;
      btn?.setAttribute('disabled', '');
    }

    dialogRef.afterClosed().subscribe( (result: boolean) => {

      let arrayIds: Array<number> = []
      if(result == true){
        this.selectedDevicesList.forEach( (x: vDevice) => {
          arrayIds.push(x.Id!)
        });
        console.log(arrayIds.join(","));
        this.router.navigate(['/devices/load-configuration'], { queryParams: { 'ids' : arrayIds } } );
      }
    });
  }

}
