import { AfterViewInit, Component, HostListener, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Device, Information, InformationChart } from 'src/app/models/device';
import { ChartComponentLike, ChartConfiguration, ChartEvent, ChartType, InteractionItem, InteractionModeFunction, LegendItem, UpdateMode } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import * as moment from 'moment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DevicesService } from 'src/app/services/devices.service';
import 'chartjs-adapter-moment';
import * as Chart from 'chart.js';
import 'chartjs-plugin-autocolors';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-timeline-chart',
  templateUrl: './timeline-chart.component.html',
  styleUrls: ['./timeline-chart.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})
export class TimelineChartComponent implements OnInit, AfterViewInit {
  @Input() device?: Device;
  @Input() showTitle: boolean = false;
  loading: boolean = true;
  noData: boolean = false;

  listSensor: Array<String> = [
    this.translate.instant('modules.alarms.rpm'),
    this.translate.instant('modules.alarms.horas-trabajo'),
    this.translate.instant('modules.alarms.presion'),
    this.translate.instant('modules.alarms.caudal'),
    this.translate.instant('modules.alarms.temp-aceite'),
    this.translate.instant('modules.alarms.nivel-aceite'),
    this.translate.instant('modules.alarms.num-acciones'),
    this.translate.instant('modules.alarms.peso'),
    this.translate.instant('modules.alarms.rpm-2'),
    this.translate.instant('modules.alarms.rpm-3'),
    this.translate.instant('modules.alarms.presion-2'),
    this.translate.instant('modules.alarms.presion-3'),
    this.translate.instant('modules.alarms.caudal-2'),
    this.translate.instant('modules.alarms.caudal-3'),
    this.translate.instant('modules.alarms.peso-2'),
    this.translate.instant('modules.alarms.num-acciones-2'),
    this.translate.instant('modules.alarms.num-acciones-3'),
    this.translate.instant('modules.alarms.aux-1'),
    this.translate.instant('modules.alarms.aux-2'),
    this.translate.instant('modules.alarms.aux-3'),
    this.translate.instant('modules.alarms.aux-4'),
    this.translate.instant('modules.alarms.aux-5')
  ];

  listSensorView: Array<String> = [
    this.translate.instant('modules.alarms.rpm'),
    this.translate.instant('modules.alarms.horas-trabajo')
  ];

  cursorLastX = 0;
  cursorLastY = 0;

  dropdownSensor : IDropdownSettings= {
    singleSelection: false,
    limitSelection: 4,
    selectAllText: this.translate.instant('modules.shared.select-all'),
    unSelectAllText: this.translate.instant('modules.shared.unselect-all'),
    allowSearchFilter: true,
    //noFilteredDataAvailablePlaceholderText: "No hay coincidencias",
    noDataAvailablePlaceholderText: this.translate.instant('modules.shared.no-available-sensors'),
    searchPlaceholderText: this.translate.instant('modules.shared.search'),
    clearSearchFilter: true,
  };

  InformationFirstValue: moment.Moment = moment();
  InformationLastValue: moment.Moment = moment();

  InformationFirstValueZoom:  moment.Moment = moment();
  InformationLastValueZoom: moment.Moment = moment();

  dates = [];
  values = [];


  filterChartForm: FormGroup = this.formBuilder.group({
  });

  infoChart: InformationChart = new InformationChart();



  constructor(
    private formBuilder: FormBuilder,
    private deviceSrv: DevicesService,
    public authSrv : AuthenticationService,
    private translate: TranslateService) { }

  get InformationFirst(){ return this.filterChartForm.get("InformationFirst"); }
  get InformationLast(){ return this.filterChartForm.get("InformationLast"); }

  public lineChartData: ChartConfiguration['data'] = {
    datasets: [
    ],
    labels: []
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    layout:{
      padding:{
        left: 0,
        right: 60,
        bottom: 20
      }
    },
    interaction: {
      intersect: true,
      mode: "nearest",
      axis: "x",
    },
    scales: {
      x: {
        ticks: {
          padding: 15,
        },
        offsetAfterAutoskip: true,
        type: 'time',
        time: {
          unit: 'day',
          stepSize: 1,
          tooltipFormat: 'DD-MM-YYYY HH:mm:ss',

        },

        title: {
          display: false,
          text: 'Fechas'
        },
        min: this.InformationFirstValueZoom.format("YYYY-MM-DD HH:mm:ss"),
        max: this.InformationLastValueZoom.format("YYYY-MM-DD HH:mm:ss")
      },
      y: {
        //beginAtZero: true,
        display: true,
        title: {
          display: true,
          text: 'Valores'
        }
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip:{
        enabled: true,
        intersect: false,
        mode: 'nearest'
      },
      legend: {
        display: true,
        /*labels: {
          generateLabels: (chart) => {

            //return "test";
            return chart.data.datasets.map((data, i) => ( <LegendItem>{
              text: `${chart.data.datasets[i].label} ${data}`,
              fillStyle: chart.data.datasets[i].backgroundColor,
              hidden: (i> 2)? true: false
            }))

          },
        }*/
      },
      datalabels: {
        display: false
      },

    }
  };

  public lineChartType: ChartType = 'line';



  public timeLinePlugins: ChartComponentLike[] = [ ];



  public lineChartDataTimeline: ChartConfiguration['data'] = {
    datasets: [
    ],
    labels: []
  };
  public lineChartOptionsTimeline: ChartConfiguration['options'] = {
    layout:{
      padding:{
        left: 30,
        right: 30
      }
    },
    aspectRatio: 10,
    scales: {
      x: {
        type: 'time',
        time: {
          // Luxon format string
          unit: 'day',
        },
        min: this.InformationFirstValueZoom.format("YYYY-MM-DD HH:mm:ss"),
        max: this.InformationLastValueZoom.format("YYYY-MM-DD HH:mm:ss"),
        ticks: {
          display: true
        },
        grid:{
          display: true
        }
      },
      y: {
        beginAtZero: false,
        display: false,
        ticks: {
          display: false
        },
        grid:{
          display: false
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip:{
        enabled: false
      },
      legend: { display: false },
      datalabels: {
        display: false
      }
    }
  };

  //@ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  @ViewChildren(BaseChartDirective)
  charts!: QueryList<BaseChartDirective>;

  ngAfterViewInit(): void {

    console.log("ngAfterViewInit");
    /*
    setTimeout( () => {
      this.pintarTimeline();
    }, 1500 );*/
    //this.pintarTimeline();

  }

  ngOnInit(): void {

    if(this.device!.InformationFirst){
      this.InformationFirstValue = moment(this.device?.InformationFirst);
      this.InformationLastValue = moment(this.device?.InformationLast);
    }



    console.log(this.InformationFirstValue);
    console.log(this.InformationLastValue);

    this.filterChartForm = this.formBuilder.group({
      InformationFirst: new FormControl(this.InformationFirstValue, [
        Validators.required
      ]),
      InformationLast: new FormControl(this.InformationLastValue, [
        Validators.required
      ]),
      Sensors: new FormControl(this.listSensorView, [
        Validators.required
      ])
    });

    if(this.device!.InformationFirst){
      this.loadChart();
    }else{
      this.noData = true;
    }
    this.loading = false;
  }

  public reloadInfo(){
    console.log('loadDevice: ' , this.device!.Id );
    this.deviceSrv.getTrackInformations(this.device!.Id!, this.InformationFirstValue.format("DD/MM/YYYY"), this.InformationLastValue.format("DD/MM/YYYY")).subscribe({
      next: (res: Array<Information>) => {
        this.device!.Informations! = [];
        res.forEach( element => {
          this.device!.Informations!.push(element);
        });

        if(res.length > 0 ){

          this.device!.InformationFirst = res[0].TrackPosition!.Date!;
          this.device!.InformationLast = res[res.length - 1].TrackPosition!.Date!;
          this.device!.InformationFirst = this.InformationFirstValue.toDate();
          this.device!.InformationLast = this.InformationLastValue.toDate();
        }


        console.log(this.device );
      }, error: ()=> {

      },
      complete: ()=>{
        this.loading = false;
        this.loadChart(true);
      }
    });
  }

  public loadChart(refresh: boolean= false){

    let opcionesColor : any= {
      'borderWidth': 2,
      'hoverOffset': 0,
      'cubicInterpolationMode': 'default', 'pointRadius': 0, 'hoverRadius': 0
    };




    this.infoChart!.RPM! = this.device?.Informations?.map((element) => element.RPM);
    this.infoChart!.WorkingHours! = this.device?.Informations?.map((element) => element.WorkingHours);
    this.infoChart!.Pressure1! = this.device?.Informations?.map((element) => element.Pressure1);
    this.infoChart!.Pressure2! = this.device?.Informations?.map((element) => element.Pressure2);
    this.infoChart!.Flow1! = this.device?.Informations?.map((element) => element.Flow1);
    this.infoChart!.Flow2! = this.device?.Informations?.map((element) => element.Flow2);
    this.infoChart!.Action1! = this.device?.Informations?.map((element) => element.Action1);
    this.infoChart!.Action2! = this.device?.Informations?.map((element) => element.Action2);
    this.infoChart!.Action3! = this.device?.Informations?.map((element) => element.Action3);
    this.infoChart!.Action4! = this.device?.Informations?.map((element) => element.Action4);
    this.infoChart!.Action5! = this.device?.Informations?.map((element) => element.Action5);
    this.infoChart!.Action6! = this.device?.Informations?.map((element) => element.Action6);
    this.infoChart!.Weight1! = this.device?.Informations?.map((element) => element.Weight1);
    this.infoChart!.Weight2! = this.device?.Informations?.map((element) => element.Weight2);
    this.infoChart!.Aux1! = this.device?.Informations?.map((element) => element.Aux1);
    this.infoChart!.Aux2! = this.device?.Informations?.map((element) => element.Aux2);
    this.infoChart!.Aux3! = this.device?.Informations?.map((element) => element.Aux3);
    this.infoChart!.Aux4! = this.device?.Informations?.map((element) => element.Aux4);
    this.infoChart!.Aux5! = this.device?.Informations?.map((element) => element.Aux5);
    this.infoChart!.Aux6! = this.device?.Informations?.map((element) => element.Aux6);
    this.infoChart!.Aux7! = this.device?.Informations?.map((element) => element.Aux7);
    this.infoChart!.Aux8! = this.device?.Informations?.map((element) => element.Aux8);

    console.log("RPM", ...this.infoChart!.RPM! );

    let data = [
      {...this.infoChart!.RPM! },
      {...this.infoChart!.WorkingHours! },
      {...this.infoChart!.Pressure1! },
      {...this.infoChart!.Pressure2! },
      {...this.infoChart!.Flow1! },
      {...this.infoChart!.Flow2! },
      {...this.infoChart!.Action1! },
      {...this.infoChart!.Action2! },
      {...this.infoChart!.Action3! },
      {...this.infoChart!.Action4! },
      {...this.infoChart!.Action5! },
      {...this.infoChart!.Action6! },
      {...this.infoChart!.Weight1! },
      {...this.infoChart!.Weight2! },
      {...this.infoChart!.Aux1! },
      {...this.infoChart!.Aux2! },
      {...this.infoChart!.Aux3! },
      {...this.infoChart!.Aux4! },
      {...this.infoChart!.Aux5! },
      {...this.infoChart!.Aux6! },
      {...this.infoChart!.Aux7! },
      {...this.infoChart!.Aux8! },

    ];

    console.log(data);
    let totalCount = 0;
    this.lineChartData.datasets = [];
    this.lineChartDataTimeline.datasets = [];

    for(let i = 0; i < data.length; i++){
      //this.lineChartData.datasets[i].data = data[i];
      //this.lineChartData.datasets[i].label = labelDataset[i];

      if(this.listSensorView.includes(this.listSensor[i])){

        let arr: any = [];
        Object.keys(data[i]).map(function(key){
            arr.push(data[i][key])
        });

        console.log(this.listSensor[i]);
        console.log(data[i]);

        //this.lineChartData.datasets = [];

        if(arr.length > 0){
          totalCount += 1;
          this.lineChartData.datasets.push( { 'data': arr, 'label': this.listSensor[i], /*'hidden': (i > 1)?true:false,*/ ...opcionesColor } );

          if(i==0){
            let arrResize = arr.map( (val:  number) => (val * 0.25));
            // this.lineChartData.datasets.push( { 'type': 'bar', 'data': arrResize, 'label': labelDataset[i], 'hidden': (i > 1)?true:false, 'yAxisID': 'volume', 'backgroundColor': '#666', ...opcionesColor } );

            console.log(data[i])
            let auxArray: Array<number> = [];
            auxArray.push(1)
            auxArray.push(2)
            auxArray.push(3)
            auxArray.push(4)
            console.log(auxArray)

          }
          this.lineChartDataTimeline.datasets.push( { 'data': arr, 'label': this.listSensor[i], /*'hidden': (i > 1)?true:false, */ ...opcionesColor } );

        }
      }
    }

    console.log(this.lineChartData.datasets)

    this.lineChartData.labels = this.device?.Informations?.map((element) =>moment(element.TrackPosition!.Date).utc());
    this.lineChartDataTimeline.labels = this.lineChartData.labels;


    console.log(this.lineChartData.labels);
    if(totalCount==0){
      this.noData = true;
    }else{
      this.noData = false;

      if( this.InformationFirstValue == null){
        this.InformationFirstValue = moment(this.device?.InformationFirst);
      }
      if(this.InformationLastValue  == null){
        this.InformationLastValue = moment(this.device?.InformationLast);
      }
      /*
      console.log(this.InformationFirstValue);
      console.log(this.InformationLastValue);

      this.filterChartForm = this.formBuilder.group({
          Validators.required
        ]),
        InformationLast: new FormControl(this.InformationLastValue, [
          Validators.required
        ])
      });*/
    }


    let auxMinDate = this.lineChartData.labels![0] as moment.Moment;
    let auxMaxDate  = this.lineChartData.labels![this.lineChartData.labels!.length - 1] as moment.Moment;

    this.InformationFirstValueZoom = moment(this.device!.InformationFirst);
    this.InformationLastValueZoom = moment(this.device!.InformationLast);

    this.lineChartOptions!.scales!['x']!.min = moment(this.device!.InformationFirst).format("YYYY-MM-DD HH:mm:ss");
    this.lineChartOptions!.scales!['x']!.max = moment(this.device!.InformationLast).format("YYYY-MM-DD HH:mm:ss");

    this.lineChartOptionsTimeline!.scales!['x']!.min = moment(this.device!.InformationFirst).format("YYYY-MM-DD HH:mm:ss");
    this.lineChartOptionsTimeline!.scales!['x']!.max = moment(this.device!.InformationLast).format("YYYY-MM-DD HH:mm:ss");
    //this.lineChartOptions!.scales!['x']!.min = moment().subtract(30,'days').toDate().toDateString();
    //this.lineChartOptions!.scales!['x']!.max = moment().add(30,'days').toDate().toDateString();
    //this.charts.first.chart!.config.options!.scales!['x']!.min = auxMinDate.toDate().toTimeString();
    //this.charts.first.chart!.config.options!.scales!['x']!.max = auxMaxDate.toDate().toTimeString();

    if(refresh){
      Chart.Chart.getChart("mainChart")!.config.options!.scales!['x']!.min = this.InformationFirstValueZoom.format("YYYY-MM-DD HH:mm:ss");
      Chart.Chart.getChart("mainChart")!.config.options!.scales!['x']!.max = this.InformationLastValueZoom.format("YYYY-MM-DD HH:mm:ss");
      Chart.Chart.getChart("timelineChart")!.config.options!.scales!['x']!.min = this.InformationFirstValueZoom.format("YYYY-MM-DD HH:mm:ss");
      Chart.Chart.getChart("timelineChart")!.config.options!.scales!['x']!.max = this.InformationLastValueZoom.format("YYYY-MM-DD HH:mm:ss");


      this.updateChart("mainChart",'none');
      this.updateChart("timelineChart",'none');
    }else{
    }
    //this.ocultarDatasets();

    const that = this;
    setTimeout(function () {
      that.pintarTimeline();
    }, 2000);

  }

  public chartZoom(  event: Event): void {
    console.log("chartZoom ", event.type);


    if(event.type == "mousewheel"){
      let wheelEvent = <WheelEvent>event;

      if (wheelEvent.deltaY < 0) {
        this.zoomin(wheelEvent);
      }else{
        this.zoomout(wheelEvent);
      }
    }

    return;

    if(event.type == "mousewheel"){
      let wheelEvent = <WheelEvent>event;
      const min = Chart.Chart.getChart("mainChart")!.config.options!.scales!['x']!.min!;
      const max = Chart.Chart.getChart("mainChart")!.config.options!.scales!['x']!.max!;
      console.log('min', moment(min).format("DD/MM/YYYY HH:mm:ss"));
      console.log('max', moment(max).format("DD/MM/YYYY HH:mm:ss"));

      //console.log(this.lineChartData.labels![0]);
      //console.log(this.lineChartData.labels![this.lineChartData.labels!.length - 1]);

      let auxMinDate : moment.Moment;
      let auxMaxDate : moment.Moment;

      let segment: number = (this.lineChartData.labels!.length > 50)?50:this.lineChartData.labels!.length;


      //console.log(auxMinDate);
      //console.log(auxMaxDate);
      console.log("deltaY", wheelEvent.deltaY );

      if (wheelEvent.deltaY < 0) {
        console.log("zoom in");
        //this.zoomIn();
        //let firstDate = this.lineChartData.labels![this.lineChartData.labels!.indexOf(min) + 1];
        //console.log(firstDate);
        let filtroMin = this.lineChartData.labels!.filter(
          (x) => {
            let aux = x as moment.Moment;
            //console.log("comapre ", aux.format("DD/MM/YYYY HH:mm:ss") , " - " , moment(min).format("DD/MM/YYYY HH:mm:ss"));
            return aux.format("DD/MM/YYYY HH:mm:ss") === moment(min).format("DD/MM/YYYY HH:mm:ss");
            // moment(x).format("DD/MM/YYYY HH:mm:ss") === moment(min).format("DD/MM/YYYY HH:mm:ss")
          }
        );

        let filtroMax = this.lineChartData.labels!.filter(
          (x) => {
            let aux = x as moment.Moment;
            return aux.format("DD/MM/YYYY HH:mm:ss") === moment(max).format("DD/MM/YYYY HH:mm:ss");
            // moment(x).format("DD/MM/YYYY HH:mm:ss") === moment(min).format("DD/MM/YYYY HH:mm:ss")
          }
        );

        console.log("filtroMin ", filtroMin);
        console.log("filtroMax ", filtroMax);

        if(filtroMin.length == 0){
          auxMinDate = this.lineChartData.labels![0] as moment.Moment;
        }else{
          auxMinDate = filtroMin[0]  as moment.Moment;
        }
        if(filtroMax.length == 0){
          auxMaxDate = this.lineChartData.labels![this.lineChartData.labels!.length - 1] as moment.Moment;
        }else{
          auxMaxDate = filtroMax[0]  as moment.Moment;
        }

        if(this.lineChartData.labels!.indexOf(moment(min)) == -1){
          console.log("no trobat min");
          auxMinDate = this.lineChartData.labels![0] as moment.Moment;
        }else {
          auxMinDate = this.lineChartData.labels![this.lineChartData.labels!.indexOf(min) + segment ] as moment.Moment;
        }
        if(this.lineChartData.labels!.indexOf(moment(max)) == -1){
          console.log("no trobat max");
          auxMaxDate = this.lineChartData.labels![this.lineChartData.labels!.length - 1] as moment.Moment;
        }else {
          auxMaxDate = this.lineChartData.labels![this.lineChartData.labels!.indexOf(max) - segment ] as moment.Moment;
        }

        console.log(auxMinDate.format("DD/MM/YYYY HH:mm:ss"));
        console.log(auxMaxDate.format("DD/MM/YYYY HH:mm:ss"));
        console.log("auxMinDate ", auxMinDate.format("DD/MM/YYYY HH:mm:ss"));
        console.log("auxMaxDate ", auxMaxDate.format("DD/MM/YYYY HH:mm:ss"));

        let a = this.lineChartData.labels![0] as moment.Moment;
        let b = this.lineChartData.labels![this.lineChartData.labels!.length - 1] as moment.Moment;
        console.log("a ", a.format("DD/MM/YYYY HH:mm:ss"));
        console.log("b ", b.format("DD/MM/YYYY HH:mm:ss"));
        //console.log("auxMaxDate ", moment(this.lineChartData.labels![this.lineChartData.labels?.length-1]!).format("DD/MM/YYYY HH:mm:ss"));

        //this.InformationFirstValueZoom = this.InformationFirstValueZoom.add(1, 'day');
        //this.InformationLastValueZoom = this.InformationLastValueZoom.subtract(1, 'day');
        //this.charts.first.chart!.config.options!.scales!['x']!.min = auxMinDate!.toDate().toDateString();
        //this.charts.first.chart!.config.options!.scales!['x']!.max = auxMaxDate!.toDate().toDateString();

        //this.charts.first.chart!.render();
        this.lineChartOptions!.scales!['x']!.min = auxMinDate.format("YYYY-MM-DD HH:mm:ss");
        this.lineChartOptions!.scales!['x']!.max = auxMaxDate.format("YYYY-MM-DD HH:mm:ss");
        //this.charts.first.chart!.update();
        /*this.charts.forEach((child) => {
          child.update()
        });*/

      } else if (wheelEvent.deltaY < 0) {
        //this.zoomOut();
        console.log("Zoom out");

        if(this.lineChartData.labels!.indexOf(min) == -1){
          auxMinDate = this.lineChartData.labels![0] as moment.Moment;
        }else {
          auxMinDate = this.lineChartData.labels![this.lineChartData.labels!.indexOf(min) - segment ] as moment.Moment;
        }
        if(this.lineChartData.labels!.indexOf(max) == -1){
          auxMaxDate = this.lineChartData.labels![this.lineChartData.labels!.length - 1] as moment.Moment;
        }else {
          auxMaxDate = this.lineChartData.labels![this.lineChartData.labels!.indexOf(max) + segment ] as moment.Moment;
        }
      }

      //this.charts.first.chart!.update();
    }
    //console.log( moment(min).format("DD/MM/YYYY HH:mm:ss") );
    //console.log( moment(max).format("DD/MM/YYYY HH:mm:ss") );
  }
  // events
  public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    console.log(event, active);
    this.chartHovered({ event, active });
  }

  public chartTimeHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    let coordY = event!.y!;
    let coordX = event!.x!;
    console.log('chartHovered: ', Chart.Chart.getChart("timelineChart")!.id, " - x: " , coordX , " y: " , coordY );

  }

  /* Cuando se mueve el cursor por el grafico */
  public chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {

    //const { canvas, ctx, chartArea: { left, right, top, bottom} } = this.charts.first.chart!;
    const { canvas, ctx, chartArea: { left, right, top, bottom, height}, scales: { x, y } } = Chart.Chart.getChart("mainChart")!;
    //const { canvas, ctx, chartArea: { left, right, top, bottom, height}, scales: { x, y } } = this.charts.first.chart!;
    //ctx.save();

    let coordY = event!.y!;
    let coordX = event!.x!;

    this.cursorLastX = coordX;
    this.cursorLastY = coordY;

    if(coordX >= left && coordX <= right && coordY >= top && coordY <= bottom  ){
      canvas.style.cursor = 'crosshair';
    }else{
      canvas.style.cursor = 'default'
    }

    this.pintarlineasCursor(coordX,coordY);

  }

  public pintarlineasCursor(coordX:number, coordY: number){
//const { canvas, ctx, chartArea: { left, right, top, bottom} } = this.charts.first.chart!;
const { canvas, ctx, chartArea: { left, right, top, bottom, height}, scales: { x, y } } = Chart.Chart.getChart("mainChart")!;
//const { canvas, ctx, chartArea: { left, right, top, bottom, height}, scales: { x, y } } = this.charts.first.chart!;
//ctx.save();

if(coordX >= left && coordX <= right && coordY >= top && coordY <= bottom  ){
  canvas.style.cursor = 'crosshair';
}else{
  canvas.style.cursor = 'default'
}


//console.log('chartHovered: ', Chart.Chart.getChart("mainChart")!.id, " - x: " , coordX , " y: " , coordY );

//this.charts.first.chart!.update('none');
this.updateChart("mainChart",'none');
ctx.restore();

// Dibujar linias hasta cursor
ctx.lineWidth = 1;
ctx.strokeStyle = '#666'
ctx.setLineDash([3,3]);

// horizontal
if( coordY >= top && coordY <= bottom){
  ctx.beginPath();
  ctx.moveTo(left, coordY);
  ctx.lineTo(right, coordY);
  ctx.stroke();
  ctx.closePath();

}

// vertical
if( coordX >= left && coordX <= right  ){
  ctx.beginPath();
  ctx.moveTo(coordX, top );
  ctx.lineTo(coordX, bottom);
  ctx.stroke();
  ctx.closePath();
}


/* Texto inferior */

var valueX = null, valueY = null;
for (var scaleName in Chart.Chart.getChart("mainChart")!.scales) {
  var scale = Chart.Chart.getChart("mainChart")!.scales[scaleName];
  if (scale.isHorizontal()) {
    valueX = scale.getValueForPixel(coordX);
  } else {
    valueY = scale.getValueForPixel(coordY);
  }
}



let text = moment(valueX!).format("DD/MM/YYYY HH:mm:ss") ;
const textMeasure = ctx.measureText(text).width + 10  ;

ctx.beginPath();
ctx.fillStyle = 'rgba(132,132,132,1)';
ctx.fillRect(coordX - (textMeasure/2) , bottom, textMeasure + 20, 20);
ctx.closePath();
ctx.fillStyle = 'white';
ctx.fillText(text, coordX - (textMeasure/2) + 10 , bottom + 10 );

//this.pintarTimeline();

//ctx.restore();
//ctx.restore();

/*
ctx.beginPath();
if( coordY >= top && coordY <= bottom &&  coordX >= left && coordX <= right){
  ctx.fillStyle = 'rgba(255,26,104,1)';
  ctx.strokeStyle = '#666';
  ctx.lineWidth = 3;
  ctx.setLineDash([6,0]);

  const angle = Math.PI / 180;
  ctx.arc(
    coordX,
    20,
    5,
    angle * 0,
    angle * 360,
    false
  );
  ctx.fill();
  ctx.stroke();

}
ctx.closePath();
*/

//ctx.moveTo(top, event!.x!);
//ctx.lineTo(bottom, event!.x!);
  }


  public hideOne(): void {
    //const isHidden = this.chart?.isDatasetHidden(1);
    //this.chart?.hideDataset(1, !isHidden);
  }


  public ocultarDatasets(){

    const that = this;
    /*
    setTimeout(function () {

      that.chart?.hideDataset(6, true);
      that.chart?.hideDataset(7, true);
      that.chart?.hideDataset(8, true);
      that.chart?.hideDataset(9, true);
      that.chart?.hideDataset(10, true);
      that.chart?.hideDataset(11, true);
      that.chart?.hideDataset(12, true);
      that.chart?.hideDataset(13, true);
      that.chart?.hideDataset(14, true);
      that.chart?.hideDataset(15, true);
      that.chart?.hideDataset(16, true);
      that.chart?.hideDataset(17, true);
      that.chart?.hideDataset(18, true);
      that.chart?.hideDataset(19, true);
      that.chart?.hideDataset(20, true);
      that.chart?.hideDataset(21, true);
    }, 250);
    */
  }

  public mouseOut({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
  }
  public mouseEnter({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
  }
  public crosshairLine({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
  }

  public zoomin(event?: WheelEvent){
    return this.zoom(50, event);
  }
  public zoomout(event?: WheelEvent){
    return this.zoom(-50, event);
  }

  public zoom(segment: number, event?: WheelEvent){

    const { canvas, config, ctx, chartArea: { left, right, top, bottom, height}, scales: { x, y } } =  Chart.Chart.getChart("mainChart")!;

    let min = config.options!.scales!['x']!.min!;
    let max = config.options!.scales!['x']!.max!;

    let multiplySerieLeft = 1;
    let multiplySerieRight = 1;

    let secureMinDate
    let secureMaxDate;

    if(event){

      this.pintarlineasCursor(this.cursorLastX,this.cursorLastY);

      let val = x.getValueForPixel(this.cursorLastX)

      var valueX = null, valueY = null;
      for (var scaleName in Chart.Chart.getChart("mainChart")!.scales) {
        var scale = Chart.Chart.getChart("mainChart")!.scales[scaleName];
        if (scale.isHorizontal()) {
          valueX = scale.getValueForPixel(this.cursorLastX);
        } else {
          valueY = scale.getValueForPixel(this.cursorLastX);
        }
      }

      let text = moment(valueX!).format("DD/MM/YYYY HH:mm:ss") ;

      secureMinDate = moment(valueX!).subtract(2, "hours");
      secureMaxDate = moment(valueX!).add(2, "hours");

      console.log( "ZOOM: ", text);

      let rx100 = 0;
      let lx100 = 0;
      let firstStack: number = Math.abs(moment.duration(moment(min).diff(moment(valueX!))).asHours());
      let secondStack: number = Math.abs(moment.duration(moment(valueX!).diff(moment(max))).asHours());
      let totalStack = firstStack + secondStack;

      console.log('firstStack: ', firstStack);
      console.log('secondStack: ', secondStack);
      console.log('totalStack: ', firstStack + secondStack);

      rx100 =  ( 100 * secondStack ) / totalStack ;
      lx100 =  ( 100 * firstStack ) / totalStack ;

      console.log( "lx100: ", lx100);
      console.log( "rx100: ", rx100);

      rx100 = (rx100 > 60)? rx100 * 2 : (rx100 < 30)? 1 : rx100;
      lx100 = (lx100 > 60)? lx100 * 2 : (lx100 < 30)? 1 : lx100;

      if(segment > 0 ){
        if(secondStack > firstStack){
          multiplySerieRight = 1 + ( rx100 / 100) ;
          multiplySerieLeft =( lx100 / 100 );
        }else{
          multiplySerieRight = (rx100 / 100);
          multiplySerieLeft = 1 + ( lx100 / 100 );
        }
      }else{
        if(secondStack > firstStack){
          multiplySerieRight =  ( lx100 / 100) ;
          multiplySerieLeft = 1 + ( rx100 / 100 );
        }else{
          multiplySerieRight = 1 +  (lx100 / 100);
          multiplySerieLeft = ( rx100 / 100 );
        }

      }
      console.log( "multiplySerieLeft: ", multiplySerieLeft);
      console.log( "multiplySerieRight: ", multiplySerieRight);

      // moment(val).format("DD/MM/YYYY HH:mm:ss");

      //multiplySerieLeft = 1;
      //multiplySerieRight = 1;
    }else{
      let totalStack: number = Math.abs(moment.duration(moment(min).diff(moment(max))).asSeconds());
      secureMinDate = moment(min).add(totalStack - 7200, "seconds");
      secureMaxDate = moment(min).add(totalStack + 7200, "seconds");
    }

    console.log('min actual: ', moment(min).format("DD/MM/YYYY HH:mm:ss"));
    console.log('max actual: ', moment(max).format("DD/MM/YYYY HH:mm:ss"));

    let filtroMin = this.lineChartData.labels!.filter(
      (x) => {
        let aux = x as moment.Moment;
        //console.log("comapre ", aux.format("DD/MM/YYYY HH:mm:ss") , " - " , moment(min).format("DD/MM/YYYY HH:mm:ss"));
        return aux.format("DD/MM/YYYY HH:mm:ss") === moment(min).format("DD/MM/YYYY HH:mm:ss");
        // moment(x).format("DD/MM/YYYY HH:mm:ss") === moment(min).format("DD/MM/YYYY HH:mm:ss")
      }
    );

    let filtroMax = this.lineChartData.labels!.filter(
      (x) => {
        let aux = x as moment.Moment;
        return aux.format("DD/MM/YYYY HH:mm:ss") === moment(max).format("DD/MM/YYYY HH:mm:ss");
        // moment(x).format("DD/MM/YYYY HH:mm:ss") === moment(min).format("DD/MM/YYYY HH:mm:ss")
      }
    );

    if(filtroMin.length == 0){
      this.InformationFirstValueZoom = this.lineChartData.labels![0] as moment.Moment;
    }else{
      this.InformationFirstValueZoom = filtroMin[0]  as moment.Moment;
    }
    if(filtroMax.length == 0){
      this.InformationLastValueZoom = this.lineChartData.labels![this.lineChartData.labels!.length - 1] as moment.Moment;
    }else{
      this.InformationLastValueZoom  = filtroMax[0]  as moment.Moment;
    }

    //let a = this.lineChartData.labels![0] as moment.Moment;
    //let b = this.lineChartData.labels![this.lineChartData.labels!.length - 1] as moment.Moment;

    let auxInformationFirstValueZoom: moment.Moment = this.InformationFirstValueZoom;
    let auxInformationLastValueZoom: moment.Moment = this.InformationLastValueZoom;

    var duration = moment.duration(this.InformationLastValueZoom.diff(this.InformationFirstValueZoom));
    var hours = duration.asHours();

    var duration3 = moment.duration(this.InformationLastValue.diff(this.InformationFirstValue));
    var hours = duration.asHours();
    let serieLeft = 6;
    let serieRight = 6;




    if(segment > 0 ){

      if(hours > 30){
        serieLeft = 6;
        serieRight = 6;
      }else if(hours > 15){
        serieLeft = 3;
        serieRight = 3;
      }else if(hours > 6){
        serieLeft = 1;
        serieRight = 1;
      }else if (hours> 3){
        serieLeft = 0;
        serieRight = 0;
      }

      serieLeft = serieLeft * multiplySerieLeft;
      serieRight = serieRight * multiplySerieRight;
      console.log("duration: ", hours, " serieLeft: ", serieLeft, " serieRight: ", serieRight);

      this.InformationFirstValueZoom = this.InformationFirstValueZoom.add(serieLeft, "hours");
      this.InformationLastValueZoom = this.InformationLastValueZoom.subtract(serieRight, "hours");

      //this.InformationFirstValueZoom  = this.maxData(this.InformationFirstValueZoom, this.InformationFirstValue);
      //this.InformationLastValueZoom = this.minData(this.InformationLastValueZoom, this.InformationLastValue);

    }else {
      serieLeft = serieLeft * multiplySerieLeft;
      serieRight = serieRight * multiplySerieRight;

      this.InformationFirstValueZoom = this.InformationFirstValueZoom.subtract(serieLeft, "hours");
      this.InformationLastValueZoom = this.InformationLastValueZoom.add(serieRight, "hours");

      console.log("log out");

      //this.InformationFirstValueZoom  = this.minData(this.InformationFirstValueZoom, this.InformationFirstValue);
      //this.InformationLastValueZoom = this.maxData(this.InformationLastValueZoom, this.InformationLastValue);
    }

    var duration2 = moment.duration(this.InformationLastValueZoom.diff(this.InformationFirstValueZoom)).asHours();
    if(duration2 < 0 ){

      this.InformationFirstValueZoom  = this.minData(this.InformationFirstValueZoom, auxInformationFirstValueZoom);
      this.InformationLastValueZoom = this.maxData(this.InformationLastValueZoom, auxInformationLastValueZoom);
    }

    if(duration2 > duration3.asHours()){
      this.InformationFirstValueZoom = this.InformationFirstValue;
      this.InformationLastValueZoom = this.InformationLastValue;
    }

    console.log("min next: ", this.InformationFirstValueZoom .format("DD/MM/YYYY HH:mm:ss"));
    console.log("max next: ", this.InformationLastValueZoom.format("DD/MM/YYYY HH:mm:ss"));

    this.InformationFirstValueZoom = this.maxData(this.InformationFirstValueZoom, secureMinDate);
    this.InformationLastValueZoom = this.minData(this.InformationLastValueZoom, secureMaxDate);

    console.log("diferenciaEntreFechas", this.diferenciaEntreFechas(secureMinDate, secureMaxDate));


    if(this.diferenciaEntreFechas(this.InformationLastValue , this.InformationLastValueZoom) > 0 ){
      this.InformationLastValueZoom = this.InformationLastValue;
    }
    if(this.diferenciaEntreFechas(this.InformationFirstValueZoom , this.InformationFirstValue) > 0 ){
      this.InformationFirstValueZoom = this.InformationFirstValue;
    }
    //this.InformationFirstValueZoom = this.maxData(this.InformationFirstValueZoom, this.InformationFirstValue);
    //this.InformationLastValueZoom = this.minData(this.InformationLastValueZoom, this.);

    console.log("min secure: ", secureMinDate.format("DD/MM/YYYY HH:mm:ss"));
    console.log("max secure: ", secureMaxDate.format("DD/MM/YYYY HH:mm:ss"));

    console.log("min next: ", this.InformationFirstValueZoom .format("DD/MM/YYYY HH:mm:ss"));
    console.log("max next: ", this.InformationLastValueZoom.format("DD/MM/YYYY HH:mm:ss"));
    //console.log("auxMaxDate ", moment(this.lineChartData.labels![this.lineChartData.labels?.length-1]!).format("DD/MM/YYYY HH:mm:ss"));

    //this.InformationFirstValueZoom = this.InformationFirstValueZoom.add(1, 'day');
    //this.InformationLastValueZoom = this.InformationLastValueZoom.subtract(1, 'day');
    Chart.Chart.getChart("mainChart")!.config.options!.scales!['x']!.min = this.InformationFirstValueZoom.format("YYYY-MM-DD HH:mm:ss");
    Chart.Chart.getChart("mainChart")!.config.options!.scales!['x']!.max = this.InformationLastValueZoom.format("YYYY-MM-DD HH:mm:ss");


    //this.lineChartOptions!.scales!['x']!.min = auxMinDate.format("YYYY-MM-DD HH:mm:ss");
    //this.lineChartOptions!.scales!['x']!.max = auxMaxDate.format("YYYY-MM-DD HH:mm:ss");
    //this.zoomBox(this.InformationFirstValueZoom, this.InformationLastValueZoom);

    this.pintarTimeline();

    this.updateChart("mainChart","resize");
    //Chart.Chart.getChart("mainChart")!.ngOnChanges({});
    Chart.Chart.getChart("mainChart")!.render();

    //this.updateChart("2",'none');

  }

  public zoomBox(min: moment.Moment, max: moment.Moment){

    this.updateChart("timelineChart",'none');

    const { id, canvas, ctx, chartArea: { left, right, top, bottom, height}, scales: { x, y } } = Chart.Chart.getChart("timelineChart")!;

    console.log('zoomBox: ' , id);

    ctx.save();

    ctx.lineWidth = 1;
    ctx.strokeStyle = '#000'

    ctx.beginPath();
    ctx.moveTo(left, 10);
    ctx.lineTo(right, 10);
    ctx.stroke();
    ctx.closePath();

    ctx.beginPath();
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, 100, 20 );
    ctx.stroke();
    ctx.closePath();

    ctx.beginPath();
    ctx.fillStyle = 'rgba(54,162,235,0.9)';
    ctx.fillRect(x.getPixelForValue(min.valueOf()), top, x.getPixelForValue(max.valueOf()) - x.getPixelForValue(min.valueOf()),height);
    ctx.closePath();
    //ctx.restore();

    const angle = Math.PI / 180;
    ctx.beginPath();
    ctx.strokeStyle =  'rgba(54,162,235,1)';
    ctx.fillStyle = '#FFF';
    ctx.arc(
      x.getPixelForValue(min.valueOf()),
      height/2,
      10,
      angle * 0,
      angle * 360,
      false
    );

    ctx.fill();
    ctx.stroke();
    ctx.closePath();


    ctx.beginPath();
    ctx.strokeStyle =  'rgba(54,162,235,1)';
    ctx.fillStyle = '#FFF';
    ctx.arc(
      x.getPixelForValue(max.valueOf()),
      height/2,
      10,
      angle * 0,
      angle * 360,
      false
    );

    ctx.fill();
    ctx.stroke();
    ctx.closePath();


    //this.updateChart("timelineChart",'none');
    //this.pintarTimeline();
  }

  public updateChart(id: string, mode: UpdateMode){
    Chart.Chart.getChart(id)?.update(mode);

  }

  public maxData(actual: moment.Moment, max: moment.Moment): moment.Moment{

    if(actual.isBefore(max)){
      return actual;
    }
    return max;
  }
  public minData(actual: moment.Moment, min: moment.Moment): moment.Moment{

    if(actual.isAfter(min)){
      return actual;
    }
    return min;
  }


  public pintarTimeline(){
    this.updateChart("timelineChart",'none');

    const { id, canvas, ctx, chartArea: { left, right, top, bottom, height, width}, scales: { x, y } } = Chart.Chart.getChart("timelineChart")!;
    ctx.save();

    let leftRect = x.getPixelForValue(this.InformationFirstValueZoom.valueOf());
    let rightRect = x.getPixelForValue(this.InformationLastValueZoom.valueOf());

    console.log("leftRect: ", leftRect);

    ctx.beginPath();
    ctx.fillStyle = 'rgba(54,162,235,0.4)';
    ctx.fillRect(leftRect , top, rightRect - leftRect ,height);
    ctx.closePath();

    const angle = Math.PI / 180;
    ctx.beginPath();
    ctx.strokeStyle =  'rgba(54,162,235,1)';
    ctx.fillStyle = '#FFF';
    ctx.arc(
      leftRect,
      height/2,
      10,
      angle * 0,
      angle * 360,
      false
    );

    ctx.fill();
    ctx.stroke();
    ctx.closePath();


    ctx.beginPath();
    ctx.strokeStyle =  'rgba(54,162,235,1)';
    ctx.fillStyle = '#FFF';
    ctx.arc(
      rightRect,
      height/2,
      10,
      angle * 0,
      angle * 360,
      false
    );

    ctx.fill();
    ctx.stroke();
    ctx.closePath();
    ctx.restore();
  }


  public diferenciaEntreFechas(data1: moment.Moment, data2: moment.Moment){

    return moment.duration(data2.diff(data1)).asHours();
  }
}
