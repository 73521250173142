import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Information } from 'src/app/models/device';
import { Trip, TripEvent } from 'src/app/models/trip';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { DevicesService } from 'src/app/services/devices.service';
import { TripsService } from 'src/app/services/trips.service';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-split-trip-page',
  templateUrl: './split-trip-page.component.html',
  styleUrls: ['./split-trip-page.component.scss']
})
export class SplitTripPageComponent implements OnInit {

  hasDevicePage: boolean = false;
  id?: number;
  loading:boolean = true;
  idModel?: number;
  trip?: Trip;
  title: string = "";
  typeSplit = 0;
  
  constructor(private breadcrumbService: BreadcrumbService,
    public authSrv: AuthenticationService,
    private deviceSrv: DevicesService,
    private tripSrv : TripsService,
    private router: Router,
    private utilsSrv: UtilsService,
    private route: ActivatedRoute,
    private translate: TranslateService) { 

      this.title = this.translate.instant('modules.trips.trip-management-device')
      breadcrumbService.setBreadCrumbUrl('trips', this.title );
      this.route.params.subscribe(params => {

        this.id = params['id'];
        let auxId = params['idDevice'];

        if(auxId){
          this.title = this.translate.instant('modules.trips.trip-management-device')

        }else{
          this.loadValues(this.id! );

        }

      });
    }

  ngOnInit(): void {
  }

  loadValues(id: number){
    this.loading = true;
    this.trip = undefined;
    console.log('load trip: ' , id );
    this.tripSrv.getById(id).subscribe({
      next: (res: Trip) => {
        this.trip = new Trip(<Trip>res);

        if(this.hasDevicePage){

        }else{
          this.breadcrumbService.setBreadCrumbUrl('trips', this.translate.instant('modules.trips.trip-management-device'));
          this.title = this.translate.instant('modules.trips.trip') + ' ' + this.trip!.Id??'';
          this.breadcrumbService.addBreadCrumb('trips/' + this.trip!.Id  ,this.title  );
        }

        this.title = this.translate.instant('modules.trips.trip') + ' ' + this.trip!.Id??'';
        this.breadcrumbService.addBreadCrumb('trips/' + this.trip!.Id + '/split'  , this.translate.instant('modules.modals.split'));

        
        this.trip!.Device!.Informations! = new Array<Information>();
        
        this.trip!.Events = new Array<TripEvent>;
        this.trip.DeviceTripEvents?.forEach( (e) =>{
          this.trip!.Events!.push(e);

        });
        /*
        this.trip!.Events.push(new TripEvent({Id: 0, Type: 1, Name: 'Inicio viaje', Information: this.trip!.DeviceTripTrackInformations![0].TrackInformation!, Date: Date.parse('2023-09-28T13:44:49')}));
        this.trip!.Events.push(new TripEvent({Id: 15, Type: 3, Name: 'Inicio trabajo', Information: this.trip!.DeviceTripTrackInformations![15].TrackInformation!, Date: Date.parse('2023-09-28T13:46:04')}));
        this.trip!.Events.push(new TripEvent({Id: 20, Type: 2, Name: 'Alarma 1', Information: this.trip!.DeviceTripTrackInformations![20].TrackInformation!, Description: '1' , Date: Date.parse('2023-09-28T12:46:04')}));
        this.trip!.Events.push(new TripEvent({Id: 25, Type: 2,Name: 'Alarma 2', Information: this.trip!.DeviceTripTrackInformations![25].TrackInformation!, Description: '1' , Date: Date.parse('2023-09-28T13:46:04')}));
        this.trip!.Events.push(new TripEvent({Id: 26, Type: 4,Name: 'Fin trabajo', Information: this.trip!.DeviceTripTrackInformations![26].TrackInformation!, Date: Date.parse('2023-09-28T16:46:04')}));
        this.trip!.Events.push(new TripEvent({Id: 27, Type: 1, Name: 'Fin viaje', Information: this.trip!.DeviceTripTrackInformations![0].TrackInformation!, Date: Date.parse('2023-09-28T16:02:52')}));
        */
        
        this.trip?.DeviceTripTrackInformations?.forEach((e)=>{
          this.trip!.Device!.Informations!.push(e.TrackInformation!);
        });



        
        if(this.trip!.Device!.Informations && this.trip!.Device!.Informations!.length > 0 ){
          this.trip!.Device!.InformationFirst = this.trip!.Device!.Informations![0].TrackPosition?.Date;
          this.trip!.Device!.InformationLast = this.trip!.Device!.Informations![this.trip!.Device!.Informations!.length-1].TrackPosition?.Date;
        }


      }, error: ()=> {
        this.loading = false;

      },
      complete: ()=>{
        this.loading = false;

        console.log(this.trip);
      }
    });
  }

  changeMode(){
    this.typeSplit = (this.typeSplit == 0)?1:0;
  }


}
