import { EmailVariable } from "./emailVariable";

export class Theme {
    Id?: number;
    IdUser?: number;

    CreatedAt?: Date;
    UpdatedAt?: Date;
    State?: number;
    Active?: boolean;

    HeadColor: string = "#ffffff";
    HeadIconColor: string = "#000000";
    HeadIconHoverColor= "#000000";

    MenuColor  = "#8dc63f";
    MenuColorTop  = "#3ec4dd";
    MenuColorBottom  = "#f8ee30";

    MenuTextColor  = "#ffffff";
    MenuTextActiveColor = "#ffffff";
    MenuTextHoverColor = "#ffffff";

    MenuBottomColor = "#f8ee30";
    MenuBottomTextColor  = "#fff";
    MenuBottomTextActiveColor = "#3ec4dd";
    MenuBottomTextHoverColor = "#3ec4dd";

    HeadLogoColor = "Images/default/batsi-brand.svg";


  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

}
