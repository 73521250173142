<app-breadcrumb></app-breadcrumb>

<div class="container-fluid pt-3 pl-0 pr-0 espace">

  <ng-container>

    <div class="my-4">
      <div class="container-fluid main">
        <div class="row row-eq-height">
          <div class="col-12">
            <div class="row align-items-center">
              <div class="col-xxl-4">
                <h1><b>{{'modules.alarms.simulate-trace' | translate}}</b></h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-container>


  <app-block-group-dashboard>

    <app-block-dashboard>

      <app-simulate-trace></app-simulate-trace>

    </app-block-dashboard>

  </app-block-group-dashboard>





</div>

<app-footer></app-footer>
