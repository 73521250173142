<div>
    terminal test
</div>
<ng-terminal #term></ng-terminal>
<button (click)="showInput();">Ver Input</button>


<form class="text-start p-2" [formGroup]="terminalForm" autocomplete="off" >

    <input formControlName="textCopy" value="">
    <button (click)="copyInput();">Copy</button>

</form>