<div class="container-fluid p-0 h-100">

    <app-header [inputSideNav]="sideNav"></app-header>
  
    <mat-sidenav-container class="shadow" >

      <mat-sidenav #sideNav mode="side"  opened>
        <app-side-nav></app-side-nav>
      </mat-sidenav>

      <mat-sidenav-content class="minW">
        <router-outlet></router-outlet>
      </mat-sidenav-content>
      
    </mat-sidenav-container>

  </div>
