import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Device, DeviceModel } from 'src/app/models/device';
import { Modal } from 'src/app/models/modal';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-device-add-quick',
  templateUrl: './device-add-quick.component.html',
  styleUrls: ['./device-add-quick.component.scss']
})
export class DeviceAddQuickComponent implements OnInit {

  deviceOutput: Device = new Device();
  user: User | null = null;
  model: DeviceModel | null = null;
  fromList: boolean = false;

  constructor(private dialogRef: MatDialogRef<DeviceAddQuickComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Modal) {

      console.log(data);

      if(this.data!.Data){
        if(this.data!.Data['idUser']){
          this.user = new User(this.data!.User)??null;

          console.log(this.user);
        }
        if(this.data!.Data['idModel']){
          this.model = new Device(this.data!.Data.DeviceModel)??null;
          console.log('model: ' , this.model);
        }
        if(this.data!.Data['fromList']){
          this.fromList = this.data!.Data.fromList??false;
        }
      }
    }

  ngOnInit(): void {
  }

  emitDevice(deviceOutput: Device | any){

    console.log("quickDevice", deviceOutput);
    this.dialogRef.close(deviceOutput);
  }

  accept(){
    this.dialogRef.close();
  }

  cancel(){
    this.dialogRef.close();
  }

}
