import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ListResult } from 'src/app/models/apiRequestObject';
import { User, vUser } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { UserService } from 'src/app/services/user.service';
import { UsersService } from 'src/app/services/users.services';
import { UtilsService } from 'src/app/services/utils.service';
declare const $: any

@Component({
  selector: 'app-detail-public-user',
  templateUrl: './detail-public-user.component.html',
  styleUrls: ['./detail-public-user.component.scss']
})
export class DetailPublicUserComponent implements OnInit, AfterViewInit {

  @Input() user: User | null = null;
  @Input() editSource: string = "";

  isManufacturerOfClient: boolean = false;
  listaUsuarios: vUser[] = [];
  loading: boolean = true;
  loadingPassword: boolean = false;


  constructor(
    private breadcrumbService: BreadcrumbService,
    private router : Router,
    private usersSrv: UsersService,
    private usrSrv: UserService,
    private utilsSrv: UtilsService,
    public authSrv: AuthenticationService,
    private translate: TranslateService) {


  }


  get isAdmin(): boolean{
    return this.authSrv.isAdmin();
  }

  get isManufacturer(): boolean{
    return this.authSrv.isManufacturer();
  }

  ngOnInit(): void {
    console.log("user" , this.user)

    if(this.authSrv.isManufacturer() && this.user?.isClient){
      console.log("isManufacturer");
      this.usersSrv.getList(0, 100000, 'Name', 'ASC').subscribe(
        {
          next: (res: ListResult) => {
            this.listaUsuarios = <vUser[]>res.Result.map( x => new vUser(x));

            this.listaUsuarios.forEach( element => {

              if(element.Id == this.user!.Id){
                this.isManufacturerOfClient = true;
              }

            });

          },
          error: (err: any) =>{

          },
          complete: ()  => {
            this.loading = false;
          }
      });

    }

  }

  ngAfterViewInit(): void {
    $(".mat-tab-body-content").css({'overflow-x': 'hidden' });
  }

  goEditUser(){

    console.log("goEditUser", this.editSource);

    if(this.editSource == 'account' || this.user?.Id == this.authSrv.currentUserValue!.UserId){
      this.router.navigate(['/account/edit']);

    }else{

      this.router.navigate(['/users/' + this.user!.Id  + '/edit']);
    }


  }

  sendNewAlert(){


    this.usrSrv.sendSimulateAlertById(this.user!.Id!).subscribe(
      {
        next: (res: boolean) => {
          if(res){
            this.utilsSrv.infoModal(this.translate.instant('modules.modals.email-with-alert-sent') + '.', undefined, this.translate.instant('modules.modals.info'));
          }
        },
        error: (err: any) =>{
          if(err.status! == "MODAL"){
            this.utilsSrv.infoModal(err.msg!, undefined, this.translate.instant('modules.shared.warning'));
          }else{
            this.utilsSrv.infoModal(this.translate.instant('modules.users.send-email-error') + '.', undefined, this.translate.instant('modules.shared.warning'));
          }
          this.loadingPassword = false;
        },
        complete: ()  => {
          this.loadingPassword = false;
        }
    });
  }

  sendNewPassword(){

    console.log("sendNewPassword()");
    this.loadingPassword = true;


      this.usrSrv.sendNewPasswordById(this.user!.Id!).subscribe(
        {
          next: (res: boolean) => {
            if(res){
              this.utilsSrv.infoModal(this.translate.instant('modules.modals.recover-password-email-sent') + '.', undefined, this.translate.instant('modules.modals.info'));
            }
          },
          error: (err: any) =>{
            if(err.status! == "MODAL"){
              this.utilsSrv.infoModal(err.msg!, undefined, this.translate.instant('modules.shared.warning'));
            }else{
              this.utilsSrv.infoModal(this.translate.instant('modules.users.send-email-error') + '.', undefined, this.translate.instant('modules.shared.warning'));

            }
            this.loadingPassword = false;
          },
          complete: ()  => {
            this.loadingPassword = false;
          }
      });


  }
}
