<app-loading-custom [loading]="loading"></app-loading-custom>

<ng-container *ngIf="loading == false">

    <form class="text-start p-2" [formGroup]="deviceForm" (ngSubmit)="onSubmit()" autocomplete="off">

        <mat-card fxFlex fxLayoutAlign="start center" fxLayout="column" class="solution"
            [style.borderRadius.px]="'0'" [style.paddingTop.px]="'20'"
            [style.marginBottom.px]="'20'" formGroupName="Device">
            <!-- *ngIf="authSrv.isAdmin() == true || authSrv.isManufacturer() == true" -->

            <!--
            <div class="form-group" *ngIf="authSrv.isAdmin() == true">
                <label for="Active">Activo</label>
                <div style="display:inline-block; width: 20px;"></div>
                <mat-slide-toggle color="primary" formControlName="Active"  [(ngModel)]="editDevice!.Active"></mat-slide-toggle>

            </div>-->
            <ng-container  *ngIf="authSrv.isClient()">

              <div class="form-group"  >
                <label for="Name">{{'modules.tables.device-name' | translate}}</label>
                <input type="text" class="form-control" id="Name"  formControlName="Name" [(ngModel)]="editDevice!.Name">

                    <!-- <div [hidden]="Name!.valid || Name!.pristine" class="alert alert-danger">
                        {{'modules.shared.name-required' | translate}}.
                    </div> -->
                    <div *ngIf="Name && Name!.invalid && (Name!.dirty || Name!.touched)" class="alert alert-danger mt-3 text-start">
                        <div *ngIf="Name!.errors?.['minlength']">
                            {{'modules.shared.min-characters' | translate}}.
                        </div>
                        <div *ngIf="Name!.errors?.['maxlength']">
                            {{'modules.shared.max-characters' | translate}}.
                        </div>
                    </div>
              </div>
              

            </ng-container>

            <ng-container  *ngIf="authSrv.isAdmin() == true || authSrv.isManufacturer()">

              <div class="form-group"  >
                <label for="Name">{{'modules.tables.device-name' | translate}}</label>
                <input type="text" class="form-control" id="Name"  formControlName="Name" [(ngModel)]="editDevice!.Name">

                    <!-- <div [hidden]="Name!.valid || Name!.pristine" class="alert alert-danger">
                        {{'modules.shared.name-required' | translate}}.
                    </div> -->
                    <div *ngIf="Name!.invalid && (Name!.dirty || Name!.touched)" class="alert alert-danger mt-3 text-start">
                        <div *ngIf="Name!.errors?.['minlength']">
                            {{'modules.shared.min-characters' | translate}}.
                        </div>
                        <div *ngIf="Name!.errors?.['maxlength']">
                            {{'modules.shared.max-characters' | translate}}.
                        </div>
                    </div>
              </div>

              <div class="form-group">
                  <label for="IMEI">IMEI</label>
                  <input type="text" class="form-control" id="IMEI"  formControlName="IMEI" required [(ngModel)]="editDevice!.IMEI" maxlength="15">


                      <div *ngIf="IMEI!.invalid && (IMEI!.dirty || IMEI!.touched)" class="alert alert-danger mt-3 text-start">
                          <div *ngIf="IMEI!.errors?.['required']">
                              {{'modules.shared.imei-required' | translate}}.
                          </div>
                          <div *ngIf="IMEI!.errors?.['minlength']">
                              {{'modules.shared.imei-length' | translate}}.
                          </div>
                          <div *ngIf="IMEI!.errors?.['maxlength']">
                              {{'modules.shared.imei-length' | translate}}.
                          </div>
                      </div>
              </div>

              <div class="form-group" *ngIf="authSrv.isAdmin() == true" >
                <label for="">ICCID</label>
                <input type="text" class="form-control" id="ICCID"  formControlName="ICCID" required [(ngModel)]="editDevice!.ICCID" maxlength="19">


                    <div *ngIf="ICCID!.invalid && (ICCID!.dirty || ICCID!.touched)" class="alert alert-danger mt-3 text-start">
                        <div *ngIf="ICCID!.errors?.['required']">
                            {{'modules.shared.iccid-required' | translate}}.
                        </div>
                        <div *ngIf="ICCID!.errors?.['minlength']">
                          {{'modules.shared.iccid-length' | translate}}.
                        </div>
                        <div *ngIf="ICCID!.errors?.['maxlength']">
                          {{'modules.shared.iccid-length' | translate}}.
                        </div>
                    </div>
              </div>



            <div class="form-group" *ngIf="authSrv.isAdmin() == true || authSrv.isManufacturer() == true" >
              <label for="NumSerieManufacturer">{{'modules.tables.serial-number' | translate}}</label>
              <input type="text" class="form-control" id="NumSerieManufacturer"  formControlName="NumSerieManufacturer" [(ngModel)]="editDevice!.NumSerieManufacturer" maxlength="100" />

              <div *ngIf="NumSerieManufacturer!.invalid && (ICCID!.dirty || ICCID!.touched)" class="alert alert-danger mt-3 text-start">
                <div *ngIf="NumSerieManufacturer!.errors?.['maxlength']">
                  {{'modules.shared.serial-number-lenght' | translate}}.
                </div>
              </div>
            </div>

            <div class="form-group" *ngIf="authSrv.isAdmin() == true || authSrv.isManufacturer() == true" >
              <label for="Comments">{{'modules.tables.comments' | translate}}</label>
              <input type="text" class="form-control" id="Comments"  formControlName="Comments" [(ngModel)]="editDevice!.Comments" maxlength="150" />

              <div *ngIf="Comments!.invalid && (ICCID!.dirty || ICCID!.touched)" class="alert alert-danger mt-3 text-start">
                <div *ngIf="Comments!.errors?.['maxlength']">
                  {{'modules.shared.comments-length' | translate}}.
                </div>
              </div>
            </div>

              <div class="form-group" *ngIf="modelInput == null && modelsList && modelsList.length == 0 && ( authSrv.isAdmin())">
                <label for="DeviceModel" class="text-right-label">{{'modules.tables.account' | translate}} <span class="c-pointer" (click)="newModel()" *ngIf="authSrv.isAdmin()">{{'modules.account.exist' | translate}}</span></label>
                {{'modules.account.no-available' | translate}}.
              </div>
              <div class="form-group" *ngIf="modelInput == null && modelsList && modelsList.length == 1 && authSrv.isAdmin()" >
                <label for="DeviceModel" class="text-right-label">{{'modules.tables.account' | translate}} <span class="c-pointer" (click)="newModel()" *ngIf="authSrv.isAdmin()">{{'modules.account.exist' | translate}}</span></label>
                {{'modules.account.will-be-assigned' | translate}}: {{ modelsList[0].NameManufacturer }}.
              </div>
              <div class="form-group" *ngIf="modelsList && modelsList.length > 0">
                  <label for="DeviceModel" class="text-right-label">{{'modules.tables.model-device' | translate}} <span class="c-pointer" (click)="newModel()">{{'modules.devices.model-exist' | translate}}</span></label>

                  <ng-multiselect-dropdown
                    class="dropup"
                    id="DeviceModel"
                    formControlName="DeviceModel"
                    [(ngModel)]="editDevice!.DeviceModel"
                    [placeholder]="'modules.shared.search-account' | translate"
                    [settings]="dropdownSettingsFabricante"
                    [data]="modelsList"
                    >

                    <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
                      <div style="padding:1px 10px;margin-right:10px;right:2px;top:-2px;cursor: pointer !important;">

                        <label style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
                          {{ getModel[id].Name??'' }}{{ getModel[id].NameManufacturer?' | ':''}}{{ getModel[id].NameManufacturer??'' }}
                        </label>
                      </div>
                    </ng-template>

                    <ng-template #optionSelectedTemplate let-option="option" let-id="id">

                      <div >
                        {{ getModel[id].Name??'' }}{{ getModel[id].NameManufacturer?' | ':''}}{{ getModel[id].NameManufacturer??'' }}
                      </div>
                    </ng-template>
                  </ng-multiselect-dropdown>

              </div>

              <div class="form-group" *ngIf="modelError == true" >

                      <div class="alert alert-danger mt-3 text-start">
                        <div>
                          {{'modules.account.required' | translate}}.
                        </div>

                    </div>
              </div>

          </ng-container>



        </mat-card>



<section class="mt-2">
    <div class="text-right" style="flex-direction: row-reverse;">
        <button  mat-raised-button class="btn btn-primary" [disabled]="isValidForm? null: 'disabled'"  (click)="onSubmit()">{{'modules.shared.save' | translate}}</button>
        <button *ngIf="showButtonCancel" mat-raised-button class="btn btn-danger" type="button" style="margin-right: 10px;" (click)="cancel()">{{'modules.shared.cancel' | translate}}</button>
    </div>

    <!-- <div  class="btn btn-primary"  (click)="test()">test</div> -->

</section>

    </form>


</ng-container>
