
<app-loading-custom [loading]="loading"></app-loading-custom>

<ng-container *ngIf="loading == false && user">

<form class="text-start p-2" [formGroup]="addressForm" (ngSubmit)="onSubmit()" autocomplete="off" >

<h2 class="text-center mt-3 mb-3">{{'modules.users.address-info' | translate}}</h2>
<mat-card fxFlex fxLayoutAlign="start center" fxLayout="column" class="solution"
    [style.borderRadius.px]="'0'"  [style.paddingTop.px]="'20'" [style.marginBottom.px]="'20'" formGroupName="Address">


            <div class="form-group">
                <label for="Address">{{'modules.tables.address' | translate}}</label>
                <input type="text" class="form-control" id="Address"  formControlName="Address" required [(ngModel)]="user!.Address">
                
                    <!-- <div [hidden]="Address!.valid || Address!.pristine" class="alert alert-danger">
                        {{'modules.shared.name-required' | translate}}.
                    </div> -->
                    <div *ngIf="Address!.invalid && (Address!.dirty || Address!.touched)" class="alert alert-danger mt-3 text-start">
                        
                        <div [hidden]="!Address!.errors?.['required']">{{'modules.shared.address-required' | translate}}.</div>
                        <div [hidden]="!Address!.errors?.['minlength']">{{'modules.shared.min' | translate}} {{ Address!.errors?.['minlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
                        <div [hidden]="!Address!.errors?.['maxlength']">{{'modules.shared.max' | translate}} {{ Address!.errors?.['maxlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
                    </div>
            </div>

            <div class="form-group" >
                <label for="CP">{{'modules.users.postal-code' | translate}}</label>
                <input type="text" class="form-control" id="CP"  formControlName="CP" required [(ngModel)]="user!.CP">
  
                    <div *ngIf="CP!.invalid && (CP!.dirty || CP!.touched)" class="alert alert-danger mt-3 text-start">
                        <div [hidden]="!CP!.errors?.['required']">{{'modules.shared.postal-code-required' | translate}}.</div>
                        <div [hidden]="!CP!.errors?.['minlength']">{{'modules.shared.min' | translate}} {{ CP!.errors?.['minlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
                        <div [hidden]="!CP!.errors?.['maxlength']">{{'modules.shared.max' | translate}} {{ CP!.errors?.['maxlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
                    </div>
                      
              </div>
        
              <div class="form-group" >
                <label for="Population">{{'modules.users.city' | translate}}</label>
                  <input type="text" class="form-control" id="Population"  formControlName="Population" required [(ngModel)]="user!.Population">
  
                    <div *ngIf="Population!.invalid && (Population!.dirty || Population!.touched)" class="alert alert-danger mt-3 text-start">
                        <div [hidden]="!Population!.errors?.['required']">{{'modules.shared.city-required' | translate}}.</div>
                        <div [hidden]="!Population!.errors?.['minlength']">{{'modules.shared.min' | translate}} {{ Population!.errors?.['minlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
                        <div [hidden]="!Population!.errors?.['maxlength']">{{'modules.shared.max' | translate}} {{ Population!.errors?.['maxlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>                      
                    </div>
              </div>
      
      
            <div class="form-group"  >
                <label for="Location">{{'modules.users.province' | translate}}</label>
                  <input type="text" class="form-control" id="Location"  formControlName="Location" required [(ngModel)]="user!.Location">  
                      <div *ngIf="Location!.invalid && (Location!.dirty || Location!.touched)" class="alert alert-danger mt-3 text-start">
                        <div [hidden]="!Location!.errors?.['required']">{{'modules.shared.province-required' | translate}}.</div>
                        <div [hidden]="!Location!.errors?.['minlength']">{{'modules.shared.min' | translate}} {{ Location!.errors?.['minlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
                        <div [hidden]="!Location!.errors?.['maxlength']">{{'modules.shared.max' | translate}} {{ Location!.errors?.['maxlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
                    </div>
              </div>

              <div class="form-group" >
                <label for="Country">{{'modules.users.country' | translate}}</label>
                  <input type="text" class="form-control" id="Country"  formControlName="Country" required [(ngModel)]="user!.Country">

                  <div *ngIf="Country!.invalid && (Country!.dirty || Country!.touched)" class="alert alert-danger mt-3 text-start">
                      
                    <div [hidden]="!Country!.errors?.['required']">{{'modules.shared.country-required' | translate}}.</div>
                    <div [hidden]="!Country!.errors?.['minlength']">{{'modules.shared.min' | translate}} {{ Country!.errors?.['minlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
                    <div [hidden]="!Country!.errors?.['maxlength']">{{'modules.shared.max' | translate}} {{ Country!.errors?.['maxlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>

                    </div>
              </div>
      

</mat-card>

</form>


</ng-container>