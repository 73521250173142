import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


import { environment } from '../../environments/environment'
import { ApiRequestObject } from '../models/apiRequestObject';
import { Mail } from '../models/email';
import { EmailVariable } from '../models/emailVariable';
import { UserService } from './user.service';
import { UtilsService } from './utils.service';
@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private http: HttpClient,
    private utils: UtilsService,
    private translate: TranslateService) { }

  public rememberPassword(mail: string, nombre:string, token: string): Observable<any> {
    let mailSend: Mail;
    mailSend = new Mail;
    mailSend.Variables = [];
    mailSend.MailFrom = environment.mailFrom;

    let routeTranslate: string = "mails.remember-pass";
    mailSend.Subject = this.translate.instant(routeTranslate + '.subject');
    mailSend.Template = this.translate.instant(routeTranslate + '.template');
    mailSend.MailTo = mail;


    let arrayVariables: Array<string>;
    arrayVariables = [
      "hola-1",
      "link",
      "Mensaje1",
      "Mensaje2",
      "gracias-1",
      "equipo-1",
      "sistema-1"
    ];

    mailSend.Variables = this.loadArrayVariables(arrayVariables, routeTranslate, mailSend.Variables);
    token = encodeURIComponent(token);


    mailSend.Variables.push(new EmailVariable("url", `${environment.urlRememberPassword.replace("{0}",token)}`));
    mailSend.Variables.push(new EmailVariable("hola-1-content", nombre));
    return this.http.post<any>(`${environment.API_ENDPOINT}mail/send`, mailSend).pipe(
      catchError(this.error)
    );
  }

  public confirmPassword(mail: string) {

    let mailSend: Mail;
    mailSend = new Mail;
    mailSend.Variables = [];
    mailSend.MailFrom =  environment.mailFrom;

    let routeTranslate: string = "mails.confirmacio-pass";
    mailSend.Subject = this.translate.instant(routeTranslate + '.subject');
    mailSend.Template = this.translate.instant(routeTranslate + '.template');
    mailSend.MailTo = mail;


    let arrayVariables: Array<string>;
    arrayVariables = [
      "hola-1",
      "automatico-1",
      "Mensaje1",
      "Mensaje2",
      "Mensaje3",
      "Mensaje4",
      "visite-1",
      "web-1",
      "gracias-1",
      "equipo-1",
      "enviado-1"
    ];

    mailSend.Variables = this.loadArrayVariables(arrayVariables, routeTranslate, mailSend.Variables);
    mailSend.Variables.push(new EmailVariable("[url_token]", environment.urlRememberPassword));

    return this.http.post<any>(`${environment.API_ENDPOINT}mail/send`, mailSend).pipe();
  }

  public enableUser(mail: string, name: string) {

    let mailSend: Mail;
    mailSend = new Mail;
    mailSend.Variables = [];
    mailSend.MailFrom = environment.mailFrom;

    let routeTranslate: string = "mails.activar-usuari";
    mailSend.Subject = this.translate.instant(routeTranslate + '.subject');
    mailSend.Template = this.translate.instant(routeTranslate + '.template');
    mailSend.MailTo = mail;


    let arrayVariables: Array<string>;
    arrayVariables = [
      "hola-1",
      "Mensaje1",
      "Mensaje2",
      "Mensaje3",
      "gracias-1",
    ];

    mailSend.Variables = this.loadArrayVariables(arrayVariables, routeTranslate, mailSend.Variables);
    mailSend.Variables.push(new EmailVariable("[hola-1-content]", name));

    return this.http.post<any>(`${environment.API_ENDPOINT}mail/send`, mailSend).pipe();
  }

  public disableUser(mail: string, name: string) {

    let mailSend: Mail;
    mailSend = new Mail;
    mailSend.Variables = [];
    mailSend.MailFrom = environment.mailFrom;

    let routeTranslate: string = "mails.desactivar-usuari";
    mailSend.Subject = this.translate.instant(routeTranslate + '.subject');
    mailSend.Template = this.translate.instant(routeTranslate + '.template');
    mailSend.MailTo = mail;


    let arrayVariables: Array<string>;
    arrayVariables = [
      "hola-1",
      "Mensaje1",
      "Mensaje2",
      "Mensaje3",
      "gracias-1",
    ];

    mailSend.Variables = this.loadArrayVariables(arrayVariables, routeTranslate, mailSend.Variables);
    mailSend.Variables.push(new EmailVariable("hola-1-content", name));

    return this.http.post<any>(`${environment.API_ENDPOINT}mail/send`, mailSend).pipe();
  }

  private loadArrayVariables(varList: Array<string>, routeTranslate: string, arrayAdd: EmailVariable[]) {

    varList.forEach(element => {

      let auxElement: EmailVariable;
      auxElement = new EmailVariable( element , this.translate.instant(routeTranslate + '.variables.' + element));

      arrayAdd.push(auxElement);
    });


    return arrayAdd;


  }

  getOrdersEmails(email: string): Observable<any> {
    return this.http.get<any>(`${environment.API_ENDPOINT}email/orders?email=${email}`).pipe();
  }
  getStockEmails(email: string): Observable<any> {
    return this.http.get<any>(`${environment.API_ENDPOINT}email/stock?email=${email}`).pipe();
  }
  getReturnsEmails(email: string): Observable<any> {
    return this.http.get<any>(`${environment.API_ENDPOINT}email/returns?email=${email}`).pipe();
  }



  // Error handling
  error(error: HttpErrorResponse) {

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);

    let res : ApiRequestObject = ApiRequestObject.error(errorMessage);

    return throwError(() => res);
  }


}
