import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { AgmCoreModule } from '@agm/core';

import { MaterialCdkModule } from '../material-cdk/material-cdk.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';

import { ListNotificationsPageComponent } from './pages/list-notifications-page/list-notifications-page.component';
import { DetailNotificationPageComponent } from './pages/detail-notification-page/detail-notification-page.component';
import { DetailNotificationComponent } from './components/detail-notification/detail-notification.component';
import { ListNotificationsComponent } from './components/list-notifications/list-notifications.component';
import { NotificationsRoutingModule } from './notifications-routing.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';



@NgModule({
  declarations: [
    ListNotificationsPageComponent,
    DetailNotificationPageComponent,
    DetailNotificationComponent,
    ListNotificationsComponent
  ],
  imports: [
    CommonModule,
    MaterialCdkModule,
    CoreModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NotificationsRoutingModule,
    DataTablesModule,
    NgMultiSelectDropDownModule,
    //AgmCoreModule,
  ],
  exports:[
    MaterialCdkModule,
    NgMultiSelectDropDownModule,
    ListNotificationsComponent
  ],
})
export class NotificationsModule { }
