<div id="htmlData">
  <div class="header">
    <div class="row">
      <div class="col-6 logo">
        <img class="img-brand" src="/assets/img/batsi-brand.svg" />
      </div>
      <div class="col-6 header-title">
        <h1>{{'modules.charts.data-report' | translate}}</h1>
        <!-- <p>{{'modules.charts.report-date' | translate}}: {{ date | date: "dd-MM-yyyy 'a las' HH:mm 'h'" }}</p> -->
        <p>{{'modules.charts.report-date' | translate}}: {{ date | date: "dd-MM-yyyy" }} {{ 'modules.trips.date-aux' | translate }} {{ date | date: "HH:mm 'h'" }}</p>
      </div>
    </div>
  </div>
  <div class="data">
    <div class="title-first">{{'modules.charts.machine-data' | translate}}</div>
    <div class="body">
      <div class="row">
        <div class="col-6">

          <div class="row">
            <div *ngIf="reportData.sensor.length > 1">
              <div class="col-12">
                <div class="title">{{'modules.charts.data' | translate}}</div>
                <div class="text">
                  <span *ngFor="let sensor of sensorsList; index as i">
                    <span *ngIf="i > 0"> | </span> {{ sensor }}
                  </span>
                </div>
              </div>
            </div>
            <ng-container *ngIf="reportData.sensor.length === 1">
              <div class="col-6">
                <div class="title">{{'modules.charts.data-single' | translate}}</div>
                <div class="text">{{ reportData.sensor[0].name }}</div>
              </div>
              <div class="col-6">
                <div class="title">{{'modules.charts.unit' | translate}}</div>
                <div class="text"> - </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="col-6">
          <div class="title">IMEI</div>
          <div class="text">{{ reportData.imei }}</div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="title">{{'modules.charts.name' | translate}}</div>
          <div class="text">{{ reportData.nombre }}</div>
        </div>

        <div class="col-6">
          <div class="title">{{'modules.charts.model' | translate}}</div>
          <div class="text">{{ reportData.modelo }}</div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="title">{{'modules.charts.user' | translate}}</div>
          <div class="text">{{ reportData.cliente }}</div>
        </div>

        <div class="col-6">
          <div class="title">{{'modules.charts.account' | translate}}</div>
          <div class="text">{{ reportData.fabricante }}</div>
        </div>
      </div>

    </div>
  </div>

  <div class="follow">
    <div class="title-first">{{'modules.charts.tracking' | translate}}</div>
    <div class="body">
      <div class="row">
        <div class="col-6">
          <div class="title2">{{'modules.charts.from' | translate}}</div>
          <div class="text2">{{ this.reportChart?.chart?.selection.xaxis.min | date:'dd/MM/yyyy' }}</div>
        </div>
        <div class="col-6">
          <div class="title2">{{'modules.charts.to' | translate}}</div>
          <div class="text2">{{ this.reportChart?.chart?.selection.xaxis.max | date:'dd/MM/yyyy' }}</div>
        </div>
      </div>
      <div class="chart">
        <div id="chart-line">
          <apx-chart #mainChart
            [series]="reportChart.series"
            [chart]="reportChart.chart"
            [xaxis]="reportChart.xaxis"
            [yaxis]="reportChart.yaxis"
            [dataLabels]="reportChart.dataLabels"
            [colors]="reportChart.colors"
            [fill]="reportChart.fill"
            [markers]="reportChart.markers"
            [stroke]="reportChart.stroke"
            [tooltip]="reportChart.tooltip"
            [legend]="reportChart.legend"
          ></apx-chart>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <div class="title">{{'modules.charts.data-single' | translate}}</div>
          <div *ngFor="let sensor of sensorsList" class="text">{{ sensor }}</div>
        </div>
        <div class="col-3">
          <div class="title">{{ 'modules.charts.average' | translate }}</div>
          <div *ngFor="let values of sensorsValues; index as i" class="text">{{ med[i] }}</div>
        </div>
        <div class="col-3">
          <div class="title">{{ 'modules.charts.max' | translate }}</div>
          <div *ngFor="let values of sensorsValues; index as i" class="text">{{ max[i] }}</div>
        </div>
        <div class="col-3">
          <div class="title">{{ 'modules.charts.min' | translate }}</div>
          <div *ngFor="let values of sensorsValues; index as i" class="text">{{ min[i] }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
