
<app-loading-custom [loading]="loading"></app-loading-custom>


<form class="text-start p-2" [formGroup]="passwordForm"  autocomplete="off" >

  <h2 class="text-center mt-3 mb-3">{{'modules.users.change-password' | translate}}</h2>
  <mat-card fxFlex fxLayoutAlign="start center" fxLayout="column" class="solution"
      [style.borderRadius.px]="'0'"  [style.paddingTop.px]="'20'" [style.marginBottom.px]="'20'" formGroupName="Password">


      <div class="form-group">
          <label for="NewPassword">{{'modules.users.new-password' | translate}}</label>
          <input type="text" class="form-control" id="NewPassword"
          formControlName="NewPassword" [(ngModel)]="newPassword">

          <div *ngIf="NewPassword!.value && NewPassword!.invalid && (NewPassword!.dirty || NewPassword!.touched)" class="alert alert-danger mt-3 text-start">
            <div [hidden]="!NewPassword!.errors?.['minlength']">{{'modules.shared.min' | translate}} {{ NewPassword!.errors?.['minlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
            <div [hidden]="!NewPassword!.errors?.['maxlength']">{{'modules.shared.max' | translate}} {{ NewPassword!.errors?.['maxlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
          </div>
      </div>

      <div class="form-group">
        <label for="NewPasswordRepeat">{{'modules.users.repeat-new-password' | translate}}</label>
        <input type="text" class="form-control" id="NewPasswordRepeat"
        formControlName="NewPasswordRepeat" [(ngModel)]="newPasswordRepeat">

        <div *ngIf="NewPasswordRepeat!.value && NewPasswordRepeat!.invalid && (NewPasswordRepeat!.dirty || NewPasswordRepeat!.touched)" class="alert alert-danger mt-3 text-start">
          <div [hidden]="!NewPasswordRepeat!.errors?.['minlength']">{{'modules.shared.min' | translate}} {{ NewPasswordRepeat!.errors?.['minlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
          <div [hidden]="!NewPasswordRepeat!.errors?.['maxlength']">{{'modules.shared.max' | translate}} {{ NewPasswordRepeat!.errors?.['maxlength']?.requiredLength}} {{'modules.shared.characters' | translate}}.</div>
          <div [hidden]="!NewPasswordRepeat!.errors?.['noEsIgual']">{{'modules.users.passwords-not-match' | translate}}.</div>

        </div>


    </div>




  </mat-card>

  </form>
