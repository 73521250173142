import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-edit-user-special',
  templateUrl: './edit-user-special.component.html',
  styleUrls: ['./edit-user-special.component.scss']
})
export class EditUserSpecialComponent implements OnInit {
  @Input() user?: User;
  @Input() page?: number;
  userEdit?: User;
  loading:boolean = true;
  loadingPassword: boolean = false;


  specialForm: FormGroup = this.formBuilder.group({
  });

  @Output() specialFormShared = new EventEmitter<FormGroup>();

  private emitFormGeneral() {
    this.specialFormShared.emit(this.specialForm);
  }

  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private utilsSrv: UtilsService,
    private usrSrv: UserService,
    private dateAdapter: DateAdapter<Date>,
    public authSrv : AuthenticationService,
    private translate: TranslateService) {
      this.dateAdapter.setLocale('es-ES');
    }

  ngOnInit(): void {


    this.userEdit = this.user;
    this.specialForm.addControl('Special',this.addSpecialForm());
    this.emitFormGeneral();
    this.loading = false;
  }

  onSubmit(){

  }

  sendEmail(){
    console.log("sendNewPassword()");
    this.loadingPassword = true;


      this.usrSrv.sendWelcomePasswordById(this.user!.Id!).subscribe(
        {
          next: (res: boolean) => {
            if(res){
              this.utilsSrv.infoModal(this.translate.instant('modules.modals.welcome-email-sent') + '.', undefined, this.translate.instant('modules.modals.info'));
            }
          },
          error: (err: any) =>{
            if(err.status! == "MODAL"){
              this.utilsSrv.infoModal(err.msg!, undefined, this.translate.instant('modules.shared.warning'));
            }else{
              this.utilsSrv.infoModal(this.translate.instant('modules.users.send-email-error') + '.', undefined, this.translate.instant('modules.shared.warning'));

            }
            this.loadingPassword = false;
          },
          complete: ()  => {
            this.loadingPassword = false;
          }
      });

  }


  get IsSeller(){ return this.specialForm.get("Special")!.get("IsSeller"); }
  get ServiceStart(){ return this.specialForm.get("Special")!.get("ServiceStart"); }
  get ServiceEnd(){ return this.specialForm.get("Special")!.get("ServiceEnd"); }
  get Active(){ return this.specialForm.get("Special")!.get("Active"); }
  get TripsModuleEnabled() { return this.specialForm.get("Special")!.get("TripsModuleEnabled"); }
  get DocManagerModuleEnabled() { return this.specialForm.get("Special")!.get("DocManagerModuleEnabled"); }

  addSpecialForm() {

    if(this.user!.Role!.Tag! == "admin"){

      return this.formBuilder.group({
        Active: new FormControl(this.user!.Active, [
          Validators.required
        ])
      });

    }else if(this.user!.Role!.Tag! == "fabricant"){
      this.user!.ServiceStart??new Date();
      this.user!.ServiceEnd??new Date();

      return this.formBuilder.group({
        ServiceStart: new FormControl(this.user!.ServiceStart, [
          Validators.required
        ]),
        ServiceEnd: new FormControl(this.user!.ServiceEnd, [
          Validators.required
        ]),
        Active: new FormControl(this.user!.Active, [
          Validators.required
        ]),
        TripsModuleEnabled: new FormControl(this.user!.TripsModuleEnabled, [
          Validators.required
        ]),
        DocManagerModuleEnabled: new FormControl(this.user!.DocManagerModuleEnabled, [
          Validators.required
        ]),
      });

    }else{
      this.user!.ServiceStart??new Date();
      this.user!.ServiceEnd??new Date();

      return this.formBuilder.group({
        IsSeller: new FormControl(this.user!.IsSeller, [
          Validators.required
        ]),
        ServiceStart: new FormControl(this.user!.ServiceStart, [
          Validators.required
        ]),
        ServiceEnd: new FormControl(this.user!.ServiceEnd, [
          Validators.required
        ]),
        Active: new FormControl(this.user!.Active, [
          Validators.required
        ]),
        TripsModuleEnabled: new FormControl(this.user!.TripsModuleEnabled, [
          Validators.required
        ]),
        DocManagerModuleEnabled: new FormControl(this.user!.DocManagerModuleEnabled, [
          Validators.required
        ]),
      });
    }
  }


}
