<ng-container *ngIf="model">

    <div class="group col-12 col-xl-12 col-lg-12" >
        <div class="my-4">
            <div class="row row-eq-height">
            <div class="col-12">
                <div class="row align-items-center">
                <div class="col-xxl-8">
                    <h1><b>{{'modules.devices.model-detail' | translate}}</b> {{ model.Name }}</h1>
                </div>
                <div class="col-xxl-4 text-xxl-end">
                  <div class="btn btn-primary m-2"  *ngIf="isAdmin || model.IdUser == authSrv.currentUserValue!.UserId" [routerLink]="['/devices/models/' + model!.Id  + '/alarms/add']">
                    {{'modules.alarms.add-alarm' | translate}}
                  </div>
                    <ng-container *ngIf="isAdmin || (model.IdUser == authSrv.currentUserValue!.UserId && model.Name != nameDeviceModelMain)">
                        <div class="btn btn-primary" (click)="editDeviceModel()">{{'modules.devices.edit-model' | translate}} <i class="fas fa-device-edit fa-sm"></i></div>
                    </ng-container>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    <app-block-dashboard>
        <div class="group col-12 col-xl-12 col-lg-12">

            <div class="container-fluid my-3">
                <div class="main main-box">
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <div><strong>{{'modules.tables.name' | translate}}:</strong></div>
                        <div>{{ model.Name }}</div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <div><strong>{{'modules.tables.account' | translate}}:</strong></div>
                        <div><a [routerLink]="['/users/' + model.Manufacturer!.Id + '/detail' ]" class="c-pointer">{{ model.Manufacturer!.Name??'' }}</a></div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <div><strong>{{'modules.tables.register-date' | translate}}:</strong> </div>
                        <div>{{ model.CreatedAt | date: "dd-MM-yyyy HH:mm"  }}</div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <div><strong>{{'modules.tables.last-modification' | translate}}:</strong> </div>
                        <div>{{ model.UpdatedAt | date: "dd-MM-yyyy HH:mm"  }}</div>
                    </div>
                </div>
                </div>
            </div>

            <!-- <div class="table-responsive">
                <table class="table table-striped  w-100" style="width: 100%;">
                <tbody>
                    <tr>
                    <td>{{'modules.tables.name' | translate}}: </td>
                    <td>{{ model.Name }}</td>
                    </tr>
                    <tr>
                        <td>{{'modules.devices.manufacturer' | translate}}: </td>
                        <td><a [routerLink]="['/users/' + model.Manufacturer!.Id + '/detail' ]" class="c-pointer">{{ model.Manufacturer!.Name??'' }}</a></td>
                    </tr>
                    <tr>
                    <td>{{'modules.tables.register-date' | translate}}: </td>
                    <td>{{ model.CreatedAt | date: "dd-MM-yyyy HH:mm"  }}</td>
                    </tr>
                    <tr *ngIf="model.UpdatedAt">
                    <td>{{'modules.tables.last-modification' | translate}}:</td>
                    <td>{{ model.UpdatedAt | date: "dd-MM-yyyy HH:mm"  }}</td>
                    </tr>

                </tbody>
                </table>
            </div> -->

            <ng-container *ngIf="authSrv.isClient() == false">
                <mat-tab-group mat-align-tabs="start" [selectedIndex]="currentIndex" (selectedIndexChange)="page = $event">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            {{'modules.devices.devices' | translate}}
                        </ng-template>
                        <div class="mt-2">
                            <app-list-devices [modelInput]="model"></app-list-devices>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            {{'modules.alarms.alarms' | translate}}
                        </ng-template>
                        <div class="mt-2">
                          <app-list-all-alarms [modelInput]="model" [showDefaultValuesAlarm]="false" [showCreateDefaultAlarm]="(model!.DefaultAlarmConfig == null)?true:false"></app-list-all-alarms>

                        </div>
                    </mat-tab>
                    <mat-tab *ngIf="(model?.DefaultAlarmConfig != null && authSrv.isAdmin())?true:false">
                        <ng-template mat-tab-label>
                            {{'modules.alarms.alarm-default-values' | translate}}
                        </ng-template>
                        <div class="mt-2" *ngIf="model">
                          <app-detail-default-values-alarm [information]="model!.DefaultAlarmConfig" [showChangeModel]="false" ></app-detail-default-values-alarm>

                          <!-- <app-list-all-alarms [modelInput]="model" [showDefaultValuesAlarm]="true" [showCreateDefaultAlarm]="false" ></app-list-all-alarms>
                          -->
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </ng-container>

        </div>
    </app-block-dashboard>
</ng-container>
