
<app-breadcrumb></app-breadcrumb>

<div class="container-fluid pt-3 pl-0 pr-0 espace">


  <app-loading-custom [loading]="loading"></app-loading-custom>

  <app-block-group-dashboard *ngIf="loading == false">

    <div class="my-4">
      <div class="row align-items-center">
        <div class="col-xxl-8">
          <h1><b>{{title}}</b></h1>
        </div>
        <div class="col-xxl-4 text-xxl-end" *ngIf="id != 0 && ((authSrv.isAdmin() && information && information.Id != 0) || (information && information.Id != 0 && authSrv.currentUserValue?.UserId == information?.IdUserCreated))">
          <app-generic-button title="{{'modules.shared.warning' | translate}}" txt="{{'modules.modals.delete-current-alarm' | translate}}" classType="danger"
          (confirmDelete)="deleteAlarm()" ></app-generic-button>

        </div>

      </div>
    </div>

    <app-block-dashboard>

      <app-detail-device-alarm [information]="information"
        [showChangeManufacturer]="authSrv.isAdmin() && idDevice == null" [showChangeDeviceModel]="(authSrv.isAdmin() && idDevice == null) || (authSrv.isManufacturer() && idDevice == null )"
        [showChangeDevice]="(authSrv.isAdmin() && idDevice == null)  || (authSrv.isManufacturer() && idDevice == null ) || (authSrv.isClient() && idDevice == null )"
        [manufacturerList]="manufacturerList" [selectedManufacturerList]="selectedManufacturerList"
        [onlyDevice]="idDevice"
        [pageDeviceInto]="(idDevice)?true:false" >

      </app-detail-device-alarm>

    </app-block-dashboard>

  </app-block-group-dashboard>

</div>

<app-footer></app-footer>
