import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Device, Information, InformationChart } from 'src/app/models/device';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import * as moment from 'moment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DevicesService } from 'src/app/services/devices.service';


@Component({
  selector: 'app-detail-chart',
  templateUrl: './detail-chart.component.html',
  styleUrls: ['./detail-chart.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})

export class DetailChartComponent implements OnInit {

  @Input() device?: Device;
  @Input() showTitle: boolean = false;
  loading: boolean = true;
  noData: boolean = false;

  InformationFirstValue: moment.Moment = moment();
  InformationLastValue: moment.Moment = moment();


  filterChartForm: FormGroup = this.formBuilder.group({
  });

  infoChart: InformationChart = new InformationChart();


  constructor(
    private formBuilder: FormBuilder,
    private deviceSrv: DevicesService,
    public authSrv : AuthenticationService) {

  }
  get InformationFirst(){ return this.filterChartForm.get("InformationFirst"); }
  get InformationLast(){ return this.filterChartForm.get("InformationLast"); }

  public lineChartData: ChartConfiguration['data'] = {
    datasets: [
    ],
    labels: []
  };


  public lineChartOptions: ChartConfiguration['options'] = {
    interaction: {
      intersect: false,
      mode: 'x'
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Value'
        }
      }
    },
    elements: {
      line: {
        tension: 0.5
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: true },
      annotation: {
        annotations: [
          {
            type: 'line',
            scaleID: 'x',
            value: 'March',
            borderColor: 'orange',
            borderWidth: 2,
            label: {
              position: 'center',
              color: 'orange',
              content: 'LineAnno',
              font: {
                weight: 'bold'
              }
            }
          },
        ],
      },
      datalabels: {
        display: false
      }
    },
  };

  public lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;


  ngOnInit(): void {

    if(this.device!.InformationFirst){
      this.InformationFirstValue = moment(this.device?.InformationFirst);
      this.InformationLastValue = moment(this.device?.InformationLast);
    }



    console.log(this.InformationFirstValue);
    console.log(this.InformationLastValue);

    this.filterChartForm = this.formBuilder.group({
      InformationFirst: new FormControl(this.InformationFirstValue, [
        Validators.required
      ]),
      InformationLast: new FormControl(this.InformationLastValue, [
        Validators.required
      ])
    });

    if(this.device!.InformationFirst){
      this.loadChart();
    }else{
      this.noData = true;
    }
    this.loading = false;
  }


  public reloadInfo(){
    console.log('loadDevice: ' , this.device!.Id );
    this.deviceSrv.getTrackInformations(this.device!.Id!, this.InformationFirstValue.format("DD/MM/YYYY"), this.InformationLastValue.format("DD/MM/YYYY")).subscribe({
      next: (res: Array<Information>) => {
        this.device!.Informations! = [];
        res.forEach( element => {
          this.device!.Informations!.push(element);
        });

        if(res.length > 0 ){

          this.device!.InformationFirst = res[0].TrackPosition!.Date!;
          this.device!.InformationLast = res[res.length - 1].TrackPosition!.Date!;
        }


        console.log(this.device );
      }, error: ()=> {

      },
      complete: ()=>{
        this.loading = false;
        this.loadChart(true);
      }
    });
  }

  public loadChart(refresh: boolean= false){

    let opcionesColor : any= {
      'hoverOffset': 4,
      'cubicInterpolationMode': 'monotone', 'tension': 0.4, 'pointRadius': 4.5, 'hoverRadius': 6
    };

    let labelDataset = [
      'RPM',
      'Horas trabajo',
      'Presión',
      'Caudal',
      'Temp. aceite',
      'Nivel aceite',
      'Nº acciones',
      'Peso',
      'RPM 2',
      'RPM 3',
      'Presión 2',
      'Presión 3',
      'Caudal 2',
      'Caudal 3',
      'Peso 2',
      'Nº acciones 2',
      'Nº acciones 3',
      'Aux1',
      'Aux2',
      'Aux3',
      'Aux4',
      'Aux5'
    ];


    this.infoChart!.RPM! = this.device?.Informations?.map((element) => element.RPM);
    this.infoChart!.WorkingHours! = this.device?.Informations?.map((element) => element.WorkingHours);
    this.infoChart!.Pressure1! = this.device?.Informations?.map((element) => element.Pressure1);
    this.infoChart!.Pressure2! = this.device?.Informations?.map((element) => element.Pressure2);
    this.infoChart!.Flow1! = this.device?.Informations?.map((element) => element.Flow1);
    this.infoChart!.Flow2! = this.device?.Informations?.map((element) => element.Flow2);
    this.infoChart!.Action1! = this.device?.Informations?.map((element) => element.Action1);
    this.infoChart!.Action2! = this.device?.Informations?.map((element) => element.Action2);
    this.infoChart!.Action3! = this.device?.Informations?.map((element) => element.Action3);
    this.infoChart!.Action4! = this.device?.Informations?.map((element) => element.Action4);
    this.infoChart!.Action5! = this.device?.Informations?.map((element) => element.Action5);
    this.infoChart!.Action6! = this.device?.Informations?.map((element) => element.Action6);
    this.infoChart!.Weight1! = this.device?.Informations?.map((element) => element.Weight1);
    this.infoChart!.Weight2! = this.device?.Informations?.map((element) => element.Weight2);
    this.infoChart!.Aux1! = this.device?.Informations?.map((element) => element.Aux1);
    this.infoChart!.Aux2! = this.device?.Informations?.map((element) => element.Aux2);
    this.infoChart!.Aux3! = this.device?.Informations?.map((element) => element.Aux3);
    this.infoChart!.Aux4! = this.device?.Informations?.map((element) => element.Aux4);
    this.infoChart!.Aux5! = this.device?.Informations?.map((element) => element.Aux5);
    this.infoChart!.Aux6! = this.device?.Informations?.map((element) => element.Aux6);
    this.infoChart!.Aux7! = this.device?.Informations?.map((element) => element.Aux7);
    this.infoChart!.Aux8! = this.device?.Informations?.map((element) => element.Aux8);

    let data = [
      {...this.infoChart!.RPM! },
      {...this.infoChart!.WorkingHours! },
      {...this.infoChart!.Pressure1! },
      {...this.infoChart!.Pressure2! },
      {...this.infoChart!.Flow1! },
      {...this.infoChart!.Flow2! },
      {...this.infoChart!.Action1! },
      {...this.infoChart!.Action2! },
      {...this.infoChart!.Action3! },
      {...this.infoChart!.Action4! },
      {...this.infoChart!.Action5! },
      {...this.infoChart!.Action6! },
      {...this.infoChart!.Weight1! },
      {...this.infoChart!.Weight2! },
      {...this.infoChart!.Aux1! },
      {...this.infoChart!.Aux2! },
      {...this.infoChart!.Aux3! },
      {...this.infoChart!.Aux4! },
      {...this.infoChart!.Aux5! },
      {...this.infoChart!.Aux6! },
      {...this.infoChart!.Aux7! },
      {...this.infoChart!.Aux8! },
    ];

    console.log(data);
    let totalCount = 0;
    this.lineChartData.datasets = [];
    for(let i = 0; i < data.length; i++){
      //this.lineChartData.datasets[i].data = data[i];
      //this.lineChartData.datasets[i].label = labelDataset[i];

      let arr: any = [];
      Object.keys(data[i]).map(function(key){
          arr.push(data[i][key])
      });

      console.log(labelDataset[i]);
      console.log(data[i]);

      //this.lineChartData.datasets = [];

      if(arr.length > 0){
        totalCount += 1;
        this.lineChartData.datasets.push( { 'data': arr, 'label': labelDataset[i], 'hidden': (i > 1)?true:false, ...opcionesColor } )
        if(i==0){
          console.log(data[i])
          let auxArray: Array<number> = [];
          auxArray.push(1)
          auxArray.push(2)
          auxArray.push(3)
          auxArray.push(4)
          console.log(auxArray)

        }
      }
    }

    console.log(this.lineChartData.datasets)

    this.lineChartData.labels = this.device?.Informations?.map((element) => moment(element.TrackPosition!.Date).utc().format("YYYY-MM-DD"));

    if(totalCount==0){
      this.noData = true;
    }else{
      this.noData = false;

      if( this.InformationFirstValue == null){
        this.InformationFirstValue = moment(this.device?.InformationFirst);
      }
      if(this.InformationLastValue  == null){
        this.InformationLastValue = moment(this.device?.InformationLast);
      }
      /*
      console.log(this.InformationFirstValue);
      console.log(this.InformationLastValue);

      this.filterChartForm = this.formBuilder.group({
        InformationFirst: new FormControl(this.InformationFirstValue, [
          Validators.required
        ]),
        InformationLast: new FormControl(this.InformationLastValue, [
          Validators.required
        ])
      });*/
    }



    if(refresh){
      this.chart?.update();
    }else{
    }
    //this.ocultarDatasets();

  }

  // events
  public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    console.log(event, active);
    //this.ocultarDatasets()
  }

  public chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    //console.log(event, active);
  }

  public hideOne(): void {
    const isHidden = this.chart?.isDatasetHidden(1);
    this.chart?.hideDataset(1, !isHidden);
  }


  public changeColor(): void {
    this.lineChartData.datasets[2].borderColor = 'green';
    this.lineChartData.datasets[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;

    this.chart?.update();
  }

  public changeLabel(): void {
    if (this.lineChartData.labels) {
      this.lineChartData.labels[2] = [ '1st Line', '2nd Line' ];
    }

    this.chart?.update();
  }


  public ocultarDatasets(){

    const that = this;

    setTimeout(function () {

      that.chart?.hideDataset(6, true);
      that.chart?.hideDataset(7, true);
      that.chart?.hideDataset(8, true);
      that.chart?.hideDataset(9, true);
      that.chart?.hideDataset(10, true);
      that.chart?.hideDataset(11, true);
      that.chart?.hideDataset(12, true);
      that.chart?.hideDataset(13, true);
      that.chart?.hideDataset(14, true);
      that.chart?.hideDataset(15, true);
      that.chart?.hideDataset(16, true);
      that.chart?.hideDataset(17, true);
      that.chart?.hideDataset(18, true);
      that.chart?.hideDataset(19, true);
      that.chart?.hideDataset(20, true);
      that.chart?.hideDataset(21, true);
    }, 250);
  }



}
