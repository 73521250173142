import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RolesGuard } from 'src/app/guards/roles.guard';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { AddUserPageComponent } from './pages/add-user-page/add-user-page.component';
import { DetailUserPageComponent } from './pages/detail-user-page/detail-user-page.component';
import { EditUserPageComponent } from './pages/edit-user-page/edit-user-page.component';
import { ListUsersPageComponent } from './pages/list-users-page/list-users-page.component';

const routes: Routes = [
  {
    path: '',
    children:  [
      { path: 'list', component: ListUsersPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant'] }},
      { path: ':id/edit', component: EditUserPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant'] }},
      { path: ':id/edit/:page', component: EditUserPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant'] }},
      { path: ':id/detail', component: DetailUserPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant','user'] }},
      { path: 'add', component: AddUserPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant'] }},
      { path: 'add/:role', component: AddUserPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin'] }},
      { path: ':id/models/:idModel/detail', component: EditUserPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant'] }},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
