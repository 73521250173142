import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Device } from 'src/app/models/device';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { DevicesService } from 'src/app/services/devices.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-detail-chart-page',
  templateUrl: './detail-chart-page.component.html',
  styleUrls: ['./detail-chart-page.component.scss']
})
export class DetailChartPageComponent implements OnInit {

  id?: number;
  idAlert?: number;
  device?: Device;
  loading:boolean = true;

  constructor(private breadcrumbService: BreadcrumbService,
    private deviceSrv: DevicesService,
    private route: ActivatedRoute,
    private translate: TranslateService) {
      this.breadcrumbService.setBreadCrumbUrl('charts', this.translate.instant('modules.shared.chart'));


      this.route.params.subscribe(params => {
        this.id = params['id'];
        this.idAlert = params['idAlert'];
        console.log(this.id)
        this.loadDevice(params['id']??152 );

      });
  }

  ngOnInit(): void {
    //this.loadDevice(this.id! )
  }



  loadDevice(id: number){
    this.loading = true;
    console.log('loadDevice: ' , id );
    this.deviceSrv.getDeviceById(id).subscribe({
      next: (res: Device) => {
        this.device = <Device>res;
        console.log(this.device );
        this.loading = false;
      }, error: (e)=> {
        console.log(e);
        this.loading = false;
      },
      complete: ()=>{
        this.loading = false;
      }
    });
  }
}
