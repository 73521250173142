<app-loading-custom [loading]="(user == null || user == undefined)?true:false"></app-loading-custom>

<app-loading-custom [loading]="loadingPassword"></app-loading-custom>


<ng-container *ngIf="user">

  <div class="col-12 col-xl-12 col-lg-12" style="display: block">

    <div class="my-4">
      <div class="row align-items-center">
        <div class="col-xl-8">
          <h1><b>{{'modules.users.detail-of' | translate}}</b> {{ user.Name }} {{ user.FirstName}} </h1>
        </div>
        <div class="col-xl-4 text-xl-end">
          <ng-container *ngIf="isAdmin && user.Id != authSrv.currentUserValue!.UserId">


            <app-generic-button classType="warning" txtBtn="{{'modules.users.simulate-alert' | translate}}" title="{{'modules.shared.warning' | translate}}" txt="{{'modules.users.send-simulate-alert' | translate}}?"
              btnAccept="{{'modules.shared.send' | translate}}" (confirmDelete)="sendNewAlert()" ></app-generic-button>

            <app-generic-button txtBtn="{{'modules.users.new-password' | translate}}" title="{{'modules.shared.warning' | translate}}" txt="{{'modules.users.send-password-email' | translate}}?"
              btnAccept="{{'modules.shared.send' | translate}}" (confirmDelete)="sendNewPassword()" ></app-generic-button>

            <div class="btn btn-primary m-2" (click)="goEditUser()">
              {{'modules.users.edit-user' | translate}}
            </div>
          </ng-container>
          <ng-container *ngIf="(user.Id == authSrv.currentUserValue!.UserId) || (isManufacturerOfClient)">
            <div class="btn btn-primary" (click)="goEditUser()">
              {{'modules.shared.edit' | translate}}
            </div>
          </ng-container>
        </div>
      </div>
    </div>


    <app-block-dashboard>
      <div class="container-fluid my-3">
        <div class="main main-box">
          <div class="row">
            <div class="mb-3" [ngClass]="(user!.FirstName && user.FirstName != '')?'col-md-6':'col-md-12'">
              <div><strong>{{'modules.tables.name' | translate}}:</strong></div>
              <div>{{ user!.Name??'' }}</div>
            </div>
            <div class="col-md-6 mb-3" *ngIf="user!.FirstName && user.FirstName != ''" >
              <div><strong>{{'modules.tables.surnames' | translate}}:</strong></div>
              <div>{{ user!.FirstName }} {{ user.LastName??'' }}</div>
            </div>
            <div class="col-md-6 mb-3">
              <div><strong>{{'modules.tables.email2' | translate}}:</strong> </div>
              <div>{{ user.Email??'modules.shared.no-info' | translate }}</div>
            </div>
            <div class="col-md-6 mb-3">
              <div><strong>{{'modules.tables.phone' | translate}}:</strong> </div>
              <div>{{ user.Phone1??'modules.shared.no-info' | translate }}</div>
            </div>
            <div class="col-md-6 mb-3">
              <div><strong>{{'modules.users.register-date' | translate}}:</strong> </div>
              <div>{{ user.CreatedAt | date: "dd-MM-yyyy HH:mm"  }}</div>
            </div>
            <div class="col-md-6 mb-3">
              <div><strong>{{'modules.tables.last-modification' | translate}}:</strong> </div>
              <div *ngIf="user.UpdatedAt">{{ user.UpdatedAt | date: "dd-MM-yyyy HH:mm"  }}</div>
              <div *ngIf="user.UpdatedAt == null"> - </div>
            </div>
            <div class="col-md-6 mb-3">
              <div><strong>{{'modules.users.user-type' | translate}}:</strong> </div>
              <div>{{ user.Role!.Name??''  }}</div>
            </div>
          </div>
        </div>
      </div>

      <mat-tab-group *ngIf="(authSrv.isAdmin() == true || authSrv.isManufacturer() == true) && user.Id != authSrv.currentUserValue!.UserId" mat-align-tabs="start"  style="width:100vw;">

        <mat-tab>
          <ng-template mat-tab-label>
            {{'modules.devices.devices' | translate}}
          </ng-template>
          <div class="mt-2 p-2">
            <app-list-devices [userInput]="user" [isManufacturer]="isManufacturer" [showNewDevice]="user.isClient == true?false:true"  ></app-list-devices>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            {{'modules.alarms.alarms' | translate}}
          </ng-template>
        </mat-tab>
        <mat-tab *ngIf="user.Role!.Tag! == 'fabricant'">
          <ng-template mat-tab-label>
            {{'modules.users.users' | translate}}
          </ng-template>
          <div class="mt-2 p-2">
            <app-list-users [userInput]="user" [showNewUser]="authSrv.isAdmin() || authSrv.isManufacturer()"></app-list-users>
          </div>
        </mat-tab>
        <mat-tab *ngIf="user.Role!.Tag! == 'fabricant'">
          <ng-template mat-tab-label>
            {{'modules.devices.models' | translate}}
          </ng-template>
          <div class="mt-2 p-2">
            <app-list-device-models [userInput]="user"></app-list-device-models>
          </div>
        </mat-tab>
      </mat-tab-group>

    </app-block-dashboard>


    <!--
    <app-block-dashboard *ngIf="user && user.Role!.Tag! != 'admin'">
      <h2>{{'modules.devices.devices-list' | translate}}</h2>
      <app-list-devices [userInput]="user"></app-list-devices>
    </app-block-dashboard>


    <app-block-dashboard>
      <h2>{{'modules.alarms.alarms-list' | translate}}</h2>
    </app-block-dashboard>


    <app-block-dashboard *ngIf="user.Role!.Tag! == 'fabricant'">
      <h2>{{'modules.users.users-list2' | translate}}</h2>
    </app-block-dashboard>
    -->
  </div>

</ng-container>
