import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DevicesRoutingModule } from './devices-routing.module';
import { MaterialCdkModule } from '../material-cdk/material-cdk.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { DetailDevicePageComponent } from './pages/detail-device-page/detail-device-page.component';
import { ListDevicesPageComponent } from './pages/list-devices-page/list-devices-page.component';
import { ListDevicesComponent } from './components/list-devices/list-devices.component';
import { DetailDeviceComponent } from './components/detail-device/detail-device.component';
import { AddDevicePageComponent } from './pages/add-device-page/add-device-page.component';
import { AddDeviceComponent } from './components/add-device/add-device.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AddModelDevicePageComponent } from './pages/add-model-device-page/add-model-device-page.component';
import { AddModelDeviceComponent } from './components/add-model-device/add-model-device.component';
import { ListDeviceModelsComponent } from './components/list-device-models/list-device-models.component';
import { DetailDeviceModelPageComponent } from './pages/detail-device-model-page/detail-device-model-page.component';
import { DetailDeviceModelComponent } from './components/detail-device-model/detail-device-model.component';
import { SearchDeviceModelsComponent } from './components/search-device-models/search-device-models.component';
import { ChartsModule } from '../charts/charts.module';
import { SensorInformationsDetailDeviceComponent } from './components/sensor-informations-detail-device/sensor-informations-detail-device.component';
import { MapDeviceComponent } from './components/map-device/map-device.component';
import { ConfigurationDetailDeviceComponent } from './components/configuration-detail-device/configuration-detail-device.component';
import { AlarmsModule } from '../alarms/alarms.module';

import { NgMapsCoreModule } from '@ng-maps/core';
import { GOOGLE_MAPS_API_CONFIG, NgMapsGoogleModule } from '@ng-maps/google';
import { EditDeviceComponent } from './components/edit-device/edit-device.component';
import { EditDeviceModelComponent } from './components/edit-device-model/edit-device-model.component';
import { NotificationsModule } from '../notifications/notifications.module';
import { CustomDecimalPipe } from 'src/app/pipes/decimal.pipe';
import { CustomHoursMinutesPipe } from 'src/app/pipes/hours-minutes.pipe';
import { ConsoleDevicePageComponent } from './pages/console-device-page/console-device-page.component';
import { ConsoleDeviceComponent } from './components/console-device/console-device.component';
import { NgTerminalModule } from 'ng-terminal';
import { LoadFirmwarePageComponent } from './pages/load-firmware-page/load-firmware-page.component';
import { LoadFirmwareComponent } from './components/load-firmware/load-firmware.component';
import { LoadConfigurationComponent } from './components/load-configuration/load-configuration.component';
import { LoadConfigurationPageComponent } from './pages/load-configuration-page/load-configuration-page.component';
import { AgmCoreModule } from '@agm/core';
import { MapListDevicesComponent } from './components/map-list-devices/map-list-devices.component';
import { ListTripsDevicesComponent } from './components/list-trips-devices/list-trips-devices.component';
import { DetailTripDeviceComponent } from './components/detail-trip-device/detail-trip-device.component';
import { DetailTripDevicePageComponent } from './pages/detail-trip-device-page/detail-trip-device-page.component';
import { TripsModule } from '../trips/trips.module';
import { SplitTripDevicePageComponent } from './pages/split-trip-device-page/split-trip-device-page.component';
import { DetailJoinTripDevicePageComponent } from './pages/detail-join-trip-device-page/detail-join-trip-device-page.component';

@NgModule({
  declarations: [
    DetailDevicePageComponent,
    ListDevicesPageComponent,
    ListDevicesComponent,
    DetailDeviceComponent,
    AddDevicePageComponent,
    AddDeviceComponent,
    AddModelDevicePageComponent,
    AddModelDeviceComponent,
    ListDeviceModelsComponent,
    DetailDeviceModelPageComponent,
    DetailDeviceModelComponent,
    SearchDeviceModelsComponent,
    SensorInformationsDetailDeviceComponent,
    MapDeviceComponent,
    ConfigurationDetailDeviceComponent,
    EditDeviceComponent,
    EditDeviceModelComponent,
    CustomDecimalPipe,
    CustomHoursMinutesPipe,
    ConsoleDevicePageComponent,
    ConsoleDeviceComponent,
    LoadFirmwarePageComponent,
    LoadFirmwareComponent,
    LoadConfigurationComponent,
    LoadConfigurationPageComponent,
    MapListDevicesComponent,
    ListTripsDevicesComponent,
    DetailTripDeviceComponent,
    DetailTripDevicePageComponent,
    DetailJoinTripDevicePageComponent,
    SplitTripDevicePageComponent
  ],
  imports: [
    CommonModule,
    MaterialCdkModule,
    CoreModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    DevicesRoutingModule,
    DataTablesModule,
    NgMultiSelectDropDownModule,
    ChartsModule,
    AlarmsModule,
    NgMapsCoreModule,
    NgMapsGoogleModule,
    NotificationsModule,
    NgTerminalModule,
    AgmCoreModule,
    TripsModule
  ],
  exports:[
    ChartsModule,
    MaterialCdkModule,
    NgMultiSelectDropDownModule,
    AddModelDeviceComponent,
    AddDeviceComponent,
    EditDeviceModelComponent,
    EditDeviceComponent,
    ListDevicesComponent,
    ListDeviceModelsComponent,
    SearchDeviceModelsComponent,
  ],
  providers: [

    {
      provide: GOOGLE_MAPS_API_CONFIG,
      useValue: {
        apiKey: 'AIzaSyBoksiGlAZxushSb9vuGN4SNYGrMuTsFSk'
      }
    }
  ]
})
export class DevicesModule { }
