import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalComponent } from 'src/app/modals/confirmation-modal/confirmation-modal.component';
import { InfoModalComponent } from 'src/app/modals/info-modal/info-modal.component';
import { Information } from 'src/app/models/device';
import { Modal } from 'src/app/models/modal';
import { Trip, TripEvent } from 'src/app/models/trip';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { DevicesService } from 'src/app/services/devices.service';
import { TripsService } from 'src/app/services/trips.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-detail-join-trip-page',
  templateUrl: './detail-join-trip-page.component.html',
  styleUrls: ['./detail-join-trip-page.component.scss']
})
export class DetailJoinTripPageComponent implements OnInit {

  id?: number;
  loading:boolean = true;
  idModel?: number;
  trip?: Trip;
  title: string = "";
  showChangeModel= true;
  showDeleteButton: boolean = false;
  hasDevicePage: boolean = false;
  addTrips: Array<number> = [];
  arrayViajes: Array<number> = [];
  
  constructor(private breadcrumbService: BreadcrumbService,
    public dialog: MatDialog,
    public authSrv: AuthenticationService,
    private deviceSrv: DevicesService,
    private tripSrv : TripsService,
    private router: Router,
    private utilsSrv: UtilsService,
    private route: ActivatedRoute,
    private translate: TranslateService) { 
      this.title = this.translate.instant('modules.trips.trip-management')
      breadcrumbService.setBreadCrumbUrl('trips', this.title );
      this.route.queryParamMap.subscribe(params => {

        params.get('addTrips')?.split(',').forEach( x => {
          this.addTrips.push(+x);
        });

        this.route.params.subscribe(params => {

          console.log('params', params);
    
          this.id = params['id'];
          let auxId = params['idDevice'];
          //let addTrips2 = params.get('addTrip');
  
          this.arrayViajes.push(this.id! );
          this.addTrips.forEach( x =>{
            this.arrayViajes.push(x);
          } );
  
    
          if(auxId){
            this.hasDevicePage = true;
            this.title = this.translate.instant('modules.trips.trip-management-device')
    
          }else{
            this.loadValues(this.id!, this.addTrips );
    
          }
    
        });

      });
      
    }

  ngOnInit(): void {

  }
  
  loadValues(id: number, addTrips: Array<number>){
    this.loading = true;
    this.trip = undefined;
    console.log('load trip: ' , id );
    console.log('load addTrips: ' , addTrips );
    this.tripSrv.getByIds(id, addTrips).subscribe({
      next: (res: Trip) => {
        this.trip = new Trip(<Trip>res);

        if(this.hasDevicePage){

        }else{
          this.breadcrumbService.setBreadCrumbUrl('trips', this.translate.instant('modules.trips.trip-management-device'));

          this.title = this.translate.instant('modules.trips.aggregate-view-trip') + ': ' + this.arrayViajes.join(',');
          this.breadcrumbService.addBreadCrumb('trips/' + this.trip!.Id  ,this.title  );
        }

        
        this.trip!.Device!.Informations! = new Array<Information>();
        
        this.trip!.Events = new Array<TripEvent>;
        this.trip.DeviceTripEvents?.forEach( (e) =>{
          this.trip!.Events!.push(e);

        });
        /*
        this.trip!.Events.push(new TripEvent({Id: 0, Type: 1, Name: 'Inicio viaje', Information: this.trip!.DeviceTripTrackInformations![0].TrackInformation!, Date: Date.parse('2023-09-28T13:44:49')}));
        this.trip!.Events.push(new TripEvent({Id: 15, Type: 3, Name: 'Inicio trabajo', Information: this.trip!.DeviceTripTrackInformations![15].TrackInformation!, Date: Date.parse('2023-09-28T13:46:04')}));
        this.trip!.Events.push(new TripEvent({Id: 20, Type: 2, Name: 'Alarma 1', Information: this.trip!.DeviceTripTrackInformations![20].TrackInformation!, Description: '1' , Date: Date.parse('2023-09-28T12:46:04')}));
        this.trip!.Events.push(new TripEvent({Id: 25, Type: 2,Name: 'Alarma 2', Information: this.trip!.DeviceTripTrackInformations![25].TrackInformation!, Description: '1' , Date: Date.parse('2023-09-28T13:46:04')}));
        this.trip!.Events.push(new TripEvent({Id: 26, Type: 4,Name: 'Fin trabajo', Information: this.trip!.DeviceTripTrackInformations![26].TrackInformation!, Date: Date.parse('2023-09-28T16:46:04')}));
        this.trip!.Events.push(new TripEvent({Id: 27, Type: 1, Name: 'Fin viaje', Information: this.trip!.DeviceTripTrackInformations![0].TrackInformation!, Date: Date.parse('2023-09-28T16:02:52')}));
        */
        
        this.trip?.DeviceTripTrackInformations?.forEach((e)=>{
          this.trip!.Device!.Informations!.push(e.TrackInformation!);
        });



        
        if(this.trip!.Device!.Informations && this.trip!.Device!.Informations!.length > 0 ){
          this.trip!.Device!.InformationFirst = this.trip!.Device!.Informations![0].TrackPosition?.Date;
          this.trip!.Device!.InformationLast = this.trip!.Device!.Informations![this.trip!.Device!.Informations!.length-1].TrackPosition?.Date;
        }


      }, error: ()=> {
        this.loading = false;

      },
      complete: ()=>{
        this.loading = false;

        console.log(this.trip);
      }
    });
  }

  

  get positions(): Array<Information>{

    let pos = new Array<Information>();
    
    this.trip?.DeviceTripTrackInformations?.forEach((e)=>{
      pos.push(e.TrackInformation!);
    });
    return pos;

  }

  joinTrip(){
    let modal = new Modal();
    modal.Title = this.translate.instant('modules.modals.join-trip');
    modal.Body = this.translate.instant('modules.modals.sure-to-join-trips') + ": " + this.arrayViajes.join(',') + ' ?';
    modal.BtnAccept = this.translate.instant('modules.modals.join-trip')
    modal.BtnCancel = this.translate.instant('modules.shared.cancel');
    modal.ClassType = "warning";

    const dialog = this.dialog.open(
      ConfirmationModalComponent,
      {
      autoFocus : 'dialog',
      width: "450px",
      height: "auto",
      data: modal,
      closeOnNavigation: false,
      disableClose: true,
      panelClass: 'custom-modalbox',
      position: { top: '150px'}
    });

    dialog.afterClosed().subscribe(
      (result: string) => {

        console.log("Input: ", result);
        if(result != ''){
          this.loading = true;

          this.tripSrv.joinById(this.trip! , this.arrayViajes[1].toString()).subscribe(
            {
              next: (res: Trip) => {
                this.router.navigate(['/trips/' + res!.Id!  ]);
                this.trip = res;
                this.loading = false;
                

              },
              error: (err) =>{
                this.loading = false;
                this.utilsSrv.infoModal(this.translate.instant('modules.modals.delete-trip-error') + '.',undefined);
      
              }
            }
          );
          
        }
      }
    );
  }
}
