import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Modal } from 'src/app/models/modal';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-upload-file-config-modal',
  templateUrl: './upload-file-config-modal.component.html',
  styleUrls: ['./upload-file-config-modal.component.scss']
})
export class UploadFileConfigModalComponent implements OnInit {

  typeForm: string = "ConfigFile";
  waitUpload = false;
  commandsList: Array<string> | null = null;
  textInput: string = this.translate.instant('modules.modals.select-config') + '.';

  updateWaitUpload(wait: boolean) {
    console.log("updateWaitUpload");
    this.waitUpload = wait;
  }

  updateCommandsNewFile(commands: Array<string>) {
    this.commandsList = commands;
  }

  constructor(
    private dialogRef: MatDialogRef<UploadFileConfigModalComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: Modal) {

      if(data.Data['textInput']){
        console.log(data.Data['textInput']);
        this.textInput = data.Data['textInput'];
      }


      if(data.Data['typeForm']){
        console.log(data.Data['typeForm']);
        this.typeForm = data.Data['typeForm'];
      }
    }

  ngOnInit(): void {
  }

  accept(){
    this.dialogRef.close(this.commandsList);
  }

  cancel(){
    this.dialogRef.close(null);
  }

}
