<app-loading-custom [loading]="loading"></app-loading-custom>

<ng-container *ngIf="loading == false">

<form class="text-start p-2" [formGroup]="valueRangeForm" (ngSubmit)="onSubmit()" autocomplete="off"  [ngClass]="(minValue == undefined && maxValue==undefined && readOnly ==true)?'d-none':''"
>

  <table class="full-width" cellspacing="0" [ngClass]="(name=='Aux 5' && authSrv.isAdmin() == false)?'d-none':''">
    <tr>
      <td>
        {{ translation }}
      </td>
      <td>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>{{'modules.shared.min' | translate}}</mat-label>
          <input [id]="tag+'_min'" matInput [(ngModel)]="minValue" formControlName="minValue" type="number" >
          <mat-error *ngIf="minValueInput!.errors?.['required']">{{'modules.alarms.the' | translate}} {{ translation }} {{'modules.alarms.min-required' | translate}}.</mat-error>
          <mat-error *ngIf="minValueInput!.errors?.['pattern']">{{'modules.alarms.the' | translate}} {{ translation }} {{'modules.alarms.min-invalid' | translate}}.</mat-error>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>{{'modules.shared.max' | translate}}</mat-label>
          <input [id]="tag+'_max'" matInput [(ngModel)]="maxValue" formControlName="maxValue" type="number" >
          <mat-error *ngIf="maxValueInput!.errors?.['required']">{{'modules.alarms.the' | translate}} {{ translation }} {{'modules.alarms.max-required' | translate}}.</mat-error>
          <mat-error *ngIf="maxValueInput!.errors?.['pattern']">{{'modules.alarms.the' | translate}} {{ translation }} {{'modules.alarms.max-invalid' | translate}}.</mat-error>
        </mat-form-field>
      </td>
      <td *ngIf="readOnly == false">
        <mat-checkbox color="primary" class="example-margin" [(ngModel)]="noValues" formControlName="noValues" (change)="changeAlarm($event.checked)" >{{'modules.alarms.no-alarm' | translate}}</mat-checkbox>
      </td>
    </tr>
  </table>
</form>

<!--
<button id="basic" type="button" mat-button (click)="showForm()">
  show
</button>
-->
