import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { User, vUser } from 'src/app/models/user';
import { Subject } from 'rxjs';
import { UsersService } from 'src/app/services/users.services';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ListResult } from 'src/app/models/apiRequestObject';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from 'src/app/services/utils.service';
import { DatatableColumn, DatatableConfig } from 'src/app/models/datatableColumn';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserAddQuickComponent } from 'src/app/modals/user-add-quick/user-add-quick.component';
import { LinkModal, Modal } from 'src/app/models/modal';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from 'src/app/modals/confirmation-modal/confirmation-modal.component';
declare const $: any

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss']
})
export class ListUsersComponent implements OnInit, OnDestroy, AfterViewInit {
  public page?: number;
  @Input() showNewUser: boolean = true;
  @ViewChild(DataTableDirective, {static: false}) datatableElement?: DataTableDirective;
  @Input() userInput: User | null = null;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  nameDatatable: string = "UserList";
  datatableConfigColumns: DatatableConfig = new DatatableConfig();
  isDtInitialized: boolean = false;
  viewPaging: boolean = true;

  loadingDelete: boolean = false;

  listaUsuarios: vUser[] = [];


  loading: boolean = true;

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private router : Router,
    private usersSrv : UsersService,
    private utilsSrv : UtilsService,
    public authSrv: AuthenticationService

    ) { }

    get isViewManufacturer(): boolean{

      if(this.userInput){
        return this.userInput.isManufacturer;
      }
      return false;
    }

    get isViewClient(): boolean{

      if(this.userInput){
        return this.userInput.isClient;
      }
      return false;
    }

  ngAfterViewInit(): void {


      console.log("ngAfterViewInit");
        const that = this;
        that.dtTrigger.next(0);
        /*
        setTimeout(function () {
          $('#' + that.nameDatatable).DataTable().destroy();
          //document.getElementsByClassName('dataTables_empty')[0].style.display = 'none';

          /*that.datatableElement!.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
          });
          that.dtTrigger.next(0);
          that.datatableElement!.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
          });

        }, 300);*/

  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngOnInit(): void {
    this.dtOptions = this.optionsDatatable();

  }

  public optionsDatatable() {

    this.datatableConfigColumns.headerTable = [
      new DatatableColumn(0,"#", "NameRole", "asc"),
      new DatatableColumn(1,this.translate.instant('modules.tables.name'), "Name", "asc"),
      new DatatableColumn(2,this.translate.instant('modules.tables.surnames'), "FirstName", "asc"),
      new DatatableColumn(3,this.translate.instant('modules.tables.created-at'), "CreatedAt", "desc"),
      new DatatableColumn(4,this.translate.instant('modules.tables.email'), "Email", "asc"),
      new DatatableColumn(5,this.translate.instant('modules.tables.num-devices'), "NumDevices", "desc"),
      new DatatableColumn(6,this.translate.instant('modules.tables.num-clients'), "NumClients", "desc"),
      new DatatableColumn(7,this.translate.instant('modules.tables.num-manufacturers'), "NumManufacturers", "desc"),
      new DatatableColumn(8,this.translate.instant('modules.tables.active'), "Active", "desc"),
      new DatatableColumn(9,this.translate.instant('modules.tables.actions'), "", "asc")
    ];

    console.log("list-users input ", this.userInput);

    if(this.userInput){
      if(this.userInput.isClient){
        console.log("isClient ");
        this.datatableConfigColumns.hideColumnByTag("NumClients");
        this.datatableConfigColumns.hideColumnByTag("NumManufacturers");
      }

    }

    if(!this.authSrv.isAdmin()){
      console.log("isManufacturer ");
      this.datatableConfigColumns.hideColumnByTag("NumClients");
      this.datatableConfigColumns.hideColumnByTag("NumManufacturers");
      this.datatableConfigColumns.hideColumnByTag("NumDevices");
    }



    this.datatableConfigColumns.changeOrderByTag("CreatedAt");
    this.datatableConfigColumns.orderDesc();

    const that = this;

    console.log("DATATABLES", this.datatableConfigColumns.order, this.datatableConfigColumns.columnDefs );

    console.log(this.datatableConfigColumns.columnDefs);

    return {
      // Use this attribute to enable the responsive extension
      name: this.nameDatatable,
      order: this.datatableConfigColumns.order,
      columnDefs: this.datatableConfigColumns.columnDefs,
      lengthChange: true,
      serverSide: true,
      processing: false,
      retrieve: true,
      //stateSave: true,
      deferRender: true,
      initComplete: (settings: DataTables.Settings, json: any) => {
        $("#" + this.nameDatatable).wrap("<div class='demo' style='overflow:auto; width:100%;position:relative;'></div>");
        if(!this.viewPaging){
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().parent().find("div").eq(0).removeClass("col-md-5").addClass("col-md-12");
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().css("display", "none" );
        }else{
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().parent().find("div").eq(0).removeClass("col-md-12").addClass("col-md-5");
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().css("display", "block" );
        }
      },
      ajax: (dataTablesParameters: any, callback: any) => {

        that.loading = true;
        that.listaUsuarios = [];

        let column = dataTablesParameters.order[0].column;
        let colmunOrder = this.datatableConfigColumns.getColumnById(column);
        let colmunOrderTag = colmunOrder?.tag!;
        let order = dataTablesParameters.order[0].dir;
        let draw = dataTablesParameters.draw;

        dataTablesParameters.search.regex = true
        let page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length);
        this.listaUsuarios = [];

        that.usersSrv.getList(page, dataTablesParameters.length, colmunOrderTag, dataTablesParameters.order[0].dir, dataTablesParameters.search.value, this.userInput??undefined).subscribe(
          {
            next: (res: ListResult) => {

            that.listaUsuarios = <vUser[]>res.Result.map( x => new vUser(x));
            //that.listaUsuarios = <vUser[]>res.Result;

            this.viewPaging = false;
            if(res.Filter > 10){
              this.viewPaging = true;
            }

            callback({
              recordsTotal: res.Total,
              recordsFiltered: res.Filter,
              data: []
            });

            that.loading = false;
            if (res.Result.length > 0) {
              $('.dataTables_empty').css('display', 'none');
            }
            that.columnAdjust();

          },
          error: ( err : any) => {
            that.loading = false;
          }
        });


      },
      language: {
        url: this.utilsSrv.DatatablesFileLang()
      },
      infoCallback: function( settings: any, start:number, end:number, max:number, total:number, pre: any ) {
        return that.datatableConfigColumns.DatatablesFooterShowText(settings, start,end, max, total , pre );
      },
    };

  }

  rerender(): void {
    this.datatableElement!.dtInstance.then((dtInstance: DataTables.Api) => {
       dtInstance.destroy();
       this.dtTrigger.next(0);
    });
  }

  columnAdjust(): void {
    $($.fn.dataTable.tables(true)).DataTable().columns.adjust().responsive.recalc();
  }


  newUser(){

    const dialogRef = this.dialog.open(
      UserAddQuickComponent,
      {
        id: 'UserAddQuickComponent',
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: new Modal({ 'Data' : 'client' }),
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe( (result?: vUser) => {
      if(result){
        this.rerender();

      }
    });

  }

  deleteItem(user: User){

    let modal = new Modal();
    modal.Title = this.translate.instant('modules.shared.warning');
    modal.Body = this.translate.instant('modules.modals.delete-user') + " " + user.Name??'' + ' ' + user.FirstName??'';
    modal.BtnAccept = this.translate.instant('modules.shared.delete')
    modal.BtnCancel = this.translate.instant('modules.shared.cancel');
    modal.ClassType = "danger";

    const dialogRef = this.dialog.open(
      ConfirmationModalComponent,
      {
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: modal,
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe( (result: boolean) => {
      if(result){

        this.loadingDelete = true;

        this.usersSrv.delete(user).subscribe({
          next: (res: boolean) => {
            if(res == true){
             // this.dtTrigger.next(0);
              this.rerender();
            }
          },
          error: (err: any) =>{
            if(err.status! == "MODAL"){
              let link: LinkModal= new LinkModal(
                { 'Text': this.translate.instant('modules.modals.here'),
                'Link': 'users/' + user.Id  + '/detail',
                'PreText': this.translate.instant('modules.modals.click-for') + ' ' ,
                'PostText':' ' + this.translate.instant('modules.modals.review-user') + '.'
              });
              this.utilsSrv.infoModal(err.msg!,link);
            }
            this.loadingDelete = false;
          },
          complete: () =>{
            this.loadingDelete = false;
          }
        });



        console.log("eliminar user " , user.Name , " " , user.Id)
      }

    });

  }

}
