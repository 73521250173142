import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalComponent } from 'src/app/modals/confirmation-modal/confirmation-modal.component';
import { Device, Information } from 'src/app/models/device';
import { Modal } from 'src/app/models/modal';
import { Trip } from 'src/app/models/trip';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { DevicesService } from 'src/app/services/devices.service';
import { TripsService } from 'src/app/services/trips.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-detail-trip-device',
  templateUrl: './detail-trip-device.component.html',
  styleUrls: ['./detail-trip-device.component.scss']
})
export class DetailTripDeviceComponent implements OnInit {

  
  @Input() trip!: Trip;
  @Input() centralPoint!: [number,number];
  @Input() positions!: Array<Information>;
  @Input() addTrips: Array<number> = [];
  @Input() hasAggregateView: boolean = false;
  arrayViajes: Array<number> = [];
  loading:boolean = true;

  constructor(private breadcrumbService: BreadcrumbService,
    public dialog: MatDialog,
    public authSrv: AuthenticationService,
    private deviceSrv: DevicesService,
    private tripSrv : TripsService,
    private translate: TranslateService,
    private router: Router,
    private utilsSrv: UtilsService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    if(this.hasAggregateView && this.trip.Id2 ){
      this.arrayViajes.push(this.trip.Id! );
      this.arrayViajes.push(this.trip.Id2! );
    }

 
  }

  goToDevice(){
    this.router.navigate(['/devices/' + this.trip.Device!.Id  + '/detail']);
  }


  splitTrip(){
    this.router.navigate(['/devices/' + this.trip.Device!.Id  + '/trips/' + this.trip!.Id! + '/split' ]);

  }
  deleteTrip(){
    console.log("deleteTrip");
    this.loading = true;
    this.tripSrv.deleteById(this.trip!).subscribe(
      {
        next: (res: boolean) => {
          this.loading = false;
          if(res==true){
            this.router.navigate(['/trips']);
          }else{            
            this.utilsSrv.infoModal(this.translate.instant('modules.modals.delete-trip-error') + '.',undefined);
          }
        },
        error: (err) =>{
          this.loading = false;
          this.utilsSrv.infoModal(this.translate.instant('modules.modals.delete-trip-error') + '.',undefined);

        }
      }
    );
  }

  
  joinTrip(){
    console.log(this.arrayViajes, this.trip.Device!.Id!);
    let modal = new Modal();
    modal.Title = this.translate.instant('modules.modals.join-trip');
    modal.Body = this.translate.instant('modules.modals.sure-to-join-trips') + ": " + this.arrayViajes.join(',') + ' ?';
    modal.BtnAccept = this.translate.instant('modules.modals.join-trip')
    modal.BtnCancel = this.translate.instant('modules.shared.cancel');
    modal.ClassType = "warning";

    const dialog = this.dialog.open(
      ConfirmationModalComponent,
      {
      autoFocus : 'dialog',
      width: "450px",
      height: "auto",
      data: modal,
      closeOnNavigation: false,
      disableClose: true,
      panelClass: 'custom-modalbox',
      position: { top: '150px'}
    });

    dialog.afterClosed().subscribe(
      (result: string) => {

        console.log("Input: ", result);
        if(result != ''){
          this.loading = true;

          this.tripSrv.joinById(this.trip! , this.arrayViajes[1].toString()).subscribe(
            {
              next: (res: Trip) => {
                this.router.navigate(['/devices/'+ this.trip.Device!.Id! + '/trips/' + res!.Id!  ]);
                this.trip = res;
                this.loading = false;
                

              },
              error: (err) =>{
                this.loading = false;
                this.utilsSrv.infoModal(this.translate.instant('modules.modals.delete-trip-error') + '.',undefined);
      
              }
            }
          );
          
        }
      }
    );
  }

}
