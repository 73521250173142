import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { DateTime } from 'luxon';
import { MatDatepicker } from '@angular/material/datepicker';

export const MY_FORMATS_LUXON = {
  parse: {
    dateInput: 'MM/yyyy',
  },
  display: {
    dateInput: 'MM/yyyy',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-edit-user-bank-information',
  templateUrl: './edit-user-bank-information.component.html',
  styleUrls: ['./edit-user-bank-information.component.scss'],
  providers:[
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_LUXON},
  ]
})
export class EditUserBankInformationComponent implements OnInit {

  @Input() user?: User;
  @Input() page?: number;
  userEdit?: User;
  loading:boolean = true;

  public endDate?: DateTime;


  bankInfoForm: FormGroup = this.formBuilder.group({
  });

  @Output() bankInfoFormShared = new EventEmitter<FormGroup>();

  private emitFormGeneral() {
    this.bankInfoFormShared.emit(this.bankInfoForm);
  }

  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private dateAdapter: DateAdapter<Date>,
    public authSrv : AuthenticationService) {
      this.dateAdapter.setLocale('es-ES');
  }

  ngOnInit(): void {

    this.userEdit = this.user;

    if(this.user?.CreditCardDate){
      this.endDate = DateTime.local(this.user!.CreditCardDate.getFullYear(), this.user!.CreditCardDate.getMonth(), 1);
    }

    this.bankInfoForm.addControl('BankInformation',this.initForm());
    this.emitFormGeneral();

    this.loading = false;
  }

  get CreditCardName(){ return this.bankInfoForm.get("BankInformation")!.get("CreditCardName"); }
  get CreditCardDate(){ return this.bankInfoForm.get("BankInformation")!.get("CreditCardDate"); }
  get CreditCard(){ return this.bankInfoForm.get("BankInformation")!.get("CreditCard"); }


  initForm() {
    return this.formBuilder.group({
      CreditCardName: new FormControl(this.user!.CreditCardName, [
        Validators.minLength(3),
        Validators.maxLength(55)
      ]),
      CreditCardDate: new FormControl(this.user!.CreditCardDate, [
      ]),
      CreditCard: new FormControl(this.user!.CreditCard, [
        Validators.minLength(16),
        Validators.maxLength(16)
      ])
    });
  }

  openDatePicker(dp: any) {
    dp.open();
  }

  closeDatePicker(eventData: any, dp?:any) {
    // get month and year from eventData and close datepicker, thus not allowing user to select date
    dp.close();
  }

  setMonthAndYear(normalizedMonthAndYear: DateTime, datepicker: MatDatepicker<DateTime>) {
    console.log(normalizedMonthAndYear.toFormat("dd-MM-yyyy"));

    //this.endDate.set({ day: 1, month: normalizedMonthAndYear.month, year: normalizedMonthAndYear.year  });
    this.endDate = DateTime.local(normalizedMonthAndYear.year, normalizedMonthAndYear.month, 1);
    console.log(this.endDate.toFormat("DD-MM-YYYY"));
    this.user!.CreditCardDate = this.endDate.toJSDate();
    datepicker.close();
  }

}
