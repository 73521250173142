import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-add-model-device-page',
  templateUrl: './add-model-device-page.component.html',
  styleUrls: ['./add-model-device-page.component.scss']
})
export class AddModelDevicePageComponent implements OnInit {

  constructor(
    private breadcrumbService: BreadcrumbService,
    public authSrv: AuthenticationService,
    private translate: TranslateService) {
      breadcrumbService.setBreadCrumbUrl('devices/list', this.translate.instant('modules.devices.management'));
      breadcrumbService.addBreadCrumb('devices/models/add', this.translate.instant('modules.shared.new-register-model'));
    }

  ngOnInit(): void {
  }

}
