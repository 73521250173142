import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './modules/core/core.module';
import { SharedModule } from './modules/shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { MaterialCdkModule } from './modules/material-cdk/material-cdk.module';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { library } from '@fortawesome/fontawesome-svg-core'


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// Import from library
import { LottieModule } from 'ngx-lottie';
import { UploadFileModalComponent } from './modals/upload-file-modal/upload-file-modal.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { UserAddQuickComponent } from './modals/user-add-quick/user-add-quick.component';
import { DeviceModelAddQuickComponent } from './modals/device-model-add-quick/device-model-add-quick.component';
import { UsersModule } from './modules/users/users.module';
import { DevicesModule } from './modules/devices/devices.module';
import { DeviceAddQuickComponent } from './modals/device-add-quick/device-add-quick.component';
import { SearchUserModalComponent } from './modals/search-user-modal/search-user-modal.component';
import { SearchDeviceModelModalComponent } from './modals/search-device-model-modal/search-device-model-modal.component';
import { ChartsModule } from './modules/charts/charts.module';


import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { InputTextModalComponent } from './modals/input-text-modal/input-text-modal.component';
import { DeviceEditQuickComponent } from './modals/device-edit-quick/device-edit-quick.component';
import { DeviceModelEditQuickComponent } from './modals/device-model-edit-quick/device-model-edit-quick.component';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { UploadFileConfigModalComponent } from './modals/upload-file-config-modal/upload-file-config-modal.component'
import { InputDateModalComponent } from './modals/input-date-modal/input-date-modal.component';
import { FolderMediaAddQuickComponent } from './modals/folder-media-add-quick/folder-media-add-quick.component';
import { DocManagerModule } from './modules/doc-manager/doc-manager.module';
import { FileMediaAddQuickComponent } from './modals/file-media-add-quick/file-media-add-quick.component';



export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [
    AppComponent,
    UploadFileModalComponent,
    UserAddQuickComponent,
    DeviceModelAddQuickComponent,
    DeviceAddQuickComponent,
    SearchUserModalComponent,
    SearchDeviceModelModalComponent,
    InputTextModalComponent,
    InputDateModalComponent,
    DeviceEditQuickComponent,
    DeviceModelEditQuickComponent,
    UploadFileConfigModalComponent,
    FolderMediaAddQuickComponent,
    FileMediaAddQuickComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    HttpClientModule,
    LottieModule.forRoot({ player: playerFactory }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    NgMultiSelectDropDownModule.forRoot(),
    UsersModule,
    DocManagerModule,
    DevicesModule,
    ChartsModule,
    FontAwesomeModule,
    MatLuxonDateModule,
     


  ],
  exports: [
    TranslateModule,
    LottieModule,
    UsersModule,
    DevicesModule,
    ChartsModule,
    FontAwesomeModule,
    MatLuxonDateModule,
     
    
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {

  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);

  }


}
