import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { ApiRequestObject } from '../models/apiRequestObject';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private http: HttpClient, private authSrv: AuthenticationService) { }


  upload(info: FormData){
    return this.http.post<any>(`${environment.API_ENDPOINT}files/upload`, info, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
       catchError(this.error)
      );

  }
  uploadConfig(info: FormData){

    return this.http.post<any>(`${environment.API_ENDPOINT}files/config/upload`, info, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
       catchError(this.error)
      );
  }



  // Error handling
  error(error: HttpErrorResponse) {

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);

    let res : ApiRequestObject = ApiRequestObject.error(errorMessage);

    return throwError(() => res);
  }

}
