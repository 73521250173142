import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialCdkModule } from '../material-cdk/material-cdk.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from "angular-datatables";
import { ColorPickerModule } from 'ngx-color-picker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DetailChartComponent } from './components/detail-chart/detail-chart.component';
import { ChartsRoutingModule } from './charts-routing.module';
import { DetailChartPageComponent } from './pages/detail-chart-page/detail-chart-page.component';
import { NgChartsModule } from 'ng2-charts';
import { GenericChartComponent } from './components/generic-chart/generic-chart.component';
import { TimelineChartComponent } from './components/timeline-chart/timeline-chart.component';
import { WheelMouseDirective } from 'src/app/directives/wheelMouse';
import { NgApexchartsModule } from 'ng-apexcharts';
import { TimelineApexChartComponent } from './components/timeline-apex-chart/timeline-apex-chart.component';
import { ReportComponent } from './components/report/report.component';
import { ApexChartComponent } from './components/apex-chart/apex-chart.component';
import { ReportHorizontalComponent } from './components/report-horizontal/report-horizontal.component';
import { TimelineApexTestChartComponent } from './components/timeline-apex-test-chart/timeline-apex-test-chart.component';
import { TimelineApexV2ChartComponent } from './components/timeline-apex-v2-chart/timeline-apex-v2-chart.component';


@NgModule({
  declarations: [
    DetailChartComponent,
    DetailChartPageComponent,
    GenericChartComponent,
    TimelineChartComponent,
    TimelineApexChartComponent,
    WheelMouseDirective,
    ReportComponent,
    ApexChartComponent,
    ReportHorizontalComponent,
    TimelineApexTestChartComponent,
    TimelineApexV2ChartComponent    
  ],
  imports: [
    CommonModule,
    MaterialCdkModule,
    CoreModule,
    SharedModule,
    MaterialCdkModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsRoutingModule,
    DataTablesModule,
    ColorPickerModule,
    NgMultiSelectDropDownModule,
    NgChartsModule,
    NgApexchartsModule

  ],
  exports:[
    DetailChartComponent,
    GenericChartComponent,
    ApexChartComponent,
    TimelineApexChartComponent,
    MaterialCdkModule,
    NgMultiSelectDropDownModule,
    TimelineApexTestChartComponent,
    TimelineApexV2ChartComponent
  ]
})
export class ChartsModule { }
