import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserService } from '../services/user.service';

interface ErrorValidate {
  [s:string]: boolean
}



@Injectable({
  providedIn: 'root'
})
export class UserValidator {

  constructor(private userService: UserService){

  }

  checkMatchValidator( pass1Name: string, pass2Name: string ) {

    return ( formGroup: FormGroup ) => {

      const pass1Control = formGroup.controls[pass1Name];
      const pass2Control = formGroup.controls[pass2Name];

      if(pass1Control.value  == '' && pass2Control.value == '' ){
        pass2Control.setErrors(null);
      }

      if ( pass1Control.value === pass2Control.value ) {
        pass2Control.setErrors(null);
      } else {
        pass2Control.setErrors({ noEsIgual: true });
      }

    }

  }


  checkEmail(control: FormControl): Promise<ErrorValidate | null> | Observable<ErrorValidate> {

      return new Promise(resolve => {

        if(!control.value){
          resolve(null);
        }else{

          this.userService.checkAvailableEmail(control.value).subscribe(
            {
              next: res => {
              //console.log(res)
              if(res){
                //console.log(res)
                resolve(null);
              }else{
                //console.log(res)
                //resolve({emailInUse: false});
                resolve({'emailInUse': true});
              }
              },
              error: err => {
                resolve({'emailInUse': true});
            }
          });
          }
       });

  }

}
