<app-loading-custom [loading]="loadingDelete"></app-loading-custom>

<!--
<div *ngIf="showNewDevice" class="text-right-label" style="flex-direction: row-reverse">  <span class="c-pointer"  (click)="newDevice()" >{{'modules.devices.exist' | translate}}</span></div>
-->

<section *ngIf="showNewDevice" class="mt-2">
  <div class="text-right" style="flex-direction: row-reverse;">
      <button  mat-raised-button class="btn btn-primary"  (click)="newDevice()">
        {{'modules.devices.add-device' | translate}}
      </button>
  </div>
</section>

<div *ngIf="datatableConfigColumns && datatableConfigColumns.headerTable" class="m-3">

  <div class="d-none d-md-block p-absolute" *ngIf="selectedItems || selectedDevicesList.length > 0">

    <div class="btn btn-warning m-2" (click)="loadFirmaware()"  *ngIf="authSrv.isAdmin() == true">
      <i class="fa-solid fa-cloud-arrow-up"></i> Firm.
    </div>
    <div class="btn btn-primary m-2" (click)="loadConfiguration()"  *ngIf="authSrv.isAdmin() == true">
      <i class="fa-solid fa-cloud-arrow-up"></i> Config.
    </div>
  </div>

  <table #deviceList [id]="nameDatatable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
    class="display nowrap table row-border hover stripe table-striped table-sm" style="width: 100% !important">
    <thead>
      <tr>
        <th *ngFor="let headColumn of datatableConfigColumns.headerTable" [attr.class]="'column-' + headColumn.column + ' column-' + headColumn.tag" >
          <ng-container *ngIf="headColumn.name != '#select'">
            {{ headColumn.name}}
          </ng-container>
          <ng-container *ngIf="headColumn.name == '#select'">
            <div class="c-pointer-i">
              <label>
                <input type="checkbox" style="display: none;" (change)="selectUnselectAll()">
                <i  class="far fa-lg pl-3"
                    [ngClass]="selectedState === 1 ? 'fa-square' : (selectedState === 2 ? 'fa-check-square text-success' : 'fa-minus-square text-warning')"
                    style="color: #000">
                </i>
              </label>
            </div>
          </ng-container>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loading == true">
        <td [attr.colspan]="datatableConfigColumns!.headerTable!.length">{{'modules.shared.loading-info' | translate}}...</td>
      </tr>
      <tr *ngIf="loading == false && listaDispositivos.length == 0">
        <td [attr.colspan]="datatableConfigColumns!.headerTable!.length">{{'modules.shared.no-records' | translate}}.</td>
      </tr>
      <tr *ngFor="let device of listaDispositivos" (click)="checkuncheckall(device)">

        <td  class="text-center c-pointer">
          <i *ngIf="device.Selected == false" class="far fa-square fa-lg pl-3" style="color: #000"></i>
          <i *ngIf="device.Selected == true"  class="far fa-check-square text-success fa-lg pl-3"></i>
        </td>
        <td class="c-pointer" >
          <a [routerLink]="['/devices/' + device.Id + '/detail' ]">{{ device.IMEI }}</a>
        </td>
        <td class="c-pointer" *ngIf="authSrv.isAdmin() == true">
          <a [routerLink]="['/devices/' + device.Id + '/detail' ]">{{ device.Name }}</a>
        </td>
        <td class="c-pointer">
          <a [routerLink]="['/devices/models/' + device.IdModel + '/detail' ]">{{ device.NameDeviceModel }}</a>
        </td>
        <td *ngIf="authSrv.isAdmin() == true">
          {{ device.ICCID }}
        </td>

        <td *ngIf="authSrv.isAdmin() == true || authSrv.isManufacturer() == true">
          {{ device.NumSerieManufacturer }}
        </td>

        <td *ngIf="authSrv.isAdmin() == true  || authSrv.isManufacturer() == true">
          {{ device.Comments }}
        </td>
        <td *ngIf="authSrv.isAdmin() == true">
          {{ (device.FirmwareVersion)?device.FirmwareVersion!.split(" ")[0]:'' }}
        </td>

        <td *ngIf="isViewClient == false && (isClient == null || isClient == false)" class="isViewClient">
          <a [routerLink]="['/users/' + device.IdUser + '/detail' ]">{{ device.NameClient }}</a>
        </td>
        <td *ngIf="isViewManufacturer == false && (isManufacturer == null || isManufacturer == false)" class="isViewManufacturer">
          <a [routerLink]="['/users/' + device.IdManufacturer + '/detail' ]">{{ device.NameManufacturer }}</a>
        </td>
        <td>
          {{ device.CreatedAt | date: "dd-MM-yyyy HH:mm" }}
        </td>
        <td *ngIf="authSrv.isAdmin() == true || authSrv.isManufacturer() == true" class="c-pointer-i text-center">
          <!-- <i *ngIf="device.IsOnline == false" class="fa-solid fa-circle" style="color: red"></i>
          <i *ngIf="device.IsOnline == true  " class="fa-solid fa-circle" style="color: chartreuse"></i> -->
          
          <i *ngIf="device.onlineState == 0" class="fa-solid fa-circle" style="color: red"></i>
          <i *ngIf="device.onlineState == 1" class="fa-solid fa-circle" style="color: chartreuse"></i>
          <i *ngIf="device.onlineState == 2" class="fa-solid fa-circle" style="color: orange"></i>

          


        </td>
        <td class="c-pointer-i text-right actions">
          <a [routerLink]="['/chart/' + device.Id + '' ]"><i class="fa fa-solid fa-chart-line text-warning fa-lg pl-3"></i></a>
          <a *ngIf="authSrv.isAdmin()" [routerLink]="['/devices/' + device.Id + '/terminal' ]">
            <img class="fa-lg pl-3" src="/assets/img/terminal.png" /></a>
          <!-- <i *ngIf="isManufacturer == true || authSrv.isAdmin()" (click)="deleteItem(device)" class="fa-solid fa-trash text-danger fa-lg pl-3"></i> -->
          <i *ngIf="authSrv.isAdmin()" (click)="deleteItem(device)" class="fa-solid fa-trash text-danger fa-lg pl-3"></i>
          <a [routerLink]="['/devices/' + device.Id + '/detail' ]"><i class="fas fa-angle-double-right text-primary fa-lg pl-3"></i></a>


        </td>
      </tr>
    </tbody>
  </table>

  <section class="m-1 mt-2" *ngIf="selectedItems || selectedDevicesList.length > 0">
    <div class="btn btn-warning m-2" (click)="loadFirmaware()" >
      {{'modules.devices.load-firmware' | translate}}
    </div>
    <div class="btn btn-primary m-2" (click)="loadConfiguration()" >
      {{'modules.devices.load-config' | translate}}
    </div>
  </section>

  <app-map-list-devices [centralPoint]="[40.664022, -2.629330]" [devices]="listaDispositivos" ></app-map-list-devices>




</div>
