import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ExportChartsService } from 'src/app/services/export-charts.service';
import { GeneratingModalComponent } from 'src/app/modals/generating-modal/generating-modal.component';
import { Modal } from 'src/app/models/modal';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexYAxis,
  ApexFill,
  ApexMarkers,
  ApexStroke,
  ChartComponent
} from "ng-apexcharts";
import { environment } from '../../../../../environments/environment';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ThemeService } from 'src/app/services/theme.services';
import { Theme } from 'src/app/models/theme';
import { Notification } from 'src/app/models/notifications';
import { DateTime } from 'luxon';

import { sensors, sensorAccomulative } from 'src/app/const/sensor-const';
import { TranslateService } from '@ngx-translate/core';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  fill: ApexFill;
  stroke: ApexStroke;
  markers: ApexMarkers;
  colors: string[];
};

@Component({
  selector: 'app-report-horizontal',
  templateUrl: './report-horizontal.component.html',
  styleUrls: ['./report-horizontal.component.scss'],
  providers: [DatePipe],
})
export class ReportHorizontalComponent implements OnInit, AfterViewInit {


  @Input() notification?: Notification | null;
  @ViewChild("mainChartReport") chart?: ChartComponent;

  reportData: any = {};
  reportChart: any = {};
  date: Date | undefined;
  formatedDate: string | null = '';
  formatedHour: string | null = '';
  sensorsList: any = [];
  sensorsValues: any = [];
  max: any = [];
  min: any = [];
  med: any = [];

  currentTheme : Theme = new Theme();
  loading: boolean = true;


  constructor(
    private exportData: ExportChartsService,
    private exportChart: ExportChartsService,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    public authSrv: AuthenticationService,
    private themeSrv: ThemeService,
    private translate: TranslateService
  ) {

    if(this.themeSrv.currentPreviewTheme()){
      this.currentTheme = this.themeSrv.currentPreviewTheme()!;
    }else{
      this.currentTheme = this.themeSrv.currentTheme;
    }
  }

  get customLogo(){
    return environment.BASE_URL + "/" + this.currentTheme.HeadLogoColor.replace( environment.BASE_URL+"/", "");

  }
  ngOnDestroy(): void {
    this.exportChart.destroy();
  }

  ngOnInit(): void {
    this.exportData.currentData.subscribe(data => this.reportData = data);
    console.log('init');

    this.exportChart?.currentChart.subscribe(response => {
      console.log('init2', response);


      
        if (response) {
          this.loading = true;
          setTimeout(()=>{
            this.loading = false;
            console.log('init3');
            this.date = new Date();
            console.log('init4');
            this.reportChart = response;
            this.sensorsList = this.reportData.sensor.map((obj: { name: any; }) => obj.name);
            this.sensorsValues = this.reportData.sensor.map((obj: { data: any; }) => obj.data.map((val: any) => val.y));
            for (let index = 0; index < this.sensorsValues.length; index++) {
              this.max.push(Math.max(...this.sensorsValues[index]));
              this.min.push(Math.min(...this.sensorsValues[index]));
              this.med.push(Math.round(this.sensorsValues[index].reduce((a: any,b: any) => Number(a) + Number(b), 0) / this.sensorsValues[index].length).toFixed(2));
            }

            console.log("this.loading" , this.notification);

            if (this.reportData.download === true) {
              this.addNotification();
              this.reportData.download = false;
              this.openPDF();
              this.openGeneratingPDF();
            }

            this.loading = false;
          },100);
        }
        
    });



  }

  ngAfterViewInit() {

  }


  addNotification(){

    console.log('addNotification', this.notification);

    if(this.notification && this.notification?.Value){
      const that = this;



        console.log('value' , this.notification!.Value);

        let datePoint = DateTime.fromJSDate(new Date(this.notification!.TrackPosition!.Date!));
        console.log('datePoint' , datePoint);

        this.reportChart.annotations = {
          position: 'front',
          points: [{
            x: datePoint.valueOf(),
            y: +(this.notification!.Value!.replace(",",".")),
            borderColor: "#FEB019",
                label: {
                  borderColor: "#FEB019",
                  style: {
                    color: "#fff",
                    background: "#FEB019"
                  },
                  orientation: "horizontal",
                  text: "Alerta"
                }
          }
          ]
        };

        /*

        if(that.chart){

          that.chart.addPointAnnotation({
            x: datePoint.valueOf(),
            y: +(this.notification!.Value!.replace(",",".")),
            borderColor: "#FEB019",
                label: {
                  borderColor: "#FEB019",
                  style: {
                    color: "#fff",
                    background: "#FEB019"
                  },
                  orientation: "horizontal",
                  text: "Alerta"
                }
          });
        }*/


    }
  }

  public openGeneratingPDF(): void{
    let modal = new Modal();
    modal.Title = this.translate.instant('modules.modals.generating-report');
    modal.Body = this.translate.instant('modules.modals.generating-report-wait') + "...";
    modal.ClassType = "warning";
    modal.BtnAccept = this.translate.instant('modules.modals.continue');

    this.dialog.open(
      GeneratingModalComponent,
      {
        autoFocus : 'dialog',
        width: "400px",
        data: modal,
        closeOnNavigation: false,
        disableClose: true,
        panelClass: 'custom-modalbox',
        position: { top: '150px'}
      }
    );
    window.setTimeout(() => {
      this.dialog.closeAll();
    }, 5000)
  }

  public openPDF(): void {
    window.setTimeout(() => {

      let DATA: any = document.getElementById('htmlData');
      html2canvas(DATA, { scale: 1 }).then((canvas) => {
        let fileHeight =208;
        let fileWidth = (canvas.width * fileHeight) / canvas.height;
        //let fileWidth = 208;
        //let fileHeight = (canvas.height * fileWidth) / canvas.width;
        const FILEURI = canvas.toDataURL('image/jpeg');                       //Utilitzo jpg per reduir el tamany
        let PDF = new jsPDF('l', 'mm', 'a4');                                 //horizontal cambiar el parametro 'p' por una 'l'
        let position = 0;
        PDF.addImage(FILEURI, 'JPEG', 0, position, fileWidth, fileHeight,'MEDIUM');
        PDF.save(this.reportData.nombre + this.datepipe.transform(this.date, "_HH-mm'h_'dd-MM-yyyy") + '.pdf');
      });
    }, 100)

  }


  public containInAcc( s: string): boolean {
    let ret = false;
    sensorAccomulative.forEach( (e) => {

      if( e.key == s){
        ret = true;
      }

    });

    return ret;

  }

  

  public calcularHorasYMinutos(numero: number) {
    // Obtén las horas enteras (parte entera del número)
    const horas = Math.floor(numero);
  
    // Obtén los minutos a partir de la parte decimal del número
    const minutosDecimal = numero - horas;
    const minutos = Math.round(minutosDecimal * 60); // Convierte el decimal a minutos
  
    return { horas: horas, minutos: minutos };
  }

}

