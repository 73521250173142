import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-map-device',
  templateUrl: './map-device.component.html',
  styleUrls: ['./map-device.component.scss']
})
export class MapDeviceComponent implements OnInit {

  @Input() lat?: number | null;
  @Input() lng?: number | null;
  constructor() { }

  ngOnInit(): void {
  }

  get latitude(): number{
    return this.lat??41.619042;
  }
  get longitude(): number{
    return this.lng??0.618856;
  }
}
