
<app-breadcrumb></app-breadcrumb>

<div class="container-fluid pt-3 pl-0 pr-0 espace">



  <app-block-group-dashboard>

    <app-block-dashboard>

      <app-add-alarm [information]="information" [manufacturerList]="manufacturerList">

      </app-add-alarm>

    </app-block-dashboard>

  </app-block-group-dashboard>

</div>

<app-footer></app-footer>
