import { Component, Input, OnInit } from '@angular/core';
import { Information } from 'src/app/models/device';
import { Sensor } from 'src/app/models/sensor';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-sensor-informations-detail-device',
  templateUrl: './sensor-informations-detail-device.component.html',
  styleUrls: ['./sensor-informations-detail-device.component.scss']
})
export class SensorInformationsDetailDeviceComponent implements OnInit {

  @Input() sensors : Information | null = null;
  loading: boolean = true;
  nameSensors: string[] = [];
  nameSensorsTranslations: string[] = [];
  permitSensors: string[] = [];
  valueSensors: number[] = [0,1,2,3,4];
  mapSensor: Sensor[] = [];
  math = Math;


  constructor(utilSrv: UtilsService, public authSrv: AuthenticationService) {
    this.nameSensors = utilSrv.getNameSensors();
    this.nameSensorsTranslations = utilSrv.getNameSensorsTranslations();
    this.permitSensors = utilSrv.getPermitNameSensors();
  }


  ngOnInit(): void {
    //let index = this.nameSensors[0];


    //let keys =[ ...this.sensors!.keys() ];

    if(this.sensors){

      let arrayAttr = Object.getOwnPropertyNames(this.sensors!);
      console.log("arrayName", arrayAttr);

      for (let i = 0; i < this.permitSensors.length; i++) {
          let a: keyof Information = this.permitSensors[i];
          let c = this.sensors![a];
          let b: string = this.permitSensors[i];
          console.log(a, c, b);
          let sensor : Sensor;
          sensor= new Sensor({ name: b, value: c});

          if(this.permitSensors.includes(b)){
            sensor.name = this.nameSensors[i];
            sensor.translation = this.nameSensorsTranslations[i];
            this.mapSensor.push( sensor);
          }
          /*
          this.mapSensor = [
            ...this.mapSensor,
            { b : c }
          ]*/

    }
  }

    console.log("mapSensor", this.mapSensor);
    this.loading = false;

  }

}
