
<app-loading-custom [loading]="loading"></app-loading-custom>

<mat-tab-group mat-align-tabs="start" [selectedIndex]="page" (selectedIndexChange)="page = $event" *ngIf="loading == false">

  <mat-tab *ngIf="authSrv.isClient() == true">
      <ng-template mat-tab-label>
          {{'modules.users.info' | translate}}
          <mat-icon class="tab-icon  error-1 errorSpecial" [hidden]="editClientFormSharedValid">error</mat-icon>
      </ng-template>
      <app-edit-client-user *ngIf="user" [user]="user"  (editClientFormShared)="updateEditClientFormShared($event)"></app-edit-client-user>
  </mat-tab>

  <mat-tab *ngIf="authSrv.isAdmin() == true || isManufacturerOfClient">
      <ng-template mat-tab-label>
        {{'modules.shared.general' | translate}}
          <mat-icon class="tab-icon error-0 errorEdit" [hidden]="editFormSharedValid">error</mat-icon>
      </ng-template>
      <app-edit-user *ngIf="user" [user]="user" [page]="page" (editFormShared)="updateEditFormShared($event)"></app-edit-user>
  </mat-tab>
  <mat-tab>
      <ng-template mat-tab-label>
        {{'modules.tables.contact' | translate}}
          <mat-icon class="tab-icon  error-1 errorContact" [hidden]="contactFormSharedValid">error</mat-icon>
      </ng-template>
      <app-edit-user-contact *ngIf="user" [user]="user"  (contactFormShared)="updateContactFormShared($event)"></app-edit-user-contact>
  </mat-tab>
  <mat-tab >
      <ng-template mat-tab-label>
          {{'modules.tables.address' | translate}}
          <mat-icon class="tab-icon  error-1 errorAddress" [hidden]="addressFormSharedValid">error</mat-icon>
      </ng-template>
      <app-edit-user-address *ngIf="user" [user]="user"  (addressFormShared)="updateAddressFormShared($event)"></app-edit-user-address>
  </mat-tab>

  <mat-tab>
      <ng-template mat-tab-label>
          {{'modules.tables.bank-data' | translate}}
          <mat-icon class="tab-icon  error-1 errorSpecial" [hidden]="specialFormSharedValid">error</mat-icon>
      </ng-template>
      <app-edit-user-bank-information *ngIf="user" [user]="user"  (bankInfoFormShared)="updateBankInformationFormShared($event)"></app-edit-user-bank-information>
  </mat-tab>

  <mat-tab *ngIf="(authSrv.isAdmin() == true || (authSrv.isManufacturer() && authSrv.currentUserValue?.UserId == user!.Id ))  && user!.Role!.Tag! != 'admin'">
      <ng-template mat-tab-label>
          {{'modules.tables.theme' | translate}}
          <mat-icon class="tab-icon  error-1 errorTheme" [hidden]="themeFormSharedValid">error</mat-icon>
      </ng-template>
      <app-edit-user-theme *ngIf="user" [user]="user"  (themeFormShared)="updateThemeFormShared($event)"></app-edit-user-theme>
  </mat-tab>

  <mat-tab *ngIf="authSrv.isAdmin() == true || authSrv.isManufacturer() == true">
      <ng-template mat-tab-label>
          {{'modules.tables.more' | translate}}
          <mat-icon class="tab-icon  error-1 errorSpecial" [hidden]="specialFormSharedValid">error</mat-icon>
      </ng-template>
      <app-edit-user-special *ngIf="user" [user]="user"  (specialFormShared)="updateSpecialFormShared($event)"></app-edit-user-special>
  </mat-tab>

  <mat-tab *ngIf="authSrv.isAdmin() == true && user!.Role!.Tag! != 'admin'">
      <ng-template mat-tab-label>
          {{'modules.devices.devices' | translate}}
      </ng-template>
      <app-edit-user-devices *ngIf="user" [user]="user" (devicesFormShared)="updateDevicesFormShared($event)"></app-edit-user-devices>
  </mat-tab>

  <mat-tab *ngIf="authSrv.isAdmin() == true && user && user!.Role && user!.Role!.Tag! == 'fabricant'">
      <ng-template mat-tab-label>
          {{'modules.devices.models' | translate}}
      </ng-template>
      <app-edit-user-device-models *ngIf="user" [user]="user"></app-edit-user-device-models>
  </mat-tab>

  <mat-tab *ngIf="authSrv.isAdmin() == true && user && user!.Role && user!.Role!.Tag! == 'user'">
      <ng-template mat-tab-label>
        {{'modules.tables.num-manufacturers' | translate}}
      </ng-template>
      <app-edit-user-manufacturers *ngIf="user" [user]="user" (manufacturersFormShared)="updateManufacturerFormShared($event)"></app-edit-user-manufacturers>

  </mat-tab>
  <mat-tab *ngIf="authSrv.isAdmin() == true && user && user!.Role && user!.Role!.Tag! == 'fabricant'">
      <ng-template mat-tab-label>
        {{'modules.users.users' | translate}}
      </ng-template>
      <app-edit-user-clients *ngIf="user" [user]="user" (clientsFormShared)="updateClientsFormShared($event)"></app-edit-user-clients>
  </mat-tab>

  <mat-tab *ngIf="authSrv.currentUserValue?.UserId == user!.Id">
    <ng-template mat-tab-label>
        {{'modules.users.change-password' | translate}}
    </ng-template>

    <app-edit-user-password (passwordFormShared)="updatePasswordFormShared($event)"></app-edit-user-password>

  </mat-tab>
</mat-tab-group>


<section class="mt-2">
  <div class="text-right" style="flex-direction: row-reverse;">
      <button  mat-raised-button class="btn btn-primary" (click)="onSubmit()">{{'modules.shared.save' | translate}}</button>
  </div>
</section>
