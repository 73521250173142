<app-loading-custom [loading]="loading"></app-loading-custom>

<!-- <app-range-slider-date></app-range-slider-date> -->
<ng-container *ngIf="!loading">

    <div class="row mb-3">
        <div class="col-12">
            
    <form  [formGroup]="splitTripForm"  autocomplete="off" >
        
        <div  class="mr-2 pl-3 d-flex">
            <!-- <mat-form-field  class="form-field" appearance="fill">
                <mat-label>{{'modules.trips.start-date' | translate}}</mat-label>
                <input matInput type="datetime-local" placeholder="start date" formControlName="StartDate" [value]="StartDateValue" readonly>
            </mat-form-field> -->
            <div class="d-flex p-3" style="align-items: center;">
                <strong style="display: contents !important;">{{'modules.trips.first-record' | translate}}:</strong> <br> {{ StartDateValue! | date: "dd-MM-yyyy HH:mm:ss"  }} 
            </div>
            <!-- <div class="form-group registration-date">
                <label class="control-label col-sm-3" for="registration-date"> </label>
            	<div class="input-group registration-date-time">
            		<span class="input-group-addon" id="basic-addon1"><span class="glyphicon glyphicon-calendar" aria-hidden="true"></span></span>
            		<input class="form-control" name="registration_date" id="registration-date" type="date" formControlName="SplitDate"  >
            		<span class="input-group-addon" id="basic-addon1"><span class="glyphicon glyphicon-time" aria-hidden="true"></span></span>
            		<input class="form-control" name="registration_time" id="registration-time" type="time">
            	
            	</div>
            </div> -->
            <!-- 
            <mat-form-field  class="form-field ml-3" style="align-items: center;" appearance="fill">
                <mat-label>{{'modules.trips.split-date' | translate}}</mat-label>
                <input matInput (change)="changeSplitDate($event)" type="datetime-local" placeholder="start date" formControlName="SplitDate" >
            </mat-form-field>
        -->
            <div  class="d-flex p-3" style="align-items: center;">
                <strong style="display: contents !important;">{{'modules.trips.last-record' | translate}}:</strong><br>{{ FinishDateValue! | date: "dd-MM-yyyy HH:mm:ss"  }} 
            </div>
            <div  class="d-flex p-3" style="align-items: center;">
                <strong style="display: contents !important;">{{'modules.trips.split-date' | translate}}:</strong><br>{{ splitDateForm! | date: "dd-MM-yyyy HH:mm:ss"  }} 
            </div>
            <!-- 
            <mat-form-field  class="form-field ml-3" appearance="fill">
                <mat-label>{{'modules.trips.end-date' | translate}}</mat-label>
                <input matInput type="datetime-local" placeholder="start date" formControlName="FinishDate" [value]="FinishDateValue" readonly>
            </mat-form-field>-->
            <div style="width: 10px;"></div>
            <div class="btn btn-primary" style="height: fit-content; margin-top: 20px;" (click)="resetPositions()">{{'modules.trips.reset-points' | translate}}</div>

            <div style="width: 10px;"></div>
            <div class="btn btn-primary" style="height: fit-content; margin-top: 20px;" (click)="submit()">{{'modules.trips.split' | translate}}</div>


        </div>

        <!-- 
        {{ percentValue }} - 
        {{ Percent!.value }} 
        <ng-container *ngIf="splitDate">

            ## {{ splitDate.toJSDate() | date: "dd-MM-yyyy HH:mm:ss"  }}



           
        </ng-container>
        ## {{  this.trip!.DeviceTripTrackInformations![20].TrackInformation!.TrackPosition!.Date! | date: "dd-MM-yyyy HH:mm:ss"  }}-->

    </form>
        </div>
        <!-- 
        <div class="col-12">
            ## {{ StartDateValue! | date: "dd-MM-yyyy HH:mm:ss"  }} 
            <ng-container *ngIf="splitDate">
                ## {{ splitDate.toJSDate() | date: "dd-MM-yyyy HH:mm:ss"  }}
            </ng-container>
            ## {{ FinishDateValue! | date: "dd-MM-yyyy HH:mm:ss"  }}
            ##
        </div>-->
    </div>
    <div class="row">
        <div class="col-6 ">

            <!-- <div >
                Total: {{ split1.length }}
                <div *ngFor="let position of split1; let i = index;">
                    ID: {{ position.Id }} - {{ position.TrackInformation!.TrackPosition!.Date! | date: "dd-MM-yyyy HH:mm:ss" }}
                </div>

            </div> -->

            <agm-map *ngIf="centralPoint && centralPoint[0] != 0 && centralPoint[1] != 0"
                [mapTypeId]="'satellite'" 
                [latitude]="centralPoint[0]"  [longitude]="centralPoint[1]" [zoom]="zoom">

                
                
                <ng-container *ngFor="let position of split1; let i = index;" >
                    <agm-marker [agmFitBounds]="true" *ngIf="i == 0"  [latitude]="position.TrackInformation!.TrackPosition!.LatitudeWGS84!" [longitude]="position.TrackInformation!.TrackPosition!.LongitudeWGS84!" [iconUrl]="'assets/img/maps/location-pin-online.png'"    >
                    </agm-marker>
        
                    <agm-marker [agmFitBounds]="true" *ngIf="i == split1!.length - 1"  [latitude]="position.TrackInformation!.TrackPosition!.LatitudeWGS84!" [longitude]="position.TrackInformation!.TrackPosition!.LongitudeWGS84!" [iconUrl]="'assets/img/maps/location-pin.png'"  >
                    </agm-marker>
                    
                    <agm-circle  [latitude]="position.TrackInformation!.TrackPosition!.LatitudeWGS84!" [longitude]="position.TrackInformation!.TrackPosition!.LongitudeWGS84!" 
                        (circleClick)="clickPoint(position)"
                        (mouseOver)="overPoint(position)" (mouseOut)="outPoint(position)"
                        [radius]="3"
                        [strokeColor]="(currentPoint!=undefined && currentPoint.Id == position.Id)?'white':'#07b57a'"
                        [fillColor]="(currentPoint!=undefined && currentPoint.Id == position.Id)?'#ff0000':'white'"
                        [fillOpacity]="1"
                        [strokeWeight]="1"
                        [circleDraggable]="false"
                        [editable]="false">
                    </agm-circle>
                </ng-container>

                <agm-polyline [strokeWeight]="3" [strokeColor]="'#07b57a'">
                    <ng-container *ngFor="let position of split1; let i = index;" >

            
                        


                        <agm-polyline-point [latitude]="position.TrackInformation!.TrackPosition!.LatitudeWGS84!" [longitude]="position.TrackInformation!.TrackPosition!.LongitudeWGS84!">
                        </agm-polyline-point>
                    </ng-container>

                    <!-- 
                    <agm-icon-sequence [strokeColor]="'#00d21d'" [repeat]="'1px'" [offset]="'0'" [strokeWeight]="3"  [scale]="2" [strokeOpacity]="100"  [path]="'M 0,-1 0,1'">
                    </agm-icon-sequence>-->
                </agm-polyline>

              
                

            </agm-map>

            {{ split1.length }} {{'modules.tables.records' | translate}}.
        </div>
        
        <div class="col-6">

            <!-- <div >
                Total: {{ split2.length }}
                <div *ngFor="let position of split2; let i = index;">
                    ID: {{ position.Id }} - {{ position.TrackInformation!.TrackPosition!.Date! | date: "dd-MM-yyyy HH:mm" }}
                </div>

            </div> -->
            <agm-map *ngIf="centralPoint && centralPoint[0] != 0 && centralPoint[1] != 0"
                [mapTypeId]="'satellite'"
                [latitude]="centralPoint[0]"  [longitude]="centralPoint[1]" [zoom]="zoom">

                <agm-polyline [strokeWeight]="3" [strokeColor]="'#07b57a'">
                    <ng-container *ngFor="let position of split2; let i = index;" >

                        <agm-polyline-point [latitude]="position.TrackInformation!.TrackPosition!.LatitudeWGS84!" [longitude]="position.TrackInformation!.TrackPosition!.LongitudeWGS84!">
                        </agm-polyline-point>
            
                        <agm-marker [agmFitBounds]="true" *ngIf="i == 0"  [latitude]="position.TrackInformation!.TrackPosition!.LatitudeWGS84!" [longitude]="position.TrackInformation!.TrackPosition!.LongitudeWGS84!" [iconUrl]="'assets/img/maps/location-pin.png'"  >
                        </agm-marker>
            
                        <agm-marker [agmFitBounds]="true" *ngIf="i == split2!.length - 1"  [latitude]="position.TrackInformation!.TrackPosition!.LatitudeWGS84!" [longitude]="position.TrackInformation!.TrackPosition!.LongitudeWGS84!" [iconUrl]="'assets/img/maps/location-pin-online.png'"  >
                        </agm-marker>
                        
                        <agm-circle  [latitude]="position.TrackInformation!.TrackPosition!.LatitudeWGS84!" [longitude]="position.TrackInformation!.TrackPosition!.LongitudeWGS84!" 
                            [radius]="1"
                            [strokeColor]="'#07b57a'"
                            [fillColor]="'white'"
                            [fillOpacity]="1"
                            [strokeWeight]="1"
                            [circleDraggable]="false"
                            [editable]="false">
                        </agm-circle>


                    </ng-container>

                    <!-- 
                    <agm-icon-sequence [strokeColor]="'#00d21d'" [repeat]="'1px'" [offset]="'0'" [strokeWeight]="3"  [scale]="2" [strokeOpacity]="100"  [path]="'M 0,-1 0,1'">
                    </agm-icon-sequence>-->
                </agm-polyline>

             


            </agm-map>
            {{ split2.length }} {{'modules.tables.records' | translate}}.

        </div>

    </div>

    <!-- 
    <div class="row">
        <div class="col-6">

            <div >
                Total: {{ trip!.DeviceTripTrackInformations!.length }}
                <div *ngFor="let position of trip!.DeviceTripTrackInformations!; let i = index;">
                    ID: {{ position.Id }} - {{ position.TrackInformation!.TrackPosition!.Date! | date: "dd-MM-yyyy HH:mm:ss" }}
                </div>

            </div>
        </div>
    </div>-->
</ng-container>