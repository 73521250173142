import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { ApiRequestObject, ListResult } from '../models/apiRequestObject';
import { User } from '../models/user';
import { Device, DeviceModel, Information, vDevice, vDeviceModel } from '../models/device';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class DevicesService extends ApiService{

  constructor(private http: HttpClient, router: Router, authSrv: AuthenticationService) {
    super(router, authSrv);
  }

  getDeviceById(id: number) {
    return this.http.get<Device>(`${environment.API_ENDPOINT}device/${id}`).pipe(
       catchError(this.error)
    );
  }

  getModelById(id: number){
    return this.http.get<DeviceModel>(`${environment.API_ENDPOINT}device/model/${id}`).pipe(
      catchError(this.error)
   );
  }

  getAllModels(){
    return this.http.get<ListResult>(`${environment.API_ENDPOINT}devices/models`).pipe(
      catchError(this.error)
   );
  }

  getList(page: number, offset: number, order: string, direction: string,  filter?: string ) : Observable<ListResult> {

    return this.http.get<ListResult>(`${environment.API_ENDPOINT}devices/list/${page}/${offset}/${order}/${direction}?filter=${filter}`).pipe(
       catchError(this.error)
    );
  }

  getLiteList(page: number, offset: number, order: string, direction: string,  filter?: string, user?:User, model?:DeviceModel ) : Observable<ListResult> {
    if(filter == undefined){
      filter = "";
    }
    let userFilter=""
    if(user){
      userFilter="&UserId="+user.Id
    }
    let modelFilter=""
    if(model){
      modelFilter="&ModelDeviceId="+model.Id
    }

    return this.http.get<ListResult>(`${environment.API_ENDPOINT}devices/liteList/${page}/${offset}/${order}/${direction}?filter=${filter}${userFilter}${modelFilter}`).pipe(
       catchError(this.error)
    );
  }


  getModelLiteList(page: number, offset: number, order: string, direction: string,  filter?: string, user?:User ) : Observable<ListResult> {

    let userFilter=""
    if(user){
      userFilter="&UserId="+user.Id
    }
    filter = filter??"";

    return this.http.get<ListResult>(`${environment.API_ENDPOINT}devices/models/liteList/${page}/${offset}/${order}/${direction}?filter=${filter}${userFilter}`).pipe(
       catchError(this.error)
    );
  }

  getDevicesWithoutManufacturer() : Observable<ListResult> {
    let page = 0;
    let offset = 1000;
    let order = "IMEI";
    let direction = "ASC";
    let withManufacturer = false;

    return this.http.get<ListResult>(`${environment.API_ENDPOINT}devices/liteList/${page}/${offset}/${order}/${direction}?withManufacturer=${withManufacturer}`).pipe(
       catchError(this.error)
    );

  }

  getDevicesWithoutUser() : Observable<ListResult> {
    let page = 0;
    let offset = 1000;
    let order = "IMEI";
    let direction = "ASC";
    let withUser = false;

    return this.http.get<ListResult>(`${environment.API_ENDPOINT}devices/liteList/${page}/${offset}/${order}/${direction}?withUser=${withUser}`).pipe(
       catchError(this.error)
    );
  }

  create(device: Device) : Observable<Device> {
    device.Client = null;
    device.DeviceModel = null;
    return this.http.post<Device>(`${environment.API_ENDPOINT}device`, device).pipe(
       catchError(this.error)
    );
  }

  change(device: Device) : Observable<Device> {
    device.Client = null;
    device.DeviceModel = null;
    return this.http.patch<Device>(`${environment.API_ENDPOINT}device/${device.Id}`, device).pipe(
       catchError(this.error)
    );
  }
  changeModel(deviceModel: DeviceModel) : Observable<DeviceModel> {

    return this.http.patch<DeviceModel>(`${environment.API_ENDPOINT}device/model/${deviceModel.Id}`, deviceModel).pipe(
       catchError(this.error)
    );
  }

  createModel(model: vDeviceModel) : Observable<vDeviceModel> {
    model.Manufacturer = null;
    model.NameManufacturer = "";
    return this.http.post<vDeviceModel>(`${environment.API_ENDPOINT}device/model`, model).pipe(
       catchError(this.error)
    );
  }


  delete(device: vDevice ) : Observable<boolean> {
    return this.http.delete<boolean>(`${environment.API_ENDPOINT}device/${device.Id}`).pipe(
       catchError(this.error)
    );
  }

  deleteModel(model: vDeviceModel ) : Observable<boolean> {
    return this.http.delete<boolean>(`${environment.API_ENDPOINT}device/model/${model.Id}`).pipe(
       catchError(this.error)
    );
  }



  getTrackInformations(id: number, dateFrom?: string, dateTo?: string, notification?: number) : Observable<Array<Information>> {

    return this.http.get<Array<Information>>(`${environment.API_ENDPOINT}devices/${id}/trackInformations?DateFrom=${dateFrom}&DateTo=${dateTo}&IdNotification=${notification}`).pipe(
      catchError(this.error)
    );
  }


  traceSimulate(trace: Information) : Observable<boolean> {

    return this.http.post<boolean>(`${environment.API_ENDPOINT}device/trace/simulate`, trace).pipe(
      catchError(this.error)
    );
  }

  searchInfoByImei(imei: string, start: DateTime, finish: DateTime) : Observable<number> {

    return this.http.get<number>(`${environment.API_ENDPOINT}device/IMEI/${imei}?StartDate=${start}&FinishDate=${finish}`).pipe(
       catchError(this.error)
    );
  }

  cleanByImei(imei: string, start: any ) : Observable<boolean> {
    console.log('cleanByImei' , start);
    let url = `${environment.API_ENDPOINT}device/IMEI/${imei}/clean`;
    if(start){
      url += `?StartDate=${start}`;
    }
    return this.http.get<boolean>(url).pipe(
       catchError(this.error)
    );
  }

  migrateByImei(imei: string, imei2: string ) : Observable<boolean> {
    let url = `${environment.API_ENDPOINT}device/IMEI/${imei}/migrate/${imei2}`;
 
    return this.http.get<boolean>(url).pipe(
       catchError(this.error)
    );
  }

}
