<app-breadcrumb></app-breadcrumb>


<div class="container-fluid pt-3 pl-0 pr-0 espace">

    <app-loading-custom [loading]="loading"></app-loading-custom>

    <app-block-group-dashboard *ngIf="loading == false">

        <app-detail-device-model *ngIf="model" [pageInput]="page" [model]="model"></app-detail-device-model>

    </app-block-group-dashboard>

</div>


<app-footer></app-footer>
