import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LuxonDateAdapter, MAT_LUXON_DATE_FORMATS, MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { TranslateService } from '@ngx-translate/core';
import { DateTime, Settings } from 'luxon';
import * as moment from 'moment';
import { ConfirmationModalComponent } from 'src/app/modals/confirmation-modal/confirmation-modal.component';
import { Modal } from 'src/app/models/modal';
import { Trip } from 'src/app/models/trip';
import { CustomLuxonDateAdapter } from 'src/app/modules/charts/components/timeline-apex-chart/customDateAdapter';
import { DevicesService } from 'src/app/services/devices.service';
import { TripsService } from 'src/app/services/trips.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-new-trip',
  templateUrl: './new-trip.component.html',
  styleUrls: ['./new-trip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: DateAdapter,
      useClass: CustomLuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS},
  ]
})
export class NewTripComponent implements OnInit {

  newTripForm: FormGroup = this.formBuilder.group({
  });
  StartDateValue: DateTime | null= null;
  FinishDateValue: DateTime  | null = null;
  StartDateValueMoment: string | null= null;
  FinishDateValueMoment: string | null= null;
  IMEIValue: string = "";
  loadingCreate: boolean = false;
  
  constructor(
    private tripSrv: TripsService,
    private deviceSrv: DevicesService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router : Router,
    private utilsSrv : UtilsService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private dateTimeAdapter: DateTimeAdapter<any>,
  ) { 
      Settings.defaultZone = "europe/madrid";
      Settings.defaultLocale= "es";
      this.dateTimeAdapter.setLocale(localStorage.getItem('lang')!);
  }

  ngOnInit(): void {
    this.newTripForm = this.formBuilder.group({
      StartDate: new FormControl(this.StartDateValue, [
        Validators.required
      ]),
      FinishDate: new FormControl(this.FinishDateValue, [
        Validators.required
      ]),
      StartDateMoment: new FormControl(this.StartDateValueMoment, [
        Validators.required
      ]),
      FinishDateMoment: new FormControl(this.FinishDateValueMoment, [
        Validators.required
      ]),
      IMEI: new FormControl(this.IMEIValue, [
        Validators.required
      ])
    });
  }

  get StartDate(){ return this.newTripForm.get("StartDate"); }
  get FinishDate(){ return this.newTripForm.get("FinishDate"); }
  get IMEI(){ return this.newTripForm.get("IMEI"); }

  get isValidForm(): boolean{
    if(this.newTripForm){
      return this.newTripForm.get('StartDate')!.valid && this.newTripForm.get('FinishDate')!.valid && this.newTripForm.get('IMEI')!.valid;
    }
    return false;
  }

  // Se inserta directamente la fecha del moment (traducible) a los inputs DateTime (no traducibles)
  change(inputId: string) {
    let dateToInsert;
    let inputDest;

    dateToInsert = moment(inputId == 'dtStart' ? this.StartDateValueMoment : this.FinishDateValueMoment).format('yyyy-MM-DDTHH:mm').toString();

    inputDest = (<HTMLInputElement>document.getElementById(inputId));
    inputDest.value = dateToInsert;
    inputDest.dispatchEvent(new Event("input", { bubbles: true }));

      // Marcar campos como validos
      let startDateField = (<HTMLInputElement>document.getElementById('StartDateMoment'));
      if (this.newTripForm.get('StartDate')!.valid) {
        startDateField.classList.remove('invalid');
      }
      let finishDateField = (<HTMLInputElement>document.getElementById('FinishDateMoment'));
      if (this.newTripForm.get('FinishDate')!.valid) {
        finishDateField.classList.remove('invalid');
      }
  }

  submit(analyze: boolean){
    this.StartDateValue = this.newTripForm.get("StartDate")?.value;
    this.IMEIValue = this.newTripForm.get("IMEI")?.value;
    this.FinishDateValue = this.newTripForm.get("FinishDate")?.value;

    console.log('values' , this.StartDateValue, this.FinishDateValue , this.IMEIValue);

    if(!this.isValidForm){

      // Marcar campos como no validos
      let startDateField = (<HTMLInputElement>document.getElementById('StartDateMoment'));
      if (!this.newTripForm.get('StartDate')!.valid) {
        startDateField.classList.add('invalid');
        startDateField.getElementsByTagName('input')[0].value = '';
      } else {
        startDateField.classList.remove('invalid');
      }
      let finishDateField = (<HTMLInputElement>document.getElementById('FinishDateMoment'));
      if (!this.newTripForm.get('FinishDate')!.valid) {
        finishDateField.classList.add('invalid');        
        finishDateField.getElementsByTagName('input')[0].value = '';
      } else {
        finishDateField.classList.remove('invalid');
      }

      console.log(this.newTripForm.errors);
      this.utilsSrv.infoModal(this.translate.instant('modules.modals.missing-field') + '.',undefined);
      return;
    }

    if(this.StartDateValue! > this.FinishDateValue!){
      console.log('Error fechas');
      this.utilsSrv.infoModal(this.translate.instant('modules.modals.start-end-dates-error') + '.',undefined);
      return;
    }


    this.deviceSrv.searchInfoByImei(this.IMEIValue, this.StartDateValue!, this.FinishDateValue!).subscribe(
      {
        next: (res: number) => {


          if(res == 0){
            this.utilsSrv.infoModal(this.translate.instant('modules.modals.no-records-found-for-trip') + '.',undefined);

          }else{

            let modal = new Modal();
            modal.Title = this.translate.instant('modules.shared.warning');
            modal.Body = (analyze == true)? this.translate.instant('modules.modals.found') + " " + res + " " + this.translate.instant('modules.tables.records') + ". " + this.translate.instant('modules.modals.want-to-analyze') : this.translate.instant('modules.modals.found') + " " + res + " " + this.translate.instant('modules.tables.records') + ". " + this.translate.instant('modules.modals.want-to-create-trip');
            modal.BtnAccept = (analyze == true)? this.translate.instant('modules.trips.analyze') : this.translate.instant('modules.shared.create')
            modal.BtnCancel = this.translate.instant('modules.shared.cancel');
            modal.ClassType = "danger";

            const dialogRef = this.dialog.open(
              ConfirmationModalComponent,
              {
                autoFocus : 'dialog',
                width: "auto",
                minWidth: '600px',
                data: modal,
                closeOnNavigation: true,
                disableClose: false,
                panelClass: 'custom-modalbox'

            });


                    
            dialogRef.afterClosed().subscribe( (result?: any) => {
              if(result){
                console.log("CREAR");


                this.loadingCreate = true;



                if(analyze == true){
                  this.tripSrv.analyze(this.IMEIValue, this.StartDateValue!, this.FinishDateValue!).subscribe({
                    next: (res: Trip) => {

                      this.utilsSrv.infoModal(this.translate.instant('modules.modals.analysis-sent') + '.',undefined);

                    },
                    error: (err) =>{
                      this.loadingCreate = false;
                      this.utilsSrv.infoModal(this.translate.instant('modules.modals.parsing-dates-error') + '.',undefined);

                    }
                  });

                }else{


                  this.tripSrv.create(this.IMEIValue, this.StartDateValue!, this.FinishDateValue!).subscribe({
                    next: (res: Trip) => {

                      this.loadingCreate = false;
                      console.log(res);

                      this.router.navigate(['/trips/' , res.Id]);

                    },
                    error: (err) =>{
                      this.loadingCreate = false;
                      this.utilsSrv.infoModal(this.translate.instant('modules.modals.add-trip-error') + '.',undefined);

                    }
                  });
                }


              }else{
                
                console.log("CERRAR");
              }
            });

          }

        },error: (err) => {
          this.utilsSrv.infoModal(this.translate.instant('modules.modals.no-records-found-for-trip') + '.',undefined);

        }
    })
  }

}
