<app-loading-custom [loading]="loadingDelete"></app-loading-custom>
<div *ngIf="showFilter" class="grid-container">
  <form class="text-start p-2" [formGroup]="filterChartForm"  autocomplete="off" >
    <div>
      <div class="mr-2 ml-2 pl-3" >
        <button mat-fab class="my-fab" aria-label="Filtrar" [disabled]="(InformationFirstValue == null || InformationLastValue == null)?'disabled':null" (click)="reloadInfo()">
          <mat-icon>search</mat-icon>
        </button>
      </div>
      <div class="mr-2 pl-3" >
        <mat-form-field class="form-field" appearance="fill">
            <mat-label>{{'modules.shared.time-frame' | translate}}</mat-label>
            <mat-date-range-input
            [rangePicker]="campaignOnePicker" >
            <input matStartDate placeholder="{{'modules.shared.start-data' | translate}}" formControlName="InformationFirst" [(ngModel)]="InformationFirstValue" >
            <input matEndDate placeholder="{{'modules.shared.end-data' | translate}}" formControlName="InformationLast" [(ngModel)]="InformationLastValue" >
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="campaignOnePicker" ></mat-datepicker-toggle>
        <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="mr-2 ml-2 pl-3" *ngIf="InformationFirstValue" >
        <button mat-fab class="my-fab" aria-label="Filtrar" [disabled]="(InformationFirstValue == null || InformationLastValue == null)?'disabled':null" (click)="clearDate()">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>

      

    </div>   


  </form>
</div>
<div *ngIf="datatableConfigColumns && datatableConfigColumns.headerTable" class="m-3">


  <button *ngIf="selectedItems && selectedTripsList.length == 2"  mat-raised-button class="btn btn-warning d-none d-xl-block p-absolute" (click)="goToAggregateView()">
    {{'modules.trips.aggregate-view' | translate}}
  </button>
  
  <table #deviceList [id]="nameDatatable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
    class="display nowrap table row-border hover stripe table-striped table-sm" style="width: 100% !important">
    <thead>
      <tr>
        <th *ngFor="let headColumn of datatableConfigColumns.headerTable" [attr.class]="'column-' + headColumn.column + ' column-' + headColumn.tag" >
          <ng-container *ngIf="headColumn.name != '#select'">
            {{ headColumn.name}}
          </ng-container>
          <ng-container *ngIf="headColumn.name == '#select'">
            <!-- <div class="c-pointer-i">
              <label>
                <input type="checkbox" style="display: none;" (change)="selectUnselectAll()">
                <i  class="far fa-lg pl-3"
                    [ngClass]="selectedState === 1 ? 'fa-square' : (selectedState === 2 ? 'fa-check-square text-success' : 'fa-minus-square text-warning')"
                    style="color: #000">
                </i>
              </label>
            </div> -->
          </ng-container>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loading == true">
        <td [attr.colspan]="datatableConfigColumns!.headerTable!.length">{{'modules.shared.loading-info' | translate}}...</td>
      </tr>
      <tr *ngIf="loading == false && tripsList.length == 0">
        <td [attr.colspan]="datatableConfigColumns!.headerTable!.length">{{'modules.shared.no-records' | translate}}.</td>
      </tr>
      <tr *ngFor="let trip of tripsList" (click)="checkuncheckall(trip)" >

        <td  class="text-center c-pointer">
          <i *ngIf="trip.Selected == false" class="far fa-square fa-lg pl-3" style="color: #000"></i>
          <i *ngIf="trip.Selected == true"  class="far fa-check-square text-success fa-lg pl-3"></i>

        </td>
        <td>
            <a [routerLink]="[urlDetail  + trip.Id + '' ]">#{{ trip.Id }}</a>
        </td>
        <td class="c-pointer" >
          <a [routerLink]="[urlDetail  + trip.Id + '' ]">{{ trip.StartedDate }}</a>
        </td>
        <td class="c-pointer" *ngIf="authSrv.isAdmin() == true">
          <a [routerLink]="[urlDetail +  trip.Id + '' ]">{{ trip.FinishedDate }}</a>
        </td>
        <td >
          {{ trip.AlarmCount }}
        </td>

        <td>
            <a [routerLink]="[urlDetail  + trip.Id + '' ]">{{ trip.IMEI }}</a>
        </td>
        <td>
            {{ trip.NameDevice }}
        </td>
        <td>
            {{ trip.NumSerieManufacturer }}
        </td>
        <td>
            {{ trip.Comments }}
        </td>
        <td>
            {{ trip.NameClient }}
        </td>
        <td>
            {{ trip.NameManufacturer }}
        </td>

        <td *ngIf="authSrv.isAdmin() == true">
            {{ trip.VoltageValue }}
        </td>
        <td *ngIf="authSrv.isAdmin() == true">
            {{ trip.MobileOperator }}
        </td>

        <td>
          {{ trip.CreatedAt | date: "dd-MM-yyyy HH:mm" }}
        </td>
    
        <td class="c-pointer-i text-right actions">
          <i *ngIf="isManufacturer == true || authSrv.isAdmin()" (click)="deleteTrip(trip)"   class="fa-solid fa-trash text-danger fa-lg pl-3"></i>
          <a [routerLink]="[  urlDetail + trip.Id  ]"><i class="fas fa-angle-double-right text-primary fa-lg pl-3"></i></a>


        </td>
      </tr>
    </tbody>
  </table>