<app-loading-custom [loading]="loading"></app-loading-custom>
<div *ngIf="loading == false" class="grid-container">
    <div style="margin-right: auto;" *ngIf="showInfoPeriod">
      <ul>
        
        <ng-container *ngFor="let sensorName  of listSensorView">
          <ng-container *ngFor="let sensor of listSensorAccomulative">
              <li *ngIf="sensor.key === sensorName && firstInformation && lastInformation && sensor.info != 'WorkingHours'">
                <strong>{{ sensor.value }}:</strong> 
                {{  tripSrv.getDiff(informations!, sensor.info)![2]  }} ({{'modules.shared.total-selected-frame' | translate}})
              </li>
              <li *ngIf="sensor.key === sensorName && firstInformation && lastInformation && sensor.info == 'WorkingHours'">
                <strong>{{ sensor.value }}:</strong> 
                {{  calcularHorasYMinutos(tripSrv.getDiff(informations!, sensor.info)![2]).horas  }} horas {{ calcularHorasYMinutos(tripSrv.getDiff(informations!, sensor.info)![2]).minutos }} {{'modules.shared.minutes' | translate}} ({{'modules.shared.total-selected-frame' | translate}})
              </li>

          </ng-container>

        </ng-container>
      </ul>
    </div>
    <form class="text-start p-2" [formGroup]="filterChartForm"  autocomplete="off" *ngIf="showChangeSensors" >      

      <div class="mr-2 ml-2 pl-3 pt-2" style="min-width: 250px;" >
        <ng-multiselect-dropdown
          id="Sensors"
          (onSelect)="changeSensorSelected($event)"
          (onDeSelect)="changeSensorSelected($event)"
          formControlName="Sensors"
          [(ngModel)]="listSensorView"
          [placeholder]="'modules.shared.sensors' | translate"
          [settings]="dropdownSensor"
          [data]="listSensorNames">
        </ng-multiselect-dropdown>
      </div>




    </form>
  </div>

<div id="wrapper" class="mb-3" *ngIf="!loading && chartOptions1 && chartOptions2">
    <div class="main main-box">
      <div id="chart-line">
        <apx-chart #mainChart
          [series]="chartOptions1.series"
          [chart]="chartOptions1.chart"
          [xaxis]="chartOptions1.xaxis"
          [yaxis]="chartOptions1.yaxis"
          [dataLabels]="chartOptions1.dataLabels"
          [colors]="chartOptions1.colors"
          [fill]="chartOptions1.fill"
          [markers]="chartOptions1.markers"
          [stroke]="chartOptions1.stroke"
          [tooltip]="chartOptions1.tooltip"
          [legend]="chartOptions1.legend"
        ></apx-chart>
      </div>

      <div id="chart-line2">
        <apx-chart #timelineChart
          [series]="chartOptions2.series"
          [chart]="chartOptions2.chart"
          [xaxis]="chartOptions2.xaxis"
          [colors]="chartOptions2.colors"
          [fill]="chartOptions2.fill"
          [yaxis]="chartOptions2.yaxis"
          [dataLabels]="chartOptions2.dataLabels"
          [legend]="chartOptions2.legend"
        ></apx-chart>
      </div>
    </div>
 

  </div>