import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EmailService } from 'src/app/services/email.service';
import { UsersService } from 'src/app/services/users.services';
import { UtilsService } from 'src/app/services/utils.service';


@Component({
  selector: 'app-edit-client-user',
  templateUrl: './edit-client-user.component.html',
  styleUrls: ['./edit-client-user.component.scss']
})
export class EditClientUserComponent implements OnInit {
  @Input() user?: User;
  @Input() page?: number;
  userEdit?: User;
  loading:boolean = true;

  editClientForm: FormGroup = this.formBuilder.group({
  });
  @Output() editClientFormShared = new EventEmitter<FormGroup>();

  private emitFormGeneral() {
    this.editClientFormShared.emit(this.editClientForm);
  }

  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public authSrv : AuthenticationService,
    private emailSrv: EmailService,
    private usersSrv : UsersService,
    private utilsSrv : UtilsService) {
    }

  ngOnInit(): void {

    this.userEdit = this.user;

    this.editClientForm.addControl('EditClient',this.addUserClientForm());
    this.emitFormGeneral();
    this.loading = false;
  }

  updateParen(){

  }

  onSubmit(){
    console.log(this.userEdit);
    console.log(this.editClientForm!.errors);
  }


  get Name(){ return this.editClientForm.get("EditClient")!.get("Name"); }
  get FirstName(){ return this.editClientForm.get("EditClient")!.get("FirstName"); }
  get LastName(){ return this.editClientForm.get("EditClient")!.get("LastName"); }
  get DNICIF(){ return this.editClientForm.get("EditClient")!.get("DNICIF")}

  //get CreditCard(){ return this.editClientForm.get("EditClient")!.get("CreditCard")}
  get IsSeller(){ return this.editClientForm.get("EditClient")!.get("IsSeller"); }


  addUserClientForm() {

    if(this.user!.TypeDocument! == true){
      return this.formBuilder.group({
        IsSeller: new FormControl(this.user!.IsSeller, [
          Validators.required
        ]),
        Name: this.formControlName(),
        DNICIF:  this.formControlDNICIF(),
      });

    }else{
      return this.formBuilder.group({
        IsSeller: new FormControl(this.user!.IsSeller, [
          Validators.required
        ]),
        Name: this.formControlName(),
        FirstName: this.formControlFirstName(),
        LastName: this.formControlLastName(),
        DNICIF:  this.formControlDNICIF(),
      });
    }
  }

  formControlName(){
    return new FormControl(this.user!.Name, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(35)
    ]);
  }
  formControlFirstName(){
    return new FormControl(this.user!.FirstName, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50)
    ]);
  }
  formControlLastName(){
    new FormControl(this.user!.LastName, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50)
    ])
  }
  formControlDNICIF(){
    return new FormControl(this.user!.DNICIF, [
      Validators.required,
      Validators.minLength(9),
      Validators.maxLength(15)
    ])
  }


  test(){

  }

}
