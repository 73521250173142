import { Theme } from "./theme"

export class UserLogged   {
  AlertExpirydate?: Date
  ExpiryDate?: Date
  JwtTokenString?: string
  Password?: string
  UserId?: number
  Username?: string
  UsernameLong?: string
  Payload?: Payload;
  Superadmin?: boolean;
  Theme?: Theme;
  NotificationsCount?: number;
  NumDevices?: number;
  MainDevice?: number;
  Email?: string;
  TripsModuleEnabled?: boolean;
  DocManagerModuleEnabled?: boolean;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

}

export class Payload   {
  aud?: string
  exp?: number
  fullName?: string
  iat?: number
  iss?: string
  nbf?: number
  role?: string
  uid?: number;
  unique_name?: string;

  constructor(values: Object = {}) {
    (<any>Object).assign(this, values);
  }

}

export class Register {
  Nombre?: number;
  Apellido1?: string;
  Apellido2?: string;
  Telefono1?: string;
  Telefono2?: string;
  Email?: string;
  Roles_Id?: number;
  Password?: string;
  DNICIF?: string;
  NombreEmpresa?: string;
  NombreComercialExplotacion?: string;
  CuentaBancaria?: string;
  DireccionFiscal?: string;
  DireccionEntrega?: string;
  TarjetaPago?: string;
  CardValid? : Date
  OwnerIdNum? : string
}
