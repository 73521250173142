<app-breadcrumb></app-breadcrumb>

<div class="container-fluid pt-3 pl-0 pr-0 espace">
    <div class="col-12">
        <div class="my-4">
            <div class="container-fluid">
                <div class="row align-items-center">
                <div class="col-12">
                    <h1>{{'modules.shared.new-register-model-machine' | translate}}</h1>
                </div>
                </div>
            </div>
        </div>
    </div>
    <app-block-group-dashboard>
        <app-block-dashboard>
            <app-add-model-device></app-add-model-device>
        </app-block-dashboard>
    </app-block-group-dashboard>
</div>


<app-footer></app-footer>
