import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { SearchUserModalComponent } from 'src/app/modals/search-user-modal/search-user-modal.component';
import { ListResult } from 'src/app/models/apiRequestObject';
import { DocManagerFolder } from 'src/app/models/docManager';
import { Modal } from 'src/app/models/modal';
import { Role, User, vUser } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DocManagerService } from 'src/app/services/doc-manager.service';
import { UsersService } from 'src/app/services/users.services';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-add-folder-media',
  templateUrl: './add-folder-media.component.html',
  styleUrls: ['./add-folder-media.component.scss']
})
export class AddFolderMediaComponent implements OnInit {

  @Input() parentFolder?: DocManagerFolder | null;
  @Input() currentFolder?: DocManagerFolder | null;

  
  @Input() eventOnSubmit: boolean = false;
  @Input() showButtonCancel: boolean = false;
  @Output() folderOutput = new EventEmitter<DocManagerFolder>();
  loading: boolean = false;
  public roleAll: Role = new Role({Id: -1, InternName: 'Todos'});
  public newDocManagerFolder: DocManagerFolder = new DocManagerFolder({'Name' : '', 'InternalName' : '', 'IsVisible' : true, 'IsPublic' : true , 'Priority' : 1});

  public rolesList: Array<Role> | null = null;

  dropdownClients: IDropdownSettings= {};
  public selectedClientsList: Array<User> = [];
  public clientsList: Array<User> = [];
  loadClients: boolean = false;
  loadClientsSelecteds: boolean = false;
  loadRoles: boolean = false;
  
  addForm: FormGroup = this.formBuilder.group({
  });
  
  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private usersSrv : UsersService,
    private utilsSrv : UtilsService,
    public authSrv : AuthenticationService,
    public mediaSrv: DocManagerService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.loading = true;
    this.newDocManagerFolder.Role = this.roleAll;
    //this.newDocManagerFolder.IdRole = this.roleAll.Id

    if(this.currentFolder &&  this.currentFolder!.Id){
      console.log('currentFolder', this.currentFolder);
      this.newDocManagerFolder = { ...this.currentFolder};

      if(this.newDocManagerFolder.Role == null){
        this.newDocManagerFolder.Role = this.roleAll;
      }
    }

    this.addForm.addControl( 'Media' ,  this.formBuilder.group({
      Name: new FormControl(this.newDocManagerFolder!.Name, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(25)
      ]),
      IsVisible: new FormControl(this.newDocManagerFolder.IsVisible, [
        Validators.required
      ]),
      IsPublic: new FormControl(this.newDocManagerFolder.IsPublic, [
        Validators.required
      ]),
      Role: new FormControl(this.newDocManagerFolder.Role),
      IdRole: new FormControl(this.newDocManagerFolder.IdRole),
      IdParent: new FormControl(this.newDocManagerFolder.IdParent),
      SelectedClientsList: new FormControl(this.selectedClientsList)

    }));

    // Get roles
    this.rolesList = null;

    if(this.newDocManagerFolder.IdRole && this.newDocManagerFolder.Role.Tag == 'user' ){
      this.getClientsUser();
    }else if(this.newDocManagerFolder.IdRole && this.newDocManagerFolder.Role.Tag == 'fabricant' ){
      this.getManufacturerUser();
    }else if(this.newDocManagerFolder.IdRole && this.newDocManagerFolder.Role.Tag == 'admin' ){
      this.getAdminUser();
    }else{      
      this.getAllUser();
    }

    this.usersSrv.getRoles().subscribe({
      next: (res: ListResult) => {
        this.rolesList =  <Role[]> res.Result; 
        this.rolesList.push(new Role({InternName: 'Todos'}))


      },error: (err: any) =>{
        console.log("error getRoles");
      }, complete: () =>{
        this.loadRoles = true;
        this.loadComplete();
      }
    });
  
    // Get users
    
    this.dropdownClients = {
      singleSelection: false,
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: true,
      idField: 'Id',
      textField: 'Name',
      selectAllText: this.translate.instant('modules.shared.select-all'),
      unSelectAllText: this.translate.instant('modules.shared.unselect-all'),
      allowSearchFilter: true,
      //noFilteredDataAvailablePlaceholderText: "No hay coincidencias",
      noDataAvailablePlaceholderText: this.translate.instant('modules.shared.no-users'),
      searchPlaceholderText: this.translate.instant('modules.shared.search-user'),
      clearSearchFilter: true,
    };
    
    // Folders
    this.newDocManagerFolder.IdParent = this.parentFolder?.Id;

  }

  
  get Name(){ return this.addForm.get("Media")!.get("Name"); }
  get IsVisible(){ return this.addForm.get("Media")!.get("IsVisible"); }
  get IsPublic(){ return this.addForm.get("Media")!.get("IsPublic"); }
  get IdRole(){ return this.addForm.get("Media")!.get("IdRole"); }
  get IdParent(){ return this.addForm.get("Media")!.get("IdParent")}
  get SelectedClientsList(){ return this.addForm.get("Media")!.get("SelectedClientsList"); }

  cancel() {

    this.dialog.getDialogById('FolderMediaAddQuickComponent')?.close();
  }
  onSubmit(){
    this.loading = true;
    if (! this.addForm!.valid){
      return ;
    }

    this.newDocManagerFolder.IdRole = this.newDocManagerFolder.Role?.Id;
    
    this.newDocManagerFolder.Users = this.selectedClientsList;
    
    if(this.currentFolder &&  this.currentFolder!.Id){
      this.mediaSrv.saveChangesById(this.newDocManagerFolder).subscribe({
        next: (res: DocManagerFolder) =>{
          console.log('eventOnSubmit' , this.eventOnSubmit );
          if(this.eventOnSubmit){
            console.log('res' , res );

            this.emitFolder({ ...res });

          }else{

            this.utilsSrv.infoModal('Directorio modificado correctamente',undefined);


          }

        },
        error: (err: any)=>{
          console.log("error create");
        }, 
        complete: () =>{
          this.loading = false;
        }

      });
    }else{
      this.mediaSrv.create(this.newDocManagerFolder).subscribe({
        next: (res: DocManagerFolder) =>{
          if(this.eventOnSubmit){

            this.emitFolder({ ...res });

          }else{

            this.utilsSrv.infoModal('Directorio creado correctamente',undefined);


          }

        },
        error: (err: any)=>{
          console.log("error create");
        }, 
        complete: () =>{
          this.loading = false;
        }

      });
    }

  }

  

  private emitFolder(resultFolder: DocManagerFolder) {
    this.folderOutput.emit(resultFolder);
  }

  
  getAllUser(){
    this.loading = true;
    this.loadClients = false;
    this.clientsList = [];
    this.usersSrv.getList(0, 10000, 'Name' , 'ASC' ).subscribe({
      next: (res: ListResult) => {

        this.clientsList = <User[]>res.Result;
        console.log(this.clientsList);

        this.selectedClientsList = [];
        this.newDocManagerFolder.Users?.forEach( x=> {
          this.selectedClientsList.push(new User({...x}));
        });

      },error: (err: any) =>{
        console.log("error getClients");
        this.loadClients = true;
        this.loadComplete();
      }, complete: () =>{
        this.loadClients = true;
        this.loadComplete();

      }
    });
  }

  
  getManufacturerUser(){
    this.loading = true;
    this.loadClients = false;
    this.clientsList = [];
    this.usersSrv.getManufacturers(0, 10000, 'Name' , 'ASC' ).subscribe({
      next: (res: ListResult) => {

        this.clientsList = <User[]>res.Result;
        console.log(this.clientsList);

        this.selectedClientsList = [];
        this.newDocManagerFolder.Users?.forEach( x=> {
          this.selectedClientsList.push(new User({...x}));
        });

      },error: (err: any) =>{
        console.log("error getClients");
        this.loadClients = true;
        this.loadComplete();
      }, complete: () =>{
        this.loadClients = true;
        this.loadComplete();

      }
    });
  }
  
  getClientsUser(){
    this.loading = true;
    this.loadClients = false;
    this.clientsList = [];
    this.usersSrv.getClients(0, 10000, 'Name' , 'ASC' ).subscribe({
      next: (res: ListResult) => {

        this.clientsList = <User[]>res.Result;
        console.log(this.clientsList);

        this.selectedClientsList = [];
        this.newDocManagerFolder.Users?.forEach( x=> {
          this.selectedClientsList.push(new User({...x}));
        });

      },error: (err: any) =>{
        console.log("error getClients");
        this.loadClients = true;
        this.loadComplete();
      }, complete: () =>{
        this.loadClients = true;
        this.loadComplete();

      }
    });
  }
  
  getAdminUser(){
    this.loading = true;
    this.loadClients = false;
    this.clientsList = [];
    this.usersSrv.getUsersByRole('admins', 0, 10000, 'Name' , 'ASC' ).subscribe({
      next: (res: ListResult) => {

        this.clientsList = <User[]>res.Result;
        console.log(this.clientsList);

        this.selectedClientsList = [];
        this.newDocManagerFolder.Users?.forEach( x=> {
          this.selectedClientsList.push(new User({...x}));
        });

      },error: (err: any) =>{
        console.log("error getClients");
        this.loadClients = true;
        this.loadComplete();
      }, complete: () =>{
        this.loadClients = true;
        this.loadComplete();

      }
    });
  }


  changeRole(event: any){
    console.log(event.Tag);
    console.log(this.selectedClientsList );

    this.newDocManagerFolder.Users = [];
    this.selectedClientsList = [];
    this.addForm.get("Media")!.get("SelectedClientsList")!.setValue([]); 

    if(event.Tag == 'user' ){
      this.getClientsUser();
    }else if(event.Tag == 'fabricant' ){
      this.getManufacturerUser();
    }else if(event.Tag == 'admin'){  
      this.getAdminUser();
    }else{
      this.getAllUser();
    }
    console.log(this.selectedClientsList );
    
  }
  

  loadComplete(){
    if(this.loadClients == true   && this.loadRoles == true){
      this.loading = false; 
    }
  }

}
