import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AlarmDeviceModelDefaultValues, AlarmDeviceModelValues, AlarmValue } from 'src/app/models/alarms';
import { ListResult } from 'src/app/models/apiRequestObject';
import { Device, vDevice, vDeviceModel } from 'src/app/models/device';
import { Manufacturer, User, vUser } from 'src/app/models/user';
import { AlarmsService } from 'src/app/services/alarms.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DevicesService } from 'src/app/services/devices.service';
import { UserService } from 'src/app/services/user.service';
import { UsersService } from 'src/app/services/users.services';
import { FormRangeDefaultValuesAlarmComponent } from '../form-range-default-values-alarm/form-range-default-values-alarm.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-detail-model-alarm',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './detail-model-alarm.component.html',
  styleUrls: ['./detail-model-alarm.component.scss'],
  providers: [{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  }]
})
export class DetailModelAlarmComponent implements OnInit, AfterContentChecked {

  loading:boolean = true;
  loadingUser:boolean = true;

  @Input() information?: AlarmDeviceModelValues;
  @Input() manufacturerList?: Array<Manufacturer>;
  @Input() autoCloseModels: boolean = true;
  @Input() autoCloseManufacturer: boolean = true;
  @Input() selectedManufacturerList:  Array<Manufacturer>= [];

  @Input() pageDeviceInto: boolean = false;
  @Input() showChangeModel: boolean = true;
  @Input() showChangeManufacturer: boolean = true;


  public modelsList: Array<vDeviceModel> | null= null;
  itemRol?: number = 0;

  public filteredManufacturerList: Array<Manufacturer> = [];

  public manufacturer?: Manufacturer;
  public modelError = false;

  public selectedModelsList: Array<vDeviceModel>= [];

  valuesAlarmForm: FormGroup = this.formBuilder.group({  });
  dropdownSettingsUser : IDropdownSettings= {};

  @Output() valuesAlarmFormShared = new EventEmitter<FormGroup>();
  @ViewChildren('inputsChild') inputs?:QueryList<FormRangeDefaultValuesAlarmComponent>

  get modelsListFilter(): Array<vDeviceModel>{
    if (this.selectedManufacturerList.length == 0){
      return this.modelsList??[];
    }else{

      return this.modelsList!.filter(  ( x )  => { return x.IdManufacturer! == this.selectedManufacturerList[0].Id; });
    }
  }

  alarmForm: FormGroup = this.formBuilder.group({
  });

  rpmValuesAlarmForm: FormGroup = this.formBuilder.group({  });
  workingHoursValuesAlarmForm: FormGroup = this.formBuilder.group({  });

  pressure1ValuesAlarmForm: FormGroup = this.formBuilder.group({  });
  pressure2ValuesAlarmForm: FormGroup = this.formBuilder.group({  });
  flow1ValuesAlarmForm: FormGroup = this.formBuilder.group({  });
  flow2ValuesAlarmForm: FormGroup = this.formBuilder.group({  });

  action1ValuesAlarmForm: FormGroup = this.formBuilder.group({  });
  action2ValuesAlarmForm: FormGroup = this.formBuilder.group({  });
  action3ValuesAlarmForm: FormGroup = this.formBuilder.group({  });
  action4ValuesAlarmForm: FormGroup = this.formBuilder.group({  });
  action5ValuesAlarmForm: FormGroup = this.formBuilder.group({  });
  action6ValuesAlarmForm: FormGroup = this.formBuilder.group({  });

  weight1ValuesAlarmForm: FormGroup = this.formBuilder.group({  });
  weight2ValuesAlarmForm: FormGroup = this.formBuilder.group({  });

  aux1ValuesAlarmForm: FormGroup = this.formBuilder.group({  });
  aux2ValuesAlarmForm: FormGroup = this.formBuilder.group({  });
  aux3ValuesAlarmForm: FormGroup = this.formBuilder.group({  });
  aux4ValuesAlarmForm: FormGroup = this.formBuilder.group({  });
  aux5ValuesAlarmForm: FormGroup = this.formBuilder.group({  });
  aux6ValuesAlarmForm: FormGroup = this.formBuilder.group({  });
  aux7ValuesAlarmForm: FormGroup = this.formBuilder.group({  });
  aux8ValuesAlarmForm: FormGroup = this.formBuilder.group({  });

  dropdownSettingsManufacturer : IDropdownSettings= {};
  dropdownSettingsModels: IDropdownSettings= {};

  public emitFormGeneral() {
    this.valuesAlarmFormShared.emit(this.valuesAlarmForm);
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  constructor(public dialog: MatDialog,
    private deviceSrv: DevicesService,
    private usrSrv: UsersService,
    private alarmSrv: AlarmsService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    public authSrv: AuthenticationService,
    private alarmrSrv : AlarmsService,
    private formBuilder: FormBuilder,
    private translate: TranslateService) {

  }

  get RPMValid():boolean{ return this.rpmValuesAlarmForm.valid??false; }
  get WorkingHoursValid():boolean{ return this.workingHoursValuesAlarmForm.valid??false; }
  get Pressure1Valid():boolean{ return this.pressure1ValuesAlarmForm.valid??false; }
  get Pressure2Valid():boolean{ return this.pressure2ValuesAlarmForm.valid??false; }
  get Flow1Valid():boolean{ return this.flow1ValuesAlarmForm.valid??false; }
  get Flow2Valid():boolean{ return this.flow2ValuesAlarmForm.valid??false; }

  get Action1Valid():boolean{ return this.action1ValuesAlarmForm.valid??false; }
  get Action2Valid():boolean{ return this.action2ValuesAlarmForm.valid??false; }
  get Action3Valid():boolean{ return this.action3ValuesAlarmForm.valid??false; }
  get Action4Valid():boolean{ return this.action4ValuesAlarmForm.valid??false; }
  get Action5Valid():boolean{ return this.action5ValuesAlarmForm.valid??false; }
  get Action6Valid():boolean{ return this.action6ValuesAlarmForm.valid??false; }

  get Weight1():boolean{ return this.weight1ValuesAlarmForm.valid??false; }
  get Weight2():boolean{ return this.weight2ValuesAlarmForm.valid??false; }

  get Aux1():boolean{ return this.aux1ValuesAlarmForm.valid??false; }
  get Aux2():boolean{ return this.aux2ValuesAlarmForm.valid??false; }
  get Aux3():boolean{ return this.aux3ValuesAlarmForm.valid??false; }
  get Aux4():boolean{ return this.aux4ValuesAlarmForm.valid??false; }
  get Aux5():boolean{ return this.aux5ValuesAlarmForm.valid??false; }
  get Aux6():boolean{ return this.aux6ValuesAlarmForm.valid??false; }
  get Aux7():boolean{ return this.aux7ValuesAlarmForm.valid??false; }
  get Aux8():boolean{ return this.aux8ValuesAlarmForm.valid??false; }

  alarmValuesForm: FormGroup = this.formBuilder.group({  });



  get textRole() : string{
    if(this.information && this.information.User){

      let u: User = <User>this.information.User;

      if(u.isAdmin){
        return this.translate.instant('modules.alarms.admin-assigned');
      }
      if(u.isManufacturer){
        return this.translate.instant('modules.alarms.account-assigned');
      }
      if(u.isClient){
        return this.translate.instant('modules.alarms.account-assigned');
      }

    }
    return '';
  }

  updateRPMValid(form: FormGroup) {
    console.log("updateRPMValid");
    this.rpmValuesAlarmForm = form;
  }
  updateWorkingHoursValid(form: FormGroup) {
    console.log("updateWorkingHoursValid");
    this.workingHoursValuesAlarmForm = form;
  }

  updatePressure1Valid(form: FormGroup) {
    console.log("updatePressure1Valid");
    this.pressure1ValuesAlarmForm = form;
  }
  updatePressure2Valid(form: FormGroup) {
    console.log("updatePressure2Valid");
    this.pressure2ValuesAlarmForm = form;
  }
  updateFlow1Valid(form: FormGroup) {
    console.log("updateFlow1Valid");
    this.flow1ValuesAlarmForm = form;
  }
  updateFlow2Valid(form: FormGroup) {
    console.log("updateFlow2Valid");
    this.flow2ValuesAlarmForm = form;
  }

  updateAction1Valid(form: FormGroup) {
    console.log("updateAction1Valid");
    this.action1ValuesAlarmForm = form;
  }
  updateAction2Valid(form: FormGroup) {
    console.log("updateAction2Valid");
    this.action2ValuesAlarmForm = form;
  }
  updateAction3Valid(form: FormGroup) {
    console.log("updateAction3Valid");
    this.action3ValuesAlarmForm = form;
  }
  updateAction4Valid(form: FormGroup) {
    console.log("updateAction4Valid");
    this.action4ValuesAlarmForm = form;
  }
  updateAction5Valid(form: FormGroup) {
    console.log("updateAction5Valid");
    this.action5ValuesAlarmForm = form;
  }
  updateAction6Valid(form: FormGroup) {
    console.log("updateAction6Valid");
    this.action6ValuesAlarmForm = form;
  }

  updateWeight1Valid(form: FormGroup) {
    console.log("updateWeight1Valid");
    this.weight1ValuesAlarmForm = form;
  }
  updateWeight2Valid(form: FormGroup) {
    console.log("updateWeight2Valid");
    this.weight2ValuesAlarmForm = form;
  }

  updateAux1Valid(form: FormGroup) {
    console.log("updateAux1Valid");
    this.aux1ValuesAlarmForm = form;
  }
  updateAux2Valid(form: FormGroup) {
    console.log("updateAux2Valid");
    this.aux2ValuesAlarmForm = form;
  }
  updateAux3Valid(form: FormGroup) {
    console.log("updateAux3Valid");
    this.aux3ValuesAlarmForm = form;
  }
  updateAux4Valid(form: FormGroup) {
    console.log("updateAux4Valid");
    this.aux4ValuesAlarmForm = form;
  }
  updateAux5Valid(form: FormGroup) {
    console.log("updateAux5Valid");
    this.aux5ValuesAlarmForm = form;
  }
  updateAux6Valid(form: FormGroup) {
    console.log("updateAux6Valid");
    this.aux6ValuesAlarmForm = form;
  }
  updateAux7Valid(form: FormGroup) {
    console.log("updateAux7Valid");
    this.aux7ValuesAlarmForm = form;
  }
  updateAux8Valid(form: FormGroup) {
    console.log("updateAux8Valid");
    this.aux8ValuesAlarmForm = form;
  }

  ngOnInit(): void {
    this.loading = true;
    this.cdr.detach();

    console.log(this.information);
    console.log('this.information.Id', this.information!.Id!);

    console.log("this.showChangeModel", this.showChangeModel);


    this.dropdownSettingsUser = {
      singleSelection: true,
      closeDropDownOnSelection: this.autoCloseManufacturer,
      idField: 'Id',
      textField: 'Name',
      selectAllText: this.translate.instant('modules.shared.select-all'),
      unSelectAllText: this.translate.instant('modules.shared.unselect-all'),
      allowSearchFilter: true,
      //noFilteredDataAvailablePlaceholderText: "No hay coincidencias",
      noDataAvailablePlaceholderText: this.translate.instant('modules.shared.no-users'),
      searchPlaceholderText: this.translate.instant('modules.shared.search'),
      clearSearchFilter: true,
    };

    this.dropdownSettingsModels = {
      singleSelection: true,
      closeDropDownOnSelection: this.autoCloseModels,
      showSelectedItemsAtTop: true,
      idField: 'Id',
      textField: 'Name',
      selectAllText: this.translate.instant('modules.shared.select-all'),
      unSelectAllText: this.translate.instant('modules.shared.unselect-all'),
      allowSearchFilter: true,
      //noFilteredDataAvailablePlaceholderText: "No hay coincidencias",
      noDataAvailablePlaceholderText: this.translate.instant('modules.shared.no-models-machine'),
      searchPlaceholderText: this.translate.instant('modules.shared.search-model-machine'),
      clearSearchFilter: true,
    };

    this.dropdownSettingsManufacturer = {
      singleSelection: true,
      showSelectedItemsAtTop: true,
      closeDropDownOnSelection: this.autoCloseManufacturer,
      idField: 'Id',
      textField: 'Name',
      selectAllText: this.translate.instant('modules.shared.select-all'),
      unSelectAllText: this.translate.instant('modules.shared.unselect-all'),
      allowSearchFilter: true,
      //noFilteredDataAvailablePlaceholderText: "No hay coincidencias",
      noDataAvailablePlaceholderText: this.translate.instant('modules.shared.no-accounts'),
      searchPlaceholderText: this.translate.instant('modules.shared.search-account'),
      clearSearchFilter: true,

    }

    this.getModelsManufacturers();


    if(this.showChangeModel == false && this.information?.Id == 0){
      this.selectedModelsList.push(<vDevice>new vDevice(this.information?.DeviceModel));
      this.searchDefaultValueByModel();
    }
    if(this.authSrv.isAdmin()  && this.information?.User){


      if(this.information?.User.isAdmin){ this.itemRol = 0; }
      if(this.information?.User.isClient){ this.itemRol = this.information?.User.Id;}
      if(this.information?.User.isManufacturer){  this.itemRol = this.information?.User.Id;}
      //this.getUsers();
      this.loadingUser = false;
    }else {
      this.loadingUser = false;
    }

  }


  get readOnly(): boolean{
    return !this.authSrv.isAdmin() && this.authSrv.currentUserValue?.UserId != this.information!.IdUserCreated && this.information!.Id! > 0
  }

  onSubmit(validatonForm: boolean = true){

    console.log('validatonForm' ,validatonForm);
    console.log(this.information);

    if (validatonForm && !this.validAllForm){
      console.log("ko");
      return ;
    }


    this.loading = true;
    console.log(this.information!.DeviceModel);


    if(this.selectedModelsList.length == 0){
      this.modelError = true;
      this.loading = false;
      return;
    }

    if(this.showChangeModel == true && this.authSrv.isClient() == false ){
      this.information!.DeviceModel = this.alarmForm.get("Alarm")?.get("DeviceModel")?.value[0];
      this.information!.IdModel = this.alarmForm.get("Alarm")?.get("DeviceModel")?.value[0].Id;
    }

    if(this.information!.DeviceModel){
      this.information!.IdModel = this.information!.DeviceModel!.Id??null;
      this.modelError = false;
    }else{
      this.modelError = true;
      this.loading = false;
      return;
    }

    console.log(this.information!.IdModel);


    this.information!.AlarmValue!.RPMMin = this.rpmValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.RPMMax = this.rpmValuesAlarmForm.get("maxValue")?.value;

    this.information!.AlarmValue!.WorkingHoursMin = this.workingHoursValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.WorkingHoursMax = this.workingHoursValuesAlarmForm.get("maxValue")?.value;

    this.information!.AlarmValue!.Pressure1Min = this.pressure1ValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.Pressure1Max = this.pressure1ValuesAlarmForm.get("maxValue")?.value;
    this.information!.AlarmValue!.Pressure2Min = this.pressure2ValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.Pressure2Max = this.pressure2ValuesAlarmForm.get("maxValue")?.value;

    this.information!.AlarmValue!.Flow1Min = this.flow1ValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.Flow1Max = this.flow1ValuesAlarmForm.get("maxValue")?.value;
    this.information!.AlarmValue!.Flow2Min = this.flow2ValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.Flow2Max = this.flow2ValuesAlarmForm.get("maxValue")?.value;

    this.information!.AlarmValue!.Action1Min = this.action1ValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.Action1Max = this.action1ValuesAlarmForm.get("maxValue")?.value;
    this.information!.AlarmValue!.Action2Min = this.action2ValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.Action2Max = this.action2ValuesAlarmForm.get("maxValue")?.value;
    this.information!.AlarmValue!.Action3Min = this.action3ValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.Action3Max = this.action3ValuesAlarmForm.get("maxValue")?.value;
    this.information!.AlarmValue!.Action4Min = this.action4ValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.Action4Max = this.action4ValuesAlarmForm.get("maxValue")?.value;
    this.information!.AlarmValue!.Action5Min = this.action5ValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.Action5Max = this.action5ValuesAlarmForm.get("maxValue")?.value;
    this.information!.AlarmValue!.Action6Min = this.action6ValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.Action6Max = this.action6ValuesAlarmForm.get("maxValue")?.value;

    this.information!.AlarmValue!.Weight1Min = this.weight1ValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.Weight1Max = this.weight1ValuesAlarmForm.get("maxValue")?.value;
    this.information!.AlarmValue!.Weight2Min = this.weight2ValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.Weight2Max = this.weight2ValuesAlarmForm.get("maxValue")?.value;

    this.information!.AlarmValue!.Aux1Min = this.aux1ValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.Aux1Max = this.aux1ValuesAlarmForm.get("maxValue")?.value;
    this.information!.AlarmValue!.Aux2Min = this.aux2ValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.Aux2Max = this.aux2ValuesAlarmForm.get("maxValue")?.value;
    this.information!.AlarmValue!.Aux3Min = this.aux3ValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.Aux3Max = this.aux3ValuesAlarmForm.get("maxValue")?.value;
    this.information!.AlarmValue!.Aux4Min = this.aux4ValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.Aux4Max = this.aux4ValuesAlarmForm.get("maxValue")?.value;
    this.information!.AlarmValue!.Aux5Min = this.aux5ValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.Aux5Max = this.aux5ValuesAlarmForm.get("maxValue")?.value;
    this.information!.AlarmValue!.Aux6Min = this.aux6ValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.Aux6Max = this.aux6ValuesAlarmForm.get("maxValue")?.value;
    this.information!.AlarmValue!.Aux7Min = this.aux7ValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.Aux7Max = this.aux7ValuesAlarmForm.get("maxValue")?.value;
    this.information!.AlarmValue!.Aux8Min = this.aux8ValuesAlarmForm.get("minValue")?.value;
    this.information!.AlarmValue!.Aux8Max = this.aux8ValuesAlarmForm.get("maxValue")?.value;


    this.information!.AlarmValue!.ManufacturerActive = this.alarmForm.get("Alarm")?.get("ManufacturerActive")?.value;
    this.information!.AlarmValue!.ClientActive = this.alarmForm.get("Alarm")?.get("ClientActive")?.value;

    if(this.authSrv.isAdmin()){
      this.information!.IdUserCreated = this.itemRol;

    }

    console.log(this.information!.IdUserCreated);

    if(this.information!.Id == 0){

      this.alarmSrv.createAlarmByModel(this.information!).subscribe({
        next: (result: AlarmDeviceModelValues) =>{
          this.information = result;

        },error: (err: any) =>{
          console.log("error changeDefaultValueAlarmById");
        }, complete: () =>{
          this.loading = false;

          if(this.showChangeModel == true){
            this.router.navigate(['/alarms/model/' + this.information!.Id]);
          }else{
            this.router.navigate(['/devices/models/' + this.information!.IdModel! + '/alarms/' + this.information!.Id]);

          }
          console.log(this.information);
        }
      });

    }else{


      this.alarmSrv.changeModelValueAlarmById(this.information!).subscribe({
        next: (result: AlarmDeviceModelValues) =>{
          this.information = new AlarmDeviceModelValues(result);
          this.information.correct();
        },error: (err: any) =>{
          console.log("error changeDefaultValueAlarmById");
        }, complete: () =>{
          this.loading = false;
          console.log(this.information);
        }
      });

    }


  }

  get rpmValuesAlarmFormValid():boolean{
    return this.rpmValuesAlarmForm?.valid??false;
  }

  get workingHoursFormValid():boolean{
    return this.workingHoursValuesAlarmForm?.valid??false;
  }
  get pressure1FormValid():boolean{
    return this.pressure1ValuesAlarmForm?.valid??false;
  }
  get pressure2FormValid():boolean{
    return this.pressure2ValuesAlarmForm?.valid??false;
  }
  get flow1FormValid():boolean{
    return this.flow1ValuesAlarmForm?.valid??false;
  }
  get flow2FormValid():boolean{
    return this.flow2ValuesAlarmForm?.valid??false;
  }

  get action1FormValid():boolean{
    return this.action1ValuesAlarmForm?.valid??false;
  }
  get action2FormValid():boolean{
    return this.action2ValuesAlarmForm?.valid??false;
  }
  get action3FormValid():boolean{
    return this.action3ValuesAlarmForm?.valid??false;
  }
  get action4FormValid():boolean{
    return this.action4ValuesAlarmForm?.valid??false;
  }
  get action5FormValid():boolean{
    return this.action5ValuesAlarmForm?.valid??false;
  }
  get action6FormValid():boolean{
    return this.action6ValuesAlarmForm?.valid??false;
  }
  get weight1FormValid():boolean{
    return this.weight1ValuesAlarmForm?.valid??false;
  }
  get weight2FormValid():boolean{
    return this.weight2ValuesAlarmForm?.valid??false;
  }
  get aux1FormValid():boolean{
    return this.aux1ValuesAlarmForm?.valid??false;
  }
  get aux2FormValid():boolean{
    return this.aux2ValuesAlarmForm?.valid??false;
  }
  get aux3FormValid():boolean{
    return this.aux3ValuesAlarmForm?.valid??false;
  }
  get aux4FormValid():boolean{
    return this.aux4ValuesAlarmForm?.valid??false;
  }
  get aux5FormValid():boolean{
    return this.aux5ValuesAlarmForm?.valid??false;
  }
  get aux6FormValid():boolean{
    return this.aux6ValuesAlarmForm?.valid??false;
  }
  get aux7FormValid():boolean{
    return this.aux7ValuesAlarmForm?.valid??false;
  }
  get aux8FormValid():boolean{
    return this.aux8ValuesAlarmForm?.valid??false;
  }
  get modelValid():boolean{
    return this.alarmForm?.valid??false;
  }




  get ManufacturerActive(){ return this.alarmForm?.get("Alarm")?.get("ManufacturerActive")?.value; }
  get ClientActive(){ return this.alarmForm?.get("Alarm")?.get("ClientActive")?.value; }
  get User(){ return this.alarmForm?.get("Alarm")?.get("User")?.value; }
  get Role(){ return this.alarmForm?.get("Alarm")?.get("Role")?.value; }

  get validAllForm():boolean{

    if((this.authSrv.isClient() || this.authSrv.isManufacturer()) && this.information?.IdUserCreated != this.authSrv.currentUserValue?.UserId ){
      return this.modelValid;
    }else{

      //return this.rpmValuesAlarmFormValid && this.workingHoursFormValid;
      return this.modelValid && this.selectedModelsList.length > 0
      && this.rpmValuesAlarmFormValid && this.workingHoursFormValid  && this.pressure1FormValid
      && this.pressure2FormValid &&  this.flow1FormValid &&  this.flow2FormValid
      && this.action1FormValid && this.action2FormValid  && this.action3FormValid && this.action4FormValid && this.action5FormValid && this.action6FormValid
      && this.weight1FormValid && this.weight2FormValid
      && this.aux1FormValid && this.aux2FormValid  && this.aux3FormValid && this.aux4FormValid
      && this.aux5FormValid && this.aux6FormValid  && this.aux7FormValid && this.aux8FormValid
      ;
    }
  }

  showForm(){

    console.log('rpmValuesAlarmForm valid: ', this.rpmValuesAlarmForm?.valid);
    // console.log('workingHoursvaluesAlarmForm ', this.workingHoursvaluesAlarmForm?.valid);

    console.log('rpmValuesAlarmForm valid: ', this.rpmValuesAlarmForm?.valid);

    console.log('rpmValuesAlarmForm errors: ', this.rpmValuesAlarmForm?.errors);
   // console.log('workingHoursvaluesAlarmForm ', this.workingHoursvaluesAlarmForm?.valid);


    console.log("valid all forms: " , this.validAllForm);

  }

  get isCompleteLoading(): boolean{
    if(this.modelsList != null){
      return true;
    }
    return false;
  }

  displayFn(d: vDevice): string {
    return d && d.Name ? d.Name : '';
  }

  getModelsManufacturers(){
    this.modelsList = null;
    this.loading = true;
    this.selectedModelsList = [];

    console.log("getModelsManufacturers")

    this.deviceSrv.getAllModels().subscribe({
      next: (res: ListResult) => {

        this.modelsList = <vDeviceModel[]>res.Result;
        console.log(this.modelsList);

        let aux: vDeviceModel | undefined = this.modelsList.find( (x) => { return x.Id == this.information?.DeviceModel?.Id } );

        console.log("model list"  , this.information?.DeviceModel?.Id )
        if(aux){
          this.selectedModelsList.push(aux);
        }





      },error: (err: any) =>{
        console.log("error getManufacturers");
      }, complete: () =>{
        if(this.isCompleteLoading){
          this.alarmForm.addControl('Alarm',this.addAlarmForm());

          this.loading = false;
        }
      }
    });
  }


  get getModel() {
    return this.modelsList!.reduce((acc:any , curr: vDeviceModel) => {
      acc[curr.Id!] = curr;
      return acc??'';
    }, {});
  }
  get getManufacturer() {
    return this.manufacturerList!.reduce((acc:any , curr: vDeviceModel) => {
      acc[curr.Id!] = curr;
      return acc??'';
    }, {});
  }


  addAlarmForm() {
      return this.formBuilder.group({
        DeviceModel: new FormControl(this.information!.DeviceModel, [
          Validators.required
        ]),
        Manufacturer: new FormControl(this.manufacturer, [
        ]),
        ManufacturerActive: new FormControl(this.information!.AlarmValue!.ManufacturerActive, [
        ]),
        ClientActive: new FormControl(this.information!.AlarmValue!.ClientActive, [
        ]),
        User: new FormControl(this.information!.User, [
        ]),
        Role: new FormControl(this.itemRol, [
        ]),
      });

  }

  changeModel(){
    this.selectedModelsList = [];
  }

  changeManufacturer(){
    console.log("change", this.selectedModelsList);

    let model: vDeviceModel = this.modelsList!.filter((x) => { return x.Id == this.selectedModelsList[0].Id})[0];

    console.log("model", model);

    let elements = this.manufacturerList!.filter((x) => { return x.Id == model.IdManufacturer});
    this.selectedManufacturerList = elements;
    console.log(elements);

    this.searchDefaultValueByModel();
  }


  searchDefaultValueByModel(){

      console.log('searchDefaultValueByModel information: ' , this.selectedModelsList);
      console.log('searchDefaultValueByModel information: ' , this.selectedModelsList[0].Id );
      if(this.selectedModelsList[0].Id){
        this.alarmrSrv.getDefaultValuesByModelId(this.selectedModelsList[0].Id! ).subscribe({
          next: (res: Array<AlarmDeviceModelDefaultValues>) => {
            //this.information = new AlarmDeviceModelDefaultValues(<AlarmDeviceModelDefaultValues>res);
            if(res.length > 0){
              /*this.inputs!.forEach(element => {
                element.changeAlarm(true);
              });*/

              this.loading = true;
              this.information!.AlarmValue = <AlarmValue>res[0].AlarmValue;


              setTimeout( () => {
                this.loading = false;
              }, 250 );


            }
          }, error: ()=> {

          },
          complete: ()=>{

            console.log(this.information);
          }
        });
      }
  }



  test(){
    console.log(this.information);
    console.log(this.selectedModelsList);
    console.log( this.pressure1ValuesAlarmForm.get("minValue")?.value );
    console.log(this.pressure1ValuesAlarmForm.get("maxValue")?.value);
    console.log(this.pressure2ValuesAlarmForm.get("minValue")?.value);
    console.log( this.pressure2ValuesAlarmForm.get("maxValue")?.value);

  }
}
