import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocManagerFolder } from 'src/app/models/docManager';
import { Modal } from 'src/app/models/modal';

@Component({
  selector: 'app-folder-media-add-quick',
  templateUrl: './folder-media-add-quick.component.html',
  styleUrls: ['./folder-media-add-quick.component.scss']
})
export class FolderMediaAddQuickComponent implements OnInit {

  folderOutput: DocManagerFolder = new DocManagerFolder();
  eventOnSubmit: boolean = false;

  constructor(private dialogRef: MatDialogRef<FolderMediaAddQuickComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Modal) { 

      if(this.data!.Data && this.data!.Data!.ParentFolder){
        this.folderOutput.ParentFolder = this.data!.Data!.ParentFolder;
      }else{
        this.folderOutput.ParentFolder = null; 
      }

      if(this.data!.Data && this.data!.Data!){
        this.folderOutput = this.data!.Data!;
      }

      if(this.data!.Data && this.data!.Data!.eventOnSubmit){
        this.eventOnSubmit = this.data!.Data!.eventOnSubmit;
      }
  
  }
      

  ngOnInit(): void {
  }

  emitFolder(folderOutput: DocManagerFolder){

    console.log('emitFolder' , folderOutput );
    this.folderOutput = folderOutput;
    this.dialogRef.close(folderOutput);
  }

  accept(){
    this.dialogRef.close();
  }

  cancel(){
    this.dialogRef.close(null);
  }

  onSubmit(){
    console.log(this.folderOutput);
  }

}
