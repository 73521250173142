<app-loading-custom [loading]="loading"></app-loading-custom>

<div class="form-group" *ngIf="userList">
    <label for="Client" class="text-right-label" *ngIf="nameField != ''">{{ nameField }}</label>
 
    <ng-multiselect-dropdown 
      id="Client" 
      [(ngModel)]="userListSelected"
      [placeholder]="placeholder" 
      [settings]="dropdownSettingsUser" 
      [data]="userList"
      (onSelect)="onItemSelect($event)"
      >

      <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
        <div style="padding:1px 10px;margin-right:10px;right:2px;top:-2px;cursor: pointer !important;">
          <label style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
            {{ getClient[id].Name??'' }} | {{ getClient[id].DNICIF??'' }} | {{ getClient[id].Role!.Tag??'' }}
          </label>
        </div>
      </ng-template>

      <ng-template #optionSelectedTemplate let-option="option" let-id="id">
        <div>
            {{ getClient[id].Name??'' }} | {{ getClient[id].DNICIF??'' }} | {{ getClient[id].Role!.Tag??'' }}
        </div>
      </ng-template>
    </ng-multiselect-dropdown>

  </div>