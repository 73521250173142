import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlarmDeviceModelDefaultValues, AlarmDeviceModelValues, AlarmValue } from 'src/app/models/alarms';
import { ListResult } from 'src/app/models/apiRequestObject';
import { Manufacturer } from 'src/app/models/user';
import { AlarmsService } from 'src/app/services/alarms.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { UsersService } from 'src/app/services/users.services';

@Component({
  selector: 'app-add-alarm-page',
  templateUrl: './add-alarm-page.component.html',
  styleUrls: ['./add-alarm-page.component.scss']
})
export class AddAlarmPageComponent implements OnInit {

  id?: number;
  loading:boolean = true;
  information?: AlarmDeviceModelValues;
  manufacturerList?: Manufacturer[];

  constructor(private breadcrumbService: BreadcrumbService,
    public authSrv: AuthenticationService,
    private usersSrv: UsersService,
    private alarmrSrv : AlarmsService,
    private route: ActivatedRoute,
    private translate: TranslateService) {
      breadcrumbService.setBreadCrumbUrl('alarms/list', this.translate.instant('modules.alarms.management'));

      this.route.params.subscribe(params => {
        this.id = params['id'];
        console.log(this.id)
        if(this.id){
          this.breadcrumbService.setBreadCrumbUrl('alarms/list', this.translate.instant('modules.alarms.management'));
          this.breadcrumbService.addBreadCrumb('alarms/add', this.translate.instant('modules.alarms.alarm') + ' #' + this.id??'' );


          this.information = new AlarmDeviceModelValues();
          this.information.Id = 0;
          this.information.AlarmDeviceModelDefaultValues = new AlarmDeviceModelDefaultValues();
          this.information.AlarmDeviceModelDefaultValues.defaultValues();

          this.information.AlarmValue =  new AlarmValue();
          this.information.AlarmValue.defaultValues();

          this.getManufacturers();

        }else{
          this.id = 0;
          this.breadcrumbService.setBreadCrumbUrl('alarms/list', this.translate.instant('modules.alarms.management'));
          this.breadcrumbService.addBreadCrumb('alarms/add', this.translate.instant('modules.alarms.new-alarm'));

          this.information = new AlarmDeviceModelValues();
          this.information.Id = 0;
          this.information.AlarmDeviceModelDefaultValues = new AlarmDeviceModelDefaultValues();
          this.information.AlarmDeviceModelDefaultValues.defaultValues();

          this.information.AlarmValue =  new AlarmValue();
          this.information.AlarmValue.defaultValues();

          this.getManufacturers();


          console.log(this.information);
        }

      });
    }



  ngOnInit(): void {
    this.loading = true;
  }

  getManufacturers(){
    this.loading = true;
    this.manufacturerList = undefined;
    this.usersSrv.getManufacturers(0, 10000, 'Name' , 'ASC' ).subscribe({
      next: (res: ListResult) => {

        this.manufacturerList = <Manufacturer[]>res.Result;
        console.log(this.manufacturerList);

      },error: (err: any) =>{
        console.log("error getClients");
      }, complete: () =>{
        this.loading = false;

      }
    });

  }

}
