<app-breadcrumb></app-breadcrumb>

<div class="main-page container-fluid pt-3 pl-0 pr-0 espace">
    <app-loading-custom [loading]="loading"></app-loading-custom>
    <app-block-group-dashboard *ngIf="loading == false">
        <ng-container *ngIf="user">
            <div class="col-12">
                <div class="my-4">
                    <div class="row align-items-center">
                        <div class="col-12">
                            <h1>
                                <user-icon [user] = "user" *ngIf="authSrv.isAdmin() == true"> </user-icon>
                                <b> {{'modules.shared.edit' | translate}}</b> {{ originalUser!.Name??'' }} {{ originalUser!.FirstName??''  }} {{ originalUser!.LastName??'' }}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <app-block-dashboard>
                <app-edit-user-form [pageInput]="page" [IdUser]="user!.Id" ></app-edit-user-form>
                <!--
                <mat-tab-group mat-align-tabs="start" [selectedIndex]="page" (selectedIndexChange)="page = $event">
                    <mat-tab *ngIf="authSrv.isAdmin() == true">
                        <ng-template mat-tab-label>
                            {{'modules.shared.general' | translate}}
                            <mat-icon class="tab-icon error-0 errorEdit" [hidden]="editFormSharedValid">error</mat-icon>
                        </ng-template>
                        <app-edit-user *ngIf="user" [user]="user" [page]="page" (editFormShared)="updateEditFormShared($event)"></app-edit-user>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            {{'modules.tables.contact' | translate}}
                            <mat-icon class="tab-icon  error-1 errorContact" [hidden]="contactFormSharedValid">error</mat-icon>
                        </ng-template>
                        <app-edit-user-contact *ngIf="user" [user]="user"  (contactFormShared)="updateContactFormShared($event)"></app-edit-user-contact>
                    </mat-tab>
                    <mat-tab >
                        <ng-template mat-tab-label>
                            {{'modules.tables.address' | translate}}
                            <mat-icon class="tab-icon  error-1 errorAddress" [hidden]="addressFormSharedValid">error</mat-icon>
                        </ng-template>
                        <app-edit-user-address *ngIf="user" [user]="user"  (addressFormShared)="updateAddressFormShared($event)"></app-edit-user-address>
                    </mat-tab>
                    <mat-tab *ngIf="authSrv.isAdmin() == true">
                        <ng-template mat-tab-label>
                            {{'modules.tables.theme' | translate}}
                            <mat-icon class="tab-icon  error-1 errorTheme" [hidden]="themeFormSharedValid">error</mat-icon>
                        </ng-template>
                        <app-edit-user-theme *ngIf="user" [user]="user"  (themeFormShared)="updateThemeFormShared($event)"></app-edit-user-theme>
                    </mat-tab>

                    <mat-tab *ngIf="authSrv.isAdmin() == true">
                        <ng-template mat-tab-label>
                            {{'modules.tables.more' | translate}}
                            <mat-icon class="tab-icon  error-1 errorSpecial" [hidden]="specialFormSharedValid">error</mat-icon>
                        </ng-template>
                        <app-edit-user-special *ngIf="user" [user]="user"  (specialFormShared)="updateSpecialFormShared($event)"></app-edit-user-special>
                    </mat-tab>

                    <mat-tab *ngIf="authSrv.isAdmin() == true">
                        <ng-template mat-tab-label>
                            {{'modules.devices.devices' | translate}}
                        </ng-template>
                        <app-edit-user-devices *ngIf="user" [user]="user" (devicesFormShared)="updateDevicesFormShared($event)"></app-edit-user-devices>
                    </mat-tab>

                    <mat-tab *ngIf="authSrv.isAdmin() == true && user && user!.Role && user!.Role!.Tag! == 'fabricant'">
                        <ng-template mat-tab-label>
                            {{'modules.devices.models' | translate}}
                        </ng-template>
                        <app-edit-user-device-models *ngIf="user" [user]="user"></app-edit-user-device-models>
                    </mat-tab>

                    <mat-tab *ngIf="authSrv.isAdmin() == true && user && user!.Role && user!.Role!.Tag! == 'user'">
                        <ng-template mat-tab-label>
                            {{'modules.devices.manufacturers' | translate}}
                        </ng-template>
                        <app-edit-user-clients *ngIf="user" [user]="user"></app-edit-user-clients>

                    </mat-tab>
                    <mat-tab *ngIf="authSrv.isAdmin() == true && user && user!.Role && user!.Role!.Tag! == 'fabricant'">
                        <ng-template mat-tab-label>
                            {{'modules.devices.clients' | translate}}
                        </ng-template>
                        <app-edit-user-clients *ngIf="user" [user]="user"></app-edit-user-clients>
                    </mat-tab>
                </mat-tab-group>


                <section class="mt-2">
                    <div class="text-right" style="flex-direction: row-reverse;">
                        <button  mat-raised-button class="btn btn-primary" (click)="onSubmit()">{{'modules.shared.save' | translate}}</button>
                    </div>
                </section>-->

            </app-block-dashboard>
        </ng-container>
    </app-block-group-dashboard>
</div>


<app-footer></app-footer>
