import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiRequestObject } from 'src/app/models/apiRequestObject';
import { Device } from 'src/app/models/device';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { DevicesService } from 'src/app/services/devices.service';

@Component({
  selector: 'app-console-device-page',
  templateUrl: './console-device-page.component.html',
  styleUrls: ['./console-device-page.component.scss']
})
export class ConsoleDevicePageComponent implements OnInit {

  id?: number;
  idAlert?: number;
  device?: Device;
  loading:boolean = true;
  resultApi?: ApiRequestObject;
  public page?: string;

  constructor(breadcrumbService: BreadcrumbService,
    private deviceSrv: DevicesService,
    private route: ActivatedRoute,
    private translate: TranslateService) {
      breadcrumbService.setBreadCrumbUrl('devices/list', this.translate.instant('modules.devices.management'));

      this.route.params.subscribe(params => {
        this.id = params['id'];
        this.idAlert = params['idAlert'];
        console.log(this.id)
        this.loadDevice(params['id']??'' );
        this.page = this.route.snapshot.paramMap.get('page')?? "";

      });
  }

  ngOnInit(): void {
    this.loading = true;
  }

  loadDevice(id: number){
    console.log('loadDevice: ' , id );
    this.deviceSrv.getDeviceById(id).subscribe({
      next: (res: Device) => {
        this.device = <Device>new Device(res);
        console.log(this.device );
        this.loading = false;
      }, error: (err: ApiRequestObject)=> {

        console.log(err);
        this.resultApi = err;
        this.loading = false;
      }
    });
  }

}
