import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RolesGuard } from 'src/app/guards/roles.guard';
import { DetailChartPageComponent } from './pages/detail-chart-page/detail-chart-page.component';

const routes: Routes = [
  {
    path: '',
    children:  [
      { path: ':id', component: DetailChartPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant', 'user'] }},
      { path: ':id/alert/:idAlert', component: DetailChartPageComponent, canActivate: [ RolesGuard ], data: { role: ['admin', 'fabricant', 'user'] }},

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChartsRoutingModule { }
