import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeviceModelAddQuickComponent } from 'src/app/modals/device-model-add-quick/device-model-add-quick.component';
import { vDeviceModel } from 'src/app/models/device';
import { Modal } from 'src/app/models/modal';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-edit-user-device-models',
  templateUrl: './edit-user-device-models.component.html',
  styleUrls: ['./edit-user-device-models.component.scss']
})
export class EditUserDeviceModelsComponent implements OnInit {

  @Input() user: User | null = null;
  loading:boolean = true;
  
  constructor() { }

  ngOnInit(): void {
    this.loading = false;
  }

  

}
