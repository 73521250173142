import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Modal } from 'src/app/models/modal';
import { Device, DeviceModel, vDeviceModel } from 'src/app/models/device';
import { User } from 'src/app/models/user';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-model-device-modal',
  templateUrl: './search-device-model-modal.component.html',
  styleUrls: ['./search-device-model-modal.component.scss']
})
export class SearchDeviceModelModalComponent implements OnInit {

  loading: boolean = true;
  noDataAvailablePlaceholderText: string = "";
  labelText: string = this.translate.instant('modules.modals.select-model');

  device: Device | null = null;
  deviceModel: DeviceModel | null = null;
  deviceModelsSelected: Array<vDeviceModel> | null = null;
  userAuth: User | null = null;

  constructor(
    private dialogRef: MatDialogRef<SearchDeviceModelModalComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: Modal) {

      console.log(this.data!.Data);
      this.device = this.data!.Data?new Device(this.data!.Data):null;
      this.deviceModel = this.device?.DeviceModel;
      this.userAuth = this.data.User?new User(this.data.User):null;

    }

  ngOnInit(): void {
    this.deviceModelsSelected = null;
  }


  updateModel(models: Array<vDeviceModel>){

    if(models){

      if(models.length > 0){
        this.deviceModelsSelected = models;
      }else{
        this.deviceModelsSelected = [];
      }
    }

    console.log(this.deviceModelsSelected );


    if(this.loading == true){
      this.loading = false;
    }
  }
  emit(modelEmit: Array<vDeviceModel> | null){

    console.log("emit" , modelEmit);
    if(modelEmit){
      this.loading = false;

      if(modelEmit.length > 0){

        console.log("SearchUserModalComponent", modelEmit);
        this.dialogRef.close(modelEmit);
      }

    }
  }
  accept(){
    this.dialogRef.close(this.deviceModelsSelected);
  }

  cancel(){
    this.dialogRef.close();
  }

}
