import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Modal } from 'src/app/models/modal';

@Component({
  selector: 'app-info-modal',
  templateUrl: './generating-modal.component.html',
  styleUrls: ['./generating-modal.component.scss']
})
export class GeneratingModalComponent implements OnInit {


  constructor(
    private dialogRef: MatDialogRef<GeneratingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Modal) { }

  ngOnInit(): void {
  }

}
