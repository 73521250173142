<app-loading-custom [loading]="loading"></app-loading-custom>


<ng-container *ngIf="loading == false && user && user!.vDevices">

  <form class="text-start p-2" [formGroup]="devicesForm" (ngSubmit)="onSubmit()" autocomplete="off">

    <ng-container *ngIf="!user!.isClient">

      <h2 class="text-center mt-3 mb-3">{{'modules.devices.devices' | translate}}</h2>
      <!-- <div class="text-right-label" style="flex-direction: row-reverse">  <span class="c-pointer" (click)="newDevice()">{{'modules.devices.exist' | translate}}</span></div> -->
      <mat-card fxFlex fxLayoutAlign="start center" fxLayout="column" class="solution" [style.borderRadius.px]="'0'"
        [style.paddingTop.px]="'20'" [style.marginBottom.px]="'20'" style="min-height: 400px;" formGroupName="Devices">
    
        <app-list-devices [userInput]="user"></app-list-devices>
        
    
      </mat-card>
    
    </ng-container>
    
    <ng-container *ngIf="user?.isClient">
      <h2 class="text-center mt-3 mb-3">{{'modules.devices.devices' | translate}}</h2>

      
      <mat-card fxFlex fxLayoutAlign="start center" fxLayout="column" class="solution" [style.borderRadius.px]="'0'"
        [style.paddingTop.px]="'20'" [style.marginBottom.px]="'20'" style="min-height: 400px;" formGroupName="Devices">


        <div class="form-group" *ngIf="devices">
          <label for="DeviceModel" class="text-right-label">{{'modules.devices.associated-devices' | translate}} <span class="c-pointer" (click)="newDevice()">{{'modules.devices.exist' | translate}}</span></label>

          <ng-multiselect-dropdown 
            id="vDevices" 
            formControlName="vDevices" 
            [(ngModel)]="user!.vDevices"
            [placeholder]="'modules.shared.search-imei' | translate" 
            [settings]="dropdownSettings" 
            [data]="devices"
            >

            <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
              <div style="padding:1px 10px;margin-right:10px;right:2px;top:-2px;cursor: pointer !important; " >
                <label style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;" *ngIf="user!.isClient" >
                  {{ getDevice[id].Name??'{' + ('modules.devices.no-name2' | translate) +'}' }} | {{ getDevice[id].IMEI??'{' + ('modules.devices.no-imei' | translate) +'}' }} - {{ getDevice[id].NameManufacturer??'{' + ('modules.devices.no-account' | translate) +'}' }} | {{ getDevice[id].NameDeviceModel??'{' + ('modules.devices.no-model' | translate) +'}' }}
                </label>
                
                <label style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;" *ngIf="user!.isManufacturer">
                  {{ getDevice[id].Name??'{' + ('modules.devices.no-name2' | translate) +'}' }} | {{ getDevice[id].IMEI??'{' + ('modules.devices.no-imei' | translate) +'}' }} - {{ getDevice[id].NameClient??'{' + ('modules.devices.no-user' | translate) +'}' }}
                </label>
              </div>
            </ng-template>

            <ng-template #optionSelectedTemplate let-option="option" let-id="id">
              <div *ngIf="user!.isClient" >
                {{ getDevice[id].Name??'{' + ('modules.devices.no-name2' | translate) +'}' }} | {{ getDevice[id].IMEI??'{' + ('modules.devices.no-imei' | translate) +'}' }} - {{ getDevice[id].NameManufacturer??'{' + ('modules.devices.no-account' | translate) +'}' }} | {{ getDevice[id].NameDeviceModel??'{' + ('modules.devices.no-model' | translate) +'}' }}
              </div>
              <div *ngIf="user!.isManufacturer">
                {{ getDevice[id].Name??'{' + ('modules.devices.no-name2' | translate) +'}' }} | {{ getDevice[id].IMEI??'{' + ('modules.devices.no-imei' | translate) +'}' }} - | {{ getDevice[id].NameClient??'{' + ('modules.devices.no-user' | translate) +'}' }}
              </div>
            </ng-template>
          </ng-multiselect-dropdown>

        </div>

      </mat-card>
    </ng-container>

  </form>


</ng-container>

