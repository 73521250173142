import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { ApiRequestObject, ListResult } from '../models/apiRequestObject';
import { User } from '../models/user';
import { Device, DeviceModel, vDevice, vDeviceModel } from '../models/device';
import { Router } from '@angular/router';
import { ɵNullViewportScroller } from '@angular/common';

/*
@Injectable({
  providedIn: 'root'
})*/
export abstract class ApiService {

  constructor(private router: Router, private authSrv: AuthenticationService) { }

  // Error handling
  error(error: HttpErrorResponse) {

    console.log(error);

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    }
    let isModal : boolean = false;
    let errorMessage = '';

    if (error.error && error.error instanceof ErrorEvent) {
      errorMessage = error.error.message??'';
    } else if(error.error && error.error.modal){
      errorMessage = error.error.modal;
      isModal = true;
    }else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

      if(error.status == 401 || error.status == 0){
        // TODO
        //this.authSrv.logout();
        //this.router.navigate(['/auth/login']);

      }
    }

    console.log(errorMessage);

    let res : ApiRequestObject;

    if(isModal){
      res = ApiRequestObject.modal(errorMessage, error.status);
    }else{
      res = ApiRequestObject.errorServer(errorMessage, error.status);
    }
    console.log(error.error);

    res.title = error.error?.title??null;
    res.link = error.error?.link??null;
    res.id = error.error?.id??null;

    res.code = error.status;
    console.log(res);

    return throwError(() => res);
  }


}
