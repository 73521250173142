import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { ApiRequestObject, ListResult } from '../models/apiRequestObject';
import { User } from '../models/user';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { AlarmDeviceModelDefaultValues, AlarmDeviceModelValues, AlarmDeviceValues } from '../models/alarms';
import { Device, DeviceModel } from '../models/device';

@Injectable({
  providedIn: 'root'
})
export class AlarmsService extends ApiService {

  constructor(private http: HttpClient, router: Router, authSrv: AuthenticationService) {
    super(router, authSrv);
  }


  getListAllAlarm(page: number, offset: number, order: string, direction: string, filter?: string, device?: Device, modelDevice?: DeviceModel, showDefaultValues: boolean = true): Observable<ListResult> {

    let deviceFilter = ""
    if (device) {
      deviceFilter = "&DeviceId=" + device.Id
    }

    let deviceModelFilter = ""
    if (modelDevice) {
      deviceModelFilter = "&DeviceModelId=" + modelDevice.Id
      if (device == null) {
        deviceModelFilter += "&ShowDeviceValues=" + false
      }
    }
    let showDefaultValuesFilter = ""
    if (showDefaultValues == false) {
      showDefaultValuesFilter = "&ShowDefaultValues=" + false
    }

    if (!filter) {
      filter = "";
    }

    let url = `${environment.API_ENDPOINT}alarms/all/liteList/${page}/${offset}/${order}/${direction}?filter=${filter}${deviceFilter}${deviceModelFilter}${showDefaultValuesFilter}`;


    return this.http.get<ListResult>(url).pipe(
      catchError(this.error)
    );
  }

  getListByDevice(page: number, offset: number, order: string, direction: string, filter?: string, device?: Device): Observable<ListResult> {

    let deviceFilter = ""
    if (device) {
      deviceFilter = "&DeviceId=" + device.Id
    }
    if (!filter) {
      filter = "";
    }

    let url = `${environment.API_ENDPOINT}alarms/devices/liteList/${page}/${offset}/${order}/${direction}?filter=${filter}${deviceFilter}`;


    return this.http.get<ListResult>(url).pipe(
      catchError(this.error)
    );
  }

  getListByModel(page: number, offset: number, order: string, direction: string, filter?: string, user?: User): Observable<ListResult> {

    let userFilter = ""
    if (user) {
      userFilter = "&UserId=" + user.Id
    }
    if (!filter) {
      filter = "";
    }

    let url = `${environment.API_ENDPOINT}alarms/models/liteList/${page}/${offset}/${order}/${direction}?filter=${filter}${userFilter}`;


    return this.http.get<ListResult>(url).pipe(
      catchError(this.error)
    );
  }

  getListDefault(page: number, offset: number, order: string, direction: string, filter?: string, user?: User): Observable<ListResult> {

    let userFilter = ""
    if (user) {
      userFilter = "&UserId=" + user.Id
    }
    if (!filter) {
      filter = "";
    }

    let url = `${environment.API_ENDPOINT}alarms/liteList/default/${page}/${offset}/${order}/${direction}?filter=${filter}${userFilter}`;


    return this.http.get<ListResult>(url).pipe(
      catchError(this.error)
    );
  }

  getDeviceValuesById(id: number) {
    return this.http.get<AlarmDeviceValues>(`${environment.API_ENDPOINT}alarm/device/${id}`).pipe(
      catchError(this.error)
    );
  }

  getModelsValuesById(id: number) {
    return this.http.get<AlarmDeviceModelValues>(`${environment.API_ENDPOINT}alarm/model/${id}`).pipe(
      catchError(this.error)
    );
  }

  getDefaultValuesById(id: number) {
    return this.http.get<AlarmDeviceModelDefaultValues>(`${environment.API_ENDPOINT}alarm/default/${id}`).pipe(
      catchError(this.error)
    );
  }

  getDefaultValuesByModelId(id: number): Observable<Array<AlarmDeviceModelDefaultValues>> {

    return this.http.get<Array<AlarmDeviceModelDefaultValues>>(`${environment.API_ENDPOINT}alarm/default?model=${id}`).pipe(
      catchError(this.error)
    );

  }

  changeDefaultValueAlarmById(alarm: AlarmDeviceModelDefaultValues): Observable<AlarmDeviceModelDefaultValues> {

    return this.http.patch<AlarmDeviceModelDefaultValues>(`${environment.API_ENDPOINT}alarm/default/${alarm.Id}`, alarm).pipe(
      catchError(this.error)
    );
  }
  changeModelValueAlarmById(alarm: AlarmDeviceModelValues): Observable<AlarmDeviceModelValues> {

    return this.http.patch<AlarmDeviceModelValues>(`${environment.API_ENDPOINT}alarm/model/${alarm.Id}`, alarm).pipe(
      catchError(this.error)
    );
  }

  changeDeviceValueAlarmById(alarm: AlarmDeviceValues): Observable<AlarmDeviceValues> {

    return this.http.patch<AlarmDeviceValues>(`${environment.API_ENDPOINT}alarm/device/${alarm.Id}`, alarm).pipe(
      catchError(this.error)
    );
  }

  createAlarmByModel(alarmDeviceModelValues: AlarmDeviceModelValues): Observable<AlarmDeviceModelValues> {

    return this.http.post<AlarmDeviceModelValues>(`${environment.API_ENDPOINT}alarm/model`, alarmDeviceModelValues).pipe(
      catchError(this.error)
    );
  }
  createAlarmByDevice(alarmDeviceModelValues: AlarmDeviceValues): Observable<AlarmDeviceValues> {

    return this.http.post<AlarmDeviceValues>(`${environment.API_ENDPOINT}alarm/device`, alarmDeviceModelValues).pipe(
      catchError(this.error)
    );
  }

  createDefaultValueAlarm(alarmDeviceModelDefaultValues: AlarmDeviceModelDefaultValues): Observable<AlarmDeviceModelDefaultValues> {

    return this.http.post<AlarmDeviceModelDefaultValues>(`${environment.API_ENDPOINT}alarm/default`, alarmDeviceModelDefaultValues).pipe(
      catchError(this.error)
    );

  }



  deleteByDevice(alarm: AlarmDeviceValues): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.API_ENDPOINT}alarm/device/${alarm.Id}`).pipe(
      catchError(this.error)
    );
  }

  deleteByModel(alarm: AlarmDeviceModelValues): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.API_ENDPOINT}alarm/model/${alarm.Id}`).pipe(
      catchError(this.error)
    );
  }

  deleteByModelDefault(alarm: AlarmDeviceModelDefaultValues): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.API_ENDPOINT}alarm/default/${alarm.Id}`).pipe(
      catchError(this.error)
    );
  }

  /* Notifications / Alerts */

  processRecords(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.API_ENDPOINT}notifications/processRecords`).pipe(
      catchError(this.error)
    );
  }

}
