<app-loading-custom [loading]="loading"></app-loading-custom>


<ng-container *ngIf="loading == false">

  <form class="text-start p-2" [formGroup]="traceForm" autocomplete="off" >

    <mat-card fxFlex fxLayoutAlign="start center" fxLayout="column" class="solution"
        [style.borderRadius.px]="'0'"  [style.paddingTop.px]="'20'" [style.marginBottom.px]="'20'" formGroupName="Trace">


        <div class="form-group">
          <label for="IMEI">IMEI</label>
          <input type="text" class="form-control" id="IMEI"  formControlName="IMEI" required [(ngModel)]="simulateTrace!.IMEI">
        </div>

        <div class="form-group">
            <label for="RealDate">{{'modules.alarms.date-trace' | translate}}</label>
            <div style="display:inline-block; width: 20px;"></div>
            <input class="form-control" type="datetime-local" placeholder="start date" id="RealDate" required formControlName="RealDate" [(ngModel)]="simulateTrace!.TrackPosition!.Date">
        </div>

        <div class="form-group">
          <label for="Sensor">Sensor</label>
          <input type="text" class="form-control" id="Sensor"  formControlName="Sensor" required [(ngModel)]="sensor">
        </div>

        <div class="form-group">
          <label for="Name">Value</label>
          <input inputOnlyNumber type="text" class="form-control" id="Value"  formControlName="Value" required [(ngModel)]="value">
        </div>



        <section class="mt-2">


          <div class="text-right" style="flex-direction: row-reverse;">

            <app-generic-button txtBtn="{{'modules.alarms.simulate-trace' | translate}}" title="{{'modules.shared.warning' | translate}}" [txt]="('modules.alarms.are-you-sure-trace-simulation' | translate) + ': ' + simulateTrace!.IMEI +'?'"
              [disabled]="(IsValidForm)? null :'disabled'"
              btnAccept="{{'modules.alarms.simulate-trace' | translate}}" (confirmDelete)="sendTrace()" ></app-generic-button>

              <!-- <button  mat-raised-button class="btn btn-primary" [disabled]="(IsValidForm)? null :'disabled'"  (click)="sendTrace()">{{'modules.shared.send' | translate}}</button> -->
          </div>
        </section>






    </mat-card>

    </form>

</ng-container>
