import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Role, User, vUser } from 'src/app/models/user';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ListResult } from 'src/app/models/apiRequestObject';
import { UsersService } from 'src/app/services/users.services';
import { Device, DeviceModel, vDeviceModel } from 'src/app/models/device';
import { DevicesService } from 'src/app/services/devices.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-device-models',
  templateUrl: './search-device-models.component.html',
  styleUrls: ['./search-device-models.component.scss']
})
export class SearchDeviceModelsComponent implements OnInit {

  @Input() deviceModelInput: DeviceModel | null = null;
  @Input() userInput: User | null = null;
  @Input() roleInput: string | null = null;

  @Output() deviceModelsOutput = new EventEmitter<Array<vDeviceModel>>();


  @Input() nameField: string = "";
  @Input() placeholder: string = "";
  @Input() idField: string = "Id";
  @Input() textField: string = "Name";
  @Input() noDataAvailablePlaceholderText: string = this.translate.instant('modules.shared.no-records');
  @Input() autoClose: boolean = true;

  dropdownSettingsUser : IDropdownSettings= {};
  loading: boolean = false;

  public deviceModelList: Array<vDeviceModel> | null= null;
  public deviceModelListSelected: Array<vDeviceModel> = [];

  constructor(
    private deviceSrv: DevicesService,
    private translate: TranslateService) { }

  ngOnInit(): void {

    this.loading = true;

    this.dropdownSettingsUser = {
      singleSelection: true,
      idField: this.idField,
      textField: this.textField,
      selectAllText: this.translate.instant('modules.shared.select-all'),
      unSelectAllText: this.translate.instant('modules.shared.unselect-all'),
      allowSearchFilter: true,
      closeDropDownOnSelection: this.autoClose,
      //noFilteredDataAvailablePlaceholderText: "No hay coincidencias",
      noDataAvailablePlaceholderText: this.noDataAvailablePlaceholderText,
      searchPlaceholderText: this.translate.instant('modules.shared.search'),
      clearSearchFilter: true,
    };

    console.log("getDeviceModels");

    this.getDeviceModels();


  }

  getDeviceModels(){
    this.deviceSrv.getModelLiteList(0, 10000, 'Name' , 'ASC'  ).subscribe({
      next: (res: ListResult) => {

        this.deviceModelList = <vDeviceModel[]>res.Result;
        console.log("getUsersByRole");

        /*if(this.isClient){
          this.newDevice.IdUser = this.userInput!.Id;
          this.newDevice.Client = [ this.userInput! ];
        }*/
        this.emitUsers(this.deviceModelListSelected);

      },error: (err: any) =>{
        console.log("error getClients");
      }, complete: () =>{
        this.loading = false;
      }
    });
  }


  get getModel() {
    return this.deviceModelList!.reduce((acc:any , curr: vDeviceModel) => {
      acc[curr.Id!] = curr;
      return acc;
    }, {});
  }

  onItemSelect(items: any) {
    this.emitUsers(this.deviceModelListSelected);
  }

  private emitUsers(resultDeviceModel: Array<vDeviceModel>) {
    this.deviceModelsOutput.emit(resultDeviceModel);
  }

}
