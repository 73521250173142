import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RolesGuard } from 'src/app/guards/roles.guard';
import { DetailNotificationPageComponent } from './pages/detail-notification-page/detail-notification-page.component';
import { ListNotificationsPageComponent } from './pages/list-notifications-page/list-notifications-page.component';

const routes: Routes = [
  {
    path: '',
    children:  [
      { path: 'list', component: ListNotificationsPageComponent},
      { path: ':id/detail', component: DetailNotificationPageComponent},
    ]
}
];

@NgModule({
imports: [RouterModule.forChild(routes)],
exports: [RouterModule]
})
export class NotificationsRoutingModule { }
