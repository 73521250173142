import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { User } from 'src/app/models/user';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { DatatableColumn, DatatableConfig } from 'src/app/models/datatableColumn';
import { Device, vDeviceModel } from 'src/app/models/device';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.services';
import { DevicesService } from 'src/app/services/devices.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ApiRequestObject, ListResult } from 'src/app/models/apiRequestObject';
import { LinkModal, Modal } from 'src/app/models/modal';
import { ConfirmationModalComponent } from 'src/app/modals/confirmation-modal/confirmation-modal.component';
import { DeviceModelAddQuickComponent } from 'src/app/modals/device-model-add-quick/device-model-add-quick.component';
import { InfoModalComponent } from 'src/app/modals/info-modal/info-modal.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

declare const $: any

@Component({
  selector: 'app-list-device-models',
  templateUrl: './list-device-models.component.html',
  styleUrls: ['./list-device-models.component.scss']
})
export class ListDeviceModelsComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() showNewModel: boolean = true;
  @Input() userInput: User | null = null;


  @Input() isManufacturer: boolean | null = null;

  @ViewChild(DataTableDirective, {static: false}) datatableElement?: DataTableDirective;

  /* Datatable parameters */
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  nameDatatable: string = "DeviceModelList";
  isDtInitialized: boolean = false;
  datatableConfigColumns: DatatableConfig = new DatatableConfig();
  viewPaging: boolean = true;

  selectedItems: boolean = false;
  listaDispositivoModels: vDeviceModel[] = [];
  selectedModelsList: vDeviceModel[] = [];
  selectedState: number = 1;
  loading: boolean = true;
  loadingDelete: boolean = false;


  get isViewManufacturer(): boolean{

    if(this.userInput){
      return this.userInput.isManufacturer;
    }
    return false;
  }

  get isViewClient(): boolean{

    if(this.userInput){
      return this.userInput.isClient;
    }
    return false;
  }

  constructor(
    public dialog: MatDialog,
    private router : Router,
    private usersSrv : UsersService,
    private deviceSrv : DevicesService,
    private utilsSrv : UtilsService,
    private translate: TranslateService) { }

  ngAfterViewInit(): void {
    this.dtTrigger.next(0);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngOnInit(): void {

    this.dtOptions = this.optionsDatatable();
  }

  public optionsDatatable() {

    this.datatableConfigColumns.headerTable = [
      new DatatableColumn(0,"#select", "", "asc"),
      new DatatableColumn(1,"Id", "Id", "desc"),
      new DatatableColumn(2,this.translate.instant('modules.tables.model-device'), "Name", "asc"),
      new DatatableColumn(3,this.translate.instant('modules.tables.account'), "NameManufacturer", "asc"),
      new DatatableColumn(4,this.translate.instant('modules.tables.created-at'), "CreatedAt", "desc"),
      new DatatableColumn(5,this.translate.instant('modules.tables.update'), "UpdatedAt", "desc"),
      new DatatableColumn(6,this.translate.instant('modules.tables.num-devices'), "NumDevices", "desc"),
      new DatatableColumn(7,this.translate.instant('modules.tables.actions'), "", "asc")
    ];

    if( (this.isManufacturer != null && this.isManufacturer == true) || (this.userInput != null && this.userInput.isManufacturer == true) ){
      this.datatableConfigColumns.hideColumnByTag("NameManufacturer");
    }

    this.datatableConfigColumns.hideColumnByTag("UpdatedAt");

    this.datatableConfigColumns.changeOrderByTag("CreatedAt");
    this.datatableConfigColumns.orderDesc();

    const that = this;

    return {
      // Use this attribute to enable the responsive extension
      name: this.nameDatatable,
      //order: [ [ "asc", 0 ]],
      order: this.datatableConfigColumns.order,
      columnDefs: this.datatableConfigColumns.columnDefs,
      /*
      columnDefs: [
        { orderable: false, targets: [6,7 ]},
        { orderSequence: ["desc", "asc"], targets: [0,3] }
      ],*/
      lengthChange: true,
      serverSide: true,
      processing: false,
      retrieve: true,
      //stateSave: true,
      deferRender: true,
      // Declare the use of the extension in the dom parameter
      //dom: 'Bfrtip',
      //select: true,
      initComplete: (settings: DataTables.Settings, json: any) => {
        $("#" + this.nameDatatable).wrap("<div class='demo' style='overflow:auto; width:100%;position:relative;'></div>");
        if(!this.viewPaging){
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().parent().find("div").eq(0).removeClass("col-md-5").addClass("col-md-12");
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().css("display", "none" );
        }else{
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().parent().find("div").eq(0).removeClass("col-md-12").addClass("col-md-5");
          $("#" + this.nameDatatable + "_wrapper .pagination").parent().parent().css("display", "block" );
        }
      },
      ajax: (dataTablesParameters: any, callback: any) => {

        that.loading = true;

        let column = dataTablesParameters.order[0].column;
        let colmunOrder = this.datatableConfigColumns.getColumnById(column);
        let colmunOrderTag = colmunOrder?.tag!;
        let order = dataTablesParameters.order[0].dir;
        let draw = dataTablesParameters.draw;

        dataTablesParameters.search.regex = true
        let page = parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length);
        this.listaDispositivoModels = [];


        console.log(colmunOrder);

        that.deviceSrv.getModelLiteList(page, dataTablesParameters.length, colmunOrderTag, order, dataTablesParameters.search.value, this.userInput??undefined).subscribe(
          {
            next: (res: ListResult) => {

              //that.listaUsuarios = res.result.map(x => new User (x));
              that.listaDispositivoModels = <vDeviceModel[]>res.Result.map( x => new vDeviceModel(x));

              // Activa los checklist de los modelos seleccionados entre paginas
              for (let index = 0; index < this.listaDispositivoModels.length; index++) {
                const modelo = this.listaDispositivoModels[index];
                if (this.selectedModelsList.some(selModel => selModel.Id === modelo.Id)) {
                  modelo.Selected = true;
                }
              }
              this.checkSelectedState();

            callback({
              recordsTotal: res.Total,
              recordsFiltered: res.Filter,
              data: []
            });

            this.viewPaging = false;
            if(res.Filter > 10){
              this.viewPaging = true;
            }

            this.selectedItems = false;
            that.loading = false;

            if (res.Result.length > 0) {
              $('.dataTables_empty').css('display', 'none');
            }
            that.columnAdjust();

          },
          error: ( err : any) => {
            that.loading = false;
          }
        });


      },
      infoCallback: function( settings: any, start:number, end:number, max:number, total:number, pre: any ) {

        return that.datatableConfigColumns.DatatablesFooterShowText(settings, start,end, max, total , pre );
        /*if(end <= max){
          if(end==1){
            return "Mostrando "+end+" registros.";
          }
          return "Mostrando "+end+" registros.";
        }
        return "Mostrando registros del " + start + " al " + end +" de un total de " + total + " registros";
        */
      },
      /*
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        console.log("click");

        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)

        $('td:first-child', row).unbind('click');
        $('td:first-child', row).bind('click', () => {
          console.log("click");
          //self.someClickHandler(row, data, index);
        });
        return row;
      },*/
      headerCallback: (thead: any, data: any, start: any, end: any, display: any) => {
      },
      language: {
        url: this.utilsSrv.DatatablesFileLang()
      }
    };

  }

  handleDelete(){

  }

  columnAdjust(): void {
    $($.fn.dataTable.tables(true)).DataTable().columns.adjust().responsive.recalc();
  }

  deleteAll(){
    let allowedModels = this.selectedModelsList.filter(m => m.NumDevices === 0 && m.Name != environment.NameDeviceModelMain);
    let notAllowedModels = this.selectedModelsList.filter(m => m.NumDevices! > 0 ||  m.Name == environment.NameDeviceModelMain );
    let text1 = `${this.translate.instant('modules.modals.delete-models')}:
                <ul>
                ${allowedModels.map(m => '<li>#' + m.Id + ' - ' + m.Name) + '</li>'}
                </ul>`;
    let text2 = `${this.translate.instant('modules.modals.cannot-delete-models')}:
                <ul>
                ${notAllowedModels.map(m => '<li>#' + m.Id + ' - ' + m.Name) + '</li>'}
                </ul>`;

    let bodyText =  (allowedModels.length > 0) ? text1 + ((notAllowedModels.length > 0) ? text2 : '') : (notAllowedModels.length > 0) ? text2 : '';

    let modal = new Modal();
    modal.Title = this.translate.instant('modules.shared.warning');
    modal.Body = bodyText;
    modal.BtnAccept = this.translate.instant('modules.shared.delete');
    modal.BtnCancel = this.translate.instant('modules.shared.cancel');
    modal.ClassType = "danger";

    const dialogRef = this.dialog.open(
      ConfirmationModalComponent,
      {
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: modal,
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'
    });

    // Desabilita botó 'Eliminar' si no hi ha elements a eliminar
    if (allowedModels.length === 0) {
      const btn = document.querySelector('.btn-primary') as HTMLButtonElement | null;
      btn?.setAttribute('disabled', '');
    }

    dialogRef.afterClosed().subscribe( (result: boolean) => {
      if(result){

        let contador = allowedModels.length;
        this.loadingDelete = true;

        for (let index = 0; index < allowedModels.length; index++) {
          const device = allowedModels[index];

          this.deviceSrv.deleteModel(device).subscribe({
            next: (res: boolean) => {
              contador--;
              if(res == true){
                allowedModels.splice(allowedModels.indexOf(device), 1);
              }
              this.checkDeleteAll(contador, allowedModels);
            },
            error: (err: ApiRequestObject) =>{
              contador--;
              this.checkDeleteAll(contador, allowedModels);
            }
          });
        }
      }
    });
  }

  checkDeleteAll(contador: number, items: Array<vDeviceModel>): void {
    items = Object.assign([], items)
    // console.log(contador);
    // console.log(items);

    if (contador === 0) {
      if (items.length > 0) {
        // console.log('error');
        let link: LinkModal= new LinkModal(
          { 'Text': this.translate.instant('modules.modals.here'),
          'Link': 'devices/models/' + items[0].Id  + '/detail',
          'PreText': this.translate.instant('modules.modals.click-for') + ' ' ,
          'PostText':' ' + this.translate.instant('modules.modals.review-devices') + '.'
        });
        this.utilsSrv.infoModal('error',link);
        this.loadingDelete = false;
      }
      else if (this.loadingDelete == true) {
        this.loadingDelete = false;
        // console.log("finalizada eliminación masiva");
        this.rerender();
      }
      else {
        this.loadingDelete = false;
      }
    }
  }

  deleteItem(device: vDeviceModel){
    this.checkuncheckall(device);

    let modal = new Modal();
    modal.Title = this.translate.instant('modules.shared.warning');
    modal.Body = this.translate.instant('modules.modals.delete-model') + " # " + device.Id + " " + device.Name??'';
    modal.BtnAccept = this.translate.instant('modules.shared.delete')
    modal.BtnCancel = this.translate.instant('modules.shared.cancel');
    modal.ClassType = "danger";

    const dialogRef = this.dialog.open(
      ConfirmationModalComponent,
      {
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: modal,
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe( (result: boolean) => {
      if(result){

        this.loadingDelete = true;

        this.deviceSrv.deleteModel(device).subscribe({
          next: (res: boolean) => {
            if(res == true){
             // this.dtTrigger.next(0);
              this.rerender();
            }
          },
          error: (err: ApiRequestObject) =>{

            if(err.status! == "MODAL"){
              let link: LinkModal= new LinkModal(
                { 'Text': this.translate.instant('modules.modals.here'),
                'Link': 'devices/models/' + device.Id  + '/detail',
                'PreText': this.translate.instant('modules.modals.click-for') + ' ' ,
                'PostText':' ' + this.translate.instant('modules.modals.review-devices') + '.'
              });
              this.utilsSrv.infoModal(err.msg!,(err.link != 'none')?link:undefined);
            }
            this.loadingDelete = false;

          },
          complete: () =>{
            this.loadingDelete = false;
          }
        });



        // console.log("eliminar dispositivo " , device.Name , " " , device.Id)
      }
    });
  }

  rerender(){

    // Buida la llista d'elemens seleccionats
    this.selectedModelsList = [];

    this.datatableElement!.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.clear().destroy();
      this.dtTrigger.next(0);
    });
  }

  checkuncheckall(device: vDeviceModel)
  {
    device.Selected = !device.Selected;

    this.selectedItems = false;
    for(let i = 0; i < this.listaDispositivoModels.length; i++){
      if(this.listaDispositivoModels[i].Selected == true){
        this.selectedItems = true;
        break;
      }
    }
    if (device.Selected == true) {
      if (!this.selectedModelsList.some(model => model.Id === device.Id)) {
        this.selectedModelsList.push(device);
      }
    } else {
      this.selectedModelsList.splice(this.selectedModelsList.findIndex(model => model.Id === device.Id), 1);
    }
    this.checkSelectedState();
  }

  selectUnselectAll() {
    if (this.listaDispositivoModels.some( m => m.Selected === false )) {
      if (this.selectedModelsList.length === 0){
        this.selectedModelsList = [...this.listaDispositivoModels];
        for (let index = 0; index < this.listaDispositivoModels.length; index++) {
          this.listaDispositivoModels[index].Selected = true;
        }
      } else {
        for (let index = 0; index < this.listaDispositivoModels.length; index++) {
          const unique = this.selectedModelsList.findIndex(model => model.Id === this.listaDispositivoModels[index].Id);
          if (unique === -1) {
            this.selectedModelsList.push(this.listaDispositivoModels[index]);
            this.selectedModelsList[ this.selectedModelsList.length-1].Selected = true;
          }
        }
      }
      this.selectedItems = true;
    } else {
      for (let index = 0; index < this.listaDispositivoModels.length; index++) {
        this.listaDispositivoModels[index].Selected = false;
        this.selectedModelsList.splice(this.selectedModelsList.findIndex(model => model.Id === this.listaDispositivoModels[index].Id), 1);
      }
      this.selectedItems = false;
    }
    this.checkSelectedState();
  }

  checkSelectedState() {
    if (this.selectedModelsList.length === 0) {
      this.selectedState = 1;
    } else if (!this.listaDispositivoModels.some( m => m.Selected === false )) {
      this.selectedState = 2
    } else {
      this.selectedState = 3;
    }
  }

  newDeviceModel()
  {
    let data;
    if(this.userInput){
      data = { 'Data' : { "idUser" : this.userInput!.Id }, 'User' : this.userInput! };
    }

    const dialogRef = this.dialog.open(
      DeviceModelAddQuickComponent,
      {
        id: 'DeviceModelAddQuickComponent',
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: new Modal(data),
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe( (result?: vDeviceModel) => {
      if(result){
        this.rerender();

      }

    });
  }

}
