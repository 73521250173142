<app-loading-custom [loading]="loading"></app-loading-custom>

<div *ngIf="showTitle">
    <h1 class=""><b>{{'modules.shared.chart' | translate}} {{ device.Name??'~' }}</b> {{'modules.shared.with' | translate}} IMEI: {{ device.IMEI }}</h1>
    <h2 *ngIf="idAlert" class=""><b>{{'modules.shared.alert' | translate}} {{ idAlert }}</b></h2>
    <h4 class="mb-4"><b>{{'modules.tables.account' | translate}}:</b> {{ device.Manufacturer.Name }} - {{'modules.alarms.model' | translate}}: {{ device.DeviceModel.Name }} - {{'modules.users.user' | translate}}: {{ device.User?.Name }}</h4>

    <div class="my-4">
      <div class="main main-box">
        <div class="row">
          <div class="col-md-6 mb-3" *ngIf="device?.InformationFirst">
            <div><strong>{{'modules.shared.first-data-recorded' | translate}}:</strong></div>
            <div>{{ device.InformationFirst | date: "dd-MM-yyyy HH:mm"  }}</div>
          </div>
          <div class="col-md-6 mb-3" *ngIf="device?.InformationLast">
            <div><strong>{{'modules.shared.last-data-recorded' | translate}}:</strong></div>
            <div>{{ device.InformationLast | date: "dd-MM-yyyy HH:mm" }}</div>
          </div>
          <div class="col-md-6 mb-3" *ngIf="device?.InformationLast == null">
            <div><strong>{{'modules.devices.no-data-recorded' | translate}}.</strong></div>
          </div>
          <div>{{ dataMaxChart }}</div>
        </div>
      </div>
    </div>

    <!-- <span *ngIf="device?.InformationFirst"><strong>{{'modules.shared.first-data-recorded' | translate}}:</strong>  {{ device?.InformationFirst | date: "dd-MM-yyyy HH:mm"  }} </span>
    <span *ngIf="device?.InformationLast"><strong>{{'modules.shared.last-data-recorded' | translate}}:</strong> {{ device?.InformationLast | date: "dd-MM-yyyy HH:mm" }} </span>
    <span *ngIf="device?.InformationLast == null"><strong>{{'modules.devices.no-data-recorded' | translate}}.</strong> </span> -->

</div>

  <div *ngIf="loading == false && device?.InformationFirst" class="grid-container">
    <form class="text-start p-2" [formGroup]="filterChartForm"  autocomplete="off" >
      <div>
        <div class="mr-2 ml-2 pl-3">
          <button mat-fab class="my-fab" aria-label="Filtrar" (click)="reloadInfo()">
            <mat-icon>search</mat-icon>
          </button>
        </div>
        <div class="mr-2 pl-3">
          <mat-form-field class="form-field" appearance="fill">
              <mat-label>{{'modules.shared.time-frame' | translate}}</mat-label>
              <mat-date-range-input
              [rangePicker]="campaignOnePicker"
              [comparisonStart]="InformationFirstValueZoom"
              [comparisonEnd]="InformationLastValueZoom">
              <input matStartDate placeholder="{{'modules.shared.start-data' | translate}}" formControlName="InformationFirst" [(ngModel)]="InformationFirstValue" >
              <input matEndDate placeholder="{{'modules.shared.end-data' | translate}}" formControlName="InformationLast" [(ngModel)]="InformationLastValue" >
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="campaignOnePicker" ></mat-datepicker-toggle>
          <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
          </mat-form-field>
        </div>

      </div>

      <div class="mr-2 ml-2 pl-3 pt-2" style="min-width: 250px;" >
        <ng-multiselect-dropdown
          id="Sensors"
          formControlName="Sensors"
          [(ngModel)]="listSensorView"
          [placeholder]="'modules.shared.sensors' | translate"
          [settings]="dropdownSensor"
          [data]="listSensor">
          <!-- <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
            <div style="padding:1px 10px;margin-right:10px;right:2px;top:-2px;cursor: pointer !important;">
              <label style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
                {{option}}
              </label>
            </div>
          </ng-template>
          <ng-template #optionSelectedTemplate let-option="option" let-id="id">
            <div>
              {{option}}
            </div>
          </ng-template> -->
        </ng-multiselect-dropdown>
      </div>


      <div  *ngIf="idAlert">
        <div class="mr-2 pl-3">
          <mat-form-field  class="example-chip-list" appearance="legacy" style="min-width: 240px;">
            <mat-chip-list #chipList>
              <mat-chip>
                <strong>{{'modules.shared.alert-filter' | translate}}:</strong>  {{'modules.shared.alert' | translate}} #{{idAlert}}
              </mat-chip>

            </mat-chip-list>

          </mat-form-field>
        </div>

      </div>


    </form>
  </div>

  <div *ngIf="!loading && noData == true" class="text-right">
    {{'modules.shared.no-data-between-dates' | translate}}.
  </div>

  <div *ngIf="!loading && showErrorAlert == true" class="alert alert-danger mt-3 text-start">
    {{'modules.shared.no-alert-associated' | translate}}.
  </div>
  <div *ngIf="notification" class="alert alert-warning mt-3 text-start">
    {{'modules.alarms.alarm-sensor' | translate}} <strong>{{ notification.Data??'' }}</strong> {{'modules.shared.with-value' | translate}} <strong>{{ notification.Value??'' }}</strong> {{'modules.shared.on-day' | translate}}
    <!-- {{ notification.TrackPosition!.Date | date: "dd-MM-yyyy 'a las' HH:mm 'h'" }}. -->
    {{ notification.TrackPosition!.Date | date: "dd-MM-yyyy" }} {{ 'modules.trips.date-aux' | translate }} {{ notification.TrackPosition!.Date | date: "HH:mm 'h'" }}.
  </div>

  <div id="wrapper" *ngIf="!loading && noData == false && chartOptions1 && chartOptions2">
    <div class="main main-box">
      <div id="chart-line">
        <apx-chart #mainChart
          [series]="chartOptions1.series"
          [chart]="chartOptions1.chart"
          [xaxis]="chartOptions1.xaxis"
          [yaxis]="chartOptions1.yaxis"
          [dataLabels]="chartOptions1.dataLabels"
          [colors]="chartOptions1.colors"
          [fill]="chartOptions1.fill"
          [markers]="chartOptions1.markers"
          [stroke]="chartOptions1.stroke"
          [tooltip]="chartOptions1.tooltip"
          [legend]="chartOptions1.legend"
        ></apx-chart>
      </div>

      <div id="chart-line2">
        <apx-chart #timelineChart
          [series]="chartOptions2.series"
          [chart]="chartOptions2.chart"
          [xaxis]="chartOptions2.xaxis"
          [colors]="chartOptions2.colors"
          [fill]="chartOptions2.fill"
          [yaxis]="chartOptions2.yaxis"
          [dataLabels]="chartOptions2.dataLabels"
          [legend]="chartOptions2.legend"
        ></apx-chart>
      </div>
    </div>

    <div *ngIf="!loading && noData == false">
      <div class="col-12 mt-3 text-xl-end">
        <button class="btn btn btn-secondary" (click)="downloadPDF()">{{'modules.shared.download-report' | translate}}</button>
      </div>

    </div>

  </div>


<app-report-horizontal *ngIf="!loading && noData == false" ></app-report-horizontal>

<!-- <app-report *ngIf="!loading && noData == false" ></app-report>
-->
