import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { ListNotificationsComponent } from '../../components/list-notifications/list-notifications.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-list-notifications-page',
  templateUrl: './list-notifications-page.component.html',
  styleUrls: ['./list-notifications-page.component.scss']
})
export class ListNotificationsPageComponent implements OnInit {

  @ViewChildren('#notificationsList') tablesNotifications?: QueryList<ListNotificationsComponent>;

  public page?: number;
  loading:boolean = false;

  viewList1:boolean= true;
  viewList2:boolean= true;

  totalNoProcessed: number = 0;

  constructor(breadcrumbService: BreadcrumbService,
    private notificationSrv : NotificationsService,
    public authSrv: AuthenticationService,
    private route: ActivatedRoute,
    private translate: TranslateService) {

    breadcrumbService.setBreadCrumbUrl('notifications/list', this.translate.instant('modules.notifications.management'));
    this.page = Number(this.route.snapshot.paramMap.get('page'))?? 0;
  }

  ngOnInit(): void {
  }

  updateNumberTotal(count: number){
    this.totalNoProcessed = count;
  }

  updateList(list: number){

    console.log("updateList " , list);

    if(list == 1){
      this.viewList1 = false;
    }
    if(list == 2){
      this.viewList2 = false;
    }

    setTimeout( () => {
      this.viewList1 = true;
      this.viewList2 = true;
    }, 500 );


  }

  checkReadAll(){
    this.loading = true;
    this.notificationSrv.checkReadAll().subscribe({
      next: (res: boolean) => {
        this.totalNoProcessed = 0;
        this.authSrv.clearNotification();

        this.viewList1 = false;
        this.viewList2 = false;

        setTimeout( () => {
          this.loading = false;
          this.viewList1 = true;
          this.viewList2 = true;
        }, 500 );

      },
    });
  }

}
