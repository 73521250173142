<div id="htmlData" *ngIf="!loading">

  <!---------------------------------------------------
                    CABECERA
  ------------------------------------------------------>
    <div class="header">
      <div class="row">
        <!--TITULO-->
        <div class="col-4 header-title">
          <h1>{{'modules.charts.data-report' | translate}}</h1>
        </div>
        <!--LOGO-->
        <div class="col-4 logo mx-auto my-auto">
          <img class="img-brand" [src]="customLogo" />
        </div>
        <!--FECHA-->
        <div class="col-4 header-fecha">
          <p>{{'modules.charts.report-date' | translate}}: {{ date | date: "dd/MM/yyyy" }}</p>
        </div>
      </div>
    </div>

  <!----------------------------------------------------------
                    DATOS DE LA MÁQUINA
  ------------------------------------------------------------>
    <div class="data">
      <!--TITULO-->
      <div class="title-first">{{'modules.charts.machine-data' | translate}}</div>

      <div class="body">
        <!--BODY DATOS-->
        <!--
          --Si es mayor que 1 muestra datos--
          <div *ngIf="reportData.sensor.length > 1">
            <div class="col-12">
              <div class="title">{{'modules.charts.data' | translate}}</div>
              <div class="text">
                <span *ngFor="let sensor of sensorsList; index as i">
                  <span *ngIf="i > 0"> | </span> {{ sensor }}
                </span>
              </div>
            </div>
          </div>
          --Si es el mismo que 1 muestra datos--
          <ng-container *ngIf="reportData.sensor.length === 1">
            <div class="col-6">
              <div class="title">{{'modules.charts.data-single' | translate}}</div>
              <div class="text">{{ reportData.sensor[0].name }}</div>
            </div>
            <div class="col-6">
              <div class="title">{{'modules.charts.unit' | translate}}</div>
              <div class="text"> - </div>
            </div>
          </ng-container>

        -->
        <div class="row min-box">
          <!--FABRICANTE-->
          <div class="col-2   mb-3">
            <div class="title">{{'modules.charts.account' | translate}}</div>
            <div class="text">{{ reportData.fabricante??'' }} </div>
          </div>
          <!--MODELO-->
          <div class="col-2  mb-3">
            <div class="title">{{'modules.charts.model' | translate}}</div>
            <div class="text">{{ reportData.modelo??'' }} </div>
          </div>
          <!--NOMBRE-->
          <div class="col-2   mb-3">
            <div class="title">{{'modules.charts.serial-number' | translate}}</div>
            <div class="text">{{ reportData.numeroSerie??'' }} </div>
          </div>
          <!--CLIENTE-->
          <div class="col-2  mb-3">
            <div class="title">{{'modules.charts.user' | translate}}</div>
            <div class="text">{{ reportData.cliente??'' }} </div>
          </div>
          <!--DISPOSITIVO-->
          <div class="col-2  mb-3">
            <div class="title">{{'modules.charts.device' | translate}}</div>
            <div class="text">{{ reportData.nombre??'' }} </div>
          </div>
          <!--IMEI-->
            <div class="col-2 ml-3 mb-3">
              <div class="title">IMEI</div>
              <div class="text">{{ reportData.imei??'' }} </div>
            </div>
          </div>
      </div>
    </div>
  <!----------------------------------------------------------
                    SEGUIMIENTO
  ------------------------------------------------------------>
    <div class="follow">
      <div class="title-first">{{'modules.charts.tracking-chart' | translate}}</div>

      <!--BODY GRÁFICO-->
      <div class="body-grafico">
        <!--GRÁFICO-->
        <div class="chart">
          <div id="chart-line-report">
            <apx-chart #mainChartReport
              [series]="reportChart.series"
              [chart]="reportChart.chart"
              [xaxis]="reportChart.xaxis"
              [yaxis]="reportChart.yaxis"
              [dataLabels]="reportChart.dataLabels"
              [colors]="reportChart.colors"
              [fill]="reportChart.fill"
              [markers]="reportChart.markers"
              [stroke]="reportChart.stroke"
              [tooltip]="reportChart.tooltip"
              [legend]="reportChart.legend"
              [annotations]="reportChart.annotations"
            ></apx-chart>
          </div>
        </div>
        <!--DATOS-->
        <div class="row">
          <!--DESDE-->
          <div class="col-2 mb-3">
            <div class="title">{{'modules.charts.from' | translate}}</div>
            <div class="text">{{ this.reportChart?.chart?.selection.xaxis.min | date:'dd/MM/yyyy' }}</div>
          </div>
          <!--HASTA-->
          <div class="col-2  mb-3">
            <div class="title">{{'modules.charts.to' | translate}}</div>
            <div class="text">{{ this.reportChart?.chart?.selection.xaxis.max | date:'dd/MM/yyyy' }}</div>
          </div>
          <!--VALOR-->
          <div class="col-2  mb-3">
            <div class="title">{{'modules.charts.value' | translate}}</div>
            <div *ngFor="let sensor of sensorsList" class="text">{{ sensor }}</div>
          </div>
          <!--MÍNIMO-->
          <div class="col-2  mb-3">
            <div class="title">
              {{ containInAcc(sensorsList[0]) ? ('modules.charts.start' | translate) : 'modules.charts.min' | translate }}
            </div>
            <div *ngFor="let values of sensorsValues; index as i" class="text">
              <ng-container *ngIf="sensorsList[i] == 'Horas trabajo'">
                {{ calcularHorasYMinutos(min[i]).horas }}h. {{ calcularHorasYMinutos(min[i]).minutos }}m.
              </ng-container>
              <ng-container *ngIf="sensorsList[i] != 'Horas trabajo'">
                {{ min[i] | number : '1.2-2' }}
              </ng-container>
            </div>
          </div>
          <!--MÁXIMO-->
          <div class="col-2  mb-3">
            <div class="title">
              {{ containInAcc(sensorsList[0]) ? ('modules.charts.end' | translate) : 'modules.charts.max' | translate }}
            </div>
            <div *ngFor="let values of sensorsValues; index as i" class="text">
              <ng-container *ngIf="sensorsList[i] == 'Horas trabajo'">
                {{ calcularHorasYMinutos(max[i]).horas }}h. {{ calcularHorasYMinutos(max[i]).minutos }}m.
              </ng-container>
              <ng-container *ngIf="sensorsList[i] != 'Horas trabajo'">
                {{ max[i] | number : '1.2-2' }}
              </ng-container>
            </div>
          </div>
          <!--PROMEDIO-->
          <div class="col-2  mb-3">
            <div class="title">
              {{ containInAcc(sensorsList[0]) ? ('modules.charts.total-frame' | translate) : 'modules.charts.average' | translate }}
              
            </div>
            <div *ngFor="let values of sensorsValues; index as i" class="text">
              <ng-container *ngIf="containInAcc(sensorsList[i])">
                <ng-container *ngIf="sensorsList[i] == 'Horas trabajo'">
                  {{ calcularHorasYMinutos(max[i] - min[i]).horas }}h. {{ calcularHorasYMinutos(max[i] - min[i]).minutos }}m.
                </ng-container>
                <ng-container *ngIf="sensorsList[i] != 'Horas trabajo'">
                  {{ max[i] - min[i] | number : '1.2-2' }}
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!containInAcc(sensorsList[i])">
                {{ med[i] | number : '1.2-2'}}
              </ng-container>
            </div>
          </div>
        </div>

      </div>
    </div>
      <div class="logo-footer mt-2">
        <img class="img-brand" src="/assets/img/batsi-brand.svg" />
      </div>
  </div>
