import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Device, Information, InformationChart } from 'src/app/models/device';
import { CustomLuxonDateAdapter } from '../timeline-apex-chart/customDateAdapter';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS, MAT_LUXON_DATE_FORMATS } from '@angular/material-luxon-adapter';
import { ChartOptions } from 'chart.js';
import { ExportChartsService } from 'src/app/services/export-charts.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { mapNameSensors, mapNameSensors2, sensorAccomulative, sensors } from 'src/app/const/sensor-const';
import { DateTime, Settings, } from "luxon";
import { DataSetApex } from 'src/app/models/alarms';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TripEvent } from 'src/app/models/trip';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexYAxis,
  ApexFill,
  ApexMarkers,
  ApexStroke
} from "ng-apexcharts";
import { TripsService } from 'src/app/services/trips.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-timeline-apex-v2-chart',
  templateUrl: './timeline-apex-v2-chart.component.html',
  styleUrls: ['./timeline-apex-v2-chart.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: DateAdapter,
      useClass: CustomLuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS},
  ]
})
export class TimelineApexV2ChartComponent implements OnInit {

  @Input() showInfoPeriod: boolean = true;
  @Input() showChangeSensors: boolean = true;
  @Input() informations!: Information[];
  @Input() limitSensors?: Array<string>;
  @Output() indexCurrentPoint = new EventEmitter<number>();
  @Output() timeCurrentPoint = new EventEmitter<DateTime>();
  @ViewChild("mainChart", { static: false }) chart?: ChartComponent;
  @ViewChild("timelineChart", { static: false }) chart2?: ChartComponent;

  @Input() events!: TripEvent[];

  public firstInformation?: Information;
  public lastInformation?: Information;
  InformationFirstValue: DateTime | null= null
  InformationLastValue: DateTime  | null = null;
  
  public chartOptions1!: Partial<ChartOptions> | any;
  public chartOptions2!: Partial<ChartOptions> | any;
  isFirstTime: boolean = true;

  reportData: any  = {};
  reportChart: any = {};
  loading: boolean = true;
  infoChart: InformationChart = new InformationChart();
  public datasets: DataSetApex[] = [];
  colors = ["#77B6EA", "#ff6384", "#ffd05e", "#545454"];
  listSensor: Array<string> = [
    this.translate.instant('modules.alarms.rpm'),
    this.translate.instant('modules.alarms.horas-trabajo'),
    this.translate.instant('modules.alarms.presion'),
    this.translate.instant('modules.alarms.caudal'),
    this.translate.instant('modules.alarms.temp-aceite'),
    this.translate.instant('modules.alarms.nivel-aceite'),
    this.translate.instant('modules.alarms.num-acciones'),
    this.translate.instant('modules.alarms.peso'),
    this.translate.instant('modules.alarms.rpm-2'),
    this.translate.instant('modules.alarms.rpm-3'),
    this.translate.instant('modules.alarms.presion-2'),
    this.translate.instant('modules.alarms.presion-3'),
    this.translate.instant('modules.alarms.caudal-2'),
    this.translate.instant('modules.alarms.caudal-3'),
    this.translate.instant('modules.alarms.peso-2'),
    this.translate.instant('modules.alarms.num-acciones-2'),
    this.translate.instant('modules.alarms.num-acciones-3'),
    this.translate.instant('modules.alarms.aux-1'),
    this.translate.instant('modules.alarms.aux-2'),
    this.translate.instant('modules.alarms.aux-3'),
    this.translate.instant('modules.alarms.aux-4'),
  ];
  listSensorNames: Array<string> = [];

  listSensorRename: any = sensors;

  listSensorAccomulative: any = sensorAccomulative;

  listSensorView: Array<string> = [
    this.translate.instant('modules.alarms.rpm')
    // this.translate.instant('modules.alarms.temp-aceite')
  ];

  dropdownSensor : IDropdownSettings= {
    singleSelection: false,
    limitSelection: 4,
    selectAllText: this.translate.instant('modules.shared.select-all'),
    unSelectAllText: this.translate.instant('modules.shared.unselect-all'),
    allowSearchFilter: true,
    //noFilteredDataAvailablePlaceholderText: "No hay coincidencias",
    noDataAvailablePlaceholderText: this.translate.instant('modules.shared.no-available-sensors'),
    searchPlaceholderText: this.translate.instant('modules.shared.search'),
    clearSearchFilter: true,
  };
  
  filterChartForm: FormGroup = this.formBuilder.group({
  }); 
  
  constructor(
    private formBuilder: FormBuilder,
    public authSrv : AuthenticationService,
    private exportData: ExportChartsService,
    private exportChart: ExportChartsService,
    public tripSrv: TripsService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService) { }

  ngOnInit(): void {
    Settings.defaultZone = "europe/madrid";
    Settings.defaultLocale= "fr";

    if(this.limitSensors){
      this.listSensor = [];

      this.limitSensors.forEach((e)=>{
        this.listSensor.push(mapNameSensors.get(e)!);
        this.listSensorNames.push(this.translate.instant(mapNameSensors2.get(e)!));
      });
      this.listSensorView = [ this.listSensor[0] ];
    }

    this.firstInformation = <Information>this.informations[0];
    this.lastInformation = <Information>this.informations[this.informations.length - 1];
    this.InformationFirstValue = DateTime.fromJSDate(new Date(this.firstInformation.TrackPosition?.Date!), {zone: 'UTC+1'});
    this.InformationLastValue = DateTime.fromJSDate(new Date(this.lastInformation.TrackPosition?.Date!), {zone: 'UTC+1'});


    this.exportData.currentData.subscribe(data => this.reportData = data)
    this.exportChart.currentChart.subscribe((chart: any) => this.reportChart = chart)

    this.filterChartForm = this.formBuilder.group({
      InformationFirst: new FormControl(this.InformationFirstValue, [
        Validators.required
      ]),
      InformationLast: new FormControl(this.InformationLastValue, [
        Validators.required
      ]),
      Sensors: new FormControl(this.listSensorView, [
        Validators.required
      ])
    });


    this.loadChart();

    

  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();

  }

  
  get InformationFirst(){ return this.filterChartForm.get("InformationFirst"); }
  get InformationLast(){ return this.filterChartForm.get("InformationLast"); }


  public loadChart(refresh: boolean= false){

    console.log('++ loadChart', refresh);

    console.log(refresh);
    this.loading = true;

    let opcionesColor : any= {
      'borderWidth': 2,
      'hoverOffset': 0,
      'cubicInterpolationMode': 'default', 'pointRadius': 0, 'hoverRadius': 0
    };
    console.log(this.informations);

    this.infoChart!.RPM! = this.informations.map((element) => element.RPM);
    console.log('++ RPM', this.infoChart!.RPM!);
    this.infoChart!.WorkingHours! = this.informations.map((element) => element.WorkingHours);
    this.infoChart!.Pressure1! = this.informations.map((element) => element.Pressure1);
    this.infoChart!.Pressure2! = this.informations.map((element) => element.Pressure2);
    this.infoChart!.Flow1! = this.informations.map((element) => element.Flow1);
    this.infoChart!.Flow2! = this.informations.map((element) => element.Flow2);
    this.infoChart!.Action1! = this.informations.map((element) => element.Action1);
    this.infoChart!.Action2! = this.informations.map((element) => element.Action2);
    this.infoChart!.Action3! = this.informations.map((element) => element.Action3);
    this.infoChart!.Action4! = this.informations.map((element) => element.Action4);
    this.infoChart!.Action5! = this.informations.map((element) => element.Action5);
    this.infoChart!.Action6! = this.informations.map((element) => element.Action6);
    this.infoChart!.Weight1! = this.informations.map((element) => element.Weight1);
    this.infoChart!.Weight2! = this.informations.map((element) => element.Weight2);
    this.infoChart!.Aux1! = this.informations.map((element) => element.Aux1);
    this.infoChart!.Aux2! = this.informations.map((element) => element.Aux2);
    this.infoChart!.Aux3! = this.informations.map((element) => element.Aux3);
    this.infoChart!.Aux4! = this.informations.map((element) => element.Aux4);
    this.infoChart!.Aux5! = this.informations.map((element) => element.Aux5);
    this.infoChart!.Aux6! = this.informations.map((element) => element.Aux6);
    this.infoChart!.Aux7! = this.informations.map((element) => element.Aux7);
    this.infoChart!.Aux8! = this.informations.map((element) => element.Aux8);

    //console.log("RPM", ...this.infoChart!.RPM! );

    let data = [
      {...this.infoChart!.RPM! },
      {...this.infoChart!.WorkingHours! },
      {...this.infoChart!.Pressure1! },
      {...this.infoChart!.Pressure2! },
      {...this.infoChart!.Flow1! },
      {...this.infoChart!.Flow2! },
      {...this.infoChart!.Action1! },
      {...this.infoChart!.Action2! },
      {...this.infoChart!.Action3! },
      {...this.infoChart!.Action4! },
      {...this.infoChart!.Action5! },
      {...this.infoChart!.Action6! },
      {...this.infoChart!.Weight1! },
      {...this.infoChart!.Weight2! },
      {...this.infoChart!.Aux1! },
      {...this.infoChart!.Aux2! },
      {...this.infoChart!.Aux3! },
      {...this.infoChart!.Aux4! },
      {...this.infoChart!.Aux5! },
      {...this.infoChart!.Aux6! },
      {...this.infoChart!.Aux7! },
      {...this.infoChart!.Aux8! },
    ];

    //this.infoChart!.Dates! = this.informations.map((element) => DateTime.fromJSDate(element.TrackPosition!.Date!));

    this.infoChart!.Dates! = this.informations.filter((element) => element.TrackPosition!.Date).map((element) => {

      let milis: number = new Date(element.TrackPosition!.Date!).getTime();
      //console.log(milis);
      //console.log(element.TrackPosition!.Date);
      //console.log(DateTime.fromMillis(milis));
      return DateTime.fromMillis(milis);
    });

    //console.log(this.cloneDevice?.Informations![0]);
    //console.log(this.infoChart!.Dates![0]);

    this.datasets = [];
    //this.datasets2 = [];

    console.log(data);
    let totalCount = 0;

    for(let i = 0; i < data.length; i++){
      //this.lineChartData.datasets[i].data = data[i];
      //this.lineChartData.datasets[i].label = labelDataset[i];
      let auxSensor = sensors.find( e => e.key == this.listSensor[i]);
      console.log('auxSensor' , auxSensor);

      if(this.listSensorView.includes(this.listSensorNames[i])){
        

        console.log('this.listSensor[' + i + '] ' , this.listSensor[i] , ' IN ' , this.listSensorView);


        let dataset: DataSetApex = { name : this.listSensorNames[i] , data : [ ] };
        let arr: any = [];
        let dates: any = [];

        let element = 0;
        let auxI = auxSensor!.position;

        Object.keys(data[auxI]).map((key) =>{
            //if(data[auxI][key]  && data[auxI][key] != null){
            if(data[auxI][key] != undefined && data[auxI][key] != null){

              let aux: DateTime = this.infoChart!.Dates![element];

              dataset.data.push({ y: data[auxI][key], x: aux.valueOf()  })
              arr.push(data[auxI][key])
              dates.push( this.infoChart!.Dates![element] );

            }else{
              //console.log('problem data' , data[auxI] , key , 'value' , data[auxI][key]);
            }
            element = element + 1;
        });

        console.log(dataset);
        console.log(arr);
        console.log(dates);
        if(arr.length > 0){
          totalCount += 1;
          this.datasets.push(dataset);
        }

      }else{
      }
    }

    console.log(this.datasets.length);

    let getData= false;
    this.datasets.forEach( x => {

      if(x !== undefined){
        getData = true;
      }

    });

    if(getData == false){
      this.loading = false;
      return;
    }

    if(this.datasets[0]){
      console.log(this.datasets[0]);
      console.log(this.datasets[0].data[0].y??'');
    }

    const that = this;
    

      this.chartOptions1 = {
        
        series: [ ...this.datasets],
        animations: {
          initialAnimation: {
            enabled: false
          },
          enabled: false,
        },
        chart: {
          id: "chart2",
          type: "line",
          background: '#fff',
          height: 230,
          toolbar: {
            autoSelected: "zoom",
            show: false,
          },
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
        },
        colors: this.colors,
        stroke: {
          width: 3
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          opacity: 1
        },
        markers: {
          size: 0
        },
        xaxis: {
          type: "datetime",
          tooltip: {
              enabled: true,
              formatter: function (value: any, opt: any) {
                let time = DateTime.fromMillis(value.valueOf());
                console.log("++ time" , time);
                console.log("++ opt" , opt);
                console.log("++ tooltip" , opt.dataPointIndex);
                that.indexCurrentPoint!.emit(opt.dataPointIndex);
                that.timeCurrentPoint!.emit(time);
                return time.toFormat("dd/MM/yyyy - HH:mm:ss")
              },
          },
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
                year: 'yyyy',
                month: "MMM 'yy",
                day: 'dd MMM',
                hour: 'HH:mm',
                minutes: 'HH:mm:ss',
            },
          },
        },
        yaxis: {
          min: 0,
          max: 700,
          labels: {
            formatter: function (value: number) {
              return value.toFixed(2);
            }
          },
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            format: 'dd/MM/yyyy - HH:mm',
          },
        }, legend: {

          position: 'top'
        }
      };

      this.chartOptions2 = {
        
        events: {
          mouseMove: function(_event: any, _chartContext: any, _config: any) {
            // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts.
            console.log('++ mouseMove ' , _event);
          }
        },
        series: [
          ...this.datasets
        ],
        animations: {
          initialAnimation: {
            enabled: false
          },
          enabled: false,
        },
        chart: {
          id: "chart1",
          height: 130,
          type: "area",
          brush: {
            target: "chart2",
            enabled: true
          },
          selection: {
            enabled: true,
            xaxis: {
              //min: 0,
              //max: 10000
              min:  this.datasets[0].data![0].x!.valueOf(),
              //min: this.datasets2[0].data![this.datasets2[0].data.length/2].x!,
              max: this.datasets[0].data![this.datasets[0].data.length - 1].x!.valueOf(),
            }
          }
        },
        colors: this.colors,
        xaxis: {
          type: "datetime",
          tooltip: {
            enabled: false
          },
          labels: {
            datetimeUTC: false,
            /*
            formatter: function (value: any) {
              let time = DateTime.fromMillis(value.valueOf())
              return time.toFormat("dd/MM/yyyy - HH:mm")
            }*/
          }
        },
        yaxis: {
          forceNiceScale: true,
          tickAmount: 2,
          labels: {
            formatter: function (value: number, opt: any) {
           
                return value.toFixed(2);
              

            }
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          opacity: 0
        },
        legend: {
          show: false
        }
      };

      //this.chart.addEventListener();
 
    setTimeout(() => {
      
      let aux = 15;
      this.events.forEach( (e) => {

        //let datePoint = DateTime.fromJSDate(new Date(this.notification!.TrackPosition!.Date!), {zone: 'UTC+1'});
        console.log('event' , e);

        if(e.Type == 2){

          that.chart!.addPointAnnotation({
            x: this.datasets[0].data![e.Id!].x!.valueOf(),
            y: this.datasets[0].data![e.Id!].y!.valueOf(),          
            borderColor: "#FF2D00",
            label: {
              borderColor: "#FF2D00",
              style: {
                color: "#fff",
                background: "#FF2D00"
              },
              orientation: "horizontal",
              text: (e.Title)?this.translate.instant(e.Title!):''
            }
          });

        }else if(e.Type == 3 || e.Type == 4){

          

          console.log('++ datos trabajo', new Date(e.Date!).valueOf());
          //console.log('++ datos trabajo', this.datasets[0].data![e.Id!].x!.valueOf());
          that.chart!.addXaxisAnnotation ({
            x: new Date(e.Date!).valueOf(), 
            borderColor: "#FEB019",
            label: {
              borderColor: "#FEB019",
              style: {
                color: "#fff",
                background: "#FEB019"
              },
              text: (e.Title)?this.translate.instant(e.Title!):''
            }
          });
        }else if(e.Type == 1){
        
        }else{

          that.chart!.addPointAnnotation({
            x: this.datasets[0].data![e.Id!].x!.valueOf(),
            y: this.datasets[0].data![e.Id!].y!.valueOf(),          
            borderColor: "#FEB019",
            label: {
              borderColor: "#FEB019",
              style: {
                color: "#fff",
                background: "#FEB019"
              },
              orientation: "horizontal",
              text: (e.Title)?this.translate.instant(e.Title!):''
            }
          });

        }

        aux = aux +10;

      });


    }, 500);

      
      this.loading = false;

    
  }

  changeSensorSelected(e: any){
    console.log('changeSensorSelected' , e);
    this.loadChart(true);
  }

  public calcularHorasYMinutos(numero: number) {
    // Obtén las horas enteras (parte entera del número)
    const horas = Math.floor(numero);
  
    // Obtén los minutos a partir de la parte decimal del número
    const minutosDecimal = numero - horas;
    const minutos = Math.round(minutosDecimal * 60); // Convierte el decimal a minutos
  
    return { horas: horas, minutos: minutos };
  }

}
