import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Device, DeviceModel } from 'src/app/models/device';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { DevicesService } from 'src/app/services/devices.service';

@Component({
  selector: 'app-detail-device-model-page',
  templateUrl: './detail-device-model-page.component.html',
  styleUrls: ['./detail-device-model-page.component.scss']
})
export class DetailDeviceModelPageComponent implements OnInit {

  id?: number;
  model?: DeviceModel;
  loading:boolean = true;
  public page?: string;



  constructor(breadcrumbService: BreadcrumbService,
    private deviceSrv: DevicesService,
    private route: ActivatedRoute,
    private translate: TranslateService) {

      breadcrumbService.setBreadCrumbUrl('devices/list', this.translate.instant('modules.devices.management'));

      this.route.params.subscribe(params => {
        this.id = params['id'];
        console.log(this.id)
        this.loadModel(params['id']??'' );
        this.page = this.route.snapshot.paramMap.get('page')?? "";

      });
    }

  ngOnInit(): void {
    this.loading = true;
  }


  loadModel(id: number){
    console.log('loadDevice: ' , id );
    this.deviceSrv.getModelById(id).subscribe({
      next: (res: DeviceModel) => {
        this.model = <DeviceModel>res;

        if(this.model.DefaultAlarmConfig){
          this.model.DefaultAlarmConfig!.DeviceModel = new DeviceModel();
          this.model.DefaultAlarmConfig!.DeviceModel.Id = this.model.Id;
          this.model.DefaultAlarmConfig!.DeviceModel.Name = this.model.Name;
        }


        console.log(this.model );
      }, error: ()=> {
        this.loading = false;

      },
      complete: ()=>{
        this.loading = false;
      }
    });
  }

}
