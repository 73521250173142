<app-loading-custom [loading]="loading || loadingUser"></app-loading-custom>

<ng-container *ngIf="loading == false && loadingUser == false && loadModels && information && manufacturerList && modelsList">

  <form class="text-start p-2" [formGroup]="alarmForm" autocomplete="off">
    <!-- <h2 class="text-center mt-3 mb-3">{{'modules.alarms.config-alarm' | translate}} {{ information!.DeviceModel?.Name??'' }}</h2> -->

    <ng-container formGroupName="Alarm" *ngIf="pageDeviceInto == false && (authSrv.isAdmin() || authSrv.isManufacturer() || authSrv.isClient()   ||  authSrv.currentUserValue?.UserId == information.IdUserCreated )">

      <div class="form-group" *ngIf="modelsList && manufacturerList && modelsList.length > 1">

        <!--
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Assignee</mat-label>
            <input type="text" matInput formControlName="DeviceModel" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let option of modelsList" [value]="option">
                {{option.Name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field> -->


        <ng-multiselect-dropdown [ngClass]="(showChangeManufacturer == false || authSrv.isAdmin() == false)?'d-none':''" id="Manufacturer"
          formControlName="Manufacturer" [(ngModel)]="selectedManufacturerList" [placeholder]="'modules.shared.search-account' | translate"
          [settings]="dropdownSettingsManufacturer" [data]="manufacturerList" (onSelect)="changeModel()">

          <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
            <div style="padding:1px 10px;margin-right:10px;right:2px;top:-2px;cursor: pointer !important;">
              <label *ngIf="authSrv.isManufacturer() == false"
                style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
                {{option}}{{ getManufacturer[id].Email?' | ' +getManufacturer[id].Email:'' }} {{
                getManufacturer[id].ContactName?' | ' +getManufacturer[id].ContactName:'' }}
              </label>
              <label *ngIf="authSrv.isManufacturer() == true"
                style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
                {{ getManufacturer[id]?.Name??'' }}
              </label>
            </div>
          </ng-template>

          <ng-template #optionSelectedTemplate let-option="option" let-id="id">
            <div *ngIf="authSrv.isManufacturer() == false">
              {{option}}{{ getManufacturer[id].Email?' | ' +getManufacturer[id].Email:'' }} {{
              getManufacturer[id].ContactName?' | ' +getManufacturer[id].ContactName:'' }}
            </div>
            <div *ngIf="authSrv.isManufacturer() == true">
              {{ getManufacturer[id]?.Name??'' }}
            </div>
          </ng-template>
        </ng-multiselect-dropdown>

      </div>

      <div *ngIf="authSrv.isAdmin() && modelError == true" class="form-group" >

        <div class="alert alert-danger mt-3 text-start">
          <div>
            {{'modules.account.required' | translate}}.
          </div>

        </div>
      </div>

      <div class="form-group pt-3" *ngIf="( authSrv.isAdmin() || (authSrv.isManufacturer() && information.Device.Id == null) || (authSrv.isClient() && information.Device.Id == null) ) && modelsList && modelsList.length > 1 && loadModels == true">

        <ng-multiselect-dropdown [ngClass]="(showChangeDeviceModel == false)?'d-none':''" id="DeviceModel"
          formControlName="DeviceModel" [(ngModel)]="selectedModelsList" [placeholder]="'modules.shared.search-model-machine' | translate"
          [settings]="dropdownSettingsModels" [data]="modelsListFilter" (onSelect)="changeManufacturer()">

          <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
            <div style="padding:1px 10px;margin-right:10px;right:2px;top:-2px;cursor: pointer !important;">
              <label *ngIf="authSrv.isManufacturer() == false"
                style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
                {{option}} | {{ getModel[id].NameManufacturer??'' }}
              </label>
              <label *ngIf="authSrv.isManufacturer() == true"
                style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
                {{ getModel[id].Name??'' }}
              </label>
            </div>
          </ng-template>

          <ng-template #optionSelectedTemplate let-option="option" let-id="id">
            <div *ngIf="authSrv.isManufacturer() == false">
              {{option}} | {{ getModel[id].NameManufacturer??'' }}
            </div>
            <div *ngIf="authSrv.isManufacturer() == true">
              {{option}}
            </div>
          </ng-template>
        </ng-multiselect-dropdown>

      </div>

      <div class="form-group pt-3 pb-2" *ngIf=" modelsList && modelsList.length > 1"
        [ngClass]="(showChangeDevice == false || (authSrv.isManufacturer() && information.Device.Id != null) || (authSrv.isClient() && information.Device.Id != null) )?'d-none':''">

        <ng-multiselect-dropdown id="Device" formControlName="Device" [(ngModel)]="selectedDevicesList" (onSelect)="selectDeviceMultiselect($event)"
          [placeholder]="'modules.shared.search-device' | translate" [settings]="dropdownSettingsDevices" [data]="devicesListFilter">

          <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
            <div style="padding:1px 10px;margin-right:10px;right:2px;top:-2px;cursor: pointer !important;">
              <label *ngIf="authSrv.isAdmin() == true"
                style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
                {{option}} | {{ getDevice[id].NameDeviceModel??'' }} | {{ getDevice[id].NameManufacturer??'' }}
              </label>
              <label *ngIf="authSrv.isManufacturer() == true"
                style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
                {{ getDevice[id].Name??'' }} | {{ getDevice[id].NameDeviceModel??'' }}
              </label>
              <label *ngIf="authSrv.isClient() == true"
                style="color: #333;min-width: 130px; padding-left: 15px;cursor: pointer !important;">
                {{ getDevice[id].Name??'' }} | {{ getDevice[id].NameDeviceModel??'' }} | {{ getDevice[id].IMEI??'' }}
              </label>
            </div>
          </ng-template>

          <ng-template #optionSelectedTemplate let-option="option" let-id="id">
            <div *ngIf="authSrv.isAdmin() == true">
              {{option}} | {{ getDevice[id].NameDeviceModel??'' }} | {{ getDevice[id].NameManufacturer??'' }}
            </div>
            <div *ngIf="authSrv.isManufacturer() == true">
              {{ getDevice[id].Name??'' }}
            </div>
            <div *ngIf="authSrv.isClient() == true">
              {{ getDevice[id].Name??'' }} | {{ getDevice[id].IMEI??'' }}
            </div>
          </ng-template>
        </ng-multiselect-dropdown>

      </div>

      <div class="form-group" *ngIf="modelError == true">

        <div class="alert alert-danger mt-3 text-start">
          <div>
            {{'modules.devices.model-required' | translate}}.
          </div>

        </div>
      </div>

    </ng-container>


    <!--
        <div class="row">
          <div class="form-group col-6">
              <label for="RPMMin"  class="sr-only">RPM</label>
              <input type="text" class="form-control  input-group-lg" id="RPMMin"
              formControlName="RPMMin" required [(ngModel)]="information!.AlarmValue!.RPMMin">

              <div *ngIf="RPMMin!.invalid && (RPMMin!.dirty || RPMMin!.touched)" class="alert alert-danger mt-3 text-start">
                  <div [hidden]="!RPMMin!.errors?.['required']">{{'modules.devices.rpm-min-required' | translate}}.</div>
                  <div [hidden]="!RPMMin!.errors?.['pattern']">{{'modules.devices.rpm-min-invalid' | translate}}.</div>
              </div>

            <input type="text" class="form-control  input-group-lg" id="RPMMax"
            formControlName="RPMMax" required [(ngModel)]="information!.AlarmValue!.RPMMax">

            <div *ngIf="RPMMax!.invalid && (RPMMax!.dirty || RPMMax!.touched)" class="alert alert-danger mt-3 text-start">
                <div [hidden]="!RPMMax!.errors?.['required']">{{'modules.devices.rpm-max-required' | translate}}.</div>
                <div [hidden]="!RPMMax!.errors?.['pattern']"{{'modules.devices.rpm-max-invalid' | translate}}.</div>
            </div>
        </div>
      </div>-->
    <ng-container formGroupName="Alarm">
      <div style="max-width: 600px;" *ngIf="authSrv.isAdmin()">
        <div class="mb-3" style="margin: auto; text-align: left !important; width: fit-content;">
          <div *ngIf="this.information!.Id != 0 && loading == false">
            <strong>{{ textRole }}</strong> {{ information.User?.Name??'' }}
          </div>

          <div class="form-group roles">
            <label *ngIf="this.information!.Id != 0" for="Role" class="text-right-label">{{'modules.users.change-user' | translate}}</label>
            <label *ngIf="this.information!.Id == 0" for="Role" class="text-right-label">{{'modules.users.assign-user' | translate}}</label>

            <mat-radio-group formControlName="Role" aria-labelledby="example-radio-group-label"
              class="example-radio-group" [(ngModel)]="itemRol">
              <mat-radio-button class="example-radio-button" value="0" [checked]="itemRol === 0">
                {{'modules.users.admin' | translate}}
              </mat-radio-button>
              <mat-radio-button class="example-radio-button" [value]="information!.Device?.DeviceModel?.Manufacturer?.Id??-1"
                [checked]="itemRol === information!.Device?.DeviceModel?.Manufacturer?.Id"
                *ngIf="information!.Device?.DeviceModel?.Manufacturer && information!.Device?.DeviceModel?.Manufacturer.Id || authSrv.isAdmin()">
                {{'modules.tables.account' | translate}}
              </mat-radio-button>
              <mat-radio-button class="example-radio-button" [value]="information!.Device?.IdUser??-2"
                [checked]="itemRol === information!.Device?.IdUser" *ngIf="information!.Device?.IdUser  || authSrv.isAdmin()">
                {{'modules.users.user' | translate}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div style="max-width: 600px;">
        <div class="mb-3" style="margin: auto; text-align: left !important; width: min-content;">
          <div class="text-left d-block">
            <div class="text-left d-block m-2" [ngClass]="(authSrv.isAdmin()  || authSrv.isManufacturer())?'':'d-none'">
              <mat-checkbox color="primary" class="example-margin"
                [(ngModel)]="information!.AlarmValue!.ManufacturerActive" formControlName="ManufacturerActive">{{
                authSrv.isManufacturer()?('modules.notifications.enable' | translate) + '.':('modules.notifications.enable-for-accounts' | translate) + '.'}}
              </mat-checkbox>

            </div>
            <div class="text-left d-block m-2"
              [ngClass]="(authSrv.isAdmin()  || authSrv.isClient() ||  authSrv.currentUserValue?.UserId == information.IdUserCreated || information.Id == 0)?'':'d-none'">
              <mat-checkbox color="primary" class="example-margin" [(ngModel)]="information!.AlarmValue!.ClientActive"
                formControlName="ClientActive">{{ authSrv.isClient()?('modules.notifications.enable' | translate) + '.':('modules.notifications.enable-for-user' | translate) + '.'}}</mat-checkbox>

            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <app-form-range-default-values-alarm translation="{{'modules.alarms.rpm' | translate}}" name="RPM" [minValue]="information!.AlarmValue!.RPMMin!"  [maxValue]="information!.AlarmValue!.RPMMax!" (formShared)="updateRPMValid($event)"  [readOnly]="readOnly"></app-form-range-default-values-alarm>
    <app-form-range-default-values-alarm translation="{{'modules.alarms.horas-trabajo' | translate}}" name="Horas trabajo" tag="WorkingHours" [minValue]="information!.AlarmValue!.WorkingHoursMin!"  [maxValue]="information!.AlarmValue!.WorkingHoursMax!" (formShared)="updateWorkingHoursValid($event)"  [readOnly]="readOnly"></app-form-range-default-values-alarm>

    <app-form-range-default-values-alarm translation="{{'modules.alarms.presion' | translate}}" name="Presión" tag="Pressure1" [minValue]="information!.AlarmValue!.Pressure1Min!"  [maxValue]="information!.AlarmValue!.Pressure1Max!" (formShared)="updatePressure1Valid($event)"  [readOnly]="readOnly"></app-form-range-default-values-alarm>
    <app-form-range-default-values-alarm translation="{{'modules.alarms.caudal' | translate}}" name="Caudal" tag="Pressure2" [minValue]="information!.AlarmValue!.Pressure2Min!"  [maxValue]="information!.AlarmValue!.Pressure2Max!" (formShared)="updatePressure2Valid($event)"  [readOnly]="readOnly"></app-form-range-default-values-alarm>
    <app-form-range-default-values-alarm translation="{{'modules.alarms.temp-aceite' | translate}}" name="Temp. aceite" tag="Flow1" [minValue]="information!.AlarmValue!.Flow1Min!"  [maxValue]="information!.AlarmValue!.Flow1Max!" (formShared)="updateFlow1Valid($event)"  [readOnly]="readOnly"></app-form-range-default-values-alarm>
    <app-form-range-default-values-alarm translation="{{'modules.alarms.nivel-aceite' | translate}}" name="Nivel aceite'" tag="Flow2" [minValue]="information!.AlarmValue!.Flow2Min!"  [maxValue]="information!.AlarmValue!.Flow2Max!" (formShared)="updateFlow2Valid($event)"  [readOnly]="readOnly"></app-form-range-default-values-alarm>

    <app-form-range-default-values-alarm translation="{{'modules.alarms.num-acciones' | translate}}" name="Nº acciones" tag="Action1" [minValue]="information!.AlarmValue!.Action1Min!"  [maxValue]="information!.AlarmValue!.Action1Max!" (formShared)="updateAction1Valid($event)"  [readOnly]="readOnly"></app-form-range-default-values-alarm>
    <app-form-range-default-values-alarm translation="{{'modules.alarms.peso' | translate}}" name="Peso" tag="Action2" [minValue]="information!.AlarmValue!.Action2Min!"  [maxValue]="information!.AlarmValue!.Action2Max!" (formShared)="updateAction2Valid($event)"  [readOnly]="readOnly"></app-form-range-default-values-alarm>
    <app-form-range-default-values-alarm translation="{{'modules.alarms.rpm-2' | translate}}" name="RPM 2" tag="Action3" [minValue]="information!.AlarmValue!.Action3Min!"  [maxValue]="information!.AlarmValue!.Action3Max!" (formShared)="updateAction3Valid($event)"  [readOnly]="readOnly"></app-form-range-default-values-alarm>
    <app-form-range-default-values-alarm translation="{{'modules.alarms.rpm-3' | translate}}" name="RPM 3" tag="Action4" [minValue]="information!.AlarmValue!.Action4Min!"  [maxValue]="information!.AlarmValue!.Action4Max!" (formShared)="updateAction4Valid($event)"  [readOnly]="readOnly"></app-form-range-default-values-alarm>
    <app-form-range-default-values-alarm translation="{{'modules.alarms.presion-2' | translate}}" name="Presión 2" tag="Action5" [minValue]="information!.AlarmValue!.Action5Min!"  [maxValue]="information!.AlarmValue!.Action5Max!" (formShared)="updateAction5Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
    <app-form-range-default-values-alarm translation="{{'modules.alarms.presion-3' | translate}}" name="Presión 3" tag="Action6" [minValue]="information!.AlarmValue!.Action6Min!"  [maxValue]="information!.AlarmValue!.Action6Max!" (formShared)="updateAction6Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>

    <app-form-range-default-values-alarm translation="{{'modules.alarms.caudal-2' | translate}}" name="Caudal 2" tag="Weight1" [minValue]="information!.AlarmValue!.Weight1Min!"  [maxValue]="information!.AlarmValue!.Weight1Max!" (formShared)="updateWeight1Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
    <app-form-range-default-values-alarm translation="{{'modules.alarms.caudal-3' | translate}}" name="Caudal 3" tag="Weight2" [minValue]="information!.AlarmValue!.Weight2Min!"  [maxValue]="information!.AlarmValue!.Weight2Max!" (formShared)="updateWeight2Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>

    <app-form-range-default-values-alarm translation="{{'modules.alarms.peso-2' | translate}}" name="Peso 2" tag="Aux1" [minValue]="information!.AlarmValue!.Aux1Min!"  [maxValue]="information!.AlarmValue!.Aux1Max!" (formShared)="updateAux1Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
    <app-form-range-default-values-alarm translation="{{'modules.alarms.num-acciones-2' | translate}}" name="Nº acciones 2" tag="Aux2" [minValue]="information!.AlarmValue!.Aux2Min!"  [maxValue]="information!.AlarmValue!.Aux2Max!" (formShared)="updateAux2Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
    <app-form-range-default-values-alarm translation="{{'modules.alarms.num-acciones-3' | translate}}" name="Nº acciones 3" tag="Aux3" [minValue]="information!.AlarmValue!.Aux3Min!"  [maxValue]="information!.AlarmValue!.Aux3Max!" (formShared)="updateAux3Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
    <app-form-range-default-values-alarm translation="{{'modules.alarms.aux-1' | translate}}" name="Aux 1" tag="Aux4" [minValue]="information!.AlarmValue!.Aux4Min!"  [maxValue]="information!.AlarmValue!.Aux4Max!" (formShared)="updateAux4Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
    <app-form-range-default-values-alarm translation="{{'modules.alarms.aux-2' | translate}}" name="Aux 2" tag="Aux5" [minValue]="information!.AlarmValue!.Aux5Min!"  [maxValue]="information!.AlarmValue!.Aux5Max!" (formShared)="updateAux5Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
    <app-form-range-default-values-alarm translation="{{'modules.alarms.aux-3' | translate}}" name="Aux 3" tag="Aux6" [minValue]="information!.AlarmValue!.Aux6Min!"  [maxValue]="information!.AlarmValue!.Aux6Max!" (formShared)="updateAux6Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
    <app-form-range-default-values-alarm translation="{{'modules.alarms.aux-4' | translate}}" name="Aux 4" tag="Aux7" [minValue]="information!.AlarmValue!.Aux7Min!"  [maxValue]="information!.AlarmValue!.Aux7Max!" (formShared)="updateAux7Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>
    <app-form-range-default-values-alarm translation="{{'modules.alarms.aux-5' | translate}}" name="Aux 5" tag="Aux8" [minValue]="information!.AlarmValue!.Aux8Min!"  [maxValue]="information!.AlarmValue!.Aux8Max!" (formShared)="updateAux8Valid($event)" [readOnly]="readOnly"></app-form-range-default-values-alarm>

<!--
  <table class="full-width" cellspacing="0">
    <tr>
      <td>
        RPM
      </td>
      <td>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>{{'modules.shared.min' | translate}}</mat-label>
          <input matInput>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>{{'modules.shared.max' | translate}}</mat-label>
          <input matInput>
        </mat-form-field>
      </td>
    </tr>
  </table>-->
<!--
  <button id="basic" type="button" mat-button (click)="showForm()">
    show
  </button>

  <p>rpmValuesAlarmForm Status: {{ rpmValuesAlarmForm.status }}</p>
  <p>workingHoursvaluesAlarmForm Status: {{ workingHoursValuesAlarmForm.status }}</p>
  <p>pressure1ValuesAlarmForm Status: {{ pressure1ValuesAlarmForm.status }}</p>
  <p>pressure2ValuesAlarmForm Status: {{ pressure2ValuesAlarmForm.status }}</p>
  <p>flow1ValuesAlarmForm Status: {{ flow1ValuesAlarmForm.status }}</p>
  <p>flow2ValuesAlarmForm Status: {{ flow2ValuesAlarmForm.status }}</p>
  <p>action1ValuesAlarmForm Status: {{ action1ValuesAlarmForm.status }}</p>
  <p>action2ValuesAlarmForm Status: {{ action2ValuesAlarmForm.status }}</p>
  <p>action3ValuesAlarmForm Status: {{ action3ValuesAlarmForm.status }}</p>
  <p>action4ValuesAlarmForm Status: {{ action4ValuesAlarmForm.status }}</p>
  <p>action5ValuesAlarmForm Status: {{ action5ValuesAlarmForm.status }}</p>
  <p>action6ValuesAlarmForm Status: {{ action6ValuesAlarmForm.status }}</p>
  <p>weight1ValuesAlarmForm Status: {{ weight1ValuesAlarmForm.status }}</p>
  <p>weight2ValuesAlarmForm Status: {{ weight2ValuesAlarmForm.status }}</p>
  <p>aux1ValuesAlarmForm Status: {{ aux1ValuesAlarmForm.status }}</p>
  <p>aux2ValuesAlarmForm Status: {{ aux2ValuesAlarmForm.status }}</p>
  <p>aux3ValuesAlarmForm Status: {{ aux3ValuesAlarmForm.status }}</p>
  <p>aux4ValuesAlarmForm Status: {{ aux4ValuesAlarmForm.status }}</p>
  <p>aux5ValuesAlarmForm Status: {{ aux5ValuesAlarmForm.status }}</p>
  <p>aux6ValuesAlarmForm Status: {{ aux6ValuesAlarmForm.status }}</p>
  <p>aux7ValuesAlarmForm Status: {{ aux7ValuesAlarmForm.status }}</p>
  <p>aux8ValuesAlarmForm Status: {{ aux8ValuesAlarmForm.status }}</p>

-->

  <section class="mt-2">
    <div class="text-right" style="flex-direction: row-reverse;" *ngIf="authSrv.isAdmin() || authSrv.currentUserValue?.UserId == information?.IdUserCreated">
        <button  mat-raised-button class="btn btn-primary" [disabled]="validAllForm == true? null: 'disabled'"  (click)="onSubmit()">{{ (information.Id == 0)?('modules.shared.create' | translate): 'modules.alarms.apply-changes' | translate }}</button>
    </div>
    <div class="text-right" style="flex-direction: row-reverse;" *ngIf="!authSrv.isAdmin() && authSrv.currentUserValue?.UserId != information?.IdUserCreated">
        <button  mat-raised-button class="btn btn-primary" [disabled]="validAllForm == true? null: 'disabled'"  (click)="onSubmit(false)">{{ (information.Id == 0)?('modules.shared.create' | translate): 'modules.alarms.apply-changes' | translate }}</button>
    </div>

  <!--  <div  class="btn btn-primary"  (click)="test()">test</div> -->

</section>

  </form>

</ng-container>
