<app-loading-custom [loading]="loading"></app-loading-custom>

<ng-container *ngIf="!error && loading == false" >

  <h3>{{'modules.devices.config-list' | translate}}</h3>
  <div class="container" *ngFor="let device of devices">
    <div class="notice " [ngClass]="(device.IsOnline)?'notice-info':'notice-warning'">
        <strong>{{ device.IMEI }}</strong>  {{ device.NameManufacturer }}
    </div>

  </div>
  <div class="container">
    <div class="btn btn-primary m-2" (click)="openUploadFileModal()" >
      {{'modules.devices.load-config' | translate}}
    </div>


  </div>


  <div class="container" *ngIf="comandos">
    <div class="notice notice-info"  *ngFor="let comando of comandos; index as i">
        <strong>{{'modules.devices.command' | translate}} {{ i }}</strong> {{ comando }}
    </div>

  </div>

  <ng-container  *ngIf="comandos">
    <div class="container"   *ngFor="let device of devices">
      <div class="btn btn-warning m-2" (click)="runScript(device.IMEI!)" >
        {{'modules.devices.start-config' | translate}}: {{ device.IMEI }}
      </div>
    </div>

    <ng-terminal #term *ngIf="showTerminal"></ng-terminal>

  </ng-container>

</ng-container>
<ng-container *ngIf="error && loading == false">
  <div>
    ERROR
  </div>
</ng-container>
