

<mat-toolbar color="{{ data.ClassType}}"  class="bg-gradient-custom">
    <mat-toolbar-row>
      <span>{{ data.Title }}</span>
      <span class="example-spacer"></span>
      <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="cancel()" >
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
    </mat-toolbar>
  
  <!-- <h2 mat-dialog-title class="bg-{{ data.ClassType}}">{{ data.Title }}</h2> -->
  <div>
  
    <div class="container">
        <div  class="row" *ngIf="user">
          <div class="col-12">
            
              <div class="m-3">
                {{'modules.modals.current-user' | translate}}: {{ user!.Name??'' }} {{ user!.FirstName??'' }} {{ user!.LastName??'' }}
            </div>
        </div>
  
  
      </div>
  
      <div  class="row" *ngIf="typeUser">
        <div class="col-12">
          
            <div class="m-3">
                <app-search-users 
                    [nameField]="''" 
                    [placeholder]="labelText"
                    [roleInput]="typeUser" 
                    [idField]="'Id'" 
                    [textField]="'Name'" 
                    [noDataAvailablePlaceholderText]="noDataAvailablePlaceholderText"
                    (usersOutput)="updateUser($event)">
                </app-search-users>
            </div>
        </div>
  
  
      </div>
  
  
    <div class="row" *ngIf="loading == false">
      <div class="col-6">
  
        <button class="btn btn-secondary btn-block my-4 w-100 mb-0" type="submit" (click)="cancel()" >
          {{ data.BtnCancel | translate }}
        </button>
  
      </div>
      <div class="col-6">
        <button class="btn btn-primary btn-block my-4 w-100 mb-0" type="submit" (click)="accept()" [disabled]="(usersSelected && usersSelected.length > 0)? null : 'disabled'"  >
          {{ data.BtnAccept | translate }}
        </button>
  
  
      </div>
  
    </div>
  
  
  </div>
  
  </div>

