import { Component, OnInit } from '@angular/core';

import {
  ApexAxisChartSeries,
  ApexDataLabels,
  ApexFill,
  ApexYAxis,
  ApexXAxis,
  ApexStroke,
  ApexLegend,
  ApexChart
} from "ng-apexcharts";
import { githubData } from "./github-data";

declare const $: any

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  fill: ApexFill;
  legend: ApexLegend;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  colors: any;
};

@Component({
  selector: 'app-apex-chart',
  templateUrl: './apex-chart.component.html',
  styleUrls: ['./apex-chart.component.scss']
})
export class ApexChartComponent implements OnInit {

  public chartMonths?: Partial<ChartOptions>;
  public chartYears?: Partial<ChartOptions>;
  public commonOptions: Partial<ChartOptions> = {
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1,
      type: "solid"
    },
    xaxis: {
      type: "datetime"
    },
    stroke: {
      width: 0,
      curve: "smooth"
    }
  };


  constructor() {
    this.initCharts();
  }

  ngOnInit(): void {
  }

  public initCharts(): void {
    this.chartMonths = {
      series: [
        {
          name: "commits",
          data: githubData.series
        }
      ],
      chart: {
        id: "chartyear",
        type: "area",
        height: 160,
        background: "#F6F8FA",
        toolbar: {
          show: false,
          autoSelected: "pan"
        },

      },
      colors: ["#FF7F00"],

      yaxis: {
        show: false,
        tickAmount: 3
      }
    };

    this.chartYears = {
      series: [
        {
          name: "commits",
          data: githubData.series
        }
      ],
      chart: {
        height: 200,
        type: "area",
        background: "#F6F8FA",
        toolbar: {
          autoSelected: "selection"
        },
        brush: {
          enabled: true,
          target: "chartyear"
        },
        selection: {
          enabled: true,
          xaxis: {
            min: new Date("26 Jan 2014").getTime(),
            max: new Date("29 Mar 2015").getTime()
          }
        }
      },
      colors: ["#7BD39A"],
      legend: {
        position: "top",
        horizontalAlign: "left"
      }
    };
  }

}
