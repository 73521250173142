import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlarmDeviceModelDefaultValues, AlarmDeviceModelValues, AlarmValue } from 'src/app/models/alarms';
import { ListResult } from 'src/app/models/apiRequestObject';
import { DeviceModel } from 'src/app/models/device';
import { Manufacturer, Role, User } from 'src/app/models/user';
import { AlarmsService } from 'src/app/services/alarms.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { DevicesService } from 'src/app/services/devices.service';
import { UsersService } from 'src/app/services/users.services';

@Component({
  selector: 'app-detail-model-alarm-page',
  templateUrl: './detail-model-alarm-page.component.html',
  styleUrls: ['./detail-model-alarm-page.component.scss']
})
export class DetailModelAlarmPageComponent implements OnInit {

  id?: number;
  idModel?: number;
  loading:boolean = true;
  information?: AlarmDeviceModelValues;
  manufacturerList?: Manufacturer[];
  title: string = "";
  selectedManufacturerList: Manufacturer[] = [];
  showChangeManufacturer = true;
  showChangeModel = true;

  loadManufacturers = false;
  loadDefaultValues = false;

  loadComplete(){

    console.log("loadComplete", this.loadManufacturers, this.loadDefaultValues);
    if(this.loadManufacturers == true && this.loadDefaultValues == true){
      this.loading = false;
    }
  }

  constructor(private router: Router,
    private breadcrumbService: BreadcrumbService,
    public authSrv: AuthenticationService,
    private usersSrv: UsersService,
    private deviceSrv: DevicesService,
    private alarmrSrv : AlarmsService,
    private route: ActivatedRoute,
    private translate: TranslateService) {
      this.title = this.translate.instant('modules.alarms.management')
      breadcrumbService.setBreadCrumbUrl('alarms/list', this.title);

      this.route.params.subscribe(params => {

        this.id = params['id'];
        let auxId = params['idAlarm'];

        console.log(this.id)
        if(this.id && (auxId == null || (auxId != null && auxId != "add" ))){

          if(auxId){
            this.idModel = this.id;
            this.id = auxId;
          }


          this.loadValues(this.id! );
          this.getManufacturers();

        }else{
          this.loadDefaultValues = true;

          if(auxId){
            this.idModel = this.id;
          }

          this.id = 0;
          this.information = new AlarmDeviceModelValues();
          this.information.Id = 0;
          this.information.AlarmDeviceModelDefaultValues = new AlarmDeviceModelDefaultValues();
          this.information.AlarmDeviceModelDefaultValues.defaultValues();

          this.information.DeviceModel = new DeviceModel();

          this.information.AlarmValue =  new AlarmValue();
          this.information.AlarmValue.defaultValues();


          if(this.idModel){

            this.loadModel(this.idModel);
          }else{
            this.title = this.translate.instant('modules.alarms.new-alarm-model-machine')
            this.breadcrumbService.setBreadCrumbUrl('alarms/list', this.translate.instant('modules.alarms.management'));
            this.breadcrumbService.addBreadCrumb('alarms/add',  this.title );
          }


          this.getManufacturers();


          console.log(this.information);
        }

      });
    }



  ngOnInit(): void {
    this.loading = true;
  }

  getManufacturers(){
    this.loading = true;
    this.manufacturerList = undefined;
    this.usersSrv.getManufacturers(0, 10000, 'Name' , 'ASC' ).subscribe({
      next: (res: ListResult) => {

        this.manufacturerList = <Manufacturer[]>res.Result;
        console.log(this.manufacturerList);

      },error: (err: any) =>{
        console.log("error getClients");
        this.loadManufacturers = true;
        this.loadComplete();
      }, complete: () =>{
        this.loadManufacturers = true;
        this.loadComplete();

      }
    });

  }

  loadValues(id: number){
    this.loading = true;
    this.information = undefined;
    console.log('load information: ' , id );
    this.alarmrSrv.getModelsValuesById(id).subscribe({
      next: (res: AlarmDeviceModelValues) => {
        this.information = new AlarmDeviceModelValues(<AlarmDeviceModelValues>res);

        this.information.User = new User( this.information.User );
        this.information.User.Role = new Role( this.information.User.Role );

        if(this.idModel){
          this.breadcrumbService.setBreadCrumbUrl('devices/list', this.translate.instant('modules.devices.management'));
          this.breadcrumbService.addBreadCrumb('devices/models/' + this.information!.DeviceModel.Id + '/detail/alarmas', this.translate.instant('modules.alarms.model') + ' ' + this.information!.DeviceModel.Name );

          let titulo =  this.translate.instant('modules.alarms.edit-alarm-model');
          this.title =  this.translate.instant('modules.alarms.edit-alarm-model') + ': ' + this.information!.DeviceModel.Name??'';
          this.breadcrumbService.addBreadCrumb('devices/models/' +  this.information!.DeviceModel.Id + '/alarms/model/' + this.information!.Id  , titulo );

          this.showChangeModel = false;

        }else{

          this.breadcrumbService.setBreadCrumbUrl('alarms/list', this.translate.instant('modules.alarms.management'));
          this.title =  this.translate.instant('modules.alarms.alarm-for-model-machine') + ' ' + this.information!.DeviceModel!.Name??'';
          this.breadcrumbService.addBreadCrumb('alarms/default/' + this.information!.Id  ,this.title  );
        }

        this.selectedManufacturerList?.push(res.DeviceModel.Manufacturer);


      }, error: ()=> {
        this.loadDefaultValues = true;
        this.loadComplete();

      },
      complete: ()=>{
        this.loadDefaultValues = true;
        this.loadComplete();

      }
    });
  }

  loadModel(id: number){
    this.loading = true;
    console.log('load model: ' , id );

    this.deviceSrv.getModelById(id).subscribe({
      next: (res: DeviceModel) => {
        this.information!.DeviceModel = new DeviceModel(<DeviceModel>res);
        this.information!.IdModel = this.information!.DeviceModel.Id;


        this.breadcrumbService.setBreadCrumbUrl('devices/list', this.translate.instant('modules.devices.management'));
        this.breadcrumbService.addBreadCrumb('devices/models/' + this.information!.DeviceModel!.Id + '/detail/alarmas' , this.translate.instant('modules.alarms.model') + ' ' + this.information!.DeviceModel!.Name );
        this.title =  this.translate.instant('modules.alarms.new-alarm-model-machine');
        this.breadcrumbService.addBreadCrumb('devices/models/' + this.information!.DeviceModel!.Id + '/alarms/' + (this.information!.Id==0?'add':this.information!.Id)  ,this.title  );

        this.selectedManufacturerList?.push(this.information!.DeviceModel.Manufacturer);
        this.showChangeManufacturer = false;
        this.showChangeModel = false;

      }, error: ()=> {
        this.loadDefaultValues = true;
        this.loadComplete();

      },
      complete: ()=>{
        this.loadDefaultValues = true;
        this.loadComplete();

      }
    });
  }



  deleteAlarm(){
    console.log("deleteAlarm");
    this.alarmrSrv.deleteByModel(this.information!).subscribe(
      {
        next: (res: boolean) => {
          if(res==true){
            if(this.idModel){
              this.router.navigate(['/devices/models/' + this.idModel + '/detail/alarmas']);
            }else{
              this.router.navigate(['/alarms/list']);
            }
          }
        }
      }
    )
  }
}
