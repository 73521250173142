import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Modal } from 'src/app/models/modal';
import { ConfirmationModalComponent } from 'src/app/modals/confirmation-modal/confirmation-modal.component';
import { ThemeService } from 'src/app/services/theme.services';
import { UtilsService } from 'src/app/services/utils.service';
import { Theme } from 'src/app/models/theme';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

declare const $: any

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, AfterViewInit {

  class: string = "#side-nav-shared";
  classTop: string = ".sidebar-content";
  classBottom: string = ".sidebar-content-footer";
  currentTheme : Theme = new Theme();

  constructor(
    public router: Router,
    public authSrv: AuthenticationService,
    public dialog: MatDialog,
    private themeSrv: ThemeService,
    private utilsSrv: UtilsService,
    private translate: TranslateService
    ) {

      if(this.themeSrv.currentPreviewTheme()){
        this.currentTheme = this.themeSrv.currentPreviewTheme()!;
        console.log("previewTheme");
      }else{
        this.currentTheme = this.themeSrv.currentTheme;
        console.log("NopreviewTheme");
      }

    }

  ngOnInit(): void {

  }

  showUrl(){
    console.log(this.router.url, 'router.url.startsWith' , this.router.url.startsWith('/devices'));
  }


  get showTrips(): boolean{
    return this.authSrv.isAdmin() || ( environment.showTrips && this.authSrv.hasTripsModuleEnabled());
  }
  get showDocManager(): boolean{
    return this.authSrv.isAdmin() || ( environment.showDocManager && this.authSrv.hasDocManagerModuleEnabled());
  }

  get nameUser(){
    return this.authSrv.currentUserValue?.UsernameLong??'';
  }
  get mail(){

    return this.authSrv.currentUserValue?.Email??'';
  }

  get isAdmin(){

    if(this.authSrv.currentUserValue?.Payload?.role){
      if(this.authSrv.currentUserValue?.Payload?.role == 'admin'){
        return true;
      }
      if(this.authSrv.currentUserValue?.Payload?.role == 'fabricant'){
        //return this.translate.instant('modules.tables.account');
      }
    }
    return false;
    //return 'Usuario';
  }


  ngAfterViewInit(): void{
    console.log("ngAfterViewInit");
    //this.applyTheme();
    // QQ : En certs casos no es pinta el sidebar correctament
    setTimeout(() => {
      this.setTheme();
    }, 10);

  }

  public hoverCss(id: string){

    if(!$("#" + id).find("a").hasClass("active")){
      $("#" + id).find("i").css("color", this.currentTheme.MenuTextHoverColor);
      $("#" + id).find("span").css("color", this.currentTheme.MenuTextHoverColor);
    }
  }

  public leaveCss(id: string){
    if(!$("#" + id).find("a").hasClass("active")){

      $("#" + id).find("i").css("color", this.currentTheme.MenuTextColor);
      $("#" + id).find("span").css("color", this.currentTheme.MenuTextColor);
    }
  }

  public hoverBottomCss(id: string){

    if(!$("#" + id).find("a").hasClass("active")){
      $("#" + id).find("i").css("color", this.currentTheme.MenuBottomTextHoverColor);
      $("#" + id).find("span").css("color", this.currentTheme.MenuBottomTextHoverColor);
    }
  }

  public leaveBottomCss(id: string){
    if(!$("#" + id).find("a").hasClass("active")){

      $("#" + id).find("i").css("color", this.currentTheme.MenuBottomTextColor);
      $("#" + id).find("span").css("color", this.currentTheme.MenuBottomTextColor);
    }
  }

  public logout(): void{

    //window.location.reload();


    let modal = new Modal();
    modal.Title = this.translate.instant('modules.modals.log-out');
    modal.Body = this.translate.instant('modules.modals.sure-to-log-out');
    modal.ClassType = "danger";
    modal.BtnAccept = this.translate.instant('modules.modals.accept');
    modal.BtnCancel = this.translate.instant('modules.shared.cancel');

    const dialogRef = this.dialog.open(
      ConfirmationModalComponent,
      {
        autoFocus : 'dialog',
        width: "400px",
        data: modal,
        closeOnNavigation: false,
        disableClose: true,
        panelClass: 'custom-modalbox',
        position: { top: '150px'}
      });
    dialogRef.afterClosed().subscribe( (result: boolean) => {
      console.log(`Dialog result: ${result}`);
      if(result == true){

        this.authSrv.logout();
      }
    });
  }

  private setTheme(){

    // console.warn('setTheme', this.currentTheme);

    let colorTop = this.utilsSrv.hexToRGB(this.currentTheme.MenuColorTop, 1);
    let color = this.utilsSrv.hexToRGB(this.currentTheme.MenuColor, 1);
    let colorBottom = this.utilsSrv.hexToRGB(this.currentTheme.MenuColorBottom, 1);

    console.log(colorTop, color, colorBottom);

    // Top
    $(this.class).css("background", "linear-gradient(180deg, " + colorTop + " 12%, " + color + " 65%, " + colorBottom + " 100%)" );
    $(this.classTop).find("a").css("color", this.currentTheme.MenuTextColor);
    $(this.classTop).find(".header-menu>span").css("color", this.currentTheme.MenuTextColor);
    $(this.classTop).find(".color-text").css("color", this.currentTheme.MenuTextColor);

    // Bottom
    console.log("color" , this.currentTheme.MenuBottomTextColor);
    $(this.classBottom).css("background", this.currentTheme.MenuBottomColor);
    $(this.classBottom).find("i").css("color", this.currentTheme.MenuBottomTextColor);
    $(this.classBottom).find("span").css("color", this.currentTheme.MenuBottomTextColor);

    // Active
    setTimeout( () => {
      $(this.classTop).find(".active").eq(0).find("i").css("color", this.currentTheme.MenuTextActiveColor);
      $(this.classTop).find(".active").eq(0).find("span").css("color", this.currentTheme.MenuTextActiveColor);

    }, 100 );
  }

}
