import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Modal } from 'src/app/models/modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateTime, Settings, } from "luxon";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomLuxonDateAdapter } from 'src/app/modules/charts/components/timeline-apex-chart/customDateAdapter';
import { LuxonDateAdapter, MAT_LUXON_DATE_FORMATS, MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';

@Component({
  selector: 'app-input-date-modal',
  templateUrl: './input-date-modal.component.html',
  styleUrls: ['./input-date-modal.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    {
      provide: DateAdapter,
      useClass: CustomLuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS},
  ]
})
export class InputDateModalComponent implements OnInit {

  inputValue: Date | null = null;

  form: FormGroup = this.formBuilder.group({
  });

  InformationFirstValue: DateTime | null= null;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<InputDateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Modal) { }


    ngOnInit(): void {
        this.form = this.formBuilder.group({
            InformationFirst: new FormControl(this.InformationFirstValue, [
              Validators.required
            ]) 
          });
    }
    

    accept(){

        console.log('InformationFirstValue' , (this.InformationFirstValue)?this.InformationFirstValue.toFormat("dd/MM/yyyy"):'null');
        //console.log('this.form' ,  (this.form.get('InformationFirst')?.value as moment.Moment).toDate() );
        console.log('---' );
      this.dialogRef.close(this.InformationFirstValue);
    }

    cancel(){
      this.dialogRef.close('');
    }

}
