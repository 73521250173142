<div class="m-3">
  <h1>{{'modules.modals.modify-model-machine' | translate}}</h1>
  <app-edit-device-model *ngIf="deviceModelInput" [rolInput]="'fabricant'"
  [editDeviceModel]="deviceModelInput"
  [editQuick]="true"
  [userInput]="user" [showButtonCancel]="true"
  (deviceModelOutput)="emitDeviceModel($event)"
  ></app-edit-device-model>
</div>
<!--


  (deviceModelOutput)="emitDeviceModel($event)"
-->
