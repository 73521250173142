import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExportChartsService {

  private dataSource = new BehaviorSubject({
      sensor:     '',
      imei:       '',
      nombre:     '',
      modelo:     '',
      cliente:    '',
      numeroSerie:    '',
      fabricante: '',
      desde:      '',
      hasta:      '',
      download:   false,
      notificacion: ''
  });
  private dataChart = new BehaviorSubject('');

  currentData = this.dataSource.asObservable();
  currentChart = this.dataChart.asObservable();

  constructor() { }

  changeData(exportData: any) {
    this.dataSource.next(exportData)
  }
  changeChart(exportChart: any) {
    this.dataChart.next(exportChart)
  }

  destroy() {
    // this.dataChart.unsubscribe();
  }
}
