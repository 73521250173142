import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Device, vDevice } from 'src/app/models/device';
import { DevicesService } from 'src/app/services/devices.service';
import { ListResult } from 'src/app/models/apiRequestObject';
import { DeviceAddQuickComponent } from 'src/app/modals/device-add-quick/device-add-quick.component';
import { Modal } from 'src/app/models/modal';
import { Observable } from 'rxjs/internal/Observable';
import { from } from 'rxjs/internal/observable/from';
import { of } from 'rxjs/internal/observable/of';
import { concatMap, delay } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-user-devices',
  templateUrl: './edit-user-devices.component.html',
  styleUrls: ['./edit-user-devices.component.scss']
})
export class EditUserDevicesComponent implements OnInit {

  @Input() user?: User;
  loading:boolean = true;

  devicesForm: FormGroup = this.formBuilder.group({
  });

  @Output() devicesFormShared = new EventEmitter<FormGroup>();



  private emitFormGeneral() {
    this.devicesFormShared.emit(this.devicesForm);
  }

  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private deviceSrv : DevicesService,
    public authSrv : AuthenticationService,
    private translate: TranslateService) { }

  devices: Array<vDevice> | null= null;
  dropdownSettings : IDropdownSettings= {};
  ngOnInit() {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'NameSelector',
      selectAllText: this.translate.instant('modules.shared.select-all'),
      unSelectAllText: this.translate.instant('modules.shared.unselect-all'),
      allowSearchFilter: true,
      //noFilteredDataAvailablePlaceholderText: "No hay coincidencias",
      noDataAvailablePlaceholderText: this.translate.instant('modules.shared.no-devices'),
      searchPlaceholderText: this.translate.instant('modules.shared.search'),
      clearSearchFilter: true,

    };

    this.getDevices();


   this.devicesForm.addControl('Devices', this.formBuilder.group({
      vDevices: new FormControl(this.user!.vDevices!, [
    ])}));
    if(this.user?.isManufacturer){
      this.emitFormGeneral();
    }
  }

  get vDevices(){ return this.devicesForm.get("Devices")!.get("vDevices"); }


  getDevices(){
    this.loading = true;
    this.devices = null;

    if(this.user!.isClient){

      this.deviceSrv.getDevicesWithoutUser().subscribe(
        {
          next: (res: ListResult) => {

          this.devices = [];
          const that = this;
          res.Result.forEach( function(item: vDevice ) {
            that.devices!.push(new vDevice(item));
          });
          this.user!.vDevices!.forEach( function(item: vDevice ) {
            that.devices!.push(new vDevice(item));
          });

          /*
          this.devices = (<vDevice[]>res.Result).concat(this.user!.vDevices!);
          if(this.devices){
            this.devices.forEach( function(item: vDevice ) {
              if(item){
                item.NameSelector =  item?.Name??'' + ' ' + item?.IMEI??'' + ' ' + item?.NameManufacturer??'' + ' ' + item?.NameDeviceModel ;
              }
            });
          }*/
          console.log(this.devices );

        },
        error: ( err : any) => {

        },
        complete: () =>{
          this.loading = false;
          this.emitFormGeneral();
        }
      });
    }else if(this.user!.isManufacturer){

      this.devices = <vDevice[]>this.user!.vDevices!.map(object => (new vDevice({ ...object })));
      this.loading = false;
    }
  }



  get getDevice() {
    return this.devices!.reduce((acc:any , curr: vDevice) => {
      acc[curr.Id!] = curr;
      return acc;
    }, {});
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  onSubmit(){
    console.log(this.devices );
    console.log(this.user!.vDevices!)
    console.log(this.user!)
  }

  newDevice()
  {
    const dialogRef = this.dialog.open(
      DeviceAddQuickComponent,
      {
        id: 'DeviceAddQuickComponent',
        autoFocus : 'dialog',
        width: "auto",
        minWidth: '600px',
        data: new Modal({ 'Data' : { "idUser" : this.user!.Id }, 'User' : this.user! }),
        closeOnNavigation: true,
        disableClose: false,
        panelClass: 'custom-modalbox'

    });

    dialogRef.afterClosed().subscribe( (result?: Device) => {
      if(result){
        console.log(`Dialog result: ${result.Name!}`);
        let newDevice: vDevice = new vDevice(Object.assign({},result));
        //newDevice.NameSelector =  newDevice?.Name??'' + ' ' + newDevice?.IMEI??'' + ' ' + newDevice?.NameManufacturer??'' + ' ' + newDevice?.NameDeviceModel ;

        this.user!.vDevices!.push(newDevice);
        this.devices!.push(newDevice);

        this.devices = <vDevice[]>this.devices!.map(object => (new vDevice({ ...object })));
        this.user!.vDevices = <vDevice[]>this.user!.vDevices!.map(object => (new vDevice({ ...object })));

        //this.getDevices();
        //this.user!.vDevices = Object.assign( this.user?.vDevices , newDevice);
        //
      }
    });
  }

}
